import React, { ReactNode, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AccordionItem } from 'modules/purchase/components/AccordionItem'
import {
  ICON_MAP,
  PAYMENT_METHODS_INITIAL,
} from 'modules/purchase/components/PaymentMethods/constants'
import { PaymentMethod } from 'modules/purchase/constants'
import { setPaymentMethodAction } from 'modules/purchase/redux/actions/common'
import { selectOneClickPaymentMethod } from 'modules/purchase/redux/selects'

export type TOption = {
  value: PaymentMethod
  title: string
  images: string[]
  form: ReactNode
}

export const PaymentMethods = () => {
  const dispatch = useDispatch()
  const oneClickPaymentMethod = useSelector(selectOneClickPaymentMethod)

  const [options, setOptions] = useState<TOption[]>([])
  const [activeMethod, setActiveMethod] = useState<string>('')

  const handleClick = (value: string) => {
    setActiveMethod((prevMethod) => (prevMethod === value ? prevMethod : value))

    if (value === PaymentMethod.CREDIT_CARD) {
      dispatch(setPaymentMethodAction(PaymentMethod.CREDIT_CARD))
    }
  }

  useEffect(() => {
    if (oneClickPaymentMethod) {
      setActiveMethod(PaymentMethod.ONE_CLICK)

      const modifiedOptions = PAYMENT_METHODS_INITIAL.reduce(
        (acc: TOption[], curr) => {
          if (curr.value === PaymentMethod.ONE_CLICK) {
            return [
              ...acc,
              {
                ...curr,
                images: [ICON_MAP[oneClickPaymentMethod], ...curr.images],
              },
            ]
          }

          return [...acc, curr]
        },
        [],
      )

      setOptions(modifiedOptions)
    } else {
      setActiveMethod(PaymentMethod.CREDIT_CARD)
      setOptions(PAYMENT_METHODS_INITIAL)
      dispatch(setPaymentMethodAction(PaymentMethod.CREDIT_CARD))
    }
  }, [dispatch, oneClickPaymentMethod])

  return (
    <>
      {options.map(({ value, title, images, form }) => (
        <AccordionItem
          key={value}
          title={title}
          icons={images}
          isOpen={activeMethod === value}
          onClick={() => handleClick(value)}
          value={value}
          form={form}
        />
      ))}
    </>
  )
}
