import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { AnswerButtonV2 } from 'components/AnswerButtonV2'
import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Gender } from 'root-constants'

import { StyledWeightGain as S } from './WeightGain.styles'
import { ANSWER_OPTIONS, OPTION_VALUES, QUESTION } from './constants'

export const WeightGain: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const userGender = useSelector(selectCurrentUserGender)

  const [answers, setAnswers] = useState<string[]>([])

  const filteredAnswerOptions = useMemo(
    () =>
      userGender === Gender.MALE
        ? ANSWER_OPTIONS.filter(({ value }) => value !== OPTION_VALUES.PREGNANT)
        : ANSWER_OPTIONS,
    [userGender],
  )

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked) {
          setAnswers(
            answers.includes(OPTION_VALUES.NONE)
              ? [value]
              : [...answers, value],
          )
        } else {
          setAnswers(answers.filter((item) => item !== value))
        }
      },
    }),
    [pageId, answers],
  )

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const otherOptionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked) {
          setAnswers([value])
        } else {
          setAnswers([])
        }
      },
    }),
    [pageId],
  )

  const handleNextClick = useCallback(() => {
    handleContinue(answers)
  }, [answers, handleContinue])

  return (
    <Container>
      <PageTitle marginBottom={8}>{t('onboarding.weightGain.title')}</PageTitle>
      <S.SubTitle>{t('onboarding.weightGain.subTitle')}</S.SubTitle>
      {filteredAnswerOptions.map(({ value, title }) => (
        <Option
          key={value}
          {...optionProps}
          marginBottom="12"
          value={value}
          checked={answers.includes(value)}
        >
          <AnswerButtonV2 withCheckbox>
            <h3>{t(title)}</h3>
          </AnswerButtonV2>
        </Option>
      ))}
      <Option
        {...otherOptionProps}
        marginBottom="12"
        value={OPTION_VALUES.NONE}
        checked={answers.includes(OPTION_VALUES.NONE)}
      >
        <AnswerButtonV2>
          <h3>{t('onboarding.weightGain.options.none')}</h3>
        </AnswerButtonV2>
      </Option>
      <StickyButtonContainer>
        <Button
          type="button"
          disabled={!answers.length}
          onClick={handleNextClick}
        >
          {t('actions.continue')}
        </Button>
      </StickyButtonContainer>
    </Container>
  )
}
