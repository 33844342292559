import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SvgImage } from 'components/SvgImage'

import { CUSTOMER_REVIEWS } from 'modules/purchase/components/CustomerReviews/constants'
import { useDynamicPaywallConfig } from 'modules/purchase/hooks/useDynamicPaywallConfig'

import starSvg from 'assets/images/sprite/star.svg'

import {
  Author,
  Button,
  Date,
  Rating,
  ReviewContainer,
  ReviewTitle,
  StyledCustomerReviews as S,
  Text,
  Title,
} from './CustomerReviews.styles'

type TCustomerReviewsProps = {
  withTitle?: boolean
  className?: string
}

export const CustomerReviews: React.FC<TCustomerReviewsProps> = ({
  withTitle = true,
  className,
}) => {
  const { t } = useTranslation()
  const [activeReviewIndex, setActiveReviewIndex] = useState<number | null>(
    null,
  )

  const { customerReviewsTitle, customerReviews } = useDynamicPaywallConfig()

  const handleReviewClick = useCallback((index: number) => {
    setActiveReviewIndex(index)
  }, [])

  return (
    <div className={className}>
      {withTitle && (
        <Title>
          {customerReviewsTitle || t('purchase.customerReviews.title')}
        </Title>
      )}
      {!customerReviews?.length &&
        CUSTOMER_REVIEWS.map(
          ({ id, title, subtitle, author, review, shortReview }, index) => (
            <ReviewContainer key={id}>
              <S.ReviewHeader>
                <ReviewTitle>{t(title)}</ReviewTitle>
                <Date>{subtitle}</Date>
                <Rating>
                  <SvgImage svg={starSvg} />
                  <SvgImage svg={starSvg} />
                  <SvgImage svg={starSvg} />
                  <SvgImage svg={starSvg} />
                  <SvgImage svg={starSvg} />
                </Rating>
                <Author>{author}</Author>
              </S.ReviewHeader>
              <S.TextContent>
                <S.TextContainer>
                  <Text>
                    {activeReviewIndex !== index ? t(shortReview) : t(review)}
                    {activeReviewIndex !== index && (
                      <Button onClick={() => handleReviewClick(index)}>
                        {t('purchase.customerReviews.more')}
                      </Button>
                    )}
                  </Text>
                </S.TextContainer>
              </S.TextContent>
            </ReviewContainer>
          ),
        )}

      {!!customerReviews?.length &&
        customerReviews.map(
          ({ id, title, subtitle, author, review, shortReview }, index) => (
            <ReviewContainer key={id}>
              <S.ReviewHeader>
                <ReviewTitle>{title}</ReviewTitle>
                <Date>{subtitle}</Date>
                <Rating>
                  <SvgImage svg={starSvg} />
                  <SvgImage svg={starSvg} />
                  <SvgImage svg={starSvg} />
                  <SvgImage svg={starSvg} />
                  <SvgImage svg={starSvg} />
                </Rating>
                <Author>{author}</Author>
              </S.ReviewHeader>
              <S.TextContent>
                <S.TextContainer>
                  <Text>
                    {activeReviewIndex !== index ? shortReview : review}
                    {activeReviewIndex !== index && (
                      <Button onClick={() => handleReviewClick(index)}>
                        {t('purchase.customerReviews.more')}
                      </Button>
                    )}
                  </Text>
                </S.TextContainer>
              </S.TextContent>
            </ReviewContainer>
          ),
        )}
    </div>
  )
}
