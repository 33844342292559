import styled from 'styled-components'

import { Logo } from 'components/Logo'

import pageBg from 'assets/images/we-all-gain-weight-bg.jpg'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledWeAllGainWeight = {
  ImageContainer: styled.div`
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    margin: 0 auto;
    padding: 8px 0 0;
    background-image: url(${pageBg});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top center;
    aspect-ratio: 338/346;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
    }
  `,
  Logo: styled(Logo)`
    margin-bottom: 3px;
  `,
  Title: styled.h1`
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: ${Color.GREY_100};
    margin: 0 0 16px 0;
  `,
  Subtitle: styled.p`
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    text-wrap: nowrap;
    color: ${Color.GREY_100};
    margin: 0 0 32px 0;
  `,
}
