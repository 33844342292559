import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledWeightLossJourney = {
  Title: styled.h2<{ hasIndent?: boolean }>`
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: ${Color.GREY_100};
    text-align: center;
    margin: 0 0 20px 0;
    padding: ${({ hasIndent }) => (hasIndent ? `0` : `0 16px`)};
  `,
  ScreenContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 324px;
    aspect-ratio: 164/324;
  `,
}
