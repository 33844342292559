import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledFeedbackV2 = {
  Card: styled.div`
    border-radius: 16px;
    overflow: hidden;
    border: 1px solid ${Color.GREY_800};
  `,
  ImageContainer: styled.div`
    width: 100%;
    aspect-ratio: 343/200;
  `,
  Feedback: styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
  `,
  FeedbackText: styled.p`
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: ${Color.GREY_100};
    margin: 0 0 12px 0;
  `,
  FeedbackAuthor: styled.p`
    display: flex;
    align-items: center;
    height: 18px;

    > :not(:last-child) {
      margin: 0 8px 0 0;
    }
  `,
  AuthorName: styled.span`
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: ${Color.GREY_100};
  `,
  Rating: styled.img`
    height: 16px;
    aspect-ratio: 84/16;
  `,
}
