import jane from 'assets/images/review-jane.png'
import kate from 'assets/images/review-kate.png'
import kyle from 'assets/images/review-kyle.png'
import meg from 'assets/images/review-meg.png'
import mike from 'assets/images/review-mike.png'
import steven from 'assets/images/review-steven.png'
import screen1 from 'assets/images/screen-fasting-plan.png'
import screen3 from 'assets/images/screen-loss-programm.png'
import screen2 from 'assets/images/screen-meal-planning.png'
import screen4 from 'assets/images/screen-recipes.png'

import { Color, Emojis, Gender } from 'root-constants'

export const PLAN_COMPONENTS = [
  {
    titleData: {
      title: 'presaleV2.mainOptions.fastingPlan.title',
      titleColor: Color.GREY_100,
      subtitle: 'presaleV2.mainOptions.fastingPlan.subtitle',
    },
    content: screen1,
  },
  {
    titleData: {
      title: 'presaleV2.mainOptions.mealPlan.title',
      titleColor: '#51A1E0',
      subtitle: 'presaleV2.mainOptions.mealPlan.subtitle',
    },
    content: screen2,
  },
  {
    titleData: {
      title: 'presaleV2.mainOptions.lossProgram.title',
      titleColor: '#FF9C46',
      subtitle: 'presaleV2.mainOptions.lossProgram.subtitle',
    },
    content: screen3,
  },
  {
    titleData: {
      title: 'presaleV2.mainOptions.recipes.title',
      titleColor: Color.GREY_100,
      subtitle: 'presaleV2.mainOptions.recipes.subtitle',
    },
    content: screen4,
  },
]
export const ADDITIONAL_PLAN_COMPONENTS = [
  {
    textKey: 'presaleV2.additionalOptions.calculator',
    iconSrc: Emojis.SOUP,
  },
  {
    textKey: 'presaleV2.additionalOptions.progress',
    iconSrc: Emojis.BICEPS,
  },
  {
    textKey: 'presaleV2.additionalOptions.tracker',
    iconSrc: Emojis.CHART,
  },
  {
    textKey: 'presaleV2.additionalOptions.support',
    iconSrc: Emojis.SUPPORT,
  },
]

export const REVIEWS_MAP = {
  [Gender.FEMALE]: [
    {
      userAvatar: kate,
      userName: 'Kate, 23',
      review: 'commonComponents.reviews.kate',
    },
    {
      userAvatar: meg,
      userName: 'Meg, 54',
      review: 'commonComponents.reviews.meg',
    },
    {
      userAvatar: jane,
      userName: 'Jane, 37',
      review: 'commonComponents.reviews.jane',
    },
  ],
  [Gender.MALE]: [
    {
      userAvatar: kyle,
      userName: 'Kyle, 34',
      review: 'commonComponents.reviews.kyle',
    },
    {
      userAvatar: steven,
      userName: 'Steven, 36',
      review: 'commonComponents.reviews.steven',
    },
    {
      userAvatar: mike,
      userName: 'Mike, 46',
      review: 'commonComponents.reviews.mike',
    },
  ],
}

export const PERCENTAGE = 98
