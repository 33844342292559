import styled from 'styled-components'

import { Button } from 'components/Button'
import { PageTitle } from 'components/PageTitle'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { Color } from 'root-constants'

export const StyledFastingFeedback = {
  PageTitle: styled(PageTitle)`
    text-align: left;
  `,
  Info: styled.h4`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 12px;

    strong {
      font-weight: 700;
    }
  `,
  Period: styled.ul`
    border-color: ${Color.GREY_300};
    border-style: solid;
    border-width: 1px 0;
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    padding: 12px 8px;
    row-gap: 12px;
    width: 100%;
  `,
  PeriodItem: styled.li`
    align-items: center;
    column-gap: 10px;
    display: flex;
  `,
  Circle: styled.span`
    background-color: ${Color.PRIMARY};
    border-radius: 50%;
    height: 8px;
    width: 8px;
  `,
  PeriodContent: styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

    strong {
      font-weight: 700;
    }
  `,
  CallToAction: styled.h4`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 24px;

    strong {
      font-weight: 700;
    }
  `,
  Benefits: styled.div`
    background-color: ${Color.LIGHT};
    border-radius: 16px;
    padding: 16px;
    width: 100%;
  `,
  BenefitsTitle: styled.h3`
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 16px;
  `,
  BenefitsList: styled.ul`
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  `,
  BenefitsItem: styled.li`
    align-items: center;
    column-gap: 12px;
    display: flex;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  `,
  BenefitsIcon: styled.span`
    aspect-ratio: 1 / 1;
    display: flex;
    height: 20px;
    min-width: 20px;
  `,
  StickyButtonContainer: styled(StickyButtonContainer)`
    max-width: initial;
  `,
  Button: styled(Button)`
    max-width: 100%;
  `,
}

export const FastingFeedbackV2 = {
  ImageContainer: styled.div`
    aspect-ratio: 343/246;
    width: 100%;
    margin: 0 auto 24px auto;
  `,
  DescriptionBlock: styled.div`
    display: flex;
    flex-direction: column;

    > :not(:last-child) {
      margin: 0 0 16px 0;
    }
  `,
  Paragraph: styled.p`
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: ${Color.GREY_101};

    strong {
      font-weight: 700;
      color: ${Color.GREY_100};
    }
  `,
}
