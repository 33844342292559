import styled, { css } from 'styled-components'

import { Input } from 'components/Input'

import { Color, MediaBreakpoint } from 'root-constants'

type THeightProps = {
  value?: string
  isValid?: boolean
  isMetric?: boolean
}

export const StyledHeightV2 = {
  Actions: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  `,
  ButtonContainer: styled.div`
    padding-top: 8px;
  `,
  Disclaimer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 8px 12px;
    gap: 4px;
    background: rgba(192, 187, 255, 0.15);
    border-radius: 12px;
    margin-top: 24px;
    margin-bottom: 24px;
  `,
  Title: styled.div`
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: ${Color.GREY_100};
  `,
  SubTitle: styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: ${Color.GREY_100};
  `,
  InputContainer: styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
  `,
  InputWrapper: styled.div<THeightProps>`
    position: relative;
    min-width: ${({ isMetric }) => (isMetric ? '285px' : '130px')};
    flex-basis: 48%;

    @media (min-width: ${MediaBreakpoint.MIN_PHONE}px) {
      min-width: ${({ isMetric }) => (isMetric ? '328px' : '164px')};
      flex-basis: auto;
    }
  `,
  FirstInput: styled(Input)<THeightProps>`
    padding: ${({ isMetric }) =>
      isMetric ? '21px 40px 21px 32px' : '16px 16px 16px 32px'};
    height: 72px;
    background: ${Color.WHITE};
    border: 2px solid ${Color.LIGHT_BLUE};
    border-radius: ${({ isMetric }) => (isMetric ? '12px' : '12px 0 0 12px')};
    ${({ isValid, value }) =>
      value &&
      !isValid &&
      css`
        background: ${Color.PALE_RED};
        border: 2px solid ${Color.PINK};
        color: ${Color.PINK};
      `};

    ${({ isValid, value }) =>
      value &&
      isValid &&
      css`
        background: ${Color.WHITE};
        border: 2px solid ${Color.PRIMARY};
        color: ${Color.GREY_100};
      `};

    &:focus,
    &:active {
      & ~ span {
        display: none;
      }
    }
  `,
  SecondInput: styled(Input)`
    border-width: 2px 2px 2px 0;
    border-style: solid;
    border-color: ${Color.GREY_WHITE};
    border-radius: 0 12px 12px 0;
    padding: 16px 16px 16px 32px;
    height: 72px;
    background: ${Color.WHITE};
    ${({ isValid, value }) =>
      value &&
      !isValid &&
      css`
        border-width: 2px 2px 2px 1px;
        border-style: solid;
        border-color: ${Color.PINK};
        background: ${Color.WHITE};
        color: ${Color.PINK};
        background: ${Color.PALE_RED};
      `};

    ${({ isValid, value }) =>
      value &&
      isValid &&
      css`
        border-width: 2px 2px 2px 1px;
        border-style: solid;
        border-color: ${Color.PRIMARY};
        background: ${Color.WHITE};
        color: ${Color.GREY_100};
      `};

    &:focus,
    &:active {
      & ~ span {
        display: none;
      }
    }
  `,
  Placeholder: styled.span`
    position: absolute;
    top: calc(50% - 2px);
    left: calc(50%);
    height: 2px;
    width: 10px;
    background-color: ${Color.DISABLED};
    pointer-events: none;
  `,
  ErrorMessage: styled.p`
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: ${Color.PINK};
    margin-top: 6px;

    & svg {
      width: 18px;
      height: 18px;
      margin-right: 4px;
    }
  `,
  Suffix: styled.span<THeightProps>`
    position: absolute;
    top: 23px;
    right: ${({ isMetric }) => (isMetric ? '32px' : '16px')};
    height: 22px;
    width: 13px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: ${Color.DISABLED};
  `,
}
