import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import {
  selectHasInAppOffer,
  selectHasUpsellOffer,
} from 'root-redux/selects/common'
import { selectUserStatus } from 'root-redux/selects/user'

import { useGetPageInfo } from 'hooks/useGetPageInfo'

import { PaymentMethod } from 'modules/purchase/constants'
import { selectPaymentMethod } from 'modules/purchase/redux/selects'

import { PageId } from 'page-constants'

export const useUserStatus = () => {
  const userStatus = useSelector(selectUserStatus)
  const hasUpsellOffer = useSelector(selectHasUpsellOffer)
  const hasInAppOffer = useSelector(selectHasInAppOffer)
  const paymentMethod = useSelector(selectPaymentMethod)
  const isPrimerAvailable = useFeatureIsOn('fas_806_primer_paypal')

  const { steps, currentSubscriptionPageId, currentUpsellPaywallPageId } =
    useGetPageInfo()

  const isInAppUnavailable = useMemo(
    () =>
      !hasInAppOffer ||
      !userStatus?.inapp.isInAppAvailable ||
      userStatus?.inapp.isInAppTried,
    [hasInAppOffer, userStatus],
  )

  const isUpsellUnavailable = useMemo(
    () =>
      !hasUpsellOffer ||
      !userStatus?.upsell.isUpsellAvailable ||
      userStatus?.upsell.isUpsellTried,
    [hasUpsellOffer, userStatus],
  )

  const isUpsellPassed =
    (userStatus?.upsell.isUpsellPurchased && !hasInAppOffer) ||
    (userStatus?.upsell.isUpsellTried && !hasInAppOffer)

  const isInAppPassed =
    userStatus?.inapp.isInAppPurchased || userStatus?.inapp.isInAppTried

  const upsellAndInAppInactive =
    userStatus?.hasSubscription && isUpsellUnavailable && isInAppUnavailable

  const upsellAndInAppActive =
    (isPrimerAvailable && paymentMethod === PaymentMethod.PAYPAL) ||
    hasInAppOffer ||
    userStatus?.inapp.isInAppAvailable ||
    !userStatus?.inapp.isInAppTried ||
    hasUpsellOffer ||
    userStatus?.upsell.isUpsellAvailable ||
    !userStatus?.upsell.isUpsellTried

  if (userStatus?.account.hasAccount && userStatus?.hasSubscription) {
    return PageId.DOWNLOAD
  }

  if (isUpsellPassed || isInAppPassed || upsellAndInAppInactive) {
    return PageId.FINISHING_TOUCHES
  }

  if (userStatus?.hasSubscription && upsellAndInAppActive) {
    return currentUpsellPaywallPageId
  }

  if (userStatus?.email.hasEmail) {
    return currentSubscriptionPageId || steps[0].id
  }

  return '' as PageId
}
