import styled from 'styled-components'

import { Option } from 'components/Option'

import checkIcon from 'assets/images/check-white.svg'

import { Color } from 'root-constants'

export const StyledAccordionItem = {
  ItemContainer: styled.div<{ isActive: boolean }>`
    display: flex;
    flex-direction: column;
    border: ${({ isActive }) =>
      isActive ? `2px solid ${Color.PRIMARY}` : `1px solid ${Color.GREY}`};
    border-radius: 12px;

    &:not(:last-of-type) {
      margin: 0 0 16px 0;
    }
  `,
  Option: styled(Option)`
    margin: 0;
    padding: 20px 16px;
  `,
  TitleBlock: styled.div<{ isSelected: boolean }>`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:after {
      content: '';
      width: 20px;
      height: 20px;
      mix-blend-mode: normal;
      border: 1.5px solid rgba(19, 29, 48, 0.1);
      border-radius: 50%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: background-color 0.3s ease-out;
      ${({ isSelected }) =>
        isSelected &&
        `
          background-image: url(${checkIcon});
          background-position: center center;
          background-repeat: no-repeat;
          background-color: #8cd9ae;
          border: 2px solid ${Color.PRIMARY};
        `};
    }
  `,
  Title: styled.p`
    font-size: 16px;
    line-height: 24px;
    padding: 0 0 0 28px;
  `,
  ImageContainer: styled.div`
    aspect-ratio: 29/6;
    height: 24px;
    display: flex;
    justify-content: flex-end;

    > :not(:last-of-type) {
      margin: 0 4px 0 0;
    }
  `,
  ContentBlock: styled.div<{ isSelected?: boolean }>`
    ${({ isSelected }) => (isSelected ? 'display: block;' : 'display: none;')}
    margin: 0 16px 0 16px;
    padding: 16px 0 16px 0;

    ${({ isSelected }) =>
      isSelected && `border-top: 1px solid ${Color.GREY_300};`}
  `,
}
