import { IRequestOptions, IResponseResult } from 'models/api.model'
import { IAppConfigResponseRaw, IPaymentConfigRaw } from 'models/config.model'

import { ApiService } from 'services/api.service'

import { API_APP_NAME, Cohort, RequestMethod } from 'root-constants'

export class ConfigApi {
  private api: ApiService

  constructor(api: ApiService) {
    this.api = api
  }

  getAppConfig(): Promise<IResponseResult<IAppConfigResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.GET,
    }

    return this.api.makeRequest<IAppConfigResponseRaw>(
      `v1/flow/${API_APP_NAME}/config`,
      options,
    )
  }

  getPaymentConfig({
    cohort,
    uuid,
  }: {
    cohort: Cohort
    uuid: string
  }): Promise<IResponseResult<IPaymentConfigRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.GET,
      params: { uuid },
    }

    return this.api.makeRequest<IPaymentConfigRaw>(
      `v1/flow/${API_APP_NAME}/variant/${cohort}/payment-config`,
      options,
    )
  }
}
