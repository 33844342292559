import { Cohort } from 'root-constants'

export const COHORTS_BASED_ON_INTRO2 = [
  Cohort.MF_INTRO_2,
  Cohort.MF_INFLUENCER,
  Cohort.MF_FLOW_2_INTRO_2,
  Cohort.MF_WOMEN_AGE,
  Cohort.MF_WOMEN_AGE_2,
  Cohort.MF_WOMEN_45,
  Cohort.MF_MEN_FLOW,
]
