import styled from 'styled-components'

import { Container } from 'components/Container'

import { Color } from 'root-constants'

export const StyledUpsellPaywallV1 = {
  Root: styled.div`
    margin-top: 23px;
    position: relative;
    padding-bottom: 171px;
  `,
  Container: styled(Container)`
    padding: 0 10px;
  `,
  Title: styled.h1`
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 16px;
    text-align: center;
  `,
  Subtitle: styled.p`
    color: ${Color.DISABLED};
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 20px;
    text-align: center;
  `,
}
