import React, { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { ScaleOptionValue } from 'value-constants'

import { AnswerButtonMini } from 'components/AnswerButtonMini'
import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { Option, OptionType } from 'components/Option'
import { SvgImage } from 'components/SvgImage'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import arrow from 'assets/images/sprite/arrows-down.svg'

import { Cohort } from 'root-constants'

import { StyledStatementQuestion as S } from './StatementQuestion.styles'
import { STATEMENT_QUESTIONS_MAP } from './constants'

export const StatementQuestion: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const [answer, setAnswer] = useState<string>('')

  const handleContinue = useNextStep({
    pageId,
    question: STATEMENT_QUESTIONS_MAP[pageId],
    nextPagePath,
  })

  const handleNextClick = useCallback(
    () => handleContinue(answer),
    [answer, handleContinue],
  )

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        setAnswer(value)
      },
    }),
    [pageId],
  )

  const question = useMemo(
    () =>
      cohortToUse === Cohort.MF_MEN_FLOW
        ? `onboarding.statementQuestionV3.${pageId}.question`
        : `onboarding.statementQuestionV1.${pageId}.question`,
    [cohortToUse, pageId],
  )

  return (
    <>
      <Container>
        <S.TitleContainer>
          <S.Title>{t('commonComponents.statementTitle')}</S.Title>
          <S.SvgWrapper>
            <SvgImage svg={arrow} width={12} height={12} />
          </S.SvgWrapper>
          <S.Question>
            <Trans i18nKey={question} />
          </S.Question>
        </S.TitleContainer>
        <S.OptionsContainer>
          <Option
            marginBottom="0"
            {...optionProps}
            value={ScaleOptionValue.ONE}
          >
            <AnswerButtonMini>1</AnswerButtonMini>
          </Option>
          <Option
            marginBottom="0"
            {...optionProps}
            value={ScaleOptionValue.TWO}
          >
            <AnswerButtonMini>2</AnswerButtonMini>
          </Option>
          <Option
            marginBottom="0"
            {...optionProps}
            value={ScaleOptionValue.THREE}
          >
            <AnswerButtonMini>3</AnswerButtonMini>
          </Option>
          <Option
            marginBottom="0"
            {...optionProps}
            value={ScaleOptionValue.FOUR}
          >
            <AnswerButtonMini>4</AnswerButtonMini>
          </Option>
          <Option
            marginBottom="0"
            {...optionProps}
            value={ScaleOptionValue.FIVE}
          >
            <AnswerButtonMini>5</AnswerButtonMini>
          </Option>
        </S.OptionsContainer>
        <S.Scale>
          <span>{t('commonComponents.lowestScaleValue')}</span>
          <span>{t('commonComponents.highestScaleValue')}</span>
        </S.Scale>
      </Container>
      <S.ButtonContainer>
        <Button onClick={handleNextClick} disabled={!answer}>
          {t('actions.continue')}
        </Button>
      </S.ButtonContainer>
    </>
  )
}
