import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { StyledAppReachedV2 as S } from './AppReached.styles'

type TProps = {
  className?: string
  children?: React.ReactNode
}

export const AppReachedV2: React.FC<TProps> = ({ className, children }) => {
  const { t } = useTranslation()

  return (
    <S.Container className={className}>
      <S.TextContainer>
        <S.Title>
          <Trans
            i18nKey="purchase.appReached.titleV2"
            components={{ span: <span /> }}
          />
        </S.Title>
      </S.TextContainer>
      <S.Subtitle>{t('purchase.appReached.subtitleV2')}</S.Subtitle>
      {children}
    </S.Container>
  )
}
