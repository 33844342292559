import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { Spinner } from 'components/Spinner'

import {
  resetErrorAction,
  setSubscriptionListAction,
  stopFetching,
} from 'root-redux/actions/common'

import { useAmplitudeInitialization } from 'hooks/useAmplitudeInitialization'
import { useCommonStore } from 'hooks/useCommonStore'
import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { usePaymentStore } from 'hooks/usePaymentStore'
import { useStripeButton } from 'hooks/useStripeButton'

import { createProductId } from 'helpers/createProductId'

import {
  PaymentWaitingModal,
  ThreeDSecureIframe,
} from 'modules/purchase/components'
import { PaymentForms } from 'modules/purchase/components/PaymentForms'
import { PaymentMethod } from 'modules/purchase/constants'
import { CHECK_PAYMENT_REQUEST_BUTTON } from 'modules/purchase/redux/actions/common'
import {
  selectIsCheckoutFlowShown,
  selectOneClickPaymentMethod,
} from 'modules/purchase/redux/selects'

import { eventLogger } from 'services/eventLogger.service'

import { goTo, replaceHistory } from 'browser-history'
import { PageId } from 'page-constants'
import { ScreenName } from 'root-constants'

import { StyledCheckoutVariant4 as S } from './CheckoutVariant4.styles'

export const CheckoutVariant4 = () => {
  const { search } = useLocation()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { currentSubscriptionPageId } = useGetPageInfo()
  const isCheckoutFlowShown = useSelector(selectIsCheckoutFlowShown)
  const oneClickPaymentMethod = useSelector(selectOneClickPaymentMethod)

  const {
    fetchingActionsList,
    cohort,
    screenName,
    stripeAccountId,
    stripeAccountName,
  } = useCommonStore()

  const {
    periodName,
    periodQuantity,
    threeDSecureIframeUrl,
    trialCurrentPrice,
    fullPrice,
    selectedSubscriptionId,
  } = usePaymentStore()

  useAmplitudeInitialization(cohort, ScreenName.CHECKOUT)
  useStripeButton()

  const [isPaymentWaitingShown, setIsPaymentWaitingShown] = useState(false)
  const [activeMethod, setActiveMethod] = useState<PaymentMethod>(
    PaymentMethod.CREDIT_CARD,
  )

  const productId = useMemo(
    () =>
      createProductId({
        periodName,
        periodQuantity,
        price: trialCurrentPrice || fullPrice,
      }),
    [periodName, periodQuantity, trialCurrentPrice, fullPrice],
  )

  const isCheckoutReady = useMemo(
    () =>
      !fetchingActionsList.includes(CHECK_PAYMENT_REQUEST_BUTTON) &&
      isCheckoutFlowShown,
    [fetchingActionsList, isCheckoutFlowShown],
  )
  const isSubscriptionTrial = useMemo(
    () => trialCurrentPrice !== fullPrice,
    [trialCurrentPrice, fullPrice],
  )

  useLayoutEffect(() => {
    dispatch(stopFetching(CHECK_PAYMENT_REQUEST_BUTTON))

    if (selectedSubscriptionId) return

    replaceHistory({
      pathname: currentSubscriptionPageId,
      search,
    })
  }, [dispatch, currentSubscriptionPageId, search, selectedSubscriptionId])

  useEffect(() => {
    oneClickPaymentMethod && setActiveMethod(oneClickPaymentMethod)
  }, [oneClickPaymentMethod])

  useEffect(() => {
    if (productId) {
      eventLogger.logPurchaseShown({
        productId,
        screenName,
        stripeAccountId,
        stripeAccountName,
      })
    }
  }, [productId, screenName, stripeAccountId, stripeAccountName])

  const handleCloseCheckout = useCallback(() => {
    dispatch(resetErrorAction())
    dispatch(setSubscriptionListAction([]))

    eventLogger.logPurchaseScreenClosed({
      productId,
      screenName,
    })

    screenName === ScreenName.CANCEL_OFFER
      ? goTo({ pathname: PageId.CANCEL_OFFER_1, search })
      : goTo({ pathname: PageId.CANCEL_OFFER_QUESTION_1, search })
  }, [dispatch, productId, screenName, search])

  return (
    <S.Container>
      {threeDSecureIframeUrl ? (
        <ThreeDSecureIframe />
      ) : (
        <>
          {isCheckoutReady && (
            <>
              <S.Title>{t('purchase.checkoutV4.title')}</S.Title>
              <S.CloseButton onClick={handleCloseCheckout} />
              <S.PaymentMethodsV2
                activePaymentMethod={activeMethod}
                onClick={setActiveMethod}
              />
              {isSubscriptionTrial ? (
                <S.TrialCheckoutSummaryV3 />
              ) : (
                <S.CheckoutSummaryV3 />
              )}
              <PaymentForms activePaymentMethod={activeMethod} />
            </>
          )}
          <PaymentWaitingModal
            isPaymentWaitingShown={isPaymentWaitingShown}
            setIsPaymentWaitingShown={setIsPaymentWaitingShown}
          />
          {!isCheckoutReady && <Spinner />}
        </>
      )}
    </S.Container>
  )
}
