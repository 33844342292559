import styled from 'styled-components'

import { PageTitle } from 'components/PageTitle'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledProgramSuite = {
  Title: styled(PageTitle)`
    line-height: 30px;
    margin-bottom: 8px;
  `,
  Subtitle: styled.h2`
    color: ${Color.GREY_100};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 16px;
    text-align: center;
  `,
  Banner: styled.div`
    aspect-ratio: 180 / 131;
    width: 100vw;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: 100%;
    }
  `,
  Info: styled.h3`
    color: ${Color.GREY_100};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 46px;
    text-align: center;

    strong {
      color: #ffa812;
      font-size: 22px;
      font-weight: 700;
    }
  `,
}
