import React, { lazy, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Navigate, useLocation, useRoutes } from 'react-router-dom'

import { Download } from 'pages/download'
import { FinishingTouches } from 'pages/finishingTouches'

import { AnimationProvider } from 'components/AnimationProvider'
import { OnboardingFlow } from 'components/OnboardingFlow'
import { ProtectedRoute } from 'components/ProtectedRoute'

import {
  selectCurrentVariantCohort,
  selectLanguage,
} from 'root-redux/selects/common'
import {
  selectTestEnvironmentQueryParam,
  selectUUID,
} from 'root-redux/selects/user'

import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { useUserStatus } from 'hooks/useUserStatus'

import { getDynamicPageId } from 'helpers/getDynamicPageId'
import { getPathFromPageId } from 'helpers/getPathFromPageId'

import { Login } from 'modules/login'
import { UpsellPaywallProvider } from 'modules/purchase/pages/UpsellPaywall'

import { IStep } from 'models/variant.model'

import { ID_TO_PAGE_MAP, PageId } from 'page-constants'
import {
  DYNAMIC_PAGE_ID_PREFIX,
  POST_PAYMENT_PAGES,
  PUBLIC_PAGES,
  UPSELL_PAYWALL_PAGES,
} from 'root-constants'

const PaymentProvider = lazy(() => import('components/PaymentProvider'))

export const RouteList: React.FC = () => {
  const { search } = useLocation()
  const { i18n } = useTranslation()

  const cohort = useSelector(selectCurrentVariantCohort)
  const uuid = useSelector(selectUUID)
  const testEnvironmentQueryParam = useSelector(selectTestEnvironmentQueryParam)
  const language = useSelector(selectLanguage)

  const availableRoute = useUserStatus()
  const { steps, currentSubscriptionPageId } = useGetPageInfo()

  const firstPagePath = useMemo(
    () =>
      getPathFromPageId({
        pageId: steps[0]?.id,
        cohort,
        uuid,
        testEnvironmentQueryParam,
        language,
        currentSearch: search,
      }),
    [steps, cohort, uuid, testEnvironmentQueryParam, language, search],
  )

  const { onboardingPages, subscriptionPages } = useMemo(
    () =>
      steps.reduce<{
        onboardingPages: IStep[]
        subscriptionPages: IStep[]
      }>(
        (accum, { isSubscriptions, isPayment, id }, ind, arr) => {
          if (isSubscriptions || isPayment) {
            accum.subscriptionPages.push(arr[ind])
            return accum
          }

          if (!POST_PAYMENT_PAGES.includes(id)) {
            accum.onboardingPages.push(arr[ind])
          }

          return accum
        },
        {
          onboardingPages: [],
          subscriptionPages: [],
        },
      ),
    [steps],
  )

  useEffect(() => {
    i18n.changeLanguage(language)
  }, [i18n, language])

  return useRoutes([
    { index: true, element: <Navigate to={firstPagePath} /> },
    {
      element: <OnboardingFlow />,
      children: onboardingPages.map(({ id: pageId }, index, arr) => {
        const currentPageId = pageId.includes(DYNAMIC_PAGE_ID_PREFIX)
          ? getDynamicPageId(pageId)
          : pageId
        const CurrentPage = ID_TO_PAGE_MAP[currentPageId]

        const nextPagePath = getPathFromPageId({
          pageId: arr[index + 1]?.id,
          cohort,
          uuid,
          testEnvironmentQueryParam,
          language,
          currentSearch: search,
        })

        const alternativePageId = arr.find(
          (dynamicPage, dynamicPageIndex) =>
            !dynamicPage.isSkippable && dynamicPageIndex > index,
        )?.id

        const alternativePagePath = getPathFromPageId({
          pageId: alternativePageId || arr[index + 1]?.id,
          cohort,
          uuid,
          testEnvironmentQueryParam,
          language,
          currentSearch: search,
        })

        if (PUBLIC_PAGES.includes(pageId)) {
          return {
            path: pageId,
            element: (
              <AnimationProvider>
                <CurrentPage
                  pageId={pageId}
                  nextPagePath={nextPagePath}
                  alternativePagePath="/"
                />
              </AnimationProvider>
            ),
          }
        }

        return {
          path: pageId,
          element: (
            <ProtectedRoute isAvailable={!availableRoute}>
              <AnimationProvider>
                <CurrentPage
                  pageId={pageId}
                  nextPagePath={nextPagePath}
                  alternativePagePath={alternativePagePath}
                />
              </AnimationProvider>
            </ProtectedRoute>
          ),
        }
      }),
    },
    ...subscriptionPages.map(({ id: pageId }, index, arr) => {
      const PurchasePage = ID_TO_PAGE_MAP[pageId]
      const nextPagePath = getPathFromPageId({
        pageId: arr[index + 1]?.id,
        cohort,
        uuid,
        testEnvironmentQueryParam,
        language,
        currentSearch: search,
      })

      const alternativePageId = arr.find(
        (dynamicPage, dynamicPageIndex) =>
          !dynamicPage.isSkippable && dynamicPageIndex > index,
      )?.id

      const alternativePagePath = getPathFromPageId({
        pageId: alternativePageId || arr[index + 1]?.id,
        cohort,
        uuid,
        testEnvironmentQueryParam,
        language,
        currentSearch: search,
      })

      return {
        path: pageId,
        element: (
          <ProtectedRoute
            isAvailable={availableRoute === currentSubscriptionPageId}
          >
            <PaymentProvider>
              <PurchasePage
                pageId={pageId}
                nextPagePath={nextPagePath}
                alternativePagePath={alternativePagePath}
              />
            </PaymentProvider>
          </ProtectedRoute>
        ),
      }
    }),
    {
      path: PageId.UPSELL_PAYWALL_1,
      element: (
        <ProtectedRoute
          isAvailable={availableRoute === PageId.UPSELL_PAYWALL_1}
        >
          <PaymentProvider>
            <AnimationProvider>
              <UpsellPaywallProvider />
            </AnimationProvider>
          </PaymentProvider>
        </ProtectedRoute>
      ),
    },
    {
      path: PageId.UPSELL_PAYWALL_2,
      element: (
        <ProtectedRoute
          isAvailable={availableRoute === PageId.UPSELL_PAYWALL_2}
        >
          <PaymentProvider>
            <AnimationProvider>
              <UpsellPaywallProvider />
            </AnimationProvider>
          </PaymentProvider>
        </ProtectedRoute>
      ),
    },
    {
      path: PageId.FINISHING_TOUCHES,
      element: (
        <ProtectedRoute
          isAvailable={
            UPSELL_PAYWALL_PAGES.includes(availableRoute) ||
            availableRoute === PageId.FINISHING_TOUCHES
          }
        >
          <AnimationProvider>
            <FinishingTouches />
          </AnimationProvider>
        </ProtectedRoute>
      ),
    },
    {
      path: PageId.LOGIN,
      element: (
        <ProtectedRoute
          isAvailable={
            UPSELL_PAYWALL_PAGES.includes(availableRoute) ||
            availableRoute === PageId.FINISHING_TOUCHES
          }
        >
          <AnimationProvider>
            <Login />
          </AnimationProvider>
        </ProtectedRoute>
      ),
    },
    {
      path: PageId.DOWNLOAD,
      element: (
        <ProtectedRoute isAvailable={availableRoute === PageId.DOWNLOAD}>
          <AnimationProvider>
            <Download />
          </AnimationProvider>
        </ProtectedRoute>
      ),
    },
    { path: '*', element: <Navigate to={firstPagePath} /> },
  ])
}
