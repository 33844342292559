import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useUsersBmi } from 'hooks/useUsersBmi'

import {
  LOWER_BMI_THRESHOLD_V1,
  PERCENTAGE_MULTIPLIER,
  UPPER_BMI_THRESHOLD,
} from 'root-constants'

import {
  Content,
  Header,
  HeaderLabel,
  HeaderSubtitle,
  HeaderTitle,
  RangeThresholds,
  RangeThumb,
  RangeTrack,
  RangeValue,
  StyledPersonalSummary as S,
  Title,
} from './PersonalSummary.styles'
import { BMI_VALUE_TO_MARKUP_MAP, MAX_BMI_VALUE } from './constants'

export const PersonalSummary = () => {
  const { t } = useTranslation()

  const { currentBMI } = useUsersBmi()

  const rangeThumbShift = useMemo(() => {
    if (currentBMI.bmiIndex >= UPPER_BMI_THRESHOLD) return MAX_BMI_VALUE
    if (currentBMI.bmiIndex <= LOWER_BMI_THRESHOLD_V1) return 0

    return (
      (Math.floor(currentBMI.bmiIndex - LOWER_BMI_THRESHOLD_V1) /
        (UPPER_BMI_THRESHOLD - LOWER_BMI_THRESHOLD_V1)) *
      PERCENTAGE_MULTIPLIER
    )
  }, [currentBMI.bmiIndex])

  return (
    <S.Root>
      <Title>{t('result.personalSummary.title')}</Title>
      <Content>
        <Header>
          <S.HeaderContent>
            <HeaderTitle>{t('result.personalSummary.headerTitle')}</HeaderTitle>
            <HeaderSubtitle>
              {t('result.personalSummary.headerSubtitle')}
            </HeaderSubtitle>
          </S.HeaderContent>
          <HeaderLabel
            color={BMI_VALUE_TO_MARKUP_MAP[currentBMI.bmiValue.value].textColor}
            backgroundColor={
              BMI_VALUE_TO_MARKUP_MAP[currentBMI.bmiValue.value].backgroundColor
            }
          >
            {t(BMI_VALUE_TO_MARKUP_MAP[currentBMI.bmiValue.value].value)}
          </HeaderLabel>
        </Header>
        <RangeTrack>
          <RangeThumb
            backgroundColor={
              BMI_VALUE_TO_MARKUP_MAP[currentBMI.bmiValue.value].textColor
            }
            left={`${rangeThumbShift}%`}
          >
            <RangeValue>
              {Number.isNaN(currentBMI.bmiIndex)
                ? '0.0'
                : currentBMI.bmiIndex.toFixed(1)}
            </RangeValue>
          </RangeThumb>
        </RangeTrack>
        <RangeThresholds>
          <span>{LOWER_BMI_THRESHOLD_V1}</span>
          <span>{UPPER_BMI_THRESHOLD}</span>
        </RangeThresholds>
      </Content>
    </S.Root>
  )
}
