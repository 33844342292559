import { PaymentMethod } from 'modules/purchase/constants'

import applePay from 'assets/images/apple-pay-icon-v2.png'
import creditCard from 'assets/images/credit-card-icon.png'
import googlePay from 'assets/images/google-pay-v3.png'
import payPal from 'assets/images/pay-pal-icon-v2.png'

export const PAYMENT_METHODS_INITIAL = [
  {
    value: PaymentMethod.CREDIT_CARD,
    icon: creditCard,
  },
  {
    value: PaymentMethod.PAYPAL,
    icon: payPal,
  },
]

export const ADDITIONAL_PAYMENT_METHOD = {
  [PaymentMethod.APPLE_PAY]: {
    value: PaymentMethod.APPLE_PAY,
    icon: applePay,
  },
  [PaymentMethod.GOOGLE_PAY]: {
    value: PaymentMethod.GOOGLE_PAY,
    icon: googlePay,
  },
}
