import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledDynamicRangeTrack = {
  RangeTrack: styled.div`
    width: 100%;
    height: 8px;
    margin: 0 0 16px 0;
    border-radius: 26px;
    position: relative;
    background: linear-gradient(
      90.33deg,
      #b5f58e 6.06%,
      #caf193 20.82%,
      #ffe79d 38.69%,
      #fec66f 62.96%,
      #ff8e78 77.79%,
      #ff7b7b 93.07%
    ); ;
  `,
  RangeThumb: styled.div<{ percentage: number }>`
    width: 28px;
    height: 28px;
    border: 9px solid ${Color.WHITE};
    box-shadow: 0 4px 12px 0 #00000029;
    border-radius: 50%;
    background-color: ${Color.DARK_BLUE};
    position: absolute;
    top: -10px;
    transform: translateX(-10px);
    left: 0;
    transition: left 2s linear;

    &.is-started {
      left: ${({ percentage }) => percentage}%;
    }
  `,
  RangeValue: styled.div<{ percentage: number }>`
    position: absolute;
    transform: translateX(-17px);
    transition: left 2s linear;
    left: 0;
    top: -40px;
    background: ${Color.DARK_BLUE};
    border-radius: 32px;
    padding: 5px 9px;
    color: ${Color.WHITE};
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;

    &.is-started {
      left: ${({ percentage }) => percentage}%;
    }

    &::after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      bottom: -4px;
      left: calc(50% - 4px);
      border-width: 4px 4px 0 4px;
      border-color: ${Color.GREY_100} transparent transparent transparent;
    }
  `,
  IndexThresholds: styled.div`
    display: flex;
    position: relative;
  `,
  IndexScale: styled.span<{ percentage: number }>`
    bottom: 14px;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    color: ${Color.DISABLED};
    position: absolute;
    left: ${({ percentage }) => percentage}%;
    padding: 0 0 2px 0;

    &::before {
      content: '';
      position: absolute;
      height: 10px;
      width: 1px;
      background-color: ${Color.GREY};
      left: 50%;
      bottom: -100%;
    }
  `,
  ValueThresholds: styled.div`
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: ${Color.DISABLED};
    text-transform: uppercase;
  `,
}
