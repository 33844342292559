export const REVIEWS_DATA_V1 = [
  {
    userName: 'onboarding.sliderV1.reviewFirst.name',
    reviewText: 'onboarding.sliderV1.reviewFirst.text',
  },
  {
    userName: 'onboarding.sliderV1.reviewSecond.name',
    reviewText: 'onboarding.sliderV1.reviewSecond.text',
  },
  {
    userName: 'onboarding.sliderV1.reviewThird.name',
    reviewText: 'onboarding.sliderV1.reviewThird.text',
  },
]

export const REVIEWS_DATA_V2 = [
  {
    userName: 'onboarding.sliderV2.reviewFirst.name',
    reviewText: 'onboarding.sliderV2.reviewFirst.text',
  },
  {
    userName: 'onboarding.sliderV2.reviewSecond.name',
    reviewText: 'onboarding.sliderV2.reviewSecond.text',
  },
  {
    userName: 'onboarding.sliderV2.reviewThird.name',
    reviewText: 'onboarding.sliderV2.reviewThird.text',
  },
]
