import styled from 'styled-components'

import { Container } from 'components/Container'

import pageBg from 'assets/images/users-choice-bg.jpg'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledFeedback = {
  Wrapper: styled.div`
    padding: 8px 0 0 0;
    background-image: url(${pageBg});
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top center;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      background-size: 360px auto;
    }
  `,
  Container: styled(Container)`
    max-width: 100%;
    margin: 0;
    padding: 0 16px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
    }
  `,
  Title: styled.h1`
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: ${Color.WHITE};
    margin: 0 0 24px 0;
  `,
  FeedbackContainer: styled.div`
    display: flex;
    flex-direction: column;
    max-width: 328px;
    background-color: ${Color.WHITE};
    box-shadow: 0 8px 20px rgba(16.98, 29.75, 22.64, 0.1);
    border-radius: 16px;
    margin: 0 0 36px 0;
  `,
  PhotosContainer: styled.div`
    display: flex;
    justify-content: space-between;
    column-gap: 2px;
    width: 100%;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    overflow: hidden;

    aspect-ratio: 328/205;

    img {
      width: 50%;
    }
  `,
  InfoContainer: styled.div`
    padding: 16px;
  `,
  FeedbackText: styled.p`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: ${Color.DARK_BLUE};
    margin: 0 0 10px 0;
  `,
  FeedbackInfo: styled.div`
    display: flex;
    align-items: center;
  `,
  LostWeight: styled.p`
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: ${Color.DARK_BLUE};
  `,
  Rating: styled.div`
    display: flex;
    justify-content: space-between;
    aspect-ratio: 49 / 9;
    height: 18px;
    margin: 0 0 0 6px;
  `,
}
