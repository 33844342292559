import React, {
  InputHTMLAttributes,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from 'react'

import checkIconSvg from 'assets/images/sprite/check-icon.svg'
import crossIconSvg from 'assets/images/sprite/cross-icon.svg'

import { StyledInput as S } from './InputWithValidationIcons.styles'

type TEmailInputProps = InputHTMLAttributes<HTMLInputElement> & {
  isValid: boolean
  label: string
  shortLabel?: string
  className?: string
  value: string
  onChange: (event: SyntheticEvent<HTMLInputElement>) => void
  onFocus: () => void
  onBlur: () => void
}

export const InputWithValidationIcons: React.FC<TEmailInputProps> = ({
  value,
  label,
  shortLabel,
  className,
  isValid,
  onFocus,
  onChange,
  onBlur,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false)

  const handleFocus = useCallback(() => {
    setIsFocused(true)
    onFocus()
  }, [onFocus])

  const handleBlur = useCallback(() => {
    setIsFocused(false)
    onBlur()
  }, [onBlur])

  const labelToShow = useMemo(() => {
    if (shortLabel) {
      return value || isFocused ? shortLabel : label
    }
    return label
  }, [isFocused, label, shortLabel, value])

  return (
    <S.InputContainer className={className}>
      <S.Input
        value={value}
        isValid={isValid}
        label={labelToShow}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...props}
      />
      {value && !isValid && <S.ValidationIcon svg={crossIconSvg} width={24} />}
      {value && isValid && <S.ValidationIcon svg={checkIconSvg} width={24} />}
    </S.InputContainer>
  )
}
