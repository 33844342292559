import React from 'react'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { Option, OptionType } from 'components/Option'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { useDynamicPage } from 'hooks/useDynamicPage'

import { TPageProps } from 'models/common.model'

import { StyledOptionsLeftToRightV2 as S } from './styles'

// This component is developed according new design system (it's in progress)
// For options with images and OptionType.CHECKBOX we don't have actual design
// Please check it before using
export const OptionsLeftToRightV2: React.FC<TPageProps> = (props) => {
  const {
    title,
    subtitle,
    buttonText,
    optionType,
    optionData,
    optionProps,
    answers,
    isAnswersDisabled,
    handleContinue,
  } = useDynamicPage(props)

  return (
    <Container>
      <S.PageTitle marginBottom={subtitle ? 8 : 24}>{title}</S.PageTitle>
      {!!subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}
      {optionType === OptionType.RADIO &&
        optionData?.map(
          ({
            id,
            value,
            text,
            additionalText,
            imageUrl,
            imageWidth,
            imageHeight,
          }) => (
            <Option
              {...optionProps}
              key={id}
              value={value}
              disabled={isAnswersDisabled}
              marginBottom="12"
            >
              <S.AnswerButton
                iconSrc={imageUrl}
                iconWidth={imageWidth || 56}
                iconHeight={imageHeight || 56}
              >
                <>
                  <h3>{text}</h3>
                  {!!additionalText && <p>{additionalText}</p>}
                </>
              </S.AnswerButton>
            </Option>
          ),
        )}
      {optionType === OptionType.CHECKBOX &&
        optionData?.map(
          ({
            id,
            value,
            text,
            additionalText,
            imageUrl,
            imageWidth,
            imageHeight,
          }) => (
            <Option
              {...optionProps}
              key={id}
              value={value}
              disabled={isAnswersDisabled}
              marginBottom="12"
            >
              <S.AnswerButton
                iconSrc={imageUrl}
                iconWidth={imageWidth || 56}
                iconHeight={imageHeight || 56}
              >
                <>
                  <h3>{text}</h3>
                  {!!additionalText && <p>{additionalText}</p>}
                </>
              </S.AnswerButton>
            </Option>
          ),
        )}
      {optionType === OptionType.CHECKBOX && (
        <StickyButtonContainer>
          <Button
            type="button"
            disabled={!answers.length}
            onClick={() => handleContinue(answers)}
          >
            {buttonText}
          </Button>
        </StickyButtonContainer>
      )}
    </Container>
  )
}
