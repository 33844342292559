import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { setAnswersAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'

import { IStepInfo, TAnswer } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'

export const useNextStep = ({
  pageId,
  question,
  nextPagePath,
}: IStepInfo): ((value: TAnswer, designation?: TAnswer) => void) => {
  const dispatch = useDispatch()

  return useCallback(
    (value: TAnswer, designation?: TAnswer) => {
      dispatch(
        setAnswersAction({
          answers: value,
          pageId,
        }),
      )
      dispatch(sendUserAnswersAction())
      eventLogger.logQuestion({
        question,
        answers: designation ? `${value},${designation}` : value,
      })

      goTo(nextPagePath)
    },
    [dispatch, question, pageId, nextPagePath],
  )
}
