import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { selectCurrentUserName } from 'root-redux/selects/common'

import { TAnswer } from 'models/common.model'

import { ALPHABETIC_SYMBOLS_REGEXP } from 'root-constants'

import { MIN_NAME_LENGTH } from '../constants'

export const useNameField = (): {
  userName: TAnswer
  handleNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  isUserNameValid: boolean
} => {
  const currentUserName = useSelector(selectCurrentUserName)

  const [userName, setUserName] = useState<{
    value: TAnswer
    isValid: boolean
  }>({ value: '', isValid: false })

  useEffect(() => {
    if (currentUserName) {
      setUserName({
        value: currentUserName,
        isValid: true,
      })
    }
  }, [currentUserName])

  const handleNameChange = useCallback(
    ({
      target: { value, validity },
    }: {
      target: { value: string; validity: { valid: boolean } }
    }) => {
      const isNameValid = !value.match(ALPHABETIC_SYMBOLS_REGEXP)
      if (isNameValid) {
        setUserName({
          value,
          isValid:
            validity.valid &&
            !!value.trim() &&
            value.trim().length > MIN_NAME_LENGTH,
        })
      }
    },
    [],
  )

  return {
    userName: userName.value,
    handleNameChange,
    isUserNameValid: userName.isValid,
  }
}
