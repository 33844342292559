import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import lottie from 'lottie-web/build/player/lottie_light'

import { resetErrorAction } from 'root-redux/actions/common'
import { getUserStatusAction } from 'root-redux/actions/user'
import { selectActionList, selectError } from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import { useLockScroll } from 'hooks/useLockScroll'

import { AnimationState, PaymentMethod } from 'modules/purchase/constants'

import paymentErrorAnimation from 'assets/animations/paymentErrorAnimation.json'
import paymentSuccessAnimation from 'assets/animations/paymentSuccessAnimation.json'
import paymentWaitingAnimation from 'assets/animations/paymentWaitingAnimation.json'

import { CHECK_3D_SECURE, PURCHASE } from '../../redux/actions/common'
import {
  select3DSecureIframeUrl,
  selectPaymentMethod,
} from '../../redux/selects'
import { StyledPaymentWaitingModal as S } from './PaymentWaitingModal.styles'

type TProps = {
  isPaymentWaitingShown: boolean
  setIsPaymentWaitingShown: (value: boolean) => void
}

export const PaymentWaitingModal: React.FC<TProps> = ({
  isPaymentWaitingShown,
  setIsPaymentWaitingShown,
}) => {
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()

  const rootRef = useRef<HTMLDivElement>(null)
  const loadingAnimationRef = useRef<HTMLDivElement | null>(null)
  const completeAnimationRef = useRef<HTMLDivElement | null>(null)
  const errorAnimationRef = useRef<HTMLDivElement | null>(null)

  const uuid = useSelector(selectUUID)
  const fetchingActionsList = useSelector(selectActionList)
  const error = useSelector(selectError)
  const threeDSecureIframeURL = useSelector(select3DSecureIframeUrl)
  const paymentMethod = useSelector(selectPaymentMethod)

  const [animationState, setAnimationState] = useState<AnimationState>(
    AnimationState.LOADING,
  )

  const isPurchaseInProcess = useMemo(
    () =>
      fetchingActionsList.includes(PURCHASE) ||
      fetchingActionsList.includes(CHECK_3D_SECURE),
    [fetchingActionsList],
  )

  useEffect(() => {
    if (isPurchaseInProcess) {
      setIsPaymentWaitingShown(true)
    }

    if (isPurchaseInProcess && loadingAnimationRef.current) {
      lottie.loadAnimation({
        container: loadingAnimationRef.current,
        animationData: paymentWaitingAnimation,
        loop: true,
      })
    }

    if (!isPurchaseInProcess && isPaymentWaitingShown && error) {
      setAnimationState(AnimationState.ERROR)
    }

    if (
      !isPurchaseInProcess &&
      isPaymentWaitingShown &&
      !error &&
      !threeDSecureIframeURL
    ) {
      setAnimationState(AnimationState.SUCCESS)
    }

    return () => lottie.destroy()
  }, [
    error,
    isPurchaseInProcess,
    isPaymentWaitingShown,
    setIsPaymentWaitingShown,
    threeDSecureIframeURL,
  ])

  useEffect(() => {
    switch (animationState) {
      case AnimationState.SUCCESS:
        if (completeAnimationRef.current) {
          lottie.loadAnimation({
            container: completeAnimationRef.current,
            animationData: paymentSuccessAnimation,
            loop: false,
          })
        }
        break
      case AnimationState.ERROR:
        if (errorAnimationRef.current) {
          lottie.loadAnimation({
            container: errorAnimationRef.current,
            animationData: paymentErrorAnimation,
            loop: false,
          })
        }
        break
      default:
        if (loadingAnimationRef.current) {
          lottie.loadAnimation({
            container: loadingAnimationRef.current,
            animationData: paymentWaitingAnimation,
            loop: true,
          })
        }
        break
    }

    return () => lottie.destroy()
  }, [animationState, setIsPaymentWaitingShown, uuid, dispatch])

  useEffect(() => {
    if (isPaymentWaitingShown && rootRef.current) {
      rootRef.current.focus()
    }
  }, [isPaymentWaitingShown])

  const handleSuccessButtonClick = useCallback(() => {
    dispatch(getUserStatusAction(uuid))
    setIsPaymentWaitingShown(false)
  }, [dispatch, uuid, setIsPaymentWaitingShown])

  const handleResetError = useCallback(() => {
    setIsPaymentWaitingShown(false)
    setAnimationState(AnimationState.LOADING)
    dispatch(resetErrorAction())
  }, [dispatch, setIsPaymentWaitingShown])

  useLockScroll(isPaymentWaitingShown)

  return (
    <S.Wrapper isShown={isPaymentWaitingShown} ref={rootRef} tabIndex={-1}>
      <S.Content>
        <S.LottieContainer>
          {animationState === AnimationState.LOADING && (
            <>
              <S.Animation ref={loadingAnimationRef} />
              <S.Title>{t('purchase.paymentWaiting.processing')}</S.Title>
              <S.Subtitle>
                {t('purchase.paymentWaiting.processingPayment')}
              </S.Subtitle>
            </>
          )}
          {animationState === AnimationState.SUCCESS && (
            <>
              <S.Animation ref={completeAnimationRef} />
              <S.Title>
                {t('purchase.paymentWaiting.paymentWasSuccessful')}
              </S.Title>
              {paymentMethod === PaymentMethod.PAYPAL && (
                <S.Disclaimer>
                  {t('purchase.paymentWaiting.disclaimerForSuccessfulPayment')}
                </S.Disclaimer>
              )}
              <S.SuccessButton type="button" onClick={handleSuccessButtonClick}>
                {t('purchase.paymentWaiting.toLoginPage')}
              </S.SuccessButton>
            </>
          )}
          {animationState === AnimationState.ERROR && (
            <>
              <S.Animation ref={errorAnimationRef} />
              <S.Subtitle>
                {t('purchase.paymentWaiting.errorDuringPayment')}
              </S.Subtitle>
              <S.Info>
                {error?.description ||
                  error ||
                  t('purchase.paymentWaiting.defaultError')}
              </S.Info>
              <S.ErrorButton type="button" onClick={handleResetError}>
                {t('actions.tryAgain')}
              </S.ErrorButton>
            </>
          )}
        </S.LottieContainer>
      </S.Content>
    </S.Wrapper>
  )
}
