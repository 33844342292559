import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { Logo } from 'components/Logo'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import columbiaLogo from 'assets/images/columbia-university-logo.png'
import mayoLogo from 'assets/images/mayo-clinic-logo.png'
import graph from 'assets/images/sprite/yo-yo-chart.svg'

import { StyledYoEffectV2 as S } from './YoEffectV2.styles'
import { QUESTION } from './constants'

export const YoEffectV2: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <Logo />
      <S.Container>
        <S.Title>{t('onboarding.yoEffect.title')}</S.Title>
        <S.Image svg={graph} />
        <S.CardsWrapper>
          <S.Card>
            <S.CardIconFirst src={columbiaLogo} alt="university logo" />
            <S.CardText>
              <Trans i18nKey="onboarding.yoEffect.option1" />
            </S.CardText>
          </S.Card>
          <S.Card>
            <S.CardIconSecond src={mayoLogo} alt="clinic logo" />
            <S.CardText>
              <Trans i18nKey="onboarding.yoEffect.option2" />
            </S.CardText>
          </S.Card>
        </S.CardsWrapper>
        <StickyButtonContainer>
          <Button type="button" onClick={() => handleContinue('')}>
            {t('actions.continue')}
          </Button>
        </StickyButtonContainer>
      </S.Container>
    </S.Wrapper>
  )
}
