import React, { useCallback, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

import { Button } from 'components/Button'
import { PageTitle } from 'components/PageTitle'
import { PresaleGraph } from 'components/PresaleGraph'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useGetPageInfo } from 'hooks/useGetPageInfo'

import { PurchasePageType } from 'modules/purchase/constants'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'

import { StyledPresaleV1 as S } from './PresaleV1.styles'

export const PresaleV1: React.FC = () => {
  const { t } = useTranslation()
  const { search } = useLocation()

  const { title } = useDynamicOBConfig()
  const { currentSubscriptionPageId } = useGetPageInfo()

  useEffect(() => {
    eventLogger.logPresalePageShown(PurchasePageType.WEB_LANDING)
  }, [])

  const handleNextClick = useCallback(() => {
    eventLogger.logPresalePageCompleted()
    goTo({ pathname: currentSubscriptionPageId, search })
  }, [currentSubscriptionPageId, search])

  return (
    <S.PageContainer>
      <PageTitle marginBottom={24}>
        {title || <Trans i18nKey="presale.title" />}
      </PageTitle>
      <S.UserInfoBlock />
      <PresaleGraph />
      <StickyButtonContainer>
        <Button type="button" onClick={handleNextClick}>
          {t('actions.continue')}
        </Button>
      </StickyButtonContainer>
    </S.PageContainer>
  )
}
