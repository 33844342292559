import { IdealWeightTimeValue } from 'value-constants'

export const FEEDBACK_MAP = {
  [IdealWeightTimeValue.LESS_THAN_YEAR_AGO]: {
    title: 'onboarding.idealWeightFeedbackV1.lessThanYearAgo.title',
    info: 'onboarding.idealWeightFeedbackV1.lessThanYearAgo.info',
    callToAction:
      'onboarding.idealWeightFeedbackV1.lessThanYearAgo.callToAction',
  },
  [IdealWeightTimeValue.ONE_TWO_YEARS_AGO]: {
    title: 'onboarding.idealWeightFeedbackV1.oneTwoYearsAgo.title',
    info: 'onboarding.idealWeightFeedbackV1.oneTwoYearsAgo.info',
    callToAction:
      'onboarding.idealWeightFeedbackV1.oneTwoYearsAgo.callToAction',
  },
  [IdealWeightTimeValue.MORE_THAN_THREE_YEARS_AGO]: {
    title: 'onboarding.idealWeightFeedbackV1.moreThanThreeYearsAgo.title',
    info: 'onboarding.idealWeightFeedbackV1.moreThanThreeYearsAgo.info',
    callToAction:
      'onboarding.idealWeightFeedbackV1.moreThanThreeYearsAgo.callToAction',
  },
  [IdealWeightTimeValue.NEVER]: {
    title: 'onboarding.idealWeightFeedbackV1.never.title',
    info: 'onboarding.idealWeightFeedbackV1.never.info',
    callToAction: 'onboarding.idealWeightFeedbackV1.never.callToAction',
  },
}
