import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setAnswersAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'
import {
  selectCurrentUserGender,
  selectCurrentVariantCohortToUse,
} from 'root-redux/selects/common'

import { CustomPageId } from 'page-constants'
import { MEN_COHORTS, WOMEN_COHORTS } from 'root-constants'

export const useGenderCohortInitialization = () => {
  const dispatch = useDispatch()

  const userGender = useSelector(selectCurrentUserGender)
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const isGenderCohort = useMemo(
    () => [...MEN_COHORTS, ...WOMEN_COHORTS].includes(cohortToUse),
    [cohortToUse],
  )

  useEffect(() => {
    if (isGenderCohort) {
      dispatch(
        setAnswersAction({
          answers: userGender,
          pageId: CustomPageId.GENDER,
        }),
      )
      dispatch(sendUserAnswersAction())
    }
  }, [dispatch, isGenderCohort, userGender])
}
