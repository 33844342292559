import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  BENEFITS_LIST,
  LIST_TITLE,
  TITLE_SUBSTRING,
} from 'pages/questions/HelpCalmCravings/constants'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { selectPeriod } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import imgSrc from 'assets/images/help-calm-cravings-banner.png'

import { Language } from 'root-constants'

import { StyledHelpCalmCravings as S } from './HelpCalmCravings.styles'

export const HelpCalmCravings: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const period = useSelector(selectPeriod)

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.helpCalmCravings.title', {
      lng: Language.EN,
      period,
    }),
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  return (
    <Container>
      <S.ImageContainer>
        <img src={imgSrc} alt="banner" />
      </S.ImageContainer>
      <S.PageTitle>
        <Trans
          i18nKey="onboarding.helpCalmCravings.title"
          values={{ period: t(TITLE_SUBSTRING[period]) }}
          components={{ span: <span /> }}
        />
      </S.PageTitle>
      <S.ListDescription>{t(LIST_TITLE[period])}</S.ListDescription>
      <S.List>
        {BENEFITS_LIST.map((item) => (
          <S.ListItem key={item}>{t(item)}</S.ListItem>
        ))}
      </S.List>

      <StickyButtonContainer>
        <Button type="button" onClick={handleNextClick}>
          {t('actions.continue')}
        </Button>
      </StickyButtonContainer>
    </Container>
  )
}
