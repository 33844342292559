import { BmiValue, FastingValue } from 'value-constants'

import femaleNormal from 'assets/images/profile-summary-female-normal.png'
import femaleObese from 'assets/images/profile-summary-female-obese.png'
import femaleOverweight from 'assets/images/profile-summary-female-overweight.png'
import femaleUnderweight from 'assets/images/profile-summary-female-underweight.png'
import maleNormal from 'assets/images/profile-summary-male-normal.png'
import maleObese from 'assets/images/profile-summary-male-obese.png'
import maleOverweight from 'assets/images/profile-summary-male-overweight.png'
import maleUnderweight from 'assets/images/profile-summary-male-underweight.png'

import { Gender } from 'root-constants'

export const FASTING_LEVEL_MAP = {
  [FastingValue.NOTHING]: 'commonComponents.fastingLevel.beginner',
  [FastingValue.HEARD]: 'commonComponents.fastingLevel.intermediate',
  [FastingValue.EXPERIENCED]: 'commonComponents.fastingLevel.advanced',
}

export const BMI_MAP = {
  [BmiValue.UNDERWEIGHT]: 'commonComponents.bmiValues.underweight',
  [BmiValue.NORMAL]: 'commonComponents.bmiValues.normal',
  [BmiValue.OVERWEIGHT]: 'commonComponents.bmiValues.overweight',
  [BmiValue.OBESITY]: 'commonComponents.bmiValues.obesity',
  [BmiValue.EXTREME_OBESITY]: 'commonComponents.bmiValues.extremeObesity',
}

export const IMAGES_MAP = {
  [Gender.MALE]: {
    [BmiValue.UNDERWEIGHT]: maleUnderweight,
    [BmiValue.NORMAL]: maleNormal,
    [BmiValue.OVERWEIGHT]: maleOverweight,
    [BmiValue.OBESITY]: maleObese,
    [BmiValue.EXTREME_OBESITY]: maleObese,
  },
  [Gender.FEMALE]: {
    [BmiValue.UNDERWEIGHT]: femaleUnderweight,
    [BmiValue.NORMAL]: femaleNormal,
    [BmiValue.OVERWEIGHT]: femaleOverweight,
    [BmiValue.OBESITY]: femaleObese,
    [BmiValue.EXTREME_OBESITY]: femaleObese,
  },
}

export const GENDER_MAP = {
  [Gender.MALE]: 'commonComponents.gender.male',
  [Gender.FEMALE]: 'commonComponents.gender.female',
}
