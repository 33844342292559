import styled from 'styled-components'

import primaryCheck from 'assets/images/check-flower.png'
import bgWhatYouGetUrl from 'assets/images/upsell-background.png'

import { Color } from 'root-constants'

export const StyledUpsellBenefits = {
  Root: styled.div`
    position: relative;
  `,
  Wrapper: styled.div`
    margin: 0 -20px;
    padding: 40px 15px 72px 35px;
    background-image: url(${bgWhatYouGetUrl});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  `,
  Title: styled.h2`
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 23px;
  `,
  DetailsTitle: styled.h2`
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    padding: 0 15px 16px 0;
  `,
  List: styled.ul`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `,
  ListItem: styled.li`
    position: relative;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 13px;
    padding: 0 5px 0 36px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      background: url(${primaryCheck}) center no-repeat;
      background-size: contain;
    }
  `,
  Details: styled.div`
    max-width: 340px;
    margin: 0 auto 40px auto;
  `,
  Detailed: styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    box-shadow: 0 8px 20px #e8e8e8;
    border-radius: 24px;
  `,
  DetailedInfo: styled.div`
    margin: 7px 6px 11px 12px;
  `,
  DetailedPicture: styled.img`
    min-width: 110px;
    min-height: 156px;
    margin-bottom: 12px;
    margin-top: 12px;
  `,
  DetailedTitle: styled.h3`
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: ${Color.GREY_100};
    margin-bottom: 6px;
  `,
  DetailedDescription: styled.p`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: ${Color.DISABLED};
  `,
}
