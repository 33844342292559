import { IdealWeightTimeValue } from 'value-constants'

export const ANSWER_OPTIONS = [
  {
    value: IdealWeightTimeValue.LESS_THAN_YEAR_AGO,
    title: 'onboarding.idealWeightTime.options.lessThanYearAgo',
  },
  {
    value: IdealWeightTimeValue.ONE_TWO_YEARS_AGO,
    title: 'onboarding.idealWeightTime.options.oneTwoYearsAgo',
  },
  {
    value: IdealWeightTimeValue.MORE_THAN_THREE_YEARS_AGO,
    title: 'onboarding.idealWeightTime.options.moreThanThreeYearsAgo',
  },
  {
    value: IdealWeightTimeValue.NEVER,
    title: 'onboarding.idealWeightTime.options.never',
  },
]
