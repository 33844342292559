import styled from 'styled-components'

import { Input } from 'components/Input'
import { SvgImage } from 'components/SvgImage'

import { Color } from 'root-constants'

export const StyledInput = {
  InputContainer: styled.div`
    position: relative;
  `,
  Input: styled(Input)`
    height: 64px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    padding: 30px 48px 14px 24px;

    & + span {
      color: ${Color.DISABLED};
      top: 22px;
      left: 24px;
      font-weight: 700;
      font-size: 16px;
      letter-spacing: -0.45px;
      line-height: 20px;
    }

    &:focus,
    &:valid {
      & + span {
        top: 14px;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
      }
    }
  `,
  ValidationIcon: styled(SvgImage)`
    position: absolute;
    right: 18px;
    top: 22px;
  `,
}
