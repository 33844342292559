import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { BMI_LABELS_MAP } from 'components/BodyCalculation/constants'

import { selectCurrentUserHeight } from 'root-redux/selects/common'

import { getBmiInImperialSystem } from 'helpers/getBmiInImperialSystem'
import { getBmiInMetricSystem } from 'helpers/getBmiInMetricSystem'

import { LOWER_BMI_THRESHOLD_V1, UPPER_BMI_THRESHOLD } from 'root-constants'

import { StyledBodyCalculation as S } from './BodyCalculation.styles'

type TBodyProps = {
  weight: string
  isMetric: boolean
}

export const BodyCalculation: React.FC<TBodyProps> = ({ isMetric, weight }) => {
  const { t } = useTranslation()
  const userHeight = +useSelector(selectCurrentUserHeight)

  const usersBMI = useMemo(
    () =>
      isMetric
        ? getBmiInMetricSystem(Number(weight), userHeight)
        : getBmiInImperialSystem(Number(weight), userHeight),
    [isMetric, weight, userHeight],
  )

  const currentBMI = useMemo(() => {
    if (usersBMI <= LOWER_BMI_THRESHOLD_V1) {
      return BMI_LABELS_MAP[0]
    }

    if (usersBMI >= UPPER_BMI_THRESHOLD) {
      return BMI_LABELS_MAP[BMI_LABELS_MAP.length - 1]
    }

    return (
      BMI_LABELS_MAP.find(
        (label) =>
          usersBMI >= label.lowerThreshold && usersBMI <= label.upperThreshold,
      ) || BMI_LABELS_MAP[BMI_LABELS_MAP.length - 1]
    )
  }, [usersBMI])

  return (
    <S.Disclaimer backgroundColor={currentBMI.backgroundColor}>
      <S.Title>
        <S.Head>
          <S.Name>{t('onboarding.currentWeight.disclaimer.title')}</S.Name>
          <S.Value color={currentBMI.color}>{usersBMI.toFixed(1)}</S.Value>
        </S.Head>
        <S.Label backgroundColor={currentBMI.color} width={currentBMI.width}>
          <S.Text>{t(currentBMI.label)}</S.Text>
        </S.Label>
      </S.Title>
      <S.SubTitle>{t(currentBMI.title)}</S.SubTitle>
    </S.Disclaimer>
  )
}
