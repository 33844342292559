export const CUSTOMER_REVIEWS = [
  {
    id: '1',
    title: 'purchase.customerReviews.firstReviewTitle',
    date: '05.01.2022',
    author: 'Marta86',
    text: 'purchase.customerReviews.firstReview',
  },
  {
    id: '2',
    title: 'purchase.customerReviews.secondReviewTitle',
    date: '29.11.2021',
    author: 'Athletic',
    text: 'purchase.customerReviews.secondReview',
  },
  {
    id: '3',
    title: 'purchase.customerReviews.thirdReviewTitle',
    date: '20.05.2022',
    author: 'PBarcelo',
    text: 'purchase.customerReviews.thirdReview',
  },
]

export const CUSTOMER_REVIEWS_V2 = [
  {
    id: '1',
    title: 'purchase.customerReviews.firstReviewTitle',
    date: '05.07.2023',
    author: 'Mike86',
    text: 'purchase.customerReviewsV3.firstReviewText',
  },
  {
    id: '2',
    title: 'purchase.customerReviews.secondReviewTitle',
    date: '29.02.2023',
    author: 'Athletic',
    text: 'purchase.customerReviewsV3.secondReviewText',
  },
  {
    id: '3',
    title: 'purchase.customerReviews.thirdReviewTitle',
    date: '20.10.2022',
    author: 'PBarcelo',
    text: 'purchase.customerReviewsV3.thirdReviewText',
  },
]
