import React, { useEffect, useState } from 'react'

import { Position } from 'root-constants'

import { StyledAnswerButtonV2 as S } from './AnswerButtonV2.styles'

export const enum IconVerticalAlignments {
  CENTER = 'center',
  BOTTOM = 'flex-end',
  TOP = 'top',
  DEFAULT = 'stretch',
}

type TProps = {
  iconSrc?: string
  children:
    | React.ReactElement<HTMLHeadingElement>
    | React.ReactElement<HTMLParagraphElement>
  iconWidth?: number
  iconHeight?: number
  withCheckbox?: boolean
  iconVerticalAlignment?: IconVerticalAlignments
  iconPosition?: Position
  checkboxPosition?: Position
  strikethroughIcon?: boolean
  className?: string
}

export const AnswerButtonV2: React.FC<TProps> = ({
  iconSrc,
  children,
  iconWidth,
  iconHeight,
  withCheckbox,
  iconVerticalAlignment = IconVerticalAlignments.DEFAULT,
  iconPosition = Position.RIGHT,
  checkboxPosition = Position.RIGHT,
  strikethroughIcon = false,
  className,
}) => {
  const [imageConfig, setImageConfig] = useState<{
    imageSrc: string
    imageHash: number
  } | null>(null)

  useEffect(() => {
    if (iconSrc) {
      setImageConfig({
        imageSrc: iconSrc,
        imageHash: Date.now(),
      })
    }
  }, [iconSrc])

  return (
    <S.Wrapper
      iconPosition={iconPosition}
      checkboxPosition={checkboxPosition}
      withCheckbox={withCheckbox}
      className={className}
    >
      <S.Content>{children}</S.Content>
      {imageConfig && (
        <S.Icon
          iconPosition={iconPosition}
          strikethroughIcon={strikethroughIcon}
          style={{
            width: iconWidth,
            height: iconHeight,
            alignSelf: iconVerticalAlignment,
          }}
        >
          <img
            src={`${imageConfig.imageSrc}?${imageConfig.imageHash}`}
            alt="icon"
          />
        </S.Icon>
      )}
    </S.Wrapper>
  )
}
