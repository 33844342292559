import { useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'

import { selectUserOnboardingEmail } from 'root-redux/selects/user'

export const useSnapPixelInitialization = (snapPixelId: string): void => {
  const email = useSelector(selectUserOnboardingEmail)

  useLayoutEffect(() => {
    window.snaptr('init', snapPixelId, { user_email: email })
    window.snaptr('track', 'PAGE_VIEW', { user_email: email })
  }, [snapPixelId, email])
}
