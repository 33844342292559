import styled from 'styled-components'

import { Container } from 'components/Container'
import { Logo } from 'components/Logo'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledWeightGainResult = {
  Logo: styled(Logo)`
    position: absolute;
    top: 8px;
    width: 100%;
    margin-bottom: 0;
  `,
  Banner: styled.div`
    aspect-ratio: 180/179;
    width: 100%;
    margin: 0 auto;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: ${MediaBreakpoint.MEDIUM_PHONE}px;
    }
  `,
  Container: styled(Container)`
    max-width: 100%;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: ${MediaBreakpoint.MEDIUM_PHONE}px;
    }
  `,
  Subtitle: styled.p`
    color: ${Color.GREY_100};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 32px;
    text-align: center;
  `,
  ButtonContainer: styled.div`
    padding-bottom: 16px;
  `,
}
