import React from 'react'

import { Progress } from 'components/Progress'

import { StyledProgressAnalyzer as S } from './ProgressAnalyzer.styles'

type TProps = {
  value: number
  color?: string
  backgroundColor?: string
  children?: React.ReactNode
}

export const ProgressAnalyzer: React.FC<TProps> = ({
  value,
  color,
  backgroundColor,
  children,
  ...props
}) => (
  <S.ProgressbarContainer {...props}>
    <S.Wrapper>
      <S.Label>{children}</S.Label>
      <S.Percent>{value}%</S.Percent>
    </S.Wrapper>

    <Progress
      background={backgroundColor}
      color={color}
      value={value}
      max={100}
    />
  </S.ProgressbarContainer>
)
