import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { AnswerButton, IconVerticalAlignments } from 'components/AnswerButton'
import { Container } from 'components/Container'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import manTippingHandIcon from 'assets/images/man-tipping-hand.png'
import olderPersonIcon from 'assets/images/older-person.png'
import moonIcon from 'assets/images/smiling-moon.png'
import sunIcon from 'assets/images/smiling-sun.png'
import womanTippingHandIcon from 'assets/images/woman-tipping-hand.png'

import { Gender } from 'root-constants'

import { OPTION_VALUES, QUESTION } from './constants'

export const WorkSchedule: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const gender = useSelector(selectCurrentUserGender)

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, setIsAnswersDisabled, handleContinue],
  )

  return (
    <Container>
      <PageTitle marginBottom={24}>
        {t('onboarding.workSchedule.title')}
      </PageTitle>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.MORNING_HOURS}
      >
        <AnswerButton
          iconSrc={sunIcon}
          iconWidth={56}
          iconHeight={56}
          iconVerticalAlignment={IconVerticalAlignments.CENTER}
        >
          <h3>{t('onboarding.workSchedule.options.morningHours')}</h3>
        </AnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.NIGHT_HOURS}
      >
        <AnswerButton
          iconSrc={moonIcon}
          iconWidth={56}
          iconHeight={56}
          iconVerticalAlignment={IconVerticalAlignments.CENTER}
        >
          <h3>{t('onboarding.workSchedule.options.nightHours')}</h3>
        </AnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.FLEXIBLE}
      >
        <AnswerButton
          iconSrc={
            gender === Gender.FEMALE ? womanTippingHandIcon : manTippingHandIcon
          }
          iconWidth={56}
          iconHeight={56}
          iconVerticalAlignment={IconVerticalAlignments.CENTER}
        >
          <h3>{t('onboarding.workSchedule.options.flexible')}</h3>
        </AnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.RETIRED}
      >
        <AnswerButton
          iconSrc={olderPersonIcon}
          iconWidth={56}
          iconHeight={56}
          iconVerticalAlignment={IconVerticalAlignments.CENTER}
        >
          <h3>{t('onboarding.workSchedule.options.retired')}</h3>
        </AnswerButton>
      </Option>
    </Container>
  )
}
