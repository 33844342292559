import { AgeRangeValue } from 'value-constants'

import firstRange from 'assets/images/age-range-v2-first.png'
import fourthRange from 'assets/images/age-range-v2-fourth.png'
import secondRange from 'assets/images/age-range-v2-second.png'
import thirdRange from 'assets/images/age-range-v2-third.png'

export const RANGES = [
  {
    value: AgeRangeValue.AR_18_29,
    text: '18-29',
    img: firstRange,
  },
  {
    value: AgeRangeValue.AR_30_39,
    text: '30-39',
    img: secondRange,
  },
  {
    value: AgeRangeValue.AR_40_49,
    text: '40-49',
    img: thirdRange,
  },
  {
    value: AgeRangeValue.AR_50_PLUS,
    text: '50+',
    img: fourthRange,
  },
]
