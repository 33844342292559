import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { DailyWaterValue } from 'value-constants'

import { AnswerButtonV2 } from 'components/AnswerButtonV2'
import { Container } from 'components/Container'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Language } from 'root-constants'

import { DAILY_WATER_DISCLAIMER } from './constants'

export const DailyWaterV2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.dailyWater.title', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, setIsAnswersDisabled, handleContinue],
  )

  return (
    <Container>
      <PageTitle marginBottom={24}>
        {t('onboarding.dailyWater.title')}
      </PageTitle>
      <Option
        {...optionProps}
        marginBottom="12"
        disabled={isAnswersDisabled}
        value={DailyWaterValue.COFFEE_OR_TEE}
      >
        <AnswerButtonV2>
          <h3>{t(DAILY_WATER_DISCLAIMER[DailyWaterValue.COFFEE_OR_TEE])}</h3>
        </AnswerButtonV2>
      </Option>
      <Option
        {...optionProps}
        marginBottom="12"
        disabled={isAnswersDisabled}
        value={DailyWaterValue.TWO_GLASSES}
      >
        <AnswerButtonV2>
          <h3>{t(DAILY_WATER_DISCLAIMER[DailyWaterValue.TWO_GLASSES])}</h3>
        </AnswerButtonV2>
      </Option>
      <Option
        {...optionProps}
        marginBottom="12"
        disabled={isAnswersDisabled}
        value={DailyWaterValue.TWO_SIX_GLASSES}
      >
        <AnswerButtonV2>
          <h3>{t(DAILY_WATER_DISCLAIMER[DailyWaterValue.TWO_SIX_GLASSES])}</h3>
        </AnswerButtonV2>
      </Option>
      <Option
        {...optionProps}
        marginBottom="12"
        disabled={isAnswersDisabled}
        value={DailyWaterValue.MORE_THAN_SIX_GLASSES}
      >
        <AnswerButtonV2>
          <h3>
            {t(DAILY_WATER_DISCLAIMER[DailyWaterValue.MORE_THAN_SIX_GLASSES])}
          </h3>
        </AnswerButtonV2>
      </Option>
    </Container>
  )
}
