import styled from 'styled-components'

export const StyledDontExerciseV1 = {
  ContinueButtonText: styled.span`
    text-transform: capitalize;
  `,
  ImageContainer: styled.div`
    aspect-ratio: 49/48;
    height: 336px;
    width: 100%;
    margin: 0 auto;
  `,
}
