import styled from 'styled-components'

import { Button } from 'components/Button'

import { Color } from 'root-constants'

export const StyledUpsellSubscriptionBlock = {
  Container: styled.div`
    margin-bottom: 40px;
    width: 100%;
  `,
  Title: styled.h2`
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 24px;
    text-align: center;
  `,
  List: styled.ul`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    row-gap: 24px;
  `,
  Item: styled.li`
    align-items: center;
    column-gap: 8px;
    display: flex;
    justify-content: space-between;
  `,
  ItemTitle: styled.h2`
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  `,
  ItemValue: styled.div`
    align-items: center;
    column-gap: 8px;
    display: flex;
  `,
  ItemValueStrong: styled.span`
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  `,
  ItemValueStrikethrough: styled.span`
    color: ${Color.DISABLED};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-decoration: line-through;
  `,
  DiscountLabel: styled.div`
    align-items: center;
    background-color: rgba(240, 139, 52, 0.2);
    border-radius: 4px;
    column-gap: 2px;
    display: flex;
    padding: 4px 6px;
  `,
  DiscountImage: styled.div`
    aspect-ratio: 1 / 1;
    width: 12px;
  `,
  DiscountValue: styled.span`
    color: ${Color.ORANGE};
    font-size: 10px;
    font-weight: 700;
    line-height: 12px;
    text-transform: uppercase;
  `,
  Button: styled(Button)`
    margin-bottom: 16px;
  `,
  Disclaimer: styled.p`
    color: ${Color.DISABLED};
    font-size: 9px;
    font-weight: 400;
    line-height: 12px;
    text-align: center;

    a {
      color: ${Color.DISABLED};
      text-decoration: underline;
    }
  `,
}
