import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  INITIAL_PROGRESS_VALUE,
  POST_PAYMENT_STEPS_MAP,
  PROGRESS_SECTION_STEP,
} from 'components/PostPaymentProgressBar/constants'
import { SvgImage } from 'components/SvgImage'

import { selectHasUpsellOffer } from 'root-redux/selects/common'

import { useGetPageInfo } from 'hooks/useGetPageInfo'

import progressCheckImg from 'assets/images/sprite/check-icon-progressbar.svg'

import { PageId } from 'page-constants'

import { StyledPostPaymentProgressBar as S } from './PostPaymentProgressBar.styles'

type TProps = {
  className?: string
}

export const PostPaymentProgressBar: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()

  const { currentPageId, currentUpsellPaywallPageId } = useGetPageInfo()

  const hasUpsellOffer = useSelector(selectHasUpsellOffer)

  const upsellProgressbarSteps = useMemo(
    () => [
      [{ id: currentUpsellPaywallPageId, text: 'login.buyPlan' }],
      [{ id: PageId.FINISHING_TOUCHES, text: 'login.addOffer' }],
      [{ id: PageId.LOGIN, text: 'login.confirmInformation' }],
      [{ id: PageId.DOWNLOAD, text: 'login.createAccount' }],
    ],
    [currentUpsellPaywallPageId],
  )

  const stepsMap = useMemo(
    () => (hasUpsellOffer ? upsellProgressbarSteps : POST_PAYMENT_STEPS_MAP),
    [hasUpsellOffer, upsellProgressbarSteps],
  )

  const currentSectionIndex = useMemo(
    () =>
      stepsMap.findIndex(
        (block) => !!block.find((step) => step.id === currentPageId),
      ),
    [currentPageId, stepsMap],
  )

  return (
    <S.Wrapper className={className}>
      {stepsMap.map(([{ id, text }], index, arr) => {
        const progressValue =
          currentSectionIndex >= index ? arr.length : INITIAL_PROGRESS_VALUE
        const nextSectionIndex = currentSectionIndex + PROGRESS_SECTION_STEP

        return (
          <S.StepBlock key={id}>
            <S.Step
              isActive={nextSectionIndex === index}
              isPassed={currentSectionIndex >= index}
            >
              {currentSectionIndex >= index && (
                <SvgImage svg={progressCheckImg} />
              )}
              <S.StepText>{t(text)}</S.StepText>
            </S.Step>
            <S.StepBlockProgress
              value={progressValue}
              pointsNumber={arr.length}
              max={arr.length}
            />
          </S.StepBlock>
        )
      })}
      <S.Step isActive={currentPageId === PageId.DOWNLOAD}>
        <S.StepText>{t('login.accessPlan')}</S.StepText>
      </S.Step>
    </S.Wrapper>
  )
}
