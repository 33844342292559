import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledNotAlone = {
  Banner: styled.div`
    aspect-ratio: 1 / 1;
    margin: 0 auto 24px;
    width: 248px;
  `,
  Subtitle: styled.h2`
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 16px;

    strong {
      color: ${Color.GREEN_300};
      font-weight: 700;
    }
  `,
  Info: styled.h3`
    color: ${Color.GREY_101};
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
  `,
}
