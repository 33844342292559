import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

const commonTextStyles = css`
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  text-align: right;
`

export const Title = styled.h2`
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
`
export const ReviewContainer = styled.div`
  margin-bottom: 16px;
  padding: 16px;
  border-radius: 16px;
  background-color: ${Color.WHITE};
  box-shadow: 0 8px 20px rgba(17, 30, 23, 0.1);

  &:last-of-type {
    margin-bottom: 24px;
  }
`
export const ReviewTitle = styled.span`
  margin-bottom: 4px;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: left;
`
export const Date = styled.span`
  ${commonTextStyles};
  color: ${Color.DISABLED};
`
export const Rating = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 98px;
  padding-bottom: 8px;

  svg {
    width: 18px;
    height: 18px;
  }
`
export const Author = styled.span`
  ${commonTextStyles};
  color: ${Color.DISABLED};
`
export const Text = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${Color.DISABLED};
`
export const Button = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  color: ${Color.PRIMARY};
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  padding: 0;
  margin-left: 4px;
  cursor: pointer;
`

export const StyledCustomerReviews = {
  WrapperReviews: styled.div`
    padding-bottom: 32px;
  `,
  ReviewHeader: styled.div`
    display: grid;
    grid-template-columns: 3fr 1fr;
  `,
  CustomDate: styled.span`
    color: ${Color.DISABLED};
    text-align: right;
    font-weight: 400;
    font-size: 14px;
  `,
  CustomAuthor: styled.span`
    color: ${Color.DISABLED};
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
  `,
  TextContent: styled.div`
    display: flex;
  `,
  TextContainer: styled.div`
    overflow: hidden;
  `,
}
