import { PurchaseProduct } from 'modules/purchase/constants'

export const TRIAL_DISCLAIMER_MAP = {
  [PurchaseProduct.SEVEN_DAY]: 'purchase.subscription.weeklyTrialDisclaimerV2',
  [PurchaseProduct.ONE_MONTH]: 'purchase.subscription.monthlyTrialDisclaimerV2',
  [PurchaseProduct.THREE_MONTHS]:
    'purchase.subscription.quarterlyTrialDisclaimerV2',
}

export const NO_TRIAL_DISCLAIMER_MAP = {
  [PurchaseProduct.SEVEN_DAY]: 'purchase.subscription.weeklyDisclaimerV2',
}
