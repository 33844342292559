import styled from 'styled-components'

import { UserInfoBlockV3, UserInfoBlockV4 } from 'components/UserInfoBlock'

export const StyledPresaleV3 = {
  UserInfoBlockV3: styled(UserInfoBlockV3)`
    margin: 0 0 20px 0;
  `,

  UserInfoBlockV4: styled(UserInfoBlockV4)`
    margin: 0 0 16px 0;
  `,
}
