import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { AnswerButton } from 'components/AnswerButton'
import { Container } from 'components/Container'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import creamSoupIcon from 'assets/images/cream-soup.png'
import plateOfFoodIcon from 'assets/images/lunch-plate-of-food.png'
import unknownFoodIcon from 'assets/images/lunch-plate-of-unknown-food.png'
import riceIcon from 'assets/images/lunch-rice.png'

import { OPTION_VALUES, QUESTION } from './constants'

export const LunchTime: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, setIsAnswersDisabled, handleContinue],
  )

  return (
    <Container>
      <PageTitle marginBottom={24}>{t('onboarding.lunchTime.title')}</PageTitle>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.TEN_NOON}
      >
        <AnswerButton iconSrc={plateOfFoodIcon}>
          <h3>{t('onboarding.lunchTime.options.tenNoon')}</h3>
        </AnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.NOON_TWO_PM}
      >
        <AnswerButton iconSrc={unknownFoodIcon}>
          <h3>{t('onboarding.lunchTime.options.nonTwo')}</h3>
        </AnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.TWO_FOUR_PM}
      >
        <AnswerButton iconSrc={riceIcon}>
          <h3>{t('onboarding.lunchTime.options.twoFour')}</h3>
        </AnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.SKIP}
      >
        <AnswerButton iconSrc={creamSoupIcon}>
          <h3>{t('onboarding.lunchTime.options.skip')}</h3>
        </AnswerButton>
      </Option>
    </Container>
  )
}
