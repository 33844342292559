import styled, { css, keyframes } from 'styled-components'

import { Color, MediaBreakpoint } from 'root-constants'

const appearAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const defaultAnimation = css`
  opacity: 0;
  animation-name: ${appearAnimation};
  animation-timing-function: linear;
  animation-duration: 200ms;
  animation-fill-mode: forwards;
`

const tipStyles = css`
  ${defaultAnimation};
  position: absolute;
  padding: 8px;
  border-radius: 50%;
`

const LabelStyles = css`
  ${defaultAnimation};
  font-weight: 900;
  font-size: 10px;
  line-height: 12px;
  min-width: 60px;
  color: ${Color.GREY_100};
`

export const Title = styled.h3`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.45px;
  color: ${Color.GREY_100};
  text-align: center;
  margin-bottom: 12px;
`
export const GraphContent = styled.div`
  position: relative;
  margin-bottom: 8px;
`

export const Label = styled.p`
  ${LabelStyles};
  animation-delay: 450ms;

  &:first-child {
    animation-delay: 200ms;
  }
`
export const LabelResult = styled.p`
  ${LabelStyles};
  animation-delay: 750ms;
  padding: 4px 8px;
  background: ${Color.PRIMARY};
  border-radius: 4px;
  color: ${Color.WHITE};
`

export const Now = styled.div`
  ${tipStyles};
  left: 10px;
  top: 0;
  width: 52px;
  height: 52px;
  background: #faefe6;
  border: 1px solid #fca868;
  animation-delay: 200ms;
  color: #fca868;
  font-weight: 700;
  font-size: 10px;
  line-height: 0;
  flex-direction: column;
  display: flex;
  align-items: center;

  @media (max-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
    top: -6px;
  }

  strong {
    font-weight: 800;
    font-size: 24px;
    line-height: 28px;
  }
`
export const Goal = styled.div`
  ${tipStyles};
  z-index: 1;
  animation-delay: 750ms;
  width: 78px;
  height: 78px;
  right: 12px;
  top: 32px;
  background-color: ${Color.PRIMARY};
  font-weight: 900;
  font-size: 12px;
  line-height: 0;
  padding: 12px 0;
  color: #ffffff;
  text-align: center;

  @media (max-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
    top: 28px;
    right: 8px;
  }

  strong {
    font-weight: 900;
    font-size: 28px;
    line-height: 38px;
  }
`
export const Footnote = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.154px;
  color: ${Color.DISABLED};
`

export const StyledGraph = {
  Wrapper: styled.div`
    margin-bottom: 32px;
  `,
  DateLabels: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: -30px auto 0;
    padding: 0 12px 0 22px;
  `,
  GraphRef: styled.div`
    display: flex;
    min-height: 200px;
  `,
}
