import React, { InputHTMLAttributes } from 'react'

import { StyledToggle as S } from './Toggle.styles'

type TProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'type' | 'onChange'
> & {
  labels?: [string, string]
  onChange: (isChecked: boolean) => void
}

export const Toggle: React.FC<TProps> = ({
  labels = ['lbs', 'kg'],
  onChange,
  checked,
  ...props
}) => {
  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(target.checked)
  }

  return (
    <S.Wrapper>
      <S.Input
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        {...props}
      />
      <S.Toggle />
      <S.Value $labels={labels} checked={checked} />
    </S.Wrapper>
  )
}
