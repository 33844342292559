import styled from 'styled-components'

import { Color } from 'root-constants'

export const Title = styled.h2`
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
`
export const ListItemTitle = styled.h3`
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
`
export const ListItemText = styled.p`
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${Color.DISABLED};
`
export const QuestionIcon = styled.div`
  aspect-ratio: 1 / 1;
  height: 24px;
  margin-right: 8px;
  width: 24px;
`

export const StyledFAQList = {
  Wrapper: styled.div`
    padding: 24px 16px;
    margin-bottom: 24px;
    background: ${Color.WHITE};
    box-shadow: 0 8px 20px rgba(17, 30, 23, 0.1);
    border-radius: 24px;
  `,
  ListItem: styled.li`
    display: flex;
  `,
}
