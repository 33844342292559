import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { ANIMATION_PATH } from 'pages/questions/WeightLossDate/constants'

import { useDelayedAnimationPlaying } from 'components/LoseWeightGraph/hooks/useDelayedAnimationPlaying'

import {
  selectCurrentUserCurrentWeight,
  selectCurrentUserGender,
  selectCurrentUserGoalWeight,
  selectLanguage,
  selectUserMeasurementSystem,
} from 'root-redux/selects/common'

import { usePredictableData } from 'hooks/usePredictableData'

import { CDN_FOLDER_LINK, Gender, WEIGHT_UNITS } from 'root-constants'

import { StyledLoseWeightGraphV4 as S } from './LoseWeightGraphV4.styles'

export const LoseWeightGraphV4: React.FC = () => {
  const gender = useSelector(selectCurrentUserGender)
  const { t } = useTranslation()

  const currentWeight = useSelector(selectCurrentUserCurrentWeight)
  const measurementSystem = useSelector(selectUserMeasurementSystem)
  const goalWeight = useSelector(selectCurrentUserGoalWeight)
  const language = useSelector(selectLanguage)

  const [animationRef, isAnimationStarted] = useDelayedAnimationPlaying({
    animationPath: `${CDN_FOLDER_LINK}${ANIMATION_PATH}-${language}.json`,
  })

  const { lostWeightPerDay } = usePredictableData()
  const actualWeight = useMemo(
    () => `${currentWeight} ${WEIGHT_UNITS[measurementSystem]}`,
    [currentWeight, measurementSystem],
  )

  const targetWeight = useMemo(
    () => `${goalWeight} ${WEIGHT_UNITS[measurementSystem]}`,
    [goalWeight, measurementSystem],
  )

  const daysToGoalWeight = useMemo(
    () =>
      Math.round(
        Number(((currentWeight - goalWeight) / lostWeightPerDay).toFixed(2)),
      ),
    [currentWeight, goalWeight, lostWeightPerDay],
  )

  return (
    <S.GraphWrapper>
      <S.Graph ref={animationRef}>
        <S.CurrentWeightLabel isAnimationStarted={isAnimationStarted}>
          {actualWeight}
        </S.CurrentWeightLabel>
        <S.GoalWeightLabel isAnimationStarted={isAnimationStarted}>
          {targetWeight}
        </S.GoalWeightLabel>
      </S.Graph>
      <S.GraphSignatureWrapper>
        <S.GraphSignature>{t`onboarding.weightLossDateV1.now`}</S.GraphSignature>
        <S.GraphSignature>
          <Trans
            i18nKey="onboarding.weightLossDateV1.days"
            values={{
              daysToGoalWeight,
            }}
          />
        </S.GraphSignature>
      </S.GraphSignatureWrapper>
      <S.BaseOnText>
        <Trans
          i18nKey="onboarding.weightLossDateV1.baseOn"
          values={{
            gender:
              gender === Gender.MALE
                ? t`commonComponents.gender.male`
                : t`commonComponents.gender.female`,
          }}
        />
      </S.BaseOnText>
    </S.GraphWrapper>
  )
}
