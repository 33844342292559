import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { MotivationValue } from 'value-constants'

import {
  selectCurrentUserMotivation,
  selectUserMeasurementSystem,
} from 'root-redux/selects/common'

import { useEventWeightInfo } from 'hooks/useEventWeightInfo'

import { FULL_MONTH_FORMAT, getFormattedTodaysDate } from 'helpers/dateHelper'

import chart from 'assets/images/chart.png'
import dot from 'assets/images/sprite/dot.svg'

import { WEIGHT_UNITS } from 'root-constants'

import { StyledGraphV2 as S } from './LoseWeightGraphV2.styles'

export const LoseWeightGraphV2: React.FC = () => {
  const measurementSystem = useSelector(selectUserMeasurementSystem)
  const motivation = useSelector(selectCurrentUserMotivation) as MotivationValue

  const {
    fullGoalDate,
    eventDate,
    currentWeight,
    goalWeight,
    weightBeforeEvent,
    pixelLeft,
    pixelTop,
    intermediateDates,
    isEventWithinGoal,
  } = useEventWeightInfo()

  const isAnswerShown = useMemo(
    () => isEventWithinGoal && eventDate && motivation !== MotivationValue.NO,
    [motivation, eventDate, isEventWithinGoal],
  )

  const answerToShow = useMemo(
    () =>
      motivation === MotivationValue.NO || motivation === MotivationValue.OTHER
        ? ''
        : motivation,
    [motivation],
  )

  return (
    <S.Wrapper>
      <S.GraphContent>
        <S.CurrentWeight>
          <span>{currentWeight}</span>
          <span>{WEIGHT_UNITS[measurementSystem]}</span>
        </S.CurrentWeight>
        <S.GoalWeight>
          <span>{goalWeight}</span>
          <span>{WEIGHT_UNITS[measurementSystem]}</span>
        </S.GoalWeight>
        <picture>
          <S.Graph src={chart} />
        </picture>
        <S.DateLabels>
          <S.Label>{getFormattedTodaysDate(FULL_MONTH_FORMAT)}</S.Label>
          {intermediateDates.length > 0 &&
            intermediateDates.map((datePoint) => (
              <S.Label key={datePoint}>{datePoint}</S.Label>
            ))}
          <S.LabelResult>{fullGoalDate}</S.LabelResult>
        </S.DateLabels>
        {isAnswerShown && (
          <S.MiddleLabel left={pixelLeft} top={pixelTop} answer={answerToShow}>
            {answerToShow && (
              <S.LabelTitle answer={answerToShow}>{answerToShow}</S.LabelTitle>
            )}
            <S.LabelValue>
              {weightBeforeEvent}
              {WEIGHT_UNITS[measurementSystem]}
            </S.LabelValue>
          </S.MiddleLabel>
        )}
        {isAnswerShown && (
          <S.Dot left={pixelLeft} top={pixelTop} svg={dot} width={10} />
        )}
      </S.GraphContent>
    </S.Wrapper>
  )
}
