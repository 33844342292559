import styled from 'styled-components'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledStopHunger = {
  Banner: styled.div`
    aspect-ratio: 60 / 37;
    margin: 0 auto 16px;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    position: relative;
    width: 100%;

    :before {
      background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        #fff 100%
      );
      bottom: -1px;
      content: '';
      height: 40%;
      left: 0;
      position: absolute;
      width: 100%;
    }
  `,
  Title: styled.h2`
    color: ${Color.GREY_100};
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 8px;
    text-align: center;
  `,
  Info: styled.h3`
    color: ${Color.GREY_100};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 24px;
    text-align: center;
  `,
  List: styled.ul`
    display: flex;
    flex-direction: column;
    margin: 0 auto 74px;
    row-gap: 16px;
    width: fit-content;
  `,
  Item: styled.li`
    align-items: center;
    column-gap: 12px;
    display: flex;
  `,
  ItemText: styled.h4`
    color: ${Color.GREY_100};
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;

    strong {
      color: ${Color.PRIMARY};
      font-weight: 600;
    }
  `,
  CheckIcon: styled.div`
    aspect-ratio: 1 / 1;
    width: 20px;
  `,
}
