import styled from 'styled-components'

import { Container } from 'components/Container'

import {
  CheckoutSummaryV3,
  TrialCheckoutSummaryV3,
} from 'modules/purchase/components/CheckoutSummaryV3'
import { PaymentMethodsV2 } from 'modules/purchase/components/PaymentMethodsV2'

import closeSvg from 'assets/images/close-icon-v2.svg'

import { Color } from 'root-constants'

export const StyledCheckoutVariant4 = {
  Container: styled(Container)`
    padding: 40px 16px 16px 16px;
    position: relative;
  `,
  Title: styled.h2`
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: ${Color.GREY_100};
    text-align: center;
    margin: 0 0 24px 0;
  `,
  CloseButton: styled.button`
    padding: 0;
    display: block;
    position: absolute;
    top: 8px;
    right: 16px;
    width: 24px;
    height: 24px;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${closeSvg});
  `,
  PaymentMethodsV2: styled(PaymentMethodsV2)`
    margin: 0 0 24px 0;
  `,
  TrialCheckoutSummaryV3: styled(TrialCheckoutSummaryV3)`
    margin: 0 0 24px 0;
  `,
  CheckoutSummaryV3: styled(CheckoutSummaryV3)`
    margin: 0 0 24px 0;
  `,
}
