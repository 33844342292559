import React, { useEffect, useRef } from 'react'
import { Trans } from 'react-i18next'

import lottie from 'lottie-web/build/player/lottie_light'

import { Container } from 'components/Container'
import { Logo } from 'components/Logo'

import { useCounter } from 'hooks/useCounter'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { CDN_FOLDER_LINK } from 'root-constants'

import { StyledAnalyzingAnswers as S } from './AnalyzingAnswers.styles'
import { QUESTION_V2 } from './constants'

export const AnalyzingAnswersV2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const animationRef = useRef<HTMLDivElement>(null)

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION_V2,
    nextPagePath,
  })

  useCounter(true, {
    duration: 4000,
    callback: handleContinue,
  })

  useEffect(() => {
    if (animationRef.current) {
      lottie.loadAnimation({
        container: animationRef.current,
        path: `${CDN_FOLDER_LINK}/animations/analyzing-answers-v2.json`,
        loop: true,
        name: 'dotAnimation',
      })
    }

    return () => lottie.destroy('dotAnimation')
  }, [])

  return (
    <S.Root>
      <Container>
        <Logo marginBottom={28} />
        <S.PageTitle>
          <Trans i18nKey="onboarding.analyzingAnswers.title" />
        </S.PageTitle>
        <S.Wrapper ref={animationRef}>
          <S.Dot />
        </S.Wrapper>
      </Container>
    </S.Root>
  )
}
