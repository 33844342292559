import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

const circleProgress = (isBackground = false) => css<{
  value: number
  radius?: number
}>`
  ${({ value, radius = 45 }) => {
    const length = radius * Math.PI * 2

    return css`
      stroke-dasharray: 0 ${length};
      stroke-dasharray: ${isBackground ? length : length * (value / 100)}
        ${length};
      stroke-width: ${radius / 8};
    `
  }};
  fill: transparent;
  stroke-linecap: round;
  transition: stroke-dasharray 0.2s;
`

export const StyledProgressRoundedV3 = {
  Container: styled.div`
    height: 102px;
    position: relative;
  `,
  Svg: styled.svg<{ value: number; radius?: number }>`
    height: 100%;
    transform: rotate(-90deg);
    width: 100%;

    circle {
      ${circleProgress(true)};
      stroke: #f5f6f7;
    }

    circle + circle {
      ${circleProgress()};
      stroke: ${Color.PRIMARY};
    }
  `,
  Label: styled.div`
    align-items: center;
    color: ${Color.PRIMARY};
    display: flex;
    font-size: 20px;
    font-weight: 700;
    left: 50%;
    line-height: 20px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  `,
}
