import { PrimaryGoalValue } from 'value-constants'

export const QUESTION_V1 = 'What else do you want to achieve?'

export const TITLE_V1 = {
  [PrimaryGoalValue.LOSE_WEIGHT]: 'onboarding.secondaryGoal.titleV1',
  [PrimaryGoalValue.GET_FIT]: 'onboarding.secondaryGoal.titleV2',
  [PrimaryGoalValue.BUILD_HEALTHY_HABITS]: 'onboarding.secondaryGoal.titleV3',
}
export const OPTION_VALUES_V1 = {
  RUNNING: 'running',
  BECOME_CONFIDENT: 'become_confident',
  FEEL_HEALTHIER: 'feel_healthier',
  FIT_BETTER: 'fit_better_in_clothes',
  OTHER: 'other',
}

export const ANSWER_OPTIONS_V1 = [
  {
    value: OPTION_VALUES_V1.RUNNING,
    title: 'onboarding.secondaryGoal.optionsV1.running.title',
    description: 'onboarding.secondaryGoal.optionsV1.running.description',
  },
  {
    value: OPTION_VALUES_V1.BECOME_CONFIDENT,
    title: 'onboarding.secondaryGoal.optionsV1.becomeConfident.title',
  },
  {
    value: OPTION_VALUES_V1.FEEL_HEALTHIER,
    title: 'onboarding.secondaryGoal.optionsV1.feelHealthier.title',
  },
  {
    value: OPTION_VALUES_V1.FIT_BETTER,
    title: 'onboarding.secondaryGoal.optionsV1.fitBetter.title',
  },
]
