import styled from 'styled-components'

import { AnswerButton } from 'components/AnswerButton'
import { PageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'

import { Color, Emojis } from 'root-constants'

export const StyledPeriod = {
  PageContainer: styled(PageContainer)`
    width: 100%;
  `,
  PageTitle: styled(PageTitle)`
    margin: 0 0 24px 0;
  `,

  Disclaimer: styled.div`
    border-radius: 12px;
    background-color: #e4ecff;
    margin: 0 0 24px 0;
    padding: 8px 8px 8px 36px;
    position: relative;

    &::before {
      position: absolute;
      left: 8px;
      top: 8px;
      content: '${Emojis.FINGER_UP_POINTER}';
      width: 20px;
      height: 20px;
      line-height: 20px;
    }
  `,
  DisclaimerTitle: styled.h3`
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: ${Color.GREY_100};
    margin: 0 0 4px 0;
  `,
  DisclaimerText: styled.p`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: ${Color.GREY_100};
  `,
  AnswerButton: styled(AnswerButton)`
    padding: 0;
    border-color: transparent;
    min-height: 92px;
    height: auto;

    div {
      padding: 24px;
    }

    h3 {
      margin: 0 0 4px 0;
    }

    p {
      line-height: 20px;
    }
  `,
}
