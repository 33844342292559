import React from 'react'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { PayPalButton } from 'modules/purchase/components'
import { PrimerPayPalButton } from 'modules/purchase/components/PrimerPayPalButton'

export const PayPalProvider: React.FC = () => {
  const isPrimerAvailable = useFeatureIsOn('fas_806_primer_paypal')

  return isPrimerAvailable ? <PrimerPayPalButton /> : <PayPalButton />
}
