import styled from 'styled-components'

import { PageContainer } from 'components/PageContainer'

import { Color, MAX_COLUMN_WIDTH } from 'root-constants'

export const StyledPreparePlan = {
  ProgressContainer: styled.div`
    margin: 40px 0 15px;
  `,
  Title: styled.h1`
    font-weight: 400;
    font-style: italic;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: ${Color.DISABLED};
    margin-bottom: 40px;
  `,
  Subtitle: styled.div`
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-bottom: 24px;

    strong {
      display: block;
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
    }
  `,
}

export const StyledPreparePlanV2 = {
  PageContainer: styled(PageContainer)`
    display: block;
    max-width: ${MAX_COLUMN_WIDTH}px;
  `,
  ProgressContainer: styled.div`
    margin: 40px 0 15px;
  `,
  Title: styled(StyledPreparePlan.Title)`
    margin-bottom: 64px;
  `,
  Subtitle: styled.p`
    display: flex;
    flex-direction: column;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 42px;
    row-gap: 4px;
    text-align: center;

    strong {
      color: ${Color.PRIMARY};
      font-size: 32px;
      font-weight: 700;
      line-height: 40px;
    }
  `,
}
