import React from 'react'
import { useTranslation } from 'react-i18next'

import { ProgressAnalyzer } from 'components/ProgressAnalyzer'

import { useCounter } from 'hooks/useCounter'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import chart from 'assets/images/good-feeling-chart.png'

import { Language } from 'root-constants'

import { StyledAboutFeelingGood as S } from './AboutFeelingGood.styles'

export const AboutFeelingGood: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.aboutFeelingGood.title', { lng: Language.EN }),
    nextPagePath,
  })

  const { counter } = useCounter(true, {
    duration: 7500,
    callback: handleContinue,
  })

  return (
    <S.PageContainer>
      <S.PageTitle>{t('onboarding.aboutFeelingGood.title')}</S.PageTitle>
      <S.ImageContainer>
        <img src={chart} alt="chart" />
      </S.ImageContainer>
      <ProgressAnalyzer value={counter}>
        <S.ProgressTitle>
          {t('onboarding.aboutFeelingGood.progressbarTitle')}
        </S.ProgressTitle>
      </ProgressAnalyzer>
    </S.PageContainer>
  )
}
