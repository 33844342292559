import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledStatementQuestion = {
  TitleContainer: styled.header`
    margin-bottom: 54px;
    margin-top: 31px;
  `,
  Title: styled.p`
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: ${Color.GREY_100};
    margin-bottom: 8px;
  `,
  SvgWrapper: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  Question: styled.h2`
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: ${Color.GREY_100};
    font-style: italic;
    margin-top: 8px;
    height: 242px;
  `,
  OptionsContainer: styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
  `,
  ButtonContainer: styled.div`
    width: 100%;
    position: sticky;
    bottom: 0;
    padding: 48px 0 24px 0;
    background: linear-gradient(
      rgba(255, 255, 255, 0) 0.25%,
      rgb(255, 255, 255) 26.14%
    );
  `,
  Scale: styled.div`
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${Color.DISABLED};
    margin-top: 12px;
  `,
}
