export const QUESTIONS_LIST_ITEMS_V1 = [
  {
    id: '1',
    question: 'purchase.faqList.questionFirst',
    answer: 'purchase.faqList.answerFirst',
  },
  {
    id: '2',
    question: 'purchase.faqList.questionSecond',
    answer: 'purchase.faqList.answerSecond',
  },
  {
    id: '3',
    question: 'purchase.faqList.questionThird',
    answer: 'purchase.faqList.answerThird',
  },
]

export const QUESTIONS_LIST_ITEMS_V2 = [
  {
    id: '1',
    question: 'purchase.faqList.questionFirst',
    answer: 'purchase.faqList.answerFirstV2',
  },
  {
    id: '2',
    question: 'purchase.faqList.questionSecond',
    answer: 'purchase.faqList.answerSecondV2',
  },
  {
    id: '3',
    question: 'purchase.faqList.questionThird',
    answer: 'purchase.faqList.answerThird',
  },
]
