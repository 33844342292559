import { AgeRangeValue } from 'value-constants'

import firstRangeV2 from 'assets/images/first-range-v2.png'
import firstRange from 'assets/images/first-range.png'
import fourthRangeV2 from 'assets/images/fourth-range-v2.png'
import fourthRange from 'assets/images/fourth-range.png'
import secondRangeV2 from 'assets/images/second-range-v2.png'
import secondRange from 'assets/images/second-range.png'
import thirdRangeV2 from 'assets/images/third-range-v2.png'
import thirdRange from 'assets/images/third-range.png'

export const RANGES_V1 = [
  {
    value: AgeRangeValue.AR_18_25,
    title: '18-25 >',
    img: firstRange,
  },
  {
    value: AgeRangeValue.AR_26_35,
    title: '26-35 >',
    img: secondRange,
  },
  {
    value: AgeRangeValue.AR_36_45,
    title: '36-45 >',
    img: thirdRange,
  },
  {
    value: AgeRangeValue.AR_46_PLUS,
    title: '46+ >',
    img: fourthRange,
  },
]

export const RANGES_V2 = [
  {
    value: AgeRangeValue.AR_18_25,
    title: '18-25 >',
    img: firstRangeV2,
  },
  {
    value: AgeRangeValue.AR_26_35,
    title: '26-35 >',
    img: secondRangeV2,
  },
  {
    value: AgeRangeValue.AR_36_45,
    title: '36-45 >',
    img: thirdRangeV2,
  },
  {
    value: AgeRangeValue.AR_46_PLUS,
    title: '46+ >',
    img: fourthRangeV2,
  },
]
