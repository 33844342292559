import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledKitProgressAnalyzer = {
  ProgressbarContainer: styled.div`
    width: 100%;
  `,
  Wrapper: styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  `,
  Label: styled.span`
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
  `,
  Percent: styled.span`
    color: ${Color.GREY_101};
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
  `,
}
