import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { PrimaryGoalValue } from 'value-constants'

import { AnswerButton } from 'components/AnswerButton'
import { Container } from 'components/Container'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import flexedBicepsIcon from 'assets/images/flexed-biceps.png'
import redApple from 'assets/images/red-apple.png'
import rockHand from 'assets/images/rock-hand.png'

import { CustomPageId } from 'page-constants'

import { QUESTION } from './constants'

export const PrimaryGoal: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId: CustomPageId.PRIMARY_GOAL,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: CustomPageId.PRIMARY_GOAL,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [handleChange, setIsAnswersDisabled, handleContinue],
  )

  return (
    <Container>
      <PageTitle marginBottom={24}>
        {t('onboarding.primaryGoal.title')}
      </PageTitle>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={PrimaryGoalValue.LOSE_WEIGHT}
      >
        <AnswerButton iconSrc={flexedBicepsIcon}>
          <>
            <h3>{t('onboarding.primaryGoal.options.loseWeight.title')}</h3>
            <p>{t('onboarding.primaryGoal.options.loseWeight.description')}</p>
          </>
        </AnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={PrimaryGoalValue.GET_FIT}
      >
        <AnswerButton iconSrc={rockHand}>
          <>
            <h3>{t('onboarding.primaryGoal.options.getFit.title')}</h3>
            <p>{t('onboarding.primaryGoal.options.getFit.description')}</p>
          </>
        </AnswerButton>
      </Option>

      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={PrimaryGoalValue.BUILD_HEALTHY_HABITS}
      >
        <AnswerButton iconSrc={redApple}>
          <>
            <h3>{t('onboarding.primaryGoal.options.healthyHabits.title')}</h3>
            <p>
              {t('onboarding.primaryGoal.options.healthyHabits.description')}
            </p>
          </>
        </AnswerButton>
      </Option>
    </Container>
  )
}
