import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { RadioOptionsPage } from 'pages/options-page'
import { MealTimingValue } from 'value-constants'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { TPageProps } from 'models/common.model'

import calendarIcon from 'assets/images/calendar.png'
import saladIcon from 'assets/images/dinner-vegi-salad.png'
import manTippingHandIcon from 'assets/images/man-tipping-hand.png'
import timeIcon from 'assets/images/time.png'
import womanTippingHandIcon from 'assets/images/woman-tipping-hand.png'

import { Gender } from 'root-constants'

import { TRANSLATION_FAMILY } from './constants'

export const MealTimingV1: React.FC<TPageProps> = (props) => {
  const gender = useSelector(selectCurrentUserGender)

  const options = useMemo(() => {
    const isFemale = gender === Gender.FEMALE

    return [
      {
        key: 'whenever',
        value: MealTimingValue.WHENEVER,
        iconSrc: saladIcon,
      },
      {
        key: 'stick',
        value: MealTimingValue.STICK,
        iconSrc: calendarIcon,
      },
      {
        key: 'always',
        value: MealTimingValue.ALWAYS,
        iconSrc: timeIcon,
      },
      {
        key: 'hungry',
        value: MealTimingValue.HUNGRY,
        iconSrc: isFemale ? womanTippingHandIcon : manTippingHandIcon,
      },
    ]
  }, [gender])

  return (
    <RadioOptionsPage
      {...props}
      options={options}
      translationFamily={TRANSLATION_FAMILY}
    />
  )
}
