import React, { RefObject, createRef, useEffect, useRef, useState } from 'react'
import { Trans } from 'react-i18next'

import { useTargetReachOnScroll } from 'hooks/useTargetReachOnScroll'

import { VIDEO_REVIEWS } from 'modules/purchase/components/VideoReviews/constants'

import { eventLogger } from 'services/eventLogger.service'

import { StyledVideoReviews as S } from './VideoReviews.styles'

type TProps = {
  title: string
  className?: string
}

export const VideoReviews: React.FC<TProps> = ({ title, className }) => {
  const [playedVideoKey, setPlayedVideoKey] = useState<string[]>([])
  const [videoReviewsReached, setVideoReviewsReached] = useState<boolean>(false)

  const videoBlock = useRef(null)
  const videoRefs = useRef(
    VIDEO_REVIEWS.map(() => createRef<HTMLVideoElement>()),
  )

  const isVideosVisible = useTargetReachOnScroll(videoBlock)

  const handleEndedVideo = (el: HTMLVideoElement | null) => {
    if (el) {
      el.pause()
      el.muted = true
    }
  }

  useEffect(() => {
    const refs = videoRefs.current
    Array.from(refs).forEach((videoRef) => {
      const currentRef = videoRef.current
      if (currentRef) {
        currentRef.muted = true
        currentRef.addEventListener('ended', () => handleEndedVideo(currentRef))
      }
    })

    return () => {
      Array.from(refs).forEach((videoRef) => {
        const currentRef = videoRef.current

        if (currentRef) {
          currentRef.removeEventListener('ended', () =>
            handleEndedVideo(currentRef),
          )
        }
      })
    }
  }, [])

  useEffect(() => {
    if (isVideosVisible && !videoReviewsReached) {
      Array.from(videoRefs.current).forEach((videoRef) => {
        const currentRef = videoRef.current
        currentRef && currentRef.play()
      })
      setVideoReviewsReached(isVideosVisible)
    }
  }, [isVideosVisible, videoReviewsReached])

  const handleFirstPlay = (videoKey: string) => {
    eventLogger.logVideoReviewTap({
      videoKey,
    })
  }

  const handleBtnClick = (ref: RefObject<HTMLVideoElement>) => {
    const currentRef = ref.current

    if (currentRef) {
      currentRef.muted && currentRef.load()
      currentRef.muted = !currentRef.muted
      currentRef.play()

      VIDEO_REVIEWS.forEach(({ key }, index) => {
        const videoReviewRef = videoRefs.current[index]

        if (videoReviewRef && videoReviewRef.current && key !== currentRef.id) {
          videoReviewRef.current.muted = true
        }
      })
    }

    if (ref.current && !playedVideoKey.includes(ref.current.id)) {
      setPlayedVideoKey([...playedVideoKey, ref.current?.id])
      handleFirstPlay(ref.current.id)
    }
  }

  return (
    <S.Container className={className} ref={videoBlock}>
      <S.Title>
        <Trans i18nKey={title} />
      </S.Title>
      <S.VideosContainer>
        {VIDEO_REVIEWS.map(({ key, userName, videoUrl, posterUrl }, index) => (
          <S.Card key={key}>
            <S.VideoWrapper posterUrl={posterUrl}>
              <video
                id={key}
                ref={videoRefs.current[index]}
                width="260px"
                height="260px"
                playsInline
                preload="auto"
                src={videoUrl}
                poster={posterUrl}
              >
                <track kind="captions" srcLang="en" label="English" />
              </video>
              <S.VideoButton
                onClick={() => handleBtnClick(videoRefs.current[index])}
                isMuted={!!videoRefs.current[index].current?.muted}
                name={key}
              />
            </S.VideoWrapper>
            <S.UserName>{userName}</S.UserName>
          </S.Card>
        ))}
      </S.VideosContainer>
    </S.Container>
  )
}
