import { AnswerWithCheckbox } from 'storybook-ui'
import styled, { css } from 'styled-components'

import { SvgImage } from 'components/SvgImage'

import { Color } from 'root-constants'

export const StyledTargetAreasV2 = {
  Subtitle: styled.h3`
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: ${Color.GREY_101};
    margin: 0 0 24px 0;
  `,
  AnswerWithCheckbox: styled(AnswerWithCheckbox)<{ checked: boolean }>`
    position: relative;
    svg {
      stroke: ${Color.GREY_800};
    }

    ${({ checked }) =>
      checked &&
      css`
        svg {
          stroke: ${Color.GREEN_400};
        }
      `}
  `,
  AnswerTitle: styled.h3`
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    color: ${Color.GREY_100};
  `,
  Row: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  ImageContainer: styled.div`
    width: 168px;
    aspect-ratio: 168/432;
  `,
  OptionList: styled.div`
    margin: 0 0 0 18px;
  `,
  SvgImage: styled(SvgImage)<{ top: number }>`
    position: absolute;
    left: 0;
    transform: translateX(-100%);
    color: ${Color.LIGHT};
    top: ${({ top }) => `${top}px`};
  `,
}
