import React from 'react'

import { RadioOptionsPage } from 'pages/options-page'

import { TPageProps } from 'models/common.model'

import { CustomPageId } from 'page-constants'

import { OPTIONS, TRANSLATION_FAMILY } from './constants'

export const FastingV1: React.FC<TPageProps> = (props) => (
  <RadioOptionsPage
    {...props}
    pageId={CustomPageId.FASTING}
    options={OPTIONS}
    translationFamily={TRANSLATION_FAMILY}
  />
)
