import styled from 'styled-components'

import { Container } from 'components/Container'

export const StyledDetermined = {
  SubTitle: styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    margin-bottom: 24px;
  `,
  Container: styled(Container)`
    padding-bottom: 16px;
  `,
}
