import React from 'react'
import { useTranslation } from 'react-i18next'

import stars from 'assets/images/stars-2.png'

import { StyledCustomerReviewsV2 as S } from './CustomerReviewsV2.styles'
import { CUSTOMER_REVIEWS_MAP_V2 } from './constants'

export const CustomerReviewsV2 = () => {
  const { t } = useTranslation()

  return (
    <S.Reviews>
      {CUSTOMER_REVIEWS_MAP_V2.map(({ id, text, name }) => (
        <S.Item key={id}>
          <S.Stars>
            <img src={stars} alt="stars" />
          </S.Stars>
          <S.Text>{t(text)}</S.Text>
          <S.Name>{t(name)}</S.Name>
        </S.Item>
      ))}
    </S.Reviews>
  )
}
