import React from 'react'

import { StyledKitOptionsPage as S } from 'pages/options-page/KitCheckboxOptionsPage.styles'
import { AnswerWithIcon, answerWithIconTheme } from 'storybook-ui'

import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'

import {
  TBuilderOptionData,
  TBuilderOptionProps,
  TPageProps,
} from 'models/common.model'

type TProps = {
  title: string
  subtitle?: string
  options?: TBuilderOptionData[]
  optionProps: TBuilderOptionProps
  isReverse?: boolean
} & TPageProps

export const KitRadioOptionsPage: React.FC<TProps> = ({
  title,
  subtitle,
  options,
  optionProps,
  isReverse,
}) => {
  return (
    <KitPageContainer>
      <PageTitle marginBottom={subtitle ? 8 : 24} textAlign="left">
        {title}
      </PageTitle>
      {subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}
      {options &&
        options.map(({ id, value, text, imageUrl, imageWidth, imageHeight }) =>
          imageUrl ? (
            <AnswerWithIcon
              {...optionProps}
              key={id}
              type="radio"
              theme={answerWithIconTheme.NUTRIMATE}
              value={value}
              reverse={isReverse}
              borderRadius="48px"
              spacingBetweenIconAndContent="16px"
              margin="0 0 12px 0"
              iconSrc={imageUrl}
              iconHeight={`${imageHeight}px`}
              iconWidth={`${imageWidth}px`}
            >
              <S.AnswerTitle>{text}</S.AnswerTitle>
            </AnswerWithIcon>
          ) : (
            <AnswerWithIcon
              {...optionProps}
              key={id}
              type="radio"
              theme={answerWithIconTheme.NUTRIMATE}
              value={value}
              reverse={isReverse}
              borderRadius="48px"
              spacingBetweenIconAndContent="0 0 0 0"
              margin="0 0 12px 0"
            >
              <S.AnswerTitle>{text}</S.AnswerTitle>
            </AnswerWithIcon>
          ),
        )}
    </KitPageContainer>
  )
}
