import { PaymentMethod, PaymentSystem } from 'modules/purchase/constants'

import { TProductId } from 'models/subscriptions.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import {
  OutbrainEventName,
  ScreenName,
  TaboolaEventName,
  TimeInterval,
} from 'root-constants'

const enum FacebookPixelEvents {
  SUBSCRIBE = 'Subscribe',
  START_TRIAL = 'StartTrial',
}
function handleLogFacebookPixelEvent(
  price: number,
  subscriptionId: string,
  uuid: string,
  eventType: string,
  currency: string,
): void {
  window.fbq(
    'track',
    eventType,
    {
      value: price,
      currency,
      subscription_id: subscriptionId,
      subscription_sid: subscriptionId,
    },
    { eventID: uuid },
  )
}

function handleLogPurchaseCompleted({
  productId,
  price,
  paymentMethod,
  currency,
  screenName,
  isTrialActive,
  paymentSystem,
  stripeAccountId,
  stripeAccountName,
}: {
  productId: string
  price: number
  paymentMethod: PaymentMethod
  currency: string
  screenName: ScreenName
  isTrialActive?: boolean
  paymentSystem?: PaymentSystem
  stripeAccountId: string
  stripeAccountName: string
}): void {
  eventLogger.logPurchaseCompleted({
    productId,
    priceDetails: {
      price,
      currency,
      trial: isTrialActive,
    },
    paymentMethod,
    screenName,
    paymentSystem,
    stripeAccountId,
    stripeAccountName,
  })
}

function handleLogGoogleAnalyticsEvent(
  subscriptionId: string,
  price: number,
  periodName: TimeInterval | null,
  periodQuantity: number | null,
  currency: string,
  screenName: ScreenName,
): void {
  googleAnalyticsLogger.logPurchaseCompleted({
    subscriptionId,
    price,
    periodName,
    periodQuantity,
    currency,
    screenName,
  })
}

export const logSuccessfulPayPalPayment = ({
  productId,
  price,
  subscriptionId,
  uuid,
  periodName,
  periodQuantity,
  paymentMethod,
  currency,
  trialPrice,
  trialPeriodDays,
  screenName,
  isTrialActive,
  email,
  stripeAccountId,
  stripeAccountName,
  paymentSystem,
}: {
  productId: TProductId
  price: number
  subscriptionId: string
  uuid: string
  periodName: TimeInterval | null
  periodQuantity: number | null
  paymentMethod: PaymentMethod
  currency: string
  trialPrice: number
  trialPeriodDays: number
  screenName: ScreenName
  isTrialActive?: boolean
  email: string
  paymentSystem?: PaymentSystem
  stripeAccountId: string
  stripeAccountName: string
}): void => {
  window.ttq.identify({ email })
  window.ttq.track('CompletePayment', {
    value: trialPrice || price,
    currency,
    content_id: subscriptionId,
  })
  window.snaptr('track', 'PURCHASE', {
    price: trialPrice || price,
    currency,
    transaction_id: subscriptionId,
    user_email: email,
  })

  window.obApi &&
    window.obApi('track', OutbrainEventName.PURCHASE, {
      orderValue: trialPrice || price,
      orderId: subscriptionId,
      currency: currency.toUpperCase(),
    })
  window._tfa &&
    window._tfa.push({
      notify: 'event',
      name: TaboolaEventName.PURCHASE,
      revenue: trialPrice || price,
      orderId: subscriptionId,
      currency: currency.toUpperCase(),
    })

  handleLogFacebookPixelEvent(
    trialPeriodDays ? trialPrice : price,
    subscriptionId,
    uuid,
    FacebookPixelEvents.SUBSCRIBE,
    currency,
  )

  if (trialPeriodDays) {
    handleLogFacebookPixelEvent(
      trialPrice,
      subscriptionId,
      uuid,
      FacebookPixelEvents.START_TRIAL,
      currency,
    )
  }

  handleLogPurchaseCompleted({
    productId,
    price,
    paymentMethod,
    currency,
    screenName,
    isTrialActive,
    paymentSystem,
    stripeAccountId,
    stripeAccountName,
  })
  handleLogGoogleAnalyticsEvent(
    subscriptionId,
    price,
    periodName,
    periodQuantity,
    currency,
    screenName,
  )
}
