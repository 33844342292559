import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  FEEDBACK_AUTHOR_MAP,
  FEEDBACK_TEXT_MAP,
  GENDER_NAME_MAP,
  IMAGE_SRC_MAP,
  LOST_WEIGHT_METRIC_MAP,
  LOST_WEIGHT_VALUE_MAP_V2,
} from 'pages/questions/Feedback/constants'

import { KitPageContainer } from 'components/PageContainer/KitPageContainer'
import { PageTitle } from 'components/PageTitle'

import {
  selectCurrentUserGender,
  selectLanguage,
  selectUserMeasurementSystem,
} from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { getCapitalizedString } from 'helpers/stringHelper'

import { TPageProps } from 'models/common.model'

import stars from 'assets/images/stars-2.png'

import { CDN_FOLDER_LINK, Language } from 'root-constants'

import { StyledFeedbackV2 as S } from './FeedbackV2.styles'

export const FeedbackV2: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const currentMeasurementSystem = useSelector(selectUserMeasurementSystem)
  const genderValue = useSelector(selectCurrentUserGender)
  const language = useSelector(selectLanguage)

  const lostWeight = useMemo(
    () =>
      `${LOST_WEIGHT_VALUE_MAP_V2[genderValue][currentMeasurementSystem]} ${t(
        LOST_WEIGHT_METRIC_MAP[currentMeasurementSystem],
      )}`,
    [currentMeasurementSystem, genderValue, t],
  )

  const feedbackAuthor = useMemo(
    () => `${t(FEEDBACK_AUTHOR_MAP[genderValue])}, − ${lostWeight}`,
    [genderValue, t, lostWeight],
  )

  const gender = useMemo(
    () => getCapitalizedString(t(GENDER_NAME_MAP[genderValue])),
    [genderValue, t],
  )

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.feedbacksV2.title', {
      lng: Language.EN,
      gender,
    }),
    nextPagePath,
  })

  return (
    <KitPageContainer
      hasContinueButton
      onContinueButtonClick={() => handleContinue('')}
    >
      <PageTitle marginBottom={24}>
        <Trans i18nKey="onboarding.feedbacksV2.title" values={{ gender }} />
      </PageTitle>
      <S.Card>
        <S.ImageContainer>
          <img
            src={`${CDN_FOLDER_LINK}${IMAGE_SRC_MAP[genderValue]}-${language}.png`}
            alt="before-after-img"
          />
        </S.ImageContainer>
        <S.Feedback>
          <S.FeedbackText>
            <Trans
              i18nKey={FEEDBACK_TEXT_MAP[genderValue]}
              values={{ value: lostWeight }}
            />
          </S.FeedbackText>
          <S.FeedbackAuthor>
            <S.AuthorName>{feedbackAuthor}</S.AuthorName>
            <S.Rating src={stars} alt="stars" />
          </S.FeedbackAuthor>
        </S.Feedback>
      </S.Card>
    </KitPageContainer>
  )
}
