import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import {
  CURRENCY_SYMBOLS,
  PurchaseProduct,
  WEEKS_BY_PRODUCT_NAME_MAP,
} from 'modules/purchase/constants'
import { TSubscriptionItemProps } from 'modules/purchase/types'

import { StyledSubscriptionItemV5 as S } from './SubscriptionItemV5.styles'

export const SubscriptionItemV5: React.FC<TSubscriptionItemProps> = ({
  subscription: { id, product, mainPrices, trialPrices, isDefault, currency },
  isSelected,
}) => {
  const { t } = useTranslation()

  const isSubscriptionTrial = useMemo(
    () =>
      trialPrices.fullPrice && trialPrices.fullPrice !== mainPrices.fullPrice,
    [trialPrices, mainPrices],
  )

  const oldPriceWithFractionalPart = useMemo(
    () =>
      product === PurchaseProduct.SEVEN_DAY
        ? trialPrices.oldPrices.beforeCustomDiscount?.fullPrice.toFixed(2)
        : mainPrices.fullPrice.toFixed(2),
    [product, mainPrices, trialPrices],
  )

  const noTrialCurrentPriceWithFractionalPart = useMemo(
    () => mainPrices.fullPrice.toFixed(2),
    [mainPrices],
  )

  const trialCurrentPriceWithFractionalPart = useMemo(
    () => trialPrices.fullPrice.toFixed(2),
    [trialPrices],
  )

  const pricePerDayWithFractionalPart = useMemo(
    () =>
      product === PurchaseProduct.SEVEN_DAY
        ? mainPrices.daily.toFixed(2)
        : trialPrices.daily.toFixed(2),
    [product, trialPrices, mainPrices],
  )

  const oldPricePerDayWithFractionalPart = useMemo(
    () => trialPrices.oldPrices.beforeCustomDiscount?.daily.toFixed(2),
    [trialPrices],
  )

  const period = useMemo(() => WEEKS_BY_PRODUCT_NAME_MAP[product], [product])

  return (
    <>
      {isDefault && (
        <S.PopularBadge>
          {t('purchase.subscriptionV2.mostPopularBadge')}
        </S.PopularBadge>
      )}
      <S.PlanContainer key={id} isPopular={isDefault} isSelected={isSelected}>
        <S.PlanColumn>
          <S.PlanPeriod>
            <Trans
              i18nKey="purchase.subscriptionV2.weeklyPeriod"
              values={{ period }}
            />
          </S.PlanPeriod>
          <S.PlanPrice>
            {isSubscriptionTrial ? (
              <>
                <S.PreviousPrice>
                  {CURRENCY_SYMBOLS[currency]}
                  {oldPriceWithFractionalPart}
                </S.PreviousPrice>
                <S.CurrentPrice>
                  {CURRENCY_SYMBOLS[currency]}
                  {trialCurrentPriceWithFractionalPart}
                </S.CurrentPrice>
              </>
            ) : (
              <S.CurrentPrice>
                {CURRENCY_SYMBOLS[currency]}
                {noTrialCurrentPriceWithFractionalPart}
              </S.CurrentPrice>
            )}
          </S.PlanPrice>
        </S.PlanColumn>
        <S.PlanColumn hasLabel isSelected={isSelected}>
          <S.DayPriceContainer>
            {isSubscriptionTrial && (
              <S.PreviousDayPrice>
                {CURRENCY_SYMBOLS[currency]}
                {oldPricePerDayWithFractionalPart}
              </S.PreviousDayPrice>
            )}
            <S.CurrentDayPrice>
              {CURRENCY_SYMBOLS[currency]}
              {pricePerDayWithFractionalPart}
            </S.CurrentDayPrice>
            <S.DayPricePeriod>
              {t('purchase.subscriptionV2.perDay')}
            </S.DayPricePeriod>
          </S.DayPriceContainer>
        </S.PlanColumn>
      </S.PlanContainer>
    </>
  )
}
