import {
  IRequestOptions,
  IRequestParams,
  IResponseResult,
  TResponseErrorData,
} from 'models/api.model'

import { goTo } from 'browser-history'

export class ApiService {
  private readonly urlPrefix: string

  constructor(urlPrefix: string) {
    this.urlPrefix = urlPrefix
  }

  public async makeRequest<T>(
    url: string,
    options: IRequestOptions,
  ): Promise<IResponseResult<T>> {
    try {
      const requestURL = this.buildURL(url, options.params)
      const requestBody = options.body
        ? JSON.stringify(options.body)
        : undefined
      const res = await fetch(requestURL, {
        method: options.method,
        headers: this.buildHeaders(options.headers),
        body: requestBody,
        credentials: 'include',
      })

      if (!res.ok) {
        return this.processError(res)
      }

      return this.processResponse<T>(res)
    } catch (error: any) {
      return this.processError(error)
    }
  }

  protected buildQuery(params?: IRequestParams): string {
    let query = ''

    if (params) {
      const items: string[] = []
      Object.entries(params).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((valueItem) =>
            items.push(
              `${encodeURIComponent(key)}=${encodeURIComponent(valueItem)}`,
            ),
          )
        } else {
          items.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        }
      })
      query = `?${items.join('&')}`
    }

    return query
  }

  protected buildURL(url: string, params?: IRequestParams): string {
    const query: string = this.buildQuery(params)

    return `${this.urlPrefix}/${url}${query}`
  }

  protected getDefaultHeaders(): Headers {
    return new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    })
  }

  protected buildHeaders(headers?: Headers): Headers {
    const baseHeaders = headers || this.getDefaultHeaders()

    if (!baseHeaders.get('Content-Type')) {
      baseHeaders.append('Content-Type', 'application/json')
    }

    return baseHeaders
  }

  protected async processResponse<T>(
    response: Response,
  ): Promise<IResponseResult<T>> {
    try {
      const result: T = await response.json()

      return {
        success: true,
        status: response.status,
        data: result,
      }
    } catch {
      return {
        success: true,
        status: response.status,
      }
    }
  }

  protected async processError(
    error: Response,
  ): Promise<IResponseResult<TResponseErrorData>> {
    if (error.status === 401) {
      goTo('/')
    }

    const data: TResponseErrorData = await error.json()

    return Promise.resolve<IResponseResult<TResponseErrorData>>({
      success: false,
      status: error.status,
      data,
    })
  }
}
