import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import dayjs from 'dayjs'
import { LOST_WEIGHT_PER_WEEK_MAP } from 'map-constants'

import {
  selectCurrentUserCurrentWeight,
  selectCurrentUserGoalWeight,
  selectUserMeasurementSystem,
} from 'root-redux/selects/common'

import { useUsersBmi } from 'hooks/useUsersBmi'

import { Period } from 'modules/purchase/constants'

import { WEEK_DAYS } from 'root-constants'

export const usePredictableData = () => {
  const goalWeight = useSelector(selectCurrentUserGoalWeight)
  const currentWeight = useSelector(selectCurrentUserCurrentWeight)
  const measurementSystem = useSelector(selectUserMeasurementSystem)

  const {
    currentBMI: {
      bmiValue: { value },
    },
  } = useUsersBmi()

  const lostWeightPerDay = useMemo(
    () =>
      Number(
        (
          LOST_WEIGHT_PER_WEEK_MAP[value][measurementSystem] / Period.SEVEN_DAYS
        ).toFixed(2),
      ),
    [value, measurementSystem],
  )

  const realPredictableDate = useMemo(() => {
    const countOfDay = Number(
      ((currentWeight - goalWeight) / lostWeightPerDay).toFixed(2),
    )

    return dayjs().add(countOfDay, 'day')
  }, [currentWeight, goalWeight, lostWeightPerDay])

  const hasWeekEarlyPredictableDate = useMemo(
    () =>
      !(
        realPredictableDate.isBefore(dayjs()) ||
        Number(realPredictableDate.diff(dayjs(), 'day', true).toFixed(1)) <=
          WEEK_DAYS
      ),
    [realPredictableDate],
  )

  const weekEarlyPredictableDate = useMemo(
    () => realPredictableDate.subtract(WEEK_DAYS, 'day'),
    [realPredictableDate],
  )

  return {
    predictableDate: hasWeekEarlyPredictableDate
      ? weekEarlyPredictableDate
      : realPredictableDate,
    hasWeekEarlyPredictableDate,
    realPredictableDate,
    weekEarlyPredictableDate,
    lostWeightPerDay,
  }
}
