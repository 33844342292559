import { getStripePurchaseCommonEventParams } from 'modules/purchase/helpers/getStripePurchaseCommonEventParams'

import { IAppState } from 'models/store.model'

export const getStripePurchaseStartedEventParams = (state: IAppState) => {
  const {
    productId,
    price,
    trialPeriodPrice,
    currency,
    screenName,
    stripePriceId,
    trialPriceId,
    stripeAccountId,
    stripeAccountName,
    paymentSystem,
  } = getStripePurchaseCommonEventParams(state)

  return {
    productId: trialPriceId ? productId : stripePriceId,
    priceDetails: {
      price,
      trial: !!trialPeriodPrice,
      currency,
    },
    screenName,
    stripeAccountName,
    stripeAccountId,
    paymentSystem,
  }
}
