import React, { useCallback, useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'
import { Spinner } from 'components/Spinner'

import { sendUserEmailConsentAction } from 'root-redux/actions/user'
import { selectIsFetching } from 'root-redux/selects/common'
import { selectUserStatus } from 'root-redux/selects/user'

import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { useNextStep } from 'hooks/useNextStep'
import { useUserStatus } from 'hooks/useUserStatus'

import { TPageProps } from 'models/common.model'

import imgSrc from 'assets/images/email-consent-v2.png'

import { goTo } from 'browser-history'
import { Language } from 'root-constants'

import { StyledEmailConsentV2 as S } from './EmailConsentV2.styles'

export const EmailConsentV2: React.FC<TPageProps> = ({
  nextPagePath,
  pageId,
}) => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const dispatch = useDispatch()

  const userStatus = useSelector(selectUserStatus)
  const isFetching = useSelector(selectIsFetching)

  const availableRoute = useUserStatus()
  const firstPageId = useGetPageInfo().steps[0].id

  useLayoutEffect(() => {
    if (!isFetching) {
      switch (true) {
        case userStatus?.hasSubscription:
          goTo({ pathname: availableRoute, search })
          break
        case !userStatus?.email.hasEmail:
          goTo({ pathname: firstPageId, search })
          break
      }
    }
  }, [search, userStatus, availableRoute, isFetching, firstPageId])

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.emailConsentV2.title', { lng: Language.EN }),
    nextPagePath,
  })

  const sendUserEmailConsent = useCallback(
    async (consented: boolean) => {
      await dispatch(sendUserEmailConsentAction({ consented }))
      handleContinue(
        t(consented ? 'actions.yesImIn' : 'actions.noThanks', {
          lng: Language.EN,
        }),
      )
    },
    [dispatch, handleContinue, t],
  )

  const handleYes = useCallback(
    () => sendUserEmailConsent(true),
    [sendUserEmailConsent],
  )

  const handleNo = useCallback(
    () => sendUserEmailConsent(false),
    [sendUserEmailConsent],
  )

  if (isFetching) {
    return <Spinner />
  }

  return (
    <KitPageContainer
      hasContinueButton
      hasSkipButton
      continueButtonContent={t('actions.yesImIn')}
      skipButtonContent={t('actions.noThanks')}
      onContinueButtonClick={handleYes}
      onSkipButtonClick={handleNo}
    >
      <PageTitle marginBottom={24} textAlign="left">
        {t('onboarding.emailConsentV2.title')}
      </PageTitle>
      <S.ImageContainer>
        <img src={imgSrc} alt="email-consent" />
      </S.ImageContainer>
    </KitPageContainer>
  )
}
