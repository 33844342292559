import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { FavouriteFoodsValue, RestrictionsValue } from 'value-constants'

import { Button } from 'components/Button'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import {
  selectCurrentFavouriteFoods,
  selectCurrentRestrictions,
  selectCurrentUserGender,
} from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import starsIcon from 'assets/images/alternative-stars-v2.png'
import banner from 'assets/images/meal-plan-banner.png'

import { Language } from 'root-constants'

import { StyledCustomizeMealPlan as S } from './CustomizeMealPlan.styles'
import { NEGATIVE_ITEMS_MAP, POSITIVE_ITEMS_MAP, REVIEW } from './constants'

export const CustomizeMealPlanV1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const favouriteFoods = useSelector(
    selectCurrentFavouriteFoods,
  ) as FavouriteFoodsValue[]
  const restrictions = useSelector(
    selectCurrentRestrictions,
  ) as RestrictionsValue[]
  const gender = useSelector(selectCurrentUserGender)

  const titleValues = useMemo(
    () => ({
      positiveItems:
        favouriteFoods.map((item) => t(POSITIVE_ITEMS_MAP[item])).join(' ') ||
        t(POSITIVE_ITEMS_MAP[FavouriteFoodsValue.MEAT]),
      negativeItems:
        restrictions.map((item) => t(NEGATIVE_ITEMS_MAP[item])).join(' ') ||
        t(NEGATIVE_ITEMS_MAP[RestrictionsValue.NO_GLUTEN]),
    }),
    [favouriteFoods, restrictions, t],
  )

  const { about, imageSrc, content } = useMemo(() => REVIEW[gender], [gender])

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.customizeMealPlanV1.title', {
      lng: Language.EN,
      ...titleValues,
    }),
    nextPagePath,
  })

  return (
    <>
      <S.Logo marginBottom={24} />
      <S.FixedContainer>
        <S.Banner>
          <img src={banner} alt="banner" />
        </S.Banner>
        <S.Container>
          <S.Review>
            <S.ReviewHeader>
              <S.ReviewPhoto>
                <img src={imageSrc} alt={gender} />
              </S.ReviewPhoto>
              <S.ReviewInfo>
                <S.Stars>
                  <img src={starsIcon} alt="stars" />
                </S.Stars>
                <S.ReviewAbout>{t(about)}</S.ReviewAbout>
              </S.ReviewInfo>
            </S.ReviewHeader>
            <S.ReviewContent>{t(content)}</S.ReviewContent>
          </S.Review>
        </S.Container>
      </S.FixedContainer>
      <S.Container>
        <S.Title>
          <Trans
            i18nKey="onboarding.customizeMealPlanV1.title"
            values={titleValues}
          />
        </S.Title>

        <StickyButtonContainer>
          <Button type="button" onClick={() => handleContinue('')}>
            {t('actions.continue')}
          </Button>
        </StickyButtonContainer>
      </S.Container>
    </>
  )
}
