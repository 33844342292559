import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button } from 'components/Button'
import { Container } from 'components/Container'

import {
  selectCurrentUserCurrentWeight,
  selectUserMeasurementSystem,
} from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { MeasurementSystem } from 'root-constants'

import { StyledLowerRisk as S } from './LowerRisk.styles'
import { FIVE_PERCENTS, QUESTION } from './constants'

export const LowerRisk: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const currentWeight = +useSelector(selectCurrentUserCurrentWeight)
  const measurementSystem = useSelector(selectUserMeasurementSystem)

  const satisfactoryWeight = useMemo(
    () => Math.round((currentWeight * FIVE_PERCENTS) / 100),
    [currentWeight],
  )

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  return (
    <Container>
      <S.Title>
        <Trans
          i18nKey="onboarding.lowerRisk.title"
          values={{
            weight: satisfactoryWeight,
            measurementSystem:
              measurementSystem === MeasurementSystem.METRIC
                ? t('commonComponents.kg')
                : t('commonComponents.lbs'),
          }}
        />
      </S.Title>
      <S.Subtitle>
        <Trans i18nKey="onboarding.lowerRisk.subtitle" />
      </S.Subtitle>
      <S.List>
        <S.ListItem>{t('onboarding.lowerRisk.diabetes')}</S.ListItem>
        <S.ListItem>{t('onboarding.lowerRisk.hypertension')}</S.ListItem>
        <S.ListItem>{t('onboarding.lowerRisk.heart')}</S.ListItem>
        <S.ListItem>{t('onboarding.lowerRisk.cholesterol')}</S.ListItem>
      </S.List>
      <S.FixedContainer>
        <Button type="button" onClick={() => handleContinue('')}>
          {t('actions.continue')}
        </Button>
      </S.FixedContainer>
    </Container>
  )
}
