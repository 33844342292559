import { ScaleOptionValue } from 'value-constants'

import { PageId } from 'page-constants'

export const STATEMENT_QUESTIONS = {
  [PageId.KIT_QUESTIONNAIRE_1]:
    'onboarding.kitStatementQuestionV1.kitQuestionnaireV1',
  [PageId.KIT_QUESTIONNAIRE_2]:
    'onboarding.kitStatementQuestionV1.kitQuestionnaireV2',
  [PageId.KIT_QUESTIONNAIRE_3]:
    'onboarding.kitStatementQuestionV1.kitQuestionnaireV3',
  [PageId.KIT_QUESTIONNAIRE_4]:
    'onboarding.kitStatementQuestionV1.kitQuestionnaireV4',
  [PageId.KIT_QUESTIONNAIRE_5]:
    'onboarding.kitStatementQuestionV1.kitQuestionnaireV5',
  [PageId.KIT_QUESTIONNAIRE_6]:
    'onboarding.kitStatementQuestionV1.kitQuestionnaireV6',
  [PageId.KIT_QUESTIONNAIRE_7]:
    'onboarding.kitStatementQuestionV1.kitQuestionnaireV7',
  [PageId.KIT_QUESTIONNAIRE_8]:
    'onboarding.kitStatementQuestionV1.kitQuestionnaireV8',
  [PageId.KIT_QUESTIONNAIRE_9]:
    'onboarding.kitStatementQuestionV1.kitQuestionnaireV9',
  [PageId.KIT_QUESTIONNAIRE_10]:
    'onboarding.kitStatementQuestionV1.kitQuestionnaireV10',
}

export const STATEMENT_QUESTION_ANSWERS = [
  {
    value: ScaleOptionValue.ONE,
    label: 1,
  },
  {
    value: ScaleOptionValue.TWO,
    label: 2,
  },
  {
    value: ScaleOptionValue.THREE,
    label: 3,
  },
  {
    value: ScaleOptionValue.FOUR,
    label: 4,
  },
  {
    value: ScaleOptionValue.FIVE,
    label: 5,
  },
]
