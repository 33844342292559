import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledUserInfoBlockV4 = {
  Container: styled.div`
    width: 100%;
    display: flex;
    border-radius: 16px;
    border: 1px solid ${Color.GREY_800};
    background-color: ${Color.WHITE};
    padding: 16px;
    position: relative;
  `,
  InfoList: styled.ul`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 183px;

    > :not(:last-child) {
      margin: 0 0 16px 0;
    }
  `,
  InfoItem: styled.li<{ bulletSrc: string }>`
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 0 0 48px;
    position: relative;

    &:before {
      position: absolute;
      content: url(${({ bulletSrc }) => bulletSrc});
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 36px;
      height: 36px;
    }
  `,
  Label: styled.h3`
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: ${Color.GREY_101};
    margin: 0 0 2px 0;
  `,
  InfoValue: styled.h3`
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: ${Color.GREY_100};
    word-break: break-word;
  `,
  Banner: styled.img`
    height: 188px;
    position: absolute;
    bottom: 0;
    right: 0;
  `,
}
