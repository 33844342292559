import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { ACTIVITY_DURING_WORK_MAP } from 'map-constants'

import {
  BMI_MAP,
  FASTING_LEVEL_MAP,
  IMAGES_MAP,
} from 'components/UserInfoBlock/constants'

import {
  selectActivityDuringWork,
  selectCurrentUserGender,
  selectFasting,
} from 'root-redux/selects/common'

import { useUsersBmi } from 'hooks/useUsersBmi'

import { getCapitalizedString } from 'helpers/stringHelper'

import bodyTypeBullet from 'assets/images/bullet-body.svg'
import activityLevelBullet from 'assets/images/bullet-chart-bar.svg'
import fastingLevelBullet from 'assets/images/bullet-clock.svg'

import { StyledUserInfoBlockV2 as S } from './UserInfoBlock.styles'

type TProps = {
  className?: string
}

export const UserInfoBlockV2: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()
  const gender = useSelector(selectCurrentUserGender)
  const activity = useSelector(selectActivityDuringWork)
  const fasting = useSelector(selectFasting)

  const { currentBMI } = useUsersBmi()

  const activityLevel = useMemo(
    () =>
      activity && getCapitalizedString(t(ACTIVITY_DURING_WORK_MAP[activity])),
    [activity, t],
  )

  const fastingLevel = useMemo(
    () => fasting && getCapitalizedString(t(FASTING_LEVEL_MAP[fasting])),
    [fasting, t],
  )

  const bmiValue = useMemo(
    () =>
      currentBMI && getCapitalizedString(t(BMI_MAP[currentBMI.bmiValue.value])),
    [currentBMI, t],
  )

  const imgSrc = useMemo(
    () => gender && IMAGES_MAP[gender][currentBMI.bmiValue.value],
    [gender, currentBMI],
  )

  return (
    <S.Container className={className}>
      <S.InfoList>
        <S.InfoItem bulletSrc={fastingLevelBullet}>
          <S.Title>
            {t('onboarding.profileSummaryV2.userInfo.fastingLevel')}
          </S.Title>
          <S.Info>{fastingLevel}</S.Info>
        </S.InfoItem>
        <S.InfoItem bulletSrc={bodyTypeBullet}>
          <S.Title>
            {t('onboarding.profileSummaryV2.userInfo.bodyType')}
          </S.Title>
          <S.Info>{bmiValue}</S.Info>
        </S.InfoItem>
        <S.InfoItem bulletSrc={activityLevelBullet}>
          <S.Title>
            {t('onboarding.profileSummaryV2.userInfo.activityLevel')}
          </S.Title>
          <S.Info>{activityLevel}</S.Info>
        </S.InfoItem>
      </S.InfoList>
      <S.ImageContainer>
        <img src={imgSrc} alt="user-info" />
      </S.ImageContainer>
    </S.Container>
  )
}
