import styled, { css } from 'styled-components'

import { Button } from 'components/Button'

import { Color } from 'root-constants'

type TPropsWrapper = {
  isShown: boolean
}

const commonTitleStyles = css`
  font-style: normal;
  font-weight: 700;
  text-align: center;
  color: ${Color.GREY_100};
`

export const StyledPaymentWaitingModal = {
  Wrapper: styled.div<TPropsWrapper>`
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${({ isShown }) => (isShown ? 103 : -1)};
    width: max(100%, var(--min-screen-width));
    height: calc(var(--full-height) - calc(var(--full-height) - 100%));
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.25);
    opacity: ${({ isShown }) => (isShown ? 1 : 0)};
    transition-duration: 100ms;
    transition-property: opacity;
    transition-timing-function: ease-out;
  `,
  Content: styled.div`
    width: calc(100% - 40px);
    max-width: 320px;
    margin: auto;
    padding: 24px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    border-radius: 10px;
    background-color: ${Color.WHITE};
  `,
  LottieContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  `,
  Animation: styled.div`
    width: 132px;
    height: 132px;
  `,
  Title: styled.div`
    ${commonTitleStyles};
    padding: 5px 0 9px;
    font-size: 24px;
    line-height: 28px;
  `,
  Subtitle: styled.div`
    ${commonTitleStyles};
    padding: 10px 15px;
    font-size: 15px;
    line-height: 18px;
  `,
  Disclaimer: styled.p`
    padding: 16px 0 0 0;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: ${Color.DISABLED};
  `,
  Info: styled.div`
    padding: 10px 7px 32px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: ${Color.GREY_600};
  `,
  ErrorButton: styled(Button)`
    background-color: ${Color.ERROR};
    height: 50px;
  `,
  SuccessButton: styled(Button)`
    height: 50px;
    margin-top: 32px;
    background-color: ${Color.PRIMARY};
  `,
}
