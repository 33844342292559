import styled from 'styled-components'

import pageBg from 'assets/images/green-banner.png'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledYoEffect = {
  Wrapper: styled.div`
    padding: 8px 0 24px;
    background-image: url(${pageBg});
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top center;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      background-size: 360px auto;
    }
  `,
  Title: styled.h1`
    max-width: 360px;
    margin: 0 auto 32px;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: ${Color.WHITE};
    text-align: center;
  `,
  Graph: styled.img`
    margin: 0 auto 12px;
    max-width: 320px;
    min-height: 269px;

    @media (min-width: ${MediaBreakpoint.MIN_PHONE}px) {
      max-width: 360px;
    }
  `,
  Card: styled.div`
    display: flex;
    margin-bottom: 19px;
    align-items: center;
    padding: 8px 16px 8px 0;

    &:last-child {
      margin-bottom: 0;
    }
  `,
  CardIconFirst: styled.img`
    min-width: 56px;
    min-height: 56px;
    margin-right: 12px;
  `,
  CardIconSecond: styled.img`
    min-width: 50px;
    min-height: 50px;
    margin-right: 15px;
  `,
  CardText: styled.p`
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: ${Color.DARK_BLUE};

    i {
      text-decoration: underline;
      font-style: normal;
    }
  `,
}
