import styled, { keyframes } from 'styled-components'

import { Button } from 'components/Button'
import { Timer } from 'components/Timer'

import arrow from 'assets/images/animated-arrow.png'

import { Color, MediaBreakpoint } from 'root-constants'

type TProps = {
  bgColor?: string
  isTimerHidden?: boolean
  isVioletTimerInHeader?: boolean
}

const animation = keyframes`
  from {
    transform: translate(-120px);
  }
  to {
    transform: translate(328px);
  }
`

export const StyledIntroMediaV2 = {
  Wrapper: styled.div`
    width: 100%;
    margin: 0 0 24px 0;
  `,
  BeforeAfter: styled.div`
    position: relative;
    display: flex;
    overflow: hidden;
    width: 100%;
    min-height: 311px;
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0.2)
      ),
      linear-gradient(222.37deg, #6fd09a 9.16%, #ffda97 94.02%);
    border-radius: 16px 16px 0 0;
    &::before {
      position: absolute;
      content: '';
      width: 120px;
      height: 328px;
      background-image: url(${arrow});
      background-repeat: round;
      animation: 0.6s linear 1s infinite running slidein;
      animation-name: ${animation};
    }

    svg {
      border-radius: 16px 16px 0 0;
    }
  `,
  TimerContainer: styled.div<TProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: ${({ isTimerHidden }) =>
      isTimerHidden ? `${MediaBreakpoint.MAX_PHONE}px` : `100%`};

    padding: 16px 16px 16px 32px;
    border-radius: 0 0 16px 16px;
    background: ${({ bgColor }) => bgColor || Color.PRIMARY};
    box-shadow: 0 12px 32px rgba(0, 0, 0, 0.12);

    ${({ isTimerHidden }) =>
      isTimerHidden &&
      `
    justify-content: center;
      position: fixed;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      padding: 13px 0;
      z-index: 11;
      border-radius: 0;
    `};
  `,
  TimerLabel: styled.span`
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    align-items: center;
    margin: 0 6px 0 0;
    color: ${Color.WHITE};
  `,
  Timer: styled(Timer)<TProps>`
    strong {
      color: ${({ isHeaderTimer }) =>
        isHeaderTimer ? Color.WHITE : Color.PRIMARY};
    }
    span {
      color: ${Color.GREY_100};
    }
  `,
  Button: styled(Button)<TProps>`
    ${({ isTimerHidden }) =>
      isTimerHidden &&
      `
    display: none;
    `};

    height: 100%;
    width: auto;
    margin: 0;
    padding: 16px 26px;
    border-radius: 16px;

    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: ${Color.WHITE};
    background-color: ${({ color }) => color};
  `,
}
