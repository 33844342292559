import React, { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import femaleImage from 'assets/images/support-banner-female.png'
import maleImage from 'assets/images/support-banner-male.png'

import { Gender, Language } from 'root-constants'

import { StyledSupport as S } from './Support.styles'

export const SupportV1: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const gender = useSelector(selectCurrentUserGender)

  const imgSrc = useMemo(
    () => (gender === Gender.MALE ? maleImage : femaleImage),
    [gender],
  )

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.supportV1.title', { lng: Language.EN }),
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  return (
    <KitPageContainer hasContinueButton onContinueButtonClick={handleNextClick}>
      <S.Banner>
        <img src={imgSrc} alt="banner" />
      </S.Banner>
      <PageTitle textAlign="left" marginBottom={16}>
        {t('onboarding.supportV1.title')}
      </PageTitle>
      <S.Subtitle>
        <Trans i18nKey="onboarding.supportV1.subtitle" />
      </S.Subtitle>
      <S.Info>
        <Trans i18nKey="onboarding.supportV1.info" />
      </S.Info>
    </KitPageContainer>
  )
}
