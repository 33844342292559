import styled from 'styled-components'
import { PrimaryGoalValue } from 'value-constants'

import { Container } from 'components/Container'

import bannerV2 from 'assets/images/man-mountains.png'
import bannerV1 from 'assets/images/man-park.png'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledReasonToGiveUp = {
  Container: styled(Container)`
    padding: 16px 16px 0;
  `,
  Title: styled.h1`
    color: ${Color.GREY_100};
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 20px;
    text-align: center;
  `,
  Subtitle: styled.h2`
    color: ${Color.GREY_100};
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 20px;
    text-align: center;
  `,
  Info: styled.h3`
    color: ${Color.GREY_100};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
  `,
  Banner: styled.div`
    aspect-ratio: 20 / 13;
    background-image: url(${bannerV1});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 0 20px 50%;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    position: relative;
    transform: translateX(-50%);
    width: 100vw;

    &[data-goal='${PrimaryGoalValue.GET_HEALTHY}'] {
      background-image: url(${bannerV2});
    }

    :before {
      background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgb(255, 255, 255)
      );
      bottom: -1px;
      content: '';
      height: 15%;
      left: 0;
      position: absolute;
      width: 100%;
    }
  `,
}
