import React from 'react'

import { PrimerPayPalButton } from 'modules/purchase/components/PrimerPayPalButton'

import { StyledOneClickPayment as S } from './OneClickPayment.styles'

export const OneClickPayment = () => (
  <S.Container>
    <S.PaymentRequestButton />
    <PrimerPayPalButton />
  </S.Container>
)
