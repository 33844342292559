import { PeriodValue } from 'value-constants'

export const TITLE_SUBSTRING = {
  [PeriodValue.MENSTRUATION]:
    'onboarding.helpCalmCravings.titleSubstringMenstruation',
  [PeriodValue.PERIMENOPAUSE_MENOPAUSEE]:
    'onboarding.helpCalmCravings.titleSubstringMenopause',
}

export const LIST_TITLE = {
  [PeriodValue.MENSTRUATION]:
    'onboarding.helpCalmCravings.listDescriptionMenstruation',
  [PeriodValue.PERIMENOPAUSE_MENOPAUSEE]:
    'onboarding.helpCalmCravings.listDescriptionMenopause',
}

export const BENEFITS_LIST = [
  'onboarding.helpCalmCravings.options.overeating',
  'onboarding.helpCalmCravings.options.hormones',
  'onboarding.helpCalmCravings.options.metabolism',
  'onboarding.helpCalmCravings.options.weight',
]
