import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { AnswerButtonV2 } from 'components/AnswerButtonV2'
import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import {
  selectCurrentUserPrimaryGoal,
  selectCurrentVariantCohortToUse,
} from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Cohort } from 'root-constants'

import {
  ANSWER_OPTIONS,
  MENS_TITLE_MAP,
  QUESTION,
  TITLE_MAP,
} from './constants'

export const ChallengesYouFaced: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const primaryGoal = useSelector(selectCurrentUserPrimaryGoal)
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const [answers, setAnswers] = useState<string[]>([])

  const pageTitle = useMemo(
    () =>
      cohortToUse === Cohort.MF_MEN_FLOW
        ? MENS_TITLE_MAP[primaryGoal]
        : TITLE_MAP[primaryGoal],
    [primaryGoal, cohortToUse],
  )

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked) {
          setAnswers([...answers, value])
        } else {
          setAnswers(answers.filter((item) => item !== value))
        }
      },
    }),
    [pageId, answers],
  )

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue(answers)
  }, [answers, handleContinue])

  return (
    <Container>
      <PageTitle marginBottom={24}>{t(pageTitle)}</PageTitle>
      {ANSWER_OPTIONS.map(({ value, title }) => (
        <Option
          key={value}
          {...optionProps}
          marginBottom="12"
          value={value}
          checked={answers.includes(value)}
        >
          <AnswerButtonV2 withCheckbox>
            <h3>{t(title)}</h3>
          </AnswerButtonV2>
        </Option>
      ))}
      <StickyButtonContainer>
        <Button
          type="button"
          disabled={!answers.length}
          onClick={handleNextClick}
        >
          {t('actions.continue')}
        </Button>
      </StickyButtonContainer>
    </Container>
  )
}
