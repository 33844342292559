import styled from 'styled-components'

import { Button } from 'components/Button'
import { PageContainer } from 'components/PageContainer'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { Color } from 'root-constants'

export const StyledThankYouForYourTrust = {
  PageContainer: styled(PageContainer)`
    padding: 0 16px 0 16px;
  `,
  ImageContainer: styled.div`
    height: 240px;
    aspect-ratio: 280/240;
    margin: 0 0 24px 0;
  `,
  TextContainer: styled.div`
    margin: 0 0 66px 0;
  `,
  Title: styled.h2`
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: ${Color.GREY_100};
    text-align: left;
    margin: 0 0 24px 0;
  `,
  Text: styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${Color.GREY_100};
    text-align: left;

    :not(:last-of-type) {
      margin: 0 0 16px 0;
    }
  `,
  StickyButtonContainer: styled(StickyButtonContainer)`
    max-width: initial;
  `,
  Button: styled(Button)`
    max-width: 100%;
  `,
}
