import styled from 'styled-components'

import { Color } from 'root-constants'

type TProps = {
  percentage: number
}

export const StyledKitPersonalSummaryV1 = {
  Container: styled.div`
    background-color: ${Color.WHITE};
    border: 1px solid ${Color.GREY_800};
    border-radius: 12px;
    margin-bottom: 24px;
    padding: 16px;
  `,
  Header: styled.div`
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    column-gap: 4px;
    margin-bottom: 48px;
  `,
  HeaderContent: styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  `,
  HeaderTitle: styled.h3`
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
  `,
  HeaderSubtitle: styled.p`
    color: ${Color.GREY_101};
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
  `,
  HeaderLabel: styled.div<{ backgroundColor: string }>`
    text-wrap: nowrap;
    background-color: ${({ backgroundColor }) => backgroundColor};
    border-radius: 6px;
    color: ${Color.WHITE};
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    padding: 4px 8px;
  `,
  RangeTrack: styled.div`
    background: linear-gradient(
      90deg,
      #fe8900 0%,
      #f6e48d 13.54%,
      #a9cd50 29.17%,
      #a9cd50 49.52%,
      #a9cd50 69.79%,
      #f6e48d 85.94%,
      #dd3b17 100%
    );
    border-radius: 20px;
    height: 8px;
    margin-bottom: 16px;
    position: relative;
    width: 100%;
  `,
  RangeThumb: styled.div<TProps>`
    background-color: ${Color.GREY_100};
    border: 9px solid ${Color.WHITE};
    border-radius: 50%;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.16);
    height: 28px;
    left: ${({ percentage }) => percentage}%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 28px;
  `,
  RangeValue: styled.div<TProps>`
    background-color: ${Color.GREY_100};
    border-radius: 32px;
    color: ${Color.WHITE};
    font-size: 13px;
    font-weight: 700;
    left: ${({ percentage }) => percentage}%;
    letter-spacing: 0.5px;
    line-height: 16px;
    padding: 5px 9px;
    position: absolute;
    top: -40px;
    transform: translateX(calc(-50% - 1px));

    &:after {
      border-color: ${Color.GREY_100} transparent transparent transparent;
      border-style: solid;
      border-width: 4px 4px 0 4px;
      bottom: -4px;
      content: '';
      height: 0;
      left: calc(50% - 3px);
      position: absolute;
      width: 0;
    }
  `,
  RangeThresholds: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  Threshold: styled.span`
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
  `,
}
