import React, { FC, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Spinner } from 'components/Spinner'

import {
  UpsellBenefits,
  UpsellSubscriptionBlock,
} from 'modules/purchase/components'

import { eventLogger } from 'services/eventLogger.service'

import { ScreenName } from 'root-constants'

import { StyledUpsellPaywallV1 as S } from './UpsellPaywallV1.styles'

type TProps = {
  isUpsellInProgress: boolean
  arePricesReady: boolean
  makeUpsell: (screenName: ScreenName) => void
}

export const UpsellPaywallV1: FC<TProps> = ({
  makeUpsell,
  isUpsellInProgress,
  arePricesReady,
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    eventLogger.logUpsellPurchaseShown(ScreenName.UPSELL_SWITCHER)
  }, [])

  const handleMakeUpsell = useCallback(() => {
    makeUpsell(ScreenName.UPSELL_SWITCHER)
  }, [makeUpsell])

  return (
    <S.Root>
      <S.Container>
        <S.Title>{t('upsell.title')}</S.Title>
        <S.Subtitle>{t('upsell.subTitle')}</S.Subtitle>

        <UpsellSubscriptionBlock makeUpsell={handleMakeUpsell} />
        <UpsellBenefits />
        {(isUpsellInProgress || !arePricesReady) && <Spinner />}
      </S.Container>
    </S.Root>
  )
}
