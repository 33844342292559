import { ProgressHTMLAttributes } from 'react'

import styled, { css } from 'styled-components'

import { Color, MediaBreakpoint } from 'root-constants'

interface IProps extends ProgressHTMLAttributes<HTMLProgressElement> {
  color?: string
  background?: string
  pointsNumber: number
}

type TStepNumberProps = {
  isActive: boolean
  isPassed?: boolean
}

const progressValueStyles = css`
  background-color: currentColor;
  transition: all 0.2s ease-out;
`

export const StyledPostPaymentProgressBar = {
  Wrapper: styled.div`
    margin: 50px auto 20px;
    display: flex;
    justify-content: center;
  `,
  StepBlock: styled.div`
    position: relative;
    display: flex;
    align-items: center;
  `,
  StepBlockProgress: styled.progress<IProps>`
    overflow: hidden;
    max-width: ${({ pointsNumber }) =>
      `${(280 - 16 * (pointsNumber + 1)) / pointsNumber}px`};
    height: 4px;
    appearance: none;
    border: none;
    display: block;

    &::-moz-progress-bar {
      ${progressValueStyles}
    }

    &::-ms-fill {
      ${progressValueStyles}
    }

    &::-webkit-progress-bar {
      background: #eceaf4;
    }

    &::-webkit-progress-value {
      background: ${Color.PRIMARY};
    }

    @media (min-width: ${MediaBreakpoint.MIN_PHONE}px) {
      max-width: ${({ pointsNumber }) =>
        `${(320 - 16 * (pointsNumber + 1)) / pointsNumber - 5}px`};
    }
  `,
  Step: styled.div<TStepNumberProps>`
    display: flex;
    position: relative;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #eceaf4;
    transition: background 0.2s linear;
    color: ${Color.DISABLED};

    ${({ isActive }) =>
      isActive &&
      `
          -webkit-box-shadow: inset 0px 0px 0px 2px ${Color.PRIMARY}; 
          box-shadow: inset 0px 0px 0px 2px ${Color.PRIMARY};
          background: #f7f8f9;
          color: ${Color.GREY_100}
        `}

    ${({ isPassed }) =>
      isPassed &&
      `
          background: transparent;
          color: ${Color.PRIMARY}
        `}
  `,
  StepText: styled.span`
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 400;
    font-size: 11px;
    line-height: 12px;
    text-align: center;
  `,
}
