import styled, { css } from 'styled-components'

import { Button } from 'components/Button'

import {
  AppReachedV1,
  BoostResults,
  CustomerReviewsV3,
  DisclaimerV2,
  DiscountTimerV2,
  FeaturedBlockV2,
  IntroMediaV5,
  LimitedOffer,
  SecurityInfo,
  SubscriptionsBlock,
  VideoReviews,
  WeightLossJourney,
} from 'modules/purchase/components'
import { MoneyBackGuaranteeV3 } from 'modules/purchase/components/MoneyBackGuaranteeV3'
import { PersonalizedPlan } from 'modules/purchase/components/PersonalizedPlan'

import { Color, MediaBreakpoint } from 'root-constants'

export const commonWrapperStyle = css`
  width: 100%;
  margin: 0 auto;
  padding: 0 16px 16px 16px;

  @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
    width: ${MediaBreakpoint.MEDIUM_PHONE}px;
  }
`

export const StyledPurchaseVariant5 = {
  Wrapper: styled.div`
    ${commonWrapperStyle};
  `,
  DiscountTimer: styled(DiscountTimerV2)`
    position: sticky;
    top: 0;
    z-index: 100;
    margin: 0 auto 16px;
    background-color: ${Color.WHITE};
  `,
  IntroMediaV5: styled(IntroMediaV5)`
    margin: 0 0 32px 0;
  `,
  PersonalizedPlan: styled(PersonalizedPlan)`
    margin: 0 0 32px 0;
  `,
  LimitedOffer: styled(LimitedOffer)`
    margin: 0 0 16px 0;
  `,
  SubscriptionsBlock: styled(SubscriptionsBlock)`
    margin: 0 0 16px 0;
  `,
  MoneyBackText: styled.p`
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: ${Color.GREY_100};
    text-decoration: underline;
    text-align: center;
    margin: 0 0 16px 0;
  `,
  Disclaimer: styled(DisclaimerV2)`
    height: 86px;
    margin: 0 0 24px 0;
  `,
  DisclaimerV2: styled(DisclaimerV2)`
    height: 86px;
    margin: 0 0 46px 0;
  `,
  MoneyBackGuaranteeV3: styled(MoneyBackGuaranteeV3)`
    margin: 0 0 32px 0;
  `,
  VideoReviews: styled(VideoReviews)`
    margin: 0 auto 32px;
  `,
  WeightLossJourney: styled(WeightLossJourney)`
    margin: 0 0 32px 0;
  `,
  AppReached: styled(AppReachedV1)`
    margin: 0 0 32px 0;
  `,
  FeaturedBlock: styled(FeaturedBlockV2)`
    margin: 0 0 32px 0;
  `,
  BoostResults: styled(BoostResults)`
    margin: 0 0 32px 0;
  `,
  CustomerReviewsV3: styled(CustomerReviewsV3)`
    margin: 0 0 32px 0;
  `,

  SecurityInfo: styled(SecurityInfo)`
    margin-bottom: 0;
    padding: 50px 20px 20px;
    background-color: ${Color.WHITE};
    box-shadow: 0 4px 12px -2px rgba(59, 59, 62, 0.16);
    border-radius: 14px;

    h2 {
      padding-bottom: 10px;
    }

    p {
      padding-bottom: 20px;
      font-weight: normal;
      font-size: 14px;
    }

    img {
      background: ${Color.WHITE};
      box-shadow: 0 4px 12px -2px rgba(59, 59, 62, 0.16);
      padding: 3px 26px;
      border-radius: 14px;
      position: absolute;
      top: -30px;
      left: 27%;
      max-width: 156px;
    }
  `,
  Button: styled(Button)`
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    text-transform: capitalize;
    margin: 0 auto 16px auto;
    max-width: 100%;
  `,
}
