import styled from 'styled-components'

export const StyledStickyButtonContainer = {
  Wrapper: styled.div`
    width: 100%;
    max-width: 360px;
    position: sticky;
    bottom: 0;
    margin: 0 auto;
    padding: 24px 0;
    background: linear-gradient(
      rgba(255, 255, 255, 0) 0.25%,
      rgb(255, 255, 255) 26.14%
    );
  `,
}
