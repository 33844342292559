import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { ANIMATION_PATH } from 'components/LoseWeightGraph/constants'

import { selectUserMeasurementSystem } from 'root-redux/selects/common'

import { useLostWeightInfo } from 'hooks/useLostWeightInfo'

import { getFormattedTodaysDate } from 'helpers/dateHelper'

import { CDN_FOLDER_LINK, WEIGHT_UNITS } from 'root-constants'

import {
  Footnote,
  Goal,
  GraphContent,
  Label,
  LabelResult,
  Now,
  StyledGraph as S,
  Title,
} from './LoseWeightGraph.styles'
import { useDelayedAnimationPlaying } from './hooks/useDelayedAnimationPlaying'

export const LoseWeightGraph: React.FC = (props) => {
  const { t } = useTranslation()
  const [animationRef] = useDelayedAnimationPlaying({
    animationPath: `${CDN_FOLDER_LINK}${ANIMATION_PATH}`,
  })

  const measurementSystem = useSelector(selectUserMeasurementSystem)

  const [, titleDate, goalDate, middleDate, currentWeight] = useLostWeightInfo()

  return (
    <S.Wrapper {...props}>
      <Title>
        {t('result.graph.title')}
        {titleDate}
      </Title>
      <GraphContent>
        <Now>
          <strong>{currentWeight}</strong>
          <span>{WEIGHT_UNITS[measurementSystem]}</span>
        </Now>
        <Goal>
          <Trans i18nKey="result.graph.goalWeight" />
        </Goal>
        <S.GraphRef ref={animationRef} />
        <S.DateLabels>
          <Label>{getFormattedTodaysDate()}</Label>
          <Label>{middleDate}</Label>
          <LabelResult>{goalDate}</LabelResult>
        </S.DateLabels>
      </GraphContent>
      <Footnote>{t('result.graph.footnote')}</Footnote>
    </S.Wrapper>
  )
}
