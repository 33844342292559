export const QUESTION =
  'What kind of feelings do you want to achieve with NutriMate?'

const enum FEELINGS_VALUES {
  CONFIDENCE = 'confidence',
  SELF_EMPOWERMENT = 'self_empowerment',
  SELF_WORTH = 'self_worth',
  SELF_ACCEPTANCE = 'self_acceptance',
  SELF_BELIEF = 'self_belief',
}

export const ANSWER_OPTIONS = [
  {
    value: FEELINGS_VALUES.CONFIDENCE,
    title: 'onboarding.feelingsToAchieve.options.confidence',
  },
  {
    value: FEELINGS_VALUES.SELF_EMPOWERMENT,
    title: 'onboarding.feelingsToAchieve.options.selfEmpowerment',
  },
  {
    value: FEELINGS_VALUES.SELF_WORTH,
    title: 'onboarding.feelingsToAchieve.options.selfWorth',
  },
  {
    value: FEELINGS_VALUES.SELF_ACCEPTANCE,
    title: 'onboarding.feelingsToAchieve.options.selfAcceptance',
  },
  {
    value: FEELINGS_VALUES.SELF_BELIEF,
    title: 'onboarding.feelingsToAchieve.options.selfBelief',
  },
]
