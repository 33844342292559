import styled from 'styled-components'

import { ListWithEmojis } from 'components/ListWithEmojis'
import { PageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'
import { PercentageProgress } from 'components/PercentageProgress'
import { PresaleGraph } from 'components/PresaleGraph'
import { Reviews } from 'components/Reviews'
import { StickyButtonContainer } from 'components/StickyButtonContainer'
import { SwipedPersonalizedPlan } from 'components/SwipedPersonalizedPlan'
import { UserInfoBlockV1 } from 'components/UserInfoBlock'

import { MAX_COLUMN_WIDTH, MediaBreakpoint } from 'root-constants'

export const StyledPresaleV2 = {
  PageContainer: styled(PageContainer)`
    padding: 0 16px 0 16px;
  `,
  PageTitle: styled(PageTitle)`
    padding: 24px 0 0 0;
  `,
  UserInfoBlock: styled(UserInfoBlockV1)`
    margin: 0 0 16px 0;
  `,
  PresaleGraph: styled(PresaleGraph)`
    margin: 0 0 32px 0;
  `,
  Title: styled.h2`
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    margin: 0 0 24px 0;
  `,
  PersonalizedPlan: styled(SwipedPersonalizedPlan)`
    width: 100%;
    margin: 0 auto 32px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: ${MAX_COLUMN_WIDTH}px;
    }

    background: linear-gradient(0deg, #f4f4f6, #f4f4f6),
      linear-gradient(0deg, rgba(255, 237, 204, 0.7), rgba(255, 237, 204, 0.7)),
      linear-gradient(
        180deg,
        rgba(255, 244, 205, 0.5) 0%,
        rgba(255, 159, 0, 0.5) 100%
      );

    border-radius: 24px;
  `,
  ListWithEmojis: styled(ListWithEmojis)`
    margin: 0 0 32px 0;
  `,
  PercentageProgress: styled(PercentageProgress)`
    margin: 0 0 32px 0;
  `,
  Reviews: styled(Reviews)`
    margin: 0 0 16px 0;
  `,
  StickyButtonContainer: styled(StickyButtonContainer)`
    z-index: 2;
    padding: 0 0 24px 0;
    background: transparent;
  `,
}
