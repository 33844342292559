import React, { useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useDelayedAnimationPlaying } from 'components/LoseWeightGraph/hooks/useDelayedAnimationPlaying'

import { useLockScroll } from 'hooks/useLockScroll'

import infoCircleIcon from 'assets/images/info-circle.png'
import closeIcon from 'assets/images/white-cross-icon.png'

import { CDN_FOLDER_LINK } from 'root-constants'

import {
  CircleProgress,
  Disclaimer,
  DisclaimerClose,
  DisclaimerText,
  DisclaimerTitle,
  Footnote,
  Info,
  InfoButton,
  PercentageContainer,
  PercentageSymbol,
  PercentageValue,
  Progress,
  StyledPercentageProgress as S,
} from './PercentageProgress.styles'
import { ANIMATION_FRAME_FREQUENCY, ANIMATION_PATH } from './constants'

type TProps = {
  title: string
  note: string
  percentageValue: number
}

export const PercentageProgress: React.FC<TProps> = ({
  title,
  note,
  percentageValue,
}) => {
  const { t } = useTranslation()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [percentage, setPercentage] = useState(0)

  const [animationRef, isAnimationStarted] = useDelayedAnimationPlaying({
    animationPath: `${CDN_FOLDER_LINK}${ANIMATION_PATH}`,
  })

  useLockScroll(isModalVisible)

  useEffect(() => {
    let intervalId: ReturnType<typeof setInterval>
    if (isAnimationStarted) {
      intervalId = setInterval(() => {
        if (percentage < percentageValue) {
          const increasedPercentage = percentage + 1
          setPercentage(increasedPercentage)

          return
        }

        clearInterval(intervalId)
      }, ANIMATION_FRAME_FREQUENCY)
    }

    return () => clearInterval(intervalId)
  }, [percentage, percentageValue, isAnimationStarted])

  const toggleModalVisibility = useCallback(() => {
    setIsModalVisible(!isModalVisible)
  }, [isModalVisible])

  return (
    <S.Root>
      <S.Title>{t(title)}</S.Title>
      <Info>
        <CircleProgress ref={animationRef} />
        <PercentageContainer>
          <Progress>
            <PercentageValue>{percentage}</PercentageValue>
            <PercentageSymbol>%</PercentageSymbol>
          </Progress>
        </PercentageContainer>
        <Footnote>
          <Trans i18nKey={note} />
        </Footnote>
        <InfoButton onClick={toggleModalVisibility} type="button">
          <img src={infoCircleIcon} alt="Know more" />
        </InfoButton>
        {isModalVisible && (
          <S.Modal>
            <Disclaimer>
              <DisclaimerTitle>
                {t('result.percentageProgress.disclaimerTitle')}
              </DisclaimerTitle>
              <DisclaimerText>
                {t('result.percentageProgress.disclaimerContent')}
              </DisclaimerText>
              <DisclaimerClose onClick={toggleModalVisibility} type="button">
                <img src={closeIcon} alt="Close modal" />
              </DisclaimerClose>
            </Disclaimer>
          </S.Modal>
        )}
      </Info>
    </S.Root>
  )
}
