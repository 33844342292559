import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledPerfectSolutionV3 = {
  ImageContainer: styled.div`
    aspect-ratio: 1 / 1;
    height: 220px;
    margin: 0 auto 24px;
  `,
  Text: styled.p`
    color: ${Color.GREY_101};
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;

    :not(:last-of-type) {
      margin: 0 0 16px 0;
    }

    strong {
      color: ${Color.GREY_100};
      font-weight: 600;
    }
  `,
}
