import { AgeRangeValue } from 'value-constants'

import firstRange from 'assets/images/age-range-v2-first.png'
import fourthRange from 'assets/images/age-range-v2-fourth.png'
import secondRange from 'assets/images/age-range-v2-second.png'
import thirdRange from 'assets/images/age-range-v2-third.png'

export const RANGES = [
  {
    value: AgeRangeValue.AR_18_25,
    text: '18-25',
    img: firstRange,
  },
  {
    value: AgeRangeValue.AR_26_35,
    text: '26-35',
    img: secondRange,
  },
  {
    value: AgeRangeValue.AR_36_45,
    text: '36-45',
    img: thirdRange,
  },
  {
    value: AgeRangeValue.AR_46_PLUS,
    text: '46+',
    img: fourthRange,
  },
]
