import React from 'react'

import { StyledProgressRounded as S } from './ProgressRounded.styles'

type TProps = {
  value: number
  fontWeight?: number
}

export const ProgressRounded: React.FC<TProps> = ({
  value,
  fontWeight,
  ...props
}) => (
  <S.Container {...props}>
    <S.Svg viewBox="0 0 100 100" value={value}>
      <circle r="45" cx="50" cy="50" />
      <circle r="45" cx="50" cy="50" />
    </S.Svg>
    <S.Label fontWeight={fontWeight}>
      {value}
      <strong>%</strong>
    </S.Label>
  </S.Container>
)
