import styled from 'styled-components'

import { Disclaimer } from 'modules/purchase/components'

import { Color } from 'root-constants'

export const StyledEmailSubscriptionItem = {
  Title: styled.h2`
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 16px;
    text-align: center;
  `,
  Plan: styled.div`
    display: flex;
    margin-bottom: 24px;
    justify-content: space-between;
  `,
  Period: styled.span`
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: ${Color.GREY_100};
    text-transform: capitalize;
  `,
  Price: styled.span`
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: ${Color.PRIMARY};
  `,
  Discount: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 24px 0;
  `,
  DiscountButton: styled.button`
    display: inline-flex;
    align-items: center;
    padding: 0 24px;
    background: ${Color.ORANGE};
    border-radius: 40px;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: ${Color.WHITE};
    border: 0;
    min-height: 32px;
  `,
  OldPrice: styled.span`
    text-decoration: line-through ${Color.ORANGE};
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: ${Color.DISABLED};
  `,
  Disclaimer: styled(Disclaimer)`
    margin-bottom: 40px;
  `,
}
