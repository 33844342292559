import styled from 'styled-components'

import { Option } from 'components/Option'

import { Color } from 'root-constants'

export const StyledPaymentMethodsV2 = {
  Container: styled.div`
    display: flex;
    justify-content: space-between;
    column-gap: 8px;
  `,
  Option: styled(Option)<{ isActive?: boolean }>`
    width: 100%;
    border: 1px solid
      ${({ isActive }) => (isActive ? Color.PRIMARY : '#d9d9d9')};
    filter: grayscale(${({ isActive }) => (isActive ? 0 : 1)});
    border-radius: 6px;
    margin: 0;
    padding: 10px 16px;
  `,

  ImageContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 52px;
    width: 100%;

    img {
      max-height: 52px;
      max-width: 68px;
    }
  `,
}
