import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { BmiValue } from 'value-constants'

import { Container } from 'components/Container'
import { PersonalSummaryV2 } from 'components/PersonalSummaryV2'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import {
  selectAnswers,
  selectCurrentUserGender,
  selectCurrentUserPrimaryGoal,
  selectCurrentVariantCohortToUse,
} from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'
import { useUsersBmi } from 'hooks/useUsersBmi'

import { TPageProps } from 'models/common.model'

import femaleImg from 'assets/images/bmi-female.png'
import maleImg from 'assets/images/bmi-male.png'
import seniorWomenImg from 'assets/images/senior-women.png'

import { CustomPageId, PageId } from 'page-constants'
import { COHORTS_BASED_ON_FLOW2, Cohort, Gender } from 'root-constants'

import { StyledProfileSummaryV1 as S } from './ProfileSummary.styles'
import {
  ACTIVITY_TO_MARKUP_MAP,
  BMI_VALUE_TO_MARKUP_MAP,
  DESKTOP_COHORTS_ACTIVITY_LEVEL_TO_MARKUP_MAP,
  DESKTOP_COHORTS_EXERCISES_TO_MARKUP_MAP,
  GOAL_TO_MARKUP_MAP,
  QUESTION,
} from './constants'

export const ProfileSummaryV1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const answers = useSelector(selectAnswers)
  const gender = useSelector(selectCurrentUserGender)
  const goal = useSelector(selectCurrentUserPrimaryGoal)
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const { t } = useTranslation()
  const { currentBMI } = useUsersBmi()

  const bmiLabel = useMemo(
    () => BMI_VALUE_TO_MARKUP_MAP[currentBMI.bmiValue.value],
    [currentBMI.bmiValue.value],
  )

  const BMIDescription = useMemo(() => {
    if (
      currentBMI.bmiValue.value === BmiValue.NORMAL &&
      gender === Gender.MALE
    ) {
      return 'onboarding.profileSummary.description.normal.male'
    }
    if (
      currentBMI.bmiValue.value === BmiValue.NORMAL &&
      gender === Gender.FEMALE
    ) {
      return 'onboarding.profileSummary.description.normal.female'
    }

    return bmiLabel.description
  }, [bmiLabel.description, currentBMI.bmiValue.value, gender])

  const activity = useMemo(
    () =>
      COHORTS_BASED_ON_FLOW2.includes(cohortToUse)
        ? answers?.[PageId.ACTIVITY_LEVEL_1]
        : answers?.[CustomPageId.ACTIVITY_DURING_WORK],
    [answers, cohortToUse],
  )

  const activityMarkup = useMemo(
    () =>
      COHORTS_BASED_ON_FLOW2.includes(cohortToUse)
        ? ACTIVITY_TO_MARKUP_MAP[activity as string]?.level
        : DESKTOP_COHORTS_ACTIVITY_LEVEL_TO_MARKUP_MAP[activity as string],
    [activity, cohortToUse],
  )

  const exercisesMarkup = useMemo(
    () =>
      COHORTS_BASED_ON_FLOW2.includes(cohortToUse)
        ? ACTIVITY_TO_MARKUP_MAP[activity as string]?.exercises
        : DESKTOP_COHORTS_EXERCISES_TO_MARKUP_MAP[
            answers?.[PageId.WORKOUT_1] as string
          ],
    [activity, answers, cohortToUse],
  )

  const imgSrc = useMemo(() => {
    if (gender === Gender.MALE) {
      return maleImg
    }
    if (cohortToUse === Cohort.MF_WOMEN_45) {
      return seniorWomenImg
    }

    return femaleImg
  }, [gender, cohortToUse])

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  return (
    <Container>
      <PersonalSummaryV2 backgroundColor={bmiLabel.color} />
      <S.List>
        <li>
          <strong>{t('onboarding.profileSummary.goal')}</strong>
          <S.Answer>{t(GOAL_TO_MARKUP_MAP[goal])}</S.Answer>
        </li>
        <li>
          <strong>{t('onboarding.profileSummary.exercise')}</strong>
          <S.Answer>{t(exercisesMarkup)}</S.Answer>
        </li>
        <li>
          <strong>{t('onboarding.profileSummary.level')}</strong>
          <S.Answer>{t(activityMarkup)}</S.Answer>
        </li>
        <S.Image src={imgSrc} alt="" />
      </S.List>
      <S.BmiDescription
        backgroundImage={bmiLabel.backgroundImage}
        color={bmiLabel.color}
      >
        <div>
          <S.BmiTitle>{t('onboarding.profileSummary.bmi')}</S.BmiTitle>
          <S.BmiValue>{currentBMI.bmiIndex.toFixed(1)}</S.BmiValue>
        </div>
        <S.Description>
          <Trans i18nKey={BMIDescription} />
        </S.Description>
      </S.BmiDescription>
      <StickyButtonContainer>
        <S.Button onClick={() => handleContinue('')}>
          {t('actions.continue')}
        </S.Button>
      </StickyButtonContainer>
    </Container>
  )
}
