import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { RANGES } from 'pages/questions/AgeRangeV3/constants'

import { Footer } from 'components/Footer'
import { OptionType } from 'components/Option'
import { PageContainer } from 'components/PageContainer'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'
import { useTitleFromUrl } from 'hooks/useTitleFromUrl'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { CustomPageId } from 'page-constants'
import { Language } from 'root-constants'

import { StyledAgeRangeV3 as S } from './AgeRangeV3.styles'

export const AgeRangeV3: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()
  const { title, subtitle } = useDynamicOBConfig()
  const { title: titleFromUrl, subText: subtitleFromUrl } = useTitleFromUrl()

  const handleContinue = useNextStep({
    pageId: CustomPageId.AGE_RANGE,
    question:
      titleFromUrl ||
      title ||
      t('onboarding.ageRangeV3.title', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: CustomPageId.AGE_RANGE,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
        eventLogger.logFirstPageCompleted({
          question:
            titleFromUrl ||
            title ||
            t('onboarding.ageRangeV3.title', { lng: Language.EN }),
          answers: value,
        })
      },
    }),
    [
      handleChange,
      handleContinue,
      setIsAnswersDisabled,
      t,
      title,
      titleFromUrl,
    ],
  )

  return (
    <PageContainer>
      <S.Title>
        {titleFromUrl || title || t('onboarding.ageRangeV3.title')}
      </S.Title>
      <S.Subtitle>
        {subtitleFromUrl || subtitle || t('onboarding.ageRangeV3.subtitle')}
      </S.Subtitle>
      <S.Disclaimer>{t('onboarding.ageRangeV3.disclaimer')}</S.Disclaimer>

      <S.OptionsContainer>
        {RANGES.map(({ value, text, img }) => (
          <S.Option
            {...optionProps}
            key={value}
            disabled={isAnswersDisabled}
            value={value}
          >
            <S.Action>
              <S.RangeImage src={img} alt="range" />
              <S.Answer />
              <S.Range>{text}</S.Range>
            </S.Action>
          </S.Option>
        ))}
      </S.OptionsContainer>
      <Footer />
    </PageContainer>
  )
}
