import React from 'react'

import { Swiper as SwiperClass } from 'swiper/types'

import { StyledSliderWithScaledActiveSlideV2 as S } from './SliderWithScaledActiveSlideV2.styles'

type TProps = {
  onSlideChange?: (swiper: SwiperClass) => void
  children: React.ReactNode
}
export const SliderWithScaledActiveSlideV2: React.FC<TProps> = ({
  onSlideChange,
  children,
}) => (
  <S.Carousel
    pagination={{
      clickable: true,
    }}
    navigation
    slidesPerView="auto"
    centeredSlides
    loop
    onSlideChange={onSlideChange}
  >
    {children}
  </S.Carousel>
)
