import styled from 'styled-components'

import { PageTitle } from 'components/PageTitle'

import { Color, Emojis } from 'root-constants'

export const StyledGainWeight = {
  PageTitleV1: styled(PageTitle)`
    margin: 0 0 32px 0;
  `,
  PageTitleV2: styled(PageTitle)`
    margin: 0 0 24px 0;
  `,
  ImageContainerV1: styled.div`
    aspect-ratio: 328/200;
    height: 200px;
    margin: 0 0 32px 0;
  `,
  ImageContainerV2: styled.div`
    aspect-ratio: 328/228;
    height: 228px;
    margin: 0 0 32px 0;
  `,
  Info: styled.p`
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: ${Color.GREY_100};
    margin: 0 0 16px 0;
  `,
  ListV1: styled.ul`
    margin: 0 0 8px 0;
  `,
  ListV2: styled.ul`
    margin: 0 0 20px 0;
  `,
  ListItem: styled.li`
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: ${Color.GREY_100};
    padding: 0 0 0 28px;
    position: relative;

    strong {
      font-weight: 800;
    }

    &:not(:last-of-type) {
      margin: 0 0 16px 0;
    }

    &::before {
      position: absolute;
      content: '${Emojis.STAR}';
      top: 0;
      left: 0;
      font-size: 20px;
      line-height: 20px;
      width: 20px;
      height: 20px;
    }
  `,
}
