import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { AnswerButtonV2 } from 'components/AnswerButtonV2'
import { Container } from 'components/Container'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Gender } from 'root-constants'

import { OPTION_IMAGES, OPTION_VALUES, QUESTION } from './constants'

export const DreamBody: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const gender = useSelector(selectCurrentUserGender)
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const isMale = useMemo(() => gender === Gender.MALE, [gender])

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, setIsAnswersDisabled, handleContinue],
  )

  return (
    <Container>
      <PageTitle marginBottom={24}>{t('onboarding.dreamBody.title')}</PageTitle>
      <Option
        {...optionProps}
        marginBottom="12"
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.FIT}
      >
        <AnswerButtonV2 iconSrc={OPTION_IMAGES[gender].FIT}>
          <h3>{t('onboarding.dreamBody.options.fit')}</h3>
        </AnswerButtonV2>
      </Option>
      <Option
        {...optionProps}
        marginBottom="12"
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.ATHLETIC}
      >
        <AnswerButtonV2 iconSrc={OPTION_IMAGES[gender].ATHLETIC}>
          <h3>{t('onboarding.dreamBody.options.athletic')}</h3>
        </AnswerButtonV2>
      </Option>
      <Option
        {...optionProps}
        marginBottom="24"
        disabled={isAnswersDisabled}
        value={isMale ? OPTION_VALUES.SWOLE : OPTION_VALUES.CURVY}
      >
        <AnswerButtonV2 iconSrc={OPTION_IMAGES[gender].CURVY}>
          <h3>
            {t(
              isMale
                ? 'onboarding.dreamBody.options.swole'
                : 'onboarding.dreamBody.options.curvy',
            )}
          </h3>
        </AnswerButtonV2>
      </Option>
    </Container>
  )
}
