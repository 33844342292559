import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Option, OptionType } from 'components/Option'

import { selectSubscriptionList } from 'root-redux/selects/common'

import { SubscriptionItemV4 } from 'modules/purchase/components/SubscriptionItemV4'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'
import {
  selectSubscriptionId,
  selectSubscriptionIntroDifPercents,
} from 'modules/purchase/redux/selects'

import { StyledEmailSubscriptionsV2 as S } from './EmailSubscriptionsV2.styles'

type TProps = {
  className?: string
}

export const EmailSubscriptionsV2: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const discount = useSelector(selectSubscriptionIntroDifPercents)
  const subscriptions = useSelector(selectSubscriptionList)
  const selectedSubscriptionId = useSelector(selectSubscriptionId)

  const handleChange = useCallback(
    (value: string) => {
      const checkedSubscription = subscriptions.find(({ id }) => id === value)

      if (checkedSubscription) {
        dispatch(setSelectedSubscriptionAction(checkedSubscription))
      }
    },
    [dispatch, subscriptions],
  )

  return (
    <div className={className}>
      <S.Title>{t('purchaseEmail.subscriptionTitleV2')}</S.Title>
      <S.DiscountBlock>
        <S.DiscountBadge>
          <Trans
            i18nKey="purchaseEmail.discountLabelV2"
            values={{ discount }}
          />
        </S.DiscountBadge>
      </S.DiscountBlock>
      <S.Subscriptions>
        {subscriptions.map((subscription) => (
          <Option
            type={OptionType.RADIO}
            name="subscription"
            value={subscription.id}
            key={subscription.id}
            onChange={handleChange}
          >
            <SubscriptionItemV4
              subscription={subscription}
              isSelected={subscription.id === selectedSubscriptionId}
            />
          </Option>
        ))}
      </S.Subscriptions>
      <S.Disclaimer />
    </div>
  )
}
