import React, { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button } from 'components/Button'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { selectCurrentUserPrimaryGoal } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledReasonToGiveUp as S } from './ReasonToGiveUp.styles'
import { PRIMARY_GOAL_MAP, QUESTION } from './constants'

export const ReasonToGiveUp: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const primaryGoal = useSelector(selectCurrentUserPrimaryGoal)

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  const goal = useMemo(() => t(PRIMARY_GOAL_MAP[primaryGoal]), [primaryGoal, t])

  return (
    <S.Container>
      <S.Title>
        <Trans i18nKey="onboarding.reasonToGiveUp.title" values={{ goal }} />
      </S.Title>
      <S.Banner data-goal={primaryGoal} />
      <S.Subtitle>
        <Trans i18nKey="onboarding.reasonToGiveUp.subtitle" />
      </S.Subtitle>
      <S.Info>{t('onboarding.reasonToGiveUp.info')}</S.Info>
      <StickyButtonContainer>
        <Button type="button" onClick={handleNextClick}>
          {t('actions.gotIt')}
        </Button>
      </StickyButtonContainer>
    </S.Container>
  )
}
