import { BodyPartValue } from 'value-constants'

import improveAreasManPng from 'assets/images/improve-men.png'
import improveAreasSeniorWomanPng from 'assets/images/improve-senior-women.png'
import improveAreasWomanPng from 'assets/images/improve-women.png'
import improveAreasMenFlowPng from 'assets/images/men-target-areas.png'
import armsLineSvgV2 from 'assets/images/sprite/arms-line-v2.svg'
import armsLineSvgV3 from 'assets/images/sprite/arms-line-v3.svg'
import armsLineSvg from 'assets/images/sprite/arms-line.svg'
import bellyLineSvgV2 from 'assets/images/sprite/belly-line-v2.svg'
import bellyLineSvg from 'assets/images/sprite/belly-line.svg'
import chestLineSvgV2 from 'assets/images/sprite/chest-line-v2.svg'
import chestLineSvg from 'assets/images/sprite/chest-line.svg'
import hipsLineSvgV2 from 'assets/images/sprite/hips-line-v2.svg'
import hipsLineSvg from 'assets/images/sprite/hips-line.svg'
import legsLineSvgV2 from 'assets/images/sprite/legs-line-v2.svg'
import legsLineSvg from 'assets/images/sprite/legs-line.svg'

import { Gender } from 'root-constants'

const DEFAULT_OPTIONS = [
  {
    value: BodyPartValue.ARMS,
    lineSvg: armsLineSvg,
    lineWidth: 62,
    lineTop: -8,
  },
  {
    value: BodyPartValue.CHEST,
    lineSvg: chestLineSvg,
    lineWidth: 93,
    lineTop: 0,
  },
  {
    value: BodyPartValue.BELLY,
    lineSvg: bellyLineSvg,
    lineWidth: 103,
    lineTop: -30,
  },
  {
    value: BodyPartValue.HIPS,
    lineSvg: hipsLineSvg,
    lineWidth: 90,
    lineTop: -40,
  },
  {
    value: BodyPartValue.LEGS,
    lineSvg: legsLineSvg,
    lineWidth: 95,
    lineTop: -40,
  },
]

export const WOMEN_SENIOR_DATA_MAP = {
  imgSrc: improveAreasSeniorWomanPng,
  options: [
    {
      value: BodyPartValue.ARMS,
      lineSvg: armsLineSvgV3,
      lineWidth: 66,
      lineTop: -10,
    },
    {
      value: BodyPartValue.CHEST,
      lineSvg: chestLineSvg,
      lineWidth: 93,
      lineTop: -4,
    },
    {
      value: BodyPartValue.BELLY,
      lineSvg: bellyLineSvg,
      lineWidth: 93,
      lineTop: -30,
    },
    {
      value: BodyPartValue.HIPS,
      lineSvg: hipsLineSvg,
      lineWidth: 80,
      lineTop: -40,
    },
    {
      value: BodyPartValue.LEGS,
      lineSvg: legsLineSvg,
      lineWidth: 88,
      lineTop: -40,
    },
  ],
}

export const MEN_FLOW_DATA_MAP = {
  imgSrc: improveAreasMenFlowPng,
  options: [
    {
      value: BodyPartValue.ARMS,
      lineSvg: armsLineSvgV2,
      lineWidth: 66,
      lineTop: -8,
    },
    {
      value: BodyPartValue.CHEST,
      lineSvg: chestLineSvgV2,
      lineWidth: 93,
      lineTop: 0,
    },
    {
      value: BodyPartValue.BELLY,
      lineSvg: bellyLineSvgV2,
      lineWidth: 103,
      lineTop: -30,
    },
    {
      value: BodyPartValue.HIPS,
      lineSvg: hipsLineSvgV2,
      lineWidth: 90,
      lineTop: -26,
    },
    {
      value: BodyPartValue.LEGS,
      lineSvg: legsLineSvgV2,
      lineWidth: 95,
      lineTop: -20,
    },
  ],
}

export const DEFAULT_DATA_MAP = {
  [Gender.FEMALE]: {
    imgSrc: improveAreasWomanPng,
    options: DEFAULT_OPTIONS,
  },
  [Gender.MALE]: {
    imgSrc: improveAreasManPng,
    options: DEFAULT_OPTIONS,
  },
}
