import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Container } from 'components/Container'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { CustomPageId } from 'page-constants'

import { StyledPrimaryGoalV3 as S } from './PrimaryGoalV3.styles'
import { PRIMARY_GOAL_V3_OPTIONS, QUESTION } from './constants'

export const PrimaryGoalV3: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId: CustomPageId.PRIMARY_GOAL,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: CustomPageId.PRIMARY_GOAL,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <Container>
      <PageTitle marginBottom={56}>
        <Trans i18nKey="onboarding.primaryGoalV3.title" />
      </PageTitle>
      {PRIMARY_GOAL_V3_OPTIONS.map(({ value, title, icon }) => (
        <Option
          key={value}
          {...optionProps}
          marginBottom="12"
          disabled={isAnswersDisabled}
          value={value}
        >
          <S.AnswerButtonV2 iconSrc={icon}>
            <h3>{t(title)}</h3>
          </S.AnswerButtonV2>
        </Option>
      ))}
    </Container>
  )
}
