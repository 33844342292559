import styled from 'styled-components'

import { InputWithValidationIcons } from 'components/InputWithValidationIcons'

export const StyledInput = {
  Input: styled(InputWithValidationIcons)`
    margin-bottom: 10px;
    font-weight: 700;
  `,
}
