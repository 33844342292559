import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { PersonalSummaryV3 } from 'components/PersonalSummaryV3'
import { UserInfoBlockV2 } from 'components/UserInfoBlock/UserInfoBlockV2'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Language } from 'root-constants'

import { StyledProfileSummaryV2 as S } from './ProfileSummary.styles'

export const ProfileSummaryV2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.profileSummaryV2.title', { lng: Language.EN }),
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  return (
    <Container>
      <S.Title>{t('onboarding.profileSummaryV2.title')}</S.Title>
      <S.ContentBlock>
        <PersonalSummaryV3 />
        <UserInfoBlockV2 />
      </S.ContentBlock>

      <S.StickyButtonContainer>
        <Button type="button" onClick={handleNextClick}>
          {t('actions.continue')}
        </Button>
      </S.StickyButtonContainer>
    </Container>
  )
}
