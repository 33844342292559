import styled from 'styled-components'

import {
  CheckoutSummaryV2,
  TrialCheckoutSummaryV2,
} from 'modules/purchase/components'

import closeSvg from 'assets/images/close-icon.svg'

import { Color } from 'root-constants'

export const StyledCheckoutVariant3 = {
  Wrapper: styled.div<{ isVisible: boolean }>`
    width: 100%;
    display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
    margin: 16px auto 60px;
    text-align: center;
  `,

  Title: styled.h2`
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: ${Color.GREY_100};
    margin: 0 0 24px 0;
  `,

  CloseButton: styled.button`
    padding: 0;
    display: block;
    position: absolute;
    top: 56px;
    right: 16px;
    width: 24px;
    height: 24px;
    border: none;
    outline: none;
    cursor: pointer;
    background-size: 20px 20px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${closeSvg});
    opacity: 0.3;
  `,
  CheckoutSummaryV2: styled(CheckoutSummaryV2)`
    margin: 0 0 24px 0;
  `,
  TrialCheckoutSummaryV2: styled(TrialCheckoutSummaryV2)`
    margin: 0 0 24px 0;
  `,
  PaymentMethodsTitle: styled.h4`
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: ${Color.GREY_100};
    margin: 0 0 16px 0;
  `,
}
