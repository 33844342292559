import styled from 'styled-components'

import { Button } from 'components/Button'
import { PageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { Color } from 'root-constants'

export const StyledSleepingTimeFeedback = {
  PageContainer: styled(PageContainer)`
    min-height: calc(100vh - 45px);
    justify-content: space-between;
  `,
  ImageContainer: styled.div`
    align-self: flex-start;
    aspect-ratio: 1 / 1;
    height: 48px;
    margin-bottom: 24px;
  `,
  PageTitle: styled(PageTitle)`
    text-align: left;
  `,
  Info: styled.h4`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 12px;

    strong {
      font-weight: 700;
    }
  `,
  Period: styled.ul`
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    padding: 0 8px;
    row-gap: 12px;
    width: 100%;
  `,
  PeriodItem: styled.li`
    align-items: center;
    column-gap: 10px;
    display: flex;
  `,
  Circle: styled.span`
    background-color: ${Color.PRIMARY};
    border-radius: 50%;
    height: 8px;
    width: 8px;
  `,
  PeriodContent: styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

    strong {
      font-weight: 700;
    }
  `,
  CallToAction: styled.h4`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 24px;

    strong {
      font-weight: 700;
    }
  `,
  StickyButtonContainer: styled(StickyButtonContainer)`
    max-width: initial;
  `,
  Button: styled(Button)`
    max-width: 100%;
  `,
}
