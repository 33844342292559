import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import lottie from 'lottie-web/build/player/lottie_light'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { CDN_FOLDER_LINK, Gender } from 'root-constants'

import {
  BeforeAfter,
  Header,
  Label,
  StyledBodyChange as S,
  Subtitle,
  Title,
} from './BodyChange.styles'
import { MEN_ANIMATION_PATH, WOMEN_ANIMATION_PATH } from './constants'

export const BodyChange = () => {
  const { t } = useTranslation()

  const gender = useSelector(selectCurrentUserGender)

  const isFemale = useMemo(() => gender === Gender.FEMALE, [gender])

  const beforeAfterAnimationRef = useCallback(
    (node: HTMLDivElement) => {
      if (node) {
        lottie.loadAnimation({
          container: node,
          path: isFemale
            ? `${CDN_FOLDER_LINK}${WOMEN_ANIMATION_PATH}`
            : `${CDN_FOLDER_LINK}${MEN_ANIMATION_PATH}`,
          loop: true,
        })
      }
    },
    [isFemale],
  )

  return (
    <S.Root>
      <Header>
        <Title>{t('result.bodyChange.title')}</Title>
        <Subtitle>{t('result.bodyChange.subtitle')}</Subtitle>
        <S.Labels>
          <Label>{t('result.bodyChange.labelBefore')}</Label>
          <Label>{t('result.bodyChange.labelAfter')}</Label>
        </S.Labels>
      </Header>
      <BeforeAfter ref={beforeAfterAnimationRef} />
    </S.Root>
  )
}
