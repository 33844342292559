import React, { useCallback, useLayoutEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import {
  GET_SUBSCRIPTION_LIST,
  getSubscriptionListAction,
} from 'root-redux/actions/common'
import { selectActionList } from 'root-redux/selects/common'
import { selectUserSubscriptionDuration } from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { useVatInfo } from 'hooks/useHasVatInfo'

import {
  MAKE_UPSELL,
  makeUpsellAction,
} from 'modules/purchase/redux/actions/upsell'

import { PageId } from 'page-constants'
import {
  ScreenName,
  SubscriptionDuration,
  SubscriptionListType,
  SubscriptionTags,
} from 'root-constants'

import { UpsellPaywallV1 } from './UpsellPaywallV1'
import { UpsellPaywallV2 } from './UpsellPaywallV2'

export const UpsellPaywallProvider = () => {
  const dispatch: TAppDispatch = useDispatch()

  const fetchingActionsList = useSelector(selectActionList)
  const subscriptionDuration = useSelector(selectUserSubscriptionDuration)

  const hasVatInfo = useVatInfo()
  const { currentPageId } = useGetPageInfo()

  const isAlternativeVariant = useFeatureIsOn('fas_530_new_upsell')

  const hasOneMonthSubscription = useMemo(
    () => subscriptionDuration === SubscriptionDuration.ONE_MONTH,
    [subscriptionDuration],
  )

  const hasUpsellPaywallV2 = useMemo(
    () => currentPageId === PageId.UPSELL_PAYWALL_2 && hasOneMonthSubscription,
    [currentPageId, hasOneMonthSubscription],
  )

  const isUpsellInProgress = useMemo(
    () => fetchingActionsList?.includes(MAKE_UPSELL),
    [fetchingActionsList],
  )

  const arePricesReady = useMemo(
    () => !fetchingActionsList?.includes(GET_SUBSCRIPTION_LIST),
    [fetchingActionsList],
  )

  const { subscriptionType, tags } = useMemo(() => {
    const upsellPaywallV2Tags = [
      hasVatInfo ? SubscriptionTags.TAX : SubscriptionTags.NO_TAX,
      SubscriptionTags.UPSELL_LONG,
      ...(isAlternativeVariant ? [SubscriptionTags.LOWER_PRICES] : []),
    ].join(',')

    return {
      subscriptionType: hasUpsellPaywallV2
        ? SubscriptionListType.UPSELL
        : SubscriptionListType.ONE_TIME,
      tags: hasUpsellPaywallV2 ? upsellPaywallV2Tags : SubscriptionTags.NO_TAX,
    }
  }, [hasUpsellPaywallV2, isAlternativeVariant, hasVatInfo])

  useLayoutEffect(() => {
    dispatch(getSubscriptionListAction(subscriptionType, tags))
  }, [dispatch, subscriptionType, tags])

  const makeUpsell = useCallback(
    (screenName: ScreenName) => {
      dispatch(
        makeUpsellAction({
          screenName,
          isInApp: !hasUpsellPaywallV2,
        }),
      )
    },
    [dispatch, hasUpsellPaywallV2],
  )

  return hasUpsellPaywallV2 ? (
    <UpsellPaywallV2
      arePricesReady={arePricesReady}
      isUpsellInProgress={isUpsellInProgress}
      makeUpsell={makeUpsell}
    />
  ) : (
    <UpsellPaywallV1
      arePricesReady={arePricesReady}
      isUpsellInProgress={isUpsellInProgress}
      makeUpsell={makeUpsell}
    />
  )
}
