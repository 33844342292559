import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectAnswers } from 'root-redux/selects/common'

import { addDaysToCurrentDate } from 'helpers/dateHelper'

import { MAXIMUM_WEIGHT_LOSS_PERCENTAGE } from 'root-constants'

export const useLostWeightInfo = () => {
  const answers = useSelector(selectAnswers)

  return useMemo(() => {
    const currentWeight = (answers?.current_weight as string) || '70'
    const goalWeight = (answers?.goal_weight as string) || '60'
    const daysToGoal = Math.round(
      (parseFloat(currentWeight) - parseFloat(goalWeight as string)) /
        (parseFloat(currentWeight) * MAXIMUM_WEIGHT_LOSS_PERCENTAGE),
    )

    const middle = addDaysToCurrentDate(daysToGoal / 2)
    const goal = addDaysToCurrentDate(daysToGoal)
    const goalDateFullFormat = addDaysToCurrentDate(daysToGoal, 'MMMM D, YYYY')
    const goalDateTitle = addDaysToCurrentDate(daysToGoal, 'MMMM YYYY')

    return [goalDateFullFormat, goalDateTitle, goal, middle, currentWeight]
  }, [answers?.current_weight, answers?.goal_weight])
}
