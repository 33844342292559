import React, { FC, useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button } from 'components/Button'
import { Logo } from 'components/Logo'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import {
  selectCurrentUserGender,
  selectUserMeasurementSystem,
} from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import stars from 'assets/images/stars.png'
import female from 'assets/images/sticking-plan-female.png'
import male from 'assets/images/sticking-plan-male.png'

import { Gender, Language, MeasurementSystem } from 'root-constants'

import { StyledStickingPlanV2 as S } from './StickingPlanV2.styles'
import { LOST_WEIGHT_MAP_V2 } from './constants'

export const StickingPlanV2: FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const gender = useSelector(selectCurrentUserGender)
  const currentMeasurementSystem = useSelector(selectUserMeasurementSystem)

  const { imageSrc, name, review } = useMemo(
    () => ({
      imageSrc: gender === Gender.FEMALE ? female : male,
      name: t(`onboarding.stickingPlanV2.reviews.${gender}.name`, {
        lostWeight: `${LOST_WEIGHT_MAP_V2[currentMeasurementSystem]} ${
          currentMeasurementSystem === MeasurementSystem.METRIC
            ? t('commonComponents.kg')
            : t('commonComponents.lbs')
        }`,
      }),
      review: t(`onboarding.stickingPlanV2.reviews.${gender}.review`, {
        lostWeight: `${LOST_WEIGHT_MAP_V2[currentMeasurementSystem]} ${
          currentMeasurementSystem === MeasurementSystem.METRIC
            ? t('commonComponents.kilograms')
            : t('commonComponents.pounds')
        }`,
      }),
    }),
    [currentMeasurementSystem, gender, t],
  )

  const handleContinue = useNextStep({
    pageId,
    question: t(`onboarding.stickingPlanV2.title.${gender}`, {
      lng: Language.EN,
    }),
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  return (
    <S.PageContainer>
      <Logo marginBottom={24} />
      <S.Title>
        <Trans i18nKey={`onboarding.stickingPlanV2.title.${gender}`} />
      </S.Title>
      <S.ReviewsBlock>
        <S.BeforeAfter>
          <img src={imageSrc} alt="before-after" />
        </S.BeforeAfter>
        <S.Feedback>
          <S.Review>{review}</S.Review>
          <S.Name>
            {name}
            <S.Stars>
              <img src={stars} alt="stars" />
            </S.Stars>
          </S.Name>
        </S.Feedback>
      </S.ReviewsBlock>
      <StickyButtonContainer>
        <Button type="button" onClick={handleNextClick}>
          {t('actions.continue')}
        </Button>
      </StickyButtonContainer>
    </S.PageContainer>
  )
}
