import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { StyledDynamicRangeTrack as S } from './RangeTrack.styles'

type TProps = {
  className?: string
  isDynamic?: boolean
  valueThresholds: string[]
  numberThresholds: {
    value: number
    shiftPercent: number
  }[]
  value: string
  percentage: number
}

export const RangeTrack: React.FC<TProps> = ({
  className,
  valueThresholds,
  numberThresholds,
  value,
  percentage,
  isDynamic = false,
}) => {
  const { t } = useTranslation()
  const [isAnimationStarted, setIsAnimationStarted] = useState(false)

  useEffect(() => {
    setIsAnimationStarted(true)
  }, [])

  return (
    <div className={className}>
      <S.IndexThresholds>
        {numberThresholds.map((threshold) => (
          <S.IndexScale
            percentage={threshold.shiftPercent}
            key={threshold.value}
          >
            {threshold.value}
          </S.IndexScale>
        ))}
      </S.IndexThresholds>
      <S.RangeTrack>
        <S.RangeThumb
          percentage={percentage}
          className={isAnimationStarted && isDynamic ? 'is-started' : ''}
        />
        <S.RangeValue
          percentage={percentage}
          className={isAnimationStarted && isDynamic ? 'is-started' : ''}
        >
          {value}
        </S.RangeValue>
      </S.RangeTrack>
      <S.ValueThresholds>
        {valueThresholds.map((threshold) => (
          <span key={threshold}>{t(threshold)}</span>
        ))}
      </S.ValueThresholds>
    </div>
  )
}
