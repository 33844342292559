import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { BadHabitsValue } from 'value-constants'

import { AnswerButtonV2 } from 'components/AnswerButtonV2'
import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Language } from 'root-constants'

import { StyledBadHabits as S } from './BadHabits.styles'
import { ANSWER_OPTIONS_MAP } from './constants'

export const BadHabits: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<string[]>([])

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked) {
          setAnswers(
            answers.includes(BadHabitsValue.NONE)
              ? [value]
              : [...answers, value],
          )
        } else {
          setAnswers(answers.filter((item) => item !== value))
        }
      },
    }),
    [pageId, answers],
  )

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.badHabits.title', { lng: Language.EN }),
    nextPagePath,
  })

  const optionPropsForSingle = useMemo(
    () => ({
      name: pageId,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked) {
          setAnswers([value])
        } else {
          setAnswers([])
        }
      },
    }),
    [pageId],
  )

  const handleNextClick = useCallback(() => {
    handleContinue(answers)
  }, [answers, handleContinue])

  return (
    <Container>
      <PageTitle marginBottom={8}>{t('onboarding.badHabits.title')}</PageTitle>
      <S.SubTitle>{t('onboarding.badHabits.subTitle')}</S.SubTitle>
      {ANSWER_OPTIONS_MAP.map(({ value, title }) => (
        <Option
          key={value}
          {...optionProps}
          marginBottom="12"
          value={value}
          checked={answers.includes(value)}
        >
          <AnswerButtonV2 withCheckbox>
            <h3>{t(title)}</h3>
          </AnswerButtonV2>
        </Option>
      ))}
      <Option
        {...optionPropsForSingle}
        marginBottom="12"
        value={BadHabitsValue.NONE}
        checked={answers.includes(BadHabitsValue.NONE)}
      >
        <AnswerButtonV2>
          <h3>{t('onboarding.badHabits.options.none')}</h3>
        </AnswerButtonV2>
      </Option>
      <StickyButtonContainer>
        <Button
          type="button"
          disabled={!answers.length}
          onClick={handleNextClick}
        >
          {t('actions.continue')}
        </Button>
      </StickyButtonContainer>
    </Container>
  )
}
