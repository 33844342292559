import styled from 'styled-components'

import { Button } from 'components/Button'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { Color } from 'root-constants'

export const StyledPerfectSolution = {
  ImageContainer: styled.div`
    aspect-ratio: 220/220;
    height: 220px;
    margin: 0 0 24px 0;
  `,
  TextContainer: styled.div`
    min-height: 224px;
  `,
  Title: styled.h2`
    text-align: left;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: ${Color.GREY_100};
    margin: 0 0 24px 0;
  `,
  Text: styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${Color.GREY_100};

    :not(:last-of-type) {
      margin: 0 0 16px 0;
    }

    strong {
      font-weight: 700;
    }
  `,
  StickyButtonContainer: styled(StickyButtonContainer)`
    max-width: initial;
  `,
  Button: styled(Button)`
    max-width: 100%;
  `,
}
