import styled from 'styled-components'

import { Color } from 'root-constants'

type TOfferProps = {
  isSelected: boolean
}

export const StyledUpsellSubscriptionItemSwitchers = {
  Offer: styled.div<TOfferProps>`
    display: flex;
    align-items: center;
    border: ${({ isSelected }) =>
      isSelected
        ? `2px solid ${Color.PRIMARY}`
        : `2px solid ${Color.LIGHT_GRAY}`};
    color: ${({ isSelected }) =>
      isSelected ? Color.GREY_100 : Color.DISABLED};
    border-radius: 12px;
    padding: 12px 16px;
    margin-bottom: 16px;
  `,
  OfferCaption: styled.span`
    max-width: 154px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-left: 19px;
  `,
  OfferPrice: styled.span`
    max-width: 52px;
    word-wrap: break-word;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: right;
    margin: 0 0 0 auto;
  `,
}
