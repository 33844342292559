import { ActivityLevelValue } from 'value-constants'

export const QUESTION_V1 = 'What is your activity level?'
export const QUESTION_V2 = 'How active are you?'

export const ANSWER_OPTIONS = [
  {
    value: ActivityLevelValue.SEDENTARY,
    title: 'onboarding.activityLevel.options.sedentary.title',
    description: 'onboarding.activityLevel.options.sedentary.description',
  },
  {
    value: ActivityLevelValue.SLIGHTLY_ACTIVE,
    title: 'onboarding.activityLevel.options.slightlyActive.title',
    description: 'onboarding.activityLevel.options.slightlyActive.description',
  },
  {
    value: ActivityLevelValue.MODERATELY,
    title: 'onboarding.activityLevel.options.moderately.title',
    description: 'onboarding.activityLevel.options.moderately.description',
  },
  {
    value: ActivityLevelValue.VERY_ACTIVE,
    title: 'onboarding.activityLevel.options.veryActive.title',
    description: 'onboarding.activityLevel.options.veryActive.description',
  },
  {
    value: ActivityLevelValue.SUPER_ACTIVE,
    title: 'onboarding.activityLevel.options.superActive.title',
    description: 'onboarding.activityLevel.options.superActive.description',
  },
]
