import React from 'react'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { useDynamicPage } from 'hooks/useDynamicPage'

import { TPageProps } from 'models/common.model'

import { StyledDynamicPage as S } from './styles'

export const OptionsRightToLeft: React.FC<TPageProps> = (props) => {
  const {
    title,
    subtitle,
    buttonText,
    optionType,
    optionData,
    optionProps,
    answers,
    isAnswersDisabled,
    handleContinue,
  } = useDynamicPage(props)

  return (
    <Container>
      <PageTitle marginBottom={subtitle ? 12 : 24}>{title}</PageTitle>
      {!!subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}

      {optionType === OptionType.RADIO &&
        optionData?.map(
          ({
            id,
            value,
            text,
            additionalText,
            imageUrl,
            imageWidth,
            imageHeight,
          }) => (
            <Option
              {...optionProps}
              key={id}
              value={value}
              disabled={isAnswersDisabled}
              marginBottom="12"
            >
              <S.AnswerButtonV2
                iconSrc={imageUrl}
                iconWidth={imageWidth || 129}
                iconHeight={imageHeight || 96}
              >
                <>
                  <h3>{text}</h3>
                  {!!additionalText && <p>{additionalText}</p>}
                </>
              </S.AnswerButtonV2>
            </Option>
          ),
        )}

      {optionType === OptionType.CHECKBOX &&
        optionData?.map(
          ({
            id,
            value,
            text,
            additionalText,
            imageUrl,
            imageWidth,
            imageHeight,
          }) => (
            <Option
              {...optionProps}
              key={id}
              value={value}
              checked={answers.includes(value)}
              marginBottom="12"
            >
              <S.AnswerButtonV2
                withCheckbox
                iconSrc={imageUrl}
                iconWidth={imageWidth || 129}
                iconHeight={imageHeight || 96}
              >
                <>
                  <h3>{text}</h3>
                  {!!additionalText && <p>{additionalText}</p>}
                </>
              </S.AnswerButtonV2>
            </Option>
          ),
        )}

      {optionType === OptionType.CHECKBOX && (
        <StickyButtonContainer>
          <Button
            type="button"
            disabled={!answers.length}
            onClick={() => handleContinue(answers)}
          >
            {buttonText}
          </Button>
        </StickyButtonContainer>
      )}
    </Container>
  )
}
