import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { CookieConsent } from 'components/CookieConsent'
import { Header, HeaderV2, HeaderWithBurger } from 'components/Header'
import { InContextControlPanel } from 'components/InContextControlPanel'
import { COHORTS_WITH_HEADER_V2 } from 'components/LayoutProvider/constants'
import { OnboardingProgressBar } from 'components/OnboardingProgressBar'
import { PostPaymentProgressBar } from 'components/PostPaymentProgressBar'
import { Spinner } from 'components/Spinner'

import { sendFacebookParamsAction } from 'root-redux/actions/user'
import {
  selectCurrentVariantCohort,
  selectFacebookPixelIds,
  selectLanguage,
  selectSnapPixelId,
  selectTiktokPixelId,
} from 'root-redux/selects/common'
import { selectUUID, selectUserCountryCode } from 'root-redux/selects/user'

import { useAmplitudeInitialization } from 'hooks/useAmplitudeInitialization'
import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useDynamicDiscount } from 'hooks/useDynamicDiscount'
import { useFacebookPixelInitialization } from 'hooks/useFacebookPixelInitialization'
import { useGAPageTracker } from 'hooks/useGAPageTracker'
import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { useGiaFlowTrackerInitialization } from 'hooks/useGiaFlowTrackerInitialization'
import { growthBook, useGrowthBook } from 'hooks/useGrowthBook'
import { useScrollToTop } from 'hooks/useScrollToTop'
import { useSetPlanAdditions } from 'hooks/useSetPlanAdditions'
import { useSnapPixelInitialization } from 'hooks/useSnapPixelInitialization'
import { useStartSession } from 'hooks/useStartSession'
import { useTestEnvQueryParameter } from 'hooks/useTestEnvQueryParameter'
import { useTiktokPixelInitialization } from 'hooks/useTiktokPixelInitialization'
import { useUtmTags } from 'hooks/useUtmTags'

import { getCurrentEnvironment } from 'helpers/getCurrentEnvironment'

import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { Color, POST_PAYMENT_PAGES } from 'root-constants'

import { RouteList } from '../../RouteList'
import { BackgroundGlobalStyles } from './LayoutProvider.styles'

export const LayoutProvider: React.FC = () => {
  const dispatch = useDispatch()
  const { search, pathname } = useLocation()

  const uuid = useSelector(selectUUID)
  const cohort = useSelector(selectCurrentVariantCohort)
  const language = useSelector(selectLanguage)
  const facebookPixelIds = useSelector(selectFacebookPixelIds)
  const tiktokPixelId = useSelector(selectTiktokPixelId)
  const snapPixelId = useSelector(selectSnapPixelId)
  const userCountryCode = useSelector(selectUserCountryCode)

  const { isEUUser, cookieConsent } = useCookieConsentAnswer()

  const {
    currentPageId,
    hasHeader,
    hasBurgerMenu,
    isSecondPage,
    hasProgressbar,
  } = useGetPageInfo()

  const { isDevEnvironment } = getCurrentEnvironment()

  const hasSubscription = useMemo(
    () => POST_PAYMENT_PAGES.includes(currentPageId),
    [currentPageId],
  )

  useGrowthBook()
  useUtmTags()
  useDynamicDiscount()
  useGAPageTracker()
  useFacebookPixelInitialization(facebookPixelIds)
  useTiktokPixelInitialization(tiktokPixelId)
  useSnapPixelInitialization(snapPixelId)
  useGiaFlowTrackerInitialization(cohort)
  useAmplitudeInitialization(cohort)
  useScrollToTop()
  useTestEnvQueryParameter()
  useSetPlanAdditions()
  useStartSession()

  const isCookieConsentVisible = useMemo(
    () => !cookieConsent && isEUUser,
    [isEUUser, cookieConsent],
  )

  useEffect(() => {
    growthBook.setAttributes({
      ...growthBook.getAttributes(),
      url: `${pathname}${search}`,
      country: userCountryCode,
      cohort,
      language,
    })
  }, [cohort, language, pathname, search, userCountryCode])

  useEffect(() => {
    if (isSecondPage) {
      dispatch(sendFacebookParamsAction())
      window.fbq('track', 'CustomizeProduct', {}, { eventID: uuid })
      googleAnalyticsLogger.logFirstPageCompleted()
    }
  }, [dispatch, isSecondPage, uuid])

  // TODO:this logic will should get rid of HeaderV2 after deactivation cohorts: mf_keto/mf_nosugar/mf_mealplan

  const headerComponent: React.ReactNode = useMemo(() => {
    if (hasBurgerMenu) {
      return <HeaderWithBurger />
    }

    if (COHORTS_WITH_HEADER_V2.includes(cohort)) {
      return <HeaderV2 />
    }

    return <Header />
  }, [cohort, hasBurgerMenu])

  return !growthBook?.ready ? (
    <Spinner />
  ) : (
    <>
      {isDevEnvironment && <InContextControlPanel />}
      <BackgroundGlobalStyles color={Color.GREY_700} />
      {hasHeader && headerComponent}
      {/* TODO: delete after deactivating cohorts mf_keto/mf_nosugar/mf_mealplan */}
      {hasProgressbar && COHORTS_WITH_HEADER_V2.includes(cohort) && (
        <OnboardingProgressBar />
      )}
      {hasSubscription && <PostPaymentProgressBar />}
      {isCookieConsentVisible && <CookieConsent />}
      <RouteList />
    </>
  )
}
