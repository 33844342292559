import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { Select } from 'components/Select'

import { updateAnswersAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'
import {
  selectCurrentUserCurrentWeight,
  selectCurrentUserGender,
  selectCurrentVariantCohortToUse,
  selectUserMeasurementSystem,
} from 'root-redux/selects/common'
import {
  selectIsOnboardingFinished,
  selectIsOnboardingSkipped,
} from 'root-redux/selects/user'

import { TAnswer } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { CustomPageId, PageId } from 'page-constants'
import {
  Cohort,
  Gender,
  MEN_COHORTS,
  MeasurementSystem,
  WEIGHT_UNITS,
  WOMEN_COHORTS,
} from 'root-constants'

import { MAX_AGE, MIN_AGE } from '../questions/Age/constants'
import { MinMaxWeight } from '../questions/CurrentWeight/constants'
import {
  MIN_KG_DIFF,
  MIN_LB_DIFF,
  MIN_WEIGHT_KG,
  MIN_WEIGHT_LB,
} from '../questions/GoalWeight/constants'
import { StyledFinalReview as S } from './FinishingTouches.styles'
import { PRIMARY_GOAL_OPTIONS_V1, PRIMARY_GOAL_OPTIONS_V2 } from './constants'
import { useAgeField, useNameField, usePrimaryGoalField } from './hooks'
import { useGoalWeightField } from './hooks/useGoalWeightField'

export const FinishingTouches: React.FC = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const dispatch = useDispatch()

  const { userAge, handleAgeChange } = useAgeField()
  const { userGoalWeight, handleGoalWeightChange } = useGoalWeightField()
  const { userName, handleNameChange } = useNameField()
  const { selectedPrimaryGoal, handlePrimaryGoalChange } = usePrimaryGoalField()

  const currentWeight = +useSelector(selectCurrentUserCurrentWeight)
  const currentMeasurementSystem = useSelector(selectUserMeasurementSystem)
  const isOnboardingSkipped = useSelector(selectIsOnboardingSkipped)
  const isOnboardingFinished = useSelector(selectIsOnboardingFinished)
  const gender = useSelector(selectCurrentUserGender)
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const [isFocused, setIsFocused] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const isMetricSystemSelected = useMemo(
    () => currentMeasurementSystem === MeasurementSystem.METRIC,
    [currentMeasurementSystem],
  )

  const isMaleSexSelected = useMemo(() => gender === Gender.MALE, [gender])

  const isConfirmationDisabled = useMemo(
    () =>
      !userGoalWeight?.isValid ||
      !userAge.isValid ||
      !selectedPrimaryGoal?.value,
    [selectedPrimaryGoal, userGoalWeight, userAge],
  )

  const primaryGoalOptions = useMemo(
    () =>
      (cohortToUse === Cohort.MF_MEN_FLOW
        ? PRIMARY_GOAL_OPTIONS_V2
        : PRIMARY_GOAL_OPTIONS_V1
      ).map((goal) => ({
        ...goal,
        label: t(goal.label),
      })),
    [cohortToUse, t],
  )

  const maxWeight = useMemo(() => {
    const userMaxWeight =
      currentWeight - (isMetricSystemSelected ? MIN_KG_DIFF : MIN_LB_DIFF)

    const defaultMaxWeight = isMetricSystemSelected
      ? MinMaxWeight.MAX_WEIGHT_KG
      : MinMaxWeight.MAX_WEIGHT_LB

    return userMaxWeight || defaultMaxWeight
  }, [isMetricSystemSelected, currentWeight])

  const hasGenderSection = useMemo(
    () => !!gender && ![...MEN_COHORTS, ...WOMEN_COHORTS].includes(cohortToUse),
    [gender, cohortToUse],
  )

  useLayoutEffect(() => {
    if (isOnboardingSkipped || isOnboardingFinished) {
      goTo({ pathname: PageId.LOGIN, search })
      return
    }
    eventLogger.logFinishingTouchesScreenShow()
  }, [isOnboardingFinished, isOnboardingSkipped, search])

  useEffect(() => {
    if (inputRef.current && isFocused) {
      inputRef.current.focus()
    }
  }, [isFocused])

  const handleConfirm = useCallback(() => {
    dispatch(
      updateAnswersAction({
        answers: {
          name: userName,
          [CustomPageId.AGE]: +userAge.value,
          [CustomPageId.PRIMARY_GOAL]: selectedPrimaryGoal?.value as TAnswer,
          [CustomPageId.GOAL_WEIGHT]: +userGoalWeight.value,
        },
      }),
    )
    dispatch(sendUserAnswersAction(null, true))

    eventLogger.logFinishingTouchesScreenConfirm({
      name: userName,
      sex: gender,
      age: userAge.value || 25,
      primaryGoal: selectedPrimaryGoal?.value || 'lose_weight',
      goalWeight: userGoalWeight.value || 60,
    })
    goTo({ pathname: PageId.LOGIN, search })
  }, [
    dispatch,
    userName,
    userAge.value,
    selectedPrimaryGoal?.value,
    userGoalWeight.value,
    gender,
    search,
  ])

  const handleBlur = useCallback(() => {
    setIsFocused(false)
  }, [])

  return (
    <Container>
      <S.Root>
        <S.Title>{t('finishingTouches.title')}</S.Title>
        <S.Subtitle>{t('finishingTouches.subtitle')}</S.Subtitle>
        <S.List>
          <S.Item>
            <S.Input
              maxLength={30}
              onChange={handleNameChange}
              label={t('finishingTouches.userName.label')}
              value={userName as string}
            />
          </S.Item>
          {hasGenderSection && (
            <S.Item>
              <S.Gender>
                <S.SectionTitle>
                  {t('finishingTouches.gender.title')}
                </S.SectionTitle>
                <S.Label htmlFor={Gender.FEMALE}>
                  <S.Radio
                    disabled
                    value={Gender.FEMALE}
                    defaultChecked={!isMaleSexSelected}
                    name="gender"
                    id={Gender.FEMALE}
                    type="radio"
                  />
                  <S.MarkIcon isSelected={!isMaleSexSelected} />
                  <span>{t('finishingTouches.gender.female')}</span>
                </S.Label>
                <S.Label htmlFor={Gender.MALE}>
                  <S.Radio
                    disabled
                    defaultChecked={isMaleSexSelected}
                    value={Gender.MALE}
                    name="gender"
                    id={Gender.MALE}
                    type="radio"
                  />
                  <S.MarkIcon isSelected={isMaleSexSelected} />
                  <span>{t('finishingTouches.gender.male')}</span>
                </S.Label>
              </S.Gender>
            </S.Item>
          )}
          <S.Item>
            <S.Input
              type="number"
              min={MIN_AGE}
              max={MAX_AGE}
              pattern="\d*"
              onChange={handleAgeChange}
              label={t('finishingTouches.age.placeholder')}
              value={userAge.value}
            />
          </S.Item>
          <S.Item>
            <Select
              placeholder={t('finishingTouches.primaryGoal.placeholder')}
              options={primaryGoalOptions}
              value={selectedPrimaryGoal}
              onChange={handlePrimaryGoalChange}
            />
          </S.Item>
          <S.WeightItem>
            <S.Input
              type="number"
              inputRef={inputRef}
              label={t('finishingTouches.goalWeight.placeholder', {
                measurementSystem: WEIGHT_UNITS[currentMeasurementSystem],
              })}
              value={userGoalWeight.value}
              isValid={userGoalWeight.isValid}
              min={isMetricSystemSelected ? MIN_WEIGHT_KG : MIN_WEIGHT_LB}
              max={maxWeight}
              lang="en"
              step={isMetricSystemSelected ? '0.1' : '1'}
              allowFloatNumbers={isMetricSystemSelected}
              onBlur={handleBlur}
              onChange={(event) => {
                handleGoalWeightChange(event)
              }}
            />
          </S.WeightItem>
        </S.List>
      </S.Root>
      <S.ActionContainer>
        <Button disabled={isConfirmationDisabled} onClick={handleConfirm}>
          {t('actions.confirm')}
        </Button>
      </S.ActionContainer>
    </Container>
  )
}
