import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import {
  COHORTS_WITHOUT_TRIAL_FOR_WEEKLY_PLAN,
  CURRENCY_SYMBOLS,
  Period,
  PurchaseProduct,
} from 'modules/purchase/constants'
import { TSubscriptionItemProps } from 'modules/purchase/types'

import { StyledSubscriptionItem as S } from './SubscriptionItem.styles'

export const SubscriptionItem: React.FC<TSubscriptionItemProps> = ({
  subscription: { id, product, mainPrices, trialPrices, isDefault, currency },
  isSelected,
  hasWeeklyTrial = true,
}) => {
  const { t } = useTranslation()

  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const isWeeklyPlanWithoutTrial = useMemo(
    () =>
      (COHORTS_WITHOUT_TRIAL_FOR_WEEKLY_PLAN.includes(cohortToUse) ||
        !hasWeeklyTrial) &&
      trialPrices.durationDays === Period.SEVEN_DAYS,
    [cohortToUse, trialPrices, hasWeeklyTrial],
  )

  const oldPriceWithFractionalPart = useMemo(
    () =>
      product === PurchaseProduct.SEVEN_DAY
        ? trialPrices.oldPrices.beforeCustomDiscount?.fullPrice.toFixed(2)
        : mainPrices.fullPrice.toFixed(2),
    [product, trialPrices, mainPrices],
  )

  const oldPricePerDayWithFractionalPart = useMemo(
    () => trialPrices.oldPrices.beforeCustomDiscount?.daily.toFixed(2),
    [trialPrices],
  )

  const pricePerDayWithFractionalPart = useMemo(
    () =>
      product === PurchaseProduct.SEVEN_DAY &&
      (COHORTS_WITHOUT_TRIAL_FOR_WEEKLY_PLAN.includes(cohortToUse) ||
        !hasWeeklyTrial)
        ? mainPrices.daily.toFixed(2)
        : trialPrices.daily.toFixed(2),
    [product, trialPrices, mainPrices, cohortToUse, hasWeeklyTrial],
  )

  const period = useMemo(() => {
    if (product === PurchaseProduct.SEVEN_DAY) {
      return t('purchase.subscription.weeklyPeriod', {
        periodQuantity: trialPrices.durationDays,
      })
    }
    if (product === PurchaseProduct.THREE_MONTHS) {
      return t('purchase.subscription.severalMonthPeriod', {
        periodQuantity: mainPrices.periodQuantity,
      })
    }
    return t('purchase.subscription.monthlyPeriod', {
      periodQuantity: mainPrices.periodQuantity,
    })
  }, [t, product, trialPrices, mainPrices])

  return (
    <S.Wrapper key={id} isSelected={isSelected}>
      <S.Content isSelected={isSelected}>
        {isDefault && (
          <S.MostPopularBadge>
            {t('purchase.subscription.mostPopularBadge')}
          </S.MostPopularBadge>
        )}
        <S.PlanContainer>
          <S.PlanPeriod isSelected={isSelected}>{period}</S.PlanPeriod>

          {isWeeklyPlanWithoutTrial ? (
            <S.PriceContainer>
              <div>
                <S.CurrentPrice>
                  {CURRENCY_SYMBOLS[currency]}
                  {mainPrices.fullPrice.toFixed(2)}
                </S.CurrentPrice>
              </div>
            </S.PriceContainer>
          ) : (
            <S.PriceContainer>
              <div>
                <S.PreviousPrice>
                  {CURRENCY_SYMBOLS[currency]}
                  {oldPriceWithFractionalPart}
                </S.PreviousPrice>
                <S.CurrentPrice>
                  {CURRENCY_SYMBOLS[currency]}
                  {trialPrices.fullPrice.toFixed(2)}
                </S.CurrentPrice>
              </div>
              <S.PreviousPrice>
                {CURRENCY_SYMBOLS[currency]}
                {oldPricePerDayWithFractionalPart}
              </S.PreviousPrice>
            </S.PriceContainer>
          )}
        </S.PlanContainer>

        <S.CustomPrice isSelected={isSelected}>
          <S.PriceValue>
            {CURRENCY_SYMBOLS[currency]}
            {pricePerDayWithFractionalPart}
          </S.PriceValue>
          <S.Period>
            {t('purchase.subscription.interval')}{' '}
            {t('purchase.subscription.day')}
          </S.Period>
        </S.CustomPrice>
      </S.Content>
    </S.Wrapper>
  )
}
