import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import {
  selectCurrency,
  selectSubscriptionFullPrice,
} from 'modules/purchase/redux/selects'

import { StyledCheckoutSummary as S } from './CheckoutSummary.styles'

export const CheckoutSummary: React.FC = () => {
  const { t } = useTranslation()

  const fullPrice = useSelector(selectSubscriptionFullPrice)
  const currency = useSelector(selectCurrency)

  return (
    <S.DescriptionContainer>
      <S.PersonalizedPlan>
        <Trans i18nKey="purchase.checkoutV1.personalizedPlan" />
      </S.PersonalizedPlan>
      <S.PlanPrice>
        {t('purchase.checkoutV1.fullPrice', {
          oldPrice: fullPrice,
          currency: CURRENCY_SYMBOLS[currency],
        })}
      </S.PlanPrice>
    </S.DescriptionContainer>
  )
}
