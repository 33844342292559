import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { PaymentRequestButtonElement } from '@stripe/react-stripe-js'

import { Spinner } from 'components/Spinner'

import {
  selectIsCheckoutFlowShown,
  selectOneClickPaymentMethod,
  selectStripePaymentRequest,
} from 'modules/purchase/redux/selects'

import { eventLogger } from 'services/eventLogger.service'

import svgSrc from 'assets/images/sprite/spinner-grey.svg'

import { StyledPaymentRequestButton as S } from './PaymentRequestButton.styles'

type TProps = {
  className?: string
}
export const PaymentRequestButtonV2: React.FC<TProps> = ({ className }) => {
  const paymentRequest = useSelector(selectStripePaymentRequest)
  const paymentMethod = useSelector(selectOneClickPaymentMethod)
  const isCheckoutFlowShown = useSelector(selectIsCheckoutFlowShown)

  const handleButtonClick = useCallback(() => {
    if (paymentMethod) {
      eventLogger.logPaymentMethodSelected(paymentMethod)
    }
  }, [paymentMethod])

  return (
    <>
      {paymentRequest && isCheckoutFlowShown && (
        <S.Wrapper className={className}>
          <PaymentRequestButtonElement
            onClick={handleButtonClick}
            options={{
              paymentRequest,
              style: {
                paymentRequestButton: {
                  height: '56px',
                },
              },
            }}
          />
        </S.Wrapper>
      )}
      {!isCheckoutFlowShown && <Spinner svgSrc={svgSrc} isFullScreen={false} />}
    </>
  )
}
