import styled from 'styled-components'

import { Color } from 'root-constants'

export const Title = styled.h2`
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 16px;
  color: ${Color.GREY_100};
`
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
  align-items: flex-start;
`
export const HeaderTitle = styled.h3`
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  color: ${Color.GREY_100};
  letter-spacing: -0.45px;
  margin-bottom: 4px;
`

export const HeaderSubtitle = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
`
export const HeaderLabel = styled.div<{
  backgroundColor: string
  color: string
}>`
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  padding: 4px 8px;
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 6px;
  border: 1px solid ${({ color }) => color};
  text-transform: uppercase;
`
export const Content = styled.div`
  background: ${Color.WHITE};
  box-shadow: 0 12px 32px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  padding: 16px;
`
export const RangeTrack = styled.div`
  width: 100%;
  height: 8px;
  position: relative;
  background: linear-gradient(
    90deg,
    #93d3ed 0%,
    #6fd09a 30.71%,
    #f6e48d 64.78%,
    #ec8774 100%
  );
  border-radius: 26px;
  margin-bottom: 10px;
`
export const RangeThumb = styled.div<{ backgroundColor: string; left: string }>`
  position: absolute;
  left: ${({ left }) => left};
  top: 50%;
  transform: translate(-50%, -50%);
  width: 28px;
  height: 28px;
  border: 9px solid ${Color.WHITE};
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.16);
  border-radius: 50%;
  background-color: ${({ backgroundColor }) => backgroundColor};
`
export const RangeValue = styled.div`
  position: absolute;
  top: -44px;
  left: 50%;
  transform: translateX(-50%);
  background: ${Color.DARK_BLUE};
  border-radius: 32px;
  padding: 5px 9px;
  color: ${Color.WHITE};
  font-weight: 900;
  font-size: 12px;
  line-height: 14px;

  &::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 4px 0 4px;
    border-color: ${Color.GREY_100} transparent transparent transparent;
  }
`
export const RangeThresholds = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: ${Color.DISABLED};
`

export const StyledPersonalSummary = {
  Root: styled.div`
    margin-bottom: 16px;
  `,
  HeaderContent: styled.div`
    margin-left: 5px;
  `,
}
