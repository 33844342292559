import styled from 'styled-components'

export const StyledFlagContainer = {
  Container: styled.div<{ backgroundColor: string }>`
    filter: url(#round);
    position: relative;

    &:before {
      background-color: ${({ backgroundColor }) => backgroundColor};
      clip-path: polygon(15% 0%, 100% 0%, 100% 100%, 15% 100%, 0% 50%);
      content: '';
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: background-color 0.3s ease-out;
      width: 100%;
      z-index: -1;
    }
  `,
  SvgFilter: styled.svg`
    height: 0;
    position: absolute;
    visibility: hidden;
    width: 0;
  `,
}
