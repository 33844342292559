import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { useElements, useStripe } from '@stripe/react-stripe-js'

import { resetErrorAction } from 'root-redux/actions/common'

import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { usePaymentStore } from 'hooks/usePaymentStore'

import { PaymentMethod } from 'modules/purchase/constants'
import {
  purchaseAction,
  setCheckoutFlowShownAction,
  setOneClickPaymentMethodAction,
  setStripePaymentRequestAction,
} from 'modules/purchase/redux/actions/common'

import {
  CENTS_IN_DOLLAR,
  OutbrainEventName,
  TaboolaEventName,
} from 'root-constants'

export const useStripeButton = () => {
  const stripe = useStripe()
  const elements = useElements()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { currency, periodQuantity, periodName, fullPrice, trialCurrentPrice } =
    usePaymentStore()

  const { currentPageId } = useGetPageInfo()

  const planPeriodDescription = useMemo(
    () =>
      t('purchase.checkoutV1.planDescription', {
        periodQuantity,
        periodName,
      }),
    [t, periodName, periodQuantity],
  )

  const calculatedPrice = useMemo(
    () => +((trialCurrentPrice || fullPrice) * CENTS_IN_DOLLAR).toFixed(),
    [trialCurrentPrice, fullPrice],
  )

  useEffect(() => {
    if (!stripe || !elements) {
      return
    }

    dispatch(setCheckoutFlowShownAction(false))
    dispatch(setStripePaymentRequestAction(null))
    dispatch(setOneClickPaymentMethodAction(''))

    const pr = stripe?.paymentRequest({
      currency,
      country: 'GB',
      requestPayerEmail: true,
      requestPayerName: true,
      total: {
        label: planPeriodDescription,
        amount: calculatedPrice,
      },
    })

    pr?.canMakePayment().then((result) => {
      if (result) {
        dispatch(setStripePaymentRequestAction(pr))
        dispatch(
          setOneClickPaymentMethodAction(
            result?.applePay
              ? PaymentMethod.APPLE_PAY
              : PaymentMethod.GOOGLE_PAY,
          ),
        )
      }
      dispatch(setCheckoutFlowShownAction(true))
    })

    pr?.on('paymentmethod', (event) => {
      dispatch(resetErrorAction())
      dispatch(
        purchaseAction({
          stripe,
          paymentPageId: currentPageId,
          createPaymentResFromDigitalWallet: event,
        }),
      )

      window.obApi && window.obApi('track', OutbrainEventName.CHECKOUT)
      window._tfa &&
        window._tfa.push({
          notify: 'event',
          name: TaboolaEventName.CHECKOUT,
        })
    })
  }, [
    dispatch,
    currency,
    stripe,
    elements,
    planPeriodDescription,
    calculatedPrice,
    currentPageId,
  ])
}
