import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledSwipedPersonalizedPlan = {
  CarouselContainer: styled.div`
    padding: 24px 0 0 0;
  `,
  ScreenContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 355px;
    aspect-ratio: 176/355;
  `,
  TitleContainer: styled.div`
    display: flex;
    flex-direction: column;
    height: 100px;
    padding: 0 42px;
    margin: 0 0 16px 0;
  `,
  Title: styled.h4<{ color?: string }>`
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: ${({ color }) => color || Color.GREY_100};
    margin: 0 0 8px 0;
  `,
  Subtitle: styled.p`
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: ${Color.BLACK};
    text-align: center;
  `,
}
