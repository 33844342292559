import React, { useCallback, useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { KitPageContainer } from 'components/PageContainer'

import {
  selectUserAccountLoginMethod,
  selectUserLoginEmail,
} from 'root-redux/selects/user'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import {
  CUSTOM_TOKEN_LOCAL_STORAGE_KEY,
  Emojis,
  LoginMethod,
} from 'root-constants'

import { StyledDownload as S } from './Download.styles'
import {
  LOGIN_METHOD_INSTRUCTION_IMAGE_MAP,
  LOGIN_METHOD_INSTRUCTION_LINK_MAP,
} from './constants'

export const Download: React.FC = () => {
  const { t } = useTranslation()
  const loginMethod = useSelector(selectUserAccountLoginMethod)
  const userEmail = useSelector(selectUserLoginEmail)

  const downloadAppLink = useMemo(() => {
    if (!loginMethod) return ''
    const customToken = localStorage.getItem(CUSTOM_TOKEN_LOCAL_STORAGE_KEY)

    return LOGIN_METHOD_INSTRUCTION_LINK_MAP[loginMethod].replace(
      CUSTOM_TOKEN_LOCAL_STORAGE_KEY,
      customToken || '',
    )
  }, [loginMethod])

  useEffect(() => {
    eventLogger.logGettingAppShown()
  }, [])

  const handleContinue = useCallback(() => {
    window.location.href = downloadAppLink
  }, [downloadAppLink])

  const handleClick = useCallback(() => {
    googleAnalyticsLogger.logDownloadButtonPressed()
    eventLogger.logDownloadClicked(handleContinue)
  }, [handleContinue])

  return (
    loginMethod && (
      <KitPageContainer
        hasContinueButton
        paddingTop={0}
        continueButtonContent={t('download.buttonText')}
        continueButtonDeepLink={downloadAppLink}
        onContinueButtonClick={handleClick}
      >
        <S.Title>
          <Trans
            i18nKey="download.title"
            components={{ span: <span /> }}
            values={{ emoji: Emojis.PARTY }}
          />
        </S.Title>

        <S.Subtitle>
          <Trans i18nKey="download.subtitle" />
        </S.Subtitle>

        <S.LoginDescription>
          {t('download.loginDescription')}
        </S.LoginDescription>
        <S.List>
          <li>
            <S.DownloadAppText>
              <Trans
                i18nKey={
                  loginMethod === LoginMethod.EMAIL
                    ? 'download.signWithEmail'
                    : 'download.signInWithOtherMethods'
                }
              />
            </S.DownloadAppText>
          </li>
          <li>
            <S.ContinueText>{t(`download.${loginMethod}`)}</S.ContinueText>
          </li>
        </S.List>
        {loginMethod === LoginMethod.EMAIL ? (
          <S.EmailLabel>{userEmail}</S.EmailLabel>
        ) : (
          <S.Image
            src={LOGIN_METHOD_INSTRUCTION_IMAGE_MAP[loginMethod]}
            alt="Log in instruction"
            decoding="async"
          />
        )}
      </KitPageContainer>
    )
  )
}
