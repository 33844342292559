import React, { SyntheticEvent, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Container } from 'components/Container'
import { NavigationButton } from 'components/NavigationButton'
import { PageTitle } from 'components/PageTitle'
import { SvgImage } from 'components/SvgImage'

import { selectUserMeasurementSystem } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'
import { useUsersBmi } from 'hooks/useUsersBmi'

import { IInputState, TPageProps } from 'models/common.model'

import alertIcon from 'assets/images/sprite/alert-circle.svg'

import { CustomPageId } from 'page-constants'
import {
  FloatNumberRegex,
  INITIAL_INPUT_VALUE,
  MeasurementSystem,
  WEIGHT_UNITS,
} from 'root-constants'

import { StyledCurrentWeight as S } from './CurrentWeight.styles'
import { CURRENT_BMI_INFO_MAP, MinMaxWeight, QUESTION } from './constants'

export const CurrentWeightV3: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()

  const currentMeasurementSystem = useSelector(selectUserMeasurementSystem)

  const [weight, setWeight] = useState<IInputState>({
    ...INITIAL_INPUT_VALUE,
  })
  const [isFocused, setIsFocused] = useState(false)

  const { currentBMI } = useUsersBmi({
    currentUserWeight: (weight.isValid && +weight.value) || 70,
  })

  const handleContinue = useNextStep({
    pageId: CustomPageId.CURRENT_WEIGHT,
    question: QUESTION,
    nextPagePath,
  })

  const { bmiValue, text, iconSrc, background } = useMemo(
    () => CURRENT_BMI_INFO_MAP[currentBMI.bmiValue.value],
    [currentBMI.bmiValue.value],
  )

  const isMetricSystemSelected = useMemo(
    () => currentMeasurementSystem === MeasurementSystem.METRIC,
    [currentMeasurementSystem],
  )

  const handleSubmit = useCallback(
    (e: SyntheticEvent<HTMLFormElement>) => {
      e.preventDefault()
      handleContinue(+weight.value, currentMeasurementSystem)
    },
    [handleContinue, weight.value, currentMeasurementSystem],
  )

  const handleChange = useCallback(({ target: { value, validity } }) => {
    if (!value || FloatNumberRegex.test(value)) {
      setWeight((prevState) => ({
        ...prevState,
        value,
        isValid: validity.valid,
      }))
    }
  }, [])

  const handleFocus = useCallback(() => {
    setIsFocused(true)
  }, [])

  const handleBlur = useCallback(() => {
    setIsFocused(false)
  }, [])

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <PageTitle marginBottom={24}>
          {t('onboarding.currentWeight.title')}
        </PageTitle>
        <S.WeightLabel>
          {isMetricSystemSelected ? WEIGHT_UNITS.metric : WEIGHT_UNITS.imperial}
        </S.WeightLabel>
        <S.ActionsV2>
          <S.InputWrapper>
            <S.WeightInput
              type="number"
              value={weight.value}
              isContentCentered
              isValid={weight.isValid}
              min={
                isMetricSystemSelected
                  ? MinMaxWeight.MIN_WEIGHT_KG
                  : MinMaxWeight.MIN_WEIGHT_LB
              }
              max={
                isMetricSystemSelected
                  ? MinMaxWeight.MAX_WEIGHT_KG
                  : MinMaxWeight.MAX_WEIGHT_LB
              }
              lang="en"
              step="1"
              allowFloatNumbers={false}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            {!isFocused && !weight.value && <S.Placeholder />}
            <S.Suffix>
              {isMetricSystemSelected
                ? WEIGHT_UNITS.metric
                : WEIGHT_UNITS.imperial}
            </S.Suffix>
          </S.InputWrapper>
          {weight.value && !weight.isValid && (
            <S.ErrorMessage>
              <SvgImage svg={alertIcon} />
              <span>{t('onboarding.goalWeight.errorMessage')}</span>
            </S.ErrorMessage>
          )}
        </S.ActionsV2>

        {weight.isValid && (
          <S.InfoContainer background={background}>
            <S.InfoImage>
              <img src={iconSrc} alt="bmi-info-icon" />
            </S.InfoImage>
            <S.Info>
              <S.InfoTitle>
                {t('onboarding.currentWeight.mbiInfo.title', {
                  bmiIndex: currentBMI.bmiIndex,
                  bmiValue: t(bmiValue),
                })}
              </S.InfoTitle>
              <S.InfoText>{t(text)}</S.InfoText>
            </S.Info>
          </S.InfoContainer>
        )}

        <NavigationButton type="submit" disabled={!weight.isValid}>
          {t('actions.continue')}
        </NavigationButton>
      </Container>
    </form>
  )
}
