import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledUpsellDiscountBanner = {
  Container: styled.div`
    align-items: center;
    background: linear-gradient(91deg, #ffa800 0%, #ff5c00 98.82%);
    border-radius: 12px;
    column-gap: 16px;
    display: flex;
    margin-bottom: 16px;
    padding: 16px;
    width: 100%;
  `,
  ImageContainer: styled.div`
    aspect-ratio: 1 / 1;
    background-color: rgba(255, 255, 255, 0.24);
    border-radius: 50%;
    height: 32px;
    min-width: 32px;
  `,
  Label: styled.p`
    color: rgba(255, 255, 255, 0.7);
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;

    strong {
      color: ${Color.WHITE};
      font-weight: 700;
    }
  `,
}
