import React, { useCallback, useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from 'storybook-ui'

import { selectSubscriptionList } from 'root-redux/selects/common'
import { selectUserOnboardingEmail } from 'root-redux/selects/user'

import { UpsellSubscriptionItemSwitchers } from 'modules/purchase/components'
import {
  MAX_NUMBER_OF_SELECTED_SUBSCRIPTIONS,
  NUMBER_OF_SINGLE_SUBSCRIPTION,
} from 'modules/purchase/components/UpsellSubscriptionBlock/constants'
import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import {
  setSelectedSubscriptionAction,
  setSelectedUpsellProduct,
} from 'modules/purchase/redux/actions/common'
import {
  selectCurrency,
  selectSelectedUpsellProduct,
  selectUpsellSwitcherState,
} from 'modules/purchase/redux/selects'

import { eventLogger } from 'services/eventLogger.service'

import { Color, UpsellProduct } from 'root-constants'

import { StyledUpsellSubscriptionBlock as S } from './UpsellSubscriptionBlock.styles'

type TPageProps = {
  makeUpsell: () => void
  makeUpsellWithAll: () => void
  skip: () => void
}

export const UpsellSubscriptionBlock: React.FC<TPageProps> = ({
  makeUpsell,
  makeUpsellWithAll,
  skip,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const selectedProduct = useSelector(selectSelectedUpsellProduct)

  const currency = useSelector(selectCurrency)
  const subscriptions = useSelector(selectSubscriptionList)
  const email = useSelector(selectUserOnboardingEmail)

  const isSwitchersOn = useSelector(selectUpsellSwitcherState)

  const fitnessAndSelfcare = subscriptions.find(
    (subscription) => subscription.product === UpsellProduct.BOTH,
  )
  // Default subscription on this page
  const fitnessSubscription = subscriptions.find(
    (subscription) => subscription.product === UpsellProduct.FITNESS,
  )
  const selfCareSubscription = subscriptions.find(
    (subscription) => subscription.product === UpsellProduct.SELF_CARE,
  )

  const isSecondaryButtonVisible = useMemo(
    () => selectedProduct.length < MAX_NUMBER_OF_SELECTED_SUBSCRIPTIONS,
    [selectedProduct],
  )

  const isSingleSubscriptionSelected = useMemo(
    () => selectedProduct.length === NUMBER_OF_SINGLE_SUBSCRIPTION,
    [selectedProduct],
  )

  useEffect(() => {
    if (fitnessSubscription) {
      dispatch(setSelectedSubscriptionAction(fitnessSubscription))
      dispatch(
        setSelectedUpsellProduct([
          ...selectedProduct,
          fitnessSubscription.product,
        ]),
      )
    }

    return () => {
      dispatch(setSelectedUpsellProduct([]))
    }
  }, [dispatch, fitnessSubscription])

  useEffect(() => {
    if (isSingleSubscriptionSelected) {
      const [product] = selectedProduct
      const selectedSubscription = subscriptions.find(
        (subscription) => subscription.product === product,
      )

      if (selectedSubscription) {
        dispatch(setSelectedSubscriptionAction(selectedSubscription))
      }

      return
    }

    fitnessAndSelfcare &&
      dispatch(setSelectedSubscriptionAction(fitnessAndSelfcare))
  }, [
    subscriptions,
    selectedProduct,
    fitnessAndSelfcare,
    dispatch,
    isSingleSubscriptionSelected,
  ])

  const handleChange = useCallback(
    ({ target: { value } }) => {
      const isPriceSelected = selectedProduct.includes(value)
      const selectedSubscription = subscriptions.find(
        (subscription) => subscription.product === value,
      )
      if (!isPriceSelected) {
        selectedSubscription &&
          eventLogger.logUpsellSubscriptionEnable(selectedSubscription?.product)
        dispatch(setSelectedUpsellProduct([...selectedProduct, value]))

        return
      }
      selectedSubscription &&
        eventLogger.logUpsellSubscriptionDisable(selectedSubscription?.product)
      dispatch(
        setSelectedUpsellProduct([
          ...selectedProduct.filter((id) => id !== value),
        ]),
      )
    },
    [selectedProduct, dispatch, subscriptions],
  )

  return (
    <>
      <S.Discount>
        <S.DiscountBadge>{t('upsell.discount.badge')}</S.DiscountBadge>
        <S.DiscountText>
          <Trans
            i18nKey="upsell.discount.text"
            values={{
              price: fitnessAndSelfcare?.trialPrices.fullPrice,
              currency: CURRENCY_SYMBOLS[currency],
            }}
          />
        </S.DiscountText>
      </S.Discount>

      {fitnessSubscription && (
        <UpsellSubscriptionItemSwitchers
          value={fitnessSubscription.product}
          onChange={handleChange}
          key={fitnessSubscription.id}
          subscription={fitnessSubscription}
          isSelected={
            selectedProduct.includes(fitnessSubscription.product) ||
            isSwitchersOn
          }
        />
      )}

      {selfCareSubscription && (
        <UpsellSubscriptionItemSwitchers
          value={selfCareSubscription.product}
          onChange={handleChange}
          key={selfCareSubscription.id}
          subscription={selfCareSubscription}
          isSelected={
            selectedProduct.includes(selfCareSubscription.product) ||
            isSwitchersOn
          }
        />
      )}
      <Button
        width="100%"
        backgroundColor={Color.GREEN_400}
        color={Color.WHITE}
        fontSize="17px"
        fontWeight="700"
        lineHeight="24px"
        padding="16px"
        borderRadius="30px"
        margin="24px 0 16px 0"
        onClick={makeUpsellWithAll}
      >
        {t('upsell.actions.acceptAll')}
      </Button>

      {isSecondaryButtonVisible && (
        <Button
          width="100%"
          backgroundColor={Color.GREY_1000}
          color={Color.WHITE}
          fontSize="17px"
          fontWeight="700"
          lineHeight="24px"
          padding="16px"
          borderRadius="30px"
          margin="0 0 16px 0"
          onClick={!selectedProduct.length ? skip : makeUpsell}
        >
          {!selectedProduct.length
            ? t('upsell.actions.withoutCourses')
            : t('upsell.actions.acceptSelected')}
        </Button>
      )}

      <S.EmailWrapper>
        <S.Email>
          <Trans i18nKey="upsell.email" values={{ email }} />
        </S.Email>
      </S.EmailWrapper>
    </>
  )
}
