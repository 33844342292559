import React, { ButtonHTMLAttributes } from 'react'

import { Button } from 'components/Button'

import { StyledNavigationButton as S } from './NavigationButton.styles'

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  disabled?: boolean
  onClick?: () => void
}

export const NavigationButton: React.FC<IButtonProps> = ({
  disabled,
  onClick,
  children,
  ...props
}) => (
  <S.Root data-testid="navigation-root">
    <Button
      data-testid="navigation-button"
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      {children}
    </Button>
  </S.Root>
)
