import { PurchaseProduct } from 'modules/purchase/constants'

import { Cohort } from 'root-constants'

export const SUBSCRIPTION_DESCRIPTIONS = {
  [Cohort.MF_INTRO]: {
    [PurchaseProduct.SEVEN_DAY]: 'purchase.subscription.weeklyDisclaimer',
    [PurchaseProduct.ONE_MONTH]: 'purchase.subscription.monthlyDisclaimer',
    [PurchaseProduct.THREE_MONTHS]: 'purchase.subscription.quarterlyDisclaimer',
  },
  [Cohort.MF_FLOW_2]: {
    [PurchaseProduct.SEVEN_DAY]: 'purchase.subscription.weeklyDisclaimer',
    [PurchaseProduct.ONE_MONTH]: 'purchase.subscription.monthlyDisclaimer',
    [PurchaseProduct.THREE_MONTHS]: 'purchase.subscription.quarterlyDisclaimer',
  },
  [Cohort.MF_INTRO_2]: {
    [PurchaseProduct.SEVEN_DAY]: 'purchase.subscription.weeklyDisclaimerV2',
    [PurchaseProduct.ONE_MONTH]: 'purchase.subscription.monthlyDisclaimer',
    [PurchaseProduct.THREE_MONTHS]: 'purchase.subscription.quarterlyDisclaimer',
  },
  [Cohort.MF_WOMEN_AGE]: {
    [PurchaseProduct.SEVEN_DAY]: 'purchase.subscription.weeklyDisclaimerV2',
    [PurchaseProduct.ONE_MONTH]: 'purchase.subscription.monthlyDisclaimer',
    [PurchaseProduct.THREE_MONTHS]: 'purchase.subscription.quarterlyDisclaimer',
  },
  [Cohort.MF_FLOW_2_INTRO_2]: {
    [PurchaseProduct.SEVEN_DAY]: 'purchase.subscription.weeklyDisclaimerV2',
    [PurchaseProduct.ONE_MONTH]: 'purchase.subscription.monthlyDisclaimer',
    [PurchaseProduct.THREE_MONTHS]: 'purchase.subscription.quarterlyDisclaimer',
  },
  [Cohort.MF_MEN_FLOW]: {
    [PurchaseProduct.SEVEN_DAY]: 'purchase.subscription.weeklyDisclaimerV2',
    [PurchaseProduct.ONE_MONTH]: 'purchase.subscription.monthlyDisclaimer',
    [PurchaseProduct.THREE_MONTHS]: 'purchase.subscription.quarterlyDisclaimer',
  },
  [Cohort.MF_WOMEN_45]: {
    [PurchaseProduct.SEVEN_DAY]: 'purchase.subscription.weeklyDisclaimerV2',
    [PurchaseProduct.ONE_MONTH]: 'purchase.subscription.monthlyDisclaimer',
    [PurchaseProduct.THREE_MONTHS]: 'purchase.subscription.quarterlyDisclaimer',
  },
}

export const TRIAL_DISCLAIMER = {
  [PurchaseProduct.SEVEN_DAY]: 'purchase.subscription.weeklyDisclaimer',
  [PurchaseProduct.ONE_MONTH]: 'purchase.subscription.monthlyDisclaimer',
  [PurchaseProduct.THREE_MONTHS]: 'purchase.subscription.quarterlyDisclaimer',
}
