import { DailyWaterValue } from 'value-constants'

import coffeeIcon from 'assets/images/cup-of-coffee.png'
import okIcon from 'assets/images/ok-fingers.png'
import peaceIcon from 'assets/images/peace-emoji.png'
import rockIcon from 'assets/images/rock-hand.png'

export const DAILY_WATER_DISCLAIMER = {
  [DailyWaterValue.COFFEE_OR_TEE]:
    'onboarding.dailyWater.optionsV1.teaOrCoffee',
  [DailyWaterValue.TWO_GLASSES]: 'onboarding.dailyWater.optionsV1.twoGlasses',
  [DailyWaterValue.TWO_SIX_GLASSES]:
    'onboarding.dailyWater.optionsV1.twoSixGlasses',
  [DailyWaterValue.MORE_THAN_SIX_GLASSES]:
    'onboarding.dailyWater.optionsV1.moreThanSixGlasses',
} as const

export const TRANSLATION_FAMILY = 'onboarding.dailyWaterV3'

export const OPTIONS = [
  {
    key: 'coffeeOrTee',
    value: DailyWaterValue.COFFEE_OR_TEE,
    iconSrc: coffeeIcon,
  },
  {
    key: 'twoGlasses',
    value: DailyWaterValue.TWO_GLASSES,
    iconSrc: peaceIcon,
  },
  {
    key: 'twoSixGlasses',
    value: DailyWaterValue.TWO_SIX_GLASSES,
    iconSrc: okIcon,
  },
  {
    key: 'moreThanSixGlasses',
    value: DailyWaterValue.MORE_THAN_SIX_GLASSES,
    iconSrc: rockIcon,
  },
]
