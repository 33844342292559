import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledIntroMediaV5 = {
  BeforeAfter: styled.div`
    display: flex;
    overflow: hidden;
    width: 100%;
    border-radius: 16px 16px 0 0;
    aspect-ratio: 328/276;
  `,
  FatLevel: styled.div`
    display: flex;
    column-gap: 2px;
    width: 100%;
  `,
  FatValue: styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px;
    width: 50%;
    background-color: ${Color.GREY_200};

    font-weight: 600;
    font-size: 14px;
    color: ${Color.GREY_100};

    strong {
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      margin: 0 0 4px 0;
    }

    &:first-of-type {
      border-bottom-left-radius: 16px;
    }

    &:last-of-type {
      border-bottom-right-radius: 16px;
    }
  `,
}
