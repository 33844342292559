import { BmiValue } from 'value-constants'

import checkIcon from 'assets/images/check-circle-2.png'
import exclaimationIcon from 'assets/images/exclaimation-circle-2.png'
import exclaimationWarningIcon from 'assets/images/exclaimation-warning-circle-2.png'

export const QUESTION = 'Your current weight'

export const enum MinWeight {
  MIN_WEIGHT_KG = 21,
  MIN_WEIGHT_LB = 46,
}

export const CURRENT_BMI_INFO_MAP = {
  [BmiValue.UNDERWEIGHT]: {
    iconSrc: exclaimationIcon,
    background: 'rgba(231, 76, 60, 0.10)',
    text: 'onboarding.currentWeight.mbiInfo.underweight',
    bmiValue: 'onboarding.currentWeight.disclaimer.label.underweight',
  },
  [BmiValue.NORMAL]: {
    iconSrc: checkIcon,
    background: 'rgba(169, 205, 80, 0.20)',
    text: 'onboarding.currentWeight.mbiInfo.normal',
    bmiValue: 'onboarding.currentWeight.disclaimer.label.normal',
  },
  [BmiValue.OVERWEIGHT]: {
    iconSrc: exclaimationWarningIcon,
    background: 'rgba(255, 153, 0, 0.10)',
    text: 'onboarding.currentWeight.mbiInfo.overweight2',
    bmiValue: 'onboarding.currentWeight.disclaimer.label.overweight',
  },
  [BmiValue.OBESITY]: {
    iconSrc: exclaimationIcon,
    background: 'rgba(231, 76, 60, 0.10)',
    text: 'onboarding.currentWeight.mbiInfo.obesity',
    bmiValue: 'onboarding.currentWeight.disclaimer.label.obese',
  },
  [BmiValue.EXTREME_OBESITY]: {
    iconSrc: exclaimationIcon,
    background: 'rgba(231, 76, 60, 0.10)',
    text: 'onboarding.currentWeight.mbiInfo.obesity',
    bmiValue: 'onboarding.currentWeight.disclaimer.label.extrObese',
  },
}

export const MAX_WEIGHT_VALUE_LENGTH = 3
