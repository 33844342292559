import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Container } from 'components/Container'
import { Option, OptionType } from 'components/Option'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import maleOptionImage from 'assets/images/man-tipping-hand.png'
import pointUpImage from 'assets/images/point-up.png'
import femaleOptionImage from 'assets/images/woman-tipping-hand.png'

import { CustomPageId } from 'page-constants'
import { Gender, Language } from 'root-constants'

import { StyledGenderV4 as S } from './GenderV4.styles'

export const GenderV4: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId: CustomPageId.GENDER,
    question: t('onboarding.genderV4.title', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: CustomPageId.GENDER,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [handleChange, setIsAnswersDisabled, handleContinue],
  )

  return (
    <Container>
      <S.Title>{t`onboarding.genderV4.title`}</S.Title>
      <S.TextContainer>
        <S.PointUpImage src={pointUpImage} alt="point-up" />
        <S.Subtitle>{t`onboarding.genderV4.subtitle`}</S.Subtitle>
        <S.Disclaimer>{t`onboarding.genderV4.disclaimer`}</S.Disclaimer>
      </S.TextContainer>

      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={Gender.FEMALE}
      >
        <S.AnswerButton iconSrc={femaleOptionImage}>
          <h3>{t('onboarding.gender.female')}</h3>
        </S.AnswerButton>
      </Option>

      <Option {...optionProps} disabled={isAnswersDisabled} value={Gender.MALE}>
        <S.AnswerButton iconSrc={maleOptionImage}>
          <h3>{t('onboarding.gender.male')}</h3>
        </S.AnswerButton>
      </Option>
    </Container>
  )
}
