import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledUpsellFeedbacks = {
  Container: styled.div`
    margin-bottom: 40px;
    width: 100%;
  `,
  Title: styled.h2`
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 24px;
    text-align: center;
  `,
  List: styled.ul`
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  `,
  Item: styled.li`
    border-radius: 12px;
    box-shadow: 0px 8px 20px 0px rgba(17, 30, 23, 0.1);
    display: flex;
    flex-direction: column;
    padding: 16px;
    row-gap: 8px;
  `,
  ItemTitle: styled.h3`
    color: ${Color.ORANGE};
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.15px;
    line-height: 24px;
  `,
  ItemInfo: styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  `,
  ItemAbout: styled.p`
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  `,
}
