import banner1 from 'assets/images/kit-creating-plan-banner-1.png'
import banner2 from 'assets/images/kit-creating-plan-banner-2.png'
import banner3 from 'assets/images/kit-creating-plan-banner-3.png'
import banner4 from 'assets/images/kit-creating-plan-banner-4.png'
import banner5 from 'assets/images/kit-creating-plan-banner-5.png'

export const COUNTER_DURATION = 15500

export const CREATING_PLAN_ITEMS = [
  {
    imgSrc: banner1,
    subtitle: 'onboarding.creatingPlanV4.subtitleFirst',
  },
  {
    imgSrc: banner2,
    subtitle: 'onboarding.creatingPlanV4.subtitleSecond',
  },
  {
    imgSrc: banner3,
    subtitle: 'onboarding.creatingPlanV4.subtitleThird',
  },
  {
    imgSrc: banner4,
    subtitle: 'onboarding.creatingPlanV4.subtitleFourth',
  },
  {
    imgSrc: banner5,
    subtitle: 'onboarding.creatingPlanV4.subtitleFifth',
  },
]
