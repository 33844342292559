import styled from 'styled-components'

import { PageContainer } from 'components/PageContainer'

import { SecurityInfo } from 'modules/purchase/components'

import { Color } from 'root-constants'

export const StyledUpsellPaywallV2 = {
  PageContainer: styled(PageContainer)`
    padding: 4px 16px 32px;
  `,
  SecurityInfo: styled(SecurityInfo)`
    background-color: ${Color.WHITE};
    border-radius: 14px;
    box-shadow: 0 4px 12px -2px rgba(59, 59, 62, 0.16);
    margin-bottom: 32px;
    padding: 50px 20px 20px;

    h2 {
      padding-bottom: 10px;
    }

    p {
      font-weight: normal;
      font-size: 14px;
      padding-bottom: 20px;
    }

    img {
      background: ${Color.WHITE};
      border-radius: 14px;
      box-shadow: 0 4px 12px -2px rgba(59, 59, 62, 0.16);
      left: 27%;
      max-width: 156px;
      padding: 3px 26px;
      position: absolute;
      top: -30px;
    }
  `,
}
