import styled from 'styled-components'

type TProps = {
  isHeaderTimer?: boolean
  hasText?: boolean
  hasIndent?: boolean
}

export const StyledTimer = {
  Timer: styled.div<TProps>`
    display: flex;
    align-items: center;

    strong {
      font-weight: 700;
      font-size: ${({ isHeaderTimer }) => (isHeaderTimer ? `18px` : `30px`)};
    }

    span {
      font-weight: 700;
      font-size: 12px;
    }
  `,
  TimerIcon: styled.div`
    aspect-ratio: 1 / 1;
    margin: 0 10px 0 0;
    width: 32px;
  `,
  TimerSign: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 1;
  `,
  Separator: styled.strong<TProps>`
    line-height: 30px;
    ${({ hasText }) => hasText && 'align-self: flex-start;'};
    ${({ hasIndent, isHeaderTimer }) =>
      hasIndent && !isHeaderTimer ? `margin: 0 8px;` : `margin: 0;`};
  `,
}
