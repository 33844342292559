import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Swiper, SwiperSlide } from 'swiper/react'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import ratingImage from 'assets/images/stars.png'

import { Cohort } from 'root-constants'

import { StyledReviewsSlider as S } from './ReviewSlider.styles'
import { REVIEWS_DATA_V1, REVIEWS_DATA_V2 } from './constants'

type TProps = React.HTMLAttributes<HTMLDivElement>

export const ReviewsSlider: React.FC<TProps> = (props) => {
  const { t } = useTranslation()

  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const reviewsData = useMemo(
    () =>
      cohortToUse === Cohort.MF_MEN_FLOW ? REVIEWS_DATA_V2 : REVIEWS_DATA_V1,
    [cohortToUse],
  )

  return (
    <S.Wrapper {...props}>
      <Swiper
        slidesPerView="auto"
        spaceBetween={8}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        loop
      >
        {reviewsData.map(({ userName, reviewText }) => (
          <SwiperSlide key={userName}>
            <S.Card>
              <S.CardText>{t(reviewText)}</S.CardText>
              <S.Container>
                <S.UserName>{t(userName)}</S.UserName>
                <S.Rating src={ratingImage} alt="stars" />
              </S.Container>
            </S.Card>
          </SwiperSlide>
        ))}
      </Swiper>
    </S.Wrapper>
  )
}
