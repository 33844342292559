import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import firebase from 'firebase/app'
import 'firebase/auth'

import { Container } from 'components/Container'
import { ErrorNotification } from 'components/ErrorNotification'
import { Footer } from 'components/Footer'
import { Modal } from 'components/Modal'
import { Spinner } from 'components/Spinner'
import { SvgImage } from 'components/SvgImage'

import { resetErrorAction } from 'root-redux/actions/common'
import { bindUserAction } from 'root-redux/actions/user'
import { selectError, selectIsFetching } from 'root-redux/selects/common'
import { selectUserOnboardingEmail } from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import { useAuthObserver } from 'hooks/useAuthObserver'
import { useEmailInputField } from 'hooks/useEmailInputField'
import { useGetRedirectResult } from 'hooks/useGetRedirectResult'
import { useInitFirebase } from 'hooks/useInitFirebase'
import { usePasswordInputField } from 'hooks/usePasswordInputField'

import { getMobileOperatingSystem } from 'helpers/getMobileOperatingSystem'

import {
  registerWithEmailFirebaseAction,
  resetEmailErrorMessageAction,
  resetPasswordErrorMessageAction,
  selectEmailErrorMessage,
  selectPasswordErrorMessage,
} from 'modules/login/redux'

import { eventLogger } from 'services/eventLogger.service'

import continueWithApple from 'assets/images/sprite/continue-with-apple.svg'

import { LoginMethod, PlatformOS } from 'root-constants'

import { StyledLogin as S, StyledLoginButton } from './Login.styles'
import { Disclaimer } from './components/Disclaimer'
import { EmailLogin } from './components/EmailLogin'

export const Login: React.FC = () => {
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()

  const error = useSelector(selectError)
  const isFetching = useSelector(selectIsFetching)
  const emailErrorMessage = useSelector(selectEmailErrorMessage)
  const passwordErrorMessage = useSelector(selectPasswordErrorMessage)
  const userOnboardingEmail = useSelector(selectUserOnboardingEmail)

  const [isModalShown, setIsModalShown] = useState(false)
  const [isFirebaseDataLoading, setIsFirebaseDataLoading] = useState(false)

  const [isOtherLoginOptionsVisible, setIsOtherLoginOptionsValue] =
    useState(false)

  const [email, setEmail] = useEmailInputField(emailErrorMessage, () =>
    dispatch(resetEmailErrorMessageAction()),
  )

  const [password, setPassword] = usePasswordInputField(
    passwordErrorMessage,
    () => dispatch(resetPasswordErrorMessageAction()),
  )

  const isAndroid = useMemo(
    () => getMobileOperatingSystem() === PlatformOS.ANDROID,
    [],
  )

  const errorText = useMemo(() => {
    if (!email.isValid && !password.isValid) return t('login.invalidFields')
    if (!email.isValid) return email.firebaseError || email.validationText
    if (!password.isValid) {
      return password.firebaseError || password.validationText
    }

    return ''
  }, [
    t,
    email.isValid,
    email.validationText,
    email.firebaseError,
    password.isValid,
    password.validationText,
    password.firebaseError,
  ])

  const isComplete = useMemo(
    () =>
      email.isValid &&
      email.value !== '' &&
      password.isValid &&
      password.value !== '',
    [email.isValid, email.value, password.isValid, password.value],
  )

  const signInFirebase = useCallback(
    (provider: firebase.auth.AuthProvider) =>
      firebase.auth().signInWithRedirect(provider),
    [],
  )

  useEffect(() => {
    setEmail((prevState) => ({ ...prevState, value: userOnboardingEmail }))
  }, [setEmail, userOnboardingEmail])

  useEffect(() => {
    error && setIsModalShown(true)
  }, [error])

  useEffect(() => {
    eventLogger.logCreateAccountShown()
  }, [])

  const handleContinueWithApple = useCallback(async () => {
    eventLogger.logLoginMethodSelected({ method: LoginMethod.APPLE })

    await signInFirebase(new firebase.auth.OAuthProvider('apple.com'))
  }, [signInFirebase])

  const handleContinueWithEmail = useCallback(
    (e: SyntheticEvent<HTMLFormElement>) => {
      e.preventDefault()

      eventLogger.logLoginMethodSelected({ method: LoginMethod.EMAIL })
      dispatch(
        registerWithEmailFirebaseAction({
          email: email.value,
          password: password.value,
        }),
      )
    },
    [dispatch, email.value, password.value],
  )

  const handleButtonsClick = useCallback(
    (event: SyntheticEvent<HTMLDivElement>) => {
      if (error) {
        event.stopPropagation()
        setIsModalShown(true)
      }
    },
    [error],
  )

  const resetInputValues = useCallback(() => {
    setIsOtherLoginOptionsValue(true)
    setPassword((prevState) => ({ ...prevState, value: '' }))

    eventLogger.logOtherSignupOptionsShown()
  }, [setPassword])

  const authStateChangeHandler = useCallback(
    (token: string) => {
      dispatch(bindUserAction(token))
    },
    [dispatch],
  )

  useInitFirebase()
  useGetRedirectResult(authStateChangeHandler, setIsFirebaseDataLoading)
  useAuthObserver(authStateChangeHandler)

  return (
    <Container>
      {(isFetching || isFirebaseDataLoading) && <Spinner />}
      <div>
        <S.Title>{t('login.title')}</S.Title>
        <S.Form onSubmit={handleContinueWithEmail}>
          <EmailLogin
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
          />
          {(!isOtherLoginOptionsVisible || password.value) && (
            <div>
              {!isAndroid && (
                <S.SignUpLink onClick={resetInputValues}>
                  <Trans i18nKey="login.signupOptions" />
                </S.SignUpLink>
              )}

              <S.DisclaimerContainer>
                <ErrorNotification errorText={errorText} />
                <Disclaimer />
              </S.DisclaimerContainer>
              <S.Button type="submit" disabled={!isComplete || isFetching}>
                {t('actions.continue')}
              </S.Button>
            </div>
          )}
        </S.Form>

        {isOtherLoginOptionsVisible && !password.value && (
          <div onClickCapture={handleButtonsClick}>
            <S.Separator>
              <S.SeparatorLine />
              <S.SeparatorText>{t('login.separatorText')}</S.SeparatorText>
              <S.SeparatorLine />
            </S.Separator>

            {!isAndroid && (
              <StyledLoginButton.Apple onClick={handleContinueWithApple}>
                <SvgImage svg={continueWithApple} width={24} />
                <span>{t('actions.continueWithApple')}</span>
              </StyledLoginButton.Apple>
            )}
            <Disclaimer padding={10} />
          </div>
        )}
      </div>

      <S.FooterContainer>
        <Footer />
      </S.FooterContainer>

      <Modal
        onClose={() => {
          setIsModalShown(false)
          dispatch(resetErrorAction())
        }}
        isShown={isModalShown}
        error={error}
      />
    </Container>
  )
}
