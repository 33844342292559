import styled from 'styled-components'

import { SvgImage } from 'components/SvgImage'

import { Color } from 'root-constants'

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 28px;
`
export const HeaderTitle = styled.h3`
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  color: ${Color.GREY_100};
  letter-spacing: -0.45px;
  margin-bottom: 4px;
`
export const HeaderSubtitle = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${Color.GREY_100};
`
export const HeaderLabel = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: ${Color.PRIMARY};
  background-color: #e9f8f0;
  border: 1px solid ${Color.PRIMARY};
  border-radius: 6px;
  padding: 4px 8px;
`

export const GlassImg = styled(SvgImage)`
  width: 21px;
  height: 32px;
`

export const StyledWaterConsumption = {
  Root: styled.div`
    margin-bottom: 16px;
    background-color: ${Color.WHITE};
    padding: 16px 16px 20px;
    box-shadow: 0 12px 32px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
  `,
  WaterGlasses: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  WaterGlass: styled.div``,
}
