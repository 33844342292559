import styled from 'styled-components'

import { Container } from 'components/Container'
import { PageTitle } from 'components/PageTitle'
import { SvgImage } from 'components/SvgImage'

import pageBg from 'assets/images/intro-flow-bg.svg'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledYoEffectV2 = {
  Wrapper: styled.div`
    padding: 10px 0 24px;
    background-image: url(${pageBg});
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top center;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      background-size: 360px auto;
    }
  `,
  Container: styled(Container)`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100%;
  `,
  Title: styled(PageTitle)`
    width: 320px;
    margin-bottom: 32px;
  `,

  Image: styled(SvgImage)`
    margin-bottom: 44px;
    box-shadow: 0 8px 20px rgba(17, 30, 23, 0.1);
    border-radius: 24px;
  `,
  CardsWrapper: styled.div`
    margin-bottom: 19px;
  `,
  Card: styled.div`
    display: flex;
    margin-bottom: 19px;
    align-items: center;
    gap: 12px;
    padding: 0 16px;
  `,
  CardIconFirst: styled.img`
    width: 56px;
    height: 56px;
  `,
  CardIconSecond: styled.img`
    width: 50px;
    height: 50px;
    margin: 0 3px;
  `,
  CardText: styled.p`
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: ${Color.DARK_BLUE};

    strong {
      font-weight: 600;
    }

    i {
      text-decoration: underline;
      font-style: normal;
    }
  `,
}
