import React from 'react'
import { useTranslation } from 'react-i18next'

import { TermsOfUseLink } from 'components/TermsOfUseLink'

import badgeImg from 'assets/images/sprite/badge.svg'

import {
  Badge,
  Content,
  StyledMoneyBackGuaranteeV2 as S,
  Text,
  Title,
} from './MoneyBackGuaranteeV2.styles'

export const MoneyBackGuaranteeV2: React.FC = () => {
  const { t } = useTranslation()

  return (
    <S.Wrapper>
      <Content>
        <Title>{t('purchase.moneyBackGuarantee.title')}</Title>
        <Text>
          {t('purchase.moneyBackGuarantee.text')} <TermsOfUseLink />
        </Text>
        <Badge svg={badgeImg} />
      </Content>
    </S.Wrapper>
  )
}
