import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import imageFemale from 'assets/images/users-choice-female.png'
import imageMale from 'assets/images/users-choise-male.png'

import { Gender } from 'root-constants'

import { StyledUsersChoiceV3 as S } from './UsersChoiceV3.styles'
import { QUESTION_FEMALE, QUESTION_MALE } from './constants'

export const UsersChoiceV3: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const gender = useSelector(selectCurrentUserGender)

  const isFemale = useMemo(() => gender === Gender.FEMALE, [gender])

  const handleContinue = useNextStep({
    pageId,
    question: isFemale ? QUESTION_FEMALE : QUESTION_MALE,
    nextPagePath,
  })

  return (
    <>
      <S.Logo marginBottom={28} />
      <S.Banner>
        <img src={isFemale ? imageFemale : imageMale} alt="people" />
      </S.Banner>
      <Container>
        <S.Title>
          <Trans
            i18nKey={
              isFemale
                ? 'onboarding.usersChoice.titleFemale'
                : 'onboarding.usersChoice.titleMale'
            }
          />
        </S.Title>
        <StickyButtonContainer>
          <Button type="button" onClick={() => handleContinue('')}>
            {t('actions.continue')}
          </Button>
        </StickyButtonContainer>
      </Container>
    </>
  )
}
