import styled from 'styled-components'

import { PageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'

import { Color } from 'root-constants'

export const StyledAboutFeelingGood = {
  PageContainer: styled(PageContainer)`
    padding: 16px 16px 40px 16px;
  `,
  PageTitle: styled(PageTitle)`
    margin: 0 0 32px 0;
  `,
  ImageContainer: styled.div`
    aspect-ratio: 328/208;
    height: 208px;
    margin: 0 0 132px 0;
  `,
  ProgressTitle: styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: ${Color.GREY_100};
  `,
}
