import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { AnswerWithIcon, answerWithIconTheme } from 'storybook-ui'
import { PrimaryGoalValue } from 'value-constants'

import { OptionType } from 'components/Option'
import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import getFitIcon from 'assets/images/get-fit-icon.png'
import healthyHabitsIcon from 'assets/images/healthy-habits-icon.png'
import loseWeightFemaleIcon from 'assets/images/lose-weight-female-icon.png'
import loseWeightMaleIcon from 'assets/images/lose-weight-male-icon.png'

import { CustomPageId } from 'page-constants'
import { Gender, Language } from 'root-constants'

import { StyledPrimaryGoalV4 as S } from './PrimaryGoalV4.styles'

export const PrimaryGoalV4: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()

  const gender = useSelector(selectCurrentUserGender)

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId: CustomPageId.PRIMARY_GOAL,
    question: t('onboarding.primaryGoal.title', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: CustomPageId.PRIMARY_GOAL,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [handleChange, setIsAnswersDisabled, handleContinue],
  )

  const isFemale = useMemo(() => gender === Gender.FEMALE, [gender])

  return (
    <KitPageContainer>
      <PageTitle textAlign="left" marginBottom={24}>
        {t('onboarding.primaryGoal.title')}
      </PageTitle>

      <AnswerWithIcon
        {...optionProps}
        disabled={isAnswersDisabled}
        value={PrimaryGoalValue.LOSE_WEIGHT}
        theme={answerWithIconTheme.NUTRIMATE}
        iconSrc={isFemale ? loseWeightFemaleIcon : loseWeightMaleIcon}
        iconHeight="40px"
        iconMinWidth="40px"
        margin="0 0 12px 0"
        padding="8px 16px"
        spacingBetweenIconAndContent="0 0 16px"
      >
        <S.AnswerTitle>
          {t('commonComponents.primaryGoal.loseWeight')}
        </S.AnswerTitle>
        <S.AnswerDescription>
          {t('onboarding.primaryGoal.options.loseWeight.description2')}
        </S.AnswerDescription>
      </AnswerWithIcon>

      <AnswerWithIcon
        {...optionProps}
        disabled={isAnswersDisabled}
        value={PrimaryGoalValue.GET_FIT}
        theme={answerWithIconTheme.NUTRIMATE}
        iconSrc={getFitIcon}
        iconHeight="40px"
        iconMinWidth="40px"
        margin="0 0 12px 0"
        padding="8px 16px"
        spacingBetweenIconAndContent="0 0 16px"
      >
        <S.AnswerTitle>
          {t('commonComponents.primaryGoal.getFit')}
        </S.AnswerTitle>
        <S.AnswerDescription>
          {t('onboarding.primaryGoal.options.getFit.description')}
        </S.AnswerDescription>
      </AnswerWithIcon>

      <AnswerWithIcon
        {...optionProps}
        disabled={isAnswersDisabled}
        value={PrimaryGoalValue.BUILD_HEALTHY_HABITS}
        theme={answerWithIconTheme.NUTRIMATE}
        iconSrc={healthyHabitsIcon}
        iconHeight="40px"
        iconMinWidth="40px"
        margin="0 0 12px 0"
        padding="8px 16px"
        spacingBetweenIconAndContent="0 0 16px"
      >
        <S.AnswerTitle>
          {t('commonComponents.primaryGoal.developHealthyHabits')}
        </S.AnswerTitle>
        <S.AnswerDescription>
          {t('onboarding.primaryGoal.options.healthyHabits.description2')}
        </S.AnswerDescription>
      </AnswerWithIcon>
    </KitPageContainer>
  )
}
