import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { PrimaryGoalValue } from 'value-constants'

import { AnswerButtonV2 } from 'components/AnswerButtonV2'
import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { selectCurrentUserPrimaryGoal } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { CustomPageId } from 'page-constants'

import { ANSWER_OPTIONS, PAST_TRIES_VALUES, QUESTION } from './constants'

export const PastTries: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()

  const primaryGoal = useSelector(selectCurrentUserPrimaryGoal)

  const [answers, setAnswers] = useState<string[]>([])

  const optionProps = useMemo(
    () => ({
      name: CustomPageId.PAST_TRIES,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked) {
          setAnswers(
            answers.includes(PAST_TRIES_VALUES.NONE)
              ? [value]
              : [...answers, value],
          )
        } else {
          setAnswers(answers.filter((item) => item !== value))
        }
      },
    }),
    [answers],
  )

  const optionPropsForSingle = useMemo(
    () => ({
      name: CustomPageId.PAST_TRIES,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked) {
          setAnswers([value])
        } else {
          setAnswers([])
        }
      },
    }),
    [],
  )

  const pageTitle = useMemo(
    () =>
      t('onboarding.pastTries.title', {
        primaryGoal:
          primaryGoal === PrimaryGoalValue.LOSE_WEIGHT
            ? t('commonComponents.primaryGoal.loseWeight')
            : t('commonComponents.primaryGoal.getHealthy'),
      }),
    [primaryGoal, t],
  )

  const handleContinue = useNextStep({
    pageId: CustomPageId.PAST_TRIES,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue(answers)
  }, [answers, handleContinue])

  return (
    <Container>
      <PageTitle marginBottom={24}>{pageTitle}</PageTitle>
      {ANSWER_OPTIONS.map(({ value, title }) => (
        <Option
          key={value}
          {...optionProps}
          marginBottom="12"
          value={value}
          checked={answers.includes(value)}
        >
          <AnswerButtonV2 withCheckbox>
            <h3>{t(title)}</h3>
          </AnswerButtonV2>
        </Option>
      ))}
      <Option
        {...optionPropsForSingle}
        marginBottom="12"
        value={PAST_TRIES_VALUES.NONE}
        checked={answers.includes(PAST_TRIES_VALUES.NONE)}
      >
        <AnswerButtonV2>
          <h3>{t('onboarding.pastTries.options.none')}</h3>
        </AnswerButtonV2>
      </Option>
      <StickyButtonContainer>
        <Button
          type="button"
          disabled={!answers.length}
          onClick={handleNextClick}
        >
          {t('actions.continue')}
        </Button>
      </StickyButtonContainer>
    </Container>
  )
}
