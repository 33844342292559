import styled from 'styled-components'

import { PageTitle } from 'components/PageTitle'

import { Color } from 'root-constants'

export const StyledImprovements = {
  Banner: styled.div`
    aspect-ratio: 270 / 197;
    margin-bottom: 24px;
    width: calc(100% - 58px);
  `,
  Title: styled(PageTitle)`
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 8px;
  `,
  Subtitle: styled.h2`
    color: ${Color.GREY_100};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 24px;
    text-align: center;
  `,
  List: styled.ul`
    display: flex;
    flex-direction: column;
    margin: 0 auto 69px;
    row-gap: 16px;
    width: fit-content;
  `,
  Item: styled.li`
    align-items: center;
    column-gap: 12px;
    display: flex;
  `,
  ItemText: styled.h4`
    color: ${Color.GREY_100};
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  `,
  CheckIcon: styled.div`
    aspect-ratio: 1 / 1;
    width: 20px;
  `,
}
