export const EATING_MYTHS = [
  { textPath: 'cancelOffer.questionPage.eatAfterSix' },
  { textPath: 'cancelOffer.questionPage.eatingFat' },
  { textPath: 'cancelOffer.questionPage.bananas' },
  { textPath: 'cancelOffer.questionPage.treats' },
]

export const OPTION_VALUES = {
  YES: 'yes',
  NO: 'no',
}

export const IMAGE_PATH = '/images/question-intro'
