import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'

import { useGetPageInfo } from 'hooks/useGetPageInfo'

export const useDynamicOBConfig = () => {
  const language = useSelector(selectLanguage)
  const { currentPageIndex, steps } = useGetPageInfo()

  return useMemo(() => {
    const dynamicOBConfig = steps[currentPageIndex]?.onboardingDynamicConfig

    return {
      title: dynamicOBConfig?.[language]?.title,
      subtitle: dynamicOBConfig?.[language]?.subtitle,
      buttonText: dynamicOBConfig?.[language]?.buttonText,
      imageUrls: dynamicOBConfig?.[language]?.imageUrls,
      pageTexts: dynamicOBConfig?.[language]?.pageTexts,
      personalization: dynamicOBConfig?.[language]?.personalization,
    }
  }, [currentPageIndex, language, steps])
}
