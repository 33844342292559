import React from 'react'
import { useTranslation } from 'react-i18next'

import { SvgImage } from 'components/SvgImage'

import starSvg from 'assets/images/sprite/star-v2.svg'

import { StyledCustomerReviews as S } from './CustomerReviews.styles'
import { CUSTOMER_REVIEWS } from './constants'

export const CustomerReviews: React.FC = () => {
  const { t } = useTranslation()

  return (
    <S.Wrapper>
      <S.Title>{t('purchase.customerReviews.title')}</S.Title>
      {CUSTOMER_REVIEWS.map(({ id, title, date, author, text }) => (
        <S.ReviewContainer key={id}>
          <S.ReviewHeader>
            <S.ReviewTitle>{t(title)}</S.ReviewTitle>
            <S.Date>{date}</S.Date>
            <S.Rating>
              <SvgImage svg={starSvg} />
              <SvgImage svg={starSvg} />
              <SvgImage svg={starSvg} />
              <SvgImage svg={starSvg} />
              <SvgImage svg={starSvg} />
            </S.Rating>
            <S.Author>{author}</S.Author>
          </S.ReviewHeader>
          <S.TextContent>
            <S.TextContainer>
              <S.Text>{t(text)}</S.Text>
            </S.TextContainer>
          </S.TextContent>
        </S.ReviewContainer>
      ))}
    </S.Wrapper>
  )
}
