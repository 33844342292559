import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import dayjs from 'dayjs'
import { MotivationValue } from 'value-constants'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { CustomDatePicker } from 'components/CustomDatePicker'
import { PageTitle } from 'components/PageTitle'

import { selectCurrentUserMotivation } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { DEFAULT_DATE_FORMAT } from 'helpers/dateHelper'

import { TPageProps } from 'models/common.model'

import { replaceHistory } from 'browser-history'
import { CustomPageId } from 'page-constants'

import { StyledEventDate as S } from './EventDate.styles'
import { QUESTION } from './constants'

export const EventDate: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const motivation = useSelector(selectCurrentUserMotivation)

  const minDate = dayjs().add(1, 'day').toDate()

  const [startDate, setStartDate] = useState(minDate)
  const [isOpen, setOpen] = useState(false)

  const shouldBeRedirect = useMemo(
    () => motivation === MotivationValue.NO,
    [motivation],
  )

  useLayoutEffect(() => {
    if (shouldBeRedirect) {
      replaceHistory(nextPagePath)
    }
  }, [nextPagePath, shouldBeRedirect])

  const handleContinue = useNextStep({
    pageId: CustomPageId.EVENT_DATE,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue(dayjs(startDate).format(DEFAULT_DATE_FORMAT))
  }, [handleContinue, startDate])

  const handleSkip = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  const toggleDatePickerVisibility = useCallback(
    () => setOpen((open) => !open),
    [],
  )

  return (
    <Container>
      <PageTitle marginBottom={8}>{t('onboarding.eventDate.title')}</PageTitle>
      <S.SubTitle>{t('onboarding.eventDate.subTitle')}</S.SubTitle>
      <S.Overlay
        open={isOpen}
        onClick={(event) => event.stopPropagation()}
        onTouchStart={(event) => event.stopPropagation()}
      />
      <CustomDatePicker
        selectedDate={startDate}
        minDate={minDate}
        toggleDatePickerVisibility={toggleDatePickerVisibility}
        onChange={setStartDate}
      />
      <S.FixedButtonContainer>
        <Button
          onClick={handleNextClick}
          disabled={isOpen}
          disabledBackgroundColor="#6fd09a"
        >
          {t('actions.continue')}
        </Button>
        <S.SkipButton onClick={handleSkip}>
          {t('onboarding.eventDate.skip')}
        </S.SkipButton>
      </S.FixedButtonContainer>
    </Container>
  )
}
