import { PaymentIntentResult, StripeError } from '@stripe/stripe-js'

import { TProductId } from 'models/subscriptions.model'

import { Events, eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import {
  OutbrainEventName,
  ScreenName,
  StripeFieldName,
  TaboolaEventName,
  TimeInterval,
} from 'root-constants'

import {
  EMPTY_FIELD_ERROR,
  PaymentMethod,
  PaymentSystem,
  STRIPE_SOFT_DECLINE_REASONS,
  StripeErrorCode,
  StripeSoftDeclineReason,
  THREEDS_REDIRECT_SEARCH_PARAM,
} from '../constants'
import { TPaymentErrorState } from '../types'

export const getPaymentErrorStateBySubmitWithUntouchedFields = (
  errors: TPaymentErrorState,
): TPaymentErrorState =>
  Object.entries(errors).reduce(
    (result, error) => ({
      ...result,
      [error[0]]: {
        ...error[1],
        ...(!error[1].isTouched && {
          isTouched: true,
          error: EMPTY_FIELD_ERROR,
        }),
      },
    }),
    {} as TPaymentErrorState,
  )

export const getDefaultPaymentErrorsState = (): Record<
  StripeFieldName,
  {
    error: string
    isTouched: boolean
    isShown: boolean
    isComplete: boolean
  }
> => ({
  [StripeFieldName.NAME]: {
    error: '',
    isTouched: true,
    isShown: true,
    isComplete: true,
  },
  [StripeFieldName.NUMBER]: {
    error: '',
    isTouched: false,
    isShown: true,
    isComplete: false,
  },
  [StripeFieldName.EXPIRY]: {
    error: '',
    isTouched: false,
    isShown: true,
    isComplete: false,
  },
  [StripeFieldName.CVC]: {
    error: '',
    isTouched: false,
    isShown: true,
    isComplete: false,
  },
})

export const getRedirectUrl = (paymentPageId: string): string =>
  `${window.location.origin}/${paymentPageId}?${THREEDS_REDIRECT_SEARCH_PARAM}`

export const checkIsRetryAllowed = (
  confirmCardPaymentResponse: PaymentIntentResult,
): boolean => {
  const declineCode =
    confirmCardPaymentResponse?.error?.decline_code ||
    confirmCardPaymentResponse?.error?.code

  return STRIPE_SOFT_DECLINE_REASONS.includes(
    declineCode as StripeSoftDeclineReason,
  )
}

export const logSuccessfulPayment = ({
  productId,
  price,
  trialPrice,
  trialPeriodDays,
  trialPriceId,
  subscriptionId,
  discountApplied,
  uuid,
  periodName,
  periodQuantity,
  paymentMethod,
  paymentSystem,
  currency,
  screenName,
  email,
  stripeAccountId,
  stripeAccountName,
  upsell,
  isInApp,
  isPersonalDataAllowed,
}: {
  productId: TProductId
  price: number
  trialPrice: number
  trialPeriodDays: number
  trialPriceId: string
  subscriptionId: string
  discountApplied?: string
  uuid: string
  periodName: TimeInterval | null
  periodQuantity: number | null
  paymentMethod?: PaymentMethod
  paymentSystem: PaymentSystem
  currency: string
  screenName: ScreenName
  email: string
  stripeAccountId: string
  stripeAccountName: string
  upsell?: boolean
  isInApp?: boolean
  isPersonalDataAllowed: boolean
}): void => {
  const eventName: Events = isInApp
    ? Events.INAPP_PURCHASE_COMPLETED
    : Events.PURCHASE_COMPLETED

  if (trialPeriodDays) {
    window.fbq(
      'track',
      'StartTrial',
      {
        value: trialPrice,
        currency,
        subscription_id: subscriptionId,
        // This field and similar ones below have been added to avoid FB pixel issue. https://github.com/facebook/facebook-nodejs-business-sdk/issues/164
        subscription_sid: subscriptionId,
      },
      { eventID: uuid },
    )
  }
  window.ttq.identify({ email: isPersonalDataAllowed ? email : '' })
  window.ttq.track('CompletePayment', {
    value: trialPrice || price,
    currency,
    content_id: subscriptionId,
  })
  window.snaptr('track', 'PURCHASE', {
    price: trialPrice || price,
    currency,
    transaction_id: subscriptionId,
    user_email: isPersonalDataAllowed ? email : '',
  })
  window.fbq(
    'track',
    'Subscribe',
    {
      value: trialPrice || price,
      currency,
      subscription_id: subscriptionId,
      subscription_sid: subscriptionId,
    },
    { eventID: uuid },
  )

  if (!upsell) {
    window.obApi &&
      window.obApi('track', OutbrainEventName.PURCHASE, {
        orderValue: trialPriceId ? trialPrice : price,
        orderId: subscriptionId,
        currency: currency.toUpperCase(),
      })
    window._tfa &&
      window._tfa.push({
        notify: 'event',
        name: TaboolaEventName.PURCHASE,
        revenue: trialPrice || price,
        orderId: subscriptionId,
        currency: currency.toUpperCase(),
      })
  }

  eventLogger.logPurchaseCompleted({
    event: eventName,
    productId,
    priceDetails: {
      price,
      trial: upsell ? false : !!trialPeriodDays,
      currency,
    },
    paymentMethod,
    paymentSystem,
    screenName,
    discountApplied,
    stripeAccountId,
    stripeAccountName,
  })

  googleAnalyticsLogger.logPurchaseCompleted({
    subscriptionId,
    price,
    periodName,
    periodQuantity,
    currency,
    screenName,
  })
}

export const logFailedPayment = ({
  productId,
  priceDetails,
  paymentResponse: { type, code, message, decline_code: declineCode },
  paymentMethod,
  screenName,
  stripeAccountId,
  stripeAccountName,
}: {
  productId: TProductId
  priceDetails: { price: number; trial: boolean; currency: string }
  paymentResponse: StripeError
  paymentMethod: PaymentMethod
  screenName: ScreenName
  stripeAccountId: string
  stripeAccountName: string
}): void => {
  const errorCode =
    code === StripeErrorCode.CARD_DECLINED ? `${code}:${declineCode}` : code

  eventLogger.logPurchaseFailed({
    productId,
    priceDetails,
    paymentMethod,
    screenName,
    stripeAccountId,
    stripeAccountName,
    paymentSystem: PaymentSystem.STRIPE,
    error: { type, code: errorCode, description: message },
  })
}
const get2DigitValue = (value: number): string =>
  value.toString().padStart(2, '0')

export const getDecoratedTimerValue = (
  valueInSeconds: number | null,
  withHour: boolean,
): { hours: string; minutes: string; seconds: string } => {
  if (valueInSeconds) {
    const hours = Math.trunc(valueInSeconds / 3600)
    const minutes = Math.trunc(valueInSeconds / 60)
    const seconds = valueInSeconds - minutes * 60

    return {
      hours: withHour ? get2DigitValue(hours) : '',
      minutes: get2DigitValue(minutes),
      seconds: get2DigitValue(seconds),
    }
  }

  return {
    hours: withHour ? '00' : '',
    minutes: '00',
    seconds: '00',
  }
}
