import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { DailyWaterValue } from 'value-constants'

import { AnswerButton } from 'components/AnswerButton'
import { Container } from 'components/Container'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import coffeeIcon from 'assets/images/cup-of-coffee.png'
import okIcon from 'assets/images/ok-fingers.png'
import peaceIcon from 'assets/images/peace-emoji.png'
import rockIcon from 'assets/images/rock-hand.png'

import { Language } from 'root-constants'

export const DailyWater: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.dailyWater.title', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, setIsAnswersDisabled, handleContinue],
  )

  return (
    <Container>
      <PageTitle marginBottom={24}>
        {t('onboarding.dailyWater.title')}
      </PageTitle>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={DailyWaterValue.COFFEE_OR_TEE}
      >
        <AnswerButton iconSrc={coffeeIcon}>
          <h3>{t('onboarding.dailyWater.optionsV1.teaOrCoffee')}</h3>
        </AnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={DailyWaterValue.TWO_GLASSES}
      >
        <AnswerButton iconSrc={peaceIcon}>
          <h3>{t('onboarding.dailyWater.optionsV1.twoGlasses')}</h3>
        </AnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={DailyWaterValue.TWO_SIX_GLASSES}
      >
        <AnswerButton iconSrc={okIcon}>
          <h3>{t('onboarding.dailyWater.optionsV1.twoSixGlasses')}</h3>
        </AnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={DailyWaterValue.MORE_THAN_SIX_GLASSES}
      >
        <AnswerButton iconSrc={rockIcon}>
          <h3>{t('onboarding.dailyWater.optionsV1.moreThanSixGlasses')}</h3>
        </AnswerButton>
      </Option>
    </Container>
  )
}
