import { BmiValue, MotivationValue } from 'value-constants'

import femaleBanner from 'assets/images/event-feedback-female-banner.png'
import maleBanner from 'assets/images/event-feedback-male-banner.png'

import { Gender, MeasurementSystem } from 'root-constants'

export const EVENT_MAP = {
  [MotivationValue.VACATION]: 'onboarding.motivation.otherOptions.vacation',
  [MotivationValue.WEDDING]: 'onboarding.motivation.otherOptions.wedding',
  [MotivationValue.SPORTS]: 'onboarding.motivation.otherOptions.sports',
  [MotivationValue.SUMMER]: 'onboarding.motivation.otherOptions.summer',
  [MotivationValue.REUNION]: 'onboarding.motivation.otherOptions.reunion',
  [MotivationValue.FAMILY]: 'onboarding.motivation.otherOptions.family',
  [MotivationValue.HOLIDAY]: 'onboarding.motivation.otherOptions.holiday',
  [MotivationValue.OTHER]: 'onboarding.eventFeedback.event',
}

export const LOST_WEIGHT_PER_WEEK = {
  [BmiValue.UNDERWEIGHT]: {
    [MeasurementSystem.METRIC]: 1,
    [MeasurementSystem.IMPERIAL]: 2.2,
  },
  [BmiValue.NORMAL]: {
    [MeasurementSystem.METRIC]: 1,
    [MeasurementSystem.IMPERIAL]: 2.2,
  },
  [BmiValue.OVERWEIGHT]: {
    [MeasurementSystem.METRIC]: 1.5,
    [MeasurementSystem.IMPERIAL]: 3.3,
  },
  [BmiValue.OBESITY]: {
    [MeasurementSystem.METRIC]: 1.7,
    [MeasurementSystem.IMPERIAL]: 3.7,
  },
  [BmiValue.EXTREME_OBESITY]: {
    [MeasurementSystem.METRIC]: 2,
    [MeasurementSystem.IMPERIAL]: 4.4,
  },
}

export const EVENT_FEEDBACK_INFO_MAP = {
  [Gender.FEMALE]: {
    info: 'onboarding.eventFeedbackV2.infoFemale',
    imgSrc: femaleBanner,
  },
  [Gender.MALE]: {
    info: 'onboarding.eventFeedbackV2.infoMale',
    imgSrc: maleBanner,
  },
}
