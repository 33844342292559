import { createGlobalStyle } from 'styled-components'

export const GlobalStyleForPrivacyPolicy = createGlobalStyle`
    * {
        border: 0;
        box-sizing: border-box;
        margin: 0;
        outline: 0;
        padding: 0;
    }
    
    body,
    html {
        height: 100%;
    }
    
    body {
        color: #000000;
        font-family: 'Montserrat', sans-serif;
        line-height: 1.5;
        min-width: 320px;
        overflow-x: hidden;
    }
    
    .container {
        margin: 0 auto;
    }
    
    .header {
        background-color: #6fd09a;
        font-family: 'Raleway', sans-serif;
    }
    
    .header__container {
        align-items: center;
        column-gap: 20px;
        display: flex;
        max-width: 980px;
        padding: 10px 0;
        width: calc(100% - 10px - 10px);
    }
    
    .logo {
        border-radius: 50%;
        display: flex;
        overflow: hidden;
        width: 48px;
    }

    .logo__img {
        max-width: 100%;
    }
    
    .header-title {
        color: #ffffff;
        font-size: 18px;
    }

    .article {
        width: calc(100% - 10px - 10px);
        max-width: 800px;
        padding: 35px 0 20px;
    }

    .article__title {
        font-family: 'Raleway', sans-serif;
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 30px;
    }
    .article__date {
        color: #808080;
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 30px;
    }

    .article__title-v2,
    .article__date-v2 {
        font-size: 24px;
        font-weight: 700;
    }
    
    .article__date-v2 {
        margin-bottom: 30px;
    }

    .article section:not(:last-of-type) {
        margin-bottom: 30px;
    }
    .article section {
        font-weight: 300;
    }

    .article h2 {
        font-size: 24px;
        margin-bottom: 30px;
    }

    .article h3 {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 20px;
    }

    .article p,
    .article li {
        font-size: 18px;
    }

    .article a {
        color: inherit;
        font: inherit;
        text-decoration: underline;
    }

    .article ol,
    .article ul {
        list-style: disc;
        padding-left: 32px;
    }

    .article .mb-10 {
        margin-bottom: 10px;
    }

    .article .mb-30 {
        margin-bottom: 30px;
    }

    .footer {
        background-color: #6fd09a;
        padding: 10px 0;
        text-align: center;
    }

    .footer__content {
        color: #ffffff;
        font-family: 'Raleway', sans-serif;
        font-size: 14px;
        font-weight: 400;
    }
`
