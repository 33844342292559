import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import firebase from 'firebase/app'

import { selectUUID } from 'root-redux/selects/user'

export const useGetRedirectResult = (
  callback: (token: string) => void,
  callbackIsDataLoading: (isLoading: boolean) => void,
): void => {
  const uuid = useSelector(selectUUID)

  useEffect(() => {
    callbackIsDataLoading(true)
    firebase
      .auth()
      .getRedirectResult()
      .then((result) => {
        if (result.user) {
          return result.user.getIdToken()
        }
        return null
      })
      .then((token) => {
        token && callback(token)
      })
      .finally(() => callbackIsDataLoading(false))
  }, [callback, callbackIsDataLoading, uuid])
}
