import React, { useLayoutEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { PrimaryGoalValue } from 'value-constants'

import { AnswerButtonV2 } from 'components/AnswerButtonV2'
import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { selectCurrentUserPrimaryGoal } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { replaceHistory } from 'browser-history'

import { StyledWhatElse as S } from './WhatElse.styles'
import { ANSWER_OPTIONS, QUESTION } from './constants'

export const WhatElse: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const primaryGoal = useSelector(selectCurrentUserPrimaryGoal)

  const [answers, setAnswers] = useState<string[]>([])

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked) {
          setAnswers([...answers, value])
        } else {
          setAnswers(answers.filter((item) => item !== value))
        }
      },
    }),
    [pageId, answers],
  )

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  useLayoutEffect(() => {
    primaryGoal !== PrimaryGoalValue.LOSE_WEIGHT && replaceHistory(nextPagePath)
  }, [nextPagePath, primaryGoal])

  return (
    <Container>
      <PageTitle marginBottom={24}>{t('onboarding.whatElse.title')}</PageTitle>
      {ANSWER_OPTIONS.map(({ value, title }) => (
        <Option
          key={value}
          {...optionProps}
          marginBottom="12"
          value={value}
          checked={answers.includes(value)}
        >
          <AnswerButtonV2 withCheckbox>
            <h3>{t(title)}</h3>
          </AnswerButtonV2>
        </Option>
      ))}
      <S.ButtonContainer>
        <Button
          type="button"
          disabled={!answers.length}
          onClick={() => handleContinue(answers)}
        >
          {t('actions.continue')}
        </Button>
      </S.ButtonContainer>
    </Container>
  )
}
