import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { selectGoogleAnalyticsId } from 'root-redux/selects/common'

export const useGoogleAnalytics = (): void => {
  const googleAnalyticsId = useSelector(selectGoogleAnalyticsId)

  useEffect(() => {
    if (googleAnalyticsId) {
      const setUpGTM = () => {
        const script = document.createElement('script')
        script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`
        script.async = true
        document.head.appendChild(script)
      }

      setUpGTM()
      window.gtag('config', googleAnalyticsId, {
        send_page_view: false,
      })
    }
  }, [googleAnalyticsId])
}
