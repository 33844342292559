import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { StyledHeader as S } from 'components/Header/Header.styles'
import { Logo } from 'components/Logo'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { SupportActionLink } from 'components/SupportActionLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { eventLogger } from 'services/eventLogger.service'

export const HeaderWithBurger: React.FC = () => {
  const { t } = useTranslation()
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false)

  const toggleMenuVisibility = useCallback(() => {
    setIsMenuVisible(!isMenuVisible)
  }, [isMenuVisible])

  return (
    <>
      <S.Wrapper>
        <Logo marginBottom={0} />
        <S.HeaderActions>
          <S.Burger type="button" onClick={toggleMenuVisibility}>
            <S.BurgerLine />
            <S.BurgerLine />
            <S.BurgerLine />
          </S.Burger>
        </S.HeaderActions>
      </S.Wrapper>
      <S.Backdrop
        isMenuVisible={isMenuVisible}
        onClick={toggleMenuVisibility}
      />
      <S.Menu isMenuVisible={isMenuVisible}>
        <S.MenuCloseButton type="button" onClick={toggleMenuVisibility} />
        <S.StyledMenuLinkContainer>
          <TermsOfUseLink />
        </S.StyledMenuLinkContainer>
        <S.StyledMenuLinkContainer>
          <PrivacyPolicyLink />
        </S.StyledMenuLinkContainer>
        <S.StyledMenuLinkContainer>
          <SupportActionLink onClick={() => eventLogger.logNeedHelpClicked()}>
            {t('commonComponents.needHelp')}
          </SupportActionLink>
        </S.StyledMenuLinkContainer>
      </S.Menu>
    </>
  )
}
