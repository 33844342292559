import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

const commonContainerStyles = css`
  display: flex;
  justify-content: space-between;
  column-gap: 16px;
`

export const StyledCheckoutSummaryV3 = {
  DescriptionContainer: styled.div`
    ${commonContainerStyles};
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: ${Color.DISABLED};

    &:not(:last-of-type) {
      margin: 0 0 4px 0;
    }

    &:last-of-type {
      margin: 0 0 12px 0;
    }
  `,
  PlanLabel: styled.span`
    text-align: left;
  `,
  PlanPrice: styled.span`
    text-align: right;
  `,
  TotalContainer: styled.div`
    ${commonContainerStyles};
    font-size: 16px;
    line-height: 24px;
    padding: 12px 0 0 0;
    border-top: 1px solid ${Color.GREY_WHITE};
  `,
  TotalLabel: styled.span`
    text-align: left;
    font-weight: 700;
  `,
  TotalPrice: styled.span`
    text-align: right;
    color: ${Color.DISABLED};

    span {
      color: ${Color.PRIMARY};
      font-weight: 700;
      padding: 0 6px 0 0;
    }
  `,
}
