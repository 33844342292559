import { ReactNode } from 'react'

import styled from 'styled-components'

import { Color } from 'root-constants'

export const Title = styled.h2`
  font-weight: 800;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: 0.364px;
  text-align: center;
  color: ${Color.GREY_100};
  margin-bottom: 16px;
`
export const Info = styled.div`
  position: relative;
  padding: 24px 16px 34px;
  box-shadow: 0 12px 32px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
`
export const CircleProgress = styled.div`
  width: 80px;
  height: 80px;
  margin: 0 auto;
`

export const PercentageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
`
export const Progress = styled.div<{ children: ReactNode }>`
  display: flex;
  align-items: flex-end;
  font-weight: 900;
  font-size: 72px;
  line-height: 86px;
  color: ${Color.PRIMARY};
`
export const PercentageValue = styled.span`
  margin-right: 2px;
`
export const PercentageSymbol = styled.span`
  font-weight: 900;
  font-size: 32px;
  line-height: 38px;
  margin-bottom: 10px;
`
export const Disclaimer = styled.div`
  flex-basis: 100%;
  background: ${Color.WHITE};
  border-radius: 12px;
  padding: 16px;
  position: relative;
`
export const DisclaimerTitle = styled.h3`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: ${Color.GREY_100};
  margin-bottom: 8px;
`
export const DisclaimerText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${Color.DISABLED};
`
export const DisclaimerClose = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 24px;
  height: 24px;
  left: 0;
  top: -40px;
  z-index: 4;
  border: 0;
  padding: 0;
  background-color: transparent;
`
export const Footnote = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${Color.DISABLED};
  text-align: center;

  & strong {
    font-weight: 800;
    color: ${Color.PRIMARY};
  }
`
export const InfoButton = styled.button`
  width: 24px;
  height: 24px;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 2;
`

export const StyledPercentageProgress = {
  Root: styled.div`
    margin-bottom: 40px;
  `,
  Title: styled.h4`
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: ${Color.DARK_BLUE};
    margin: 0 0 16px 0;
  `,
  Modal: styled.div`
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(19, 29, 48, 0.64);
    backdrop-filter: blur(16px);
    z-index: 3;
    padding: 16px;
  `,
}
