import React, { FC, ReactNode, useMemo } from 'react'

import { useGetPageInfo } from 'hooks/useGetPageInfo'

import { StyledPageContainer as S } from './PageContainer.styles'

type TProps = {
  children: ReactNode
  className?: string
  width?: number
}

export const PageContainer: FC<TProps> = ({ children, className, width }) => {
  const { hasHeader, hasProgressbar, hasBurgerMenu } = useGetPageInfo()

  const paddingTop = useMemo(() => {
    if (hasHeader && hasProgressbar) {
      return 0
    }

    if (hasHeader && hasBurgerMenu) {
      return 16
    }

    if (hasHeader) {
      return 24
    }

    return 8
  }, [hasHeader, hasProgressbar, hasBurgerMenu])

  return (
    <S.Container className={className} paddingTop={paddingTop} width={width}>
      {children}
    </S.Container>
  )
}
