import React from 'react'

import { usePaymentStore } from 'hooks/usePaymentStore'

import { StyledThreeDSecureIframe as S } from './ThreeDSecureIframe.styles'

export const ThreeDSecureIframe = () => {
  const { threeDSecureIframeUrl } = usePaymentStore()

  return <S.ThreeDSecureIframe title="3DSecure" src={threeDSecureIframeUrl} />
}
