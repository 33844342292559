import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { OptionType } from 'components/Option'
import { KitPageContainer } from 'components/PageContainer'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'
import { useTitleFromUrl } from 'hooks/useTitleFromUrl'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { CustomPageId } from 'page-constants'
import { Language } from 'root-constants'

import { StyledAgeRangeV5 as S } from './AgeRangeV5.styles'
import { RANGES } from './constants'

export const AgeRangeV5: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()

  const { title, subtitle } = useDynamicOBConfig()
  const { title: titleFromUrl, subText: subtitleFromUrl } = useTitleFromUrl()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const question = useMemo(
    () =>
      titleFromUrl ||
      title ||
      t('onboarding.ageRangeV5.title', { lng: Language.EN }),
    [titleFromUrl, title, t],
  )

  const handleContinue = useNextStep({
    pageId: CustomPageId.AGE_RANGE,
    question,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: CustomPageId.AGE_RANGE,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
        eventLogger.logFirstPageCompleted({
          question,
          answers: value,
        })
      },
    }),
    [handleChange, handleContinue, setIsAnswersDisabled, question],
  )

  return (
    <KitPageContainer paddingTop={12}>
      <S.Title>
        {titleFromUrl || title || t('onboarding.ageRangeV5.title')}
      </S.Title>
      <S.Subtitle>
        {subtitleFromUrl || subtitle || t('onboarding.ageRangeV5.subtitle')}
      </S.Subtitle>

      <S.OptionsContainer>
        {RANGES.map(({ value, text, img }) => (
          <S.Option
            {...optionProps}
            key={value}
            disabled={isAnswersDisabled}
            value={value}
          >
            <S.RangeImage>
              <img src={img} alt="age-range" />
            </S.RangeImage>
            <S.Range>
              {t('onboarding.ageRangeV5.ageRange', {
                ageRange: text,
              })}
              <S.ArrowIcon />
            </S.Range>
          </S.Option>
        ))}
      </S.OptionsContainer>
      <S.Footer />
    </KitPageContainer>
  )
}
