import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledSecurityInfo = {
  Wrapper: styled.div<{ topIndent?: number }>`
    position: relative;
    margin-bottom: 40px;
    padding: 40px 24px 24px;
    box-shadow: 0 8px 20px rgba(17, 30, 23, 0.1);
    border-radius: 24px;
    transform: ${({ topIndent = 0 }) => `translateY(${topIndent}px)`};
  `,
  Title: styled.h2`
    padding-bottom: 8px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  `,
  Text: styled.p`
    padding-bottom: 16px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${Color.DISABLED};

    &:last-of-type {
      padding-bottom: 0;
    }

    a {
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      color: ${Color.PRIMARY};
    }
  `,
  SecureBadge: styled.img`
    position: absolute;
    top: 18px;
    right: 0;
    max-width: 104px;
  `,
}
