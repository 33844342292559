import React, { useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import dayjs from 'dayjs'
import { DAYS_NUMBER } from 'pages/questions/WeightLossDate/constants'

import { LoseWeightGraphV4 } from 'components/LoseWeightGraphV4'
import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'

import {
  selectCurrentUserGoalWeight,
  selectLanguage,
  selectUserMeasurementSystem,
} from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'
import { usePredictableData } from 'hooks/usePredictableData'

import {
  DAY_FORMAT,
  FULL_YEAR_FORMAT,
  SHORT_MONTH_FORMAT,
  getDateSequence,
} from 'helpers/dateHelper'

import { TPageProps } from 'models/common.model'

import { Language, WEIGHT_UNITS } from 'root-constants'

import { StyledWeightLossDateV2 as S } from './WeightLossDateV2.styles'

export const WeightLossDateV2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const goalWeight = useSelector(selectCurrentUserGoalWeight)
  const measurementSystem = useSelector(selectUserMeasurementSystem)
  const language = useSelector(selectLanguage)

  const { realPredictableDate, hasWeekEarlyPredictableDate } =
    usePredictableData()

  const targetWeight = useMemo(
    () => `${goalWeight} ${WEIGHT_UNITS[measurementSystem]}`,
    [goalWeight, measurementSystem],
  )

  const [dates, setDates] = useState<number[]>([
    Number(realPredictableDate.format(DAY_FORMAT)),
  ])
  const [months, setMonths] = useState<string[]>([
    realPredictableDate.locale(language).format(SHORT_MONTH_FORMAT),
  ])
  const [years, setYears] = useState<number[]>([
    Number(realPredictableDate.format(FULL_YEAR_FORMAT)),
  ])

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.weightLossDateV2.title', {
      lng: Language.EN,
    }),
    nextPagePath,
  })

  const dateData = useMemo(() => {
    const dateSequence = getDateSequence({
      mainDate: realPredictableDate,
      sequenceLength: DAYS_NUMBER,
      beforeMainDate: true,
    })

    return dateSequence.reduce(
      (
        acc: { years: number[]; months: string[]; days: number[] },
        curr: dayjs.Dayjs,
      ) => {
        const curMonth: string = curr
          .locale(language)
          .format(SHORT_MONTH_FORMAT)
        const curDay: number = curr.date()
        const curYear: number = curr.year()

        if (!acc.years.includes(curYear)) {
          acc.years.push(curYear)
        }
        if (!acc.months.includes(curMonth)) {
          acc.months.push(curMonth)
        }
        if (!acc.days.includes(curDay)) {
          acc.days.push(curDay)
        }

        return acc
      },
      { years: [], months: [], days: [] },
    )
  }, [language, realPredictableDate])

  useEffect(() => {
    if (hasWeekEarlyPredictableDate) {
      setYears(dateData.years)
      setMonths(dateData.months)
      setDates(dateData.days)
    }
  }, [hasWeekEarlyPredictableDate])

  return (
    <KitPageContainer
      hasContinueButton
      onContinueButtonClick={() => handleContinue('')}
    >
      <PageTitle marginBottom={12}>
        {t('onboarding.weightLossDateV2.title')}
      </PageTitle>
      <S.Subtitle>{t('onboarding.weightLossDateV2.subtitle')}</S.Subtitle>
      <S.PredictionBlock>
        <S.Text>
          <Trans
            i18nKey="onboarding.weightLossDateV2.days"
            values={{ targetWeight }}
            components={{ span: <span /> }}
          />
        </S.Text>
        <S.Date>
          <S.MonthsContainer hasMultipleMonths={months.length > 1}>
            {months.map((month) => (
              <S.MonthsValue key={month}>{month}</S.MonthsValue>
            ))}
          </S.MonthsContainer>
          <S.DayContainer hasMultipleDays={hasWeekEarlyPredictableDate}>
            {dates.map((date) => (
              <S.DayValue key={date}>{date},</S.DayValue>
            ))}
          </S.DayContainer>
          <S.YearContainer hasMultipleYears={years.length > 1}>
            {years.map((year) => (
              <S.YearValue key={year}>{year}</S.YearValue>
            ))}
          </S.YearContainer>
        </S.Date>
      </S.PredictionBlock>
      <LoseWeightGraphV4 />
    </KitPageContainer>
  )
}
