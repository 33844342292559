import { useEffect, useMemo } from 'react'
import { registerLocale } from 'react-datepicker'
import { useDispatch, useSelector } from 'react-redux'

import 'dayjs/locale/es'

import { setLanguageAction } from 'root-redux/actions/common'
import { selectIsInContextShown } from 'root-redux/selects/common'

import { getCurrentEnvironment } from 'helpers/getCurrentEnvironment'

import {
  DATEPICKER_SUPPORTED_LOCALES,
  INITIAL_LANGUAGE,
  LANG_QUERY_PARAM,
  Language,
  SUPPORTED_LOCALES,
} from 'root-constants'

export const useLanguage = (): void => {
  const { search } = document.location
  const dispatch = useDispatch()
  const isInContextShown = useSelector(selectIsInContextShown)

  const { isDevEnvironment } = getCurrentEnvironment()

  const isInContextActive = useMemo(
    () => isDevEnvironment && isInContextShown,
    [isDevEnvironment, isInContextShown],
  )

  useEffect(() => {
    const URLParams = new URLSearchParams(search)
    const langQueryParam = URLParams.get(LANG_QUERY_PARAM) || ''

    if (!SUPPORTED_LOCALES.includes(langQueryParam)) {
      dispatch(
        setLanguageAction(
          isInContextActive ? Language.IN_CONTEXT : INITIAL_LANGUAGE,
        ),
      )
      registerLocale(
        `${INITIAL_LANGUAGE}`,
        DATEPICKER_SUPPORTED_LOCALES[INITIAL_LANGUAGE],
      )
    } else {
      dispatch(
        setLanguageAction(
          isInContextActive ? Language.IN_CONTEXT : langQueryParam,
        ),
      )
      registerLocale(
        `${langQueryParam}`,
        DATEPICKER_SUPPORTED_LOCALES[langQueryParam as Language],
      )
    }
  }, [dispatch, search, isInContextActive])
}
