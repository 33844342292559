import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import {
  selectCurrentUserGender,
  selectCurrentVariantCohortToUse,
  selectUserMeasurementSystem,
} from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import menAfter from 'assets/images/men-after.png'
import maleBefore from 'assets/images/men-before.png'
import ratingImage from 'assets/images/stars.png'
import femaleAfter from 'assets/images/women-after.png'
import femaleBefore from 'assets/images/women-before.png'

import { Cohort, Gender, Language, MeasurementSystem } from 'root-constants'

import { StyledStickingPlan as S } from './StickingPlanV1.styles'
import { LOST_WEIGHT_MAP_V1 } from './constants'

export const StickingPlanV1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const gender = useSelector(selectCurrentUserGender)
  const currentMeasurementSystem = useSelector(selectUserMeasurementSystem)
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const { beforeImgSrc, afterImageSrc, name, review } = useMemo(() => {
    const reviewVersion =
      gender === Gender.FEMALE && cohortToUse === Cohort.MF_WOMEN_AGE_2
        ? 'reviewV2'
        : 'review'

    return {
      beforeImgSrc: gender === Gender.FEMALE ? femaleBefore : maleBefore,
      afterImageSrc: gender === Gender.FEMALE ? femaleAfter : menAfter,
      name: t(`onboarding.stickingPlanV1.reviews.${gender}.name`, {
        lostWeight: `${LOST_WEIGHT_MAP_V1[gender][currentMeasurementSystem]} ${
          currentMeasurementSystem === MeasurementSystem.METRIC
            ? t('commonComponents.kg')
            : t('commonComponents.lbs')
        }`,
      }),
      review: t(
        `onboarding.stickingPlanV1.reviews.${gender}.${reviewVersion}`,
        {
          lostWeight: `${
            LOST_WEIGHT_MAP_V1[gender][currentMeasurementSystem]
          } ${
            currentMeasurementSystem === MeasurementSystem.METRIC
              ? t('commonComponents.kilograms')
              : t('commonComponents.pounds')
          }`,
        },
      ),
    }
  }, [cohortToUse, currentMeasurementSystem, gender, t])

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.stickingPlanV1.title', { lng: Language.EN }),
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <Container>
        <S.Title>{t('onboarding.stickingPlanV1.title')}</S.Title>
        <S.ImageWrapper>
          <S.ImageContainerBefore>
            <picture>
              <S.ImageBefore src={beforeImgSrc} alt="before" />
            </picture>
            <S.LabelBefore>
              {t('onboarding.stickingPlanV1.before')}
            </S.LabelBefore>
          </S.ImageContainerBefore>
          <S.ImageContainerAfter>
            <picture>
              <S.ImageAfter src={afterImageSrc} alt="after" />
            </picture>
            <S.LabelAfter>{t('onboarding.stickingPlanV1.after')}</S.LabelAfter>
          </S.ImageContainerAfter>
        </S.ImageWrapper>
        <S.Card>
          <S.CardReview>{review}</S.CardReview>
          <S.ReviewWrapper>
            <S.CardReviewer>{name}</S.CardReviewer>
            <S.CardRating src={ratingImage} alt="stars" />
          </S.ReviewWrapper>
        </S.Card>

        <StickyButtonContainer>
          <Button type="button" onClick={() => handleContinue('')}>
            {t('actions.continue')}
          </Button>
        </StickyButtonContainer>
      </Container>
    </S.Wrapper>
  )
}
