import curvyFemale from 'assets/images/body-types-female-curvy.png'
import fitFemale from 'assets/images/body-types-female-fit.png'
import leanFemale from 'assets/images/body-types-female-lean.png'
import midSizeFemale from 'assets/images/body-types-female-mid-size.png'
import plusSizeFemale from 'assets/images/body-types-female-plus-size.png'
import athleticMale from 'assets/images/body-types-male-fit.png'
import leanMale from 'assets/images/body-types-male-lean.png'
import midSizeMale from 'assets/images/body-types-male-mid-size.png'
import plusSizeMale from 'assets/images/body-types-male-plus-size.png'
import tonedMale from 'assets/images/body-types-male-toned.png'

import { Gender } from 'root-constants'

export const QUESTION = 'What kind of body would you like to have?'

export const DREAM_BODY_DATA = {
  [Gender.MALE]: [
    {
      value: 'lean',
      text: 'onboarding.dreamBody.options.lean',
      img: leanMale,
    },
    {
      value: 'toned',
      text: 'onboarding.dreamBody.options.toned',
      img: tonedMale,
    },
    {
      value: 'athletic',
      text: 'onboarding.dreamBody.options.athletic',
      img: athleticMale,
    },
    {
      value: 'midSize',
      text: 'onboarding.dreamBody.options.midSize',
      img: midSizeMale,
    },
    {
      value: 'fewSizesSmaller',
      text: 'onboarding.dreamBody.options.fewSizesSmaller',
      img: plusSizeMale,
    },
  ],
  [Gender.FEMALE]: [
    {
      value: 'lean',
      text: 'onboarding.dreamBody.options.lean',
      img: leanFemale,
    },
    {
      value: 'fit',
      text: 'onboarding.dreamBody.options.fit',
      img: fitFemale,
    },
    {
      value: 'curvy',
      text: 'onboarding.dreamBody.options.curvy',
      img: curvyFemale,
    },
    {
      value: 'midSize',
      text: 'onboarding.dreamBody.options.midSize',
      img: midSizeFemale,
    },
    {
      value: 'fewSizesSmaller',
      text: 'onboarding.dreamBody.options.fewSizesSmaller',
      img: plusSizeFemale,
    },
  ],
}
