import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledBannerWithTimer = {
  Container: styled.div`
    width: 100%;
    padding: 14px;
    background: radial-gradient(
      146.02% 800.66% at 7.36% 190%,
      #ff0031 0%,
      #ffbe1b 100%
    );
  `,
  Text: styled.p`
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: ${Color.WHITE};
    text-align: center;

    strong {
      font-weight: 700;
    }
  `,
}
