import styled from 'styled-components'

import { Color } from 'root-constants'

type TProps = {
  color?: string
  backgroundColor?: string
  width?: number
}

export const StyledBodyCalculation = {
  Disclaimer: styled.div<TProps>`
    display: flex;
    background-color: ${({ backgroundColor }) => backgroundColor};
    flex-direction: column;
    justify-content: flex-start;
    padding: 8px 8px 8px 12px;
    gap: 2px;
    border-radius: 12px;
    margin-top: 24px;
    margin-bottom: 24px;
  `,
  ButtonContainer: styled.div`
    padding-top: 8px;
  `,
  SubTitle: styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: ${Color.GREY_100};
    margin-top: 4px;
  `,
  Title: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `,
  Head: styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0;
    gap: 6px;
  `,
  Name: styled.span`
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: ${Color.GREY_100};
    align-items: center;
  `,
  Value: styled.span<TProps>`
    color: ${({ color }) => color};
    font-weight: 800;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
  `,
  Label: styled.div<TProps>`
    display: flex;
    flex-direction: row;
    background-color: ${({ backgroundColor }) => backgroundColor};
    min-width: ${({ width }) => `${width}px`};
    align-items: center;
    justify-content: center;
    padding: 2px 6px;
    gap: 10px;
    height: 20px;
    border-radius: 6px;
  `,
  Text: styled.p`
    font-weight: 700;
    font-size: 9px;
    line-height: 16px;
    color: ${Color.WHITE};
    text-transform: uppercase;
  `,
}
