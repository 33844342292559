import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import 'firebase/auth'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'
import { selectUserCountryCode } from 'root-redux/selects/user'

import { VAT_INCLUDED_COHORTS, VAT_INCLUDED_COUNTRIES } from 'root-constants'

export const useVatInfo = () => {
  const countryCode = useSelector(selectUserCountryCode)
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  return useMemo(
    () =>
      VAT_INCLUDED_COHORTS.includes(cohortToUse) &&
      VAT_INCLUDED_COUNTRIES.includes(countryCode),
    [cohortToUse, countryCode],
  )
}
