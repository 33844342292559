import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Logo } from 'components/Logo'

import { selectHeaderSkipButton } from 'root-redux/selects/common'

import { useGetPageInfo } from 'hooks/useGetPageInfo'

import arrowRight from 'assets/images/arrow-right-icon.png'
import arrowSvg from 'assets/images/sprite/back-arrow.svg'

import { goBack } from 'browser-history'
import { Color } from 'root-constants'

import { SvgImage } from '../SvgImage'
import { StyledHeader as S } from './Header.styles'

export const Header: React.FC = () => {
  const { t } = useTranslation()

  const { hasSkipButton, onSkip } = useSelector(selectHeaderSkipButton)
  const { hasProgressbar } = useGetPageInfo()

  return (
    <S.Wrapper>
      {hasProgressbar && (
        <S.BackButton type="button" onClick={goBack}>
          <SvgImage
            fill={Color.BLACK_100}
            svg={arrowSvg}
            width={24}
            height={24}
          />
        </S.BackButton>
      )}
      <Logo marginBottom={0} />
      {hasSkipButton && (
        <S.SkipButton type="button" onClick={onSkip}>
          {t('actions.skip')}
          <img src={arrowRight} alt="back" />
        </S.SkipButton>
      )}
    </S.Wrapper>
  )
}
