import styled from 'styled-components'

import muted from 'assets/images/muted.svg'
import unmuted from 'assets/images/unmuted.svg'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledVideoReviews = {
  Container: styled.div`
    width: 100%;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: ${MediaBreakpoint.MEDIUM_PHONE}px;
    }
  `,
  Title: styled.h2`
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin: 0 0 16px 0;
    padding: 0 16px 0 16px;
  `,
  VideosContainer: styled.div`
    display: flex;
    padding: 0 16px;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      margin: 0 16px;
      height: 4px;
    }

    &::-webkit-scrollbar-thumb {
      -webkit-appearance: none;
      height: 4px;
      border-radius: 2px;
      background-color: ${Color.GREY_WHITE};
    }

    &::-webkit-scrollbar-track {
      -webkit-appearance: none;
      margin: 0 16px;
    }
  `,
  Card: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 16px 0 0;
  `,
  VideoWrapper: styled.div<{ posterUrl: string }>`
    background-image: url(${({ posterUrl }) => posterUrl});
    background-repeat: no-repeat;
    background-size: contain;
    height: 260px;
    width: 260px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 0 8px 0;
    position: relative;

    video {
      object-fit: cover;
    }
  `,
  VideoButton: styled.button<{ isMuted: boolean }>`
    position: absolute;
    z-index: 11;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
    height: 48px;
    width: 48px;
    background: #00000029;
    border: none;
    border-radius: 8px;

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: url(${({ isMuted }) => (isMuted ? muted : unmuted)});
      height: 20px;
      width: 20px;
    }
  `,
  UserName: styled.p`
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: ${Color.GREY_100};
    margin: 0 0 16px 0;
  `,
}
