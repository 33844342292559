import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { StyledUsersChoice as S } from 'pages/questions/UsersChoice/UsersChoice.styles'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { Logo } from 'components/Logo'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import people from 'assets/images/people.png'

import { QUESTION } from './constants'

export const UsersChoice: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  return (
    <S.Wrapper>
      <Container>
        <Logo marginBottom={28} />
        <S.ImageContainer>
          <img src={people} alt="people" />
        </S.ImageContainer>
        <S.Title>1,100,000+</S.Title>
        <S.Subtitle>{t('onboarding.usersChoice.subtitle')}</S.Subtitle>
        <StickyButtonContainer>
          <Button type="button" onClick={handleNextClick}>
            {t('actions.continue')}
          </Button>
        </StickyButtonContainer>
      </Container>
    </S.Wrapper>
  )
}
