import styled from 'styled-components'

import { Option } from 'components/Option'

import { Color } from 'root-constants'

export const StyledAgeRangeV2 = {
  Title: styled.h2`
    margin: 24px 0 10px;
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0.36px;
    text-transform: uppercase;
  `,
  Subtitle: styled.h3`
    margin-bottom: 6px;
    color: ${Color.DISABLED};
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  `,
  Disclaimer: styled.p`
    margin-bottom: 12px;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  `,
  OptionsContainer: styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 180px);
    grid-column-gap: 16px;
    margin: 0 0 16px 0;

    input:checked + div > div {
      background-color: var(--checked-bg-color);
    }
  `,
  Option: styled(Option)`
    margin: 0;
    align-self: center;

    :nth-of-type(odd) {
      justify-self: right;
    }

    :nth-of-type(even) {
      justify-self: left;
    }
  `,
  Action: styled.div`
    display: flex;
    aspect-ratio: 148/152;
    width: 148px;
    height: 152px;
    position: relative;
  `,
  RangeImage: styled.img`
    width: 100%;
    height: 126px;
    position: absolute;
    top: -10px;
    z-index: 1;
  `,
  Answer: styled.div`
    --checked-bg-color: ${Color.PRIMARY};
    background-color: #e8f7ef;
    border-radius: 12px;
    position: relative;
    flex-basis: 100%;
    transition: background-color 0.1s ease-out;
  `,
  Range: styled.span`
    --checked-bg-color: ${Color.PRIMARY};
    background-color: ${Color.PRIMARY};
    position: absolute;
    bottom: 0;
    z-index: 2;
    width: 100%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 12px 12px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: ${Color.WHITE};

    svg {
      margin-left: 8px;
    }
  `,
}
