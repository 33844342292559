export const QUESTION = 'How do you usually prepare your meals?'

const enum MEALS_PREPARATION_VALUES {
  I_COOK = 'i_cook',
  SOMEONE_COOKS = 'someone_cooks',
  ORDER = 'order',
  PREPACKAGED_MEALS = 'prepackaged_meals',
}

export const MEALS_PREPARATION_VALUES_MAP = [
  {
    value: MEALS_PREPARATION_VALUES.I_COOK,
    title: 'onboarding.mealsPreparation.options.iCook',
  },
  {
    value: MEALS_PREPARATION_VALUES.SOMEONE_COOKS,
    title: 'onboarding.mealsPreparation.options.someoneCooks',
  },
  {
    value: MEALS_PREPARATION_VALUES.ORDER,
    title: 'onboarding.mealsPreparation.options.order',
  },
  {
    value: MEALS_PREPARATION_VALUES.PREPACKAGED_MEALS,
    title: 'onboarding.mealsPreparation.options.prepackagedMeals',
  },
]
