import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import { getCurrentEnvironment } from './getCurrentEnvironment'

export const initSentry = (): void => {
  const { isProdEnvironment } = getCurrentEnvironment()

  if (isProdEnvironment) {
    Sentry.init({
      dsn: 'https://e114fddc3bbe48059242f6f6e8ddee63@o1132404.ingest.sentry.io/6705554',
      integrations: [new Integrations.BrowserTracing()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.0,
      ignoreErrors: [
        'top.GLOBALS',
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        'fb_xd_fragment',
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        'conduitPage',
        'Script error.',
        '_avast_submit',
        'withrealtime/messaging',
        /gtag/,
        /illegal/,
      ],
    })
  }
}
