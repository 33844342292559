import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { MENOPAUSE_INFO_LIST } from 'pages/questions/GainWeight/constants'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import chart from 'assets/images/gain-weight-menopause-chart.png'

import { Language } from 'root-constants'

import { StyledGainWeight as S } from './GainWeight.styles'

export const GainWeightMenopause: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.gainWeightMenopause.title', { lng: Language.EN }),
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  return (
    <Container>
      <S.PageTitleV2>{t('onboarding.gainWeightMenopause.title')}</S.PageTitleV2>
      <S.ImageContainerV2>
        <img src={chart} alt="chart" />
      </S.ImageContainerV2>
      <S.ListV2>
        {MENOPAUSE_INFO_LIST.map((item) => (
          <S.ListItem key={item}>
            <Trans i18nKey={item} />
          </S.ListItem>
        ))}
      </S.ListV2>
      <StickyButtonContainer>
        <Button type="button" onClick={handleNextClick}>
          {t('actions.continue')}
        </Button>
      </StickyButtonContainer>
    </Container>
  )
}
