import React, { SyntheticEvent, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from 'storybook-ui'

import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import fingerUpIcon from 'assets/images/finger-up.png'

import { CustomPageId } from 'page-constants'

import { StyledAgeV4 as S } from './AgeV4.styles'
import { IntegerNumberRegex, MAX_AGE, MIN_AGE, QUESTION } from './constants'

export const AgeV4: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()

  const [age, setAge] = useState({
    value: '',
    isValid: false,
    rangeOverflow: false,
  })
  const [isFocused, setIsFocused] = useState(false)

  const handleChange = useCallback(({ target: { value, validity } }) => {
    if (!value || IntegerNumberRegex.test(value)) {
      setAge({
        value,
        isValid: validity.valid,
        rangeOverflow: validity.rangeOverflow,
      })
    }
  }, [])

  const handleContinue = useNextStep({
    pageId: CustomPageId.AGE,
    question: QUESTION,
    nextPagePath,
  })

  const handleSubmit = useCallback(
    (e: SyntheticEvent<HTMLFormElement>) => {
      e.preventDefault()
      handleContinue(+age.value)
    },
    [age.value, handleContinue],
  )

  const handleFocus = useCallback(() => {
    setIsFocused(true)
  }, [])

  const handleBlur = useCallback(() => {
    setIsFocused(false)
  }, [])

  return (
    <form onSubmit={handleSubmit}>
      <KitPageContainer>
        <PageTitle marginBottom={24} textAlign="left">
          {t('onboarding.age.title')}
        </PageTitle>
        <S.InputWrapper>
          <S.AgeInput
            type="number"
            min={MIN_AGE}
            max={MAX_AGE}
            pattern="\d*"
            value={age.value}
            onChange={handleChange}
            isContentCentered
            isValid={age.isValid}
            allowFloatNumbers={false}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
          {!isFocused && !age.value && <S.Placeholder />}
          {age.value && !age.isValid && (
            <S.ErrorMessage>
              {age.rangeOverflow
                ? t('onboarding.age.errorMessage')
                : t('onboarding.age.tooYoung')}
            </S.ErrorMessage>
          )}
        </S.InputWrapper>

        <S.InfoContainer>
          <S.InfoImage>
            <img src={fingerUpIcon} alt="info" />
          </S.InfoImage>
          <S.Info>
            <S.InfoTitle>{t('onboarding.age.info.title2')}</S.InfoTitle>
            <S.InfoText>{t('onboarding.age.info.text')}</S.InfoText>
          </S.Info>
        </S.InfoContainer>

        <Button
          width="100%"
          type="submit"
          margin="0 auto"
          theme="nutrimatePrimary"
          disabled={!age.isValid}
        >
          {t('actions.continue')}
        </Button>
      </KitPageContainer>
    </form>
  )
}
