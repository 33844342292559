import styled from 'styled-components'

import attentionIcon1 from 'assets/images/attention-icon.svg'

import { Color } from 'root-constants'

type TWrapperProps = {
  errorText: string
}

export const StyledErrorNotification = {
  Wrapper: styled.div<TWrapperProps>`
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    padding: 10px 16px 10px 48px;
    background-color: ${Color.RED_400};
    border-radius: 10px;
    opacity: ${({ errorText }) => (errorText ? 1 : 0)};
    transform: ${({ errorText }) =>
      errorText ? 'translate(-50%, -100%)' : 'translateX(-50%)'};
    transition: all 0.5s ease-out;
  `,
  Text: styled.p`
    position: relative;
    word-break: break-word;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: ${Color.WHITE};

    &::before {
      content: url(${attentionIcon1});
      position: absolute;
      top: 50%;
      left: -30px;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
    }
  `,
}
