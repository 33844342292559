import styled from 'styled-components'

import { PageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'

import banner from 'assets/images/green-banner.png'

import { Color } from 'root-constants'

export const StyledStickingPlanV2 = {
  PageContainer: styled(PageContainer)`
    background-image: url(${banner});
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100% auto;
  `,
  Title: styled(PageTitle)`
    color: ${Color.WHITE};
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 32px;
    min-height: 84px;
  `,
  ReviewsBlock: styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 56px;
  `,
  BeforeAfter: styled.div`
    aspect-ratio: 82 / 51;
    width: 100%;
  `,
  Feedback: styled.div`
    border-radius: 0px 0px 16px 16px;
    box-shadow: 0px 8px 20px 0px rgba(17, 30, 23, 0.1);
    display: flex;
    flex-direction: column;
    padding: 16px;
    row-gap: 10px;
  `,
  Review: styled.h2`
    color: ${Color.DARK_BLUE};
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  `,
  Name: styled.h3`
    align-items: center;
    color: ${Color.DARK_BLUE};
    column-gap: 6px;
    display: flex;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
  `,
  Stars: styled.span`
    aspect-ratio: 21 / 4;
    height: 16px;
  `,
}
