import { paymentApi } from 'api'

import { startFetching, stopFetching } from 'root-redux/actions/common'
import {
  getUserStatusAction,
  savePlanAdditionsAction,
} from 'root-redux/actions/user'
import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { getStripePurchaseFailedEventParams } from 'modules/purchase/helpers/getStripePurchaseFailedEventParams'
import { getStripePurchaseStartedEventParams } from 'modules/purchase/helpers/getStripePurchaseStartedEventParams'
import { getStripePurchaseSuccessEventParams } from 'modules/purchase/helpers/getStripePurchaseSuccessEventParams'
import { logSuccessfulPayment } from 'modules/purchase/helpers/rootHelpers'
import {
  selectCurrency,
  selectInAppId,
  selectPaymentMethod,
  selectProductId,
  selectSubscription,
  selectSubscriptionTrialLookupKey,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
} from 'modules/purchase/redux/selects'

import { IAppState, TAppDispatchThunk } from 'models/store.model'

import { Events, eventLogger } from 'services/eventLogger.service'

import {
  IN_APP_PRODUCT_EVENT_NAMES_MAP,
  ScreenName,
  UPSELL_INCLUDED_COHORTS,
  UpsellProduct,
} from 'root-constants'

const MODULE_NAME = 'UPSELL'

// actions types
export const MAKE_UPSELL = `${MODULE_NAME}/MAKE_UPSELL`

export const makeUpsellAction =
  ({
    screenName,
    isInApp,
  }: {
    screenName: ScreenName
    isInApp: boolean
  }): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)
    const productId = selectProductId(state)
    const trialPriceId = selectSubscriptionTrialLookupKey(state)
    const trialPeriodDays = selectSubscriptionTrialPeriodDays(state)
    const trialCurrentPrice = selectSubscriptionTrialPeriodPrice(state)
    const currency = selectCurrency(state)
    const cohortToUse = selectCurrentVariantCohortToUse(state)
    const selectedInAppId = selectInAppId(state)
    const selectedSubscription = selectSubscription(state)
    const paymentMethod = selectPaymentMethod(state)

    dispatch(startFetching(MAKE_UPSELL))

    const commonPurchaseStartedParams =
      getStripePurchaseStartedEventParams(state)
    const commonPurchaseSuccessParams =
      getStripePurchaseSuccessEventParams(state)
    const commonPurchaseFailedParams = getStripePurchaseFailedEventParams(state)

    eventLogger.logPurchaseStarted({
      ...commonPurchaseStartedParams,
      paymentMethod,
      event: isInApp ? Events.INAPP_PURCHASE_STARTED : Events.PURCHASE_STARTED,
      screenName,
      priceDetails: { price: trialCurrentPrice, currency },
      productId: trialPriceId,
    })

    const response = isInApp
      ? await paymentApi.createInAppPurchase({
          uuid,
          planId: Number(selectedInAppId),
          productName: selectedSubscription?.product,
        })
      : await paymentApi.makeUpsell({
          uuid,
          productId: productId || UpsellProduct.BOTH,
          planId: Number(selectedInAppId),
        })

    if (response.success && response.data && selectedSubscription) {
      logSuccessfulPayment({
        ...commonPurchaseSuccessParams,
        upsell: UPSELL_INCLUDED_COHORTS.includes(cohortToUse),
        isInApp,
        price: trialCurrentPrice,
        trialPeriodDays,
        subscriptionId: isInApp
          ? response.data.inapp.payment_id
          : response.data.subscription.subscription_id,
        paymentMethod,
        screenName,
        productId: isInApp
          ? IN_APP_PRODUCT_EVENT_NAMES_MAP[selectedSubscription.product]
          : response.data.subscription.product_id,
      })

      dispatch(savePlanAdditionsAction())
      dispatch(getUserStatusAction(uuid))
      dispatch(stopFetching(MAKE_UPSELL))
      return
    }

    eventLogger.logPurchaseFailed({
      ...commonPurchaseFailedParams,
      paymentMethod,
      error: { type: response.data, declineCode: response.data.decline_code },
      screenName,
      priceDetails: { price: trialCurrentPrice, currency },
      event: isInApp ? Events.INAPP_PURCHASE_FAILED : Events.PURCHASE_FAILED,
      productId: trialPriceId,
    })

    dispatch(getUserStatusAction(uuid))
    dispatch(stopFetching(MAKE_UPSELL))
  }
