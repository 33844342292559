import styled from 'styled-components'

import { Timer } from 'components/Timer'

import { Color } from 'root-constants'

export const StyledDiscountBanner = {
  Container: styled.div`
    background-color: #fef7e8;
    border: 1px solid ${Color.ORANGE_100};
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    overflow: hidden;
    width: 100%;
  `,
  Title: styled.h4`
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    padding: 16px 16px 8px;
    text-align: center;

    strong {
      color: #e85e47;
      font-weight: 700;
    }
  `,
  Timer: styled.h5`
    align-items: center;
    background-color: ${Color.ORANGE_100};
    color: ${Color.WHITE};
    column-gap: 4px;
    display: flex;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    line-height: 24px;
    margin: 0;
    padding: 8px 16px;
    text-align: center;
    text-transform: uppercase;
  `,
  TimerValue: styled(Timer)`
    strong {
      font-size: 16px;
      font-weight: 800;
      line-height: 24px;
    }
  `,
}
