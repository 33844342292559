import styled from 'styled-components'

import { Button } from 'components/Button'
import { Container } from 'components/Container'

import discountBg from 'assets/images/cancelOffer/cancel-offer-discount-bg.png'
import mountainsBg from 'assets/images/cancelOffer/mountains-bg.svg'

import { Color, MediaBreakpoint } from 'root-constants'

export const ImageWrapper = styled.div`
  width: 100%;
  height: 346px;
  aspect-ratio: 360/346;
  margin: 0 auto 24px;
  max-width: ${MediaBreakpoint.MAX_PHONE}px;

  @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
    max-width: 360px;
  }
`
export const Image = styled.img`
  width: 100%;
  max-width: ${MediaBreakpoint.MAX_PHONE}px;
  margin: 0 auto 24px;

  @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
    max-width: 360px;
  }
`
export const StyledContainer = styled(Container)``
export const ListTitle = styled.h2`
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
`
export const StyledButton = styled.div`
  border-radius: 16px;
  background-color: #f1fff8;
  border: 2px solid ${Color.PRIMARY};
  padding: 16px 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: ${Color.PRIMARY};
  text-align: center;
  text-transform: capitalize;
  transition: all 0.2s ease-out;

  input:checked + & {
    background: ${Color.PRIMARY};
    color: ${Color.WHITE};
  }
`
export const SecondaryWrapper = styled.div`
  text-align: center;
  padding-bottom: 40px;
  background-image: url(${mountainsBg});
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom center;

  @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
    background-size: 360px;
  }
`
export const Title = styled.h3`
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
`
export const MythsQuestion = styled.p`
  margin-bottom: 4px;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
`
export const Text = styled.p`
  padding: 0 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
`
export const Discount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px auto;
  max-width: 300px;
  min-height: 112px;
  background-image: url(${discountBg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
`
export const DiscountText = styled.p`
  padding-bottom: 4px;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
`
export const NextPageButton = styled(Button)`
  margin-top: 24px;
`
export const ListItem = styled.li`
  position: relative;
  margin-bottom: 10px;
  padding-left: 40px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    background-color: ${Color.PRIMARY};
    border: 7px solid ${Color.GREEN_100};
    border-radius: 50%;
  }

  &:last-of-type {
    margin-bottom: 16px;
  }
`
export const Question = styled.p`
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
`

export const StyledCancelOfferQuestion = {
  List: styled.ul`
    padding: 0 10px 0 24px;
    margin-bottom: 48px;
  `,
  Actions: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 48px;

    label {
      width: calc(50% - 8px);
    }
  `,
}
