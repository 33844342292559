import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import {
  selectCurrency,
  selectSubscriptionFullPrice,
  selectSubscriptionFullPriceBeforeDiscount,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialPeriodPrice,
} from 'modules/purchase/redux/selects'

import { Cohort, TimeInterval } from 'root-constants'

import { StyledEmailSubscriptionItem as S } from './EmailSubscriptions.styles'

type TProps = {
  handleClick: () => void
  className?: string
}
export const EmailSubscriptions: React.FC<TProps> = ({
  handleClick,
  className,
}) => {
  const { t } = useTranslation()
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)
  const currency = useSelector(selectCurrency)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)
  const trialCurrentPrice = useSelector(selectSubscriptionTrialPeriodPrice)
  const fullPrice = useSelector(selectSubscriptionFullPrice)
  const currentPrice = useSelector(selectSubscriptionFullPrice)
  const oldPrice = useSelector(selectSubscriptionFullPriceBeforeDiscount)

  const currentPriceWithFractionalPart = useMemo(
    () =>
      cohortToUse === Cohort.MF_EMAIL_FLOW_2
        ? trialCurrentPrice.toFixed(2)
        : currentPrice.toFixed(2),
    [cohortToUse, currentPrice, trialCurrentPrice],
  )

  const fullPriceWithFractionalPart = useMemo(
    () =>
      cohortToUse === Cohort.MF_EMAIL_FLOW_2
        ? fullPrice.toFixed(2)
        : oldPrice.toFixed(2),
    [cohortToUse, oldPrice, fullPrice],
  )

  return (
    <div className={className}>
      <S.Title>{t('purchaseEmail.subscriptionTitleV1')}</S.Title>
      <S.Plan>
        <S.Period>
          <Trans
            i18nKey="purchaseEmail.planPeriodV1"
            values={{ periodQuantity, periodInterval: TimeInterval.MONTH }}
          />
        </S.Period>
        <S.Price>
          {CURRENCY_SYMBOLS[currency]}
          {currentPriceWithFractionalPart}
        </S.Price>
      </S.Plan>
      <S.Discount>
        <S.DiscountButton type="button" onClick={handleClick}>
          {t('purchaseEmail.discountLabelV1')}
        </S.DiscountButton>
        <S.OldPrice>
          {CURRENCY_SYMBOLS[currency]}
          {fullPriceWithFractionalPart}
        </S.OldPrice>
      </S.Discount>
      {cohortToUse === Cohort.MF_EMAIL_FLOW_2 && <S.Disclaimer />}
    </div>
  )
}
