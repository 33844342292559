import styled from 'styled-components'

export const StyledContainer = {
  Container: styled.div`
    max-width: 360px;
    margin: 0 auto;
    padding: 0 16px;
  `,
  Container375: styled.div`
    max-width: 375px;
    margin: 0 auto;
    padding: 0 16px;
  `,
}
