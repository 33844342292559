import React, { useMemo } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { getDecoratedTimerValue } from 'modules/purchase/helpers/rootHelpers'
import { selectSubscriptionTimerValue } from 'modules/purchase/redux/selects'

import { StyledBannerWithTimer as S } from './BannerWithTimer.styles'

type TProps = {
  className?: string
}

export const BannerWithTimer: React.FC<TProps> = ({ className }) => {
  const timerValue = useSelector(selectSubscriptionTimerValue)

  const { minutes, seconds } = useMemo(
    () => getDecoratedTimerValue(timerValue, false),
    [timerValue],
  )

  return (
    <S.Container className={className}>
      <S.Text>
        <Trans
          i18nKey="purchase.timerLabel"
          values={{ timerValue: `${minutes}:${seconds}` }}
        />
      </S.Text>
    </S.Container>
  )
}
