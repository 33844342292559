import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledAnswerButtonMini = {
  Root: styled.div`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    max-width: 56px;
    width: 100%;
    height: 64px;
    border: 2px solid ${Color.LIGHT_BLUE};
    background-color: ${Color.LIGHT};
    transition: background-color 0.25s;
    border-radius: 8px;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;

    input:checked + & {
      background: ${Color.SECONDARY};
      border-color: ${Color.PRIMARY};
      color: ${Color.PRIMARY};
    }
  `,
}
