import { useEffect } from 'react'

export const useTimer = (
  valueInSeconds: number | null,
  setTimerValue: (timerValue: number) => void,
) => {
  useEffect(() => {
    let intervalID: NodeJS.Timer

    const setIntervalCallback = () => {
      if (!valueInSeconds) {
        clearInterval(intervalID)
        return
      }

      const nextTimerValue = valueInSeconds - 1
      setTimerValue(nextTimerValue)
    }

    intervalID = setInterval(setIntervalCallback, 1000)

    const leavePageCallback = () => {
      clearInterval(intervalID)

      if (document.hidden) {
        return
      }

      intervalID = setInterval(setIntervalCallback, 1000)
    }

    window.addEventListener('visibilitychange', leavePageCallback)

    return () => {
      clearInterval(intervalID)
      window.removeEventListener('visibilitychange', leavePageCallback)
    }
  }, [valueInSeconds, setTimerValue])
}
