import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js'
import { StripeElementStyle } from '@stripe/stripe-js'
import styled, { css } from 'styled-components'

import { Button } from 'components/Button'

import alertIcon from 'assets/images/alert-circle.svg'

import { Color } from 'root-constants'

const INPUT_TEXT_COLOR = Color.DISABLED

export const stripeElementStyle: StripeElementStyle = {
  base: {
    color: Color.GREY_100,
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '51px',
    fontSmoothing: 'antialiased',
    '::placeholder': {
      color: INPUT_TEXT_COLOR,
      fontWeight: 600,
    },
  },
  invalid: {
    color: '#ff8484',
  },
}

export const commonInputStyles = css`
  color: ${Color.GREY_100};
  height: 51px;
  background-color: ${Color.WHITE};
  line-height: 51px;
  border-radius: 14px;
  border: 1px solid ${Color.GREY};
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 16px;
  padding-left: 15px;
  transition: border-color 0.2s ease-out;

  &[data-invalid='true'],
  &.StripeElement--invalid {
    position: relative;
    border-color: #ff8484;
    background-color: #fff3f3;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 17px;
      transform: translateY(-50%);
      width: 18px;
      height: 18px;
      background: url(${alertIcon}) no-repeat;
      background-size: contain;
    }
  }
`

export const StyledCreditCardForm = {
  Form: styled.form`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-column-gap: 8px;
    grid-template-areas:
      'cardNumber cardNumber'
      'cardExpiry cardCvc'
      'cardholder cardholder'
      'button button';
  `,
  CardNumberElement: styled(CardNumberElement)`
    ${commonInputStyles};
    grid-area: cardNumber;
  `,
  CardExpiryElement: styled(CardExpiryElement)`
    ${commonInputStyles};
    grid-area: cardExpiry;
  `,
  CardCvcElement: styled(CardCvcElement)`
    ${commonInputStyles};
    grid-area: cardCvc;
  `,
  CardholderInput: styled.input`
    ${commonInputStyles};
    grid-area: cardholder;
    width: 100%;
    margin-bottom: 24px;
    font-family: 'Open Sans', sans-serif;
    outline: none;
    box-shadow: none;

    ::placeholder {
      color: ${INPUT_TEXT_COLOR};
      font-weight: 600;
    }
  `,
  Button: styled(Button)`
    grid-area: button;
    max-width: 328px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  `,
}
