import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { OPTION_VALUES_V1 } from 'pages/questions/SecondaryGoal/constants'

import { AnswerButtonV2 } from 'components/AnswerButtonV2'
import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { CustomPageId, PageId } from 'page-constants'

type TOption = {
  title: string
  value: string
  description?: string
}

type TFormProps = {
  pageId: PageId | CustomPageId
  formTitle: string
  options?: TOption[]
  handleContinue: (params: string[]) => void
}

export const QuestionCheckboxForm: React.FC<TFormProps> = ({
  pageId,
  formTitle,
  options,
  handleContinue,
}) => {
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<string[]>([])

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked) {
          setAnswers(
            answers.includes(OPTION_VALUES_V1.OTHER)
              ? [value]
              : [...answers, value],
          )
        } else {
          setAnswers(answers.filter((answer) => answer !== value))
        }
      },
    }),
    [pageId, answers],
  )

  const optionPropsForSingle = useMemo(
    () => ({
      name: pageId,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked) {
          setAnswers([value])
        } else {
          setAnswers([])
        }
      },
    }),
    [pageId],
  )

  const handleContinueClick = useCallback(() => {
    handleContinue(answers)
  }, [answers, handleContinue])

  return (
    <Container>
      <PageTitle marginBottom={24}>{t(formTitle)}</PageTitle>
      {options &&
        options.map(({ value, title, description }) => (
          <Option
            key={value}
            {...optionProps}
            marginBottom="12"
            value={value}
            checked={answers.includes(value)}
          >
            <AnswerButtonV2 withCheckbox>
              <>
                <h3>{t(title)}</h3>
                {description && <p>{t(description)}</p>}
              </>
            </AnswerButtonV2>
          </Option>
        ))}
      <Option
        {...optionPropsForSingle}
        marginBottom="12"
        value={OPTION_VALUES_V1.OTHER}
        checked={answers.includes(OPTION_VALUES_V1.OTHER)}
      >
        <AnswerButtonV2 withCheckbox>
          <h3>{t('onboarding.secondaryGoal.other')}</h3>
        </AnswerButtonV2>
      </Option>
      <StickyButtonContainer>
        <Button
          type="button"
          disabled={!answers.length}
          onClick={handleContinueClick}
        >
          {t('actions.continue')}
        </Button>
      </StickyButtonContainer>
    </Container>
  )
}
