import styled, { css } from 'styled-components'

import leftSpikelet from 'assets/images/left-spikelet.svg'
import rightSpikelet from 'assets/images/right-spikelet.svg'

import { Color } from 'root-constants'

const commonPseudoElementsStyle = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 124px;
  height: 148px;
`

const commonTextContainerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 148px;
  position: relative;

  &::before {
    content: url(${leftSpikelet});
    left: 0;
    ${commonPseudoElementsStyle};
  }

  &::after {
    content: url(${rightSpikelet});
    right: 0;
    ${commonPseudoElementsStyle};
  }
`

const commonContainerStyle = css`
  padding: 16px;
  border-radius: 32px;
  background-color: ${Color.BEIGE_100};
`

export const StyledAppReachedV1 = {
  Container: styled.div`
    ${commonContainerStyle};
  `,
  TextContainer: styled.div`
    ${commonTextContainerStyle};
    margin: 0 0 16px 0;
  `,
  Title: styled.h2`
    font-weight: 700;
    font-size: 32px;
    line-height: 30px;
    text-align: center;
    color: ${Color.PRIMARY};
    margin: 0 0 8px 0;
  `,
  Subtitle: styled.p`
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: ${Color.GREY_100};
  `,
}

export const StyledAppReachedV2 = {
  Container: styled.div`
    ${commonContainerStyle};
  `,
  TextContainer: styled.div`
    ${commonTextContainerStyle};
  `,
  Title: styled.h2`
    opacity: 68%;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: ${Color.GREY_100};
    margin: 0 0 8px 0;

    strong {
      font-size: 68px;
      line-height: 80px;
    }

    span {
      font-size: 48px;
    }
  `,
  Subtitle: styled.p`
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: ${Color.GREY_100};
    margin: 0 0 16px 0;
  `,
}
