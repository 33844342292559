import { BmiValue } from 'value-constants'

import { TBMIValue } from 'models/common.model'

import { PERCENTAGE_MULTIPLIER, UPPER_BMI_THRESHOLD } from 'root-constants'

export const getBMIPercentRange = (
  bmiScale: {
    [key in BmiValue]: { range: number; minPercentValue: number }
  },
  bmiIndex: number,
  bmiValue: TBMIValue,
  lowerBMIThreshold: number,
) => {
  if (bmiIndex >= UPPER_BMI_THRESHOLD) return PERCENTAGE_MULTIPLIER
  if (bmiIndex <= lowerBMIThreshold) return 0

  return Math.floor(
    (((bmiIndex - bmiValue.lowerThreshold) * PERCENTAGE_MULTIPLIER) /
      (bmiValue.upperThreshold - bmiValue.lowerThreshold)) *
      bmiScale[bmiValue.value].range +
      bmiScale[bmiValue.value].minPercentValue,
  )
}
