import styled from 'styled-components'

import pageBg from 'assets/images/intro-flow-bg.svg'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledStickingPlan = {
  Wrapper: styled.div`
    background-image: url(${pageBg});
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    margin-top: -45px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      background-size: 360px auto;
    }
  `,
  Title: styled.h2`
    color: ${Color.DARK_BLUE};
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -1.1px;
    line-height: 32px;
    margin-bottom: 56px;
    padding-top: 65px;
    text-align: center;
  `,
  ImageAfter: styled.img`
    aspect-ratio: 135 / 169;
    transform: rotate(-5deg);
    width: 100%;
  `,
  ImageBefore: styled.img`
    aspect-ratio: 135/169;
    transform: rotate(5deg);
    width: 100%;
  `,
  ImageWrapper: styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  `,
  ImageContainerBefore: styled.div`
    background-color: ${Color.WHITE};
    border-radius: 6px;
    box-shadow: 0 5px 20px rgba(166, 179, 194, 0.25);
    height: 217px;
    transform: rotate(-4deg) translateY(-26px) translateX(25px);
    width: 145px;
  `,
  ImageContainerAfter: styled.div`
    background-color: ${Color.WHITE};
    border-radius: 6px;
    box-shadow: 0 5px 20px rgba(166, 179, 194, 0.25);
    height: 210px;
    transform: rotate(7deg) translateX(-10px);
    width: 145px;
  `,
  Card: styled.div`
    align-items: flex-start;
    background: ${Color.WHITE};
    border-left: 2px solid ${Color.PRIMARY};
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    margin: 0 auto 32px;
    max-width: 464px;
    padding: 4px 20px;
  `,
  ReviewWrapper: styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 6px;
  `,
  CardRating: styled.img`
    display: block;
    height: 16px;
    width: 84px;
  `,
  CardReview: styled.p`
    color: ${Color.DARK_BLUE};
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
  `,
  CardReviewer: styled.h3`
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    text-align: center;
  `,
  LabelBefore: styled.p`
    color: ${Color.DISABLED};
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    padding: 6px 0;
    text-align: center;
  `,
  LabelAfter: styled.p`
    color: ${Color.PRIMARY};
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    padding: 6px 0;
    text-align: center;
  `,
}
