import styled from 'styled-components'

import { Color } from 'root-constants'

type TPlanItemProps = {
  bullet?: string
  hasBottomBorder?: boolean
  hasRightBorder?: boolean
}

export const StyledPersonalizedPlan = {
  Title: styled.h2`
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: ${Color.GREY_100};
    text-transform: capitalize;
    margin: 0 0 16px 0;
  `,
  PlanContainer: styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas:
      'item item'
      'item item';
    border-radius: 16px;
    padding: 20px;
    background-color: ${Color.GREY_LIGHT};
  `,
  PlanItem: styled.div<TPlanItemProps>`
    position: relative;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 64px;
    padding: 0 4px 0 32px;

    &:nth-of-type(even) {
      padding: 0 0 0 48px;

      &:before {
        padding: 0 0 0 16px;
      }
    }

    ${({ hasBottomBorder }) =>
      hasBottomBorder &&
      `
    border-bottom: 1px solid ${Color.GREY_400};
    `}

    ${({ hasRightBorder }) =>
      hasRightBorder &&
      `
    border-right: 1px solid ${Color.GREY_400};
    `}

    &:before {
      position: absolute;
      left: 0;
      content: url(${({ bullet }) => bullet});
      width: 24px;
      height: 24px;
      color: ${Color.GREY_100};
    }
  `,
  ItemTitle: styled.h3`
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: ${Color.DISABLED};
    text-transform: capitalize;
    margin: 0 0 4px 0;
  `,
  ItemValue: styled.p<{ isLowercase?: boolean }>`
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: ${Color.GREY_100};
    text-transform: ${({ isLowercase }) =>
      isLowercase ? 'lowercase' : 'capitalize'};
  `,
}
