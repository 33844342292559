import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useVatInfo } from 'hooks/useHasVatInfo'
import { usePaymentStore } from 'hooks/usePaymentStore'

import {
  CURRENCY_SYMBOLS,
  WEEKS_BY_PRODUCT_NAME_MAP,
} from 'modules/purchase/constants'

import { StyledCheckoutSummaryV3 as S } from './CheckoutSummaryV3.styles'

type TProps = {
  className?: string
}

export const CheckoutSummaryV3: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()

  const { currency, fullPrice, product } = usePaymentStore()

  const hasVatInfo = useVatInfo()

  return (
    <div className={className}>
      <S.DescriptionContainer>
        <S.PlanLabel>
          {t('purchase.checkoutV4.planLabel', {
            periodQuantity: WEEKS_BY_PRODUCT_NAME_MAP[product],
          })}
        </S.PlanLabel>
        <S.PlanPrice>
          {t('purchase.checkoutV4.fullPrice', {
            currency: CURRENCY_SYMBOLS[currency],
            price: fullPrice.toFixed(2),
          })}
        </S.PlanPrice>
      </S.DescriptionContainer>
      <S.TotalContainer>
        <S.TotalLabel>
          {t('purchase.checkoutV4.totalLabel', {
            includingVat: hasVatInfo ? t('purchase.includingVat') : '',
          })}
        </S.TotalLabel>
        <S.TotalPrice>
          <Trans
            i18nKey="purchase.checkoutV4.totalPrice"
            values={{
              currency: CURRENCY_SYMBOLS[currency],
              price: fullPrice.toFixed(2),
              periodQuantity: WEEKS_BY_PRODUCT_NAME_MAP[product],
            }}
            components={{ span: <span /> }}
          />
        </S.TotalPrice>
      </S.TotalContainer>
    </div>
  )
}
