import React, { useCallback, useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { ProgressRoundedV3 } from 'components/ProgressRoundedV3'
import { ReviewsSlider } from 'components/ReviewSlider'

import { useCounter } from 'hooks/useCounter'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Language } from 'root-constants'

import { StyledPreparePlanV2 as S } from './PreparePlan.styles'

export const PreparePlanV2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.preparePlanV2.titleV1', { lng: Language.EN }),
    nextPagePath,
  })

  const handleNextClick = useCallback(
    () => handleContinue(''),
    [handleContinue],
  )

  const { counter, isFinished } = useCounter(true, {
    duration: 10000,
  })

  const title = useMemo(
    () => t(`onboarding.preparePlanV2.${isFinished ? 'titleV2' : 'titleV1'}`),
    [isFinished, t],
  )

  useEffect(() => {
    let redirectTimeout: NodeJS.Timeout

    if (isFinished) {
      redirectTimeout = setTimeout(() => handleNextClick(), 2000)
    }

    return () => clearTimeout(redirectTimeout)
  }, [isFinished, handleNextClick])

  return (
    <S.PageContainer>
      <S.ProgressContainer>
        <ProgressRoundedV3 value={counter} />
      </S.ProgressContainer>
      <S.Title>{title}</S.Title>
      <S.Subtitle>
        <Trans i18nKey="onboarding.preparePlanV2.subtitle" />
      </S.Subtitle>
      <ReviewsSlider />
    </S.PageContainer>
  )
}
