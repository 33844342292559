import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useVatInfo } from 'hooks/useHasVatInfo'
import { usePaymentStore } from 'hooks/usePaymentStore'

import { StyledCheckoutSummaryV2 as S } from 'modules/purchase/components/CheckoutSummaryV2/CheckoutSummaryV2.styles'
import { CURRENCY_SYMBOLS, Currency } from 'modules/purchase/constants'

type TProps = {
  className?: string
}
export const CheckoutSummaryV2: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()

  const { currency, fullPrice, periodQuantity, periodName } = usePaymentStore()

  const hasVatInfo = useVatInfo()

  const periodNameTrans = useMemo(
    () =>
      t(`commonComponents.timeInterval.${periodName}`, {
        count: periodQuantity,
      }),
    [periodName, periodQuantity, t],
  )

  const isMexican = useMemo(() => currency === Currency.MXN, [currency])

  return (
    <div className={className}>
      <S.DescriptionContainer>
        <S.PlanLabel>
          {t('checkoutV3.planLabel', {
            periodQuantity,
            periodName: periodNameTrans,
          })}
        </S.PlanLabel>
        <S.PlanPrice>
          {t('checkoutV3.fullPrice', {
            currency: CURRENCY_SYMBOLS[currency],
            price: fullPrice.toFixed(2),
          })}
        </S.PlanPrice>
      </S.DescriptionContainer>
      <S.TotalContainer isMexican={isMexican}>
        <S.TotalLabel>
          {t('checkoutV3.totalLabel', {
            includingVat: hasVatInfo ? t('purchase.includingVat') : '',
          })}
        </S.TotalLabel>
        <S.TotalPrice>
          <Trans
            i18nKey="checkoutV3.totalPrice"
            values={{
              currency: CURRENCY_SYMBOLS[currency],
              price: fullPrice.toFixed(2),
              periodQuantity,
              periodName: periodNameTrans,
            }}
            components={{ span: <span /> }}
          />
        </S.TotalPrice>
      </S.TotalContainer>
    </div>
  )
}
