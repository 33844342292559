import styled from 'styled-components'

import { Container } from 'components/Container'
import { Logo } from 'components/Logo'

import check from 'assets/images/check-benefits.png'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledBenefits = {
  Logo: styled(Logo)`
    position: absolute;
    top: 8px;
    width: 100%;
    margin-bottom: 0;
  `,
  Banner: styled.div`
    aspect-ratio: 180/179;
    width: 100%;
    margin: 0 auto;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: ${MediaBreakpoint.MEDIUM_PHONE}px;
    }
  `,
  Container: styled(Container)`
    max-width: 100%;
    padding: 0 24px 24px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: ${MediaBreakpoint.MEDIUM_PHONE}px;
    }
  `,
  Subtitle: styled.p`
    align-items: center;
    color: ${Color.GREY_100};
    display: flex;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 24px;
    text-align: center;
  `,
  Title2: styled.p`
    color: ${Color.GREY_100};
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 24px;
    text-align: center;
  `,
  WrapperLine: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  Line: styled.div`
    border: 0;
    border-top: 1px solid #8cd9ae;
    width: 176px;
    margin-bottom: 24px;
  `,
  ItemContainer: styled.p`
    padding-left: 12px;
  `,
  ListItem: styled.li`
    position: relative;
    margin-bottom: 12px;
    padding-left: 20px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: left;

    &:last-child {
      margin-bottom: 32px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      display: block;
      width: 20px;
      height: 20px;
      color: ${Color.WHITE};
      background: url(${check}) center center no-repeat;
      background-size: cover;
      border-radius: 10px;
    }
  `,
}
