import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { BMI_VALUE_TO_MARKUP_MAP } from 'map-constants'

import { BMI_SCALE_MAP } from 'components/PersonalSummaryV2/constants'

import { useUsersBmi } from 'hooks/useUsersBmi'

import { convertTextFromSnakeCase } from 'helpers/convertTextFromSnakeCase'
import { getBMIPercentRange } from 'helpers/getBMIPercentRange'

import { LOWER_BMI_THRESHOLD_V1 } from 'root-constants'

import { StyledPersonalSummaryV2 as S } from './PersonalSummaryV2.styles'

export type TProps = {
  backgroundColor: string
}

export const PersonalSummaryV2: React.FC<TProps> = ({ backgroundColor }) => {
  const { t } = useTranslation()
  const { currentBMI } = useUsersBmi()

  const scalePercentageRange = useMemo(() => {
    return getBMIPercentRange(
      BMI_SCALE_MAP,
      currentBMI.bmiIndex,
      currentBMI.bmiValue,
      LOWER_BMI_THRESHOLD_V1,
    )
  }, [currentBMI])

  return (
    <S.Root>
      <S.Title>{t('onboarding.profileSummary.title')}</S.Title>
      <S.Content>
        <S.Header>
          <S.HeaderContent>
            <S.HeaderTitle>
              {t('onboarding.profileSummary.headerTitle')}
            </S.HeaderTitle>
            <S.HeaderSubtitle>
              {t('result.personalSummary.headerSubtitle')}
            </S.HeaderSubtitle>
          </S.HeaderContent>
          <S.HeaderLabel backgroundColor={backgroundColor}>
            {t(BMI_VALUE_TO_MARKUP_MAP[currentBMI.bmiValue.value])}
          </S.HeaderLabel>
        </S.Header>
        <S.RangeTrack>
          <S.RangeThumb percentage={scalePercentageRange} />
          <S.RangeValue percentage={scalePercentageRange}>
            {currentBMI.bmiIndex.toFixed(1)}
          </S.RangeValue>
        </S.RangeTrack>
        <S.RangeThresholds>
          <span>
            {convertTextFromSnakeCase(
              t('commonComponents.bmiValues.underweight'),
            )}
          </span>
          <span>
            {convertTextFromSnakeCase(t('commonComponents.bmiValues.normal'))}
          </span>
          <span>
            {convertTextFromSnakeCase(
              t('commonComponents.bmiValues.overweight'),
            )}
          </span>
        </S.RangeThresholds>
      </S.Content>
    </S.Root>
  )
}
