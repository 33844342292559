import { PageId } from 'page-constants'

export const INITIAL_PROGRESS_VALUE = 0
export const PROGRESS_SECTION_STEP = 1

export const POST_PAYMENT_STEPS_MAP = [
  [{ id: PageId.FINISHING_TOUCHES, text: 'login.buyPlan' }],
  [{ id: PageId.LOGIN, text: 'login.confirmInformation' }],
  [{ id: PageId.DOWNLOAD, text: 'login.createAccount' }],
]
