import { CENTIMETERS_IN_METER } from 'root-constants'

import { roundDown } from './numberHelper'

export const getBmiInMetricSystem = (weight: number, height: number) =>
  roundDown(
    Number(
      (
        weight /
        (((height / CENTIMETERS_IN_METER) * height) / CENTIMETERS_IN_METER)
      ).toFixed(2),
    ),
    1,
  )
