import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { BMI_VALUE_TO_MARKUP_MAP } from 'map-constants'

import { BMI_SCALE_MAP } from 'components/PersonalSummaryV2/constants'

import { useUsersBmi } from 'hooks/useUsersBmi'

import { convertTextFromSnakeCase } from 'helpers/convertTextFromSnakeCase'
import { getBMIPercentRange } from 'helpers/getBMIPercentRange'

import { LOWER_BMI_THRESHOLD_V1 } from 'root-constants'

import { StyledKitPersonalSummaryV1 as S } from './KitPersonalSummaryV1.styles'
import { KIT_BMI_LABEL_TO_COLOR_MAP } from './constants'

export const KitPersonalSummaryV1 = () => {
  const { t } = useTranslation()

  const { currentBMI } = useUsersBmi()

  const scalePercentageRange = useMemo(
    () =>
      getBMIPercentRange(
        BMI_SCALE_MAP,
        currentBMI.bmiIndex,
        currentBMI.bmiValue,
        LOWER_BMI_THRESHOLD_V1,
      ),
    [currentBMI],
  )

  return (
    <S.Container>
      <S.Header>
        <S.HeaderContent>
          <S.HeaderTitle>
            {t('onboarding.profileSummary.headerTitle')}
          </S.HeaderTitle>
          <S.HeaderSubtitle>
            {t('onboarding.profileSummary.headerSubtitle')}
          </S.HeaderSubtitle>
        </S.HeaderContent>
        <S.HeaderLabel
          backgroundColor={
            KIT_BMI_LABEL_TO_COLOR_MAP[currentBMI.bmiValue.value]
          }
        >
          {convertTextFromSnakeCase(
            t(BMI_VALUE_TO_MARKUP_MAP[currentBMI.bmiValue.value]),
          )}
        </S.HeaderLabel>
      </S.Header>
      <S.RangeTrack>
        <S.RangeThumb percentage={scalePercentageRange} />
        <S.RangeValue percentage={scalePercentageRange}>
          {currentBMI.bmiIndex.toFixed(1)}
        </S.RangeValue>
      </S.RangeTrack>
      <S.RangeThresholds>
        <S.Threshold>
          {convertTextFromSnakeCase(
            t('commonComponents.bmiValues.underweight'),
          )}
        </S.Threshold>
        <S.Threshold>
          {convertTextFromSnakeCase(t('commonComponents.bmiValues.normal'))}
        </S.Threshold>
        <S.Threshold>
          {convertTextFromSnakeCase(t('commonComponents.bmiValues.overweight'))}
        </S.Threshold>
      </S.RangeThresholds>
    </S.Container>
  )
}
