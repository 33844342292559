import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { AnswerButtonV2 } from 'components/AnswerButtonV2'
import { Container } from 'components/Container'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { OPTION_VALUES, QUESTION } from './constants'

export const MealsPerDay: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, setIsAnswersDisabled, handleContinue],
  )

  return (
    <Container>
      <PageTitle marginBottom={24}>
        {t('onboarding.mealsPerDay.title')}
      </PageTitle>
      <Option
        {...optionProps}
        marginBottom="12"
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.ONE}
      >
        <AnswerButtonV2>
          <h3>{t('onboarding.mealsPerDay.options.one')}</h3>
        </AnswerButtonV2>
      </Option>
      <Option
        {...optionProps}
        marginBottom="12"
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.TWO}
      >
        <AnswerButtonV2>
          <h3>{t('onboarding.mealsPerDay.options.two')}</h3>
        </AnswerButtonV2>
      </Option>
      <Option
        {...optionProps}
        marginBottom="12"
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.THREE}
      >
        <AnswerButtonV2>
          <h3>{t('onboarding.mealsPerDay.options.three')}</h3>
        </AnswerButtonV2>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.FOUR}
      >
        <AnswerButtonV2>
          <h3>{t('onboarding.mealsPerDay.options.fourPlus')}</h3>
        </AnswerButtonV2>
      </Option>
    </Container>
  )
}
