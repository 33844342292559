export const QUESTION = 'What is intermittent fasting, anyway?'

export const BENEFITS_LIST_ITEM = [
  {
    id: '1',
    description: 'onboarding.benefits.description.fastBurning',
  },
  {
    id: '2',
    description: 'onboarding.benefits.description.lowerAndReduced',
  },
  {
    id: '3',
    description: 'onboarding.benefits.description.lowerLevels',
  },
  {
    id: '4',
    description: 'onboarding.benefits.description.longerLife',
  },
]
