import React, { useEffect, useState } from 'react'

export const useTargetReachOnScroll = (
  targetScrollElementRef: React.RefObject<HTMLElement>,
): boolean => {
  const [isTargetReached, setIsTargetReached] = useState<boolean>(false)

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px 0px 0px 0px',
      threshold: 0.01,
    }

    const scrollTargetCallback = (entries) => {
      entries.forEach((entry) => {
        const isTargetInViewport = entry?.isIntersecting

        setIsTargetReached(isTargetInViewport)
      })
    }
    const observer = new IntersectionObserver(
      scrollTargetCallback,
      observerOptions,
    )
    const target = targetScrollElementRef.current

    target && observer.observe(target)

    return () => {
      observer.disconnect()
    }
  }, [targetScrollElementRef])

  return isTargetReached
}
