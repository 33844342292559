import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { StyledAchieveResult as S } from 'pages/questions/AchieveResult/AchieveResult.styles'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { Logo } from 'components/Logo'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import graph from 'assets/images/graph.png'

import { QUESTION } from './constants'

export const AchieveResult: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  return (
    <S.Wrapper>
      <Container>
        <Logo />
        <S.Title>
          <Trans i18nKey="onboarding.achieveResult.title" />
        </S.Title>
        <S.ImageContainer>
          <img src={graph} alt="graph" />
        </S.ImageContainer>
        <S.Subtitle>
          <Trans i18nKey="onboarding.achieveResult.subtitle" />
        </S.Subtitle>
        <StickyButtonContainer>
          <Button type="button" onClick={handleNextClick}>
            {t('actions.in')}
          </Button>
        </StickyButtonContainer>
      </Container>
    </S.Wrapper>
  )
}
