import { BodyPartValue } from 'value-constants'

import improveAreasMan from 'assets/images/improve-areas-men-v2.png'
import improveAreasWomen from 'assets/images/improve-areas-women-v2.png'
import armsLineSvgV4 from 'assets/images/sprite/arms-line-v4.svg'
import armsLineSvgV5 from 'assets/images/sprite/arms-line-v5.svg'
import bellyLineSvgV4 from 'assets/images/sprite/belly-line-v4.svg'
import bellyLineSvgV5 from 'assets/images/sprite/belly-line-v5.svg'
import chestLineSvgV4 from 'assets/images/sprite/chest-line-v4.svg'
import chestLineSvgV5 from 'assets/images/sprite/chest-line-v5.svg'
import hipsLineSvgV4 from 'assets/images/sprite/hips-line-v4.svg'
import hipsLineSvgV5 from 'assets/images/sprite/hips-line-v5.svg'
import legsLineSvgV4 from 'assets/images/sprite/legs-line-v4.svg'
import legsLineSvgV5 from 'assets/images/sprite/legs-line-v5.svg'

import { Gender } from 'root-constants'

export const DATA_MAP = {
  [Gender.FEMALE]: {
    imgSrc: improveAreasWomen,
    options: [
      {
        value: BodyPartValue.ARMS,
        lineSvg: armsLineSvgV4,
        lineWidth: 76,
        lineTop: 16,
      },
      {
        value: BodyPartValue.CHEST,
        lineSvg: chestLineSvgV4,
        lineWidth: 116,
        lineTop: 4,
      },
      {
        value: BodyPartValue.BELLY,
        lineSvg: bellyLineSvgV4,
        lineWidth: 120,
        lineTop: -30,
      },
      {
        value: BodyPartValue.HIPS,
        lineSvg: hipsLineSvgV4,
        lineWidth: 102,
        lineTop: -16,
      },
      {
        value: BodyPartValue.LEGS,
        lineSvg: legsLineSvgV4,
        lineWidth: 104,
        lineTop: -34,
      },
    ],
  },
  [Gender.MALE]: {
    imgSrc: improveAreasMan,
    options: [
      {
        value: BodyPartValue.ARMS,
        lineSvg: armsLineSvgV5,
        lineWidth: 60,
        lineTop: 6,
      },
      {
        value: BodyPartValue.CHEST,
        lineSvg: chestLineSvgV5,
        lineWidth: 82,
        lineTop: 0,
      },
      {
        value: BodyPartValue.BELLY,
        lineSvg: bellyLineSvgV5,
        lineWidth: 93,
        lineTop: -30,
      },
      {
        value: BodyPartValue.HIPS,
        lineSvg: hipsLineSvgV5,
        lineWidth: 85,
        lineTop: -30,
      },
      {
        value: BodyPartValue.LEGS,
        lineSvg: legsLineSvgV5,
        lineWidth: 85,
        lineTop: -26,
      },
    ],
  },
}
