import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { Button } from 'components/Button'

import { selectSubscriptionList } from 'root-redux/selects/common'
import { selectUserOnboardingEmail } from 'root-redux/selects/user'

import { UpsellSubscriptionItemSwitchers } from 'modules/purchase/components'
import {
  MAX_NUMBER_OF_SELECTED_SUBSCRIPTIONS,
  NUMBER_OF_SINGLE_SUBSCRIPTION,
} from 'modules/purchase/components/UpsellSubscriptionBlock/constants'
import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'
import { selectCurrency } from 'modules/purchase/redux/selects'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import { ScreenName, UpsellProduct } from 'root-constants'

import { StyledUpsellSubscriptionBlock as S } from './UpsellSubscriptionBlock.styles'

type TPageProps = {
  makeUpsell: () => void
}

export const UpsellSubscriptionBlock: React.FC<TPageProps> = ({
  makeUpsell,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { search } = useLocation()

  const currency = useSelector(selectCurrency)
  const subscriptions = useSelector(selectSubscriptionList)
  const email = useSelector(selectUserOnboardingEmail)

  const [selectedPrices, setSelectedPrices] = useState<string[]>([])
  const [isSwitchersOn, setIsSwitchersOn] = useState(false)

  const fitnessAndSelfcare = subscriptions.find(
    (subscription) => subscription.product === UpsellProduct.BOTH,
  )
  // Default subscription on this page
  const fitnessSubscription = subscriptions.find(
    (subscription) => subscription.product === UpsellProduct.FITNESS,
  )
  const selfCareSubscription = subscriptions.find(
    (subscription) => subscription.product === UpsellProduct.SELF_CARE,
  )

  const isSecondaryButtonVisible = useMemo(
    () => selectedPrices.length < MAX_NUMBER_OF_SELECTED_SUBSCRIPTIONS,
    [selectedPrices],
  )

  const isSingleSubscriptionSelected = useMemo(
    () => selectedPrices.length === NUMBER_OF_SINGLE_SUBSCRIPTION,
    [selectedPrices],
  )

  useEffect(() => {
    if (fitnessSubscription) {
      dispatch(setSelectedSubscriptionAction(fitnessSubscription))
      setSelectedPrices((current) => [...current, fitnessSubscription?.id])
    }
  }, [dispatch, fitnessSubscription])

  useEffect(() => {
    if (isSingleSubscriptionSelected) {
      const [selectedPriceId] = selectedPrices
      const selectedSubscription = subscriptions.find(
        (subscription) => subscription.id === selectedPriceId,
      )

      if (selectedSubscription) {
        dispatch(setSelectedSubscriptionAction(selectedSubscription))
      }

      return
    }

    fitnessAndSelfcare &&
      dispatch(setSelectedSubscriptionAction(fitnessAndSelfcare))
  }, [
    subscriptions,
    selectedPrices,
    fitnessAndSelfcare,
    dispatch,
    isSingleSubscriptionSelected,
  ])

  const handleContinue = useCallback(() => {
    eventLogger.logUpsellPurchaseClose(ScreenName.UPSELL_SWITCHER)
    goTo({ pathname: PageId.FINISHING_TOUCHES, search })
  }, [search])

  const handleContinueWithAll = useCallback(() => {
    setIsSwitchersOn(true)
    fitnessAndSelfcare &&
      dispatch(setSelectedSubscriptionAction(fitnessAndSelfcare))
    makeUpsell()
  }, [dispatch, fitnessAndSelfcare, makeUpsell])

  const handleChange = useCallback(
    ({ target: { value } }) => {
      const isPriceSelected = selectedPrices.includes(value)
      const selectedSubscription = subscriptions.find(
        (subscription) => subscription.id === value,
      )
      if (!isPriceSelected) {
        selectedSubscription &&
          eventLogger.logUpsellSubscriptionEnable(selectedSubscription?.product)
        setSelectedPrices((current) => [...current, value])

        return
      }
      selectedSubscription &&
        eventLogger.logUpsellSubscriptionDisable(selectedSubscription?.product)
      setSelectedPrices((current) => [...current.filter((id) => id !== value)])
    },
    [selectedPrices, subscriptions],
  )

  return (
    <>
      <S.Discount>
        <S.DiscountBadge>
          <Trans
            i18nKey="upsell.discount.badge"
            components={{ discount: <div /> }}
          />
        </S.DiscountBadge>
        <S.DiscountText>
          <Trans
            i18nKey="upsell.discount.text"
            values={{
              price: fitnessAndSelfcare?.trialPrices.fullPrice,
              currency: CURRENCY_SYMBOLS[currency],
            }}
          />
        </S.DiscountText>
      </S.Discount>

      {fitnessSubscription && (
        <UpsellSubscriptionItemSwitchers
          value={fitnessSubscription.id}
          onChange={handleChange}
          key={fitnessSubscription.id}
          subscription={fitnessSubscription}
          isSelected={
            selectedPrices.includes(fitnessSubscription.id) || isSwitchersOn
          }
        />
      )}

      {selfCareSubscription && (
        <UpsellSubscriptionItemSwitchers
          value={selfCareSubscription.id}
          onChange={handleChange}
          key={selfCareSubscription.id}
          subscription={selfCareSubscription}
          isSelected={
            selectedPrices.includes(selfCareSubscription.id) || isSwitchersOn
          }
        />
      )}
      <S.ButtonContainer>
        <Button onClick={handleContinueWithAll}>
          {t('upsell.actions.acceptAll')}
        </Button>
      </S.ButtonContainer>

      {isSecondaryButtonVisible && (
        <S.ButtonContainer>
          <S.SecondaryButton
            onClick={!selectedPrices.length ? handleContinue : makeUpsell}
          >
            {!selectedPrices.length
              ? t('upsell.actions.withoutCourses')
              : t('upsell.actions.acceptSelected')}
          </S.SecondaryButton>
        </S.ButtonContainer>
      )}

      <S.EmailWrapper>
        <S.Email>
          <Trans i18nKey="upsell.email" values={{ email }} />
        </S.Email>
      </S.EmailWrapper>

      <S.BottomActions>
        <S.ButtonContainer>
          {(!selectedPrices.length || !isSingleSubscriptionSelected) && (
            <Button onClick={handleContinueWithAll}>
              {t('upsell.buttonText2')}
            </Button>
          )}
          {isSingleSubscriptionSelected && (
            <Button onClick={makeUpsell}>{t('upsell.buttonText')}</Button>
          )}
        </S.ButtonContainer>
        <S.LinkContainer>
          <S.Link onClick={handleContinue}>{t('upsell.noThanksLink')}</S.Link>
        </S.LinkContainer>
      </S.BottomActions>
    </>
  )
}
