import styled from 'styled-components'

import pageBg from 'assets/images/good-hands-bg.png'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledGoodHands = {
  ImageContainer: styled.div`
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    margin: 0 auto;
    padding-top: 10px;
    background-image: url(${pageBg});
    background-repeat: no-repeat;
    background-size: contain;
    aspect-ratio: 360/358;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
    }
  `,
  Title: styled.div`
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: ${Color.WHITE};
    text-align: center;
  `,
  Subtitle: styled.p`
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    margin-top: 16px;
  `,
  CountWrapper: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    background: ${Color.WHITE};
    box-shadow: 0 8px 20px rgba(17, 30, 23, 0.1);
    border-radius: 16px;
    width: 162px;
    height: 59px;
    margin: 0 auto;
  `,
  Count: styled.h1`
    font-weight: 700;
    font-size: 32px;
    line-height: 30px;
    text-align: center;
    color: #746be6;
  `,
  ProgressbarContainer: styled.div`
    margin-top: 60px;
    position: relative;
  `,
}
