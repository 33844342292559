import { BmiValue } from 'value-constants'

export const BMI_SCALE_MAP = {
  [BmiValue.UNDERWEIGHT]: {
    range: 0.25,
    minPercentValue: 0,
  },
  [BmiValue.NORMAL]: {
    range: 0.5,
    minPercentValue: 25,
  },
  [BmiValue.OVERWEIGHT]: {
    range: 0.08,
    minPercentValue: 75,
  },
  [BmiValue.OBESITY]: {
    range: 0.08,
    minPercentValue: 83,
  },
  [BmiValue.EXTREME_OBESITY]: {
    range: 0.09,
    minPercentValue: 91,
  },
}
