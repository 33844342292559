import { PageId } from 'page-constants'

export const STATEMENT_QUESTIONS_MAP: Record<string, string> = {
  [PageId.QUESTIONNAIRE_1]:
    'Eating is one of the ways I use to deal with my emotions.',
  [PageId.QUESTIONNAIRE_2]:
    'After a week or two of eating healthier or exercising, I fall back into my old routine.',
  [PageId.QUESTIONNAIRE_3]:
    'People around me often make it hard for me to stick to healthier habits.',
  [PageId.QUESTIONNAIRE_4]:
    'Multitasking while eating has become a habit (like eating while working or scrolling on my phone).',
  [PageId.QUESTIONNAIRE_5]: `My unhealthy decisions often lead me to make more unhealthy decisions. I feel like a failure because I can't commit to a healthier lifestyle.`,
  [PageId.QUESTIONNAIRE_6]: `Even if I'm already full, I usually clear my plate.`,
}
