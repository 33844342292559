import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { StyledTimer as S } from 'components/Timer/Timer.styles'

import { TEN_MINUTES_IN_SECONDS } from 'modules/purchase/constants'
import { useTimerForTarget } from 'modules/purchase/hooks/useTimerForTarget'

type TProps = {
  className?: string
  icon?: string
  secondsToEnd?: number
  isHeaderTimer?: boolean
  hasIndent?: boolean
  hasText?: boolean
  hasHour?: boolean
}

export const Timer: React.FC<TProps> = ({
  className,
  icon,
  secondsToEnd = TEN_MINUTES_IN_SECONDS,
  isHeaderTimer,
  hasHour,
  hasIndent,
  hasText,
}) => {
  const { t } = useTranslation()

  const timerElementHourRef = useRef<HTMLSpanElement>(null)
  const timerElementMinRef = useRef<HTMLSpanElement>(null)
  const timerElementSecRef = useRef<HTMLSpanElement>(null)

  useTimerForTarget(
    {
      hours: timerElementHourRef,
      minutes: timerElementMinRef,
      seconds: timerElementSecRef,
    },
    secondsToEnd,
    false,
  )

  return (
    <S.Timer className={className} isHeaderTimer={isHeaderTimer}>
      {icon && !isHeaderTimer && (
        <S.TimerIcon>
          <img src={icon} alt="clock" />
        </S.TimerIcon>
      )}
      {hasHour && (
        <>
          <S.TimerSign>
            <strong ref={timerElementHourRef} />
            {hasText && !isHeaderTimer && (
              <span>{t('commonComponents.hour')}</span>
            )}
          </S.TimerSign>
          <S.Separator
            isHeaderTimer={isHeaderTimer}
            hasText={hasText}
            hasIndent={hasIndent}
          >
            :
          </S.Separator>
        </>
      )}
      <>
        <S.TimerSign>
          <strong ref={timerElementMinRef} />
          {hasText && !isHeaderTimer && (
            <span>{t('commonComponents.timer.min')}</span>
          )}
        </S.TimerSign>
        <S.Separator
          isHeaderTimer={isHeaderTimer}
          hasIndent={hasIndent}
          hasText={hasText}
        >
          :
        </S.Separator>
      </>

      <S.TimerSign>
        <strong ref={timerElementSecRef} />
        {hasText && !isHeaderTimer && (
          <span>{t('commonComponents.timer.sec')}</span>
        )}
      </S.TimerSign>
    </S.Timer>
  )
}
