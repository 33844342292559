import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { SleepingTimeValue } from 'value-constants'

import { AnswerButton } from 'components/AnswerButton'
import { Container } from 'components/Container'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import bedIcon from 'assets/images/bed.png'
import sleepingEmoji from 'assets/images/sleeping-emoji.png'
import superHeroIcon from 'assets/images/superhero-emoji.png'
import tripleZIcon from 'assets/images/triple-z-sleeping.png'

import { Language } from 'root-constants'

export const SleepingTime: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.sleepingTime.title', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, setIsAnswersDisabled, handleContinue],
  )

  return (
    <Container>
      <PageTitle marginBottom={24}>
        {t('onboarding.sleepingTime.title')}
      </PageTitle>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={SleepingTimeValue.LESS_THAN_FIVE}
      >
        <AnswerButton iconSrc={bedIcon}>
          <h3>{t('onboarding.sleepingTime.options.lessThanFive')}</h3>
        </AnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={SleepingTimeValue.FIVE_SIX}
      >
        <AnswerButton iconSrc={sleepingEmoji}>
          <h3>{t('onboarding.sleepingTime.options.fiveSix')}</h3>
        </AnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={SleepingTimeValue.SEVEN_EIGHT}
      >
        <AnswerButton iconSrc={tripleZIcon}>
          <h3>{t('onboarding.sleepingTime.options.sevenEight')}</h3>
        </AnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={SleepingTimeValue.MORE_THAN_EIGHT}
      >
        <AnswerButton iconSrc={superHeroIcon}>
          <h3>{t('onboarding.sleepingTime.options.moreThanEight')}</h3>
        </AnswerButton>
      </Option>
    </Container>
  )
}
