import styled, { css } from 'styled-components'

import { StyledButtonBase } from 'components/Button/Button.styles'

import { Color } from 'root-constants'

const commonLabelStyles = css`
  padding: 8px 5px;
  background: #eefee6;
  border: 2px solid #71c554;
  border-radius: 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #71c554;
`

const commonTitleStyles = css`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -1px;
`

export const StyledDownload = {
  Wrapper: styled.div`
    max-width: 360px;
    margin: 0 auto;
    padding: 0 16px;
  `,
  Title: styled.h2`
    ${commonTitleStyles};
  `,
  Subtitle: styled.h3`
    ${commonTitleStyles};
    margin-bottom: 16px;
  `,
  Emoji: styled.img`
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-left: 4px;
  `,
  LoginDescription: styled.p`
    margin-bottom: 16px;
    padding: 12px 14px;
    background: #fdf7e7;
    border: 2px solid #e9c96d;
    border-radius: 16px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: ${Color.GREY_100};
  `,
  List: styled.ul`
    margin-left: 15px;
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    list-style-type: decimal;

    li {
      display: list-item;
    }
  `,
  DownloadAppText: styled.p`
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 35px;

    strong {
      ${commonLabelStyles};
    }
  `,
  ContinueText: styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
  `,
  EmailLabel: styled.p`
    ${commonLabelStyles};
    display: inline-block;
    margin: 13px 0 0 15px;
  `,
  Image: styled.img`
    margin: 16px auto 0;
  `,
  Button: styled.button`
    ${StyledButtonBase};
    position: sticky;
    position: -webkit-sticky;
    max-width: 328px;
    bottom: 15px;
    margin-top: 16px;
    font-size: 20px;
    font-weight: 700;
    line-height: 54px;
  `,
}
