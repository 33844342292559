import amex from 'assets/images/amex.png'
import apple from 'assets/images/apple-pay.png'
import bancaires from 'assets/images/bancaires.png'
import diners from 'assets/images/diners.png'
import discover from 'assets/images/discover.png'
import google from 'assets/images/google-pay-v2.png'
import jcb from 'assets/images/jcb.png'
import mastercard from 'assets/images/master-card.png'
import payPal from 'assets/images/pay-pal.png'
import union from 'assets/images/union-pay.png'
import visa from 'assets/images/visa.png'

export const CHECKOUT_METHODS = [
  apple,
  google,
  payPal,
  amex,
  discover,
  visa,
  mastercard,
  bancaires,
  diners,
  jcb,
  union,
]

export const IMAGE_PATH = '/images/money-back-guarantee-v3'
