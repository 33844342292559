import styled, { css } from 'styled-components'

import { StyledButtonBase } from 'components/Button/Button.styles'

import { EmailLogin } from 'modules/login/components/EmailLogin'

import { Color } from 'root-constants'

export const StyledLogin = {
  EmailLogin: styled(EmailLogin)`
    margin: 0 0 24px 0;
  `,
  DisclaimerContainer: styled.div`
    position: relative;
    margin: 0 0 24px 0;
  `,
  SignUpLink: styled.p`
    margin: 0 0 24px 0;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    pointer-events: none;

    strong {
      font-weight: 600;
      text-decoration: underline;
      pointer-events: auto;
      cursor: pointer;
    }
  `,
  Separator: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 24px 0;
  `,
  SeparatorLine: styled.div`
    flex: 1;
    height: 1px;
    background-color: ${Color.GREY_102};
  `,
  SeparatorText: styled.span`
    padding: 0 5px;
    font-weight: 400;
    font-size: 17px;
    line-height: 18px;
    text-align: center;
  `,
}

const StyledLoginButtonBase = css`
  ${StyledButtonBase};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
`

export const StyledLoginButton = {
  Email: styled.button`
    ${StyledLoginButtonBase};

    &[data-icon='false'] {
      background-image: none;
    }
  `,
  Facebook: styled.button`
    ${StyledLoginButtonBase};
    background-color: #395a98;
  `,
  Apple: styled.button`
    ${StyledLoginButtonBase};
    background-color: #010002;

    & > span {
      margin-left: 3px;
    }
  `,
}
