import styled, { css, keyframes } from 'styled-components'

import { Button } from 'components/Button'
import { Timer } from 'components/Timer'

import arrow from 'assets/images/animated-arrow.png'
import arrowRight from 'assets/images/arrow-right.svg'

import { Color } from 'root-constants'

type TProps = {
  isTimerHidden?: boolean
  goalColor?: string
  bmiColor?: string
}

const animation = keyframes`
  from {
    transform: translate(-120px);
  }
  to {
    transform: translate(328px);
  }
`

const commonColumnStyles = css`
  display: flex;
  flex-direction: column;
`

export const StyledIntroMediaV3 = {
  Wrapper: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 0 32px 0;
  `,
  BeforeAfterAnimation: styled.div`
    aspect-ratio: 1 / 1;
    position: relative;
    display: flex;
    overflow: hidden;
    border-radius: 16px 16px 0 0;
    width: 100%;
    
    &::before {
      position: absolute;
      content: '';
      width: 120px;
      height: 328px;
      background-image: url(${arrow});
      background-repeat: round;
      animation: 0.6s linear 1s infinite running slidein;
      animation-name: ${animation};
    }}
  `,
  BeforeAfterDescription: styled.div<TProps>`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 16px 26px;
    overflow: hidden;
    border-radius: 0 0 16px 16px;
    background-color: ${Color.WHITE};
    box-shadow: 0 24px 64px 0 #0000001f;
    position: relative;
    margin: 0 0 24px 0;

    &:after {
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 24px;
      height: 24px;
      mask: url(${arrowRight}) no-repeat 50% 50%;
      mask-size: 24px 24px;
      background-color: ${({ goalColor }) => goalColor};
    }
  `,
  Before: styled.div`
    ${commonColumnStyles};
  `,
  After: styled.div`
    ${commonColumnStyles};
  `,
  Weight: styled.div`
    ${commonColumnStyles};
    margin: 0 0 16px 0;
  `,
  BMI: styled.div`
    display: flex;
    align-items: center;
    margin: 0 0 6px 0;

    > :not(:last-child) {
      margin: 0 4px 0 0;
    }
  `,
  DescriptionTitle: styled.p`
    color: ${Color.DISABLED};
    line-height: 16px;
    font-weight: 600;
    font-size: 14px;
    margin: 0 0 6px 0;
  `,
  DescriptionValue: styled.p`
    color: ${Color.GREY_100};
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
  `,
  HighlightedValue: styled.span<TProps>`
    color: ${({ goalColor }) => goalColor};
  `,
  BMILabel: styled.p<TProps>`
    color: ${({ bmiColor }) => bmiColor};
    font-weight: 700;
    line-height: 16px;
    font-size: 16px;
  `,
  TimerContainer: styled.div<TProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-shadow: 0 12px 32px 0 #0000001f;
    padding: 12px;
    border-radius: 16px;

    ${({ isTimerHidden }) =>
      isTimerHidden &&
      `
    position: fixed;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 360px;
      justify-content: center;
      padding: 13px 0;
      background: linear-gradient(269.02deg, #f08b34 25.31%, #ffda97 108.57%);
      z-index: 11;
      border-radius: 0;
    `};
  `,
  TimerLabel: styled.span`
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    align-items: center;
    margin: 0 6px 0 0;
    color: ${Color.WHITE};
  `,
  Timer: styled(Timer)<TProps>`
    strong {
      color: ${({ isHeaderTimer }) =>
        isHeaderTimer ? Color.WHITE : Color.GREY_100};
    }
    span {
      color: ${Color.DISABLED};
    }
  `,
  Button: styled(Button)<TProps>`
    ${({ isTimerHidden }) =>
      isTimerHidden &&
      `
    display: none;
    `};

    height: 100%;
    width: auto;
    margin: 0;
    padding: 13px 14px;
    border-radius: 16px;

    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: ${Color.WHITE};
    background-color: ${({ color }) => color};
  `,
}
