import { PrimaryGoalValue } from 'value-constants'

export const PRIMARY_GOAL_OPTIONS_V1 = [
  {
    value: PrimaryGoalValue.LOSE_WEIGHT,
    label: 'commonComponents.primaryGoal.loseWeight',
  },
  {
    value: PrimaryGoalValue.GET_FIT,
    label: 'commonComponents.primaryGoal.getFit',
  },
  {
    value: PrimaryGoalValue.BUILD_HEALTHY_HABITS,
    label: 'commonComponents.primaryGoal.developHealthyHabits',
  },
]

export const PRIMARY_GOAL_OPTIONS_V2 = [
  {
    value: PrimaryGoalValue.LOSE_WEIGHT,
    label: 'onboarding.primaryGoalV3.loseWeight',
  },
  {
    value: PrimaryGoalValue.GET_HEALTHY,
    label: 'onboarding.primaryGoalV3.getHealthy',
  },
]

export const MIN_NAME_LENGTH = 1
