import React, { useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { SwiperSlide } from 'swiper/react'
import { Swiper as SwiperClass } from 'swiper/types'

import { SliderWithScaledActiveSlide } from 'components/SliderWithScaledActiveSlide'

import { StyledSwipedPersonalizedPlan as S } from './SwipedPersonalizedPlan.styles'

type TProps = {
  className?: string
  swipeData: {
    titleData?: {
      title?: string
      titleColor?: string
      subtitle?: string
    }
    content: string
  }[]
}
export const SwipedPersonalizedPlan: React.FC<TProps> = ({
  className,
  swipeData,
}) => {
  const { t } = useTranslation()
  const [activeSlideIndex, setActiveSlideIndex] = useState(0)

  const titleData = useMemo(
    () => swipeData[activeSlideIndex]?.titleData || null,
    [activeSlideIndex, swipeData],
  )

  const handleSlideChange = (swiper: SwiperClass) => {
    setActiveSlideIndex(swiper.realIndex)
  }

  return (
    <div className={className}>
      <S.CarouselContainer>
        {titleData && (
          <S.TitleContainer>
            <S.Title color={titleData.titleColor}>
              <Trans i18nKey={titleData.title} />
            </S.Title>{' '}
            {titleData.subtitle && (
              <S.Subtitle>{t(titleData.subtitle)}</S.Subtitle>
            )}
          </S.TitleContainer>
        )}
        <SliderWithScaledActiveSlide onSlideChange={handleSlideChange}>
          {swipeData.map(({ content }) => (
            <SwiperSlide key={content}>
              <S.ScreenContainer>
                <img src={content} alt="screen" />
              </S.ScreenContainer>
            </SwiperSlide>
          ))}
        </SliderWithScaledActiveSlide>
      </S.CarouselContainer>
    </div>
  )
}
