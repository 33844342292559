import React from 'react'
import { useTranslation } from 'react-i18next'

import { StyledIconsBulletList as S } from './ListWithEmojis.styles'

type TProps = {
  className?: string
  title?: string
  items: {
    textKey: string
    iconSrc: string
  }[]
}
export const ListWithEmojis: React.FC<TProps> = ({
  className,
  title,
  items,
}) => {
  const { t } = useTranslation()

  return (
    <S.ListContainer className={className}>
      {title && <S.Title>{t(title)}</S.Title>}
      <S.List>
        {items.map(({ textKey, iconSrc }) => (
          <S.ListItem key={textKey} emoji={iconSrc}>
            {t(textKey)}
          </S.ListItem>
        ))}
      </S.List>
    </S.ListContainer>
  )
}
