import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import dayjs from 'dayjs'
import { MotivationValue } from 'value-constants'

import { CustomDatePickerV2 } from 'components/CustomDatePicker/CustomDatePickerV2'
import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'

import { selectChosenEvent } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { DEFAULT_DATE_FORMAT } from 'helpers/dateHelper'

import { TPageProps } from 'models/common.model'

import { replaceHistory } from 'browser-history'
import { CustomPageId } from 'page-constants'
import { Language } from 'root-constants'

import { StyledEventDateV2 as S } from './EventDate.styles'

export const EventDateV2: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const chosenEvent = useSelector(selectChosenEvent)

  const todayDate = dayjs().add(1, 'day').toDate()

  const [selectedDate, setSelectedDate] = useState<Date>(todayDate)
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)

  const shouldBeRedirect = useMemo(
    () => chosenEvent === MotivationValue.NO,
    [chosenEvent],
  )

  useLayoutEffect(() => {
    if (shouldBeRedirect) {
      replaceHistory(nextPagePath)
    }
  }, [nextPagePath, shouldBeRedirect])

  const handleContinue = useNextStep({
    pageId: CustomPageId.EVENT_DATE,
    question: t('onboarding.eventDateV2.title', { lng: Language.EN }),
    nextPagePath,
  })

  const handleContinueClick = useCallback(() => {
    handleContinue(dayjs(selectedDate).format(DEFAULT_DATE_FORMAT))
  }, [handleContinue, selectedDate])

  const handleSkip = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  const toggleDatePickerVisibility = useCallback(
    () => setIsDatePickerOpen((open) => !open),
    [],
  )

  return (
    <KitPageContainer
      isContinueButtonDisabled={isDatePickerOpen}
      hasContinueButton
      hasSkipButton
      onContinueButtonClick={handleContinueClick}
      onSkipButtonClick={handleSkip}
    >
      <PageTitle marginBottom={8} textAlign="left">
        {t('onboarding.eventDateV2.title')}
      </PageTitle>
      <S.Subtitle>{t('onboarding.eventDateV2.subTitle')}</S.Subtitle>
      <S.Overlay
        open={isDatePickerOpen}
        onClick={(event) => event.stopPropagation()}
        onTouchStart={(event) => event.stopPropagation()}
      />
      <CustomDatePickerV2
        selectedDate={selectedDate}
        minDate={todayDate}
        toggleDatePickerVisibility={toggleDatePickerVisibility}
        onChange={setSelectedDate}
      />
    </KitPageContainer>
  )
}
