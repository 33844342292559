import appleInstructionImage from 'assets/images/getting-app-apple.png'

import { LoginMethod } from 'root-constants'

export const LOGIN_METHOD_INSTRUCTION_IMAGE_MAP = {
  [LoginMethod.APPLE]: appleInstructionImage,
}

export const LOGIN_METHOD_INSTRUCTION_LINK_MAP = {
  [LoginMethod.EMAIL]:
    'https://myfast.onelink.me/i1q0?pid=web2web_payment_success&c=myfast_DL_Email&deep_link_value=custom_token&af_dp=myfast%3A%2F%2F%3Flogin%3D&deep_link_sub1=password',
  [LoginMethod.APPLE]:
    'https://myfast.onelink.me/i1q0?pid=web2web_payment_success&c=myfast_DL_Apple&deep_link_value=custom_token&af_dp=myfast%3A%2F%2F%3Flogin%3D&deep_link_sub1=apple.com',
}
