import React, { FC, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Footer } from 'components/Footer'
import { PageContainer } from 'components/PageContainer'

import { useNextStep } from 'hooks/useNextStep'
import { useTitleFromUrl } from 'hooks/useTitleFromUrl'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import female from 'assets/images/start-journey-review-female.png'

import { Language } from 'root-constants'

import { StyledStartJourney as S } from './StartJourney.styles'

export const StartJourneyV1: FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const { title: titleFromUrl, subText: subtitleFromUrl } = useTitleFromUrl()

  const handleContinue = useNextStep({
    pageId,
    question:
      titleFromUrl ||
      t('onboarding.startJourneyV1.title', { lng: Language.EN }),
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    eventLogger.logFirstPageCompleted({
      question:
        titleFromUrl ||
        t('onboarding.startJourneyV1.title', { lng: Language.EN }),
    })
    handleContinue('')
  }, [handleContinue, t, titleFromUrl])

  return (
    <PageContainer>
      <S.Title>
        {titleFromUrl || <Trans i18nKey="onboarding.startJourneyV1.title" />}
      </S.Title>
      <S.Subtitle>
        {subtitleFromUrl || t('onboarding.startJourneyV1.subtitle')}
      </S.Subtitle>
      <S.ReviewContainer>
        <S.Photo>
          <img src={female} alt="female-review" />
        </S.Photo>
        <S.Review>
          <S.AboutMe>
            <Trans i18nKey="onboarding.startJourneyV1.review.aboutMe" />
          </S.AboutMe>
          <S.Content>{t('onboarding.startJourneyV1.review.content')}</S.Content>
        </S.Review>
      </S.ReviewContainer>
      <S.CallToAction>
        {t('onboarding.startJourneyV1.callToAction')}
      </S.CallToAction>
      <S.StickyButtonContainer>
        <S.Button type="button" onClick={handleNextClick}>
          {t('actions.takeQuiz')}
        </S.Button>
      </S.StickyButtonContainer>
      <Footer />
    </PageContainer>
  )
}
