import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { IMAGE_PATH } from 'pages/questions/DontExercise/constants'

import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'

import { selectLanguage } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { CDN_FOLDER_LINK, Language } from 'root-constants'

import { StyledDontExerciseV1 as S } from './DontExerciseV1.styles'

export const DontExerciseV1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const language = useSelector(selectLanguage)

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.dontExercise.title', { lng: Language.EN }),
    nextPagePath,
  })

  const imgSrc = useMemo(
    () => `${CDN_FOLDER_LINK}${IMAGE_PATH}-${language}.png`,
    [language],
  )

  return (
    <KitPageContainer
      hasContinueButton
      continueButtonContent={
        <S.ContinueButtonText>{t('actions.gotIt')}!</S.ContinueButtonText>
      }
      onContinueButtonClick={() => handleContinue('')}
    >
      <PageTitle marginBottom={24} textAlign="center">
        {t('onboarding.dontExercise.title')}
      </PageTitle>
      <S.ImageContainer>
        <img src={imgSrc} alt="dont-exercise" />
      </S.ImageContainer>
    </KitPageContainer>
  )
}
