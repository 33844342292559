import styled from 'styled-components'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledStartPlanningMeals = {
  Banner: styled.div`
    aspect-ratio: 45 / 23;
    margin: 0 auto 4px;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    width: 100%;
  `,
  Title: styled.h2`
    color: ${Color.GREY_100};
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 24px;
    text-align: center;

    strong {
      color: ${Color.PRIMARY};
    }
  `,
  Info: styled.h3`
    color: ${Color.GREY_100};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 170px;
    text-align: center;

    strong {
      font-weight: 700;
    }
  `,
}
