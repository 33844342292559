import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

const commonTextStyles = css`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  color: ${Color.DISABLED};
`

export const StyledCustomerReviewsV2 = {
  Wrapper: styled.div``,
  Title: styled.h2`
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  `,
  ReviewContainer: styled.div`
    margin-bottom: 16px;
    padding: 16px;
    border-radius: 12px;
    background-color: ${Color.WHITE};
    box-shadow: 0 8px 20px rgba(17, 30, 23, 0.1);

    &:last-of-type {
      margin-bottom: 28px;
    }
  `,
  ReviewHeader: styled.div`
    display: grid;
    grid-template-columns: 1fr 70px;
  `,
  ReviewTitle: styled.span`
    align-self: center;
    padding-bottom: 4px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: left;
  `,
  Date: styled.span`
    ${commonTextStyles};
    align-self: center;
    padding-bottom: 4px;
  `,
  Author: styled.span`
    ${commonTextStyles};
    font-weight: 600;
  `,
  Rating: styled.div`
    aspect-ratio: 49 / 9;
    display: flex;
    height: 18px;
    justify-content: space-between;
    margin-bottom: 8px;
  `,
  Text: styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${Color.DISABLED};
  `,
}
