import React from 'react'
import { useTranslation } from 'react-i18next'

import featuredImagePng from 'assets/images/featured.png'

import { StyledFeaturedBlock as S } from './FeaturedBlock.styles'

export const FeaturedBlock: React.FC = (props) => {
  const { t } = useTranslation()

  return (
    <S.Wrapper {...props}>
      <S.Title>{t('purchase.featuredBlockTitle')}</S.Title>
      <S.Image>
        <img src={featuredImagePng} alt="body" />
      </S.Image>
    </S.Wrapper>
  )
}
