import React, { ReactNode, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { OptionType } from 'components/Option'

import { StyledAccordionItem as S } from './AccordionItem.styles'

type TProps = {
  title: string
  value: string
  isOpen: boolean
  icons: string[]
  onClick: () => void
  form: ReactNode
}
export const AccordionItem: React.FC<TProps> = ({
  title,
  isOpen,
  onClick,
  value,
  icons,
  form,
}) => {
  const { t } = useTranslation()
  const formRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isOpen) {
      formRef.current?.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }, [isOpen])

  return (
    <S.ItemContainer isActive={isOpen}>
      <S.Option
        type={OptionType.RADIO}
        name="paymentMethod"
        value={value}
        key={value}
        onChange={onClick}
      >
        <S.TitleBlock isSelected={isOpen}>
          <S.Title>{t(title)}</S.Title>
          <S.ImageContainer>
            {icons.map((icon) => (
              <img key={icon} src={icon} alt="card" />
            ))}
          </S.ImageContainer>
        </S.TitleBlock>
      </S.Option>
      <S.ContentBlock isSelected={isOpen} ref={formRef}>
        {form}
      </S.ContentBlock>
    </S.ItemContainer>
  )
}
