import styled from 'styled-components'

export const StyledPrimerPaymentForm = {
  PayPalButton: styled.div<{ isHidden: boolean }>`
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    z-index: 14;
    height: 55px;

    &:active {
      opacity: 0.7;
    }

    ${({ isHidden }) => (isHidden ? 'display: none;' : 'display: block;')}
  `,
}
