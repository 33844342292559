import { PrimaryGoalValue } from 'value-constants'

export const QUESTION =
  'What challenges have you faced on your weight loss journey?'

const OPTION_VALUES = {
  OLD_FOOD_HABITS: 'old_food_habits',
  FOOD_TASTE: 'food_taste',
  FEELING_HUNGRY: 'feeling_hungry',
  LONG_TO_SEE_RESULTS: 'long_to_see_results',
}

export const TITLE_MAP = {
  [PrimaryGoalValue.LOSE_WEIGHT]: 'onboarding.challengesYouFaced.titleV1',
  [PrimaryGoalValue.GET_FIT]: 'onboarding.challengesYouFaced.titleV2',
  [PrimaryGoalValue.BUILD_HEALTHY_HABITS]:
    'onboarding.challengesYouFaced.titleV3',
}
export const MENS_TITLE_MAP = {
  [PrimaryGoalValue.GET_HEALTHY]: 'onboarding.challengesYouFaced.titleV4',
  [PrimaryGoalValue.LOSE_WEIGHT]: 'onboarding.challengesYouFaced.titleV1',
}

export const ANSWER_OPTIONS = [
  {
    value: OPTION_VALUES.OLD_FOOD_HABITS,
    title: 'onboarding.challengesYouFaced.options.oldFoodHabits',
  },
  {
    value: OPTION_VALUES.FOOD_TASTE,
    title: 'onboarding.challengesYouFaced.options.foodTaste',
  },
  {
    value: OPTION_VALUES.FEELING_HUNGRY,
    title: 'onboarding.challengesYouFaced.options.feelingHungry',
  },
  {
    value: OPTION_VALUES.LONG_TO_SEE_RESULTS,
    title: 'onboarding.challengesYouFaced.options.longToSeeResults',
  },
]
