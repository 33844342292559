import { ActivityDuringWorkValue } from 'value-constants'

import activeFemaleIcon from 'assets/images/active-female.png'
import activeMaleIcon from 'assets/images/active-male.png'
import normalFemaleIcon from 'assets/images/normal-female.png'
import normalMaleIcon from 'assets/images/normal-male-2.png'
import passiveFemaleIcon from 'assets/images/passive-female-2.png'
import passiveMaleIcon from 'assets/images/passive-male-2.png'

import { Gender } from 'root-constants'

export const OPTION_IMAGES = {
  [Gender.MALE]: {
    [ActivityDuringWorkValue.PASSIVE]: passiveMaleIcon,
    [ActivityDuringWorkValue.ACTIVE]: activeMaleIcon,
    [ActivityDuringWorkValue.NORMAL]: normalMaleIcon,
  },
  [Gender.FEMALE]: {
    [ActivityDuringWorkValue.PASSIVE]: passiveFemaleIcon,
    [ActivityDuringWorkValue.ACTIVE]: activeFemaleIcon,
    [ActivityDuringWorkValue.NORMAL]: normalFemaleIcon,
  },
}
