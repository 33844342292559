import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

type TValueProps = {
  labels: [string, string]
  checked?: boolean
}

export const StyledKitToggle = {
  Wrapper: styled.label`
    background-color: ${Color.GREY_130};
    border-radius: 16px;
    cursor: pointer;
    display: inline-block;
    font-size: 13px;
    font-weight: 600;
    height: 48px;
    letter-spacing: 0.5px;
    line-height: 16px;
    margin-bottom: 24px;
    position: relative;
    text-transform: uppercase;
    width: 160px;
    -webkit-tap-highlight-color: transparent;
  `,
  Input: styled.input`
    height: 0;
    margin: 0;
    opacity: 0;
    width: 0;

    &:checked {
      & + span {
        left: 50%;
      }
    }
  `,
  Toggle: styled.span`
    background-color: ${Color.GREEN_300};
    border-radius: 12px;
    bottom: 8px;
    height: 32px;
    left: 8px;
    position: absolute;
    right: 8px;
    top: 8px;
    transition: 0.2s;
    width: calc(50% - 8px);
  `,
  Value: styled.span<TValueProps>`
    &:before,
    &:after {
      align-items: center;
      color: ${Color.GREY_100};
      display: flex;
      height: 100%;
      justify-content: center;
      left: 50%;
      position: absolute;
      top: 0;
      transition: color 0.2s;
      width: calc(50% - 8px);
      z-index: 1;
    }
    &:after {
      content: '${({ labels }) => labels[1]}';
    }
    &:before {
      content: '${({ labels }) => labels[0]}';
      color: ${Color.WHITE};
      left: 8px;
    }
    ${({ checked }) =>
      checked &&
      css`
        &:before {
          color: ${Color.GREY_100};
        }

        &:after {
          color: ${Color.WHITE};
        }
      `};
  `,
}
