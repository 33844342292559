import React, { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { AgeRangeValue } from 'value-constants'

import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'

import {
  selectAgeRange,
  selectCurrentUserGender,
  selectCurrentUserPrimaryGoal,
} from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Language } from 'root-constants'

import { StyledPerfectSolutionV3 as S } from './PerfectSolutionV3.styles'
import { GENDER_MAP, PERFECT_SOLUTION_MAP_V3 } from './constants'

export const PerfectSolutionV3: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const ageRange = useSelector(selectAgeRange)
  const goal = useSelector(selectCurrentUserPrimaryGoal)
  const gender = useSelector(selectCurrentUserGender)

  const { imgSrc, firstText, secondText, ages } = useMemo(
    () => PERFECT_SOLUTION_MAP_V3[goal],
    [goal],
  )

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.perfectSolution.title', { lng: Language.EN }),
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  return (
    <KitPageContainer hasContinueButton onContinueButtonClick={handleNextClick}>
      <S.ImageContainer>
        <img src={imgSrc[gender]} alt={gender} />
      </S.ImageContainer>
      <PageTitle textAlign="left" marginBottom={16}>
        {t('onboarding.perfectSolution.title')}
      </PageTitle>
      <S.Text>
        <Trans
          i18nKey={firstText}
          values={{
            age: t(ages[ageRange]) || t(ages[AgeRangeValue.AR_45_49]),
            gender: t(GENDER_MAP[gender]),
          }}
          components={{ span: <span /> }}
        />
      </S.Text>

      <S.Text>
        <Trans i18nKey={secondText} />
      </S.Text>
    </KitPageContainer>
  )
}
