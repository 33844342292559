import React from 'react'
import { useTranslation } from 'react-i18next'

import { OuterLink } from 'components/OuterLink'

import { eventLogger } from 'services/eventLogger.service'

import { AppLinks } from 'root-constants'

export const PrivacyPolicyLink: React.FC = () => {
  const { t } = useTranslation()

  return (
    <OuterLink
      href={AppLinks.PP_LINK}
      onClick={() => eventLogger.logPrivacyPolicyClicked()}
    >
      {t('commonComponents.privacyPolicy')}
    </OuterLink>
  )
}
