import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { OptionType } from 'components/Option'

import { selectLanguage } from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { useNextStep } from 'hooks/useNextStep'

import { TBuilderOptionProps, TPageProps } from 'models/common.model'

import { CHECKBOX_CUSTOM_VALUES } from 'root-constants'

export const useDynamicPage = ({ pageId, nextPagePath }: TPageProps) => {
  const language = useSelector(selectLanguage)
  const { currentPageIndex, steps } = useGetPageInfo()
  const [answers, setAnswers] = useState<string[]>([])

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const {
    optionType = OptionType.RADIO,
    customPageId,
    title = '',
    subtitle,
    buttonText,
    optionData,
  } = useMemo(() => {
    const dynamicOBConfig = steps[currentPageIndex].onboardingDynamicConfig

    return {
      optionType: dynamicOBConfig?.[language]?.optionType,
      customPageId: dynamicOBConfig?.[language]?.customPageId,
      title: dynamicOBConfig?.[language]?.title,
      subtitle: dynamicOBConfig?.[language]?.subtitle,
      buttonText: dynamicOBConfig?.[language]?.buttonText,
      optionData: dynamicOBConfig?.[language]?.optionData,
    }
  }, [language, currentPageIndex, steps])

  const handleContinue = useNextStep({
    pageId: customPageId || pageId,
    question: title,
    nextPagePath,
  })

  const handleSingleChoice = useCallback(
    (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
    [handleChange, handleContinue, setIsAnswersDisabled],
  )

  const handleMultipleChoice = useCallback(
    (value: string, isChecked: boolean) => {
      if (isChecked) {
        setAnswers([...answers, value])
        return
      }

      const newValues = answers.filter((item) => item !== value)
      setAnswers(newValues)
    },
    [answers],
  )

  const handleCustomChoice = useCallback(
    (value: string, isChecked: boolean) => {
      if (isChecked) {
        CHECKBOX_CUSTOM_VALUES.includes(value)
          ? setAnswers([value])
          : setAnswers((prevState) =>
              [...prevState, value].filter(
                (answer) => !CHECKBOX_CUSTOM_VALUES.includes(answer),
              ),
            )
        return
      }

      setAnswers((prevState) => [...prevState].filter((item) => item !== value))
    },
    [],
  )

  const isCustomChoice = useMemo(
    () =>
      optionData
        ?.map((data) => data.value)
        .some((value) => CHECKBOX_CUSTOM_VALUES.includes(value)),
    [optionData],
  )

  const getHandler = useMemo(() => {
    if (optionType === OptionType.RADIO) {
      return handleSingleChoice
    }
    if (optionType === OptionType.CHECKBOX && !isCustomChoice) {
      return handleMultipleChoice
    }

    return handleCustomChoice
  }, [
    optionType,
    handleMultipleChoice,
    handleSingleChoice,
    handleCustomChoice,
    isCustomChoice,
  ])

  const optionProps: TBuilderOptionProps = useMemo(
    () => ({
      name: pageId,
      type: optionType,
      onChange: getHandler,
    }),
    [optionType, pageId, getHandler],
  )

  return {
    title,
    subtitle,
    buttonText,
    optionType,
    optionData,
    optionProps,
    answers,
    isAnswersDisabled,
    handleContinue,
  }
}
