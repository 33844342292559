import React, { FC, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { PageContainer } from 'components/PageContainer'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import checkIcon from 'assets/images/check-benefits.png'
import banner from 'assets/images/improvements-banner.png'

import { Language } from 'root-constants'

import { StyledImprovements as S } from './Improvements.styles'
import { IMPROVEMENTS_LIST } from './constants'

export const ImprovementsV1: FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.improvementsV1.title', { lng: Language.EN }),
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  return (
    <PageContainer>
      <S.Banner>
        <img src={banner} alt="improvements-banner" />
      </S.Banner>
      <S.Title>{t('onboarding.improvementsV1.title')}</S.Title>
      <S.Subtitle>{t('onboarding.improvementsV1.subtitle')}</S.Subtitle>
      <S.List>
        {IMPROVEMENTS_LIST.map((item) => (
          <S.Item key={item}>
            <S.CheckIcon>
              <img src={checkIcon} alt="check-icon" />
            </S.CheckIcon>
            <S.ItemText>
              <Trans i18nKey={item} />
            </S.ItemText>
          </S.Item>
        ))}
      </S.List>
      <StickyButtonContainer>
        <Button type="button" onClick={handleNextClick}>
          {t('actions.soundsGood')}
        </Button>
      </StickyButtonContainer>
    </PageContainer>
  )
}
