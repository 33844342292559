import React, { useMemo } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { KitPageContainer } from 'components/PageContainer'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import imageFemale from 'assets/images/users-female-choice.png'
import imageMale from 'assets/images/users-male-choice.png'

import { Gender } from 'root-constants'

import { StyledUsersChoiceV5 as S } from './UsersChoiceV5.styles'
import { QUESTION } from './constants'

export const UsersChoiceV5: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const gender = useSelector(selectCurrentUserGender)

  const isFemale = useMemo(() => gender === Gender.FEMALE, [gender])

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  return (
    <KitPageContainer
      hasContinueButton
      onContinueButtonClick={() => handleContinue('')}
    >
      <S.Title>
        <Trans i18nKey="onboarding.usersChoiceV5.title" />
      </S.Title>
      <S.Image>
        <img src={isFemale ? imageFemale : imageMale} alt="banner" />
      </S.Image>
    </KitPageContainer>
  )
}
