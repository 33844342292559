import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useDelayedAnimationPlaying } from 'components/LoseWeightGraph/hooks/useDelayedAnimationPlaying'

import { selectLanguage } from 'root-redux/selects/common'

import { CDN_FOLDER_LINK } from 'root-constants'

import { StyledPresaleGraph as S } from './PresaleGraph.styles'
import { ANIMATION_PATH } from './constants'

type TProps = {
  className?: string
}
export const PresaleGraph: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()
  const locale = useSelector(selectLanguage)

  const [animationRef] = useDelayedAnimationPlaying({
    animationPath: `${CDN_FOLDER_LINK}${ANIMATION_PATH}-${locale}.json`,
  })

  return (
    <S.GraphContainer className={className}>
      <S.Title>{t('presale.graph.title')}</S.Title>
      <S.Graph ref={animationRef} />
    </S.GraphContainer>
  )
}
