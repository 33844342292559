import styled, { css } from 'styled-components'

import { Input } from 'components/Input'

import exclaimationIcon from 'assets/images/exclaimation-circle-2.png'

import { Color } from 'root-constants'

export const StyledAgeV4 = {
  InputWrapper: styled.div`
    position: relative;
    width: 100%;
    margin-bottom: 24px;
  `,
  AgeInput: styled(Input)`
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    padding: 8px 16px;
    border: 1px solid ${Color.GREY_1000};
    border-radius: 16px;
    background: ${Color.WHITE};
    ${({ isValid, value }) =>
      value &&
      !isValid &&
      css`
        border: 1px solid ${Color.RED_300};
      `};

    &:focus,
    &:active {
      & ~ span {
        display: none;
      }
    }
  `,
  Placeholder: styled.span`
    position: absolute;
    top: calc(50% + 3px);
    left: calc(50%);
    transform: translateX(-50%);
    height: 3px;
    width: 13px;
    background-color: rgba(0, 0, 0, 0.5);
    pointer-events: none;
  `,
  ErrorMessage: styled.p`
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    padding-left: 36px;
    margin-top: 4px;
    color: ${Color.RED_300};
    background-image: url(${exclaimationIcon});
    background-size: 16px;
    background-repeat: no-repeat;
    background-position-x: 16px;
  `,
  InfoContainer: styled.div`
    background: ${Color.GREY_900};
    border-radius: 16px;
    column-gap: 8px;
    display: flex;
    margin-bottom: 24px;
    padding: 16px;
  `,
  InfoImage: styled.div`
    aspect-ratio: 1 / 1;
    min-width: 24px;
    height: 24px;
  `,
  Info: styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 6px;
  `,
  InfoTitle: styled.h4`
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    color: #000;
  `,
  InfoText: styled.p`
    font-size: 14px;
    line-height: 18px;
  `,
}
