import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Spinner } from 'components/Spinner'

import {
  getSubscriptionListAction,
  setScreenNameAction,
} from 'root-redux/actions/common'
import { selectSubscriptionList } from 'root-redux/selects/common'
import { TAppDispatch } from 'root-redux/store/store'

import { useVatInfo } from 'hooks/useHasVatInfo'
import { usePayment } from 'hooks/usePayment'
import {
  ViewportScrollPercentage,
  useViewportValue,
} from 'hooks/useViewportValue'

import { createProductId } from 'helpers/createProductId'

import {
  CustomerReviews,
  Disclaimer,
  FAQList,
  FeaturedBlock,
  IntroMedia,
  MoneyBackGuarantee,
  PhotoResult,
  PlanHighlights,
  SecurityInfo,
  SubscriptionsBlock,
} from 'modules/purchase/components'
import { useDynamicPaywallConfig } from 'modules/purchase/hooks/useDynamicPaywallConfig'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import {
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
} from 'root-constants'

import { OnboardingButtonText, PURCHASE_OFFSET_TOP } from '../constants'
import { StyledPurchaseVariant1 as S } from './PurchaseVariant1.styles'

export const PurchaseVariant1: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()

  const subscriptions = useSelector(selectSubscriptionList)

  const hasVatInfo = useVatInfo()
  const viewportValue = useViewportValue()
  const { title, subtitle, buttonText } = useDynamicPaywallConfig()
  const { hasPrices, handleShowPayment, handleAmplitudePaymentEvents } =
    usePayment()

  const elemForComparisonRef = useRef<HTMLHeadingElement>(null)
  const firstSubscriptionsRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    dispatch(setScreenNameAction(ScreenName.ONBOARDING))

    dispatch(
      getSubscriptionListAction(
        SubscriptionListType.PURCHASE,
        hasVatInfo ? SubscriptionTags.TAX : SubscriptionTags.NO_TAX,
      ),
    )
  }, [dispatch, hasVatInfo])

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(({ mainPrices }) =>
        createProductId({
          periodName: mainPrices.periodName,
          periodQuantity: mainPrices.periodQuantity,
          price: mainPrices.fullPrice,
        }),
      ),
      screenName: ScreenName.ONBOARDING,
    })
  }, [subscriptions])

  useEffect(() => {
    if (viewportValue > ViewportScrollPercentage.V_0) {
      eventLogger.logScrollToBottom(viewportValue, ScreenName.ONBOARDING)
    }
  }, [viewportValue])

  const handleIntroMediaButtonClick = useCallback(
    (event: SyntheticEvent<HTMLButtonElement>) => {
      const top = firstSubscriptionsRef.current?.offsetTop

      if (top) {
        document.body.scrollTo(0, top - PURCHASE_OFFSET_TOP)
      }

      handleAmplitudePaymentEvents(event.target as HTMLButtonElement)
    },
    [handleAmplitudePaymentEvents],
  )

  const handleGetPlan = useCallback(
    (event: SyntheticEvent<HTMLButtonElement>) => {
      handleShowPayment()
      handleAmplitudePaymentEvents(event.target as HTMLButtonElement)

      goTo(nextPagePath)
    },
    [handleAmplitudePaymentEvents, handleShowPayment, nextPagePath],
  )

  return !hasPrices ? (
    <Spinner />
  ) : (
    <>
      <S.Wrapper>
        <S.ProgramPlanContainer>
          <S.Title>{title || <Trans i18nKey="purchase.title" />}</S.Title>
          <S.Subtitle>
            {subtitle || <Trans i18nKey="purchase.subtitle" />}
          </S.Subtitle>
          <IntroMedia
            elemForComparisonRef={elemForComparisonRef}
            data-button-number="1"
            data-button-text={OnboardingButtonText.GET_PLAN_UPPER}
            onButtonClick={handleIntroMediaButtonClick}
          />
          <PlanHighlights titleElementRef={elemForComparisonRef} />
        </S.ProgramPlanContainer>
        <SubscriptionsBlock ref={firstSubscriptionsRef} />

        <S.Button
          type="button"
          data-button-number="2"
          data-button-text={OnboardingButtonText.GET_PLAN_MIDDLE}
          onClick={handleGetPlan}
        >
          {buttonText || t('actions.getMyPlan')}
        </S.Button>

        <FeaturedBlock />
        <MoneyBackGuarantee />
        <SecurityInfo />
      </S.Wrapper>
      <S.CustomerReviewsContainer>
        <PhotoResult />
        <FAQList />
        <CustomerReviews />
        <S.Button
          type="button"
          data-button-number="3"
          data-button-text={OnboardingButtonText.GET_PLAN_BOTTOM}
          onClick={handleGetPlan}
        >
          {buttonText || t('actions.getMyPlan')}
        </S.Button>
        <Disclaimer />
      </S.CustomerReviewsContainer>
    </>
  )
}
