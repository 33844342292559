import helen from 'assets/images/helen-mason.png'
import margaret from 'assets/images/margaret-wilson.png'
import robert from 'assets/images/robert-martinez.png'

export const TEXT_REVIEWS = [
  {
    authorName: 'purchase.customerReviewsV5.firstReview.name',
    authorImg: margaret,
    reviewText: 'purchase.customerReviewsV5.firstReview.text',
  },
  {
    authorName: 'purchase.customerReviewsV5.secondReview.name',
    authorImg: robert,
    reviewText: 'purchase.customerReviewsV5.secondReview.text',
  },
  {
    authorName: 'purchase.customerReviewsV5.thirdReview.name',
    authorImg: helen,
    reviewText: 'purchase.customerReviewsV5.thirdReview.text',
  },
]
