import { useEffect } from 'react'
import { registerLocale } from 'react-datepicker'
import { useDispatch } from 'react-redux'

import 'dayjs/locale/es'

import { setLanguageAction } from 'root-redux/actions/common'

import {
  DATEPICKER_SUPPORTED_LOCALES,
  INITIAL_LANGUAGE,
  LANG_QUERY_PARAM,
  Language,
  SUPPORTED_LOCALES,
} from 'root-constants'

export const useLanguage = (): void => {
  const { search } = document.location
  const dispatch = useDispatch()

  useEffect(() => {
    const URLParams = new URLSearchParams(search)
    const langQueryParam = URLParams.get(LANG_QUERY_PARAM) || ''

    if (!SUPPORTED_LOCALES.includes(langQueryParam)) {
      dispatch(setLanguageAction(INITIAL_LANGUAGE))
      registerLocale(
        `${INITIAL_LANGUAGE}`,
        DATEPICKER_SUPPORTED_LOCALES[INITIAL_LANGUAGE],
      )
    } else {
      dispatch(setLanguageAction(langQueryParam))
      registerLocale(
        `${langQueryParam}`,
        DATEPICKER_SUPPORTED_LOCALES[langQueryParam as Language],
      )
    }
  }, [dispatch, search])
}
