import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

const commonContainerStyles = css`
  display: flex;
  justify-content: space-between;
  column-gap: 16px;
`

export const StyledCheckoutSummaryV2 = {
  DescriptionContainer: styled.div`
    ${commonContainerStyles};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 0 0 8px 0;
  `,
  PlanLabel: styled.span`
    text-align: left;
  `,
  PlanPrice: styled.span`
    text-align: right;
  `,
  TotalContainer: styled.div<{ isMexican?: boolean }>`
    ${commonContainerStyles};
    font-size: ${({ isMexican }) => (isMexican ? 15 : 16)}px;
    line-height: 24px;
    padding: 8px 0 8px 0;
    border-top: 1px solid ${Color.GREY_300};
    border-bottom: 1px solid ${Color.GREY_300};
  `,
  TotalLabel: styled.span`
    text-align: left;
    font-weight: 700;
  `,
  TotalPrice: styled.span`
    text-align: right;

    span {
      color: #56a80f;
      font-weight: 800;
      padding: 0 6px 0 0;
    }
  `,
}
