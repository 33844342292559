import styled from 'styled-components'

import { Container } from 'components/Container'
import { PageTitle } from 'components/PageTitle'

import mapImg from 'assets/images/map.png'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledGoodHandsV2 = {
  Wrapper: styled.div`
    padding: 10px 0 24px;
    background-image: url(${mapImg});
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top center;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      background-size: 360px auto;
    }
  `,
  Container: styled(Container)`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Title: styled(PageTitle)`
    margin-bottom: 262px;
  `,
  Subtitle: styled.p`
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 89px;
  `,
  Count: styled.div`
    font-weight: 700;
    font-size: 32px;
    line-height: 30px;
    color: #746be6;
    margin-bottom: 16px;
    padding: 15px 22px;
    background: ${Color.WHITE};
    box-shadow: 0 8px 20px rgba(17, 30, 23, 0.1);
    border-radius: 16px;
  `,
}
