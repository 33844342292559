import React from 'react'
import { useTranslation } from 'react-i18next'

import { StyledWhatYouGetV1 as S } from './WhatYouGet.styles'
import { ITEMS_MAP } from './constants'

type TProps = {
  className?: string
}

export const WhatYouGetV1: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()

  return (
    <div className={className}>
      <S.Title>{t('purchase.whatYouGetV1.title')}</S.Title>
      <ul>
        {ITEMS_MAP.map(({ icon, title, text }) => (
          <S.ListItem key={title} bulletSrc={icon}>
            <div>
              <S.ItemTitle>{t(title)}</S.ItemTitle>
              <S.ItemText>{t(text)}</S.ItemText>
            </div>
          </S.ListItem>
        ))}
      </ul>
    </div>
  )
}
