import styled from 'styled-components'

import { PageTitle } from 'components/PageTitle'

import { Color } from 'root-constants'

export const StyledBurnCalories = {
  Title: styled(PageTitle)`
    line-height: 30px;
    margin-bottom: 16px;
  `,
  Banner: styled.div`
    aspect-ratio: 1 / 1;
    margin-bottom: 16px;
    width: calc(100% - 36px);
  `,
  Info: styled.h2`
    color: ${Color.GREY_100};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 34px;
    text-align: center;

    strong {
      font-weight: 700;
    }
  `,
}
