import React from 'react'

import { StyledStickyButtonContainer as S } from './StickyButtonContainer.styles'

type TProps = {
  children: React.ReactNode
  className?: string
}

export const StickyButtonContainer: React.FC<TProps> = ({
  children,
  className,
}) => <S.Wrapper className={className}>{children}</S.Wrapper>
