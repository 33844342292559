import styled from 'styled-components'

import { Button } from 'components/Button'

import { Color } from 'root-constants'

interface IOverlayProps {
  open: boolean
}

export const StyledEventDate = {
  FixedButtonContainer: styled.div`
    width: 100%;
    max-width: 360px;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
      rgba(255, 255, 255, 0) 0.25%,
      rgb(255, 255, 255) 26.14%
    );
  `,
  Overlay: styled.div<IOverlayProps>`
    position: absolute;
    top: 44px;
    left: 0;
    width: 100dvw;
    height: calc(100dvh - 44px);
    z-index: 4;

    display: ${({ open }) => (open ? 'block' : 'none')};
  `,
  SubTitle: styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
  `,
  SkipButton: styled(Button)`
    width: auto;
    display: inline-block;
    border: none;
    background-color: inherit;
    padding: 19px 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;
    color: ${Color.DISABLED};

    &:disabled {
      background-color: inherit;
    }
  `,
}

export const StyledEventDateV2 = {
  Subtitle: styled.p`
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: ${Color.GREY_101};
  `,
  Overlay: styled.div<IOverlayProps>`
    position: absolute;
    top: 44px;
    left: 0;
    width: 100dvw;
    height: calc(100dvh - 44px);
    z-index: 12;

    display: ${({ open }) => (open ? 'block' : 'none')};
  `,
}
