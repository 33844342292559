import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledFooter = {
  Footer: styled.footer`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: ${Color.DISABLED};
  `,
  List: styled.ul`
    display: flex;
    justify-content: center;
    margin: 3px 0;
  `,
  ListItem: styled.li`
    a {
      color: ${Color.DISABLED};
    }
  `,
  ListItemSeparator: styled.li`
    padding: 0 5px;
  `,
}
