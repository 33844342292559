import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  selectCurrentAge,
  selectCurrentUserGender,
  selectLanguage,
} from 'root-redux/selects/common'

import { roundToTenth } from 'helpers/numberHelper'

import {
  IMAGE_PATH_DREAM_BODY,
  IMAGE_PATH_SELF_CARE,
  UPSELL_BENEFITS,
} from 'modules/purchase/components/UpsellBenefits/constants'
import { selectSubscription } from 'modules/purchase/redux/selects'

import { CDN_FOLDER_LINK, UpsellProduct } from 'root-constants'

import { StyledUpsellBenefits as S } from './UpsellBenefits.styles'

export const UpsellBenefits: React.FC = () => {
  const { t } = useTranslation()

  const subscription = useSelector(selectSubscription)
  const age = +useSelector(selectCurrentAge)
  const gender = useSelector(selectCurrentUserGender)
  const language = useSelector(selectLanguage)

  const isBoth = useMemo(
    () => subscription?.product === UpsellProduct.BOTH,
    [subscription],
  )

  const isFitness = useMemo(
    () => subscription?.product === UpsellProduct.FITNESS,
    [subscription],
  )

  const isSelfCare = useMemo(
    () => subscription?.product === UpsellProduct.SELF_CARE,
    [subscription],
  )

  const dreamBodySrc = useMemo(
    () => `${CDN_FOLDER_LINK}${IMAGE_PATH_DREAM_BODY}-${language}.png`,
    [language],
  )

  const selfCareSrc = useMemo(
    () => `${CDN_FOLDER_LINK}${IMAGE_PATH_SELF_CARE}-${language}.png`,
    [language],
  )

  return (
    <S.Root>
      <S.Wrapper>
        <S.Title>{t('upsell.benefits.title')}</S.Title>
        <S.List>
          {UPSELL_BENEFITS.map(({ id, description }) => (
            <S.ListItem key={id}>{t(description)}</S.ListItem>
          ))}
        </S.List>
      </S.Wrapper>

      <S.Details>
        <S.DetailsTitle>{t('upsell.benefits.details.title')}</S.DetailsTitle>
        {(isBoth || isFitness) && (
          <S.Detailed>
            <S.DetailedPicture src={dreamBodySrc} alt="dreamBody" />
            <S.DetailedInfo>
              <S.DetailedTitle>
                {t('upsell.benefits.details.title1')}
              </S.DetailedTitle>
              <S.DetailedDescription>
                {t('upsell.benefits.details.description1', {
                  agePeriod: roundToTenth(age),
                })}
              </S.DetailedDescription>
            </S.DetailedInfo>
          </S.Detailed>
        )}

        {(isBoth || isSelfCare) && (
          <S.Detailed>
            <S.DetailedPicture src={selfCareSrc} alt="" />
            <S.DetailedInfo>
              <S.DetailedTitle>
                {t('upsell.benefits.details.title2')}
              </S.DetailedTitle>
              <S.DetailedDescription>
                {t('upsell.benefits.details.description2', { context: gender })}
              </S.DetailedDescription>
            </S.DetailedInfo>
          </S.Detailed>
        )}
      </S.Details>
    </S.Root>
  )
}
