import { MotivationValue } from 'value-constants'

export const QUESTION = 'How motivated are you to burn off excess pounds?'
export const QUESTION_V2 =
  'Do you have an important event coming up that will motivate you to lose weight?'

export const OPTION_VALUES = {
  SEE_THE_BUZZ: 'see_the_buzz',
  READY_TO_TRY_LOSE: 'ready_to_try_lose',
  DETERMINED_TO_DROP: 'determined_to_drop',
}

export const ANSWER_OPTIONS = [
  {
    value: MotivationValue.VACATION,
    title: 'onboarding.motivation.otherOptions.vacation',
  },
  {
    value: MotivationValue.WEDDING,
    title: 'onboarding.motivation.otherOptions.wedding',
  },
  {
    value: MotivationValue.SPORTS,
    title: 'onboarding.motivation.otherOptions.sports',
  },
  {
    value: MotivationValue.SUMMER,
    title: 'onboarding.motivation.otherOptions.summer',
  },
  {
    value: MotivationValue.REUNION,
    title: 'onboarding.motivation.otherOptions.reunion',
  },
  {
    value: MotivationValue.FAMILY,
    title: 'onboarding.motivation.otherOptions.family',
  },
  {
    value: MotivationValue.HOLIDAY,
    title: 'onboarding.motivation.otherOptions.holiday',
  },
  {
    value: MotivationValue.OTHER,
    title: 'onboarding.motivation.otherOptions.other',
  },
  {
    value: MotivationValue.NO,
    title: 'onboarding.motivation.otherOptions.no',
  },
]
