import styled from 'styled-components'

import { Color } from 'root-constants'

type TProps = {
  backgroundColor?: string
  percentage?: number
}

export const StyledPersonalSummaryV2 = {
  Root: styled.div`
    margin: 8px auto 32px;
    max-width: 464px;
  `,
  Title: styled.h2`
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 24px;
    text-align: center;
  `,
  Header: styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
    align-items: flex-start;
  `,
  HeaderContent: styled.div`
    margin-left: 5px;
  `,
  HeaderTitle: styled.h3`
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 4px;
  `,
  HeaderSubtitle: styled.p`
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  `,
  HeaderLabel: styled.div<TProps>`
    font-weight: 700;
    font-size: 9px;
    line-height: 16px;
    padding: 2px 6px;
    border-radius: 6px;
    text-transform: uppercase;
    color: #fff;
    background-color: ${({ backgroundColor }) =>
      backgroundColor && backgroundColor};
  `,
  Content: styled.div`
    background: ${Color.WHITE};
    box-shadow: 0 12px 32px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    padding: 16px;
  `,
  RangeTrack: styled.div`
    width: 100%;
    height: 8px;
    position: relative;
    background: linear-gradient(
      90deg,
      #fe8900 0%,
      #f6e48d 13.54%,
      #6fd09a 29.17%,
      #6fd09a 49.52%,
      #6fd09a 69.79%,
      #f6e48d 85.94%,
      #dd3b17 100%
    );
    border-radius: 26px;
    margin-bottom: 10px;
  `,
  RangeThumb: styled.div<TProps>`
    width: 28px;
    height: 28px;
    border: 9px solid ${Color.WHITE};
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.16);
    border-radius: 50%;
    position: absolute;
    top: -10px;
    left: ${({ percentage }) => percentage && percentage}%;
    transform: translateX(-50%);
    background-color: ${Color.DARK_BLUE};
  `,
  RangeValue: styled.div<TProps>`
    position: absolute;
    background: ${Color.GREY_100};
    border-radius: 32px;
    padding: 5px 9px;
    color: ${Color.WHITE};
    font-weight: 900;
    font-size: 12px;
    line-height: 14px;
    top: -44px;
    left: ${({ percentage }) => percentage && percentage}%;
    transform: translateX(calc(-50%));

    &::after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      bottom: -4px;
      left: calc(50% - 3px);
      border-width: 4px 4px 0 4px;
      border-color: ${Color.GREY_100} transparent transparent transparent;
    }
  `,
  RangeThresholds: styled.div`
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #131d30;
  `,
}
