import { useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'

import { selectUserOnboardingEmail } from 'root-redux/selects/user'

export const useTiktokPixelInitialization = (tiktokPixelId: string): void => {
  const email = useSelector(selectUserOnboardingEmail)

  useLayoutEffect(() => {
    window.ttq.load(tiktokPixelId)
    window.ttq.identify({ email })
    window.ttq.page()
  }, [email, tiktokPixelId])
}
