export const QUESTION =
  'Have any life events contributed to your weight gain in recent years?'

export const OPTION_VALUES = {
  COVID: 'covid',
  OVERWHELMING: 'overwhelming',
  WORK_RELATED: 'work_related',
  PREGNANT: 'pregnant',
  STRESS: 'stress',
  HORMONAL: 'hormonal',
  MEDICATION: 'medication',
  MARRIAGE: 'marriage',
  UNSUCCESSFUL: 'unsuccessful',
  INJURY: 'injury',
  NONE: 'none',
}

export const ANSWER_OPTIONS = [
  {
    value: OPTION_VALUES.COVID,
    title: 'onboarding.weightGain.options.covid',
  },
  {
    value: OPTION_VALUES.OVERWHELMING,
    title: 'onboarding.weightGain.options.overwhelming',
  },
  {
    value: OPTION_VALUES.WORK_RELATED,
    title: 'onboarding.weightGain.options.workRelated',
  },
  {
    value: OPTION_VALUES.PREGNANT,
    title: 'onboarding.weightGain.options.pregnant',
  },
  {
    value: OPTION_VALUES.STRESS,
    title: 'onboarding.weightGain.options.stress',
  },
  {
    value: OPTION_VALUES.HORMONAL,
    title: 'onboarding.weightGain.options.hormonal',
  },
  {
    value: OPTION_VALUES.MEDICATION,
    title: 'onboarding.weightGain.options.medication',
  },
  {
    value: OPTION_VALUES.MARRIAGE,
    title: 'onboarding.weightGain.options.marriage',
  },
  {
    value: OPTION_VALUES.UNSUCCESSFUL,
    title: 'onboarding.weightGain.options.unsuccessful',
  },
  {
    value: OPTION_VALUES.INJURY,
    title: 'onboarding.weightGain.options.injury',
  },
]
