import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { AnswerWithIcon, answerWithIconTheme } from 'storybook-ui'

import { OptionType } from 'components/Option'
import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { BODY_TYPES_DATA, QUESTION } from './constants'

export const BodyTypeV4: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const userGender = useSelector(selectCurrentUserGender)
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [handleChange, handleContinue, pageId, setIsAnswersDisabled],
  )

  return (
    <KitPageContainer>
      <PageTitle marginBottom={24} textAlign="left">
        {t('onboarding.bodyType.titleV4')}
      </PageTitle>
      {BODY_TYPES_DATA.map(({ value, text, img }) => (
        <AnswerWithIcon
          key={value}
          {...optionProps}
          disabled={isAnswersDisabled}
          value={value}
          theme={answerWithIconTheme.NUTRIMATE}
          iconSrc={img[userGender]}
          iconHeight="100px"
          iconWidth="100px"
          padding="0 16px 0 8px"
          borderRadius="16px"
          height="100px"
          margin="0 0 12px"
        >
          {t(text)}
        </AnswerWithIcon>
      ))}
    </KitPageContainer>
  )
}
