import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { sendFacebookParamsAction } from 'root-redux/actions/user'
import {
  selectIsPersonalDataAllowed,
  selectUserOnboardingEmail,
} from 'root-redux/selects/user'

import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { usePayment } from 'hooks/usePayment'

import { CURRENCY_SYMBOLS, PurchaseProduct } from 'modules/purchase/constants'
import { TSubscriptionItemProps } from 'modules/purchase/types'

import { goTo } from 'browser-history'
import { Color } from 'root-constants'

import { StyledKitEmailSubscriptionItemV1 as S } from './KitEmailSubscriptionItemV1.styles'

export const KitEmailSubscriptionItemV1: React.FC<TSubscriptionItemProps> = ({
  subscription: { product, mainPrices, trialPrices, currency, introDiff },
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { search } = useLocation()

  const email = useSelector(selectUserOnboardingEmail)
  const isPersonalDataAllowed = useSelector(selectIsPersonalDataAllowed)

  const { currentPaymentPageId } = useGetPageInfo()
  const { handleShowPayment } = usePayment()

  const period = useMemo(
    () =>
      product === PurchaseProduct.SEVEN_DAY
        ? t('purchase.subscription.weeklyPeriod', {
            periodQuantity: trialPrices.durationDays,
          })
        : t('purchase.subscription.monthlyPeriod', {
            periodQuantity: mainPrices.periodQuantity,
          }),
    [t, product, trialPrices, mainPrices],
  )

  const trialCurrentPrice = useMemo(
    () => trialPrices.fullPrice.toFixed(2),
    [trialPrices],
  )

  const oldPrice = useMemo(() => mainPrices.fullPrice.toFixed(2), [mainPrices])

  const discount = useMemo(
    () =>
      t('purchase.subscription.discountLabel', {
        discount: introDiff.discountPercentage,
      }),
    [introDiff, t],
  )

  const handleClick = useCallback(() => {
    dispatch(sendFacebookParamsAction())
    window.ttq.identify({ email: isPersonalDataAllowed ? email : '' })
    handleShowPayment()

    goTo(`${currentPaymentPageId}${search}`)
  }, [
    dispatch,
    isPersonalDataAllowed,
    email,
    handleShowPayment,
    currentPaymentPageId,
    search,
  ])

  return (
    <div>
      <S.PlanRow>
        <S.Period>{period}</S.Period>
        <S.Price>
          {CURRENCY_SYMBOLS[currency]}
          {trialCurrentPrice}
        </S.Price>
      </S.PlanRow>
      <S.DiscountRow>
        <S.Button
          minHeight="32px"
          padding="0 16px"
          borderRadius="16px"
          backgroundColor={Color.ORANGE_200}
          color={Color.WHITE}
          fontWeight="700"
          fontSize="16px"
          onClick={handleClick}
        >
          {discount}
        </S.Button>
        <S.OldPrice>
          {CURRENCY_SYMBOLS[currency]}
          {oldPrice}
        </S.OldPrice>
      </S.DiscountRow>
    </div>
  )
}
