import styled from 'styled-components'

import pageBg from 'assets/images/analyzing-bg.png'

import { Color, MediaBreakpoint } from 'root-constants'

type TTextProps = {
  isReady: boolean
  ratio: number
}

export const StyledAnalyzingAnswers = {
  ProgressBarContainer: styled.div`
    margin-top: 110px;
    padding-bottom: 80px;
  `,

  PageTitle: styled.div`
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: ${Color.WHITE};
    padding-top: 167px;
  `,
  Wrapper: styled.div`
    width: 100px;
    height: 100px;
    margin: 0 auto;
  `,

  Dot: styled.div`
    width: 50px;
    height: 10px;
  `,
  Root: styled.div`
    padding-top: 10px;
    background: url(${pageBg}) no-repeat;
    background-position: top center;
    background-size: cover;
    min-height: var(--full-height, 100%);
    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      background: url(${pageBg}) no-repeat;
      background-position: top center;
      background-size: cover;
    }
  `,
  Content: styled.div`
    position: relative;
    min-height: 136px;
  `,
  Text: styled.p<TTextProps>`
    position: relative;
    margin: 0 auto 12px auto;
    max-width: 281px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    transition: all 0.3s;
    text-align: center;
    opacity: ${({ isReady }) => (isReady ? 1 : 0)};
    transform: ${({ isReady, ratio }) =>
      isReady ? `translateY(0)` : `translateY(calc(136px - ${ratio * 32}px))`};

    &:last-child {
      margin-bottom: 0;
    }
  `,
}
