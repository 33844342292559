import React, { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button } from 'components/Button'
import { Logo } from 'components/Logo'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { selectUserMeasurementSystem } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import stars from 'assets/images/alternative-stars-v2.png'
import imgAfter from 'assets/images/feedback_man_after.png'
import imgBefore from 'assets/images/feedback_man_before.png'

import { Language, MeasurementSystem } from 'root-constants'

import { StyledFeedback as S } from './Feedback.styles'
import { LOST_WEIGHT_VALUE_MAP } from './constants'

export const Feedback: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const currentMeasurementSystem = useSelector(selectUserMeasurementSystem)

  const { name, review } = useMemo(
    () => ({
      name: t(`onboarding.feedbacks.name`, {
        lostWeight: `${LOST_WEIGHT_VALUE_MAP[currentMeasurementSystem]} ${
          currentMeasurementSystem === MeasurementSystem.METRIC
            ? t('commonComponents.kg')
            : t('commonComponents.lbs')
        }`,
      }),
      review: t(`onboarding.feedbacks.review`, {
        lostWeight: `${LOST_WEIGHT_VALUE_MAP[currentMeasurementSystem]} ${
          currentMeasurementSystem === MeasurementSystem.METRIC
            ? t('commonComponents.kilograms')
            : t('commonComponents.pounds')
        }`,
      }),
    }),
    [currentMeasurementSystem, t],
  )

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.feedbacks.title', { lng: Language.EN }),
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  return (
    <S.Wrapper>
      <S.Container>
        <Logo marginBottom={24} />
        <S.Title>
          <Trans i18nKey="onboarding.feedbacks.title" />
        </S.Title>
        <S.FeedbackContainer>
          <S.PhotosContainer>
            <img src={imgBefore} alt="before" />
            <img src={imgAfter} alt="after" />
          </S.PhotosContainer>
          <S.InfoContainer>
            <S.FeedbackText>{review}</S.FeedbackText>
            <S.FeedbackInfo>
              <S.LostWeight>{name}</S.LostWeight>
              <S.Rating>
                <img src={stars} alt="stars" />
              </S.Rating>
            </S.FeedbackInfo>
          </S.InfoContainer>
        </S.FeedbackContainer>
        <StickyButtonContainer>
          <Button type="button" onClick={handleNextClick}>
            {t('actions.continue')}
          </Button>
        </StickyButtonContainer>
      </S.Container>
    </S.Wrapper>
  )
}
