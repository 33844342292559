import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { selectAnswers } from 'root-redux/selects/common'

import { CustomPageId } from 'page-constants'
import { FloatNumberRegex } from 'root-constants'

export const useGoalWeightField = (): {
  userGoalWeight: { value: string; isValid: boolean }
  handleGoalWeightChange: (event: React.ChangeEvent<HTMLInputElement>) => void
} => {
  const userAnswersCollection = useSelector(selectAnswers)

  const [userGoalWeight, setUserGoalWeight] = useState<{
    value: string
    isValid: boolean
  }>({
    value: '',
    isValid: false,
  })

  useEffect(() => {
    setUserGoalWeight({
      value:
        userAnswersCollection?.[CustomPageId.GOAL_WEIGHT]?.toString() || '',
      isValid: !!userAnswersCollection?.[CustomPageId.GOAL_WEIGHT],
    })
  }, [userAnswersCollection])

  const handleGoalWeightChange = useCallback(
    ({ target: { value, validity } }) => {
      if (!value || FloatNumberRegex.test(value)) {
        setUserGoalWeight({
          value,
          isValid: validity.valid,
        })
      }
    },
    [],
  )

  return { userGoalWeight, handleGoalWeightChange }
}
