import React, { FC, useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectSleepingTime } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import moonSleepImg from 'assets/images/moon-sleep.png'

import { Language } from 'root-constants'

import { StyledSleepingTimeFeedback as S } from './SleepingTimeFeedback.styles'
import { FEEDBACKS, TITLE_MAP } from './constants'

export const SleepingTimeFeedbackV1: FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const sleepingTime = useSelector(selectSleepingTime)

  const title = useMemo(() => TITLE_MAP[sleepingTime], [sleepingTime])

  const handleContinue = useNextStep({
    pageId,
    question: t(title, {
      lng: Language.EN,
    }),
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  return (
    <S.PageContainer>
      <div>
        <S.ImageContainer>
          <img src={moonSleepImg} alt="moon-sleep" />
        </S.ImageContainer>

        <S.PageTitle marginBottom={24}>{t(title)}</S.PageTitle>

        <S.Info>
          <Trans i18nKey="onboarding.sleepingTimeFeedbackV1.info" />
        </S.Info>

        <S.Period>
          {FEEDBACKS.map((feedback) => (
            <S.PeriodItem key={feedback}>
              <S.Circle />
              <S.PeriodContent>
                <Trans i18nKey={feedback} />
              </S.PeriodContent>
            </S.PeriodItem>
          ))}
        </S.Period>

        <S.CallToAction>
          <Trans i18nKey="onboarding.sleepingTimeFeedbackV1.callToAction" />
        </S.CallToAction>
      </div>

      <S.StickyButtonContainer>
        <S.Button type="button" onClick={handleNextClick}>
          {t('actions.continue')}
        </S.Button>
      </S.StickyButtonContainer>
    </S.PageContainer>
  )
}
