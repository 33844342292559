import { useEffect } from 'react'

export const useLockScroll = (isModalShown: boolean): void => {
  useEffect(() => {
    const lockScroll = (e: TouchEvent) => e.preventDefault()

    if (isModalShown) {
      document.addEventListener('touchmove', lockScroll, { passive: false })
    }

    return () => {
      document.removeEventListener('touchmove', lockScroll, false)
    }
  }, [isModalShown])
}
