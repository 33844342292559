import { Gender, MeasurementSystem } from 'root-constants'

export const LOST_WEIGHT_MAP_V1 = {
  [Gender.FEMALE]: {
    [MeasurementSystem.IMPERIAL]: 30,
    [MeasurementSystem.METRIC]: 13,
  },
  [Gender.MALE]: {
    [MeasurementSystem.IMPERIAL]: 40,
    [MeasurementSystem.METRIC]: 18,
  },
}

export const LOST_WEIGHT_MAP_V2 = {
  [MeasurementSystem.IMPERIAL]: 30,
  [MeasurementSystem.METRIC]: 13,
}
export const REVIEWER_NAME = 'Emma'
