import React from 'react'
import { useTranslation } from 'react-i18next'

import { ReviewCard } from 'components/ReviewCard'

import { StyledReviews as S } from './Reviews.styles'

type TProps = {
  className?: string
  title?: string
  reviews: {
    userAvatar: string
    userName: string
    review: string
  }[]
}

export const Reviews: React.FC<TProps> = ({ className, reviews, title }) => {
  const { t } = useTranslation()

  return (
    <S.ReviewsContainer className={className}>
      {title && <S.Title>{t(title)}</S.Title>}
      <S.Carousel
        pagination={{
          clickable: true,
        }}
        slidesPerView={1}
        spaceBetween={16}
        loop
      >
        {reviews.map(({ review, userName, userAvatar }) => (
          <S.SwiperSlide key={userName}>
            <ReviewCard
              userName={userName}
              userAvatar={userAvatar}
              review={review}
            />
          </S.SwiperSlide>
        ))}
      </S.Carousel>
    </S.ReviewsContainer>
  )
}
