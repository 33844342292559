import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { IMAGE_PATH } from 'pages/questions/FastingFeedback/constants'

import { KitPageContainer } from 'components/PageContainer'

import { selectLanguage } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { CDN_FOLDER_LINK, Language } from 'root-constants'

import { FastingFeedbackV2 as S } from './FastingFeedback.styles'

export const FastingFeedbackV2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const language = useSelector(selectLanguage)

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.fastingFeedbackV2.description.paragraph1', {
      lng: Language.EN,
    }),
    nextPagePath,
  })

  const imgSrc = useMemo(
    () => `${CDN_FOLDER_LINK}${IMAGE_PATH}-${language}.png`,
    [language],
  )

  return (
    <KitPageContainer
      hasContinueButton
      onContinueButtonClick={() => handleContinue('')}
    >
      <S.ImageContainer>
        <img src={imgSrc} alt="fasting-feedback-img" />
      </S.ImageContainer>
      <S.DescriptionBlock>
        <S.Paragraph>
          <Trans i18nKey="onboarding.fastingFeedbackV2.description.paragraph1" />
        </S.Paragraph>
        <S.Paragraph>
          <Trans i18nKey="onboarding.fastingFeedbackV2.description.paragraph2" />
        </S.Paragraph>
        <S.Paragraph>
          <Trans i18nKey="onboarding.fastingFeedbackV2.description.paragraph3" />
        </S.Paragraph>
      </S.DescriptionBlock>
    </KitPageContainer>
  )
}
