import { FavouriteFoodsValue, RestrictionsValue } from 'value-constants'

import megImg from 'assets/images/meg.png'
import mikeImg from 'assets/images/mike.png'

import { Gender } from 'root-constants'

export const POSITIVE_ITEMS_MAP = {
  [FavouriteFoodsValue.MEAT]:
    'onboarding.customizeMealPlanV1.positiveItems.meat',
  [FavouriteFoodsValue.FISH]:
    'onboarding.customizeMealPlanV1.positiveItems.fish',
  [FavouriteFoodsValue.FRUITS_AND_VEGGIES]:
    'onboarding.customizeMealPlanV1.positiveItems.veggies',
  [FavouriteFoodsValue.CARBS]:
    'onboarding.customizeMealPlanV1.positiveItems.carbs',
  [FavouriteFoodsValue.CHEESE]:
    'onboarding.customizeMealPlanV1.positiveItems.cheese',
}

export const NEGATIVE_ITEMS_MAP = {
  [RestrictionsValue.NO_ANIMAL_PROTEIN]:
    'onboarding.customizeMealPlanV1.negativeItems.animalProtein',
  [RestrictionsValue.NO_LACTOSE]:
    'onboarding.customizeMealPlanV1.negativeItems.lactose',
  [RestrictionsValue.NO_GLUTEN]:
    'onboarding.customizeMealPlanV1.negativeItems.gluten',
  [RestrictionsValue.NO_PEANUTS]:
    'onboarding.customizeMealPlanV1.negativeItems.peanuts',
  [RestrictionsValue.NO_SOY]:
    'onboarding.customizeMealPlanV1.negativeItems.soy',
  [RestrictionsValue.NO_SHELLFISH]:
    'onboarding.customizeMealPlanV1.negativeItems.shellfish',
}

export const REVIEW = {
  [Gender.MALE]: {
    about: 'onboarding.customizeMealPlanV1.review.male',
    content: 'onboarding.customizeMealPlanV1.review.content',
    imageSrc: mikeImg,
  },
  [Gender.FEMALE]: {
    about: 'onboarding.customizeMealPlanV1.review.female',
    content: 'onboarding.customizeMealPlanV1.review.content',
    imageSrc: megImg,
  },
}
