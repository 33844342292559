import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { answerWithIconTheme } from 'storybook-ui'

import { KitInfoBlock } from 'components/KitInfoBlock'
import { OptionType } from 'components/Option'
import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import femaleIcon from 'assets/images/gender-female-icon.png'
import maleIcon from 'assets/images/gender-male-icon.png'
import pointUpImage from 'assets/images/point-up.png'

import { CustomPageId } from 'page-constants'
import { Gender, Language } from 'root-constants'

import { StyledGenderV5 as S } from './Gender.styles'

export const GenderV5: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId: CustomPageId.GENDER,
    question: t('onboarding.genderV5.title', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: CustomPageId.GENDER,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [handleChange, setIsAnswersDisabled, handleContinue],
  )

  return (
    <KitPageContainer>
      <PageTitle marginBottom={24}>{t('onboarding.genderV5.title')}</PageTitle>

      <S.AnswerWithIcon
        {...optionProps}
        disabled={isAnswersDisabled}
        value={Gender.FEMALE}
        theme={answerWithIconTheme.NUTRIMATE}
        iconSrc={femaleIcon}
        iconHeight="40px"
        iconMinWidth="40px"
        margin="0 0 12px 0"
      >
        {t('commonComponents.gender.female')}
      </S.AnswerWithIcon>

      <S.AnswerWithIcon
        {...optionProps}
        disabled={isAnswersDisabled}
        value={Gender.MALE}
        theme={answerWithIconTheme.NUTRIMATE}
        iconSrc={maleIcon}
        iconHeight="40px"
        iconMinWidth="40px"
        margin="0 0 24px 0"
      >
        {t('commonComponents.gender.male')}
      </S.AnswerWithIcon>

      <KitInfoBlock
        iconSrc={pointUpImage}
        title={t('onboarding.genderV5.infoTitle')}
        text={t('onboarding.genderV5.infoText')}
      />
    </KitPageContainer>
  )
}
