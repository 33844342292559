import styled from 'styled-components'

import { AnswerButton } from 'components/AnswerButton'
import { PageTitle } from 'components/PageTitle'

export const StyledPeriodAffectAppetite = {
  PageTitle: styled(PageTitle)`
    margin: 0 0 24px 0;
  `,

  AnswerButton: styled(AnswerButton)`
    height: 92px;
    padding: 0 24px;
    border-color: transparent;

    h3 {
      font-size: 18px;
      line-height: 20px;
    }
  `,
}
