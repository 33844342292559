import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledLimitedOffer = {
  Title: styled.h2`
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: ${Color.GREY_100};
    margin: 0 0 16px 0;
  `,
  ContentBlock: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 114px;
    padding: 16px;
    border-radius: 16px;
    background: radial-gradient(
      104% 760.5% at 15.36% 124%,
      #ff0031 0%,
      #ffbc14 100%
    );
  `,
  ContentColumn: styled.div`
    display: flex;
    flex-direction: column;
  `,
  ColumnTitle: styled.h4`
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: ${Color.WHITE};
    margin: 0 0 8px 0;
  `,
  Offer: styled.div`
    width: 144px;
    background-color: ${Color.WHITE};
    border-radius: 8px;
    overflow: hidden;
  `,
  OldOffer: styled.div`
    background-color: ${Color.BLACK_100};
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-size: 10px;
    font-weight: 700;
    line-height: 12px;
    text-align: center;
    padding: 2px 4px;
    color: ${Color.WHITE};

    span {
      text-decoration: line-through;
      text-decoration-color: #f03434;
    }
  `,
  NewOffer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6px 0 8px;
    color: ${Color.GREY_100};
  `,
  OldPromoCode: styled.p`
    font-weight: 700;
    font-size: 8px;
    line-height: 10px;
    text-align: center;
    color: ${Color.DISABLED};
    text-decoration: line-through;
    text-decoration-color: ${Color.DISABLED};
  `,
  NewPromoCode: styled.p`
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: ${Color.BLACK_100};
  `,
  TimerBlock: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  TimerColumn: styled.div`
    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6px 8px 8px 8px;
    background-color: ${Color.WHITE};
    border-radius: 8px;

    &:first-of-type {
      margin: 0 8px 0 0;
    }
  `,
  TimerValue: styled.span`
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: ${Color.GREY_100};
    margin: 0 0 2px 0;
  `,
  TimerUnits: styled.span`
    font-weight: 600;
    font-size: 8px;
    line-height: 10px;
    color: ${Color.GREY_100};
  `,
}
