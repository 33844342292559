import styled from 'styled-components'

import attentionIcon from 'assets/images/attention-icon.png'

type TWrapperProps = {
  errorText: string
}

export const StyledErrorNotification = {
  Wrapper: styled.div<TWrapperProps>`
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    padding: 10px 16px 10px 48px;
    background-color: #ff8276;
    border-radius: 10px;
    opacity: ${({ errorText }) => (errorText ? 1 : 0)};
    transform: ${({ errorText }) =>
      errorText ? 'translate(-50%, -100%)' : 'translateX(-50%)'};
    transition: all 0.5s ease-out;
  `,
  Text: styled.p`
    position: relative;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #fdfdff;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: -30px;
      transform: translateY(-50%);
      width: 18px;
      height: 16px;
      background-image: url(${attentionIcon});
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  `,
}
