import styled from 'styled-components'
import { Swiper } from 'swiper/react'

import { Color } from 'root-constants'

export const StyledSliderWithScaledActiveSlide = {
  Carousel: styled(Swiper)`
    .swiper-wrapper {
      margin: 0 0 66px 0;
    }

    .swiper-pagination {
      bottom: 26px;

      .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        background: ${Color.WHITE};
        opacity: 1;

        &.swiper-pagination-bullet-active {
          background: ${Color.PRIMARY};
        }
      }
    }

    .swiper-slide {
      width: fit-content;
      display: flex;
      flex-direction: column;
      opacity: 0.6;
      transform: scale(0.8);
    }

    .swiper-slide-active {
      opacity: 1;
      transform: scale(1);
    }
  `,
}
