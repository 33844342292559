import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { SupportActionLink } from 'components/SupportActionLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { useVatInfo } from 'hooks/useHasVatInfo'
import { usePaymentStore } from 'hooks/usePaymentStore'

import {
  NO_TRIAL_DISCLAIMER_MAP,
  TRIAL_DISCLAIMER_MAP,
} from 'modules/purchase/components/DisclaimerV2/constants'
import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import { selectProductId } from 'modules/purchase/redux/selects'

import { StyledDisclaimerV2 as S } from './DisclaimerV2.styles'

type TProps = {
  className?: string
}

export const DisclaimerV2: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()
  const product = useSelector(selectProductId)
  const { trialCurrentPrice, fullPrice, currency } = usePaymentStore()
  const hasVatInfo = useVatInfo()

  const isSubscriptionTrial = useMemo(
    () => trialCurrentPrice !== fullPrice,
    [trialCurrentPrice, fullPrice],
  )

  const disclaimerTransKey = useMemo(
    () =>
      isSubscriptionTrial
        ? TRIAL_DISCLAIMER_MAP[product]
        : NO_TRIAL_DISCLAIMER_MAP[product],
    [isSubscriptionTrial, product],
  )

  return (
    <S.DisclaimerContainer className={className}>
      <Trans
        i18nKey={disclaimerTransKey}
        components={{ span: <span /> }}
        values={{
          price: fullPrice,
          currency: CURRENCY_SYMBOLS[currency],
          includingVatUsd: hasVatInfo ? t('purchase.includingVat') : '',
        }}
      />
      <TermsOfUseLink /> {t('purchase.contactUs')} <SupportActionLink />
    </S.DisclaimerContainer>
  )
}
