import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledSteps = {
  Container: styled.div`
    margin-bottom: 40px;
    width: 100%;
  `,
  Title: styled.h2`
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 24px;
    text-align: center;
  `,
  List: styled.ul`
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  `,
  Item: styled.li`
    display: flex;
    flex-direction: column;

    &:not(:last-of-type) {
      border-bottom: 1px solid ${Color.GREY_WHITE};
      padding-bottom: 16px;
    }
  `,
  Step: styled.p`
    color: ${Color.ORANGE};
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 12px;
    text-transform: uppercase;
  `,
  ItemTitle: styled.h3`
    color: ${Color.BLACK};
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 12px;
  `,
  ItemInfo: styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  `,
}
