import React, { FC, useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { BadHabitsValue } from 'value-constants'

import { PageContainer } from 'components/PageContainer'

import {
  selectBadHabits,
  selectCurrentUserGender,
} from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import femaleBanner from 'assets/images/bad-habits-feedback-female-banner.png'
import maleBanner from 'assets/images/bad-habits-feedback-male-banner.png'

import { Gender, Language } from 'root-constants'

import { StyledBadHabitsFeedback as S } from './BadHabitsFeedback.styles'
import { POSITIVE_ITEMS } from './constants'

export const BadHabitsFeedbackV1: FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const badHabits = useSelector(selectBadHabits)
  const gender = useSelector(selectCurrentUserGender)

  const isNoneBadHabits = useMemo(
    () => badHabits.includes(BadHabitsValue.NONE),
    [badHabits],
  )

  const title = useMemo(
    () =>
      isNoneBadHabits
        ? 'onboarding.badHabitsFeedbackV1.positive.title'
        : 'onboarding.badHabitsFeedbackV1.negative.title',
    [isNoneBadHabits],
  )

  const handleContinue = useNextStep({
    pageId,
    question: t(title, { lng: Language.EN }),
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  return (
    <PageContainer>
      <S.Banner>
        <img
          src={gender === Gender.FEMALE ? femaleBanner : maleBanner}
          alt="banner"
        />
      </S.Banner>
      <S.PageTitle marginBottom={24}>{t(title)}</S.PageTitle>
      {!isNoneBadHabits ? (
        <>
          <S.Info>
            <Trans i18nKey="onboarding.badHabitsFeedbackV1.negative.info" />
          </S.Info>
          <S.Info>
            <Trans i18nKey="onboarding.badHabitsFeedbackV1.negative.callToAction" />
          </S.Info>
        </>
      ) : (
        <>
          <S.Info>
            <Trans i18nKey="onboarding.badHabitsFeedbackV1.positive.callToAction" />
          </S.Info>
          <S.Items>
            {POSITIVE_ITEMS.map((period) => (
              <S.Item key={period}>
                <S.Circle />
                <S.ItemContent>
                  <Trans i18nKey={period} />
                </S.ItemContent>
              </S.Item>
            ))}
          </S.Items>
        </>
      )}
      <S.StickyButtonContainer>
        <S.Button type="button" onClick={handleNextClick}>
          {t('actions.continue')}
        </S.Button>
      </S.StickyButtonContainer>
    </PageContainer>
  )
}
