import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledPersonalizeYourPlan = {
  ImageContainer: styled.div`
    aspect-ratio: 1 / 1;
    margin: 0 auto 24px;
    width: 248px;
  `,
  Text: styled.p`
    color: ${Color.GREY_101};
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
  `,
}
