import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { AnswerButton, IconVerticalAlignments } from 'components/AnswerButton'
import { Container } from 'components/Container'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import ectomorph from 'assets/images/ectomorph-male.png'
import endomorph from 'assets/images/endomorph-male.png'
import hourglassBodyType from 'assets/images/hourglass-body-type.png'
import mesomorph from 'assets/images/mesomorph-male.png'
import pearBodyType from 'assets/images/pear-body-type.png'
import rectangleBodyType from 'assets/images/rectangle-body-type.png'
import redQuestion from 'assets/images/red-question-mark.png'
import roundBodyType from 'assets/images/round-body-type.png'

import { Gender } from 'root-constants'

import { FEMALE_OPTION_VALUES, MALE_OPTION_VALUES, QUESTION } from './constants'

export const BodyType: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const userGender = useSelector(selectCurrentUserGender)

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, setIsAnswersDisabled, handleContinue],
  )

  const isFemale = useMemo(() => userGender === Gender.FEMALE, [userGender])

  return (
    <Container>
      <PageTitle marginBottom={24}>{t('onboarding.bodyType.title')}</PageTitle>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={
          isFemale
            ? FEMALE_OPTION_VALUES.RECTANGLE
            : MALE_OPTION_VALUES.ECTOMORPH
        }
      >
        <AnswerButton
          iconSrc={isFemale ? rectangleBodyType : ectomorph}
          iconWidth={92}
          iconHeight={92}
          iconVerticalAlignment={IconVerticalAlignments.CENTER}
        >
          <h3>
            {isFemale
              ? t('onboarding.bodyType.femaleOptions.rectangle')
              : t('onboarding.bodyType.maleOptions.ectomorph')}
          </h3>
        </AnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={
          isFemale
            ? FEMALE_OPTION_VALUES.HOURGLASS
            : MALE_OPTION_VALUES.MESOMORPH
        }
      >
        <AnswerButton
          iconSrc={isFemale ? hourglassBodyType : mesomorph}
          iconWidth={92}
          iconHeight={92}
          iconVerticalAlignment={IconVerticalAlignments.CENTER}
        >
          <h3>
            {isFemale
              ? t('onboarding.bodyType.femaleOptions.hourglass')
              : t('onboarding.bodyType.maleOptions.mesomorph')}
          </h3>
        </AnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={
          isFemale ? FEMALE_OPTION_VALUES.PEAR : MALE_OPTION_VALUES.ENDOMORPH
        }
      >
        <AnswerButton
          iconSrc={isFemale ? pearBodyType : endomorph}
          iconWidth={92}
          iconHeight={92}
          iconVerticalAlignment={IconVerticalAlignments.CENTER}
        >
          <h3>
            {isFemale
              ? t('onboarding.bodyType.femaleOptions.pear')
              : t('onboarding.bodyType.maleOptions.endomorph')}
          </h3>
        </AnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={
          isFemale ? FEMALE_OPTION_VALUES.ROUND : MALE_OPTION_VALUES.NOT_SURE
        }
      >
        <AnswerButton
          iconSrc={isFemale ? roundBodyType : redQuestion}
          iconWidth={92}
          iconHeight={92}
          iconVerticalAlignment={IconVerticalAlignments.CENTER}
        >
          <h3>
            {isFemale
              ? t('onboarding.bodyType.femaleOptions.round')
              : t('onboarding.bodyType.maleOptions.notSure')}
          </h3>
        </AnswerButton>
      </Option>
    </Container>
  )
}
