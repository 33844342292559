import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

const progressValueStyles = css`
  background-color: ${Color.GREEN_400};
  transition: all 0.2s ease-out;
`

export const StyledHeaderV2 = {
  Wrapper: styled.header`
    align-items: center;
    background-color: ${Color.WHITE};
    box-shadow: 0 -0.5px 0 0 #00000040 inset;
    padding: 10px 8px;
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
  `,
  BackButton: styled.button`
    align-items: center;
    appearance: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    left: 8px;
    margin: 0;
    outline: none;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  `,
  CategoryBlock: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Title: styled.h4`
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: ${Color.BLACK_100};
    margin: 0 0 8px 0;
  `,

  ProgressContainer: styled.div`
    display: flex;
    justify-content: center;
    column-gap: 8px;
  `,
  Progress: styled.progress`
    width: 58px;
    height: 4px;
    appearance: none;
    background-color: ${Color.GREY_800};
    border-radius: 4px;
    overflow: hidden;
    border: none;
    display: block;

    &::-webkit-progress-bar {
      background-color: ${Color.GREY_800};
    }
    &::-webkit-progress-value {
      ${progressValueStyles}
    }
    &::-moz-progress-bar {
      ${progressValueStyles}
    }
    &::-ms-fill {
      ${progressValueStyles}
    }
  `,
}
