import img1 from 'assets/images/upsell-v2-action-icon-1.png'
import img2 from 'assets/images/upsell-v2-action-icon-2.png'
import img3 from 'assets/images/upsell-v2-action-icon-3.png'

import { MeasurementSystem } from 'root-constants'

export const BEFORE_AFTER_LOSE_WEIGHT = {
  [MeasurementSystem.IMPERIAL]: {
    value: 15,
    measurementSystem: 'commonComponents.lbs',
  },
  [MeasurementSystem.METRIC]: {
    value: 7,
    measurementSystem: 'commonComponents.kg',
  },
}

export const ACTION_ITEMS_LOSE_WEIGHT = {
  [MeasurementSystem.IMPERIAL]: {
    value: 8,
    measurementSystem: 'commonComponents.pounds',
  },
  [MeasurementSystem.METRIC]: {
    value: 4,
    measurementSystem: 'commonComponents.kilograms',
  },
}

export const ACTION_ITEMS_MAP = [
  {
    imgSrc: img1,
    title: 'upsellV2.actionItems.video.title',
    info: 'upsellV2.actionItems.video.info',
  },
  {
    imgSrc: img2,
    title: 'upsellV2.actionItems.chat.title',
    info: 'upsellV2.actionItems.chat.info',
  },
  {
    imgSrc: img3,
    title: 'upsellV2.actionItems.plan.title',
    info: 'upsellV2.actionItems.plan.info',
  },
]
