import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import dayjs from 'dayjs'

import { selectAnswers } from 'root-redux/selects/common'

import {
  DEFAULT_DATE_FORMAT,
  addDaysToCurrentDate,
  daysFromNowToGoalDate,
  getIntermediateDates,
} from 'helpers/dateHelper'

import { MAXIMUM_WEIGHT_LOSS_PERCENTAGE } from 'root-constants'

const enum GOAL_WEIGHT_COORDINATES {
  X = 258,
  Y = 82,
}

const FIXED_LEFT_INDENT = 20
const FIXED_GRAPH_HEIGHT = 301

export const useEventWeightInfo = () => {
  const answers = useSelector(selectAnswers)

  return useMemo(() => {
    const currentWeight = (answers?.current_weight as string) || '70'
    const eventDate = (answers?.event_date as string) || ''
    const weightLossPerDay =
      parseFloat(currentWeight) * MAXIMUM_WEIGHT_LOSS_PERCENTAGE
    const totalWeightLossBeforeEvent =
      daysFromNowToGoalDate(eventDate) * weightLossPerDay
    let currentWeightAsNumber = +currentWeight
    const goalWeight = (answers?.goal_weight as string) || '60'
    const weightBeforeEvent = (
      parseFloat(currentWeight) - totalWeightLossBeforeEvent
    ).toFixed(1)
    const weightDifference = (
      parseFloat(currentWeight) - parseFloat(weightBeforeEvent)
    ).toFixed(1)

    const getDaysToGoal = () => {
      let res = 0
      for (let i = 1; currentWeightAsNumber > +goalWeight; i++) {
        currentWeightAsNumber -=
          currentWeightAsNumber * MAXIMUM_WEIGHT_LOSS_PERCENTAGE
        res += 1
      }
      return res
    }

    const daysToGoal = getDaysToGoal()
    const onePixelForX = Math.round(FIXED_GRAPH_HEIGHT / daysToGoal)
    const pixelLeft =
      onePixelForX * daysFromNowToGoalDate(eventDate) + FIXED_LEFT_INDENT
    const pixelTop = Math.round(
      (GOAL_WEIGHT_COORDINATES.Y / GOAL_WEIGHT_COORDINATES.X) * pixelLeft,
    ) // это координаты точки с целевым весом относительно графика
    const fullGoalDate = addDaysToCurrentDate(daysToGoal)
    const contributingEventDate = dayjs(eventDate, DEFAULT_DATE_FORMAT)
    const shortenedGoalDate = addDaysToCurrentDate(daysToGoal, 'MMMM, D')
    const intermediateDates = getIntermediateDates(fullGoalDate)
    const isEventWithinGoal =
      parseFloat(weightBeforeEvent) - parseFloat(goalWeight) > 1

    return {
      fullGoalDate,
      eventDate,
      currentWeight,
      goalWeight,
      shortenedGoalDate,
      weightBeforeEvent,
      pixelLeft,
      weightDifference,
      pixelTop,
      contributingEventDate,
      intermediateDates,
      isEventWithinGoal,
    }
  }, [answers?.current_weight, answers?.event_date, answers?.goal_weight])
}
