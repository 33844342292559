import React from 'react'
import { Trans } from 'react-i18next'

import dicountGiftIcon from 'assets/images/discount-gift-icon.png'

import { StyledUpsellDiscountBanner as S } from './UpsellDiscountBanner.styles'

export const UpsellDiscountBannerV1 = () => (
  <S.Container>
    <S.ImageContainer>
      <img src={dicountGiftIcon} alt="discount-gift" />
    </S.ImageContainer>
    <S.Label>
      <Trans i18nKey="upsellV2.discountBannerLabel" />
    </S.Label>
  </S.Container>
)
