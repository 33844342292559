import { DailyMealsValue } from 'value-constants'

import sandwichIcon from 'assets/images/breakfast-sandwich.png'
import chickenSoupIcon from 'assets/images/chicken-soup.png'
import hotMealIcon from 'assets/images/hot-meal.png'
import redCrossIcon from 'assets/images/red-cross.png'
import saladIcon from 'assets/images/salad-plate.png'

export const TRANSLATION_FAMILY = 'onboarding.dailyMealsV1'
export const OPTIONS = [
  {
    key: 'threeRegularMeals',
    value: DailyMealsValue.THREE_REGULAR_MEALS,
    iconSrc: chickenSoupIcon,
  },
  {
    key: 'oneOrTwoRegularMeals',
    value: DailyMealsValue.ONE_OR_TWO_REGULAR_MEALS,
    iconSrc: hotMealIcon,
  },
  {
    key: 'threeRegularMealsAndSnacks',
    value: DailyMealsValue.THREE_REGULAR_MEALS_AND_SNACKS,
    iconSrc: saladIcon,
  },
  {
    key: 'snackAllDay',
    value: DailyMealsValue.SNACK_ALL_DAY,
    iconSrc: sandwichIcon,
  },
  {
    key: 'noneOfAbove',
    value: DailyMealsValue.NONE_OF_ABOVE,
    iconSrc: redCrossIcon,
  },
]
