import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { AnswerButtonV2 } from 'components/AnswerButtonV2'
import { Container } from 'components/Container'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { OPTION_VALUES, QUESTION } from './constants'

export const Walking: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, setIsAnswersDisabled, handleContinue],
  )

  return (
    <Container>
      <PageTitle marginBottom={24}>{t('onboarding.walking.title')}</PageTitle>
      <Option
        {...optionProps}
        marginBottom="12"
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.LESS_TWENTY}
      >
        <AnswerButtonV2>
          <h3>{t('onboarding.walking.options.lessTwenty')}</h3>
        </AnswerButtonV2>
      </Option>
      <Option
        {...optionProps}
        marginBottom="12"
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.TWENTY_SIXTY}
      >
        <AnswerButtonV2>
          <h3>{t('onboarding.walking.options.twentySixty')}</h3>
        </AnswerButtonV2>
      </Option>
      <Option
        {...optionProps}
        marginBottom="12"
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.ONE_TWO_HOURS}
      >
        <AnswerButtonV2>
          <h3>{t('onboarding.walking.options.oneTwoHours')}</h3>
        </AnswerButtonV2>
      </Option>
      <Option
        {...optionProps}
        marginBottom="12"
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.MORE_TWO_HOURS}
      >
        <AnswerButtonV2>
          <h3>{t('onboarding.walking.options.moreTwoHours')}</h3>
        </AnswerButtonV2>
      </Option>
    </Container>
  )
}
