import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { AnswerButton } from 'components/AnswerButton'
import { Container } from 'components/Container'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import sandwichIcon from 'assets/images/breakfast-sandwich.png'
import coffeeIcon from 'assets/images/cup-of-coffee.png'
import pancakesIcon from 'assets/images/pancakes-syrup.png'
import saladIcon from 'assets/images/salad-plate.png'

import { OPTION_VALUES, QUESTION } from './constants'

export const BreakfastTime: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, setIsAnswersDisabled, handleContinue],
  )

  return (
    <Container>
      <PageTitle marginBottom={24}>
        <Trans i18nKey="onboarding.breakfastTime.title" />
      </PageTitle>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.SIX_EIGHT_AM}
      >
        <AnswerButton iconSrc={pancakesIcon}>
          <h3>{t('onboarding.breakfastTime.options.sixEight')}</h3>
        </AnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.EIGHT_TEN_AM}
      >
        <AnswerButton iconSrc={sandwichIcon}>
          <h3>{t('onboarding.breakfastTime.options.eightTen')}</h3>
        </AnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.TEN_NOON}
      >
        <AnswerButton iconSrc={saladIcon}>
          <h3>{t('onboarding.breakfastTime.options.tenNoon')}</h3>
        </AnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.SKIP}
      >
        <AnswerButton iconSrc={coffeeIcon}>
          <h3>{t('onboarding.breakfastTime.options.skip')}</h3>
        </AnswerButton>
      </Option>
    </Container>
  )
}
