import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'
import { PresaleGraph } from 'components/PresaleGraph'

import { useGetPageInfo } from 'hooks/useGetPageInfo'

import { PurchasePageType } from 'modules/purchase/constants'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'

import { StyledPresaleV3 as S } from './PresaleV3.styles'

export const PresaleV3: React.FC = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const { currentSubscriptionPageId } = useGetPageInfo()

  useEffect(() => {
    eventLogger.logPresalePageShown(PurchasePageType.WEB_LANDING)
  }, [])

  const handleNextClick = useCallback(() => {
    eventLogger.logPresalePageCompleted()
    goTo({ pathname: currentSubscriptionPageId, search })
  }, [currentSubscriptionPageId, search])

  return (
    <KitPageContainer hasContinueButton onContinueButtonClick={handleNextClick}>
      <PageTitle marginBottom={24} textAlign="left">
        {t('presaleV3.title')}
      </PageTitle>
      <S.UserInfoBlockV3 />
      <S.UserInfoBlockV4 />
      <PresaleGraph />
    </KitPageContainer>
  )
}
