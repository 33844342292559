import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import { Container } from 'components/Container'
import { Option, OptionType } from 'components/Option'

import { setAnswersAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'

import { useDelayedContinue } from 'hooks/useDelayedContinue'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import { Language } from 'root-constants'

import { StyledPeriodAffectAppetite as S } from './PeriodAffectAppetite.styles'
import { OPTIONS } from './constants'

export const PeriodAffectAppetite: React.FC<TPageProps> = ({ pageId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { search } = useLocation()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useCallback(
    (answer): void => {
      dispatch(
        setAnswersAction({
          pageId,
          answers: answer,
        }),
      )
      dispatch(sendUserAnswersAction())

      eventLogger.logQuestion({
        question: t('onboarding.period.title', { lng: Language.EN }),
        answers: answer,
      })

      goTo({ pathname: PageId.HELP_CALM_CRAVINGS, search })
    },
    [dispatch, t, pageId, search],
  )

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [handleChange, setIsAnswersDisabled, handleContinue, pageId],
  )

  return (
    <Container>
      <S.PageTitle>{t('onboarding.periodAffectAppetite.title')}</S.PageTitle>
      {OPTIONS.map(({ value, title }) => (
        <Option
          {...optionProps}
          key={value}
          value={value}
          disabled={isAnswersDisabled}
          marginBottom="12"
        >
          <S.AnswerButton>
            <h3>{t(title)}</h3>
          </S.AnswerButton>
        </Option>
      ))}
    </Container>
  )
}
