import React from 'react'
import { useTranslation } from 'react-i18next'

import { StyledCreditCardTitleV2 as S } from './CreditCardTitle.styles'

type TProps = {
  className?: string
}

export const CreditCardTitleV2: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()

  return (
    <S.TitleContainer className={className}>
      <S.PaymentInfo>{t('checkout.paymentInfo')}</S.PaymentInfo>
      <S.Label>{t('checkout.protectedLabel')}</S.Label>
    </S.TitleContainer>
  )
}
