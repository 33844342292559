import styled from 'styled-components'

import { Container } from 'components/Container'
import { Logo } from 'components/Logo'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledCustomizeMealPlan = {
  Logo: styled(Logo)`
    padding-top: 10px;
  `,
  FixedContainer: styled.div`
    left: 50%;
    margin: 0 auto 24px;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 100%;
    z-index: -1;
  `,
  Banner: styled.div`
    aspect-ratio: 360 / 443;
    width: 100%;
  `,
  Container: styled(Container)`
    align-items: center;
    display: flex;
    flex-direction: column;
  `,
  Title: styled.h2`
    color: ${Color.WHITE};
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 400px;
    max-width: 270px;
    min-height: 160px;
    text-align: center;

    strong,
    i {
      background-color: rgba(255, 255, 255, 0.7);
      border-radius: 15px;
      display: inline-block;
      padding: 0 8px;
      white-space: nowrap;
    }

    strong {
      color: #3ebb76;
      margin-bottom: 4px;
    }

    i {
      color: #ff7958;
      font-style: inherit;

      &:not(:last-of-type) {
        margin-bottom: 4px;
      }
    }
  `,
  Review: styled.div`
    background-color: ${Color.WHITE};
    border-radius: 12px;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: column;
    padding: 16px;
    row-gap: 8px;
    transform: translateY(-55px);
  `,
  ReviewHeader: styled.div`
    align-items: center;
    column-gap: 10px;
    display: flex;
  `,
  ReviewPhoto: styled.div`
    aspect-ratio: 1 / 1;
    width: 40px;
  `,
  ReviewInfo: styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2px;
  `,
  Stars: styled.div`
    aspect-ratio: 49 / 9;
    width: 88px;
  `,
  ReviewAbout: styled.div`
    color: ${Color.BLACK};
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
  `,
  ReviewContent: styled.div`
    color: ${Color.BLACK};
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
  `,
}
