import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledPresaleGraph = {
  GraphContainer: styled.div`
    width: 100%;
    padding: 16px;
    background-color: ${Color.WHITE};
    box-shadow: 0 12px 32px 0 #0000001f;
    border-radius: 16px;
  `,
  Title: styled.h4`
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: ${Color.GREY_100};
    margin: 0 0 16px 0;
  `,
  Graph: styled.div`
    min-height: 170px;
    width: 100%;
    aspect-ratio: 296/170;
  `,
}
