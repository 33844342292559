import styled from 'styled-components'

import directionArrow from 'assets/images/bottom-pointer.svg'
import rightWhiteArrow from 'assets/images/right-pointer.svg'

import { Color } from 'root-constants'

export const StyledLoseWeightGraphV3 = {
  GraphContainer: styled.div`
    display: grid;
    grid-template-columns: 32px 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 32px;
    grid-template-areas:
      'weight graph graph graph graph graph'
      'weight graph graph graph graph graph'
      'weight graph graph graph graph graph'
      'weight graph graph graph graph graph'
      'weight graph graph graph graph graph'
      'weight graph graph graph graph graph'
      'weight graph graph graph graph graph'
      '.  month month month month month';

    min-height: 240px;
    width: 100%;
    padding: 24px 8px 8px 8px;
    border: 1px solid #cfd4dd;
    border-radius: 16px;
  `,
  Weight: styled.div`
    grid-area: weight;
    display: flex;
    flex-direction: column;
    justify-self: center;
    justify-content: space-around;
    min-height: 176px;
  `,
  Months: styled.div`
    grid-area: month;
    display: flex;
    justify-content: space-between;
    margin: 0 24px 0 0;
  `,
  WithYear: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Text: styled.span`
    font-weight: 600;
    font-size: 12px;
    color: #72767e;
    line-height: 16px;
  `,
  Graph: styled.div`
    grid-area: graph;
    min-height: 176px;
    aspect-ratio: 264/176;
    margin: 0 10px 0 0;
    position: relative;
  `,
  GoalBadge: styled.div<{ isAnimationStarted: boolean }>`
    position: absolute;
    z-index: 1;
    bottom: 48px;
    right: -8px;
    border-radius: 10px;
    padding: 6px 10px;
    background-color: ${Color.PRIMARY};
    opacity: ${({ isAnimationStarted }) => (isAnimationStarted ? '1' : '0')};
    transition: opacity 2s linear;

    text-align: center;
    color: ${Color.WHITE};
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;

    &:after {
      content: url(${directionArrow});
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
    }
  `,
  EventBadge: styled.div<{ isAnimationStarted: boolean }>`
    position: absolute;
    z-index: 1;
    right: -10px;
    top: -12px;
    border-radius: 10px;
    padding: 4px 10px;
    background-color: ${Color.WHITE};
    box-shadow: 0 2px 15px 0 #26273026;
    opacity: ${({ isAnimationStarted }) => (isAnimationStarted ? '1' : '0')};
    transition: opacity 2s linear;

    font-weight: 600;
    font-size: 14px;
    color: ${Color.DARK_BLUE};
    text-transform: capitalize;

    &:after {
      content: url(${rightWhiteArrow});
      position: absolute;
      right: -8px;
      top: 50%;
      transform: translateY(-50%);
      height: 24px;
    }
  `,
}
