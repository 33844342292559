import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { OptionType } from 'components/Option'

import {
  ADDITIONAL_PAYMENT_METHOD,
  PAYMENT_METHODS_INITIAL,
} from 'modules/purchase/components/PaymentMethodsV2/constants'
import { PaymentMethod } from 'modules/purchase/constants'
import { selectOneClickPaymentMethod } from 'modules/purchase/redux/selects'

import { StyledPaymentMethodsV2 as S } from './PaymentMethodsV2.styles'

type TOption = {
  value: PaymentMethod
  icon: string
}

type TProps = {
  activePaymentMethod: PaymentMethod
  onClick: (method: PaymentMethod) => void
  className?: string
}

export const PaymentMethodsV2: React.FC<TProps> = ({
  activePaymentMethod,
  onClick,
  className,
}) => {
  const oneClickPaymentMethod = useSelector(selectOneClickPaymentMethod)
  const [options, setOptions] = useState<TOption[]>(PAYMENT_METHODS_INITIAL)

  useEffect(() => {
    oneClickPaymentMethod
      ? setOptions([
          ADDITIONAL_PAYMENT_METHOD[oneClickPaymentMethod],
          ...PAYMENT_METHODS_INITIAL,
        ])
      : setOptions(PAYMENT_METHODS_INITIAL)
  }, [oneClickPaymentMethod])

  return (
    <S.Container className={className}>
      {options.map(({ value, icon }) => (
        <S.Option
          isActive={activePaymentMethod === value}
          type={OptionType.RADIO}
          name="paymentMethod"
          value={value}
          key={value}
          onChange={() => onClick(value)}
        >
          <S.ImageContainer>
            <img src={icon} alt={value} />
          </S.ImageContainer>
        </S.Option>
      ))}
    </S.Container>
  )
}
