import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

const commonLabelStyles = css`
  padding: 8px 16px;
  background: rgba(169, 205, 80, 0.2);
  border: 1px solid ${Color.GREEN_400};
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${Color.GREEN_300};
`

const commonLabelTextStyles = css`
  font-weight: 400;
  font-size: 17px;
  line-height: 34px;
`

const commonTitleStyles = css`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
`

export const StyledDownload = {
  Title: styled.h2`
    ${commonTitleStyles};

    span {
      text-wrap: nowrap;
    }
  `,
  Subtitle: styled.h3`
    ${commonTitleStyles};
    margin-bottom: 16px;
  `,
  LoginDescription: styled.p`
    margin-bottom: 24px;
    padding: 16px;
    background: rgba(252, 217, 3, 0.2);
    border: 1px solid #fcd903;
    border-radius: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    color: ${Color.GREY_100};
  `,
  List: styled.ul`
    margin-left: 15px;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    list-style-type: decimal;

    li {
      display: list-item;
    }
  `,
  DownloadAppText: styled.p`
    margin-bottom: 16px;
    ${commonLabelTextStyles};

    strong {
      ${commonLabelStyles};
    }
  `,
  ContinueText: styled.p`
    ${commonLabelTextStyles};
  `,
  EmailLabel: styled.p`
    ${commonLabelStyles};
    display: inline-block;
    margin: 0 0 0 15px;
  `,
  Image: styled.img`
    margin: 0 auto;
  `,
}
