import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

const circleProgress = (isBackground = false) => css<{
  value: number
  radius?: number
}>`
  ${({ value, radius = 45 }) => {
    const length = radius * Math.PI * 2
    return css`
      stroke-dasharray: 0 ${length};
      stroke-width: ${radius / 8};
      stroke-dasharray: ${isBackground ? length : length * (value / 100)}
        ${length};
    `
  }};
  stroke-linecap: round;
  fill: transparent;
  transition: stroke-dasharray 0.2s;
`

export const StyledProgressRoundedV2 = {
  Container: styled.div`
    position: relative;
    height: 160px;
  `,
  Svg: styled.svg<{ value: number; radius?: number }>`
    width: 100%;
    height: 100%;
    transform: rotate(-90deg);
    circle {
      ${circleProgress(true)};
      stroke: #f5f6f7;
    }
    circle + circle {
      ${circleProgress()};
      stroke: ${Color.PRIMARY};
    }
  `,
  Label: styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 32px;
    color: ${Color.PRIMARY};

    strong {
      font-weight: 700;
      font-size: 20px;
    }
  `,
}
