import React, { ReactNode } from 'react'

import { StyledContainer as S } from './Container.styles'

type TContainer = {
  children: ReactNode
  className?: string
}

export const Container: React.FC<TContainer> = ({
  children,
  className = '',
}) => <S.Container className={className}>{children}</S.Container>

export const Container375: React.FC<TContainer> = ({
  children,
  className = '',
}) => <S.Container375 className={className}>{children}</S.Container375>
