import styled, { css } from 'styled-components'

import { Button } from 'components/Button'
import { SvgImage } from 'components/SvgImage'

import { FeaturedBlock } from 'modules/purchase/components'

import subscriptionsBg from 'assets/images/cancelOffer/cancel-offer-subscriptions-bg.jpg'
import orangeLine from 'assets/images/orange-line.png'

import { MediaBreakpoint } from 'root-constants'

const commonContainerStyles = css`
  max-width: ${MediaBreakpoint.MAX_PHONE}px;
  margin: 0 auto;

  @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
    max-width: 360px;
  }
`

export const StyledCancelOffer = {
  LogoWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-weight: 800;
    font-size: 16px;
    line-height: 16px;
    padding: 8px;
  `,
  LogoImg: styled(SvgImage)`
    width: 32px;
    height: 32px;
  `,
  ImageContainer: styled.div`
    aspect-ratio: 180 / 89;
    margin-bottom: 10px;
    ${commonContainerStyles};
  `,
  Wrapper: styled.div`
    padding: 0 16px;
    ${commonContainerStyles};
  `,
  PlanDescription: styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    margin-bottom: 8px;

    strong {
      position: relative;
      padding: 0 5px;

      &::before {
        content: '';
        position: absolute;
        top: -2px;
        left: -10px;
        width: 83px;
        height: 24px;
        background-image: url(${orangeLine});
        background-repeat: no-repeat;
        background-position: center;
        background-size: 83px 24px;
      }
    }
  `,
  SubscriptionsContainer: styled.div`
    margin-bottom: 24px;
    padding: 24px 16px 40px;
    border-radius: 24px;
    background-image: url(${subscriptionsBg});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    ${commonContainerStyles};
  `,
  FeaturedBlock: styled(FeaturedBlock)`
    margin-top: 24px;
  `,
  SecondaryWrapper: styled.div`
    padding-bottom: 24px;
  `,
  Button: styled(Button)`
    text-transform: capitalize;
  `,
}
