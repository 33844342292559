import React from 'react'

import { KitCheckboxOptionsPage, KitRadioOptionsPage } from 'pages/options-page'

import { OptionType } from 'components/Option'

import { useDynamicPage } from 'hooks/useDynamicPage'

import { TPageProps } from 'models/common.model'

export const OptionsRightToLeftV3: React.FC<TPageProps> = (props) => {
  const {
    title,
    subtitle,
    optionType,
    optionData,
    optionProps,
    buttonText,
    answers,
    handleContinue,
  } = useDynamicPage(props)

  return (
    <>
      {optionType === OptionType.CHECKBOX && (
        <KitCheckboxOptionsPage
          {...props}
          title={title}
          subtitle={subtitle}
          buttonText={buttonText}
          options={optionData}
          optionProps={optionProps}
          answers={answers}
          isReverse
          handleContinue={handleContinue}
        />
      )}
      {optionType === OptionType.RADIO && (
        <KitRadioOptionsPage
          {...props}
          title={title}
          subtitle={subtitle}
          options={optionData}
          optionProps={optionProps}
          isReverse
        />
      )}
    </>
  )
}
