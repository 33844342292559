import men from 'assets/images/men-feedback-v2.png'
import women from 'assets/images/women-feedback-v2.png'

import { Gender, MeasurementSystem } from 'root-constants'

export const LOST_WEIGHT_VALUE_MAP = {
  [MeasurementSystem.IMPERIAL]: 30,
  [MeasurementSystem.METRIC]: 13,
}
export const GENDER_NAME_MAP = {
  [Gender.MALE]: 'commonComponents.gender.men',
  [Gender.FEMALE]: 'commonComponents.gender.women',
}

export const IMAGE_SRC_MAP = {
  [Gender.MALE]: men,
  [Gender.FEMALE]: women,
}

export const FEEDBACK_AUTHOR_MAP = {
  [Gender.MALE]: 'Mark',
  [Gender.FEMALE]: 'Emma',
}

export const FEEDBACK_TEXT_MAP = {
  [Gender.MALE]: 'onboarding.feedbacksV2.menFeedback',
  [Gender.FEMALE]: 'onboarding.feedbacksV2.womenFeedback',
}

export const LOST_WEIGHT_METRIC_MAP = {
  [MeasurementSystem.IMPERIAL]: 'commonComponents.pounds',
  [MeasurementSystem.METRIC]: 'commonComponents.kilograms',
}

export const LOST_WEIGHT_VALUE_MAP_V2 = {
  [Gender.MALE]: {
    [MeasurementSystem.IMPERIAL]: 40,
    [MeasurementSystem.METRIC]: 18,
  },
  [Gender.FEMALE]: {
    [MeasurementSystem.IMPERIAL]: 30,
    [MeasurementSystem.METRIC]: 13,
  },
}
