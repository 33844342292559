import styled from 'styled-components'

import checkGreen from 'assets//images/check-green.svg'

import { Color } from 'root-constants'

export const StyledPlanHighlightsV3 = {
  Title: styled.h2`
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    color: ${Color.GREY_100};
    text-align: center;
    margin: 0 0 24px 0;
  `,
  List: styled.ul`
    margin: 0 0 32px 0;
  `,
  ListItem: styled.li`
    position: relative;
    padding: 0 0 0 32px;

    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    color: ${Color.GREY_100};

    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      content: '';
      width: 20px;
      height: 20px;
      background: url(${checkGreen}) center no-repeat;
      background-size: contain;
    }

    &:not(:last-of-type) {
      margin: 0 0 12px 0;
    }
  `,
}
