import styled from 'styled-components'

import { Container } from 'components/Container'

export const StyledMotivation = {
  SubTitle: styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    margin-bottom: 24px;
  `,
  Container: styled(Container)`
    padding: 0 16px 30px 16px;
  `,
}
