import React, { FC } from 'react'

import { StyledKitInfoBlock as S } from './KitInfoBlock.styles'

type TProps = {
  iconSrc: string
  title?: string
  text: string
  className?: string
}

export const KitInfoBlock: FC<TProps> = ({
  iconSrc,
  title,
  text,
  className,
}) => (
  <S.InfoContainer className={className}>
    <S.InfoImage>
      <img src={iconSrc} alt="info" />
    </S.InfoImage>
    <S.Info>
      {!!title && <S.InfoTitle>{title}</S.InfoTitle>}
      <S.InfoText>{text}</S.InfoText>
    </S.Info>
  </S.InfoContainer>
)
