export const QUESTION = 'Your goal weight'
export const MIN_WEIGHT_KG = 20
export const MIN_WEIGHT_LB = 44

export const MIN_KG_DIFF = 1
export const MIN_LB_DIFF = 2

export const enum LoseWeightPercentageValue {
  LESS_THAN_10 = 'less_than_10',
  FROM_10_TO_20 = 'from_10_to_20',
  MORE_THEN_20 = 'more_then_20',
}

export const LOSE_WEIGHT_INFO_MAP = {
  [LoseWeightPercentageValue.LESS_THAN_10]: {
    title: 'onboarding.goalWeight.info.lessThan10.title',
    text: 'onboarding.goalWeight.info.lessThan10.text',
  },
  [LoseWeightPercentageValue.FROM_10_TO_20]: {
    title: 'onboarding.goalWeight.info.from10To20.title',
    text: 'onboarding.goalWeight.info.from10To20.text',
  },
  [LoseWeightPercentageValue.MORE_THEN_20]: {
    title: 'onboarding.goalWeight.info.moreThen20.title',
    text: 'onboarding.goalWeight.info.moreThen20.text',
  },
}
