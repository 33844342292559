import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { StyledYoEffect as S } from 'pages/questions/YoEffect/YoEffect.styles'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { Logo } from 'components/Logo'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import columbiaLogo from 'assets/images/columbia-university-logo.png'
import graph from 'assets/images/effect-graph.png'
import mayoLogo from 'assets/images/mayo-clinic-logo.png'

import { QUESTION } from './constants'

export const YoEffect: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  return (
    <S.Wrapper>
      <Logo />
      <S.Title>
        <Trans i18nKey="onboarding.yoEffect.title" />
      </S.Title>
      <S.Graph src={graph} alt="graph" />
      <Container>
        <S.Card>
          <S.CardIconFirst src={columbiaLogo} alt="" />
          <S.CardText>
            <Trans i18nKey="onboarding.yoEffect.option1" />
          </S.CardText>
        </S.Card>
        <S.Card>
          <S.CardIconSecond src={mayoLogo} alt="" />
          <S.CardText>
            <Trans i18nKey="onboarding.yoEffect.option2" />
          </S.CardText>
        </S.Card>
        <StickyButtonContainer>
          <Button type="button" onClick={handleNextClick}>
            {t('actions.continue')}
          </Button>
        </StickyButtonContainer>
      </Container>
    </S.Wrapper>
  )
}
