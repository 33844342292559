import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { QUESTION } from 'pages/questions/MenChoice/constants'

import { Button } from 'components/Button'
import { Logo } from 'components/Logo'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import menChoice from 'assets/images/men-choice.webp'

import { StyledMenChoice as S } from './MenChoice.styles'

export const MenChoice: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  return (
    <S.Wrapper>
      <S.Container>
        <Logo marginBottom={44} />
        <S.ImageContainer>
          <img src={menChoice} alt="men" />
        </S.ImageContainer>
        <S.Title>1,100,000+</S.Title>
        <S.Subtitle>
          <Trans i18nKey="onboarding.usersChoice.mensChoiceSubtitle" />
        </S.Subtitle>
        <StickyButtonContainer>
          <Button type="button" onClick={handleNextClick}>
            {t('actions.continue')}
          </Button>
        </StickyButtonContainer>
      </S.Container>
    </S.Wrapper>
  )
}
