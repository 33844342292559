export const QUESTION = 'Your height'

export const enum MinMaxHeight {
  MIN_HEIGHT_CM = 120,
  MAX_HEIGHT_CM = 240,
  MIN_HEIGHT_FT = 4,
  MAX_HEIGHT_FT = 7,
  MIN_HEIGHT_IN = 0,
  MAX_HEIGHT_IN = 11,
  MIN_IMP_HEIGHT = 4.0,
  MAX_IMP_HEIGHT = 7.11,
}
