import { Emojis } from 'root-constants'

export const UPSELL_FEEDBACKS_MAP = [
  {
    title: 'purchase.upsellFeedbacksV1.friend.title',
    info: 'purchase.upsellFeedbacksV1.friend.info',
    about: 'purchase.upsellFeedbacksV1.friend.about',
    emoji: Emojis.SMILING_FACE_WITH_3_HEARTS,
  },
  {
    title: 'purchase.upsellFeedbacksV1.dieting.title',
    info: 'purchase.upsellFeedbacksV1.dieting.info',
    about: 'purchase.upsellFeedbacksV1.dieting.about',
    emoji: Emojis.OK_HAND,
  },
  {
    title: 'purchase.upsellFeedbacksV1.progress.title',
    info: 'purchase.upsellFeedbacksV1.progress.info',
    about: 'purchase.upsellFeedbacksV1.progress.about',
    emoji: Emojis.THUMBS_UP,
  },
]
