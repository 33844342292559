import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { SvgImage } from 'components/SvgImage'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import checkIcon from 'assets/images/sprite/check-icon.svg'

import { Cohort } from 'root-constants'

import { StyledPlanHighlightsV2 as S } from './PlanHighlightsV2.styles'
import { LIST_ITEMS_V1, LIST_ITEMS_V2 } from './constants'

type TProps = {
  titleElementRef?: React.RefObject<HTMLHeadingElement>
}

export const PlanHighlightsV2: React.FC<TProps> = ({ titleElementRef }) => {
  const { t } = useTranslation()

  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const listItems = useMemo(
    () => (cohortToUse === Cohort.MF_MEN_FLOW ? LIST_ITEMS_V2 : LIST_ITEMS_V1),
    [cohortToUse],
  )

  return (
    <>
      <S.Title ref={titleElementRef}>{t('purchase.listTitleV2')}</S.Title>
      <S.List>
        {listItems.map(({ text }) => (
          <S.ListItem key={text}>
            <SvgImage svg={checkIcon} width={24} />
            {t(text)}
          </S.ListItem>
        ))}
      </S.List>
    </>
  )
}
