import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

type TInputProps = {
  hasLabel: boolean
  hasButton: boolean
  isContentCentered: boolean
}

export const StyledInput = {
  Wrapper: styled.div`
    position: relative;
    display: block;
    width: 100%;

    &::after {
      display: block;
      position: absolute;
      left: 16px;
      color: #f37264;
      font-size: 13px;
      line-height: 20px;
    }

    &[data-valid='false'] {
      &::after {
        content: attr(data-validation-text);
      }
    }
  `,
  Input: styled.input<TInputProps>`
    display: block;
    width: 100%;
    height: 64px;
    padding: 8px 16px;
    ${({ hasLabel }) =>
      hasLabel &&
      css`
        padding-top: 32px;
      `};
    ${({ hasButton }) =>
      hasButton &&
      css`
        padding-right: 52px;
      `};
    ${({ isContentCentered }) =>
      isContentCentered &&
      css`
        text-align: center;
      `};
    color: ${Color.GREY_100};
    font-weight: 800;
    font-size: 28px;
    line-height: 32px;
    border-radius: 12px;
    border: none;
    background-color: ${Color.LIGHT};
    color: ${Color.GREY_100};
    outline: none;
    transition: border 0.2s ease-out;

    &:valid,
    &:focus {
      & + span {
        top: 10px;
        color: ${Color.GREY_100};
        font-size: 13px;
        line-height: 1;
      }
    }

    &[type='number'] {
      /* Hide arrows */
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    &::placeholder {
      font-weight: 500;
      line-height: 20px;
      color: rgba(19, 29, 48, 0.5);
    }
  `,
  Label: styled.span<{ isEmpty: boolean }>`
    position: absolute;
    top: 20px;
    left: 25px;
    color: ${Color.GREY_600};
    font-size: 16px;
    transition-property: top, color, font-size, line-height;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    pointer-events: none;
    cursor: text;
    ${({ isEmpty }) =>
      !isEmpty &&
      css`
        top: 10px;
        color: ${Color.GREY_100};
        font-size: 13px;
        line-height: 1;
      `};
  `,
  VisibilityButton: styled.button`
    padding: 0;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 52px;
    height: 60px;
    border: none;
    outline: none;
    cursor: pointer;
    background-size: 20px 20px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
  `,
}
