import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledWeAllGainWeight as S } from './WeAllGainWeight.styles'
import { QUESTION } from './constants'

export const WeAllGainWeight: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  return (
    <>
      <S.ImageContainer>
        <S.Logo marginBottom={38} />
      </S.ImageContainer>
      <Container>
        <S.Title>
          <Trans i18nKey="onboarding.weAllGainWeight.title" />
        </S.Title>
        <S.Subtitle>
          <Trans i18nKey="onboarding.weAllGainWeight.subTitle" />
        </S.Subtitle>
        <StickyButtonContainer>
          <Button type="button" onClick={handleNextClick}>
            {t('actions.letsGo')}
          </Button>
        </StickyButtonContainer>
      </Container>
    </>
  )
}
