import { useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'

import { selectTaboolaPixelId } from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { TaboolaEventName } from 'root-constants'

export const useTaboolaPixelInitialization = () => {
  const uuid = useSelector(selectUUID)
  const taboolaPixelId = useSelector(selectTaboolaPixelId)

  useLayoutEffect(() => {
    if (!uuid || !taboolaPixelId) {
      return
    }

    const taboolaScript = document.createElement('script')
    taboolaScript.type = 'text/javascript'
    taboolaScript.text = `
    window._tfa = window._tfa || [];
    window._tfa.push({notify: 'event', name: '${TaboolaEventName.PAGE_VIEW}', id: ${taboolaPixelId}});
    !function (t, f, a, x) {
           if (!document.getElementById(x)) {
              t.async = 1;t.src = a;t.id=x;f.parentNode.insertBefore(t, f);
           }
    }(document.createElement('script'),
    document.getElementsByTagName('script')[0],
    '//cdn.taboola.com/libtrc/unip/${taboolaPixelId}/tfa.js',
    'tb_tfa_script');
    `
    document.body.appendChild(taboolaScript)
  }, [taboolaPixelId, uuid])
}
