import { AgeRangeValue } from 'value-constants'

import firstRange from 'assets/images/age-range-v3-first.png'
import fourthRange from 'assets/images/age-range-v3-fourth.png'
import secondRange from 'assets/images/age-range-v3-second.png'
import thirdRange from 'assets/images/age-range-v3-third.png'

export const RANGES = [
  {
    value: AgeRangeValue.AR_45_49,
    text: '45-49 >',
    img: firstRange,
  },
  {
    value: AgeRangeValue.AR_50_54,
    text: '50-54 >',
    img: secondRange,
  },
  {
    value: AgeRangeValue.AR_54_59,
    text: '54-59 >',
    img: thirdRange,
  },
  {
    value: AgeRangeValue.AR_60_PLUS,
    text: '60+ >',
    img: fourthRange,
  },
]
