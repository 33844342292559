/* eslint-disable max-lines */
import {
  OnResumeSuccessHandler,
  OnTokenizeSuccessHandler,
} from '@primer-io/checkout-web'
import {
  PaymentMethodResult,
  PaymentRequest,
  PaymentRequestPaymentMethodEvent,
  Stripe,
  StripeCardNumberElement,
  StripeError,
} from '@stripe/stripe-js'
import { paymentApi } from 'api'
import i18n from 'i18next'

import {
  setErrorAction,
  startFetching,
  stopFetching,
} from 'root-redux/actions/common'
import {
  savePlanAdditionsAction,
  sendUserConfigAction,
  updateUserConfigAction,
} from 'root-redux/actions/user'
import { selectCurrentVariantCohort } from 'root-redux/selects/common'
import { selectUUID, selectUserPaymentId } from 'root-redux/selects/user'

import { usePaymentStore } from 'hooks/usePaymentStore'

import { createIntroOfferProductId } from 'helpers/createIntroOfferProductId'

import {
  DEFAULT_CARDHOLDER_NAME,
  PRIMER_PAYMENT_ERRORS,
  PRIMER_SOFT_DECLINE,
  PaymentMethod,
  PaymentSystem,
} from 'modules/purchase/constants'
import { getStripePurchaseFailedEventParams } from 'modules/purchase/helpers/getStripePurchaseFailedEventParams'
import { getStripePurchaseStartedEventParams } from 'modules/purchase/helpers/getStripePurchaseStartedEventParams'
import { getStripePurchaseSuccessEventParams } from 'modules/purchase/helpers/getStripePurchaseSuccessEventParams'
import {
  checkIsRetryAllowed,
  getRedirectUrl,
  logFailedPayment,
  logSuccessfulPayment,
} from 'modules/purchase/helpers/rootHelpers'
import { IPrimerSubscriptionBackupConfig } from 'modules/purchase/types'

import { IAction, IAppState, TAppDispatchThunk } from 'models/store.model'
import { ISubscription } from 'models/subscriptions.model'

import { eventLogger } from 'services/eventLogger.service'

import { CENTS_IN_DOLLAR } from 'root-constants'

import {
  selectCreatedSubscriptionId,
  selectCurrency,
  selectInAppId,
  selectIsFirstPaymentRetryPassed,
  selectPaymentClientSecret,
  selectPaymentMethod,
  selectPrimerClientSessionToken,
  selectSubscriptionFullPrice,
  selectSubscriptionLookupKey,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionPlanId,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
  selectTrialPeriodDays,
} from '../selects'

const MODULE_NAME = 'PURCHASE'

export const CANCEL_PRIMER_CLIENT_SESSION_DISCOUNT = `${MODULE_NAME}/CANCEL_PRIMER_CLIENT_SESSION_DISCOUNT`
export const CHECK_3D_SECURE = `${MODULE_NAME}/CHECK_3D_SECURE`
export const CHECK_PAYMENT_REQUEST_BUTTON = `${MODULE_NAME}/CHECK_PAYMENT_REQUEST_BUTTON`
export const PURCHASE = `${MODULE_NAME}/PURCHASE`
export const SET_IS_PAY_PAL_LOADING = `${MODULE_NAME}/SET_IS_PAY_PAL_LOADING`
export const SET_IS_PRIMER_PAY_PAL_RENDERED = `${MODULE_NAME}/SET_IS_PRIMER_PAY_PAL_RENDERED`
export const RESET_3D_SECURE_IFRAME_URL = `${MODULE_NAME}/RESET_3D_SECURE_IFRAME_URL`
export const SET_SUBSCRIPTION = `${MODULE_NAME}/SET_SUBSCRIPTION`
export const SET_3D_SECURE_IFRAME_URL = `${MODULE_NAME}/SET_3D_SECURE_IFRAME_URL`
export const SET_PAYMENT_CLIENT_SECRET = `${MODULE_NAME}/SET_PAYMENT_CLIENT_SECRET`
export const SET_TRIAL_PERIOD_DAYS = `${MODULE_NAME}/SET_TRIAL_PERIOD_DAYS`
export const SET_SUBSCRIPTION_ID = `${MODULE_NAME}/SET_SUBSCRIPTION_ID`
export const SET_PLAN_ADDITIONS = `${MODULE_NAME}/SET_PLAN_ADDITIONS`
export const SET_TAX_AMOUNT = `${MODULE_NAME}/SET_TAX_AMOUNT`
export const SET_ONE_CLICK_PAYMENT_METHOD = `${MODULE_NAME}/SET_AVAILABLE_ONE_CLICK_PAYMENT`
export const SET_STRIPE_PAYMENT_REQUEST = `${MODULE_NAME}/SET_PAYMENT_REQUEST`
export const SET_CHECKOUT_FLOW_SHOWN = `${MODULE_NAME}/SET_CHECKOUT_FLOWS_SHOWN`
export const SET_SUBSCRIPTION_TIMER_VALUE = `${MODULE_NAME}/SET_SUBSCRIPTION_TIMER_VALUE`
export const SET_PAYMENT_METHOD = `${MODULE_NAME}/SET_PAYMENT_METHOD`
export const SET_PRIMER_CLIENT_SESSION_TOKEN = `${MODULE_NAME}/SET_PRIMER_CLIENT_SESSION_TOKEN`
export const GET_PRIMER_CLIENT_SESSION_TOKEN = `${MODULE_NAME}/GET_PRIMER_CLIENT_SESSION_TOKEN`
export const SET_BACKUP_PRIMER_SUBSCRIPTION_CONFIG = `${MODULE_NAME}/SET_BACKUP_PRIMER_SUBSCRIPTION_CONFIG`
export const SET_IS_PRIMER_RETRY_PROCESSING = `${MODULE_NAME}/SET_IS_PRIMER_RETRY_PROCESSING`
export const SET_IS_FIRST_PAYMENT_RETRY_PASSED = `${MODULE_NAME}/SET_IS_FIRST_PAYMENT_RETRY_PASSED`
export const UPDATE_PRIMER_CLIENT_SESSION = `${MODULE_NAME}/UPDATE_PRIMER_CLIENT_SESSION`

const set3DSecureIframeUrlAction = (payload: string): IAction<string> => ({
  type: SET_3D_SECURE_IFRAME_URL,
  payload,
})

const reset3DSecureIframeUrlAction = (): IAction<any> => ({
  type: RESET_3D_SECURE_IFRAME_URL,
})

const setPaymentClientSecretAction = (payload: string): IAction<string> => ({
  type: SET_PAYMENT_CLIENT_SECRET,
  payload,
})

const setTrialPeriodDaysAction = (payload: number): IAction<number> => ({
  type: SET_TRIAL_PERIOD_DAYS,
  payload,
})

const setSubscriptionIdAction = (payload: string): IAction<string> => ({
  type: SET_SUBSCRIPTION_ID,
  payload,
})

export const setTaxAmountAction = (payload: number): IAction<number> => ({
  type: SET_TAX_AMOUNT,
  payload,
})

const getErrorActionPayload = ({ type, message }: StripeError): string =>
  message || type

export const setSelectedSubscriptionAction = (
  payload: ISubscription,
): IAction<ISubscription> => ({
  type: SET_SUBSCRIPTION,
  payload,
})

export const setPlanAdditionsAction = (
  payload: string[],
): IAction<string[]> => ({
  type: SET_PLAN_ADDITIONS,
  payload,
})

export function setOneClickPaymentMethodAction(
  oneClickPaymentMethod: string,
): IAction<string> {
  return {
    type: SET_ONE_CLICK_PAYMENT_METHOD,
    payload: oneClickPaymentMethod,
  }
}

export function setStripePaymentRequestAction(
  stripePaymentRequest: PaymentRequest | null,
): IAction<PaymentRequest | null> {
  return {
    type: SET_STRIPE_PAYMENT_REQUEST,
    payload: stripePaymentRequest,
  }
}

export function setCheckoutFlowShownAction(
  isCheckoutFlowShown: boolean,
): IAction<boolean> {
  return {
    type: SET_CHECKOUT_FLOW_SHOWN,
    payload: isCheckoutFlowShown,
  }
}

export const setSubscriptionTimerValueAction = (
  subscriptionTimerValue: number,
) => ({
  type: SET_SUBSCRIPTION_TIMER_VALUE,
  payload: subscriptionTimerValue,
})

export const setIsPayPalButtonLoadingAction = (
  isPaypalButtonLoading: boolean,
) => ({
  type: SET_IS_PAY_PAL_LOADING,
  payload: isPaypalButtonLoading,
})

export const setIsPrimerPayPalButtonRenderedAction = (
  isPrimerPaypalButtonRendered: boolean,
) => ({
  type: SET_IS_PRIMER_PAY_PAL_RENDERED,
  payload: isPrimerPaypalButtonRendered,
})

export const purchaseAction =
  ({
    stripe,
    paymentPageId,
    card,
    name,
    createPaymentResFromDigitalWallet,
  }: {
    stripe: Stripe
    paymentPageId: string
    card?: StripeCardNumberElement
    name?: string
    createPaymentResFromDigitalWallet?: PaymentRequestPaymentMethodEvent
  }): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const priceId = selectSubscriptionLookupKey(state)
    const trialPrice = selectSubscriptionTrialPeriodPrice(state)
    const uuid = selectUUID(state)
    const currentPrice = selectSubscriptionFullPrice(state)
    const trialPeriodDays = selectSubscriptionTrialPeriodDays(state)
    const periodName = selectSubscriptionPeriodName(state)
    const periodQuantity = selectSubscriptionPeriodQuantity(state)
    const currency = selectCurrency(state)
    const cohort = selectCurrentVariantCohort(state)
    const inappId = selectInAppId(state)

    if (!priceId || !currentPrice) {
      console.error('Error: no subscriptions plan selected')
      return
    }

    dispatch(startFetching(PURCHASE))

    const paymentMethod =
      createPaymentResFromDigitalWallet?.paymentMethod?.card?.wallet?.type

    const commonPurchaseStartedParams =
      getStripePurchaseStartedEventParams(state)
    const commonPurchaseSuccessParams =
      getStripePurchaseSuccessEventParams(state)
    const commonPurchaseFailedParams = getStripePurchaseFailedEventParams(state)

    eventLogger.logPurchaseStarted({
      ...commonPurchaseStartedParams,
      paymentMethod,
      paymentSystem: PaymentSystem.STRIPE,
    })

    try {
      const createPaymentResponse =
        card && !createPaymentResFromDigitalWallet
          ? await stripe.createPaymentMethod({
              type: 'card',
              card,
              billing_details: { name: name || DEFAULT_CARDHOLDER_NAME },
            })
          : (createPaymentResFromDigitalWallet as PaymentMethodResult)

      if (
        !createPaymentResponse?.paymentMethod &&
        createPaymentResponse?.error
      ) {
        const {
          error: { type, code, message },
        } = createPaymentResponse

        eventLogger.logPurchaseFailed({
          ...commonPurchaseFailedParams,
          paymentMethod,
          error: {
            type,
            code,
            description: message,
          },
          paymentSystem: PaymentSystem.STRIPE,
        })
        dispatch(setErrorAction(message || type))
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      const createSubscriptionResponse = await paymentApi.createSubscription({
        uuid,
        trialPeriodDays,
        cohort,
        planId: Number(inappId),
      })

      if (
        !createSubscriptionResponse.success ||
        !createSubscriptionResponse.data
      ) {
        if (createSubscriptionResponse.status === 404) {
          dispatch(setErrorAction(i18n.t('purchase.linkIsNotValid')))
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        if (createSubscriptionResponse.status === 409) {
          dispatch(setErrorAction(i18n.t('purchase.haveSubscription')))
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        dispatch(setErrorAction(i18n.t('login.somethingWentWrongError')))
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      dispatch(
        setPaymentClientSecretAction(
          createSubscriptionResponse.data.purchase.client_secret,
        ),
      )
      dispatch(
        setTrialPeriodDaysAction(
          createSubscriptionResponse.data.purchase.trial_period_days,
        ),
      )
      dispatch(
        setSubscriptionIdAction(
          createSubscriptionResponse.data.purchase.subscription_id,
        ),
      )

      const cardPaymentResponseFirst = await stripe.confirmCardPayment(
        createSubscriptionResponse.data.purchase.client_secret,
        {
          payment_method: createPaymentResponse.paymentMethod.id,
          save_payment_method: true,
          return_url: getRedirectUrl(paymentPageId),
        },
      )

      if (
        !cardPaymentResponseFirst?.paymentIntent &&
        cardPaymentResponseFirst?.error
      ) {
        logFailedPayment({
          paymentResponse: cardPaymentResponseFirst.error,
          paymentMethod,
          ...commonPurchaseFailedParams,
        })

        if (!checkIsRetryAllowed(cardPaymentResponseFirst)) {
          dispatch(
            setErrorAction(
              getErrorActionPayload(cardPaymentResponseFirst.error),
            ),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        const retryResponseFirst = await paymentApi.retryPayment({
          uuid,
          stripeError: cardPaymentResponseFirst.error as StripeError,
        })

        if (!retryResponseFirst.data.should_retry) {
          dispatch(
            setErrorAction(
              getErrorActionPayload(cardPaymentResponseFirst.error),
            ),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        dispatch(
          setPaymentClientSecretAction(
            retryResponseFirst.data.subscription.client_secret,
          ),
        )
        dispatch(
          setTrialPeriodDaysAction(
            retryResponseFirst.data.subscription.trial_period_days,
          ),
        )
        dispatch(
          setSubscriptionIdAction(
            retryResponseFirst.data.subscription.subscription_id,
          ),
        )

        const cardPaymentResponseSecond = await stripe.confirmCardPayment(
          retryResponseFirst.data.subscription.client_secret,
          {
            payment_method: createPaymentResponse.paymentMethod.id,
            save_payment_method: true,
            return_url: getRedirectUrl(paymentPageId),
          },
        )

        if (
          cardPaymentResponseSecond?.paymentIntent &&
          !cardPaymentResponseSecond?.error
        ) {
          logSuccessfulPayment({
            ...commonPurchaseSuccessParams,
            trialPrice:
              cardPaymentResponseSecond.paymentIntent.amount / CENTS_IN_DOLLAR,
            productId: createSubscriptionResponse.data.purchase.product_id,
            trialPeriodDays:
              retryResponseFirst.data.subscription.trial_period_days,
            subscriptionId:
              retryResponseFirst.data.subscription.subscription_id,
            discountApplied:
              retryResponseFirst.data.subscription.discount_applied,
            paymentMethod,
          })
          dispatch(
            sendUserConfigAction(
              {
                payment_currency: currency,
                payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
                is_download_visited: false,
                discount_applied:
                  !!retryResponseFirst.data.subscription.discount_applied,
                subscription_price: `${currentPrice}`,
                subscription_duration: `${periodQuantity}${periodName}`,
                price_id: priceId,
                trial_price: `${trialPrice}`,
                trial_period: `${trialPeriodDays}`,
              },
              null,
            ),
          )
          dispatch(savePlanAdditionsAction())
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('success')
          return
        }

        logFailedPayment({
          paymentResponse: cardPaymentResponseSecond.error,
          paymentMethod,
          ...commonPurchaseFailedParams,
        })

        if (!checkIsRetryAllowed(cardPaymentResponseSecond)) {
          dispatch(
            setErrorAction(
              getErrorActionPayload(cardPaymentResponseSecond.error),
            ),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        const retryResponseSecond = await paymentApi.retryPayment({
          uuid,
          stripeError: cardPaymentResponseSecond.error as StripeError,
        })

        if (!retryResponseSecond.data.should_retry) {
          dispatch(
            setErrorAction(
              getErrorActionPayload(cardPaymentResponseSecond.error),
            ),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        dispatch(
          setPaymentClientSecretAction(
            retryResponseSecond.data.subscription.client_secret,
          ),
        )
        dispatch(
          setTrialPeriodDaysAction(
            retryResponseSecond.data.subscription.trial_period_days,
          ),
        )
        dispatch(
          setSubscriptionIdAction(
            retryResponseSecond.data.subscription.subscription_id,
          ),
        )

        const cardPaymentResponseThird = await stripe.confirmCardPayment(
          retryResponseSecond.data.subscription.client_secret,
          {
            payment_method: createPaymentResponse.paymentMethod.id,
            save_payment_method: true,
            return_url: getRedirectUrl(paymentPageId),
          },
        )

        if (
          cardPaymentResponseThird?.paymentIntent &&
          !cardPaymentResponseThird?.error
        ) {
          logSuccessfulPayment({
            ...commonPurchaseSuccessParams,
            trialPrice:
              cardPaymentResponseThird.paymentIntent.amount / CENTS_IN_DOLLAR,
            productId: createSubscriptionResponse.data.purchase.product_id,
            trialPeriodDays:
              retryResponseSecond.data.subscription.trial_period_days,
            subscriptionId:
              retryResponseSecond.data.subscription.subscription_id,
            discountApplied:
              retryResponseSecond.data.subscription.discount_applied,
            paymentMethod,
          })
          dispatch(
            sendUserConfigAction(
              {
                payment_currency: currency,
                payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
                is_download_visited: false,
                discount_applied:
                  !!retryResponseSecond.data.subscription.discount_applied,
                subscription_price: `${currentPrice}`,
                subscription_duration: `${periodQuantity}${periodName}`,
                price_id: priceId,
                trial_price: `${trialPrice}`,
                trial_period: `${trialPeriodDays}`,
              },
              null,
            ),
          )
          dispatch(savePlanAdditionsAction())
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('success')
          return
        }

        logFailedPayment({
          paymentResponse: cardPaymentResponseThird.error,
          paymentMethod,
          ...commonPurchaseFailedParams,
        })

        if (!checkIsRetryAllowed(cardPaymentResponseThird)) {
          dispatch(
            setErrorAction(
              getErrorActionPayload(cardPaymentResponseThird.error),
            ),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        const retryResponseThird = await paymentApi.retryPayment({
          uuid,
          stripeError: cardPaymentResponseThird.error as StripeError,
        })

        if (!retryResponseThird.data.should_retry) {
          dispatch(
            setErrorAction(
              getErrorActionPayload(cardPaymentResponseThird.error),
            ),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        dispatch(
          setPaymentClientSecretAction(
            retryResponseThird.data.subscription.client_secret,
          ),
        )
        dispatch(
          setTrialPeriodDaysAction(
            retryResponseThird.data.subscription.trial_period_days,
          ),
        )
        dispatch(
          setSubscriptionIdAction(
            retryResponseThird.data.subscription.subscription_id,
          ),
        )

        const cardPaymentResponseFourth = await stripe.confirmCardPayment(
          retryResponseThird.data.subscription.client_secret,
          {
            payment_method: createPaymentResponse.paymentMethod.id,
            save_payment_method: true,
            return_url: getRedirectUrl(paymentPageId),
          },
        )

        if (
          cardPaymentResponseFourth?.paymentIntent &&
          !cardPaymentResponseFourth?.error
        ) {
          logSuccessfulPayment({
            ...commonPurchaseSuccessParams,
            trialPrice:
              cardPaymentResponseFourth.paymentIntent.amount / CENTS_IN_DOLLAR,
            productId: createSubscriptionResponse.data.purchase.product_id,
            trialPeriodDays:
              retryResponseThird.data.subscription.trial_period_days,
            subscriptionId:
              retryResponseThird.data.subscription.subscription_id,
            discountApplied:
              retryResponseThird.data.subscription.discount_applied,
            paymentMethod,
          })
          dispatch(
            sendUserConfigAction(
              {
                payment_currency: currency,
                payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
                is_download_visited: false,
                discount_applied:
                  !!retryResponseThird.data.subscription.discount_applied,
                subscription_price: `${currentPrice}`,
                subscription_duration: `${periodQuantity}${periodName}`,
                price_id: priceId,
                trial_price: `${trialPrice}`,
                trial_period: `${trialPeriodDays}`,
              },
              null,
            ),
          )
          dispatch(savePlanAdditionsAction())
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('success')
          return
        }

        logFailedPayment({
          paymentResponse: cardPaymentResponseFourth.error,
          paymentMethod,
          ...commonPurchaseFailedParams,
        })

        dispatch(
          setErrorAction(
            getErrorActionPayload(cardPaymentResponseFourth.error),
          ),
        )

        // Needed for reset invoice on BE
        await paymentApi.retryPayment({
          uuid,
          stripeError: cardPaymentResponseFourth.error as StripeError,
        })

        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      const { paymentIntent } = cardPaymentResponseFirst

      if (paymentIntent.status === 'requires_payment_method') {
        eventLogger.logPurchaseFailed({
          ...commonPurchaseFailedParams,
          paymentMethod,
          paymentSystem: PaymentSystem.STRIPE,
          // eslint-disable-line max-lines
          error: {
            type: 'requires_payment_method',
          },
        })
        dispatch(setErrorAction(i18n.t('purchase.paymentFailed')))
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      const threeDSesureURL = paymentIntent.next_action?.redirect_to_url?.url

      if (paymentIntent.status === 'requires_action' && threeDSesureURL) {
        dispatch(set3DSecureIframeUrlAction(threeDSesureURL))
        dispatch(stopFetching(PURCHASE))
        return
      }

      logSuccessfulPayment({
        trialPeriodDays:
          createSubscriptionResponse.data.purchase.trial_period_days,
        subscriptionId:
          createSubscriptionResponse.data.purchase.subscription_id,
        paymentMethod,
        ...commonPurchaseSuccessParams,
        productId: createSubscriptionResponse.data.purchase.product_id,
      })

      dispatch(
        sendUserConfigAction(
          {
            payment_currency: currency,
            payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
            is_download_visited: false,
            subscription_price: `${currentPrice}`,
            subscription_duration: `${periodQuantity}${periodName}`,
            price_id: priceId,
            trial_price: `${trialPrice}`,
            trial_period: `${trialPeriodDays}`,
          },
          null,
        ),
      )
      dispatch(savePlanAdditionsAction())
      dispatch(stopFetching(PURCHASE))
      createPaymentResFromDigitalWallet?.complete('success')
    } catch (error: any) {
      dispatch(setErrorAction(error.toString()))
      dispatch(stopFetching(PURCHASE))
      createPaymentResFromDigitalWallet?.complete('fail')
    }
  }

export const check3DSecure =
  (stripe: Stripe): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const priceId = selectSubscriptionLookupKey(state)
    const trialPriceId = selectSubscriptionLookupKey(state)
    const currentPrice = selectSubscriptionFullPrice(state)
    const clientSecret = selectPaymentClientSecret(state)
    const trialPeriodDays = selectTrialPeriodDays(state)
    const subscriptionId = selectCreatedSubscriptionId(state)
    const currency = selectCurrency(state)
    const periodName = selectSubscriptionPeriodName(state)
    const periodQuantity = selectSubscriptionPeriodQuantity(state)
    const trialPeriodQuantity = selectSubscriptionTrialPeriodDays(state)
    const trialPrice = selectSubscriptionTrialPeriodPrice(state)

    if (!priceId || !currentPrice) {
      console.error('Error: no subscriptions plan selected')
      return
    }

    if (!clientSecret) {
      console.error('Error: client secret is needed')
      return
    }

    dispatch(startFetching(CHECK_3D_SECURE))

    const response = await stripe.retrievePaymentIntent(clientSecret)
    const commonPurchaseSuccessParams =
      getStripePurchaseSuccessEventParams(state)
    const commonPurchaseFailedParams = getStripePurchaseFailedEventParams(state)

    if (response.paymentIntent?.status === 'succeeded') {
      logSuccessfulPayment({
        trialPeriodDays,
        subscriptionId,
        ...commonPurchaseSuccessParams,
        productId: trialPriceId
          ? createIntroOfferProductId({
              priceId,
              trialPriceId,
              trialPeriodQuantity,
            })
          : priceId,
      })

      dispatch(
        sendUserConfigAction(
          {
            payment_currency: currency,
            payment_method: PaymentMethod.CREDIT_CARD,
            is_download_visited: false,
            subscription_price: `${currentPrice}`,
            subscription_duration: `${periodQuantity}${periodName}`,
            price_id: priceId,
            trial_price: `${trialPrice}`,
            trial_period: `${trialPeriodDays}`,
          },
          null,
        ),
      )
      return
    }

    if (response.paymentIntent?.status === 'requires_payment_method') {
      eventLogger.logPurchaseFailed({
        ...commonPurchaseFailedParams,
        paymentSystem: PaymentSystem.STRIPE,
        error: {
          type: 'requires_payment_method',
        },
      })
      dispatch(reset3DSecureIframeUrlAction())
      dispatch(setErrorAction(i18n.t('purchase.paymentFailed')))
      dispatch(stopFetching(CHECK_3D_SECURE))
      return
    }

    if (response.error) {
      const {
        error: { type, code, message },
      } = response

      eventLogger.logPurchaseFailed({
        ...commonPurchaseFailedParams,
        paymentSystem: PaymentSystem.STRIPE,
        error: {
          type,
          code,
          description: message,
        },
      })
      dispatch(reset3DSecureIframeUrlAction())
      dispatch(setErrorAction(message || type))
      dispatch(stopFetching(CHECK_3D_SECURE))
      return
    }

    dispatch(reset3DSecureIframeUrlAction())
    dispatch(setErrorAction(i18n.t('purchase.3dSecureError')))
    dispatch(stopFetching(CHECK_3D_SECURE))
  }

export const setPaymentMethodAction = (
  payload: PaymentMethod,
): IAction<PaymentMethod> => ({
  type: SET_PAYMENT_METHOD,
  payload,
})

export const setPrimerClientSessionTokenAction = (
  payload: string,
): IAction<string> => ({
  type: SET_PRIMER_CLIENT_SESSION_TOKEN,
  payload,
})

export const setBackupPrimerSubscriptionConfigAction = (
  payload: IPrimerSubscriptionBackupConfig | null,
): IAction<IPrimerSubscriptionBackupConfig | null> => ({
  type: SET_BACKUP_PRIMER_SUBSCRIPTION_CONFIG,
  payload,
})

export function setIsPrimerRetryProcessing(
  isPrimerRetryProcessing: boolean,
): IAction<boolean> {
  return {
    type: SET_IS_PRIMER_RETRY_PROCESSING,
    payload: isPrimerRetryProcessing,
  }
}

export function getPrimerClientSessionTokenAction(): any {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const uuid = selectUUID(state)
    const planId = selectSubscriptionPlanId(state)
    const trialPeriodDays = selectSubscriptionTrialPeriodDays(state)
    const cohort = selectCurrentVariantCohort(state)

    dispatch(startFetching(GET_PRIMER_CLIENT_SESSION_TOKEN))

    const response = await paymentApi.getPrimerClientSessionToken({
      uuid,
      cohort,
      planId,
      trialPeriodDays,
    })

    if (response.success && response.data) {
      dispatch(setPrimerClientSessionTokenAction(response.data.client_token))
    }

    dispatch(stopFetching(GET_PRIMER_CLIENT_SESSION_TOKEN))
  }
}

export function primerConfirmPaymentAction(
  paymentId: string,
  handler: OnTokenizeSuccessHandler,
): any {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const priceId = selectSubscriptionLookupKey(state)
    const uuid = selectUUID(state)
    const trialPeriodDays = selectSubscriptionTrialPeriodDays(state)
    const currentPrice = selectSubscriptionFullPrice(state)
    const trialPrice = selectSubscriptionTrialPeriodPrice(state)
    const currency = selectCurrency(state)
    const periodName = selectSubscriptionPeriodName(state)
    const periodQuantity = selectSubscriptionPeriodQuantity(state)
    const paymentMethod = selectPaymentMethod(state)

    const commonPurchaseFailedParams = getStripePurchaseFailedEventParams(state)

    dispatch(
      setBackupPrimerSubscriptionConfigAction({
        paymentCurrency: currency,
        paymentMethod,
        subscriptionPrice: currentPrice,
        subscriptionDuration: `${periodQuantity}${periodName}`,
        priceId,
        trialPrice,
        trialPeriod: `${trialPeriodDays}`,
        paymentSystem: PaymentSystem.PRIMER,
      }),
    )

    try {
      const response = await paymentApi.confirmPrimerSubscription({
        uuid,
        paymentId,
      })

      if (!response.data && !window.navigator.onLine) {
        dispatch(setErrorAction(i18n.t(PRIMER_PAYMENT_ERRORS.COMMON_ERROR)))
        dispatch(stopFetching(PURCHASE))
        dispatch(setIsPrimerRetryProcessing(false))
        return
      }

      if (!response.success && response?.data?.error) {
        eventLogger.logPurchaseFailed({
          ...commonPurchaseFailedParams,
          paymentMethod,
          error: response?.data?.error,
          paymentSystem: PaymentSystem.PRIMER,
        })
        dispatch(setErrorAction(response?.data?.error))
        dispatch(stopFetching(PURCHASE))
        dispatch(setIsPrimerRetryProcessing(false))

        handler.handleFailure(response?.data?.error)
        return
      }

      dispatch(savePlanAdditionsAction())
      dispatch(stopFetching(PURCHASE))
      dispatch(setIsPrimerRetryProcessing(false))
      handler.handleSuccess()
    } catch (error: any) {
      dispatch(setErrorAction(error.toString()))
      dispatch(setIsPrimerRetryProcessing(false))
      dispatch(stopFetching(PURCHASE))
      handler.handleFailure(error.toString())
    }
  }
}

export const setIsFirstPaymentRetryPassedAction = (
  payload: boolean,
): IAction<boolean> => ({
  type: SET_IS_FIRST_PAYMENT_RETRY_PASSED,
  payload,
})

export function updatePrimerClientSessionAction(
  retryCallback: () => void,
): any {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const uuid = selectUUID(state)
    const clientToken = selectPrimerClientSessionToken(state)
    const paymentId = selectUserPaymentId(state)

    dispatch(startFetching(UPDATE_PRIMER_CLIENT_SESSION))

    try {
      const response = await paymentApi.updatePrimerClientSession({
        uuid,
        paymentId,
        clientToken,
      })

      dispatch(stopFetching(UPDATE_PRIMER_CLIENT_SESSION))

      if (response.success && response.data && response.data.should_retry) {
        dispatch(stopFetching(PURCHASE))
        dispatch(setPrimerClientSessionTokenAction(response.data.client_token))
        dispatch(setIsPrimerRetryProcessing(true))

        retryCallback()
        return
      }

      const error = i18n.t(
        PRIMER_PAYMENT_ERRORS[response.data.payment_error] ||
          PRIMER_PAYMENT_ERRORS.COMMON_ERROR,
      )

      dispatch(setIsPrimerRetryProcessing(false))
      dispatch(setErrorAction(error))
      dispatch(stopFetching(UPDATE_PRIMER_CLIENT_SESSION))
      dispatch(stopFetching(PURCHASE))
    } catch (error: any) {
      dispatch(setIsPrimerRetryProcessing(false))
      dispatch(setErrorAction(error.toString()))
      dispatch(stopFetching(PURCHASE))
      dispatch(stopFetching(UPDATE_PRIMER_CLIENT_SESSION))
    }
  }
}

export function primerPurchaseAction(
  token: string,
  handler: OnTokenizeSuccessHandler,
  retryPaymentCallback: () => void,
): any {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const uuid = selectUUID(state)

    const paymentMethod = selectPaymentMethod(state)
    const trialPeriodPrice = selectSubscriptionTrialPeriodPrice(state)
    const priceId = selectSubscriptionLookupKey(state)
    const trialPeriodDays = selectSubscriptionTrialPeriodDays(state)
    const currentPrice = selectSubscriptionFullPrice(state)
    const trialPrice = selectSubscriptionTrialPeriodPrice(state)
    const currency = selectCurrency(state)
    const periodName = selectSubscriptionPeriodName(state)
    const periodQuantity = selectSubscriptionPeriodQuantity(state)
    const discountTried = selectIsFirstPaymentRetryPassed(state)

    const commonPurchaseFailedParams = getStripePurchaseFailedEventParams(state)
    const commonPurchaseSuccessParams =
      getStripePurchaseSuccessEventParams(state)

    dispatch(startFetching(PURCHASE))

    try {
      const response = await paymentApi.createPrimerSubscription({
        uuid,
        token,
      })

      if (!response.data && !window.navigator.onLine) {
        dispatch(setErrorAction(i18n.t(PRIMER_PAYMENT_ERRORS.COMMON_ERROR)))
        dispatch(stopFetching(PURCHASE))
        dispatch(setIsPrimerRetryProcessing(false))
        return
      }

      if (!response.data.payment.is_success) {
        const error = {
          type: response.data.payment.status_reason.type,
          description: response.data.payment.status_reason.message,
          code: response.data.payment.status_reason.code,
        }

        eventLogger.logPurchaseFailed({
          ...commonPurchaseFailedParams,
          paymentMethod,
          error,
          paymentSystem: PaymentSystem.PRIMER,
        })

        handler.handleFailure(error.description)

        dispatch(
          setErrorAction(
            i18n.t(
              PRIMER_PAYMENT_ERRORS[error.code] ||
                PRIMER_PAYMENT_ERRORS.COMMON_ERROR,
            ),
          ),
        )
        dispatch(stopFetching(PURCHASE))
        dispatch(setIsPrimerRetryProcessing(false))

        if (
          response?.data.payment.status_reason.declineType ===
            PRIMER_SOFT_DECLINE &&
          paymentMethod === PaymentMethod.CREDIT_CARD &&
          !discountTried
        ) {
          dispatch(updatePrimerClientSessionAction(retryPaymentCallback))
        }

        return
      }

      dispatch(
        setPrimerClientSessionTokenAction(response.data.payment.client_token),
      )

      if (response.data.payment.client_token) {
        dispatch(
          updateUserConfigAction({
            payment_id: response.data.payment.payment_id,
          }),
        )

        handler.continueWithNewClientToken(response.data.payment.client_token)
        return
      }

      dispatch(
        primerConfirmPaymentAction(response.data.payment.payment_id, handler),
      )

      logSuccessfulPayment({
        ...commonPurchaseSuccessParams,
        price: currentPrice,
        trialPrice: trialPeriodPrice,
        trialPeriodDays,
        discountApplied: response.data.purchase.discount_applied,
        subscriptionId: response.data.purchase.subscription_id,
        paymentMethod,
        paymentSystem: PaymentSystem.PRIMER,
        productId: response.data.purchase.product_id,
      })

      dispatch(
        sendUserConfigAction({
          payment_currency: currency,
          payment_method: paymentMethod,
          payment_system: PaymentSystem.PRIMER,
          is_download_visited: false,
          discount_applied: !!response.data.purchase.discount_applied,
          subscription_price: `${currentPrice}`,
          subscription_duration: `${periodQuantity}${periodName}`,
          price_id: priceId,
          screen_name: commonPurchaseSuccessParams.screenName,
          product_name: commonPurchaseSuccessParams.productId,
          period_quantity: periodQuantity,
          trial_price: `${trialPrice}`,
          trial_period: `${trialPeriodDays}`,
        }),
      )
    } catch (error: any) {
      dispatch(setErrorAction(error.toString()))
      dispatch(setIsPrimerRetryProcessing(false))
      dispatch(stopFetching(PURCHASE))
      handler.handleFailure(error.toString())
    }
  }
}

export function cancelPrimerClientSessionDiscount({
  clientToken,
}: {
  clientToken: string
}): any {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const uuid = selectUUID(state)
    dispatch(startFetching(CANCEL_PRIMER_CLIENT_SESSION_DISCOUNT))

    try {
      const response = await paymentApi.updatePrimerClientSession({
        uuid,
        clientToken,
      })

      dispatch(stopFetching(CANCEL_PRIMER_CLIENT_SESSION_DISCOUNT))

      if (!response.success && response?.data?.error) {
        dispatch(setErrorAction(i18n.t(PRIMER_PAYMENT_ERRORS.COMMON_ERROR)))

        return
      }
    } catch (error: any) {
      dispatch(stopFetching(CANCEL_PRIMER_CLIENT_SESSION_DISCOUNT))
      dispatch(setErrorAction(error.toString()))
      dispatch(stopFetching(PURCHASE))
    }
  }
}

export function primerResumePurchaseAction(
  currentPaymentId: string | undefined,
  resumeToken: string,
  handler: OnResumeSuccessHandler,
  retryPaymentCallback: () => void,
): any {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const uuid = selectUUID(state)
    const {
      paymentMethod,
      trialCurrentPrice,
      trialPeriodDays,
      fullPrice,
      currency,
      periodName,
      periodQuantity,
    } = usePaymentStore()
    const paymentId = selectUserPaymentId(state)
    const priceId = selectSubscriptionLookupKey(state)
    const discountTried = selectIsFirstPaymentRetryPassed(state)

    const commonPurchaseSuccessParams =
      getStripePurchaseSuccessEventParams(state)
    const commonPurchaseFailedParams = getStripePurchaseFailedEventParams(state)

    dispatch(startFetching(PURCHASE))

    try {
      const response = await paymentApi.resumePrimerSubscription({
        uuid,
        paymentId: currentPaymentId || paymentId,
        resumeToken,
      })

      if (!response.data && !window.navigator.onLine) {
        dispatch(setErrorAction(i18n.t(PRIMER_PAYMENT_ERRORS.COMMON_ERROR)))
        dispatch(stopFetching(PURCHASE))
        dispatch(setIsPrimerRetryProcessing(false))
        return
      }

      if (!response.data.payment.is_success) {
        const error = {
          type: response.data.payment.status_reason.type,
          description: response.data.payment.status_reason.message,
          code: response.data.payment.status_reason.code,
        }

        eventLogger.logPurchaseFailed({
          ...commonPurchaseFailedParams,
          paymentMethod,
          error,
          paymentSystem: PaymentSystem.PRIMER,
        })

        handler.handleFailure(error.description)

        dispatch(
          setErrorAction(
            i18n.t(
              PRIMER_PAYMENT_ERRORS[error.code] ||
                PRIMER_PAYMENT_ERRORS.COMMON_ERROR,
            ),
          ),
        )
        dispatch(stopFetching(PURCHASE))
        dispatch(setIsPrimerRetryProcessing(false))

        if (
          response?.data.payment.status_reason.declineType ===
            PRIMER_SOFT_DECLINE &&
          paymentMethod === PaymentMethod.CREDIT_CARD &&
          !discountTried
        ) {
          dispatch(updatePrimerClientSessionAction(retryPaymentCallback))
        }

        return
      }

      if (response.data.payment.client_token) {
        handler.continueWithNewClientToken(response.data.payment.client_token)
        return
      }

      dispatch(
        primerConfirmPaymentAction(response.data.payment.payment_id, handler),
      )
      dispatch(
        setPrimerClientSessionTokenAction(response.data.payment.client_token),
      )

      logSuccessfulPayment({
        ...commonPurchaseSuccessParams,
        price: fullPrice,
        trialPrice: trialCurrentPrice,
        trialPeriodDays,
        discountApplied: response.data.purchase.discount_applied,
        subscriptionId: response.data.purchase.subscription_id,
        paymentMethod,
        paymentSystem: PaymentSystem.PRIMER,
        productId: response.data.purchase.product_id,
      })

      dispatch(
        sendUserConfigAction({
          payment_currency: currency,
          payment_method: paymentMethod,
          payment_system: PaymentSystem.PRIMER,
          is_download_visited: false,
          discount_applied: !!response.data.purchase.discount_applied,
          subscription_price: `${fullPrice}`,
          subscription_duration: `${periodQuantity}${periodName}`,
          price_id: priceId,
          screen_name: commonPurchaseSuccessParams.screenName,
          product_name: commonPurchaseSuccessParams.productId,
          period_quantity: periodQuantity,
          trial_price: `${trialCurrentPrice}`,
          trial_period: `${trialPeriodDays}`,
        }),
      )
      handler.handleSuccess()
    } catch (error: any) {
      dispatch(setErrorAction(error.toString()))
      dispatch(setIsPrimerRetryProcessing(false))
      dispatch(stopFetching(PURCHASE))
      handler.handleFailure(error.toString())
    }
  }
}
