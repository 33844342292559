import styled from 'styled-components'

import { PageTitle } from 'components/PageTitle'

import pageBg from 'assets/images/you-are-not-alone.png'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledYouAreNotAlone = {
  ImageContainer: styled.div`
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    margin: 0 auto;
    padding-top: 10px;
    background-image: url(${pageBg});
    background-repeat: no-repeat;
    background-size: contain;
    aspect-ratio: 360/358;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
    }
  `,
  Title: styled(PageTitle)`
    margin: -10px 0 24px;
  `,
  Subtitle: styled.p`
    margin-bottom: 24px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: ${Color.PRIMARY};

    strong {
      color: ${Color.GREY_100};
    }
  `,
  Description: styled.p`
    color: ${Color.GREY_100};
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 30px;
    text-align: center;
  `,
}
