import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { ActivityDuringWorkValue } from 'value-constants'

import { AnswerButton, IconVerticalAlignments } from 'components/AnswerButton'
import { Container } from 'components/Container'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import activeFemaleIcon from 'assets/images/active-female.png'
import activeMaleIcon from 'assets/images/active-male.png'
import normalFemaleIcon from 'assets/images/normal-female.png'
import normalMaleIcon from 'assets/images/normal-male.png'
import passiveFemaleIcon from 'assets/images/passive-female.png'
import passiveMaleIcon from 'assets/images/passive-male.png'

import { CustomPageId } from 'page-constants'
import { Gender, Language } from 'root-constants'

export const ActivityDuringWork: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const gender = useSelector(selectCurrentUserGender)

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId: CustomPageId.ACTIVITY_DURING_WORK,
    question: t('onboarding.activityDuringWork.title', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: CustomPageId.ACTIVITY_DURING_WORK,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [handleChange, setIsAnswersDisabled, handleContinue],
  )

  const isFemale = useMemo(() => gender === Gender.FEMALE, [gender])

  return (
    <Container>
      <PageTitle marginBottom={24}>
        {t('onboarding.activityDuringWork.title')}
      </PageTitle>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={ActivityDuringWorkValue.PASSIVE}
      >
        <AnswerButton
          iconSrc={isFemale ? passiveFemaleIcon : passiveMaleIcon}
          iconWidth={92}
          iconHeight={92}
          iconVerticalAlignment={IconVerticalAlignments.CENTER}
        >
          <h3>
            <Trans i18nKey="onboarding.activityDuringWork.options.passive" />
          </h3>
        </AnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={ActivityDuringWorkValue.ACTIVE}
      >
        <AnswerButton
          iconSrc={isFemale ? activeFemaleIcon : activeMaleIcon}
          iconWidth={92}
          iconHeight={92}
          iconVerticalAlignment={IconVerticalAlignments.CENTER}
        >
          <h3>{t('onboarding.activityDuringWork.options.active')}</h3>
        </AnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={ActivityDuringWorkValue.NORMAL}
      >
        <AnswerButton
          iconSrc={isFemale ? normalFemaleIcon : normalMaleIcon}
          iconWidth={92}
          iconHeight={92}
          iconVerticalAlignment={IconVerticalAlignments.CENTER}
        >
          <h3>{t('onboarding.activityDuringWork.options.normal')}</h3>
        </AnswerButton>
      </Option>
    </Container>
  )
}
