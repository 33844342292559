import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Swiper, { Autoplay, Navigation, Pagination } from 'swiper'
import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { Gender } from 'root-constants'

import { StyledPhotoResultV2 as S } from './PhotoResultV2.styles'
import { PHOTO_RESULT_MAP_V2 } from './constants'

type TProps = {
  titleI18nKey?: string
  gender?: Gender
}

Swiper.use([Pagination, Autoplay, Navigation])

export const PhotoResultV2 = ({ titleI18nKey, gender }: TProps) => {
  const { t } = useTranslation()

  const currentUserGender = useSelector(selectCurrentUserGender)

  const slideList = useMemo(
    () => PHOTO_RESULT_MAP_V2[gender || currentUserGender],
    [currentUserGender, gender],
  )

  return (
    <S.Container>
      <S.Title>
        <Trans i18nKey={titleI18nKey || 'purchase.photoResultV2.title'} />
      </S.Title>
      <S.CarouselContainer>
        <Carousel
          spaceBetween={20}
          slidesPerView={1}
          loop
          navigation
          pagination={{ clickable: true }}
          autoplay={{ delay: 5000, disableOnInteraction: false }}
        >
          {slideList.map((imgSrc) => (
            <SwiperSlide key={imgSrc}>
              <S.BeforeAfterImg>
                <img src={imgSrc} alt="before-after" />
              </S.BeforeAfterImg>
            </SwiperSlide>
          ))}
          <S.BeforeAfterLabels>
            <S.Before>{t('commonComponents.before')}</S.Before>
            <S.After>{t('commonComponents.after')}</S.After>
          </S.BeforeAfterLabels>
          <S.Disclaimer>
            <Trans i18nKey="purchase.photoResultV2.disclaimer" />
          </S.Disclaimer>
        </Carousel>
      </S.CarouselContainer>
    </S.Container>
  )
}
