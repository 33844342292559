import styled, { css } from 'styled-components'

import { Input } from 'components/Input'

import { Color } from 'root-constants'

type TAgeProps = {
  value: string
  isValid: boolean
}

export const StyledAge = {
  InputContainer: styled.div`
    position: relative;
    max-width: 464px;
    margin: 0 auto;
  `,
  AgeInput: styled(Input)<TAgeProps>`
    border: 1px solid transparent;

    ${({ isValid, value }) =>
      value &&
      !isValid &&
      css`
        background: #fff2f2;
        border: 1px solid ${Color.DANGER};
        color: ${Color.DANGER};
      `};

    &:focus,
    &:active {
      & ~ span {
        display: none;
      }
    }
  `,
  ErrorMessage: styled.p`
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin-top: 9px;
    color: ${Color.DANGER};

    & svg {
      width: 18px;
      height: 18px;
      margin-right: 4px;
    }
  `,
  Placeholder: styled.span`
    position: absolute;
    top: calc(50% - 2px);
    left: calc(50% - 7px);
    height: 4px;
    width: 14px;
    background-color: ${Color.DISABLED};
    pointer-events: none;
  `,
  InputContainerV2: styled.div`
    position: relative;
    margin: 0 auto 24px;
  `,
  InfoContainer: styled.div`
    background: linear-gradient(0deg, #e3f2ff 0%, #e3f2ff 100%), #f5f5f5;
    border-radius: 12px;
    column-gap: 8px;
    display: flex;
    padding: 16px;
  `,
  InfoImage: styled.div`
    aspect-ratio: 1 / 1;
    min-width: 24px;
    height: 24px;
  `,
  Info: styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 6px;
  `,
  InfoTitle: styled.h4`
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  `,
  InfoText: styled.p`
    font-size: 12px;
    font-weight: 40;
    line-height: 16px;
  `,
}
