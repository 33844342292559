import styled, { css } from 'styled-components'

import checkIcon from 'assets/images/check-white.svg'
import arrowRight from 'assets/images/grey-arrow-right.svg'
import dayPriceLabelActive from 'assets/images/price-label-active.png'
import dayPriceLabel from 'assets/images/price-label.png'

import { Color } from 'root-constants'

const commonPriceStyles = css`
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: ${Color.DISABLED};
`

export const StyledSubscriptionItemV5 = {
  PopularBadge: styled.div`
    background-color: ${Color.PRIMARY};
    border-radius: 8px 8px 0 0;
    padding: 4px 0;

    color: ${Color.WHITE};
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    text-transform: uppercase;
  `,
  PlanContainer: styled.div<{ isPopular?: boolean; isSelected?: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 8px 16px 8px 44px;
    border-radius: ${({ isPopular }) => (isPopular ? `0 0 8px 8px` : `8px`)};
    background-color: ${({ isSelected }) =>
      isSelected ? `${Color.GREEN_200}` : `${Color.GREEN_100}`};

    border: 1px solid
      ${({ isSelected }) => (isSelected ? `${Color.PRIMARY}` : `transparent`)};

    ${({ isSelected, isPopular }) =>
      isSelected && isPopular && `border-top: 1px solid transparent;`}

    &::before {
      content: '';
      width: 20px;
      height: 20px;
      mix-blend-mode: normal;
      border: 2px solid ${Color.PRIMARY};
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 16px;
      transform: translateY(-50%);
      ${({ isSelected }) =>
        isSelected &&
        `
          background-image: url(${checkIcon});
          background-position: center center;
          background-repeat: no-repeat;
          background-color: ${Color.PRIMARY};
        `};
    }
  `,
  PlanColumn: styled.div<{ hasLabel?: boolean; isSelected?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${({ hasLabel }) =>
      hasLabel &&
      `
      width: 106px;
      height: 72px;
      transition: background 0.3s ease-out;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url(${dayPriceLabel});
      background-size: 100%;
    `}

    ${({ isSelected }) =>
      isSelected &&
      `
        background-image: url(${dayPriceLabelActive});
    `}
  `,
  PlanPeriod: styled.span`
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    color: ${Color.GREY_100};
    margin: 0 0 4px 0;
  `,
  PlanPrice: styled.div`
    line-height: 14px;
  `,
  PreviousPrice: styled.span`
    ${commonPriceStyles};
    text-decoration: line-through;
    text-decoration-color: ${Color.RED_200};
    padding: 0 18px 0 0;
    margin: 0 5px 0 0;
    position: relative;

    &::after {
      content: url(${arrowRight});
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      line-height: 1px;
      margin: 0 0 0 5px;
    }
  `,
  CurrentPrice: styled.span`
    ${commonPriceStyles};
  `,
  DayPriceContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 0 20px;
  `,
  CurrentDayPrice: styled.span`
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    color: ${Color.GREY_100};
  `,
  PreviousDayPrice: styled.span`
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: ${Color.GREY_110};
    text-decoration: line-through;
    text-decoration-color: ${Color.GREY_110};
  `,
  DayPricePeriod: styled.span`
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    color: ${Color.GREY_110};
  `,
}
