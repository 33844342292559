import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Container } from 'components/Container'
import { Logo } from 'components/Logo'
import { ProgressAnalyzer } from 'components/ProgressAnalyzer'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import { useCounter } from 'hooks/useCounter'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Cohort } from 'root-constants'

import { StyledGoodHands as S } from './GoodHands.styles'
import { QUESTION } from './constants'

export const GoodHands: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const { counter } = useCounter(true, {
    duration: 7500,
    callback: handleContinue,
  })

  const subtitle = useMemo(
    () =>
      cohortToUse === Cohort.MF_MEN_FLOW
        ? 'onboarding.goodHands.subTitleV2'
        : 'onboarding.goodHands.subTitleV1',
    [cohortToUse],
  )

  return (
    <>
      <S.ImageContainer>
        <Logo marginBottom={28} />
        <S.Title>{t('onboarding.goodHands.title')}</S.Title>
      </S.ImageContainer>

      <Container>
        <S.CountWrapper>
          <S.Count>1,100,000+</S.Count>
        </S.CountWrapper>
        <S.Subtitle>
          <Trans i18nKey={subtitle} />
        </S.Subtitle>
        <S.ProgressbarContainer>
          <ProgressAnalyzer value={counter}>
            {t('onboarding.goodHands.progressTitle')}
          </ProgressAnalyzer>
        </S.ProgressbarContainer>
      </Container>
    </>
  )
}
