import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { Container } from 'components/Container'
import { Footer } from 'components/Footer'
import { Option, OptionType } from 'components/Option'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'
import { useTitleFromUrl } from 'hooks/useTitleFromUrl'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import stars from 'assets/images/alternative-stars.png'
import banner from 'assets/images/gender-v3-banner.webp'
import femaleImage from 'assets/images/intro-female-3.png'
import maleImage from 'assets/images/intro-male-3.png'

import { CustomPageId } from 'page-constants'
import { Gender } from 'root-constants'

import {
  StyledAlternativeGenderV3 as A,
  StyledGenderV3 as S,
} from './GenderV3.styles'
import { QUESTION } from './constants'

export const GenderV3: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()

  const { title, subtitle } = useDynamicOBConfig()
  const { title: titleFromUrl, subText: subtitleFromUrl } = useTitleFromUrl()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()
  const isAlternativeVariant = useFeatureIsOn('fas_52_first_page')

  const handleContinue = useNextStep({
    pageId: CustomPageId.GENDER,
    question: titleFromUrl || title || QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: CustomPageId.GENDER,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
        eventLogger.logFirstPageCompleted({
          question: titleFromUrl || title || QUESTION,
          answers: value,
        })
      },
    }),
    [handleChange, handleContinue, setIsAnswersDisabled, title, titleFromUrl],
  )

  return isAlternativeVariant ? (
    <A.Container>
      <A.Banner>
        <img src={banner} alt="banner" />
      </A.Banner>
      <A.Content>
        <A.Title>
          {titleFromUrl || title || (
            <Trans i18nKey="onboarding.gender.alternativeTitle" />
          )}
        </A.Title>
        <A.Subtitle>
          {subtitleFromUrl || subtitle || (
            <Trans i18nKey="onboarding.gender.alternativeSubtitle" />
          )}
        </A.Subtitle>
        <A.FeedbackContainer>
          <A.Stars>
            <img src={stars} alt="stars" />
          </A.Stars>
          <A.Feedback>
            <Trans i18nKey="onboarding.gender.feedback" />
          </A.Feedback>
        </A.FeedbackContainer>
        <A.CallToAction>
          <Trans i18nKey="onboarding.gender.alternativeCallToAction" />
        </A.CallToAction>
        <A.Actions>
          <A.Action data-gender={Gender.MALE}>
            <Option
              {...optionProps}
              disabled={isAnswersDisabled}
              value={Gender.MALE}
            >
              {t('onboarding.gender.male')}
            </Option>
          </A.Action>
          <A.Action data-gender={Gender.FEMALE}>
            <Option
              {...optionProps}
              disabled={isAnswersDisabled}
              value={Gender.FEMALE}
            >
              {t('onboarding.gender.female')}
            </Option>
          </A.Action>
        </A.Actions>
      </A.Content>
    </A.Container>
  ) : (
    <Container>
      <S.Title>
        {titleFromUrl || title || <Trans i18nKey="onboarding.gender.title" />}
      </S.Title>
      <S.Subtitle>
        {subtitleFromUrl || subtitle || t('onboarding.gender.subtitle')}
      </S.Subtitle>
      <S.CallToAction>{t('onboarding.gender.callToAction')}</S.CallToAction>

      <S.Actions>
        <Option
          {...optionProps}
          disabled={isAnswersDisabled}
          value={Gender.MALE}
        >
          <S.Action>
            <S.MaleImage src={maleImage} alt="" />
            <S.AnswerForMale />
            <S.MaleGender>{t('onboarding.gender.male')}</S.MaleGender>
          </S.Action>
        </Option>
        <Option
          {...optionProps}
          disabled={isAnswersDisabled}
          value={Gender.FEMALE}
        >
          <S.Action>
            <S.FemaleImage src={femaleImage} alt="" />
            <S.AnswerForFemale />
            <S.FemaleGender>{t('onboarding.gender.female')}</S.FemaleGender>
          </S.Action>
        </Option>
      </S.Actions>
      <div>
        <Footer />
      </div>
    </Container>
  )
}
