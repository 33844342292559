import React, { useEffect, useMemo, useRef } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import lottie from 'lottie-web/build/player/lottie_light'

import {
  selectCurrentUserGender,
  selectLanguage,
} from 'root-redux/selects/common'

import { useUsersBmi } from 'hooks/useUsersBmi'

import {
  ANIMATION_PATH_MAP,
  CURRENT_FAT_LEVEL_MAP,
  GOAL_FAT_LEVEL_MAP,
} from 'modules/purchase/components/IntroMediaV5/constants'

import { CDN_FOLDER_LINK } from 'root-constants'

import { StyledIntroMediaV5 as S } from './IntroMediaV5.styles'

type TProps = {
  className?: string
}

export const IntroMediaV5: React.FC<TProps> = ({ className }) => {
  const gender = useSelector(selectCurrentUserGender)
  const language = useSelector(selectLanguage)
  const { currentBMI } = useUsersBmi()

  const animationRef = useRef<HTMLDivElement>(null)

  const animationPath = useMemo(
    () =>
      `${
        ANIMATION_PATH_MAP[gender][currentBMI.bmiValue.value]
      }-${language}.json`,
    [gender, currentBMI.bmiValue.value, language],
  )

  const currentFatLevel = useMemo(
    () => CURRENT_FAT_LEVEL_MAP[gender][currentBMI.bmiValue.value],
    [gender, currentBMI],
  )

  const goalFatLevel = useMemo(() => GOAL_FAT_LEVEL_MAP[gender], [gender])

  useEffect(() => {
    if (animationRef.current) {
      lottie.loadAnimation({
        container: animationRef.current,
        path: `${CDN_FOLDER_LINK}${animationPath}`,
        loop: true,
      })
    }
  }, [animationPath])

  return (
    <div className={className}>
      <S.BeforeAfter ref={animationRef} />
      <S.FatLevel>
        <S.FatValue>
          <Trans
            i18nKey="purchase.introMediaV5.bodyFat"
            values={{
              bodyFat: currentFatLevel,
            }}
          />
        </S.FatValue>
        <S.FatValue>
          <Trans
            i18nKey="purchase.introMediaV5.bodyFat"
            values={{
              bodyFat: goalFatLevel,
            }}
          />
        </S.FatValue>
      </S.FatLevel>
    </div>
  )
}
