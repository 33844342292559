import React, { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { StyledBenefits as S } from 'pages/questions/Benefits/Benefits.styles'

import { Button } from 'components/Button'
import { PageTitle } from 'components/PageTitle'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import men from 'assets/images/benefits-men.png'
import women from 'assets/images/benefits-women.png'

import { Gender } from 'root-constants'

import { BENEFITS_LIST_ITEM, QUESTION } from './constants'

export const Benefits: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const gender = useSelector(selectCurrentUserGender)

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  const bannerSrc = useMemo(
    () => (gender === Gender.MALE ? men : women),
    [gender],
  )

  return (
    <>
      <S.Logo />
      <S.Banner>
        <img src={bannerSrc} alt="banner" />
      </S.Banner>
      <S.Container>
        <PageTitle marginBottom={24}>
          {t('onboarding.benefits.title')}
        </PageTitle>
        <S.Subtitle>{t('onboarding.benefits.subTitle')}</S.Subtitle>
        <S.Title2>{t('onboarding.benefits.title2')}</S.Title2>
        <S.Subtitle>{t('onboarding.benefits.subTitle2')}</S.Subtitle>
        <S.WrapperLine>
          <S.Line />
        </S.WrapperLine>
        <S.Title2>
          <Trans i18nKey="onboarding.benefits.listTitle" />
        </S.Title2>
        <ul>
          {BENEFITS_LIST_ITEM.map(({ id, description }) => (
            <S.ListItem key={id}>
              <S.ItemContainer>{t(description)}</S.ItemContainer>
            </S.ListItem>
          ))}
        </ul>
        <StickyButtonContainer>
          <Button type="button" onClick={handleNextClick}>
            {t('actions.continue')}
          </Button>
        </StickyButtonContainer>
      </S.Container>
    </>
  )
}
