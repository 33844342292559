import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { Spinner } from 'components/Spinner'

import {
  getSubscriptionListAction,
  setHasHeaderSkipButton,
  setHeaderSkipButtonCallback,
} from 'root-redux/actions/common'
import { TAppDispatch } from 'root-redux/store/store'

import { useVatInfo } from 'hooks/useHasVatInfo'
import {
  ViewportScrollPercentage,
  useViewportValue,
} from 'hooks/useViewportValue'

import { IntroMediaV6 } from 'modules/purchase/components/IntroMediaV6'
import { PhotoResultV2 } from 'modules/purchase/components/PhotoResult'
import { StepsV1 } from 'modules/purchase/components/Steps'
import { UpsellDiscountBannerV1 } from 'modules/purchase/components/UpsellDiscountBanner'
import { UpsellDiscountModalV1 } from 'modules/purchase/components/UpsellDiscountModal'
import { UpsellFeedbacksV1 } from 'modules/purchase/components/UpsellFeedbacks'
import { UpsellSubscriptionBlockV2 } from 'modules/purchase/components/UpsellSubscriptionBlockV2'
import { WhatYouGetV2 } from 'modules/purchase/components/WhatYouGet'
import { selectSubscription } from 'modules/purchase/redux/selects'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import {
  Gender,
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
} from 'root-constants'

import { StyledUpsellPaywallV2 as S } from './UpsellPaywallV2.styles'

type TProps = {
  isUpsellInProgress: boolean
  arePricesReady: boolean
  makeUpsell: (screenName: ScreenName) => void
}

type TDiscountModal = {
  isVisible: boolean
  isPreviouslyOpened: boolean
}

const discountModalDefaultState = {
  isVisible: false,
  isPreviouslyOpened: false,
}

export const UpsellPaywallV2: FC<TProps> = ({
  makeUpsell,
  isUpsellInProgress,
  arePricesReady,
}) => {
  const dispatch: TAppDispatch = useDispatch()
  const { search } = useLocation()

  const selectedSubscription = useSelector(selectSubscription)

  const [discountModal, setDiscountModal] = useState<TDiscountModal>(
    discountModalDefaultState,
  )

  const hasVatInfo = useVatInfo()
  const isAlternativeVariant = useFeatureIsOn('fas_530_new_upsell')
  const viewportValue = useViewportValue()
  const hasDiscount = discountModal.isPreviouslyOpened

  const screenName = useMemo(
    () =>
      hasDiscount
        ? ScreenName.UPSELL_COACHING_CANCEL
        : ScreenName.UPSELL_COACHING,
    [hasDiscount],
  )

  useEffect(() => {
    eventLogger.logUpsellCoachingPurchaseShown(ScreenName.UPSELL_COACHING)
  }, [])

  useEffect(() => {
    if (
      viewportValue > ViewportScrollPercentage.V_0 &&
      !discountModal.isVisible &&
      screenName === ScreenName.UPSELL_COACHING
    ) {
      eventLogger.logUpsellCoachingPageScrolled(viewportValue, screenName)
    }
  }, [viewportValue, screenName, discountModal.isVisible])

  const handleSkip = useCallback(() => {
    eventLogger.logUpsellCoachingPurchaseClosed(screenName)

    setDiscountModal((prevState) => {
      if (!prevState.isPreviouslyOpened && !isAlternativeVariant) {
        return {
          isVisible: true,
          isPreviouslyOpened: true,
        }
      }

      goTo({ pathname: PageId.FINISHING_TOUCHES, search })

      return {
        isVisible: false,
        isPreviouslyOpened: true,
      }
    })
  }, [setDiscountModal, search, isAlternativeVariant, screenName])

  useEffect(() => {
    dispatch(setHasHeaderSkipButton(true))
    dispatch(setHeaderSkipButtonCallback(handleSkip))

    return () => {
      dispatch(setHasHeaderSkipButton(false))
      dispatch(setHeaderSkipButtonCallback())
    }
  }, [dispatch, handleSkip])

  const handleCloseModal = useCallback(() => {
    const tags = [
      hasVatInfo ? SubscriptionTags.TAX : SubscriptionTags.NO_TAX,
      SubscriptionTags.UPSELL_LONG,
      SubscriptionTags.CANCEL_OFFER,
    ].join(',')

    dispatch(getSubscriptionListAction(SubscriptionListType.UPSELL, tags))

    setDiscountModal({
      isVisible: false,
      isPreviouslyOpened: true,
    })

    eventLogger.logUpsellCoachingPurchaseShown(
      ScreenName.UPSELL_COACHING_CANCEL,
    )
  }, [dispatch, setDiscountModal, hasVatInfo])

  const handleMakeUpsell = useCallback(() => {
    makeUpsell(screenName)
  }, [makeUpsell, screenName])

  return (
    <S.PageContainer>
      {hasDiscount && <UpsellDiscountBannerV1 />}
      <IntroMediaV6 />
      <UpsellSubscriptionBlockV2
        buttonNumber={1}
        screenName={screenName}
        selectedSubscription={selectedSubscription}
        hasDiscount={hasDiscount}
        makeUpsell={handleMakeUpsell}
      />

      {!hasDiscount && (
        <>
          <WhatYouGetV2 />
          <StepsV1 />
          <PhotoResultV2
            titleI18nKey="upsellV2.photoResultTitle"
            gender={Gender.FEMALE}
          />
          <UpsellFeedbacksV1 />
          <IntroMediaV6 titleI18nKey="upsellV2.titleV2" />
          <UpsellSubscriptionBlockV2
            buttonNumber={2}
            screenName={screenName}
            selectedSubscription={selectedSubscription}
            makeUpsell={handleMakeUpsell}
          />
          <S.SecurityInfo topIndent={32} />
        </>
      )}

      {discountModal.isVisible && (
        <UpsellDiscountModalV1 onClose={handleCloseModal} />
      )}
      {(isUpsellInProgress || !arePricesReady) && <Spinner />}
    </S.PageContainer>
  )
}
