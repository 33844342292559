import styled, { css } from 'styled-components'

import { Input } from 'components/Input'

import exclaimationIcon from 'assets/images/exclaimation-circle-2.png'

import { Color } from 'root-constants'

export const StyledCurrentWeightV4 = {
  WeightLabelWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 88px;
    height: 48px;
    border-radius: 16px;
    background-color: ${Color.GREY_130};
    margin: 0 auto 24px;
  `,
  WeightLabel: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 32px;
    border-radius: 12px;
    background-color: ${Color.GREEN_300};
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
    color: ${Color.WHITE};
    letter-spacing: 0.5px;
  `,
  Actions: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 24px;
  `,
  InputWrapper: styled.div`
    position: relative;
    width: 100%;
  `,
  Placeholder: styled.span`
    position: absolute;
    top: calc(50% + 3px);
    left: 50%;
    height: 3px;
    width: 14px;
    background-color: rgba(0, 0, 0, 0.5);
    pointer-events: none;
    transform: translateX(-50%);
  `,
  WeightInput: styled(Input)`
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    padding: 8px 70px;
    border-radius: 16px;
    border: 1px solid ${Color.GREY_1000};
    background: ${Color.WHITE};
    ${({ isValid, value }) =>
      value &&
      !isValid &&
      css`
        border: 1px solid ${Color.RED_300};
      `};

    &:focus,
    &:active {
      & ~ span {
        display: none;
      }
    }
  `,
  Suffix: styled.span`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    color: ${Color.GREY_102};
  `,
  ErrorMessage: styled.p`
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    padding-left: 36px;
    margin-top: 4px;
    color: ${Color.RED_300};
    background-image: url(${exclaimationIcon});
    background-size: 16px;
    background-repeat: no-repeat;
    background-position-x: 16px;
  `,
  InfoContainer: styled.div<{ background: string }>`
    background: ${({ background }) => background};
    border-radius: 16px;
    column-gap: 8px;
    display: flex;
    margin-bottom: 24px;
    padding: 16px;
  `,
  InfoImage: styled.div`
    aspect-ratio: 1 / 1;
    min-width: 24px;
    height: 24px;
  `,
  Info: styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  `,
  InfoTitle: styled.h4`
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
  `,
  InfoText: styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  `,
}
