import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledSwitch = {
  Root: styled.label`
    display: flex;
    width: 60px;
    height: 32px;
    position: relative;
    -webkit-tap-highlight-color: transparent;
  `,
  Input: styled.input`
    width: 0;
    height: 0;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    z-index: -1;

    &:checked + span {
      transform: translateX(calc(100% + 1px));
    }

    &:checked ~ div {
      background-color: ${Color.PRIMARY};
      border: 1px solid ${Color.PRIMARY};
    }
  `,
  Track: styled.div`
    width: 100%;
    transition: 0.2s;
    transition-property: background, border;
    border: 1px solid ${Color.LIGHT_GRAY};
    border-radius: 16px;
    background: ${Color.GREY_800};
  `,
  Button: styled.span`
    position: absolute;
    top: 3px;
    left: 3px;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    transition: 0.2s;
    transition-property: transform;
    background: #fff;
  `,
}
