import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  ACTIVITY_LEVEL_MAP,
  BODY_PART_NAME_MAP,
  PRIMARY_GOAL_MAP,
} from 'map-constants'

import { IMAGES_MAP } from 'components/UserInfoBlock/constants'

import {
  selectCurrentUserGender,
  selectCurrentUserPrimaryGoal,
  selectTargetAreas,
  selectWorkout,
} from 'root-redux/selects/common'

import { useUsersBmi } from 'hooks/useUsersBmi'

import { convertTextFromSnakeCase } from 'helpers/convertTextFromSnakeCase'
import { getCapitalizedString } from 'helpers/stringHelper'

import bulletCup from 'assets/images/bullet-cup.svg'
import bulletDarts from 'assets/images/bullet-darts.svg'
import bulletRunningMan from 'assets/images/bullet-running-man.svg'

import { TARGET_AREA_FULL_LIST } from 'root-constants'

import { StyledUserInfoBlockV4 as S } from './UserInfoBlockV4.styles'

type TProps = {
  className?: string
}

export const UserInfoBlockV4 = ({ className }: TProps) => {
  const { t } = useTranslation()
  const gender = useSelector(selectCurrentUserGender)
  const primaryGoalValue = useSelector(selectCurrentUserPrimaryGoal)
  const targetAreas = useSelector(selectTargetAreas)
  const workoutValue = useSelector(selectWorkout)

  const { currentBMI } = useUsersBmi()

  const imgSrc = useMemo(
    () => IMAGES_MAP[gender][currentBMI.bmiValue.value],
    [gender, currentBMI],
  )

  const goal = useMemo(
    () => convertTextFromSnakeCase(t(PRIMARY_GOAL_MAP[primaryGoalValue])),
    [primaryGoalValue, t],
  )

  const activityLevel = useMemo(
    () => convertTextFromSnakeCase(t(ACTIVITY_LEVEL_MAP[workoutValue])),
    [workoutValue, t],
  )

  const areas = useMemo(() => {
    if (JSON.stringify(targetAreas) === JSON.stringify(TARGET_AREA_FULL_LIST)) {
      return t('commonComponents.bodyPart.whole')
    }

    return targetAreas
      .map((area) => getCapitalizedString(t(BODY_PART_NAME_MAP[area])))
      .join(', ')
  }, [targetAreas, t])

  return (
    <S.Container className={className}>
      <S.InfoList>
        <S.InfoItem bulletSrc={bulletCup}>
          <S.Label>
            {convertTextFromSnakeCase(t('commonComponents.goal'))}
          </S.Label>
          <S.InfoValue>{convertTextFromSnakeCase(t(goal))}</S.InfoValue>
        </S.InfoItem>
        <S.InfoItem bulletSrc={bulletDarts}>
          <S.Label>
            {convertTextFromSnakeCase(t('commonComponents.targetArea'))}
          </S.Label>
          <S.InfoValue>{convertTextFromSnakeCase(areas)}</S.InfoValue>
        </S.InfoItem>
        <S.InfoItem bulletSrc={bulletRunningMan}>
          <S.Label>
            {convertTextFromSnakeCase(t('commonComponents.activityLevel'))}
          </S.Label>
          <S.InfoValue>{activityLevel}</S.InfoValue>
        </S.InfoItem>
      </S.InfoList>
      <S.Banner src={imgSrc} alt="user-info" />
    </S.Container>
  )
}
