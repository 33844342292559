import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { PAGES_CATEGORIES_NAME_MAP } from 'map-constants'

import { Logo } from 'components/Logo'
import { SvgImage } from 'components/SvgImage'

import {
  selectCurrentVariantCohortToUse,
  selectCurrentVariantSteps,
} from 'root-redux/selects/common'

import { useGetPageInfo } from 'hooks/useGetPageInfo'

import { IStep } from 'models/variant.model'

import arrowSvg from 'assets/images/sprite/back-arrow.svg'

import { goBack } from 'browser-history'
import { PageId } from 'page-constants'
import {
  Color,
  ONBOARDING_END,
  ONBOARDING_PAGE_BREAKPOINTS,
  ONBOARDING_START,
} from 'root-constants'

import { StyledHeaderV2 as S } from './HeaderV2.styles'

export const HeaderV2: React.FC = () => {
  const { t } = useTranslation()
  const { hasProgressbar, currentPageId } = useGetPageInfo()
  const steps = useSelector(selectCurrentVariantSteps)
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const segmentedSteps: IStep[][] = useMemo(
    () =>
      steps.reduce((stepsWithSegments: IStep[][], currentStep: IStep) => {
        const breakPointIndex = ONBOARDING_PAGE_BREAKPOINTS[
          cohortToUse
        ]?.findIndex((item: PageId) => item === currentStep.id)

        if (breakPointIndex >= 0) {
          stepsWithSegments[breakPointIndex] = [currentStep]
        } else {
          stepsWithSegments[stepsWithSegments.length - 1]?.push(currentStep)
        }

        return stepsWithSegments
      }, []),
    [cohortToUse, steps],
  )

  const [currentSectionIndex, currentStepIndex] = useMemo(() => {
    const sectionIndex = segmentedSteps.findIndex(
      (block) => !!block?.find((step) => step.id === currentPageId),
    )
    const stepIndex = segmentedSteps[sectionIndex]?.findIndex(
      (step) => step.id === currentPageId,
    )

    return [sectionIndex, stepIndex]
  }, [currentPageId, segmentedSteps])

  const segmentedOnboardingSteps = useMemo(
    () => segmentedSteps.slice(ONBOARDING_START, ONBOARDING_END),
    [segmentedSteps],
  )

  return (
    <S.Wrapper>
      {hasProgressbar ? (
        <>
          <S.BackButton type="button" onClick={goBack}>
            <SvgImage
              fill={Color.BLACK_100}
              svg={arrowSvg}
              width={24}
              height={24}
            />
          </S.BackButton>
          <S.CategoryBlock>
            <S.Title>
              {t(PAGES_CATEGORIES_NAME_MAP[cohortToUse][currentSectionIndex])}
            </S.Title>
            <S.ProgressContainer>
              {segmentedOnboardingSteps.map((stepsBlock, index) => {
                const currentBlockProgressValue =
                  currentSectionIndex === index ? currentStepIndex + 1 : 0

                const progressValue =
                  currentSectionIndex > index
                    ? stepsBlock.length
                    : currentBlockProgressValue

                return (
                  <S.Progress
                    key={stepsBlock[0].id}
                    max={stepsBlock.length}
                    value={progressValue}
                  />
                )
              })}
            </S.ProgressContainer>
          </S.CategoryBlock>
        </>
      ) : (
        <Logo marginBottom={0} />
      )}
    </S.Wrapper>
  )
}
