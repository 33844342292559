import { PAST_TRIES_VALUES } from 'pages/questions/PastTries/constants'

export const QUESTION = 'Do you follow a special diet?'

export const PAST_TRIES_WITHOUT_DIET_TYPE = [
  PAST_TRIES_VALUES.RESTRICTIVE,
  PAST_TRIES_VALUES.PAID_MEAL,
]

export const DIET_TYPE_VALUES = {
  LOW_IN_CARBOHYDRATES: 'low_in_carbohydrates',
  KETO: 'keto',
  MEDITERRANEAN: 'mediterranean',
  CLEAN_EATING: 'clean_eating',
  LOW_FAT: 'low_fat',
  VEGETARIAN: 'vegetarian',
  ANOTHER: 'another',
  NO: 'no',
}

export const ANSWER_OPTIONS_MAP = [
  {
    value: DIET_TYPE_VALUES.LOW_IN_CARBOHYDRATES,
    title: 'onboarding.dietType.options.lowInCarbohydrates.title',
    description: 'onboarding.dietType.options.lowInCarbohydrates.description',
  },
  {
    value: DIET_TYPE_VALUES.KETO,
    title: 'onboarding.dietType.options.keto.title',
    description: 'onboarding.dietType.options.keto.description',
  },
  {
    value: DIET_TYPE_VALUES.MEDITERRANEAN,
    title: 'onboarding.dietType.options.mediterranean.title',
    description: 'onboarding.dietType.options.mediterranean.description',
  },
  {
    value: DIET_TYPE_VALUES.CLEAN_EATING,
    title: 'onboarding.dietType.options.cleanEating.title',
    description: 'onboarding.dietType.options.cleanEating.description',
  },
  {
    value: DIET_TYPE_VALUES.LOW_FAT,
    title: 'onboarding.dietType.options.lowFat.title',
    description: 'onboarding.dietType.options.lowFat.description',
  },
  {
    value: DIET_TYPE_VALUES.VEGETARIAN,
    title: 'onboarding.dietType.options.vegetarian.title',
    description: 'onboarding.dietType.options.vegetarian.description',
  },
  {
    value: DIET_TYPE_VALUES.ANOTHER,
    title: 'onboarding.dietType.options.another.title',
  },
  {
    value: DIET_TYPE_VALUES.NO,
    title: 'onboarding.dietType.options.no.title',
  },
]
