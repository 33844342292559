import React, { FC, useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { IMAGE_PATH } from 'pages/questions/ReachGoals/constants'

import { KitInfoBlock } from 'components/KitInfoBlock'
import { KitPageContainer } from 'components/PageContainer'

import { selectLanguage } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import pointUpImage from 'assets/images/point-up.png'

import { CDN_FOLDER_LINK, Language } from 'root-constants'

import { StyledReachGoals as S } from './ReachGoals.styles'

export const ReachGoalsV1: FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const language = useSelector(selectLanguage)

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.reachGoalsV1.title', { lng: Language.EN }),
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  const bannerImgSrc = useMemo(
    () => `${CDN_FOLDER_LINK}${IMAGE_PATH}-${language}.png`,
    [language],
  )

  return (
    <KitPageContainer
      hasContinueButton
      onContinueButtonClick={handleNextClick}
      continueButtonContent={t('actions.letsGo')}
    >
      <S.PageTitle marginBottom={24} textAlign="left">
        <Trans i18nKey="onboarding.reachGoalsV1.title" />
      </S.PageTitle>
      <S.Banner>
        <img src={bannerImgSrc} alt="banner" />
      </S.Banner>
      <KitInfoBlock
        iconSrc={pointUpImage}
        text={t('onboarding.reachGoalsV1.info')}
      />
    </KitPageContainer>
  )
}
