import { EventValue } from 'value-constants'

import { Position } from 'root-constants'

export const TRANSLATION_FAMILY = 'onboarding.eventV1'
export const OPTIONS = [
  {
    key: 'marriageOrRelationship',
    value: EventValue.MARRIAGE_OR_RELATIONSHIP,
    checkboxPosition: Position.LEFT,
  },
  {
    key: 'busyWorkOrFamilyLife',
    value: EventValue.BUSY_WORK_OR_FAMILY_LIFE,
    checkboxPosition: Position.LEFT,
  },
  {
    key: 'financialStruggles',
    value: EventValue.FINANCIAL_STRUGGLES,
    checkboxPosition: Position.LEFT,
  },
  {
    key: 'injuryOrDisability',
    value: EventValue.INJURY_OR_DISABILITY,
    checkboxPosition: Position.LEFT,
  },
  {
    key: 'stressOrMentalHealth',
    value: EventValue.STRESS_OR_MENTAL_HEALTH,
    checkboxPosition: Position.LEFT,
  },
  {
    key: 'slowerMetabolism',
    value: EventValue.SLOWER_METABOLISM,
    checkboxPosition: Position.LEFT,
  },
  {
    key: 'postTreatmentComplications',
    value: EventValue.POST_TREATMENT_COMPLICATIONS,
    checkboxPosition: Position.LEFT,
  },
]

export const CUSTOM_OPTIONS = {
  options: [
    {
      key: 'none',
      value: EventValue.NONE,
    },
  ],
  shouldDisableOptions: false,
}
