import React, { useMemo } from 'react'

import { CreditCardForm, PayPalButton } from 'modules/purchase/components'
import { PaymentRequestButtonV2 } from 'modules/purchase/components/PaymentRequestButton/PaymentRequestButtonV2'
import { PaymentMethod } from 'modules/purchase/constants'

import { StyledPaymentForms as S } from './PaymentForms.styles'

type TProps = {
  activePaymentMethod: PaymentMethod
  className?: string
}
export const PaymentForms: React.FC<TProps> = ({
  activePaymentMethod,
  className,
}) => {
  const form = useMemo(() => {
    switch (activePaymentMethod) {
      case PaymentMethod.PAYPAL:
        return <PayPalButton />
      case PaymentMethod.APPLE_PAY:
        return <PaymentRequestButtonV2 />
      case PaymentMethod.GOOGLE_PAY:
        return <PaymentRequestButtonV2 />

      default:
        return (
          <>
            <S.CreditCardTitleV3 />
            <CreditCardForm />
          </>
        )
    }
  }, [activePaymentMethod])

  return <div className={className}>{form}</div>
}
