import alcohol from 'assets/images/alcohol-belly.png'
import gluten from 'assets/images/gluten-belly.png'
import normal from 'assets/images/normal-belly.png'
import stressed from 'assets/images/stressed-belly.png'
import testosterone from 'assets/images/testosterone-belly.png'

export const QUESTION = 'Pick the one that looks the closest to your belly'

const enum BELLY_TYPE_VALUES {
  STRESSED = 'stressed',
  GLUTEN = 'gluten',
  LOW_TESTOSTERONE = 'low_testosterone',
  ALCOHOL = 'alcohol',
  NORMAL = 'normal',
}
export const BELLY_TYPE_OPTIONS_MAP = [
  {
    value: BELLY_TYPE_VALUES.STRESSED,
    title: 'onboarding.bellyType.stressed',
    icon: stressed,
  },
  {
    value: BELLY_TYPE_VALUES.GLUTEN,
    title: 'onboarding.bellyType.gluten',
    icon: gluten,
  },
  {
    value: BELLY_TYPE_VALUES.LOW_TESTOSTERONE,
    title: 'onboarding.bellyType.testosterone',
    icon: testosterone,
  },
  {
    value: BELLY_TYPE_VALUES.ALCOHOL,
    title: 'onboarding.bellyType.alcohol',
    icon: alcohol,
  },
  {
    value: BELLY_TYPE_VALUES.NORMAL,
    title: 'onboarding.bellyType.normal',
    icon: normal,
  },
]
