import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import {
  PayPalScriptProvider,
  usePayPalScriptReducer,
} from '@paypal/react-paypal-js'

import { Spinner } from 'components/Spinner'

import { getUserStatusAction } from 'root-redux/actions/user'
import { selectPayPalClientId } from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import { useLockScroll } from 'hooks/useLockScroll'

import { ButtonWrapper } from 'modules/purchase/components/PayPalButton/ButtonWrapper'
import {
  AnimationState,
  usePayPalButton,
} from 'modules/purchase/hooks/usePayPalButton'
import {
  selectIsPayPalButtonLoading,
  selectPayPalPlanId,
} from 'modules/purchase/redux/selects'

import spinnerSvg from 'assets/images/sprite/spinner-grey.svg'

import { StyledPayPalButton as S } from './PayPalButton.styles'

const commonLottieProps = {
  width: '132px',
  height: '132px',
}

export const PayPalButton: React.FC = () => {
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()
  const uuid = useSelector(selectUUID)
  const paypalPlanId = useSelector(selectPayPalPlanId)
  const paypalClientId = useSelector(selectPayPalClientId)
  const isPayPalButtonLoading = useSelector(selectIsPayPalButtonLoading)

  const {
    isPaymentStatusShown,
    setIsPaymentStatusShown,
    animationStatus,
    completeAnimationRef,
    errorAnimationRef,
    handleResetError,
    isButtonTouched,
    handlePaymentApprove,
    handlePaymentError,
    handlePaymentCancel,
    handleButtonClick,
  } = usePayPalButton()

  const handleSuccessButtonClick = useCallback(() => {
    setIsPaymentStatusShown(false)
    dispatch(getUserStatusAction(uuid))
  }, [dispatch, uuid, setIsPaymentStatusShown])

  useLockScroll(isPaymentStatusShown)

  const FakePaypalButton = () => {
    const [{ isPending: isPaypalButtonLoading }] = usePayPalScriptReducer()

    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {!isPaypalButtonLoading && (
          <S.FakePaypalButton isTouched={isButtonTouched} type="button" />
        )}
      </>
    )
  }

  return (
    <S.Root>
      <S.ButtonsContainer>
        {paypalPlanId && paypalClientId && (
          <PayPalScriptProvider
            options={{
              'client-id': paypalClientId,
              vault: true,
              'disable-funding': 'credit',
            }}
          >
            <ButtonWrapper>
              <S.PayPalButtons
                isHidden={isPayPalButtonLoading}
                style={{
                  label: 'paypal',
                  tagline: false,
                  layout: 'horizontal',
                  height: 55,
                }}
                forceReRender={[paypalPlanId]}
                createSubscription={(_, actions) => {
                  return actions.subscription.create({
                    plan_id: paypalPlanId, // Creates the subscription
                    custom_id: uuid,
                  })
                }}
                onApprove={(data) => handlePaymentApprove(data)}
                onError={(error) => handlePaymentError(error)}
                onCancel={handlePaymentCancel}
                onClick={handleButtonClick}
              />
              {isPayPalButtonLoading && (
                <Spinner isFullScreen={false} svgSrc={spinnerSvg} />
              )}
              <FakePaypalButton />
            </ButtonWrapper>
          </PayPalScriptProvider>
        )}
      </S.ButtonsContainer>
      {isPaymentStatusShown && (
        <S.ModalWrapper>
          <S.Container>
            <S.LottieContainer>
              {animationStatus === AnimationState.SUCCESS && (
                <>
                  <div
                    ref={completeAnimationRef}
                    style={{ ...commonLottieProps }}
                  />
                  <S.PaymentStatus>
                    {t('purchase.paymentWaiting.paymentWasSuccessful')}
                  </S.PaymentStatus>

                  <S.SuccessButton
                    type="button"
                    onClick={handleSuccessButtonClick}
                  >
                    {t('purchase.paymentWaiting.toLoginPage')}
                  </S.SuccessButton>
                  <S.Disclaimer>
                    {t(
                      'purchase.paymentWaiting.disclaimerForSuccessfulPayment',
                    )}
                  </S.Disclaimer>
                </>
              )}
              {animationStatus === AnimationState.ERROR && (
                <>
                  <div
                    ref={errorAnimationRef}
                    style={{ ...commonLottieProps }}
                  />

                  <S.ErrorStatus>
                    {t('purchase.paymentWaiting.errorDuringPayment')}
                  </S.ErrorStatus>
                  <S.ErrorInfo />

                  <S.ErrorButton onClick={handleResetError} type="button">
                    {t('purchase.paymentWaiting.retry')}
                  </S.ErrorButton>
                </>
              )}
            </S.LottieContainer>
          </S.Container>
        </S.ModalWrapper>
      )}
    </S.Root>
  )
}
