import styled from 'styled-components'

import { Button } from 'components/Button'

import {
  Color,
  KIT_MAX_COLUMN_WIDTH,
  MediaBreakpoint,
  PAGE_HORIZONTAL_PADDING,
} from 'root-constants'

import { CONTINUE_BUTTON_IS_STICKY } from './constants'

export const StyledKitPageContainer = {
  Wrapper: styled.div<{
    headerWithProgressBarHeight: number
  }>`
    ${({ headerWithProgressBarHeight }) => `
      min-height: calc(100dvh - ${headerWithProgressBarHeight}px);
      width: 100%;
    `}
  `,

  Container: styled.div<{ paddingTop?: number; hasContinueButton: boolean }>`
    ${({ paddingTop, hasContinueButton }) => `
      margin: 0 auto;
      min-height: inherit;
      padding: ${
        paddingTop || paddingTop === 0 ? paddingTop : 24
      }px ${PAGE_HORIZONTAL_PADDING}px 24px;
      position: relative;

      ${
        hasContinueButton
          ? `
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          `
          : ''
      }

      @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
        width: ${KIT_MAX_COLUMN_WIDTH}px;
      }
    `}
  `,
  Content: styled.div<{ isActionButtonsVisible?: boolean }>`
    flex-grow: 1;
    margin-bottom: ${({ isActionButtonsVisible }) =>
      isActionButtonsVisible ? 32 : 0}px;
    position: relative;
  `,
  BottomContent: styled.div<{ hasContinueButton?: boolean }>`
    bottom: 0;
    left: 0;
    margin-bottom: ${({ hasContinueButton = true }) =>
      hasContinueButton ? '0' : '24'}px;
    padding: 0 16px;
    position: absolute;
    width: 100%;

    /* Target only touch device */
    @media (hover: none) and (pointer: coarse) and (max-height: 600px) {
      margin-bottom: 0;
      padding: 0;
      position: static;
    }
  `,
  StickyButtonContainer: styled.div<{ isActionButtonsVisible?: boolean }>`
    background-color: transparent;
    bottom: 0;
    display: ${({ isActionButtonsVisible }) =>
      isActionButtonsVisible ? 'block' : 'none'};
    margin: 0 auto;
    position: sticky;
    z-index: 10;
    width: 100%;

    &.${CONTINUE_BUTTON_IS_STICKY} {
      background-color: ${Color.WHITE};
      box-shadow: 0 0.5px 0 0 ${Color.GREY_1000} inset;
      padding: 16px 24px;
      margin-left: 50%;
      transform: translateX(-50%);
      width: 100vw;
    }
  `,
  SkipButton: styled(Button)`
    width: fit-content;
    height: fit-content;
    border: none;
    background-color: transparent;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: ${Color.GREY_101};
    text-decoration: underline;
  `,
}
