import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { BODY_PART_NAME_MAP } from 'map-constants'

import { AnswerButtonV2 } from 'components/AnswerButtonV2'
import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import {
  selectCurrentUserGender,
  selectCurrentVariantCohortToUse,
} from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { CustomPageId } from 'page-constants'
import { Cohort, Language, TARGET_AREA_FULL_LIST } from 'root-constants'

import { StyledTargetAreas as S } from './TargetAreas.styles'
import {
  DEFAULT_DATA_MAP,
  MEN_FLOW_DATA_MAP,
  WOMEN_SENIOR_DATA_MAP,
} from './constants'

export const TargetAreas: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)
  const gender = useSelector(selectCurrentUserGender)

  const [answers, setAnswers] = useState<string[]>([])

  const { imgSrc, options } = useMemo(() => {
    if (cohortToUse === Cohort.MF_WOMEN_45) {
      return WOMEN_SENIOR_DATA_MAP
    }
    if (cohortToUse === Cohort.MF_MEN_FLOW) {
      return MEN_FLOW_DATA_MAP
    }

    return DEFAULT_DATA_MAP[gender]
  }, [cohortToUse, gender])

  const optionProps = useMemo(
    () => ({
      name: CustomPageId.TARGET_AREAS,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked) {
          setAnswers([...answers, value])
        } else {
          setAnswers(answers.filter((item) => item !== value))
        }
      },
    }),
    [answers],
  )

  const optionPropsForSingle = () => ({
    name: CustomPageId.TARGET_AREAS,
    type: OptionType.CHECKBOX,
    onChange: (_, isChecked: boolean) => {
      if (isChecked) {
        setAnswers(TARGET_AREA_FULL_LIST)
      } else {
        setAnswers([])
      }
    },
  })

  const handleContinue = useNextStep({
    pageId: CustomPageId.TARGET_AREAS,
    question: t('onboarding.targetAreas.title', { lng: Language.EN }),
    nextPagePath,
  })

  const handleNextClick = useCallback(
    () => handleContinue(answers),
    [answers, handleContinue],
  )

  return (
    <Container>
      <PageTitle marginBottom={8}>
        {t('onboarding.targetAreas.title')}
      </PageTitle>
      <S.Subtitle> {t('onboarding.targetAreas.subTitle')}</S.Subtitle>

      <S.Row>
        <S.ImageContainer>
          <img src={imgSrc} alt="body" />
        </S.ImageContainer>

        <S.Col>
          {options?.map(({ value, lineSvg, lineWidth, lineTop }) => (
            <Option
              {...optionProps}
              marginBottom="8"
              checked={answers.includes(value)}
              value={value}
              key={value}
            >
              <S.OptionContent isChecked={answers.includes(value)}>
                <S.Content>
                  <S.OptionText>{t(BODY_PART_NAME_MAP[value])}</S.OptionText>
                </S.Content>
                <S.SvgImage svg={lineSvg} width={lineWidth} top={lineTop} />
              </S.OptionContent>
            </Option>
          ))}
        </S.Col>
      </S.Row>

      <Option
        {...optionPropsForSingle}
        value=""
        checked={answers.length === TARGET_AREA_FULL_LIST.length}
      >
        <AnswerButtonV2 withCheckbox>
          <h3>{t('onboarding.targetAreas.full')}</h3>
        </AnswerButtonV2>
      </Option>

      <StickyButtonContainer>
        <Button
          type="button"
          disabled={!answers.length}
          onClick={handleNextClick}
        >
          {t('actions.continue')}
        </Button>
      </StickyButtonContainer>
    </Container>
  )
}
