import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { Footer } from 'components/Footer'
import { Modal } from 'components/Modal'
import { PageTitle } from 'components/PageTitle'
import { SvgImage } from 'components/SvgImage'

import { resetErrorAction } from 'root-redux/actions/common'
import { sendUserEmailAction } from 'root-redux/actions/user'
import {
  selectCurrentUserGoalWeight,
  selectError,
  selectUserMeasurementSystem,
} from 'root-redux/selects/common'
import { selectUUID, selectUserStatus } from 'root-redux/selects/user'

import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useEmailInputField } from 'hooks/useEmailInputField'
import { useUserStatus } from 'hooks/useUserStatus'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import alertIcon from 'assets/images/sprite/alert-circle.svg'
import lockIconSvg from 'assets/images/sprite/lock.svg'

import { goTo } from 'browser-history'
import {
  MeasurementSystem,
  OutbrainEventName,
  TaboolaEventName,
} from 'root-constants'

import { StyledEmailV2 as S } from './EmailV2.styles'

export const EmailV2: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const dispatch = useDispatch()

  const userStatus = useSelector(selectUserStatus)
  const uuid = useSelector(selectUUID)
  const error = useSelector(selectError)
  const goalUserWeight = useSelector(selectCurrentUserGoalWeight)
  const measurementSystem = useSelector(selectUserMeasurementSystem)

  const [isErrorModalShown, setIsErrorModalShown] = useState(false)
  const [email, setEmail] = useEmailInputField()

  const { title } = useDynamicOBConfig()
  const availableRoute = useUserStatus()

  const isEmailValid = useMemo(() => email.isValid, [email.isValid])

  useEffect(() => {
    eventLogger.logEmailPageShown()
  }, [])

  useLayoutEffect(() => {
    switch (true) {
      case userStatus?.hasSubscription:
        goTo({ pathname: availableRoute, search })
        break
      case userStatus?.email.hasEmail:
        goTo(nextPagePath)
        break
    }
  }, [search, userStatus, availableRoute, nextPagePath])

  const handleErrorModal = useCallback(() => setIsErrorModalShown(true), [])

  const handleSubmit = useCallback(
    (e: SyntheticEvent<HTMLFormElement>) => {
      e.preventDefault()
      if (!email.isValid) return

      goTo(nextPagePath)
      dispatch(
        sendUserEmailAction({
          email: email.value,
          unsuccessCallback: handleErrorModal,
        }),
      )

      googleAnalyticsLogger.logEnterEmailPageCompleted()
      eventLogger.logEmailPageCompleted({ email: email.value })
      window.fbq('track', 'Lead', {}, { eventID: uuid })
      window.ttq.identify({ email: email.value })
      window.ttq.track('CompleteRegistration')
      window.snaptr('track', 'SIGN_UP', { user_email: email.value })
      window.obApi &&
        window.obApi('track', OutbrainEventName.COMPLETE_REGISTRATION)
      window._tfa &&
        window._tfa.push({
          notify: 'event',
          name: TaboolaEventName.COMPLETE_REGISTRATION,
        })
    },
    [
      dispatch,
      uuid,
      email.value,
      email.isValid,
      handleErrorModal,
      nextPagePath,
    ],
  )

  const handleChange = useCallback(
    ({ target: { value } }) => {
      const emailValue = value.toLowerCase().trim()

      setEmail((prevState) => ({
        ...prevState,
        value: emailValue,
      }))
    },
    [setEmail],
  )

  const handleFocus = useCallback(() => {
    setEmail((prevState) => ({
      ...prevState,
      isFocused: true,
    }))
  }, [setEmail])

  const handleBlur = useCallback(() => {
    setEmail((prevState) => ({
      ...prevState,
      isFocused: false,
    }))
  }, [setEmail])

  return (
    <>
      <Container>
        <S.TitleContainer>
          <PageTitle marginBottom={24}>
            {title || (
              <Trans
                i18nKey="onboarding.email.title"
                values={{
                  weight: goalUserWeight,
                  measurementSystem:
                    measurementSystem === MeasurementSystem.METRIC
                      ? t('commonComponents.kg')
                      : t('commonComponents.lbs'),
                }}
              />
            )}
          </PageTitle>
        </S.TitleContainer>
        <form onSubmit={handleSubmit}>
          <S.Input
            value={email.value}
            maxLength={50}
            isValid={isEmailValid}
            placeholder={t('onboarding.email.placeholderV2')}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
          {email.value && !email.isValid && (
            <S.ErrorMessage>
              <SvgImage svg={alertIcon} />
              <span>{t('onboarding.email.errorMessage')}</span>
            </S.ErrorMessage>
          )}
          <S.Disclaimer>
            <S.LockIcon svg={lockIconSvg} width={20} />
            {t('onboarding.email.disclaimer')}
          </S.Disclaimer>
          <S.ButtonContainer>
            <Button type="submit" disabled={!email.value || !isEmailValid}>
              {t('actions.continue')}
            </Button>
          </S.ButtonContainer>
        </form>
      </Container>
      <Footer />
      <Modal
        onClose={() => {
          dispatch(resetErrorAction())
          setIsErrorModalShown(false)
        }}
        isShown={isErrorModalShown}
        error={error}
      />
    </>
  )
}
