import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Option, OptionType } from 'components/Option'

import { selectLanguage } from 'root-redux/selects/common'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { CDN_FOLDER_LINK } from 'root-constants'

import {
  Discount,
  DiscountText,
  Image,
  ImageWrapper,
  ListItem,
  ListTitle,
  MythsQuestion,
  NextPageButton,
  Question,
  StyledCancelOfferQuestion as S,
  SecondaryWrapper,
  StyledButton,
  StyledContainer,
  Text,
  Title,
} from './CancelOfferQuestion.styles'
import { EATING_MYTHS, IMAGE_PATH, OPTION_VALUES } from './constants'

export const CancelOfferQuestion: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const language = useSelector(selectLanguage)
  const offerRef = useRef<HTMLDivElement>(null)
  const [answer, setAnswer] = useState<string>('')

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        eventLogger.logCancelOfferPageAnswer(value)
        setAnswer(value)
      },
    }),
    [pageId],
  )

  useEffect(() => {
    eventLogger.logCancelOfferPageShown()
  }, [])

  useEffect(() => {
    if (offerRef.current && answer) {
      offerRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [answer])

  const handleNextPage = useCallback(() => {
    eventLogger.logCancelOfferPageClosed()
    goTo(nextPagePath)
  }, [nextPagePath])

  const imgSrc = useMemo(
    () => `${CDN_FOLDER_LINK}${IMAGE_PATH}-${language}.png`,
    [language],
  )

  return (
    <>
      <ImageWrapper>
        <Image src={imgSrc} alt="yes or no" />
      </ImageWrapper>
      <StyledContainer>
        <S.List>
          <ListTitle>{t('cancelOffer.questionPage.listTitle')}</ListTitle>
          {EATING_MYTHS.map(({ textPath }) => (
            <ListItem key={textPath}>{t(textPath)}</ListItem>
          ))}
          <Question>{t('cancelOffer.questionPage.question')}</Question>
        </S.List>

        <S.Actions>
          <Option
            {...optionProps}
            value={OPTION_VALUES.NO}
            checked={answer === OPTION_VALUES.NO}
            marginBottom="0"
            disabled={!!answer}
          >
            <StyledButton>{t('cancelOffer.questionPage.no')}</StyledButton>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.YES}
            checked={answer === OPTION_VALUES.YES}
            marginBottom="0"
            disabled={!!answer}
          >
            <StyledButton>{t('cancelOffer.questionPage.yes')}</StyledButton>
          </Option>
        </S.Actions>
      </StyledContainer>

      {!!answer && (
        <SecondaryWrapper ref={offerRef}>
          <StyledContainer>
            <Title>
              {answer === OPTION_VALUES.YES
                ? t('cancelOffer.questionPage.mythsSection.nothingButMyths')
                : t('cancelOffer.questionPage.mythsSection.totallyRight')}
            </Title>
            <MythsQuestion>
              {t('cancelOffer.questionPage.mythsSection.question')}
            </MythsQuestion>
            <Text>{t('cancelOffer.questionPage.mythsSection.subtitle')}</Text>
            <Discount>
              <DiscountText>
                <Trans
                  i18nKey="cancelOffer.questionPage.mythsSection.discount"
                  values={{
                    discountAmount: 65,
                  }}
                />
              </DiscountText>
            </Discount>
            <Text>{t('cancelOffer.questionPage.mythsSection.text')}</Text>
            <NextPageButton type="button" onClick={handleNextPage}>
              {t('actions.continue')}
            </NextPageButton>
          </StyledContainer>
        </SecondaryWrapper>
      )}
    </>
  )
}
