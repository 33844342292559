import { ReactNode } from 'react'

import styled from 'styled-components'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { Logo } from 'components/Logo'
import { LoseWeightGraph } from 'components/LoseWeightGraph'
import { Footnote } from 'components/LoseWeightGraph/LoseWeightGraph.styles'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledLogo = styled(Logo)`
  margin-bottom: 8px;
`

export const Hero = styled.div`
  position: relative;
  border-radius: 24px;
  overflow: hidden;
  background-color: ${Color.PRIMARY};
  margin-bottom: 24px;
  min-height: 201px;

  @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
    max-width: 360px;
    margin: 0 auto 24px auto;
  }
`
export const StyledContainer = styled(Container)<{ children: ReactNode }>``

export const WeightRangeTitle = styled.h2`
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: ${Color.GREY_100};
  margin-bottom: 8px;
`

export const WeightRange = styled.div`
  display: flex;
  justify-content: space-between;
`

export const WeightItem = styled.span`
  font-weight: 900;
  font-size: 24px;
  line-height: 58px;
  &:first-child,
  &:last-child {
    color: #dcf4e7;
  }
  &:nth-child(2),
  &:nth-child(4) {
    color: #a3e1be;
  }
  &:nth-child(3) {
    font-weight: 900;
    font-size: 48px;
    line-height: 58px;
    color: ${Color.PRIMARY};
  }
`

export const WeightMeasurement = styled.p`
  text-align: center;
  font-weight: 900;
  font-size: 20px;
  line-height: 28px;
  color: ${Color.PRIMARY};
  margin-bottom: 16px;
`

export const StyledLoseWeightGraph = styled(LoseWeightGraph)``

export const Action = styled(Button)`
  position: sticky;
  bottom: 10px;
  z-index: 2;
`

export const StyledResult = {
  Root: styled.div`
    position: relative;
    padding: 10px 0 33px;
  `,
  Image: styled.img`
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
    max-width: none;
    object-fit: contain;
  `,
  Title: styled.h1`
    font-weight: 800;
    font-size: 24px;
    line-height: 30px;
    max-width: 156px;
    position: absolute;
    left: 24px;
    top: 24px;
    color: ${Color.WHITE};
    z-index: 1;
  `,
  InitialActionPlacement: styled.div`
    height: 62px;
  `,
  Footnote: styled(Footnote)`
    margin-bottom: 32px;
  `,
}
