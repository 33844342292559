import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from 'storybook-ui'

import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'
import { KitToggle } from 'components/Toggle'

import { setMultipleAnswerAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'
import { selectUserCountryCode } from 'root-redux/selects/user'

import { convertFeetToInches } from 'helpers/convertFootsToInches'

import { IInputState, TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import calculatorIcon from 'assets/images/calculator-icon.png'

import { goTo } from 'browser-history'
import { CustomPageId } from 'page-constants'
import {
  COUNTRIES_WITH_IMPERIAL_MEASUREMENT_SYSTEM,
  FloatNumberRegex,
  HEIGHT_UNITS,
  INITIAL_INPUT_VALUE,
  MeasurementSystem,
} from 'root-constants'

import { StyledHeightV4 as S } from './HeightV4.styles'
import { MinMaxHeight, QUESTION, ValueMaxLength } from './constants'

export const HeightV4: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const userCountryCode = useSelector(selectUserCountryCode)

  const [heightCm, setHeightCm] = useState<IInputState>({
    ...INITIAL_INPUT_VALUE,
  })
  const [heightFt, setHeightFt] = useState<IInputState>({
    ...INITIAL_INPUT_VALUE,
  })
  const [heightIn, setHeightIn] = useState<IInputState>({
    ...INITIAL_INPUT_VALUE,
  })
  const [isMetricSystemSelected, setIsMetricSystemSelected] = useState(false)
  const [isIntegerFocused, setIsIntegerFocused] = useState(false)
  const [isFractionalFocused, setIsFractionalFocused] = useState(false)

  const isCountryWithImperialSystem = useMemo(
    () => COUNTRIES_WITH_IMPERIAL_MEASUREMENT_SYSTEM.includes(userCountryCode),
    [userCountryCode],
  )

  useEffect(() => {
    if (!isCountryWithImperialSystem) {
      setIsMetricSystemSelected(true)
    }
  }, [isCountryWithImperialSystem])

  const handleMeasurementChange = useCallback((isChecked) => {
    setIsMetricSystemSelected(isChecked)
    setHeightCm({ ...INITIAL_INPUT_VALUE })
    setHeightFt({ ...INITIAL_INPUT_VALUE })
    setHeightIn({ ...INITIAL_INPUT_VALUE })
  }, [])

  const handleSubmit = useCallback(
    (e: SyntheticEvent<HTMLFormElement>) => {
      e.preventDefault()

      const inputValue = isMetricSystemSelected
        ? +heightCm.value
        : convertFeetToInches(+heightFt.value, +heightIn.value)

      dispatch(
        setMultipleAnswerAction({
          answers: {
            [CustomPageId.HEIGHT]: inputValue,
            measurement_system: isMetricSystemSelected
              ? MeasurementSystem.METRIC
              : MeasurementSystem.IMPERIAL,
          },
        }),
      )

      eventLogger.logQuestion({
        question: QUESTION,
        answers: `${inputValue},${
          isMetricSystemSelected
            ? MeasurementSystem.METRIC
            : MeasurementSystem.IMPERIAL
        }`,
      })
      dispatch(sendUserAnswersAction())
      goTo(nextPagePath)
    },
    [
      dispatch,
      heightCm,
      heightFt,
      heightIn,
      isMetricSystemSelected,
      nextPagePath,
    ],
  )

  const handleIntegerChange = useCallback(
    ({ target: { value, validity } }) => {
      if (!value || FloatNumberRegex.test(value)) {
        isMetricSystemSelected
          ? setHeightCm({
              value,
              isValid: validity.valid,
            })
          : setHeightFt({
              value,
              isValid: validity.valid,
            })
      }
    },
    [isMetricSystemSelected],
  )

  const handleFractionalChange = useCallback(
    ({ target: { value, validity } }) => {
      if (!value || FloatNumberRegex.test(value)) {
        setHeightIn({
          value,
          isValid: validity.valid,
        })
      }
    },
    [],
  )

  const getIsImperialHeightValid = useCallback(
    () =>
      Number(`${heightFt.value}.${heightIn.value}`) >=
        MinMaxHeight.MIN_IMP_HEIGHT &&
      Number(`${heightFt.value}.${heightIn.value}`) <=
        MinMaxHeight.MAX_IMP_HEIGHT,
    [heightFt, heightIn],
  )

  const handleIntegerFocus = useCallback(() => {
    setIsIntegerFocused(true)
  }, [])

  const handleIntegerBlur = useCallback(() => {
    setIsIntegerFocused(false)
  }, [])

  const handleFractionalFocus = useCallback(() => {
    setIsFractionalFocused(true)
  }, [])

  const handleFractionalBlur = useCallback(() => {
    setIsFractionalFocused(false)
  }, [])

  return (
    <form onSubmit={handleSubmit}>
      <KitPageContainer>
        <PageTitle marginBottom={24} textAlign="left">
          {t('onboarding.height.title')}
        </PageTitle>

        <S.Actions>
          <KitToggle
            labels={['FT', 'CM']}
            checked={isMetricSystemSelected}
            onChange={handleMeasurementChange}
          />
          <S.InputContainer>
            <S.InputWrapper>
              <S.HeightInput
                type="number"
                value={isMetricSystemSelected ? heightCm.value : heightFt.value}
                isContentCentered
                isValid={
                  isMetricSystemSelected ? heightCm.isValid : heightFt.isValid
                }
                min={
                  isMetricSystemSelected
                    ? MinMaxHeight.MIN_HEIGHT_CM
                    : MinMaxHeight.MIN_HEIGHT_FT
                }
                max={
                  isMetricSystemSelected
                    ? MinMaxHeight.MAX_HEIGHT_CM
                    : MinMaxHeight.MAX_HEIGHT_FT
                }
                lang="en"
                step="0.1"
                allowFloatNumbers={isMetricSystemSelected}
                maxLength={
                  isMetricSystemSelected ? ValueMaxLength.CM : ValueMaxLength.FT
                }
                onChange={handleIntegerChange}
                onFocus={handleIntegerFocus}
                onBlur={handleIntegerBlur}
              />
              {!isIntegerFocused && !heightCm.value && !heightFt.value && (
                <S.Placeholder />
              )}
              <S.Suffix>
                {isMetricSystemSelected
                  ? HEIGHT_UNITS.metric
                  : HEIGHT_UNITS.imperial[0]}
              </S.Suffix>
              {(isMetricSystemSelected
                ? heightCm.value && !heightCm.isValid
                : heightFt.value && !heightFt.isValid) && (
                <S.ErrorMessage>
                  {t('onboarding.height.errorMessage')}
                </S.ErrorMessage>
              )}
            </S.InputWrapper>
            {!isMetricSystemSelected && (
              <S.InputWrapper>
                <S.HeightInput
                  type="number"
                  value={heightIn.value}
                  required={false}
                  isContentCentered
                  isValid={heightIn.isValid}
                  min={MinMaxHeight.MIN_HEIGHT_IN}
                  max={MinMaxHeight.MAX_HEIGHT_IN}
                  lang="en"
                  step="1"
                  allowFloatNumbers={false}
                  maxLength={ValueMaxLength.IN}
                  onChange={handleFractionalChange}
                  onFocus={handleFractionalFocus}
                  onBlur={handleFractionalBlur}
                />
                {!isFractionalFocused && !heightIn.value && <S.Placeholder />}
                <S.Suffix>{HEIGHT_UNITS.imperial[1]}</S.Suffix>
                {!isMetricSystemSelected &&
                  heightIn.value &&
                  !heightIn.isValid && (
                    <S.ErrorMessage>
                      {t('onboarding.height.errorMessage')}
                    </S.ErrorMessage>
                  )}
              </S.InputWrapper>
            )}
          </S.InputContainer>
        </S.Actions>

        <S.KitInfoBlock
          iconSrc={calculatorIcon}
          title={t('onboarding.height.info.title')}
          text={t('onboarding.height.info.textV2')}
        />

        <Button
          width="100%"
          type="submit"
          margin="0 auto"
          theme="nutrimatePrimary"
          disabled={
            isMetricSystemSelected
              ? !heightCm.isValid
              : !getIsImperialHeightValid()
          }
        >
          {t('actions.continue')}
        </Button>
      </KitPageContainer>
    </form>
  )
}
