import styled from 'styled-components'

import { Button } from 'components/Button'

import { EmailSubscriptions } from 'modules/purchase/components/EmailSubscriptions'
import { EmailSubscriptionsV2 } from 'modules/purchase/components/EmailSubscriptionsV2'

import reviewsBg from 'assets/images/purchase-email/reviews-bg-min.png'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledPurchaseEmail = {
  Root: styled.div`
    position: relative;
  `,
  Banner: styled.div`
    aspect-ratio: 6 / 5;
    margin: 0 auto;
    width: 100%;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
    }
  `,
  Title: styled.h1`
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: ${Color.GREY_100};
    margin-bottom: 16px;
  `,
  Description: styled.p`
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 40px;
    color: ${Color.DISABLED};
    text-align: center;
  `,

  HighlightsContainer: styled.div`
    margin-bottom: 40px;
  `,
  EmailSubscriptions: styled(EmailSubscriptions)`
    margin: 0 0 24px 0;
  `,
  EmailSubscriptionsV2: styled(EmailSubscriptionsV2)`
    margin: 0 0 16px 0;
  `,
  Button: styled(Button)`
    margin: 0 0 40px 0;
  `,

  Reviews: styled.div`
    overflow: hidden;
    padding: 24px 0 94px 0;
    background: url(${reviewsBg}) no-repeat top center;
    background-size: cover;
    border-radius: 64px 64px 0 0;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
      margin: 0 auto;
    }
  `,
  ReviewsTitle: styled.h2`
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: ${Color.WHITE};
    text-align: center;
    margin-bottom: 16px;
  `,
  ButtonContainer: styled.div`
    position: sticky;
    bottom: 10px;
    margin-top: -88px;
    padding: 0 16px;
  `,
}
