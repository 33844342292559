import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import banner from 'assets/images/thank-you-for-trust-banner.png'

import { Language } from 'root-constants'

import { StyledThankYouForYourTrust as S } from './ThankYouForYourTrust.styles'

export const ThankYouForYourTrust: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { title } = useDynamicOBConfig()

  const handleContinue = useNextStep({
    pageId,
    question:
      title || t('onboarding.thankYouForYourTrust.title', { lng: Language.EN }),
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  return (
    <S.PageContainer>
      <S.ImageContainer>
        <img src={banner} alt="banner" />
      </S.ImageContainer>
      <S.TextContainer>
        <S.Title>{title || t('onboarding.thankYouForYourTrust.title')}</S.Title>
        <S.Text>{t('onboarding.thankYouForYourTrust.firstParagraph')}</S.Text>
        <S.Text>{t('onboarding.thankYouForYourTrust.secondParagraph')}</S.Text>
      </S.TextContainer>
      <S.StickyButtonContainer>
        <S.Button type="button" onClick={handleNextClick}>
          {t('actions.continue')}
        </S.Button>
      </S.StickyButtonContainer>
    </S.PageContainer>
  )
}
