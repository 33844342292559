import styled, { css } from 'styled-components'

import { Button } from 'components/Button'
import { PageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { Color } from 'root-constants'

type TTextAlign = {
  textAlign: 'left' | 'center'
}

const commonTextStyles = css`
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: ${Color.GREY_100};
`

export const StyledEventFeedback = {
  PageContainer: styled(PageContainer)`
    padding: 24px 16px;
  `,
  PageTitle: styled(PageTitle)`
    line-height: 30px;
  `,
  InfoContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 24px 0;
  `,
  Prediction: styled.p`
    ${commonTextStyles};
    line-height: 22px;

    span {
      font-weight: 700;
      color: ${Color.PRIMARY};
    }
  `,
  LostLabel: styled.p`
    margin: 12px 0;
  `,
  LostValues: styled.span`
    background-color: #f5f6f7;
    border-radius: 8px;
    padding: 6px 12px;

    ${commonTextStyles}

    strong {
      font-weight: 700;
    }
  `,
  StickyButtonContainer: styled(StickyButtonContainer)`
    padding: 40px 0;
    z-index: 2;
  `,
}

export const StyledEventFeedbackV2 = {
  PageContainer: styled(PageContainer)`
    min-height: calc(100vh - 45px);
    justify-content: space-between;
  `,
  Banner: styled.div`
    aspect-ratio: 82 / 55;
    width: 100%;
    margin-bottom: 24px;
  `,
  Info: styled.h4<TTextAlign>`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: ${({ textAlign }) => textAlign};

    strong {
      font-weight: 700;
    }
  `,
  StickyButtonContainer: styled(StickyButtonContainer)`
    max-width: initial;
  `,
  Button: styled(Button)`
    max-width: 100%;
  `,
}
