import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import stars from 'assets/images/alternative-stars-v2.png'

import { Cohort } from 'root-constants'

import { StyledCustomerReviewsV2 as S } from './CustomerReviewsV2.styles'
import { CUSTOMER_REVIEWS_V1, CUSTOMER_REVIEWS_V2 } from './constants'

export const CustomerReviewsV2: React.FC = () => {
  const { t } = useTranslation()

  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const customerReviews = useMemo(
    () =>
      cohortToUse === Cohort.MF_MEN_FLOW
        ? CUSTOMER_REVIEWS_V2
        : CUSTOMER_REVIEWS_V1,
    [cohortToUse],
  )

  return (
    <S.Wrapper>
      <S.Title>{t('purchase.customerReviews.title')}</S.Title>
      {customerReviews.map(({ title, date, author, text }) => (
        <S.ReviewContainer key={author}>
          <S.ReviewHeader>
            <S.ReviewTitle>{t(title)}</S.ReviewTitle>
            <S.Date>{date}</S.Date>
            <S.Rating>
              <img src={stars} alt="stars" />
            </S.Rating>
            <S.Author>{author}</S.Author>
          </S.ReviewHeader>
          <S.Text>{t(text)}</S.Text>
        </S.ReviewContainer>
      ))}
    </S.Wrapper>
  )
}
