import React, { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { AgeRangeValue } from 'value-constants'

import { PageContainer } from 'components/PageContainer'

import {
  selectAgeRange,
  selectCurrentUserGender,
  selectCurrentUserPrimaryGoal,
} from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Language } from 'root-constants'

import { StyledPerfectSolution as S } from './PerfectSolution.styles'
import { GENDER_MAP, PERFECT_SOLUTION_MAP_V1 } from './constants'

type TProps = {
  perfectSolutionMap: typeof PERFECT_SOLUTION_MAP_V1
} & TPageProps

export const PerfectSolution: React.FC<TProps> = ({
  pageId,
  nextPagePath,
  perfectSolutionMap,
}) => {
  const { t } = useTranslation()

  const ageRange = useSelector(selectAgeRange)
  const goal = useSelector(selectCurrentUserPrimaryGoal)
  const gender = useSelector(selectCurrentUserGender)

  const { imgSrc, firstText, secondText, ages } = useMemo(
    () => perfectSolutionMap[goal],
    [goal, perfectSolutionMap],
  )

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.perfectSolution.title', { lng: Language.EN }),
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  return (
    <PageContainer>
      <S.ImageContainer>
        <img src={imgSrc[gender]} alt={gender} />
      </S.ImageContainer>
      <S.TextContainer>
        <S.Title>{t('onboarding.perfectSolution.title')}</S.Title>
        <S.Text>
          <Trans
            i18nKey={firstText}
            values={{
              age: t(ages[ageRange]) || t(ages[AgeRangeValue.AR_45_49]),
              gender: t(GENDER_MAP[gender]),
            }}
            components={{ span: <span /> }}
          />
        </S.Text>

        <S.Text>
          <Trans i18nKey={secondText} />
        </S.Text>
      </S.TextContainer>

      <S.StickyButtonContainer>
        <S.Button type="button" onClick={handleNextClick}>
          {t('actions.continue')}
        </S.Button>
      </S.StickyButtonContainer>
    </PageContainer>
  )
}
