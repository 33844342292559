import React, { FC, useCallback, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { eventLogger } from 'services/eventLogger.service'

import banner from 'assets/images/upsell-discount-banner.png'

import { StyledUpsellDiscountModal as S } from './UpsellDiscountModal.styles'

type TProps = {
  onClose?: () => void
}

export const UpsellDiscountModalV1: FC<TProps> = ({ onClose }) => {
  const { t } = useTranslation()

  useEffect(() => {
    eventLogger.logUpsellCoachingPopupShown()
  }, [])

  const handleClose = useCallback(() => {
    eventLogger.logUpsellCoachingPopupClosed()
    onClose?.()
  }, [onClose])

  return (
    <S.Wrapper>
      <S.Container>
        <S.Title>
          <Trans i18nKey="purchase.upsellDiscountModalV1.title" />
        </S.Title>
        <S.Subtitle>
          <Trans i18nKey="purchase.upsellDiscountModalV1.subtitle" />
        </S.Subtitle>

        <S.Banner>
          <img src={banner} alt="discount-banner" />
        </S.Banner>

        <S.Content>{t('purchase.upsellDiscountModalV1.content')}</S.Content>
      </S.Container>
      <S.ButtonContainer>
        <S.Button type="button" onClick={handleClose}>
          {t('actions.gotIt')}
        </S.Button>
      </S.ButtonContainer>
    </S.Wrapper>
  )
}
