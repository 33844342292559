import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledUsersChoiceV5 = {
  Title: styled.h2`
    display: flex;
    flex-direction: column;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: ${Color.GREY_100};
    row-gap: 12px;
    margin-bottom: 36px;
    text-align: center;

    strong {
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
      color: ${Color.GREEN_300};
    }
  `,
  Image: styled.div`
    aspect-ratio: 515 / 498;
    width: 100%;
  `,
}
