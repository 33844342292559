export const QUESTION = 'What else?'

const OPTION_VALUES = {
  DIDNT_FIT_LIFESTYLE: 'didnt_fit_lifestyle',
  TEMPTATIONS: 'temptations',
  FEELING_GUILTY: 'feeling_guilty',
  GAINING_WEIGHT: 'gaining_weight',
}

export const ANSWER_OPTIONS = [
  {
    value: OPTION_VALUES.DIDNT_FIT_LIFESTYLE,
    title: 'onboarding.whatElse.options.didntFitLifestyle',
  },
  {
    value: OPTION_VALUES.TEMPTATIONS,
    title: 'onboarding.whatElse.options.temptations',
  },
  {
    value: OPTION_VALUES.FEELING_GUILTY,
    title: 'onboarding.whatElse.options.feelingGuilty',
  },
  {
    value: OPTION_VALUES.GAINING_WEIGHT,
    title: 'onboarding.whatElse.options.gainingWeight',
  },
]
