import styled from 'styled-components'

import pageBg from 'assets/images/users-choice-bg.jpg'

import { MediaBreakpoint } from 'root-constants'

export const StyledUsersChoice = {
  Wrapper: styled.div`
    padding: 10px 0 24px;
    background-image: url(${pageBg});
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top center;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      background-size: 360px auto;
    }
  `,
  ImageContainer: styled.div`
    max-width: 218px;
    aspect-ratio: 218/346;
    margin: 0 auto 34px;
  `,
  Title: styled.h1`
    font-weight: 800;
    font-size: 32px;
    line-height: 32px;
    text-align: center;
    margin-bottom: 4px;
  `,
  Subtitle: styled.p`
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #131d30;
    margin-bottom: 48px;
  `,
}
