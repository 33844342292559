import React from 'react'

import { PayPalButtons } from '@paypal/react-paypal-js'
import styled from 'styled-components'

import { Button } from 'components/Button'

import paypalLogo from 'assets/images/paypal.png'

import { Color } from 'root-constants'

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isTouched: boolean
}

export const StyledPayPalButton = {
  Root: styled.div`
    z-index: 103;
  `,
  Container: styled.div`
    width: 100%;
    max-width: 362px;
    min-height: 362px;
    max-height: 360px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    background-color: ${Color.WHITE};
    margin: 0 20px;
  `,
  ButtonsContainer: styled.div`
    position: relative;
    z-index: 1;
    min-height: 55px;
    max-height: 55px;

    & iframe {
      border-radius: 16px;
      -webkit-tap-highlight-color: transparent;
      opacity: 0.0000000001 !important;
    }
  `,
  PayPalButtons: styled(PayPalButtons)<{ isHidden: boolean }>`
    ${({ isHidden }) => (isHidden ? 'display: none;' : 'display: block;')}
  `,
  FakePaypalButton: styled.button<IButtonProps>`
    width: 100%;
    height: 55px;
    line-height: 55px;
    border-radius: 16px;
    border: none;
    background: #f9c456 url(${paypalLogo}) no-repeat center;
    background-size: 102px 28px;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    padding: 0;
    z-index: 1;
    filter: ${({ isTouched }) =>
      isTouched ? 'brightness(0.95)' : 'brightness(1)'};
  `,

  ModalWrapper: styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(128, 128, 128, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 101;
  `,
  LottieContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  `,
  PaymentStatus: styled.div`
    font-style: normal;
    font-weight: 700;
    text-align: center;
    color: ${Color.GREY_100};
    padding: 5px 0 9px;
    font-size: 24px;
    line-height: 28px;
  `,
  ErrorStatus: styled.div`
    font-style: normal;
    font-weight: 700;
    text-align: center;
    color: ${Color.GREY_100};
    padding: 10px 15px;
    font-size: 15px;
    line-height: 18px;
  `,
  ErrorInfo: styled.div`
    padding: 10px 7px 32px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: ${Color.GREY_600};
  `,
  ErrorButton: styled(Button)`
    background-color: ${Color.ERROR};
    height: 50px;
  `,
  SuccessButton: styled(Button)`
    height: 50px;
    margin-top: 32px;
    background-color: ${Color.PRIMARY};
  `,
  Disclaimer: styled.p`
    padding: 16px 0 0 0;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: ${Color.DISABLED};
  `,
}
