import firebase from 'firebase/app'
import 'firebase/auth'
import { t } from 'i18next'

import { startFetching, stopFetching } from 'root-redux/actions/common'

import { IAction, TAppDispatchThunk } from 'models/store.model'

import { eventLogger } from 'services/eventLogger.service'

import { FirebaseSignUpErrorCode, Language } from 'root-constants'

const MODULE_NAME = 'LOGIN'
const REGISTER_WITH_EMAIL_FIREBASE = `${MODULE_NAME}/REGISTER_WITH_EMAIL_FIREBASE`

export const SET_EMAIL_ERROR_MESSAGE = `${MODULE_NAME}/SET_EMAIL_ERROR_MESSAGE`
export const RESET_EMAIL_ERROR_MESSAGE = `${MODULE_NAME}/RESET_EMAIL_ERROR_MESSAGE`

export const SET_PASSWORD_ERROR_MESSAGE = `${MODULE_NAME}/SET_PASSWORD_ERROR_MESSAGE`
export const RESET_PASSWORD_ERROR_MESSAGE = `${MODULE_NAME}/RESET_PASSWORD_ERROR_MESSAGE`

export const resetEmailErrorMessageAction = (): IAction<never> => ({
  type: RESET_EMAIL_ERROR_MESSAGE,
})

export const setEmailErrorMessageAction = (
  message: string,
): IAction<string> => ({
  type: SET_EMAIL_ERROR_MESSAGE,
  payload: message,
})

export const resetPasswordErrorMessageAction = (): IAction<never> => ({
  type: RESET_PASSWORD_ERROR_MESSAGE,
})

export const setPasswordErrorMessageAction = (
  message: string,
): IAction<string> => ({
  type: SET_PASSWORD_ERROR_MESSAGE,
  payload: message,
})

export function registerWithEmailFirebaseAction({
  email,
  password,
}: {
  email: string
  password: string
}): TAppDispatchThunk<any> {
  return async (dispatch) => {
    dispatch(startFetching(REGISTER_WITH_EMAIL_FIREBASE))

    try {
      await firebase.auth().createUserWithEmailAndPassword(email, password)
    } catch (error: any) {
      if (error.code === FirebaseSignUpErrorCode.EMAIL_EXISTS) {
        dispatch(setEmailErrorMessageAction(t('login.emailExists')))
        eventLogger.logAccountCreationFailed({
          error: t('login.emailExists', { lng: Language.EN }),
        })
      }
    }

    dispatch(stopFetching(REGISTER_WITH_EMAIL_FIREBASE))
  }
}
