import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledUserInfoBlock = {
  Container: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  `,
  Column: styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1;

    &:first-of-type {
      text-align: right;
    }

    &:last-of-type {
      text-align: left;
    }
  `,
  Divider: styled.div`
    margin: 0 24px;
    width: 2px;
    height: 36px;
    background-color: ${Color.GREY_200};
  `,
  InfoLabel: styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: ${Color.GREY_100};
    margin: 0 0 6px 0;
  `,
  Info: styled.p`
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: ${Color.GREY_100};
  `,
}
