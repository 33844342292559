import styled from 'styled-components'

import { AnswerButton } from 'components/AnswerButton'

export const StyledGenderV4 = {
  Title: styled.h2`
    margin: 24px 0;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
  `,
  TextContainer: styled.div`
    display: grid;
    grid-template-columns: 24px 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'image subtitle' 'image disclaimer';
    grid-gap: 4px 8px;
    margin-bottom: 24px;
    padding: 16px;
    border-radius: 12px;
    background-color: #fff4e3;
  `,
  PointUpImage: styled.img`
    grid-area: image;
    width: 24px;
  `,
  Subtitle: styled.h3`
    grid-area: subtitle;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
  `,
  Disclaimer: styled.p`
    grid-area: disclaimer;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
  `,
  AnswerButton: styled(AnswerButton)`
    border: 2px solid #edeff0;
  `,
}
