import styled from 'styled-components'

import calendarIconV1 from 'assets/images/calendar.svg'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledCustomDatePicker = {
  WrapperDataField: styled.div`
    border-radius: 12px;
    border: 2px solid ${Color.LIGHT_GRAY};
    background: ${Color.WHITE};
    padding: 16px 16px 16px 20px;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    height: 54px;
    display: flex;
    justify-content: space-around;
    cursor: pointer;
  `,
  DateField: styled.div`
    user-select: none;
    margin-right: 7px;
  `,
  CalendarIcon: styled.div`
    width: 20px;
    height: 20px;
    right: calc(50% - 66px);
    bottom: 17px;
    background: url(${calendarIconV1});

    path {
      display: none;
    }
  `,
  Wrapper: styled.div`
      position: relative;

      .react-datepicker__input-container {
        position: relative;
        display: flex;
        justify-content: center;
        margin-top: 32px;
      }

      .react-datepicker {
        min-width: 328px;
        background: ${Color.WHITE};
        box-shadow: 0 8px 20px rgba(17, 30, 23, 0.1);
        border-radius: 16px;
        border: none;
        color: transparent;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
      }

      .react-datepicker__navigation {
        top: 12px;
      }

      .react-datepicker__current-month {
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        padding-bottom: 17px;
      }

      .react-datepicker__day-name {
        font-weight: 600;
        font-size: 14px;
        color: ${Color.DISABLED};
        margin: 6px;
      }

      .react-datepicker__week {
        .react-datepicker__day--selected {
          background: #F1FFF8;
          border: 2px solid #6FD09A;
          border-radius: 12px;
          color: ${Color.PRIMARY};
          outline: none;
        }

        .react-datepicker__day--keyboard-selected {
          background-color: transparent;
        }

        .react-datepicker__day {
          width: 36px;
          height: 36px;
          line-height: 30px;
          font-weight: 600;
          font-size: 14px;
        }

      ,
      }

      .react-datepicker-popper[data-placement^=bottom] {
        padding-top: 0;
        transform: translate3d(23px, 190px, 0px);
        z-index: 5;

        @media (min-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
          transform: translate3d(43px, 190px, 0px);
        }
      }

      .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
        margin-top: 0 !important;
        transform: none !important;
        left: 0 !important;

        ::after,
        ::before {
          border: none !important;
        }
      }

      .react-datepicker__header {
        padding-top: 13px !important;
        padding-bottom: 0;
        background-color: transparent;
        border: none;
      }

      .react-datepicker__month {
        margin: 20px 12px;
      }

      .react-datepicker__month-container {
        width: 328px;
      }

      .react-datepicker__input-container input {
        width: 153px;
        height: 54px;
        border: 2px solid ${Color.LIGHT_GRAY};
        border-radius: 12px;
        background: ${Color.WHITE};
        align-items: center;
        padding: 16px 16px 16px 20px;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;

        &:focus {
          outline: none !important;
          border-color: ${Color.PRIMARY}
        }
      }

      .react-datepicker__day--outside-month {
        color: ${Color.GREY};
      }
    `,
}
