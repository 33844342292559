import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import questionIconFlower from 'assets/images/question-icon-flower.png'

import { Cohort } from 'root-constants'

import {
  ListItemText,
  ListItemTitle,
  QuestionIcon,
  StyledFAQList as S,
  Title,
} from './FAQList.styles'
import { QUESTIONS_LIST_ITEMS_V1, QUESTIONS_LIST_ITEMS_V2 } from './constants'

type TProps = {
  questionIconSrc?: string
  className?: string
}

export const FAQList: React.FC<TProps> = ({
  questionIconSrc = questionIconFlower,
  className,
}) => {
  const { t } = useTranslation()

  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const questionsListItems = useMemo(
    () =>
      cohortToUse === Cohort.MF_MEN_FLOW
        ? QUESTIONS_LIST_ITEMS_V2
        : QUESTIONS_LIST_ITEMS_V1,
    [cohortToUse],
  )

  return (
    <S.Wrapper className={className}>
      <Title>{t('purchase.faqList.title')}</Title>
      <ul>
        {questionsListItems.map((item) => (
          <S.ListItem key={item.id}>
            <QuestionIcon>
              <img src={questionIconSrc} alt="question" />
            </QuestionIcon>
            <div>
              <ListItemTitle>{t(item.question)}</ListItemTitle>
              <ListItemText>{t(item.answer)}</ListItemText>
            </div>
          </S.ListItem>
        ))}
      </ul>
    </S.Wrapper>
  )
}
