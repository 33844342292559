import React from 'react'

import { CreditCardForm } from 'modules/purchase/components'

import { StyledCreditCardPayment as S } from './CreditCardPayment.styles'

export const CreditCardPayment = () => (
  <>
    <S.CreditCardTitleV2 />
    <CreditCardForm />
  </>
)
