export const CUSTOMER_REVIEWS_V1 = [
  {
    title: 'purchase.customerReviews.firstReviewTitle',
    date: '05.09.2023',
    author: 'Luke86',
    text: 'purchase.customerReviewsV2.firstReviewText',
  },
  {
    title: 'purchase.customerReviews.secondReviewTitle',
    date: '29.03.2023',
    author: 'Athletic',
    text: 'purchase.customerReviewsV2.secondReviewText',
  },
  {
    title: 'purchase.customerReviews.thirdReviewTitle',
    date: '20.11.2022',
    author: 'PBarcelo',
    text: 'purchase.customerReviewsV2.thirdReviewText',
  },
]

export const CUSTOMER_REVIEWS_V2 = [
  {
    title: 'purchase.customerReviews.firstReviewTitle',
    date: '05.09.2023',
    author: 'Luke86',
    text: 'purchase.customerReviewsV2.firstReviewText',
  },
  {
    title: 'purchase.customerReviews.secondReviewTitle',
    date: '29.03.2023',
    author: 'Athletic',
    text: 'purchase.customerReviewsV2.secondReviewText',
  },
  {
    title: 'purchase.customerReviews.thirdReviewTitle',
    date: '20.11.2022',
    author: 'PBarcelo',
    text: 'purchase.customerReviewsV2.thirdReviewTextV2',
  },
]
