import styled, { css } from 'styled-components'

import { Input } from 'components/Input'
import { SvgImage } from 'components/SvgImage'

import { Color } from 'root-constants'

export const StyledEmailV2 = {
  TitleContainer: styled.header`
    margin-top: 24px;

    strong {
      color: ${Color.PRIMARY};
    }
  `,
  Input: styled(Input)`
    padding: 25px 24px;
    margin-bottom: 5px;
    height: 72px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    background: ${Color.WHITE};
    border: 2px solid ${Color.LIGHT_BLUE};
    border-radius: 12px;
    ${({ isValid, value }) =>
      value &&
      !isValid &&
      css`
        background: rgba(224, 105, 140, 0.1);
        border: 2px solid ${Color.PINK};
        color: ${Color.PINK};
      `};

    ${({ isValid, value }) =>
      value &&
      isValid &&
      css`
        border: 2px solid ${Color.PRIMARY};
      `};

    &:focus {
      ::placeholder {
        color: transparent;
      }
    }
  `,
  ErrorMessage: styled.p`
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: ${Color.PINK};

    & svg {
      width: 18px;
      height: 18px;
      margin-right: 4px;
    }
  `,
  Disclaimer: styled.p`
    display: flex;
    align-items: flex-start;
    margin-bottom: 30px;
    margin-top: 30px;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    color: rgba(19, 29, 48, 0.5);
  `,
  LockIcon: styled(SvgImage)`
    flex-shrink: 0;
    margin-right: 10px;
  `,
  ButtonContainer: styled.div`
    position: relative;
    margin-bottom: 30px;
  `,
}
