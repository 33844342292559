import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { GET_SUBSCRIPTION_LIST, startFetching } from 'root-redux/actions/common'
import { selectUUID, selectUserOnboardingEmail } from 'root-redux/selects/user'

import { useCommonStore } from 'hooks/useCommonStore'

import { OnboardingButtonText } from 'modules/purchase/pages/constants'
import { CHECK_PAYMENT_REQUEST_BUTTON } from 'modules/purchase/redux/actions/common'
import { selectSubscription } from 'modules/purchase/redux/selects'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { OutbrainEventName, PagePath, TaboolaEventName } from 'root-constants'

export const usePayment = () => {
  const dispatch = useDispatch()

  const selectedSubscription = useSelector(selectSubscription)
  const uuid = useSelector(selectUUID)
  const email = useSelector(selectUserOnboardingEmail)

  const { cohort, screenName, fetchingActionsList, subscriptionsList } =
    useCommonStore()

  const hasPrices = useMemo(
    () =>
      !fetchingActionsList?.includes(GET_SUBSCRIPTION_LIST) &&
      !!subscriptionsList.length,
    [fetchingActionsList, subscriptionsList],
  )

  const handleAmplitudePaymentEvents = useCallback(
    (target: HTMLButtonElement) =>
      eventLogger.logPlansPageButtonTapped({
        screenName,
        buttonNumber: +(target.getAttribute('data-button-number') || 1),
        buttonText:
          (target.getAttribute('data-button-text') as OnboardingButtonText) ||
          OnboardingButtonText.GET_PLAN_UPPER,
      }),
    [screenName],
  )

  const handleShowPayment = useCallback(() => {
    dispatch(startFetching(CHECK_PAYMENT_REQUEST_BUTTON))

    window.fbq('track', 'AddToCart', {}, { eventID: uuid })
    window.ttq.track('AddToCart')
    window.snaptr('track', 'ADD_CART', { user_email: email })
    window.obApi && window.obApi('track', OutbrainEventName.ADD_TO_CART)
    window._tfa &&
      window._tfa.push({
        notify: 'event',
        name: TaboolaEventName.ADD_TO_CART,
      })

    googleAnalyticsLogger.logPageView(`${PagePath.CHECKOUT}/${cohort}`)

    if (selectedSubscription) {
      googleAnalyticsLogger.logAddingToCart(selectedSubscription)
    }
  }, [dispatch, uuid, email, cohort, selectedSubscription])

  return {
    hasPrices,
    handleShowPayment,
    handleAmplitudePaymentEvents,
  }
}
