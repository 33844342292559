import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { WorkOutFrequencyValue } from 'value-constants'

import { AnswerButton, IconVerticalAlignments } from 'components/AnswerButton'
import { Container } from 'components/Container'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import athleticFemaleIcon from 'assets/images/athletic-female.png'
import athleticMaleIcon from 'assets/images/athletic-male.png'
import drinkingFemaleIcon from 'assets/images/drinking-female.png'
import drinkingMaleIcon from 'assets/images/drinking-male.png'
import pressUpMaleIcon from 'assets/images/press-up-male.png'
import sitUpFemaleIcon from 'assets/images/sit-up-female.png'

import { Gender, Language } from 'root-constants'

export const Workout: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const gender = useSelector(selectCurrentUserGender)

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.workout.title', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, setIsAnswersDisabled, handleContinue],
  )

  const isFemale = useMemo(() => gender === Gender.FEMALE, [gender])

  return (
    <Container>
      <PageTitle marginBottom={24}>{t('onboarding.workout.title')}</PageTitle>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={WorkOutFrequencyValue.THREE_FIVE_A_WEEK}
      >
        <AnswerButton
          iconSrc={isFemale ? athleticFemaleIcon : athleticMaleIcon}
          iconWidth={92}
          iconHeight={92}
          iconVerticalAlignment={IconVerticalAlignments.CENTER}
        >
          <h3>{t('onboarding.workout.options.threeFiveAWeek')}</h3>
        </AnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={WorkOutFrequencyValue.ONE_TWO_A_WEEK}
      >
        <AnswerButton
          iconSrc={isFemale ? sitUpFemaleIcon : pressUpMaleIcon}
          iconWidth={92}
          iconHeight={92}
          iconVerticalAlignment={IconVerticalAlignments.CENTER}
        >
          <h3>{t('onboarding.workout.options.oneTwoAWeek')}</h3>
        </AnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={WorkOutFrequencyValue.RARELY}
      >
        <AnswerButton
          iconSrc={isFemale ? drinkingFemaleIcon : drinkingMaleIcon}
          iconWidth={92}
          iconHeight={92}
          iconVerticalAlignment={IconVerticalAlignments.CENTER}
        >
          <h3>{t('onboarding.workout.options.rarely')}</h3>
        </AnswerButton>
      </Option>
    </Container>
  )
}
