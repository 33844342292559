import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { TOptions } from 'components/Select'

import {
  selectAnswers,
  selectCurrentVariantCohortToUse,
} from 'root-redux/selects/common'

import { CustomPageId } from 'page-constants'
import { Cohort } from 'root-constants'

import { PRIMARY_GOAL_OPTIONS_V1, PRIMARY_GOAL_OPTIONS_V2 } from '../constants'

export const usePrimaryGoalField = (): {
  selectedPrimaryGoal: TOptions | undefined
  handlePrimaryGoalChange: (obj: TOptions) => void
} => {
  const { t } = useTranslation()

  const [selectedPrimaryGoal, setSelectedPrimaryGoal] = useState<
    TOptions | undefined
  >()

  const userAnswers = useSelector(selectAnswers)
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const defaultPrimaryGoal = useMemo(
    () =>
      (cohortToUse === Cohort.MF_MEN_FLOW
        ? PRIMARY_GOAL_OPTIONS_V2
        : PRIMARY_GOAL_OPTIONS_V1
      ).find(
        (option) => option.value === userAnswers?.[CustomPageId.PRIMARY_GOAL],
      ),
    [cohortToUse, userAnswers],
  )

  useEffect(() => {
    if (defaultPrimaryGoal) {
      setSelectedPrimaryGoal({
        ...defaultPrimaryGoal,
        label: t(defaultPrimaryGoal.label),
      })
    }
  }, [t, defaultPrimaryGoal])

  const handlePrimaryGoalChange = useCallback((obj) => {
    setSelectedPrimaryGoal(obj)
  }, [])

  return {
    selectedPrimaryGoal,
    handlePrimaryGoalChange,
  }
}
