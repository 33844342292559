import React, { FC, useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { PageTitle } from 'components/PageTitle'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Emojis, Gender, Language } from 'root-constants'

import { StyledEventFeedbackV2 as S } from './EventFeedback.styles'
import { EVENT_FEEDBACK_INFO_MAP } from './constants'

export const EventFeedbackV2: FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const gender = useSelector(selectCurrentUserGender)

  const isFemale = useMemo(() => gender === Gender.FEMALE, [gender])

  const { info, imgSrc } = useMemo(
    () => EVENT_FEEDBACK_INFO_MAP[gender],
    [gender],
  )

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.eventFeedbackV2.title', {
      lng: Language.EN,
    }),
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  return (
    <S.PageContainer>
      <div>
        <S.Banner>
          <img src={imgSrc} alt="event-feedback-banner" />
        </S.Banner>
        <PageTitle textAlign={isFemale ? 'center' : 'left'} marginBottom={24}>
          {t('onboarding.eventFeedbackV2.title')}
        </PageTitle>
        <S.Info textAlign={isFemale ? 'center' : 'left'}>
          <Trans
            i18nKey={info}
            values={{
              emoji: Emojis.WAVING_HAND,
            }}
          />
        </S.Info>
      </div>

      <S.StickyButtonContainer>
        <S.Button type="button" onClick={handleNextClick}>
          {t('actions.continue')}
        </S.Button>
      </S.StickyButtonContainer>
    </S.PageContainer>
  )
}
