import React, { FC, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { PageContainer } from 'components/PageContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import checkIcon from 'assets/images/check-benefits.png'

import { Language } from 'root-constants'

import { StyledFastingFeedback as S } from './FastingFeedback.styles'
import { BENEFITS, PERIODS } from './constants'

export const FastingFeedbackV1: FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.fastingFeedbackV1.title', { lng: Language.EN }),
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  return (
    <PageContainer>
      <S.PageTitle marginBottom={24}>
        {t('onboarding.fastingFeedbackV1.title')}
      </S.PageTitle>

      <S.Info>
        <Trans i18nKey="onboarding.fastingFeedbackV1.info" />
      </S.Info>

      <S.Period>
        {PERIODS.map((period) => (
          <S.PeriodItem key={period}>
            <S.Circle />
            <S.PeriodContent>
              <Trans i18nKey={period} />
            </S.PeriodContent>
          </S.PeriodItem>
        ))}
      </S.Period>

      <S.CallToAction>
        <Trans i18nKey="onboarding.fastingFeedbackV1.callToAction" />
      </S.CallToAction>

      <S.Benefits>
        <S.BenefitsTitle>
          {t('onboarding.fastingFeedbackV1.benefits.title')}
        </S.BenefitsTitle>
        <S.BenefitsList>
          {BENEFITS.map((benefit) => (
            <S.BenefitsItem key={benefit}>
              <S.BenefitsIcon>
                <img src={checkIcon} alt="benefit" />
              </S.BenefitsIcon>
              {t(benefit)}
            </S.BenefitsItem>
          ))}
        </S.BenefitsList>
      </S.Benefits>

      <S.StickyButtonContainer>
        <S.Button type="button" onClick={handleNextClick}>
          {t('actions.continue')}
        </S.Button>
      </S.StickyButtonContainer>
    </PageContainer>
  )
}
