import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from 'components/Button'
import { Spinner } from 'components/Spinner'

import {
  getSubscriptionListAction,
  setScreenNameAction,
} from 'root-redux/actions/common'
import { selectSubscriptionList } from 'root-redux/selects/common'
import { TAppDispatch } from 'root-redux/store/store'

import { useVatInfo } from 'hooks/useHasVatInfo'
import { usePayment } from 'hooks/usePayment'
import { useTimer } from 'hooks/useTimer'
import {
  ViewportScrollPercentage,
  useViewportValue,
} from 'hooks/useViewportValue'

import { createProductId } from 'helpers/createProductId'

import { CustomerReviewsV2 } from 'modules/purchase/components/CustomerReviews'
import { PhotoResultV2 } from 'modules/purchase/components/PhotoResult'
import { TEN_MINUTES_IN_SECONDS } from 'modules/purchase/constants'
import { useDynamicPaywallConfig } from 'modules/purchase/hooks/useDynamicPaywallConfig'
import { setSubscriptionTimerValueAction } from 'modules/purchase/redux/actions/common'
import { selectSubscriptionTimerValue } from 'modules/purchase/redux/selects'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import {
  Emojis,
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
} from 'root-constants'

import { OnboardingButtonText, PURCHASE_OFFSET_TOP_V2 } from '../constants'
import { StyledPurchaseVariant6 as S } from './PurchaseVariant6.styles'

export const PurchaseVariant6: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()

  const subscriptions = useSelector(selectSubscriptionList)
  const timerValueFromStore = useSelector(selectSubscriptionTimerValue)

  const hasVatInfo = useVatInfo()
  const viewportValue = useViewportValue()
  const { title, buttonText } = useDynamicPaywallConfig()
  const { hasPrices, handleShowPayment, handleAmplitudePaymentEvents } =
    usePayment()

  const titleElementRef = useRef<HTMLHeadingElement>(null)
  const firstSubscriptionsRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    dispatch(setScreenNameAction(ScreenName.ONBOARDING))

    dispatch(
      getSubscriptionListAction(
        SubscriptionListType.PURCHASE,
        hasVatInfo ? SubscriptionTags.TAX : SubscriptionTags.NO_TAX,
      ),
    )
  }, [dispatch, hasVatInfo])

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(({ mainPrices }) =>
        createProductId({
          periodName: mainPrices.periodName,
          periodQuantity: mainPrices.periodQuantity,
          price: mainPrices.fullPrice,
        }),
      ),
      screenName: ScreenName.ONBOARDING,
    })
  }, [subscriptions])

  useEffect(() => {
    if (viewportValue > ViewportScrollPercentage.V_0) {
      eventLogger.logScrollToBottom(viewportValue, ScreenName.ONBOARDING)
    }
  }, [viewportValue])

  useEffect(() => {
    if (timerValueFromStore === null) {
      dispatch(setSubscriptionTimerValueAction(TEN_MINUTES_IN_SECONDS))
    }
  }, [dispatch, timerValueFromStore])

  const handleGetPlan = useCallback(
    (event: SyntheticEvent<HTMLButtonElement>) => {
      handleShowPayment()
      handleAmplitudePaymentEvents(event.target as HTMLButtonElement)

      goTo(nextPagePath)
    },
    [handleShowPayment, handleAmplitudePaymentEvents, nextPagePath],
  )

  const handleScrollOnSubscriptionBlock = useCallback(
    (event: SyntheticEvent<HTMLButtonElement>) => {
      const top = firstSubscriptionsRef.current?.offsetTop

      if (top) {
        // TODO: avoid const PURCHASE_OFFSET_TOP_V2
        document.body.scrollTo(0, top - PURCHASE_OFFSET_TOP_V2)
      }

      handleAmplitudePaymentEvents(event.target as HTMLButtonElement)
    },
    [handleAmplitudePaymentEvents],
  )

  const handleSetTimerValue = useCallback(
    (timerValue: number) =>
      dispatch(setSubscriptionTimerValueAction(timerValue)),
    [dispatch],
  )

  useTimer(timerValueFromStore, handleSetTimerValue)

  return !hasPrices ? (
    <Spinner />
  ) : (
    <div>
      <S.DiscountTimer
        observableElemRef={titleElementRef}
        onButtonClick={handleScrollOnSubscriptionBlock}
      />
      <S.Wrapper>
        <S.IntroMediaV5 />
        <S.Title ref={titleElementRef}>
          {title || t('purchaseV6.title')}
        </S.Title>
        <S.UserInfoBlock />
        <S.SubscriptionsBlock ref={firstSubscriptionsRef} hasTitle={false} />
        <S.MoneyBackText>
          {t('purchase.moneyBackGuaranteeV3.description')}
        </S.MoneyBackText>
        <S.Button
          type="button"
          data-button-number="2"
          data-button-text={OnboardingButtonText.GET_PLAN_MIDDLE}
          onClick={handleGetPlan}
        >
          {buttonText || t('actions.getMyPlan')}
        </S.Button>
        <S.Disclaimer />
      </S.Wrapper>
      <S.WeightLossJourney
        hasIndent={false}
        title="purchaseV6.weightLossJourneyTitle"
      />
      <S.Wrapper>
        <S.AppReached>
          <Button
            type="button"
            data-button-number="3"
            data-button-text={OnboardingButtonText.GET_PLAN_MIDDLE_2}
            onClick={handleScrollOnSubscriptionBlock}
          >
            {buttonText || t('actions.getMyPlan')}
          </Button>
        </S.AppReached>
        <S.WhatYouGetV1 />
        <S.SuccessStoriesTitle>
          <Trans
            i18nKey="purchaseV6.successStoriesTitle"
            values={{ emoji: Emojis.STAR_EYES }}
          />
        </S.SuccessStoriesTitle>

        <CustomerReviewsV2 />
        <PhotoResultV2 titleI18nKey="purchaseV6.photoResultTitle" />
        <S.FeaturedBlock />
        <S.SubscriptionBlockTitle>
          {t('purchaseV6.subscriptionBlockTitle')}
        </S.SubscriptionBlockTitle>
        <S.SubscriptionsBlock hasTitle={false} />
        <S.MoneyBackText>
          {t('purchase.moneyBackGuaranteeV3.description')}
        </S.MoneyBackText>
        <S.Button
          type="button"
          data-button-number="4"
          data-button-text={OnboardingButtonText.GET_PLAN_BOTTOM}
          onClick={handleGetPlan}
        >
          {buttonText || t('actions.getMyPlan')}
        </S.Button>
        <S.Disclaimer />
        <S.MoneyBackGuaranteeV3 />
        <S.SecurityInfo topIndent={32} />
      </S.Wrapper>
    </div>
  )
}
