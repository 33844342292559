import React from 'react'

import { PaymentInstrumentType } from '@primer-io/checkout-web'

import {
  SubscriptionItem,
  SubscriptionItemV2,
  SubscriptionItemV3,
  SubscriptionItemV3Trial,
  SubscriptionItemV3WithoutWeeklyTrial,
  SubscriptionItemV4,
  SubscriptionItemV4WithoutWeeklyTrial,
  SubscriptionItemWithoutWeeklyTrial,
} from 'modules/purchase/components'
import {
  SubscriptionItemV5,
  SubscriptionItemV5Trial,
} from 'modules/purchase/components/SubscriptionItemV5'
import { TSubscriptionItemProps } from 'modules/purchase/types'

import { Cohort } from 'root-constants'

import {
  KitSubscriptionItemV1,
  KitSubscriptionItemV1Trial,
} from './components/KitSubscriptionItem'

export const TEN_MINUTES_IN_SECONDS = 600
export const FIFTEEN_MINUTES_IN_SECONDS = 900
export const enum AnimationState {
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
}

export const EMPTY_FIELD_ERROR = `can't be blank`
export const DEFAULT_CARDHOLDER_NAME = ' '

export const enum StripeErrorCode {
  CARD_DECLINED = 'card_declined',
}

export const COHORTS_SUPPORTING_TRIAL_PRICE = [
  Cohort.MF_INTRO,
  Cohort.MF_FLOW_2,
  Cohort.MF_INTRO_2,
  Cohort.MF_INFLUENCER,
  Cohort.MF_WOMEN_AGE,
  Cohort.MF_WOMEN_AGE_2,
  Cohort.MF_WOMEN_45,
  Cohort.MF_FLOW_2_INTRO_2,
  Cohort.MF_MEN_FLOW,
]

export const enum PaymentMethod {
  CREDIT_CARD = 'credit_card',
  ONE_CLICK = 'one_click',
  APPLE_PAY = 'apple_pay',
  GOOGLE_PAY = 'google_pay',
  PAYPAL = 'pay_pal',
}

export const enum PaymentSystem {
  PAYPAL = 'paypal',
  PRIMER = 'primer',
  STRIPE = 'stripe',
}

export const THREEDS_REDIRECT_SEARCH_PARAM = 'threeds'

export const enum StripeSoftDeclineReason {
  AUTHENTICATION_FAILURE = 'payment_intent_authentication_failure',
  DO_NOT_HONOR = 'do_not_honor',
  INSUFFICIENT_FUNDS = 'insufficient_funds',
  INVALID_AMOUNT = 'invalid_amount',
  PROCESSING_ERROR = 'processing_error',
  REENTER_TRANSACTION = 'reenter_transaction',
  SERVICE_NOT_ALLOWED = 'service_not_allowed',
  TRANSACTION_NOT_ALLOWED = 'transaction_not_allowed',
  CARD_VELOCITY_EXCEEDED = 'card_velocity_exceeded',
}

export const STRIPE_SOFT_DECLINE_REASONS = [
  StripeSoftDeclineReason.AUTHENTICATION_FAILURE,
  StripeSoftDeclineReason.DO_NOT_HONOR,
  StripeSoftDeclineReason.INSUFFICIENT_FUNDS,
  StripeSoftDeclineReason.INVALID_AMOUNT,
  StripeSoftDeclineReason.PROCESSING_ERROR,
  StripeSoftDeclineReason.REENTER_TRANSACTION,
  StripeSoftDeclineReason.SERVICE_NOT_ALLOWED,
  StripeSoftDeclineReason.TRANSACTION_NOT_ALLOWED,
  StripeSoftDeclineReason.CARD_VELOCITY_EXCEEDED,
]

export const enum Currency {
  USD = 'usd',
  EUR = 'eur',
  GBP = 'gbp',
  NZD = 'nzd',
  AUD = 'aud',
  CAD = 'cad',
  MXN = 'mxn',
}

export const CURRENCY_SYMBOLS = {
  [Currency.USD]: '$',
  [Currency.EUR]: '€',
  [Currency.GBP]: '£',
  [Currency.NZD]: 'NZ$',
  [Currency.AUD]: 'AU$',
  [Currency.CAD]: 'C$',
  [Currency.MXN]: 'MXN',
}

export const enum PurchasePageType {
  WEB_LANDING = 'web_landing',
  EMAIL_LANDING = 'email_landing',
}

export const enum PurchaseProduct {
  SEVEN_DAY = 'sevenDay',
  ONE_MONTH = 'oneMonth',
  TWO_MONTHS = 'twoMonths',
  FOUR_MONTHS = 'fourMonths',
  THREE_MONTHS = 'threeMonths',
  SIX_MONTHS = 'sixMonths',
}

export const COHORTS_WITHOUT_TRIAL_FOR_WEEKLY_PLAN = [
  Cohort.MF_INTRO_2,
  Cohort.MF_INFLUENCER,
  Cohort.MF_WOMEN_AGE,
  Cohort.MF_WOMEN_AGE_2,
  Cohort.MF_WOMEN_45,
  Cohort.MF_FLOW_2_INTRO_2,
  Cohort.MF_MEN_FLOW,
]

export const enum Period {
  SEVEN_DAYS = 7,
}

export const enum PeriodName {
  DAY = 'day',
}

export const SUBSCRIPTION_ITEMS: Record<
  string,
  React.FC<TSubscriptionItemProps>
> = {
  variant_1: SubscriptionItem,
  variant_1_no_weekly_trial: SubscriptionItemWithoutWeeklyTrial,
  variant_2: SubscriptionItemV2,
  variant_3: SubscriptionItemV3,
  variant_3_trial: SubscriptionItemV3Trial,
  variant_3_no_weekly_trial: SubscriptionItemV3WithoutWeeklyTrial,
  variant_4: SubscriptionItemV4,
  variant_4_no_weekly_trial: SubscriptionItemV4WithoutWeeklyTrial,
  variant_5: SubscriptionItemV5,
  variant_5_trial: SubscriptionItemV5Trial,
  kit_variant_1: KitSubscriptionItemV1,
  kit_variant_1_trial: KitSubscriptionItemV1Trial,
}

export const EMAIL_COHORTS = [
  Cohort.MF_EMAIL_FLOW,
  Cohort.MF_EMAIL_FLOW_2,
  Cohort.MF_EMAIL_FLOW_3,
]

export const EMAIL_FLOW_DISCOUNT = 50

export const WEEKS_BY_PRODUCT_NAME_MAP = {
  [PurchaseProduct.SEVEN_DAY]: 1,
  [PurchaseProduct.ONE_MONTH]: 4,
  [PurchaseProduct.THREE_MONTHS]: 12,
}

export type TCardPaymentSystem = PaymentSystem.PRIMER | PaymentSystem.STRIPE

export const PRIMER_SOFT_DECLINE = 'SOFT_DECLINE'

export const PRIMER_PAYMENT_ERRORS = {
  INSUFFICIENT_FUNDS: 'purchase.paymentErrors.insufficientFunds',
  PAYMENT_FAILED: 'purchase.paymentErrors.insufficientFunds',
  DECLINED: 'purchase.paymentErrors.declinedError',
  AUTHENTICATION_REQUIRED: 'purchase.paymentErrors.authenticationRequired',
  WITHDRAWAL_LIMIT_EXCEEDED: 'purchase.paymentErrors.withdrawLimit',
  EXPIRED_CARD: 'purchase.paymentErrors.expiredCard',
  COMMON_ERROR: 'purchase.paymentErrors.somethingWentWrongError',
  ERROR: 'purchase.paymentErrors.somethingWentWrongError',
  INVALID_CARD_NUMBER: 'purchase.paymentErrors.somethingWentWrongError',
  LOST_OR_STOLEN_CARD: 'purchase.paymentErrors.somethingWentWrongError',
  SUSPECTED_FRAUD: 'purchase.paymentErrors.somethingWentWrongError',
  UNKNOWN: 'purchase.paymentErrors.somethingWentWrongError',
  REFER_TO_CARD_ISSUER: 'purchase.paymentErrors.somethingWentWrongError',
  DO_NOT_HONOR: 'purchase.paymentErrors.somethingWentWrongError',
  ISSUER_TEMPORARILY_UNAVAILABLE:
    'purchase.paymentErrors.somethingWentWrongError',
}

export const PRIMER_PAY_PAL_ID = 'paypal-button'

export const INITIAL_PRIMER_CONFIG = {
  card: {
    cardholderName: {
      required: false,
    },
  },
}

export const PRIMER_PAYMENT_METHODS_MAP = {
  [PaymentInstrumentType.CARD]: PaymentMethod.CREDIT_CARD,
  [PaymentInstrumentType.APPLE_PAY]: PaymentMethod.APPLE_PAY,
  [PaymentInstrumentType.GOOGLE_PAY]: PaymentMethod.GOOGLE_PAY,
  [PaymentInstrumentType.PAYPAL]: PaymentMethod.PAYPAL,
  [PaymentInstrumentType.PAYPAL_VAULTED]: PaymentMethod.PAYPAL,
}
