import React, { useRef } from 'react'
import ReactSelect from 'react-select'

import selectedIcon from 'assets/images/sprite/check-icon.svg'

import { StyledSelect as S, customSelectStyles } from './Select.styles'

export type TOptions = {
  value: string
  label: string
}

type TSelectProps = {
  placeholder: string
  options: TOptions[]
  defaultValue?: TOptions | undefined | TOptions[]
  value?: TOptions | TOptions[]
  isMultiSelect?: boolean
  onBlur?: (event: any) => void
  onChange?: (event: any) => void
}

export const Select: React.FC<TSelectProps> = ({
  placeholder,
  options,
  isMultiSelect = false,
  defaultValue,
  value,
  onBlur,
  onChange,
}) => {
  const rootRef = useRef<HTMLDivElement>(null)
  const handleBlur = (event) => {
    onBlur && onBlur(event)
  }

  const handleChange = (event) => {
    onChange && onChange(event)
  }

  const CustomOption = ({
    innerProps,
    isDisabled,
    innerRef,
    isSelected,
    isMulti,
    children,
  }) =>
    !isDisabled ? (
      <S.Option {...innerProps} ref={innerRef}>
        {children}
        {isSelected && <S.SelectedIcon svg={selectedIcon} width={20} />}
        {isMulti && !isSelected && <S.CircleMarkIcon />}
      </S.Option>
    ) : null

  const MultiValueContainer = ({ selectProps, data }) => {
    const values = selectProps.value
    if (values) {
      return values[values.length - 1].label === data.label ? (
        data.label
      ) : (
        <S.MultiValue>{`${data.label},`} </S.MultiValue>
      )
    }
    return ''
  }

  const handleMenuOpen = () => {
    setTimeout(() => {
      if (rootRef.current) {
        rootRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'end',
        })
      }
    }, 0)
  }

  return (
    <S.Root ref={rootRef}>
      <S.Placeholder isEmpty={!defaultValue && !value}>
        {placeholder}
      </S.Placeholder>
      <ReactSelect
        defaultValue={defaultValue}
        value={value}
        isMulti={isMultiSelect}
        options={options}
        hideSelectedOptions={false}
        styles={customSelectStyles}
        isClearable={false}
        isSearchable={false}
        onBlur={handleBlur}
        closeMenuOnSelect={!isMultiSelect}
        blurInputOnSelect={!isMultiSelect}
        onChange={handleChange}
        placeholder=""
        onMenuOpen={handleMenuOpen}
        components={{
          Option: CustomOption,
          MultiValueContainer,
        }}
      />
    </S.Root>
  )
}
