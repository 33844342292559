import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledKitInfoBlock = {
  InfoContainer: styled.div`
    background-color: ${Color.GREY_900};
    border-radius: 12px;
    display: flex;
    column-gap: 8px;
    padding: 16px;
  `,
  InfoImage: styled.div`
    aspect-ratio: 1 / 1;
    min-width: 24px;
    height: 24px;
  `,
  Info: styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  `,
  InfoTitle: styled.h4`
    color: ${Color.BLACK_100};
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
  `,
  InfoText: styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  `,
}
