export const STEPS_MAP = [
  {
    title: 'purchase.stepsV1.session.title',
    info: 'purchase.stepsV1.session.info',
  },
  {
    title: 'purchase.stepsV1.coach.title',
    info: 'purchase.stepsV1.coach.info',
  },
  {
    title: 'purchase.stepsV1.between.title',
    info: 'purchase.stepsV1.between.info',
  },
  {
    title: 'purchase.stepsV1.track.title',
    info: 'purchase.stepsV1.track.info',
  },
]
