import styled from 'styled-components'

import { Logo } from 'components/Logo'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledeUsersChoiceV4 = {
  Logo: styled(Logo)`
    left: 0;
    padding-top: 8px;
    position: absolute;
    top: 0;
    width: 100%;
  `,
  Banner: styled.div`
    aspect-ratio: 90 / 103;
    margin: 0 auto 16px;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    width: 100%;
  `,
  Title: styled.h2`
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    color: ${Color.GREY_100};
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    margin: 0 0 64px 0;

    strong {
      font-weight: 700;
      font-size: 36px;
      line-height: 32px;
    }
  `,
}
