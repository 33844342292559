import React, {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useCallback,
} from 'react'
import { useTranslation } from 'react-i18next'

import { IInputFieldState } from 'models/inputField.model'

import { StyledInput as S } from './EmailLogin.styles'

type TProps = {
  email: IInputFieldState
  setEmail: Dispatch<SetStateAction<IInputFieldState>>
  password: IInputFieldState
  setPassword: Dispatch<SetStateAction<IInputFieldState>>
}

export const EmailLogin: React.FC<TProps> = ({
  email,
  setEmail,
  password,
  setPassword,
}) => {
  const { t } = useTranslation()

  const handleChange = useCallback(
    (
      stateCallback: Dispatch<SetStateAction<IInputFieldState>>,
      newData: Partial<IInputFieldState>,
    ) => {
      stateCallback((prevState) => ({ ...prevState, ...newData }))
    },
    [],
  )

  const handleEmailChange = useCallback(
    (e: SyntheticEvent<HTMLInputElement>) => {
      handleChange(setEmail, {
        value: (e.target as HTMLInputElement).value.trim(),
      })
    },
    [setEmail, handleChange],
  )

  const handlePasswordChange = useCallback(
    (e: SyntheticEvent<HTMLInputElement>) => {
      handleChange(setPassword, {
        value: (e.target as HTMLInputElement).value,
      })
    },
    [setPassword, handleChange],
  )

  return (
    <>
      <S.Input
        value={email.value}
        maxLength={50}
        isValid={email.isValid}
        label={t('login.emailPlaceholder')}
        shortLabel={t('onboarding.email.shortPlaceholder')}
        onChange={handleEmailChange}
        onFocus={() => handleChange(setEmail, { isFocused: true })}
        onBlur={() => handleChange(setEmail, { isFocused: false })}
      />
      <S.Input
        value={password.value}
        isValid={password.isValid}
        label={t('login.passwordPlaceholder')}
        type="password"
        onChange={handlePasswordChange}
        onFocus={() => handleChange(setPassword, { isFocused: true })}
        onBlur={() => handleChange(setPassword, { isFocused: false })}
      />
    </>
  )
}
