import React from 'react'
import { useTranslation } from 'react-i18next'

import stars from 'assets/images/review-stars.png'

import { StyledReviewCard as S } from './ReviewCard.styles'

type TProps = {
  className?: string
  userName: string
  userAvatar: string
  review: string
}
export const ReviewCard: React.FC<TProps> = ({
  className,
  userName,
  userAvatar,
  review,
}) => {
  const { t } = useTranslation()

  return (
    <S.Container className={className}>
      <S.UserBlock>
        <S.UserAvatar>
          <img src={userAvatar} alt="avatar" />
        </S.UserAvatar>
        <div>
          <S.Rating>
            <img src={stars} alt="stars" />
          </S.Rating>
          <S.UserName>{userName}</S.UserName>
        </div>
      </S.UserBlock>
      <S.ReviewText>{t(review)}</S.ReviewText>
    </S.Container>
  )
}
