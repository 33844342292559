import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledPrimaryGoalV4 = {
  AnswerTitle: styled.h3`
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: ${Color.GREY_100};
    margin-bottom: 4px;
  `,
  AnswerDescription: styled.p`
    color: #00000080;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  `,
}
