import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { Container } from 'components/Container'
import { Logo } from 'components/Logo'
import { ProgressAnalyzer } from 'components/ProgressAnalyzer'

import { useCounter } from 'hooks/useCounter'
import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import dish1 from 'assets/images/dish-1.png'
import dish2 from 'assets/images/dish-2.png'
import dish3 from 'assets/images/dish-3.png'
import dish4 from 'assets/images/dish-4.png'
import arrowsSvg from 'assets/images/sprite/arrows-down.svg'

import { Language } from 'root-constants'

import { StyledCreatingPlan as S } from './CreatingPlan.styles'

export const CreatingPlan: React.FC<TPageProps> = ({ pageId }) => {
  const [isFirstChangeableContentShown, setIsFirstChangeableContentShown] =
    useState(true)
  const [isSecondChangeableContentShown, setIsSecondChangeableContentShown] =
    useState(false)
  const [isThirdChangeableContentShown, setIsThirdChangeableContentShown] =
    useState(false)
  const [isFourthChangeableContentShown, setIsFourthChangeableContentShown] =
    useState(false)

  const { t } = useTranslation()
  const { search } = useLocation()

  const { currentSubscriptionPageId } = useGetPageInfo()

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.creatingPlan.title', { lng: Language.EN }),
    nextPagePath: `${currentSubscriptionPageId}${search}`,
  })

  const { counter, linearCounterValue } = useCounter(true, {
    duration: 12500,
    callback: handleContinue,
  })

  useEffect(() => {
    if (linearCounterValue === 0) {
      setIsFirstChangeableContentShown(true)
    }

    if (linearCounterValue === 25) {
      setIsSecondChangeableContentShown(true)
    }

    if (linearCounterValue === 50) {
      setIsThirdChangeableContentShown(true)
    }

    if (linearCounterValue === 75) {
      setIsFourthChangeableContentShown(true)
    }
  }, [linearCounterValue])

  return (
    <S.Wrapper>
      <Container>
        <Logo marginBottom={38} />
        <S.Title>{t('onboarding.creatingPlan.title')}</S.Title>
        <S.Arrows svg={arrowsSvg} />

        <S.ChangeableContent data-is-shown={isFirstChangeableContentShown}>
          <S.Subtitle>{t('onboarding.creatingPlan.subtitleFirst')}</S.Subtitle>
          <img src={dish1} alt="dish" />
        </S.ChangeableContent>

        <S.ChangeableContent data-is-shown={isSecondChangeableContentShown}>
          <S.Subtitle>{t('onboarding.creatingPlan.subtitleSecond')}</S.Subtitle>
          <img src={dish2} alt="dish" />
        </S.ChangeableContent>

        <S.ChangeableContent data-is-shown={isThirdChangeableContentShown}>
          <S.Subtitle marginBottom={70}>
            {t('onboarding.creatingPlan.subtitleThird')}
          </S.Subtitle>
          <img src={dish3} alt="dish" />
        </S.ChangeableContent>

        <S.ChangeableContent data-is-shown={isFourthChangeableContentShown}>
          <S.Subtitle marginBottom={24}>
            {t('onboarding.creatingPlan.subtitleFourth')}
          </S.Subtitle>
          <S.Description>
            <Trans i18nKey="onboarding.creatingPlan.description" />
          </S.Description>
          <img src={dish4} alt="dish" />
        </S.ChangeableContent>

        <S.ProgressbarContainer>
          <ProgressAnalyzer value={counter}>
            {t('onboarding.creatingPlan.loaderText')}
          </ProgressAnalyzer>
        </S.ProgressbarContainer>
      </Container>
    </S.Wrapper>
  )
}
