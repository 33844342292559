import React, { FC, useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Answer, answerTheme } from 'storybook-ui'

import { KitPageContainer } from 'components/PageContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Color, Language } from 'root-constants'

import { StyledStatementQuestionComponent as S } from './KitStatementQuestion.styles'
import { STATEMENT_QUESTIONS, STATEMENT_QUESTION_ANSWERS } from './constants'

export const KitStatementQuestionV1: FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const [answer, setAnswer] = useState('')

  const question = useMemo(() => STATEMENT_QUESTIONS[pageId], [pageId])

  const handleContinue = useNextStep({
    pageId,
    question: t(question, { lng: Language.EN }),
    nextPagePath,
  })

  const handleNextClick = useCallback(
    () => handleContinue(answer),
    [answer, handleContinue],
  )

  return (
    <KitPageContainer
      hasContinueButton
      isContinueButtonDisabled={!answer}
      onContinueButtonClick={handleNextClick}
      bottomContent={
        <>
          <S.AnswersContainer>
            {STATEMENT_QUESTION_ANSWERS.map(({ value, label }) => (
              <Answer
                key={label}
                type="radio"
                theme={answerTheme.NUTRIMATE}
                name={pageId}
                value={value}
                borderRadius="16px"
                contentHorizontalAlign="center"
                activeColor={Color.GREEN_400}
                onChange={setAnswer}
              >
                {label}
              </Answer>
            ))}
          </S.AnswersContainer>
          <S.Scale>
            <S.ScaleItem>{t('commonComponents.lowestScaleValue')}</S.ScaleItem>
            <S.ScaleItem>{t('commonComponents.highestScaleValue')}</S.ScaleItem>
          </S.Scale>
        </>
      }
    >
      <S.PageTitle textAlign="left" marginBottom={24}>
        {t('commonComponents.statementTitleV2')}
      </S.PageTitle>
      <S.QuestionContainer>
        <S.Question>
          <Trans i18nKey={question} />
        </S.Question>
      </S.QuestionContainer>
    </KitPageContainer>
  )
}
