import styled, { css } from 'styled-components'

import { SvgImage } from 'components/SvgImage'

import { Color } from 'root-constants'

export const customSelectStyles = {
  menu: () => ({
    border: `2px solid ${Color.PRIMARY}`,
    borderRadius: '16px',
    padding: '12px 0',
    marginTop: '8px',
  }),
  control: (_, state) => ({
    // none of react-select's styles are passed to <Control />
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '16px',
    padding: !state.selectProps.menuIsOpen
      ? '30px 16px 14px 25px'
      : '29px 15px 13px 24px',
    minHeight: '60px',
    backgroundColor: !state.selectProps.menuIsOpen ? Color.LIGHT : Color.WHITE,
    border: !state.selectProps.menuIsOpen
      ? `none`
      : `2px solid ${Color.PRIMARY}`,
  }),
  valueContainer: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
    overflow: 'visible',
    position: 'relative',
    alignSelf: 'flex-end',
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '20px',
  }),
  multiValue: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
    backgroundColor: 'transparent',
    marginRight: 5,
    fontSize: 16,
  }),

  multiValueRemove: () => ({
    display: 'none',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    marginTop: '-14px',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: Color.BLACK,
    padding: 0,
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  input: (provided) => ({
    ...provided,
    display: 'none',
  }),
  singleValue: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
  }),
}

export const StyledSelect = {
  Root: styled.div`
    position: relative;
  `,
  Option: styled.div`
    display: flex;
    align-items: center;
    padding: 0 50px 0 18px;
    min-height: 48px;
    cursor: pointer;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: ${Color.GREY_100};
    position: relative;
    border-bottom: 1px solid #d1e1e9;

    &:last-child {
      border-bottom: 0;
    }
  `,
  SelectedIcon: styled(SvgImage)`
    overflow: hidden;
    position: absolute;
    right: 21px;
    top: calc(50% - 10px);
  `,
  CircleMarkIcon: styled.span`
    width: 20px;
    height: 20px;
    opacity: 0.5;
    border: 1px solid #555965;
    border-radius: 50%;
    position: absolute;
    top: calc(50% - 10px);
    right: 20px;
  `,
  MultiValue: styled.span`
    font-weight: 500;
    margin-right: 4px;

    &:last-child {
      margin-right: 0;
    }
  `,
  Placeholder: styled.span<{ isEmpty: boolean }>`
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: ${Color.GREY_100};
    position: absolute;
    top: 14px;
    left: 25px;
    pointer-events: none;
    z-index: 2;
    ${({ isEmpty }) =>
      isEmpty &&
      css`
        top: 24px;
        color: ${Color.GREY_600};
        font-size: 16px;
      `};
  `,
}
