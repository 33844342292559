import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledBMIInfoCard = {
  Card: styled.div<{ color: string; icon: string }>`
    border-radius: 12px;
    padding: 16px 16px 16px 48px;
    background-color: ${({ color }) => color};
    position: relative;

    &:before {
      position: absolute;
      left: 16px;
      content: url(${({ icon }) => icon});
      height: 24px;
      width: 24px;
    }
  `,
  Title: styled.h4`
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    color: ${Color.GREY_100};
    margin: 0 0 6px 0;
  `,
  Info: styled.p`
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: ${Color.GREY_100};
  `,
}
