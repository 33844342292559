import React from 'react'

import { TPageProps } from 'models/common.model'

import { PerfectSolution } from './PerfectSolution'
import { PERFECT_SOLUTION_MAP_V1 } from './constants'

export const PerfectSolutionV1: React.FC<TPageProps> = (props) => (
  <PerfectSolution {...props} perfectSolutionMap={PERFECT_SOLUTION_MAP_V1} />
)
