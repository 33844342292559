import { TValueOf } from 'models/common.model'

import { CurrentEnvironment } from 'root-constants'

const CURRENT_META_ENV = 'current-env'

export const getCurrentEnvironment = () => {
  const currentEnv = (
    document.querySelector(`meta[name='${CURRENT_META_ENV}'`) as HTMLMetaElement
  ).content as TValueOf<CurrentEnvironment>

  return {
    isProdEnvironment: currentEnv === CurrentEnvironment.PROD,
    isDevEnvironment: currentEnv === CurrentEnvironment.DEV,
    isStageEnvironment: currentEnv === CurrentEnvironment.STAGE,
  }
}
