import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { PERIOD_DATA_MAP } from 'pages/questions/Period/constants'
import { PeriodValue } from 'value-constants'

import { Container } from 'components/Container'
import { Option, OptionType } from 'components/Option'

import { setAnswersAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'

import { useDelayedContinue } from 'hooks/useDelayedContinue'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { CustomPageId } from 'page-constants'
import { Language } from 'root-constants'

import { StyledPeriod as S } from './Period.styles'

export const Period: React.FC<TPageProps> = ({
  nextPagePath,
  alternativePagePath,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useCallback(
    (answer): void => {
      dispatch(
        setAnswersAction({
          pageId: CustomPageId.PERIOD,
          answers: answer,
        }),
      )
      dispatch(sendUserAnswersAction())

      eventLogger.logQuestion({
        question: t('onboarding.period.title', { lng: Language.EN }),
        answers: answer,
      })

      const path =
        answer === PeriodValue.MENSTRUATION ? nextPagePath : alternativePagePath

      goTo(path)
    },
    [dispatch, t, nextPagePath, alternativePagePath],
  )

  const optionProps = useMemo(
    () => ({
      name: CustomPageId.PERIOD,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [handleChange, setIsAnswersDisabled, handleContinue],
  )

  return (
    <Container>
      <S.PageTitle>{t('onboarding.period.title')}</S.PageTitle>
      <S.Disclaimer>
        <S.DisclaimerTitle>
          {t('onboarding.period.disclaimer.title')}
        </S.DisclaimerTitle>
        <S.DisclaimerText>
          {t('onboarding.period.disclaimer.text')}
        </S.DisclaimerText>
      </S.Disclaimer>
      {PERIOD_DATA_MAP.map(({ value, title, subtitle }) => (
        <Option
          {...optionProps}
          key={value}
          value={value}
          disabled={isAnswersDisabled}
          marginBottom="12"
        >
          <S.AnswerButton>
            <>
              <h3>{t(title)}</h3>
              <p>{t(subtitle)}</p>
            </>
          </S.AnswerButton>
        </Option>
      ))}
    </Container>
  )
}
