import styled, { css } from 'styled-components'

import { Container } from 'components/Container'
import { StyledOption as O } from 'components/Option/Option.styles'

import { Color, Gender, MediaBreakpoint } from 'root-constants'

const commonGenderStyles = css`
  display: flex;
  align-items: center;
  transition: 0.2s;
  border-radius: 0 0 12px 12px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: ${Color.WHITE};
  position: absolute;
  bottom: 0;
  left: 0;
  height: 40px;
  width: 100%;
  padding: 10px 16px;
  z-index: 2;
`

export const StyledGenderV3 = {
  Title: styled.h2`
    margin-bottom: 8px;
    font-weight: 800;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    letter-spacing: 0.36px;
    text-transform: uppercase;
    color: ${Color.GREY_100};
    margin-top: 16px;
  `,
  Subtitle: styled.p`
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: ${Color.DISABLED};
  `,
  CallToAction: styled.h3`
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: ${Color.GREY_100};
    text-align: center;
    margin-bottom: 16px;
  `,
  Actions: styled.div`
    display: flex;
    justify-content: space-between;

    input:checked + div > div {
      background-color: var(--checked-bg-color);
    }

    & label {
      flex-basis: calc(50% - 8px);
    }
  `,
  Action: styled.div`
    display: flex;
    min-height: 260px;
    position: relative;
    padding-top: 8px;
    overflow: hidden;
  `,
  AnswerForMale: styled.div`
    --checked-bg-color: #6fd09a;
    flex-basis: 100%;
    background-color: #e8ffee;
    border: 1px solid #6fd09a;
    border-radius: 12px;
    transition: background-color 0.1s ease-out;
  `,
  AnswerForFemale: styled.div`
    --checked-bg-color: #e0698c;
    flex-basis: 100%;
    background-color: #f9e1e8;
    border: 1px solid #e0698c;
    border-radius: 12px;
    transition: background-color 0.1s ease-out;
  `,
  MaleGender: styled.span`
    ${commonGenderStyles};
    background: #6fd09a;
  `,
  FemaleGender: styled.span`
    ${commonGenderStyles};
    background: #e0698c;
  `,
  MaleImage: styled.img`
    position: absolute;
    top: -3px;
    left: 50%;
    transform: translateX(-50%);
    max-height: 265px;
    z-index: 1;
  `,
  FemaleImage: styled.img`
    position: absolute;
    top: -3px;
    left: 2px;
    max-height: 265px;
    z-index: 1;
  `,
}

export const StyledAlternativeGenderV3 = {
  Banner: styled.div`
    aspect-ratio: 360/272;
    position: relative;
    width: 100%;

    :before {
      background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgb(255, 255, 255)
      );
      bottom: -1px;
      content: '';
      height: 20%;
      left: 0;
      position: absolute;
      width: 100%;
    }
  `,
  Container: styled(Container)`
    width: 100%;
    margin: 0 auto;
    padding: 0;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: ${MediaBreakpoint.MEDIUM_PHONE}px;
    }
  `,
  Content: styled.div`
    padding: 0 16px 24px;
    position: relative;
    text-align: center;
  `,
  Title: styled.h2`
    color: ${Color.DARK_BLUE};
    font-size: 25px;
    font-weight: 800;
    line-height: 38px;
    margin-bottom: 8px;
  `,
  Subtitle: styled.p`
    color: ${Color.DISABLED};
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 16px;
  `,
  FeedbackContainer: styled.div`
    align-items: center;
    background-color: ${Color.GREY_LIGHT};
    border-radius: 20px;
    column-gap: 8px;
    display: inline-flex;
    margin-bottom: 16px;
    padding: 4px 8px;
  `,
  Stars: styled.div`
    height: 18px;

    img {
      height: inherit;
    }
  `,
  Feedback: styled.span`
    color: ${Color.GREY_100};
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;

    strong {
      font-weight: 700;
    }
  `,
  CallToAction: styled.h3`
    color: ${Color.DARK_BLUE};
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 24px;
    text-transform: uppercase;
  `,
  Actions: styled.div`
    column-gap: 16px;
    display: flex;
  `,
  Action: styled.div`
    border-radius: 10px;
    width: 100%;

    ${O.OptionContainer} {
      color: ${Color.WHITE};
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      margin: 0;
      padding: 14px;
    }

    &[data-gender=${Gender.MALE}] {
      background-color: ${Color.PRIMARY};
    }

    &[data-gender=${Gender.FEMALE}] {
      background-color: ${Color.PINK};
    }
  `,
}
