import styled from 'styled-components'

import { BMIInfoCard } from 'components/BMIInfoCard'
import { RangeTrack } from 'components/RangeTrack'

import { Color } from 'root-constants'

export const StyledPersonalSummaryV3 = {
  TitleRow: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 4px 0;
  `,
  Title: styled.h4`
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: ${Color.GREY_100};
    text-align: left;
  `,
  TitleLabel: styled.span`
    padding: 2px 6px;
    border-radius: 6px;
    background-color: ${Color.PRIMARY};
    font-weight: 700;
    font-size: 10px;
    line-height: 16px;
    text-transform: uppercase;
    color: ${Color.WHITE};
  `,
  Subtitle: styled.h4`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: ${Color.DISABLED};
    text-align: left;
    margin: 0 0 54px 0;
  `,
  RangeTrack: styled(RangeTrack)<{ hasBMIInfo: boolean }>`
    margin: ${({ hasBMIInfo }) => (hasBMIInfo ? `0 0 16px 0` : `0 0 32px 0`)};
  `,
  BMIInfoCard: styled(BMIInfoCard)`
    margin: 0 0 24px 0;
  `,
}
