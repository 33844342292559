import { PaymentMethod } from 'modules/purchase/constants'

import apple from 'assets/images/apple-pay-icon.png'
import google from 'assets/images/google-pay.png'
import maestro from 'assets/images/maestro-icon.png'
import mastercard from 'assets/images/mastercard-icon.png'
import paypal from 'assets/images/pay-pal-icon.png'
import visa from 'assets/images/visa-icon.png'

import { CreditCardPayment } from '../CreditCardPayment'
import { OneClickPayment } from '../OneClickPayment'

export const PAYMENT_METHODS_INITIAL = [
  {
    value: PaymentMethod.ONE_CLICK,
    title: 'kitCheckoutV1.oneClick',
    images: [paypal],
    form: OneClickPayment(),
  },
  {
    value: PaymentMethod.CREDIT_CARD,
    title: 'kitCheckoutV1.creditCard',
    images: [visa, mastercard, maestro],
    form: CreditCardPayment(),
  },
]

export const ICON_MAP = {
  [PaymentMethod.GOOGLE_PAY]: google,
  [PaymentMethod.APPLE_PAY]: apple,
}
