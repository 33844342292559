import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  LOST_WEIGHT_MAP_V2,
  REVIEWER_NAME,
} from 'pages/questions/StickingPlan/constants'

import { Button } from 'components/Button'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { selectUserMeasurementSystem } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import stars from 'assets/images/stars.png'
import female from 'assets/images/sticking-plan-v3-female.png'

import { Language, MeasurementSystem } from 'root-constants'

import { StyledStickingPlanV3 as S } from './StickingPlanV3.styles'

export const StickingPlanV3: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const currentMeasurementSystem = useSelector(selectUserMeasurementSystem)

  const { reviewTitle, reviewText } = useMemo(
    () => ({
      reviewTitle: t('onboarding.stickingPlanV3.reviewTitle', {
        lostWeight: `${LOST_WEIGHT_MAP_V2[currentMeasurementSystem]} ${
          currentMeasurementSystem === MeasurementSystem.METRIC
            ? t('commonComponents.kg')
            : t('commonComponents.lbs')
        }`,
      }),
      reviewText: t('onboarding.stickingPlanV3.reviewText', {
        lostWeight: `${LOST_WEIGHT_MAP_V2[currentMeasurementSystem]} ${
          currentMeasurementSystem === MeasurementSystem.METRIC
            ? t('commonComponents.kilograms')
            : t('commonComponents.pounds')
        }`,
      }),
    }),
    [currentMeasurementSystem, t],
  )

  const handleContinue = useNextStep({
    pageId,
    question: t(`onboarding.stickingPlanV3.title`, {
      lng: Language.EN,
    }),
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  return (
    <S.Container>
      <S.PageTitle>{t('onboarding.stickingPlanV3.title')}</S.PageTitle>
      <S.ReviewBlock>
        <S.ImageContainer>
          <img src={female} alt="before-after" />
        </S.ImageContainer>
        <S.Review>
          <S.ReviewTitle>{reviewTitle}</S.ReviewTitle>
          <S.ReviewText>{reviewText}</S.ReviewText>
          <S.Reviewer>
            <S.ReviewerName>{REVIEWER_NAME}</S.ReviewerName>
            <S.Stars>
              <img src={stars} alt="stars" />
            </S.Stars>
          </S.Reviewer>
        </S.Review>
      </S.ReviewBlock>
      <StickyButtonContainer>
        <Button type="button" onClick={handleNextClick}>
          {t('actions.continue')}
        </Button>
      </StickyButtonContainer>
    </S.Container>
  )
}
