export const QUESTION = 'How determined are you to reach your goal weight?'

export const enum READINESS_LEVEL_VALUES {
  NOT_START = 'not_start',
  OPTIMISTIC = 'optimistic',
  LITTLE_WARY = 'little_wary',
  BOTHER = 'bother',
}

export const ANSWER_OPTIONS = [
  {
    value: READINESS_LEVEL_VALUES.NOT_START,
    title: 'onboarding.determined.options.notStart',
  },
  {
    value: READINESS_LEVEL_VALUES.OPTIMISTIC,
    title: 'onboarding.determined.options.optimistic',
  },
  {
    value: READINESS_LEVEL_VALUES.LITTLE_WARY,
    title: 'onboarding.determined.options.littleWary',
  },
  {
    value: READINESS_LEVEL_VALUES.BOTHER,
    title: 'onboarding.determined.options.bother',
  },
]
