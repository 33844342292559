import { BadHabitsValue } from 'value-constants'

export const ANSWER_OPTIONS_MAP = [
  {
    value: BadHabitsValue.ENOUGH_REST,
    title: 'onboarding.badHabits.options.enoughRest',
  },
  {
    value: BadHabitsValue.CHOCOLATE_AND_SWEETS,
    title: 'onboarding.badHabits.options.chocolateAndSweets',
  },
  {
    value: BadHabitsValue.SODAS,
    title: 'onboarding.badHabits.options.sodas',
  },
  {
    value: BadHabitsValue.SALTY_FOODS,
    title: 'onboarding.badHabits.options.saltyFoods',
  },
  {
    value: BadHabitsValue.SNACK,
    title: 'onboarding.badHabits.options.snack',
  },
  {
    value: BadHabitsValue.JUNK,
    title: 'onboarding.badHabits.options.junk',
  },
  {
    value: BadHabitsValue.WHENEVER,
    title: 'onboarding.badHabits.options.whenever',
  },
  {
    value: BadHabitsValue.OVEREAT,
    title: 'onboarding.badHabits.options.overeat',
  },
  {
    value: BadHabitsValue.SAY_NO_TO_DRINK,
    title: 'onboarding.badHabits.options.sayNoToDrink',
  },
]
