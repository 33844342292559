import styled from 'styled-components'

import { Button } from 'components/Button'
import { PageTitle } from 'components/PageTitle'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { Color } from 'root-constants'

export const StyledStartJourney = {
  Title: styled(PageTitle)`
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 12px;

    strong {
      color: ${Color.PRIMARY};
    }
  `,
  Subtitle: styled.h2`
    color: ${Color.GREY_600};
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.1px;
    line-height: 20px;
    margin-bottom: 44px;
    text-align: center;
  `,
  ReviewContainer: styled.div`
    align-items: center;
    display: flex;
    column-gap: 12px;
    margin-bottom: 44px;
  `,
  Photo: styled.div`
    aspect-ratio: 1 / 1;
    min-width: 146px;
  `,
  Review: styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  `,
  AboutMe: styled.h4`
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
  `,
  Content: styled.h5`
    color: ${Color.DISABLED};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
  `,
  CallToAction: styled.h3`
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 44px;
    text-align: center;
  `,
  StickyButtonContainer: styled(StickyButtonContainer)`
    max-width: initial;
    padding: 0 0 22px;
  `,
  Button: styled(Button)`
    max-width: initial;
  `,
}
