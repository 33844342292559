import styled from 'styled-components'

import { Footer } from 'components/Footer'
import { KitInputWithValidationIcons } from 'components/InputWithValidationIcons'

import exclaimationIcon from 'assets/images/exclaimation-circle-2.png'

import { Color } from 'root-constants'

export const StyledEmailV3 = {
  Form: styled.form`
    margin: 0 auto 24px;
  `,
  Input: styled(KitInputWithValidationIcons)`
    margin-bottom: 24px;
  `,
  ErrorMessage: styled.p`
    background-image: url(${exclaimationIcon});
    background-position-x: 16px;
    background-repeat: no-repeat;
    background-size: 16px;
    color: ${Color.RED_300};
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-top: 4px;
    padding-left: 36px;
    width: 100%;
  `,
  Disclaimer: styled.div`
    align-items: center;
    color: ${Color.GREY_101};
    column-gap: 8px;
    display: flex;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 40px;
    position: relative;
  `,
  LockIcon: styled.div`
    aspect-ratio: 1 / 1;
    display: flex;
    height: 24px;
    min-width: 24px;
  `,
  Footer: styled(Footer)`
    font-size: 11px;
    line-height: 14px;
  `,
}
