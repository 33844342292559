import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { StyledWeightGainResult as S } from 'pages/questions/WeightGainResult/WeightGainResult.styles'

import { Button } from 'components/Button'
import { PageTitle } from 'components/PageTitle'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import men from 'assets/images/weight-gain-men.png'
import women from 'assets/images/weight-gain-women.png'

import { Gender } from 'root-constants'

import { QUESTION } from './constants'

export const WeightGainResult: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const gender = useSelector(selectCurrentUserGender)

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  const bannerSrc = useMemo(
    () => (gender === Gender.MALE ? men : women),
    [gender],
  )

  return (
    <>
      <S.Logo marginBottom={28} />
      <S.Banner>
        <img src={bannerSrc} alt="banner" />
      </S.Banner>
      <S.Container>
        <PageTitle marginBottom={16}>
          {t('onboarding.weightGainResult.title')}
        </PageTitle>
        <S.Subtitle>{t('onboarding.weightGainResult.subTitle')}</S.Subtitle>
        <S.ButtonContainer>
          <Button type="button" onClick={handleNextClick}>
            {t('actions.continue')}
          </Button>
        </S.ButtonContainer>
      </S.Container>
    </>
  )
}
