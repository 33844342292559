const enum PeriodAffectAppetiteValue {
  INCREASED = 'My appetite has increased',
  SUGAR_CRAVINGS = 'I started getting sugar cravings',
  DECREASED = 'My appetite has decreased',
  NO_CHANGES = 'No significant changes',
}

export const OPTIONS = [
  {
    value: PeriodAffectAppetiteValue.INCREASED,
    title: 'onboarding.periodAffectAppetite.options.increased',
  },
  {
    value: PeriodAffectAppetiteValue.SUGAR_CRAVINGS,
    title: 'onboarding.periodAffectAppetite.options.sugarСravings',
  },
  {
    value: PeriodAffectAppetiteValue.DECREASED,
    title: 'onboarding.periodAffectAppetite.options.decreased',
  },
  {
    value: PeriodAffectAppetiteValue.NO_CHANGES,
    title: 'onboarding.periodAffectAppetite.options.noChanges',
  },
]
