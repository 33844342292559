import styled from 'styled-components'

import { SvgImage } from 'components/SvgImage'

import { Color } from 'root-constants'

type TProps = {
  answer?: string
  top?: number
  left?: number
}

export const StyledGraphV2 = {
  Wrapper: styled.div`
    margin-bottom: 32px;
    margin-top: 70px;
  `,
  GraphContent: styled.div`
    position: relative;
  `,
  DateLabels: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 8px;
  `,
  LabelResult: styled.p`
    font-weight: 800;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: ${Color.PRIMARY};
  `,
  Label: styled.p`
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    min-width: 53px;
    text-align: center;
    color: ${Color.GREY_100};
  `,
  MiddleLabel: styled.div<TProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4px 6px;
    top: ${({ top }) => top && `calc(${top}px + 12px)`};
    left: ${({ left }) => left && `calc(${left}px - 18px)`};
    position: absolute;
    width: ${({ answer }) => (answer ? 'auto' : '45px')};
    background: ${Color.BLUE};
    border-radius: 6px;
  `,
  Dot: styled(SvgImage)<TProps>`
    position: absolute;
    top: ${({ top }) => `${top}px`};
    left: ${({ left }) => `${left}px`};
  `,
  LabelTitle: styled.span<TProps>`
    font-weight: 700;
    font-size: 10px;
    line-height: 16px;
    min-width: ${({ answer }) => (answer ? '44px' : 0)};
    text-align: center;
    padding-left: ${({ answer }) => (answer ? '6px' : 0)};
    color: ${Color.WHITE};
  `,
  LabelValue: styled.span`
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    min-width: 31px;
    text-align: center;
    color: ${Color.WHITE};
  `,
  Graph: styled.img`
    min-height: 138px;
  `,
  CurrentWeight: styled.div`
    position: absolute;
    gap: 5px;
    left: 0;
    top: -40px;
    min-width: 53px;
    height: 30px;
    padding: 4px 8px;
    background: ${Color.LIGHT_BLUE};
    border-radius: 6px;
    flex-direction: row;
    display: flex;
    align-items: center;

    span {
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
    }
  `,
  GoalWeight: styled.div`
    position: absolute;
    gap: 5px;
    right: 0;
    bottom: 80px;
    min-width: 53px;
    height: 30px;
    padding: 4px 8px;
    background: ${Color.PRIMARY};
    border-radius: 6px;
    flex-direction: row;
    display: flex;
    align-items: center;

    span {
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      color: ${Color.WHITE};
    }
  `,
}
