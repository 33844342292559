import { Color } from 'root-constants'

export const BMI_LABELS_MAP = [
  {
    lowerThreshold: 10,
    upperThreshold: 18.5,
    color: Color.ORANGE,
    backgroundColor: Color.ORANGE_LIGHT,
    label: 'onboarding.currentWeight.disclaimer.label.underweight',
    title: 'onboarding.currentWeight.disclaimer.underweight',
    width: 80,
  },
  {
    lowerThreshold: 18.5,
    upperThreshold: 24.99,
    color: Color.PRIMARY,
    backgroundColor: Color.GREEN_100,
    label: 'onboarding.currentWeight.disclaimer.label.normal',
    title: 'onboarding.currentWeight.disclaimer.normal',
    width: 53,
  },
  {
    lowerThreshold: 25,
    upperThreshold: 29.99,
    color: Color.PINK,
    backgroundColor: 'rgba(224, 105, 140, 0.1)',
    label: 'onboarding.currentWeight.disclaimer.label.overweight',
    title: 'onboarding.currentWeight.disclaimer.overweight',
    width: 72,
  },
  {
    lowerThreshold: 30,
    upperThreshold: 34.99,
    color: Color.RED_100,
    backgroundColor: 'rgba(255, 1, 1, 0.1)',
    label: 'onboarding.currentWeight.disclaimer.label.obese',
    title: 'onboarding.currentWeight.disclaimer.obese',
    width: 41,
  },
  {
    lowerThreshold: 35,
    upperThreshold: 45,
    color: Color.RED_100,
    backgroundColor: 'rgba(255, 1, 1, 0.1)',
    label: 'onboarding.currentWeight.disclaimer.label.extrObese',
    title: 'onboarding.currentWeight.disclaimer.obese',
    width: 94,
  },
]
