import styled from 'styled-components'

import { Button } from 'components/Button'

import { Color, MAX_COLUMN_WIDTH, MediaBreakpoint } from 'root-constants'

export const StyledUpsellDiscountModal = {
  Wrapper: styled.div`
    background-color: rgba(107, 114, 128, 0.75);
    height: 100vh;
    left: 0;
    padding-top: 24px;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1;
  `,
  Container: styled.div`
    background-color: ${Color.WHITE};
    border-radius: 24px 24px 0px 0px;
    height: calc(100% - 89px);
    margin: 0 auto;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 24px 16px;
    width: 100%;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: ${MAX_COLUMN_WIDTH}px;
    }
  `,
  Title: styled.h2`
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 12px;
    text-align: center;
  `,
  Subtitle: styled.h3`
    color: ${Color.DISABLED};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 20px;
    text-align: center;

    strong {
      color: ${Color.PRIMARY};
      font-weight: 700;
    }
  `,
  Banner: styled.div`
    aspect-ratio: 328 / 269;
    margin-bottom: 16px;
    width: 100%;
  `,
  Content: styled.p`
    background-color: ${Color.GREY_120};
    border-radius: 16px;
    color: ${Color.GREY_600};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: 16px;
  `,
  ButtonContainer: styled.div`
    background-color: ${Color.WHITE};
    border-top: 1px solid ${Color.GREY_800};
    margin: 0 auto;
    padding: 16px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: ${MAX_COLUMN_WIDTH}px;
    }
  `,
  Button: styled(Button)`
    margin: 0;
    max-width: 100%;
    text-transform: uppercase;
  `,
}
