import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Container } from 'components/Container'
import { ProgressRounded } from 'components/ProgressRounded'

import { useCounter } from 'hooks/useCounter'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledAnalyzingAnswers as S } from './AnalyzingAnswers.styles'
import { QUESTION } from './constants'

export const AnalyzingAnswers: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(
    () => handleContinue(''),
    [handleContinue],
  )

  const { counter, linearCounterValue } = useCounter(true, {
    duration: 7500,
    callback: handleNextClick,
  })

  return (
    <Container>
      <S.ProgressBarContainer>
        <ProgressRounded value={counter} />
        <S.Content>
          <S.Text ratio={0} isReady={linearCounterValue > 20}>
            {t('onboarding.analyzingAnswers.analyzingProfile')}
          </S.Text>
          <S.Text ratio={1} isReady={linearCounterValue > 40}>
            {t('onboarding.analyzingAnswers.estimatingAge')}
          </S.Text>
          <S.Text ratio={2} isReady={linearCounterValue > 60}>
            {t('onboarding.analyzingAnswers.selectingRecipes')}
          </S.Text>
          <S.Text ratio={3} isReady={linearCounterValue > 80}>
            {t('onboarding.analyzingAnswers.planIsReady')}
          </S.Text>
        </S.Content>
      </S.ProgressBarContainer>
    </Container>
  )
}
