import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { convertTextFromSnakeCase } from 'helpers/convertTextFromSnakeCase'

import { StyledCustomerReviewsV3 as S } from './CustomerReviewsV3.styles'

type TReview = {
  authorName: string
  authorImg: string
  reviewText: string
}

type TProps = {
  className?: string
  title: string
  reviews: TReview[]
}
export const CustomerReviewsV3: React.FC<TProps> = ({
  className,
  title,
  reviews,
}) => {
  const { t } = useTranslation()

  return (
    <div className={className}>
      <S.Title>{t(title)}</S.Title>
      {reviews.map((review, index) => (
        <S.ReviewCard key={review.authorName}>
          {!index && (
            <S.CardTitle>
              {convertTextFromSnakeCase(
                t('purchase.customerReviewsV5.customerReviews'),
              )}
            </S.CardTitle>
          )}
          <S.CardContent>
            <S.Author>
              <S.AuthorImg>
                <img src={review.authorImg} alt={t(review.authorName)} />
              </S.AuthorImg>
              <S.AuthorName>
                <S.Name>{t(review.authorName)}</S.Name>
                <S.Recommendation>
                  <Trans i18nKey="purchase.customerReviewsV5.recommends" />
                </S.Recommendation>
              </S.AuthorName>
            </S.Author>
            <S.Text>{t(review.reviewText)}</S.Text>
          </S.CardContent>
        </S.ReviewCard>
      ))}
    </div>
  )
}
