import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useVatInfo } from 'hooks/useHasVatInfo'
import { usePaymentStore } from 'hooks/usePaymentStore'

import {
  CURRENCY_SYMBOLS,
  PurchaseProduct,
  WEEKS_BY_PRODUCT_NAME_MAP,
} from 'modules/purchase/constants'

import { StyledCheckoutSummaryV3 as S } from './CheckoutSummaryV3.styles'

type TProps = {
  className?: string
}

export const TrialCheckoutSummaryV3: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()

  const {
    currency,
    fullPrice,
    trialCurrentPrice,
    trialPriceBeforeDiscount,
    product,
    discountAmount,
  } = usePaymentStore()

  const hasVatInfo = useVatInfo()

  return (
    <div className={className}>
      <div>
        <S.DescriptionContainer>
          <S.PlanLabel>
            {t('purchase.checkoutV4.planLabel', {
              periodQuantity: WEEKS_BY_PRODUCT_NAME_MAP[product],
            })}
          </S.PlanLabel>
          <S.PlanPrice>
            {t('purchase.checkoutV4.fullPrice', {
              currency: CURRENCY_SYMBOLS[currency],
              price:
                product === PurchaseProduct.SEVEN_DAY
                  ? trialPriceBeforeDiscount.toFixed(2)
                  : fullPrice.toFixed(2),
            })}
          </S.PlanPrice>
        </S.DescriptionContainer>
        <S.DescriptionContainer>
          <S.PlanLabel>{t('purchase.checkoutV3.discountLabel')}</S.PlanLabel>
          <S.PlanPrice>
            -{CURRENCY_SYMBOLS[currency]}
            {discountAmount}
          </S.PlanPrice>
        </S.DescriptionContainer>
      </div>

      <S.TotalContainer>
        <S.TotalLabel>
          {t('purchase.checkoutV4.totalLabel', {
            includingVat: hasVatInfo ? t('purchase.includingVat') : '',
          })}
        </S.TotalLabel>
        <S.TotalPrice>
          <Trans
            i18nKey="purchase.checkoutV4.totalPrice"
            values={{
              currency: CURRENCY_SYMBOLS[currency],
              price: trialCurrentPrice.toFixed(2),
              periodQuantity: WEEKS_BY_PRODUCT_NAME_MAP[product],
            }}
            components={{ span: <span /> }}
          />
        </S.TotalPrice>
      </S.TotalContainer>
    </div>
  )
}
