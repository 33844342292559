import styled from 'styled-components'

import { Input } from 'components/Input'

import checkIcon from 'assets/images/check-white.svg'

import { Color } from 'root-constants'

type TMarkIcon = {
  isSelected: boolean
}

export const StyledFinalReview = {
  Root: styled.div`
    max-width: 360px;
    margin: 0 auto;
  `,
  Title: styled.h2`
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 15px;
  `,
  Subtitle: styled.p`
    max-width: 375px;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: ${Color.DISABLED};
    text-align: center;
    margin: 0 auto 24px;
  `,
  FieldContainer: styled.div`
    margin-bottom: 16px;
  `,
  SectionTitle: styled.h3`
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: ${Color.GREY_100};
    margin-bottom: 8px;
  `,
  Gender: styled.div`
    margin-top: 24px;
    margin-bottom: 25px;
  `,
  Label: styled.label`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${Color.GREY_100};
    padding-left: 28px;
    position: relative;
    margin-right: 18px;

    &:last-child {
      margin-right: 0;
    }
  `,
  MarkIcon: styled.span<TMarkIcon>`
    display: flex;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    opacity: 0.5;
    border: 1px solid #555965;
    position: absolute;
    top: calc(50% - 10px);
    left: 0;

    ${({ isSelected }) =>
      isSelected &&
      `
          background-image: url(${checkIcon});
          background-position: center center;
          background-repeat: no-repeat;
          background-color: ${Color.PRIMARY};
          border: none;
        `};
  `,
  Radio: styled.input`
    width: 1px;
    height: 1px;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    z-index: -1;

    &:disabled + span {
      border-color: #e3e4e8;
    }

    &:disabled ~ span {
      color: ${Color.DISABLED};
    }

    &:checked + span {
      opacity: 1;
      border: none;
    }
  `,
  List: styled.ul``,
  Item: styled.li`
    margin-bottom: 8px;
  `,
  WeightItem: styled.li`
    margin-bottom: 0;
  `,
  ActionContainer: styled.div`
    text-align: center;
    position: sticky;
    z-index: 3;
    bottom: 0;
    padding: 40px 0;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.0001) 0.25%,
      #ffffff 26.14%
    );
  `,
  Input: styled(Input)`
    padding: 30px 25px 14px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    border-radius: 16px;
  `,
  GoalWeightValue: styled.div`
    position: relative;
    padding: 30px 25px 14px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    border-radius: 16px;
    color: ${Color.GREY_100};
    border: none;
    background-color: ${Color.LIGHT};
    color: ${Color.GREY_100};
    outline: none;
    transition: border 0.2s ease-out;
  `,
  GoalWeightPlaceholder: styled.span`
    position: absolute;
    top: 10px;
    color: ${Color.GREY_100};
    font-size: 13px;
    font-weight: 400;
    line-height: 1;
  `,
}
