import styled, { css } from 'styled-components'

import { Container } from 'components/Container'
import { PageTitle } from 'components/PageTitle'

import { Color } from 'root-constants'

export const commonTitleStyle = css`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: ${Color.GREY_100};
`

export const StyledStickingPlanV3 = {
  Container: styled(Container)`
    padding: 16px;
  `,
  PageTitle: styled(PageTitle)`
    margin: 0 0 24px 0;
  `,
  ReviewBlock: styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 0 40px 0;
    background-color: #f2f5fc;
    border-radius: 16px;
    overflow: auto;
  `,
  ImageContainer: styled.div`
    aspect-ratio: 328/198;
    height: 198px;
    margin: 0 0 20px 0;
  `,
  Review: styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 20px 36px 20px;

    > :not(:last-child) {
      margin: 0 0 10px 0;
    }
  `,
  ReviewTitle: styled.h3`
    ${commonTitleStyle};
    text-align: center;
  `,

  ReviewText: styled.p`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: ${Color.GREY_100};
  `,
  Reviewer: styled.div`
    display: flex;
    align-items: center;
  `,
  ReviewerName: styled.h4`
    ${commonTitleStyle};
    margin: 0 8px 0 0;
  `,
  Stars: styled.div`
    aspect-ratio: 84 / 16;
    height: 16px;
  `,
}
