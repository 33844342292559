import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { StyledAppReachedV1 as S } from './AppReached.styles'

type TProps = {
  className?: string
  children?: React.ReactNode
}
export const AppReachedV1: React.FC<TProps> = ({ className, children }) => {
  const { t } = useTranslation()

  return (
    <S.Container className={className}>
      <S.TextContainer>
        <S.Title>{t('purchase.appReached.titleV1')}</S.Title>
        <S.Subtitle>
          <Trans i18nKey="purchase.appReached.subtitleV1" />
        </S.Subtitle>
      </S.TextContainer>
      {children}
    </S.Container>
  )
}
