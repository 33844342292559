import styled, { css } from 'styled-components'

import { Button } from 'components/Button'

import {
  AppReachedV2,
  DisclaimerV2,
  FeaturedBlockV3,
  IntroMediaV5,
  SecurityInfo,
  SubscriptionsBlock,
  UserInfoBlock,
  WeightLossJourney,
} from 'modules/purchase/components'
import { DiscountTimerV1 } from 'modules/purchase/components/DiscountTimer/DiscountTimerV1'
import { MoneyBackGuaranteeV3 } from 'modules/purchase/components/MoneyBackGuaranteeV3'
import { WhatYouGetV1 } from 'modules/purchase/components/WhatYouGet'

import { Color, MediaBreakpoint } from 'root-constants'

export const commonWrapperStyle = css`
  width: 100%;

  @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
    width: ${MediaBreakpoint.MEDIUM_PHONE}px;
  }
`

export const StyledPurchaseVariant6 = {
  DiscountTimer: styled(DiscountTimerV1)`
    margin: 0 auto 16px;
  `,
  Wrapper: styled.div`
    ${commonWrapperStyle};
    margin: 0 auto;
    padding: 0 16px 0;
  `,
  IntroMediaV5: styled(IntroMediaV5)`
    margin: 0 0 32px 0;
  `,
  Title: styled.h2`
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    text-transform: uppercase;
    color: ${Color.GREY_100};
    margin: 0 0 24px 0;
  `,
  UserInfoBlock: styled(UserInfoBlock)`
    margin: 0 0 32px 0;
  `,
  SubscriptionsBlock: styled(SubscriptionsBlock)`
    margin: 0 0 16px 0;
  `,
  MoneyBackText: styled.p`
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: ${Color.GREY_100};
    text-decoration: underline;
    text-align: center;
    margin: 0 0 16px 0;
  `,

  Disclaimer: styled(DisclaimerV2)`
    height: 86px;
    margin: 0 0 20px 0;
  `,
  WeightLossJourney: styled(WeightLossJourney)`
    ${commonWrapperStyle};
    margin: 0 auto 20px;
  `,
  AppReached: styled(AppReachedV2)`
    margin: 0 0 32px 0;
  `,
  WhatYouGetV1: styled(WhatYouGetV1)`
    margin: 0 0 32px 0;
  `,
  SuccessStoriesTitle: styled.h2`
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: ${Color.GREY_100};
    margin: 0 0 24px 0;
  `,
  FeaturedBlock: styled(FeaturedBlockV3)`
    margin: 0 0 32px 0;
  `,
  SubscriptionBlockTitle: styled.h2`
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    text-transform: uppercase;
    color: ${Color.GREY_100};
    margin: 0 0 24px 0;
  `,
  MoneyBackGuaranteeV3: styled(MoneyBackGuaranteeV3)`
    margin: 0 0 32px 0;
  `,
  SecurityInfo: styled(SecurityInfo)`
    background-color: ${Color.WHITE};
    border-radius: 14px;
    box-shadow: 0 4px 12px -2px rgba(59, 59, 62, 0.16);
    margin-bottom: 32px;
    padding: 50px 20px 20px;

    h2 {
      padding-bottom: 10px;
    }

    p {
      font-weight: normal;
      font-size: 14px;
      padding-bottom: 20px;
    }

    img {
      background: ${Color.WHITE};
      border-radius: 14px;
      box-shadow: 0 4px 12px -2px rgba(59, 59, 62, 0.16);
      left: 27%;
      max-width: 156px;
      padding: 3px 26px;
      position: absolute;
      top: -30px;
    }
  `,
  Button: styled(Button)`
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    margin: 0 auto 16px auto;
  `,
}
