import React, { useLayoutEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { AnswerButtonV2 } from 'components/AnswerButtonV2'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import {
  selectCurrentVariantCohortToUse,
  selectPastTries,
} from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { replaceHistory } from 'browser-history'
import { Cohort } from 'root-constants'

import { StyledDietType as S } from './DietType.styles'
import {
  ANSWER_OPTIONS_MAP,
  PAST_TRIES_WITHOUT_DIET_TYPE,
  QUESTION,
} from './constants'

export const DietType: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const pastTries = useSelector(selectPastTries)
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, setIsAnswersDisabled, handleContinue],
  )

  const shouldBeRedirect = useMemo(
    () =>
      !pastTries?.some((el) => PAST_TRIES_WITHOUT_DIET_TYPE.includes(el)) &&
      cohortToUse !== Cohort.MF_MEN_FLOW,
    [cohortToUse, pastTries],
  )

  useLayoutEffect(() => {
    if (shouldBeRedirect) {
      replaceHistory(nextPagePath)
    }
  }, [nextPagePath, shouldBeRedirect])

  return (
    <S.Container>
      <PageTitle marginBottom={24}>{t('onboarding.dietType.title')}</PageTitle>
      {ANSWER_OPTIONS_MAP.map(({ value, title, description }) => (
        <Option
          key={value}
          {...optionProps}
          marginBottom="12"
          disabled={isAnswersDisabled}
          value={value}
        >
          <AnswerButtonV2>
            <>
              <h3>{t(title)}</h3>
              {description && <p>{t(description)}</p>}
            </>
          </AnswerButtonV2>
        </Option>
      ))}
    </S.Container>
  )
}
