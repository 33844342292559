import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  ACTIVITY_DURING_WORK_MAP,
  BODY_PART_NAME_MAP,
  PRIMARY_GOAL_MAP,
} from 'map-constants'

import {
  selectActivityDuringWork,
  selectCurrentUserGender,
  selectCurrentUserPrimaryGoal,
  selectCurrentVariantCohortToUse,
  selectTargetAreas,
} from 'root-redux/selects/common'

import { getCapitalizedString } from 'helpers/stringHelper'

import activityLevelBullet from 'assets/images/bullet-activity-level.svg'
import goalBullet from 'assets/images/bullet-goal.svg'
import targetAreasBullet from 'assets/images/bullet-target-area.svg'
import man from 'assets/images/presale-man.png'
import seniorWomen from 'assets/images/presale-senior-women.png'
import women from 'assets/images/presale-women.png'

import { Cohort, Gender } from 'root-constants'

import { StyledUserInfoBlockV1 as S } from './UserInfoBlock.styles'

type TProps = {
  className?: string
}

export const UserInfoBlockV1: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()

  const gender = useSelector(selectCurrentUserGender)
  const targetAreas = useSelector(selectTargetAreas)
  const primaryGoal = useSelector(selectCurrentUserPrimaryGoal)
  const activity = useSelector(selectActivityDuringWork)
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const activityLevel = useMemo(
    () =>
      activity && getCapitalizedString(t(ACTIVITY_DURING_WORK_MAP[activity])),
    [activity, t],
  )

  const areas = useMemo(
    () =>
      targetAreas
        .map((area) => getCapitalizedString(t(BODY_PART_NAME_MAP[area])))
        .join(', '),
    [targetAreas, t],
  )

  const goal = useMemo(
    () => primaryGoal && getCapitalizedString(t(PRIMARY_GOAL_MAP[primaryGoal])),
    [primaryGoal, t],
  )

  const imgSrc = useMemo(() => {
    if (gender === Gender.MALE) {
      return man
    }
    if (cohortToUse === Cohort.MF_WOMEN_45) {
      return seniorWomen
    }

    return women
  }, [gender, cohortToUse])

  return (
    <S.Container className={className}>
      <S.InfoList>
        <S.InfoItem bulletSrc={goalBullet}>
          <S.Title>{t('presale.userInfo.goal')}</S.Title>
          <S.Info>{goal}</S.Info>
        </S.InfoItem>
        <S.InfoItem bulletSrc={targetAreasBullet}>
          <S.Title>{t('presale.userInfo.targetArea')}</S.Title>
          <S.Info>{areas}</S.Info>
        </S.InfoItem>
        <S.InfoItem bulletSrc={activityLevelBullet}>
          <S.Title>{t('presale.userInfo.activityLevel')}</S.Title>
          <S.Info>{activityLevel}</S.Info>
        </S.InfoItem>
      </S.InfoList>
      <S.ImageContainer>
        <img src={imgSrc} alt="user-info" />
      </S.ImageContainer>
    </S.Container>
  )
}
