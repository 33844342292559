import styled from 'styled-components'

import { Container } from 'components/Container'
import { Option } from 'components/Option'

import arrow from 'assets/images/take-quiz-arrow.svg'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledAgeRange = {
  Container: styled(Container)`
    overflow-x: hidden;
    max-width: 100vw;
    max-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 16px 24px 16px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
    }
  `,
  Title: styled.h2`
    width: 100%;
    font-weight: 700;
    font-size: 26px;
    line-height: 32px;
    text-align: center;
    color: ${Color.GREY_100};
    margin: 24px 0 16px 0;
  `,
  CallToAction: styled.h3`
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: ${Color.DISABLED};
    position: relative;
    margin: 0 0 30px 0;

    &::after {
      display: inline-block;
      content: url(${arrow});
      height: 100%;
      width: 16px;
      vertical-align: middle;
      margin: 0 0 0 4px;
    }

    strong {
      color: ${Color.GREY_100};
      font-weight: 700;
    }
  `,
  OptionsContainer: styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 180px);
    grid-column-gap: 16px;
    margin: 0 0 40px 0;

    input:checked + div > div {
      background-color: var(--checked-bg-color);
    }
  `,
  Option: styled(Option)`
    margin: 0;
    align-self: center;

    :nth-of-type(odd) {
      justify-self: right;
    }

    :nth-of-type(even) {
      justify-self: left;
    }
  `,
  Action: styled.div`
    display: flex;
    aspect-ratio: 148/152;
    width: 148px;
    height: 152px;
    position: relative;
  `,
  RangeImage: styled.img`
    height: auto;
    width: 100%;
    position: absolute;
    z-index: 1;
    top: -10px;
  `,
  Answer: styled.div`
    --checked-bg-color: ${Color.PRIMARY};
    background-color: #e8f7ef;
    border-radius: 12px;
    position: relative;
    flex-basis: 100%;
    transition: background-color 0.1s ease-out;
  `,
  Range: styled.span`
    --checked-bg-color: ${Color.PRIMARY};
    background-color: ${Color.PRIMARY};
    position: absolute;
    bottom: 0;
    z-index: 2;

    width: 100%;
    padding: 8px;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 12px 12px;

    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: ${Color.WHITE};
  `,
}
