import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledEasyToFollowPlanV1 = {
  ImageContainer: styled.div`
    width: 248px;
    aspect-ratio: 248/248;
    margin: 0 auto 24px;
  `,
  Text: styled.p`
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: ${Color.GREY_101};
  `,
}
