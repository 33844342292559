import React, { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { FlagContainer } from 'components/FlagContainer'

import {
  selectCurrentVariantCohortToUse,
  selectDynamicDiscount,
  selectScreenName,
} from 'root-redux/selects/common'

import {
  CURRENCY_SYMBOLS,
  Currency,
  PurchaseProduct,
} from 'modules/purchase/constants'
import { getPriceBeforeDiscount } from 'modules/purchase/helpers/getRecalculatedPrice'
import { TSubscriptionItemProps } from 'modules/purchase/types'

import {
  Cohort,
  Color,
  DEFAULT_DYNAMIC_DISCOUNT_AMOUNT,
  ScreenName,
} from 'root-constants'

import { StyledKitSubscriptionItemV1 as S } from './KitSubscriptionItemV1.styles'
import { StyledMxnKitSubscriptionItemV1 as M } from './MxnKitSubscriptionItemV1.styles'

export const KitSubscriptionItemV1: FC<TSubscriptionItemProps> = ({
  subscription: { id, product, mainPrices, trialPrices, isDefault, currency },
  isSelected,
}) => {
  const { t } = useTranslation()
  const dynamicDiscount = useSelector(selectDynamicDiscount)
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)
  // TODO: checking on screenName should be deleted after implementing https://gismart.atlassian.net/browse/FAS-886
  const screenName = useSelector(selectScreenName)

  const hasDynamicDiscount = useMemo(
    () =>
      cohortToUse === Cohort.MF_INTRO_SALE &&
      screenName !== ScreenName.CANCEL_OFFER,
    [cohortToUse, screenName],
  )

  const discount = useMemo(
    () =>
      dynamicDiscount
        ? dynamicDiscount?.amount
        : DEFAULT_DYNAMIC_DISCOUNT_AMOUNT,
    [dynamicDiscount],
  )

  const isSubscriptionTrial = useMemo(
    () =>
      trialPrices.fullPrice && trialPrices.fullPrice !== mainPrices.fullPrice,
    [trialPrices, mainPrices],
  )

  const noTrialCurrentPriceWithFractionalPart = useMemo(
    () => mainPrices.fullPrice.toFixed(2),
    [mainPrices],
  )

  const trialCurrentPriceWithFractionalPart = useMemo(
    () => trialPrices.fullPrice.toFixed(2),
    [trialPrices],
  )

  const oldPriceWithFractionalPart = useMemo(() => {
    if (hasDynamicDiscount && discount) {
      return product === PurchaseProduct.SEVEN_DAY
        ? trialPrices.oldPrices.beforeCustomDiscount?.fullPrice.toFixed(2)
        : getPriceBeforeDiscount(
            Number(trialCurrentPriceWithFractionalPart),
            discount,
          )
    }
    return product === PurchaseProduct.SEVEN_DAY
      ? trialPrices.oldPrices.beforeCustomDiscount?.fullPrice.toFixed(2)
      : mainPrices.fullPrice.toFixed(2)
  }, [
    hasDynamicDiscount,
    discount,
    product,
    trialPrices.oldPrices.beforeCustomDiscount?.fullPrice,
    mainPrices.fullPrice,
    trialCurrentPriceWithFractionalPart,
  ])

  const pricePerDayWithFractionalPart = useMemo(
    () =>
      product === PurchaseProduct.SEVEN_DAY
        ? mainPrices.daily.toFixed(2)
        : trialPrices.daily.toFixed(2),
    [product, trialPrices, mainPrices],
  )

  const oldPricePerDayWithFractionalPart = useMemo(() => {
    if (
      hasDynamicDiscount &&
      discount &&
      trialPrices.oldPrices.beforeCustomDiscount?.daily
    ) {
      return getPriceBeforeDiscount(
        Number(pricePerDayWithFractionalPart),
        discount,
      )
    }

    return trialPrices.oldPrices.beforeCustomDiscount?.daily.toFixed(2)
  }, [
    discount,
    hasDynamicDiscount,
    pricePerDayWithFractionalPart,
    trialPrices.oldPrices.beforeCustomDiscount?.daily,
  ])

  const period = useMemo(
    () =>
      product === PurchaseProduct.SEVEN_DAY
        ? t('purchase.subscription.weeklyPeriod', {
            periodQuantity: trialPrices.durationDays,
          })
        : t('purchase.subscription.monthlyPeriod', {
            periodQuantity: mainPrices.periodQuantity,
          }),
    [t, product, trialPrices, mainPrices],
  )

  const isMexican = useMemo(() => currency === Currency.MXN, [currency])

  const getFormattedPrice = useCallback(
    (price?: string) =>
      isMexican ? (
        <>
          {price} {CURRENCY_SYMBOLS[currency]}
        </>
      ) : (
        <>
          {CURRENCY_SYMBOLS[currency]}
          {price}
        </>
      ),
    [currency, isMexican],
  )

  return !isMexican ? (
    <S.PlanContainer key={id} isSelected={isSelected}>
      <S.PlanColumn>
        <S.PlanPeriod isSelected={isSelected}>{period}</S.PlanPeriod>
        <S.PlanPrice>
          {isSubscriptionTrial ? (
            <>
              <S.PreviousPrice>
                {getFormattedPrice(oldPriceWithFractionalPart)}
              </S.PreviousPrice>
              <S.CurrentPrice>
                {getFormattedPrice(trialCurrentPriceWithFractionalPart)}
              </S.CurrentPrice>
              <S.PreviousDayPrice>
                {getFormattedPrice(oldPricePerDayWithFractionalPart)}
              </S.PreviousDayPrice>
            </>
          ) : (
            <S.CurrentPrice>
              {getFormattedPrice(noTrialCurrentPriceWithFractionalPart)}
            </S.CurrentPrice>
          )}
        </S.PlanPrice>
      </S.PlanColumn>
      <S.PlanColumn>
        <FlagContainer
          backgroundColor={isSelected ? Color.GREEN_300 : Color.GREY_130}
        >
          <S.DayPriceContainer isSelected={isSelected}>
            <S.CurrentDayPrice>
              {getFormattedPrice(pricePerDayWithFractionalPart)}
            </S.CurrentDayPrice>
            <S.DayPricePeriod>
              {t('purchase.subscriptionV2.perDay')}
            </S.DayPricePeriod>
          </S.DayPriceContainer>
        </FlagContainer>
      </S.PlanColumn>
      {isDefault && (
        <S.PopularBadge isSelected={isSelected}>
          {t('purchase.subscriptionV2.mostPopularBadge')}
        </S.PopularBadge>
      )}
    </S.PlanContainer>
  ) : (
    <M.PlanContainer key={id} isSelected={isSelected}>
      <M.PlanColumn>
        <M.PlanPeriod isSelected={isSelected}>{period}</M.PlanPeriod>
        <M.PlanPrice>
          {isSubscriptionTrial ? (
            <>
              <M.PreviousPrice>
                {getFormattedPrice(oldPriceWithFractionalPart)}
              </M.PreviousPrice>
              <M.CurrentPrice>
                {getFormattedPrice(trialCurrentPriceWithFractionalPart)}
              </M.CurrentPrice>
            </>
          ) : (
            <M.CurrentPrice>
              {getFormattedPrice(noTrialCurrentPriceWithFractionalPart)}
            </M.CurrentPrice>
          )}
        </M.PlanPrice>
      </M.PlanColumn>
      <M.PlanColumn>
        <FlagContainer
          backgroundColor={isSelected ? Color.GREEN_300 : Color.GREY_130}
        >
          <M.DayPriceContainer isSelected={isSelected}>
            {isSubscriptionTrial && (
              <M.PreviousDayPrice isSelected={isSelected}>
                {getFormattedPrice(oldPricePerDayWithFractionalPart)}
              </M.PreviousDayPrice>
            )}
            <M.CurrentDayPrice>
              {getFormattedPrice(pricePerDayWithFractionalPart)}
            </M.CurrentDayPrice>
            <M.DayPricePeriod>
              {t('purchase.subscriptionV2.perDay')}
            </M.DayPricePeriod>
          </M.DayPriceContainer>
        </FlagContainer>
      </M.PlanColumn>
      {isDefault && (
        <M.PopularBadge isSelected={isSelected}>
          {t('purchase.subscriptionV2.mostPopularBadge')}
        </M.PopularBadge>
      )}
    </M.PlanContainer>
  )
}
