import styled from 'styled-components'

import { Button } from 'components/Button'
import { PageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

export const StyledIdealWeightFeedback = {
  PageContainer: styled(PageContainer)`
    min-height: calc(100vh - 45px);
    justify-content: space-between;
  `,
  ImageContainer: styled.div`
    align-self: flex-start;
    aspect-ratio: 1 / 1;
    height: 48px;
    margin-bottom: 24px;
  `,
  PageTitle: styled(PageTitle)`
    text-align: left;
  `,
  Content: styled.h4`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 16px;

    strong {
      font-weight: 700;
    }
  `,
  StickyButtonContainer: styled(StickyButtonContainer)`
    max-width: initial;
  `,
  Button: styled(Button)`
    max-width: 100%;
  `,
}
