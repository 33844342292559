import styled from 'styled-components'

import { PageTitle } from 'components/PageTitle'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { Color } from 'root-constants'

export const StyledWeightLossDate = {
  PageTitle: styled(PageTitle)`
    strong {
      color: ${Color.GREEN_300};
      font-weight: 700;
      margin-top: 4px;
    }
  `,
  Text: styled.p`
    color: #17202a;
    text-align: center;
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
  `,
  StickyButtonContainer: styled(StickyButtonContainer)`
    padding: 20px 0;
    z-index: 2;
    background: none;
  `,
}
