import React from 'react'

import { Swiper as SwiperClass } from 'swiper/types'

import { StyledSliderWithScaledActiveSlide as S } from './SliderWithScaledActiveSlide.styles'

type TProps = {
  onSlideChange?: (swiper: SwiperClass) => void
  children: React.ReactNode
}
export const SliderWithScaledActiveSlide: React.FC<TProps> = ({
  onSlideChange,
  children,
}) => (
  <S.Carousel
    pagination={{
      clickable: true,
    }}
    slidesPerView="auto"
    centeredSlides
    loop
    onSlideChange={onSlideChange}
  >
    {children}
  </S.Carousel>
)
