import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import femaleImage from 'assets/images/personalize-your-plan-female.png'
import maleImage from 'assets/images/personalize-your-plan-male.png'

import { Gender, Language } from 'root-constants'

import { StyledPersonalizeYourPlan as S } from './PersonalizeYourPlan.styles'

export const PersonalizeYourPlanV1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const gender = useSelector(selectCurrentUserGender)

  const imgSrc = useMemo(
    () => (gender === Gender.MALE ? maleImage : femaleImage),
    [gender],
  )

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.personalizeYourPlanV1.title', { lng: Language.EN }),
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  return (
    <KitPageContainer
      hasContinueButton
      continueButtonContent={t('actions.iAmReady')}
      onContinueButtonClick={handleNextClick}
    >
      <S.ImageContainer>
        <img src={imgSrc} alt="banner" />
      </S.ImageContainer>
      <PageTitle textAlign="left" marginBottom={16}>
        {t('onboarding.personalizeYourPlanV1.title')}
      </PageTitle>
      <S.Text>{t('onboarding.personalizeYourPlanV1.description')}</S.Text>
    </KitPageContainer>
  )
}
