import React from 'react'
import { useTranslation } from 'react-i18next'

import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { StyledMoneyBackGuarantee as S } from './MoneyBackGuarantee.styles'

export const MoneyBackGuarantee: React.FC = () => {
  const { t } = useTranslation()

  return (
    <S.Wrapper>
      <S.Content>
        <S.Title>{t('purchase.moneyBackGuarantee.title')}</S.Title>
        <S.Text>
          {t('purchase.moneyBackGuarantee.text')} <TermsOfUseLink />
        </S.Text>
      </S.Content>
    </S.Wrapper>
  )
}
