import { RestrictionsValue } from 'value-constants'

import breadIcon from 'assets/images/bread.png'
import cheeseIcon from 'assets/images/cheese.png'
import meatIcon from 'assets/images/meat.png'
import peanutIcon from 'assets/images/peanut.png'
import shellfishIcon from 'assets/images/shellfish.png'
import soyIcon from 'assets/images/soy.png'

export const TRANSLATION_FAMILY = 'onboarding.restrictionsV1'
export const OPTIONS = [
  {
    key: 'noAnimalProtein',
    value: RestrictionsValue.NO_ANIMAL_PROTEIN,
    iconSrc: meatIcon,
    strikethroughIcon: true,
  },
  {
    key: 'noLactose',
    value: RestrictionsValue.NO_LACTOSE,
    iconSrc: cheeseIcon,
    strikethroughIcon: true,
  },
  {
    key: 'noGluten',
    value: RestrictionsValue.NO_GLUTEN,
    iconSrc: breadIcon,
    strikethroughIcon: true,
  },
  {
    key: 'noPeanuts',
    value: RestrictionsValue.NO_PEANUTS,
    iconSrc: peanutIcon,
    strikethroughIcon: true,
  },
  {
    key: 'noSoy',
    value: RestrictionsValue.NO_SOY,
    iconSrc: soyIcon,
    strikethroughIcon: true,
  },
  {
    key: 'noShellfish',
    value: RestrictionsValue.NO_SHELLFISH,
    iconSrc: shellfishIcon,
    strikethroughIcon: true,
  },
]
