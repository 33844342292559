import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { AnswerButtonV2 } from 'components/AnswerButtonV2'
import { Container } from 'components/Container'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { MEALS_PREPARATION_VALUES_MAP, QUESTION } from './constants'

export const MealsPreparation: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, setIsAnswersDisabled, handleContinue],
  )

  return (
    <Container>
      <PageTitle marginBottom={24}>
        {t('onboarding.mealsPreparation.title')}
      </PageTitle>
      {MEALS_PREPARATION_VALUES_MAP.map(({ value, title }) => {
        return (
          <Option
            key={value}
            {...optionProps}
            marginBottom="12"
            disabled={isAnswersDisabled}
            value={value}
          >
            <AnswerButtonV2>
              <h3>{t(title)}</h3>
            </AnswerButtonV2>
          </Option>
        )
      })}
    </Container>
  )
}
