import React, { FC, useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectIdealWeightTime } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import handThumbUpImg from 'assets/images/hand-thumb-up.png'

import { Language } from 'root-constants'

import { StyledIdealWeightFeedback as S } from './IdealWeightFeedback.styles'
import { FEEDBACK_MAP } from './constants'

export const IdealWeightFeedbackV1: FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const idealWeightTime = useSelector(selectIdealWeightTime)

  const { title, info, callToAction } = useMemo(
    () => FEEDBACK_MAP[idealWeightTime],
    [idealWeightTime],
  )

  const handleContinue = useNextStep({
    pageId,
    question: t(title, { lng: Language.EN }),
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  return (
    <S.PageContainer>
      <div>
        <S.ImageContainer>
          <img src={handThumbUpImg} alt="hand-thumb-up" />
        </S.ImageContainer>
        <S.PageTitle marginBottom={24}>{t(title)}</S.PageTitle>
        <S.Content>
          <Trans i18nKey={info} />
        </S.Content>
        <S.Content>
          <Trans i18nKey={callToAction} />
        </S.Content>
      </div>

      <S.StickyButtonContainer>
        <S.Button type="button" onClick={handleNextClick}>
          {t('actions.continue')}
        </S.Button>
      </S.StickyButtonContainer>
    </S.PageContainer>
  )
}
