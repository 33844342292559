import React, { SyntheticEvent, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Container } from 'components/Container'
import { NavigationButton } from 'components/NavigationButton'
import { PageTitle } from 'components/PageTitle'
import { SvgImage } from 'components/SvgImage'

import {
  selectCurrentUserCurrentWeight,
  selectUserMeasurementSystem,
} from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { IInputState, TPageProps } from 'models/common.model'

import infoIcon from 'assets/images/information-circle.png'
import alertIcon from 'assets/images/sprite/alert-circle.svg'

import { CustomPageId } from 'page-constants'
import {
  FloatNumberRegex,
  INITIAL_INPUT_VALUE,
  MeasurementSystem,
  WEIGHT_UNITS,
} from 'root-constants'

import { StyledGoalWeight as S } from './GoalWeight.styles'
import {
  LOSE_WEIGHT_INFO_MAP,
  LoseWeightPercentageValue,
  MIN_KG_DIFF,
  MIN_LB_DIFF,
  MIN_WEIGHT_KG,
  MIN_WEIGHT_LB,
  QUESTION,
} from './constants'

export const GoalWeightV3: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()

  const currentWeightValue = +useSelector(selectCurrentUserCurrentWeight)
  const currentMeasurementSystem = useSelector(selectUserMeasurementSystem)

  const [weight, setWeight] = useState<IInputState>({
    ...INITIAL_INPUT_VALUE,
  })
  const [isFocused, setIsFocused] = useState(false)

  const isMetricSystemSelected = useMemo(
    () => currentMeasurementSystem === MeasurementSystem.METRIC,
    [currentMeasurementSystem],
  )

  const { title, text } = useMemo(() => {
    const goalWeight = (weight.isValid && +weight.value) || 60
    const loseWeightPercentage = +(
      100 -
      (goalWeight * 100) / currentWeightValue
    ).toFixed()

    let loseWeightPercentageValue = LoseWeightPercentageValue.LESS_THAN_10

    if (loseWeightPercentage <= 10) {
      loseWeightPercentageValue = LoseWeightPercentageValue.LESS_THAN_10
    }

    if (loseWeightPercentage > 10 && loseWeightPercentage <= 20) {
      loseWeightPercentageValue = LoseWeightPercentageValue.FROM_10_TO_20
    }

    if (loseWeightPercentage > 20) {
      loseWeightPercentageValue = LoseWeightPercentageValue.MORE_THEN_20
    }

    const loseWeightInfoMap = LOSE_WEIGHT_INFO_MAP[loseWeightPercentageValue]

    return {
      title: t(loseWeightInfoMap.title, {
        loseWeightPercentage,
      }),
      text: t(loseWeightInfoMap.text, {
        loseWeight: t(
          `onboarding.goalWeight.info.${
            isMetricSystemSelected ? 'kgValue' : 'lbsValue'
          }`,
        ),
      }),
    }
  }, [weight, currentWeightValue, isMetricSystemSelected, t])

  const handleContinue = useNextStep({
    pageId: CustomPageId.GOAL_WEIGHT,
    question: QUESTION,
    nextPagePath,
  })

  const handleClick = useCallback(
    (e: SyntheticEvent<HTMLFormElement>) => {
      e.preventDefault()
      handleContinue(+weight.value, currentMeasurementSystem)
    },
    [handleContinue, weight, currentMeasurementSystem],
  )

  const handleFocus = useCallback(() => {
    setIsFocused(true)
  }, [])

  const handleBlur = useCallback(() => {
    setIsFocused(false)
  }, [])

  const handleChange = useCallback(({ target: { value, validity } }) => {
    if (!value || FloatNumberRegex.test(value)) {
      setWeight((prevState) => ({
        ...prevState,
        value,
        isValid: validity.valid,
      }))
    }
  }, [])

  return (
    <form onSubmit={handleClick}>
      <Container>
        <PageTitle marginBottom={24}>
          {t('onboarding.goalWeight.title')}
        </PageTitle>
        <S.WeightLabel>
          {isMetricSystemSelected ? WEIGHT_UNITS.metric : WEIGHT_UNITS.imperial}
        </S.WeightLabel>
        <S.ActionsV2>
          <S.InputWrapper>
            <S.WeightInput
              type="number"
              value={weight.value as string}
              isContentCentered
              isValid={weight.isValid}
              min={isMetricSystemSelected ? MIN_WEIGHT_KG : MIN_WEIGHT_LB}
              max={
                currentWeightValue -
                (isMetricSystemSelected ? MIN_KG_DIFF : MIN_LB_DIFF)
              }
              lang="en"
              step="1"
              allowFloatNumbers={false}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            {!isFocused && !weight.value && <S.Placeholder />}
            <S.Suffix isErrorVisible={!!weight.value && !weight.isValid}>
              {isMetricSystemSelected
                ? WEIGHT_UNITS.metric
                : WEIGHT_UNITS.imperial}
            </S.Suffix>
          </S.InputWrapper>
          {weight.value && !weight.isValid && (
            <S.ErrorMessage>
              <SvgImage svg={alertIcon} />
              <span>{t('onboarding.goalWeight.errorMessage')}</span>
            </S.ErrorMessage>
          )}
        </S.ActionsV2>

        {weight.isValid && (
          <S.InfoContainer>
            <S.InfoImage>
              <img src={infoIcon} alt="info" />
            </S.InfoImage>
            <S.Info>
              <S.InfoTitle>{t(title)}</S.InfoTitle>
              <S.InfoText>{t(text)}</S.InfoText>
            </S.Info>
          </S.InfoContainer>
        )}

        <NavigationButton type="submit" disabled={!weight.isValid}>
          {t('actions.continue')}
        </NavigationButton>
      </Container>
    </form>
  )
}
