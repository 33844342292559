import { PrimaryGoalValue } from 'value-constants'

import getHealthy from 'assets/images/get-healthy-goal.png'
import loseWeight from 'assets/images/lose-weight-goal.png'

export const QUESTION = 'What do you want to achieve with NutriMate?'
export const PRIMARY_GOAL_V3_OPTIONS = [
  {
    value: PrimaryGoalValue.LOSE_WEIGHT,
    title: 'onboarding.primaryGoalV3.loseWeight',
    icon: loseWeight,
  },
  {
    value: PrimaryGoalValue.GET_HEALTHY,
    title: 'onboarding.primaryGoalV3.getHealthy',
    icon: getHealthy,
  },
]
