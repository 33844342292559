import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { AnswerButtonV2 } from 'components/AnswerButtonV2'
import { Container } from 'components/Container'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Cohort } from 'root-constants'

import { ANSWER_OPTIONS, QUESTION_V1, QUESTION_V2 } from './constants'

export const ActivityLevel: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const pageTitle = useMemo(
    () =>
      cohortToUse === Cohort.MF_MEN_FLOW
        ? 'onboarding.activityLevel.titleV2'
        : 'onboarding.activityLevel.titleV1',
    [cohortToUse],
  )
  const question = useMemo(
    () => (cohortToUse === Cohort.MF_MEN_FLOW ? QUESTION_V2 : QUESTION_V1),
    [cohortToUse],
  )

  const handleContinue = useNextStep({
    pageId,
    question,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, setIsAnswersDisabled, handleContinue],
  )

  return (
    <Container>
      <PageTitle marginBottom={24}>{t(pageTitle)}</PageTitle>
      {ANSWER_OPTIONS.map(({ value, title, description }) => (
        <Option
          key={value}
          {...optionProps}
          marginBottom="12"
          disabled={isAnswersDisabled}
          value={value}
        >
          <AnswerButtonV2>
            <>
              <h3>{t(title)}</h3>
              {description && <p>{t(description)}</p>}
            </>
          </AnswerButtonV2>
        </Option>
      ))}
    </Container>
  )
}
