import { Cohort } from 'root-constants'

export const COHORTS_WITH_ALTERNATIVE_UI = [
  Cohort.MF_INTRO_2,
  Cohort.MF_INFLUENCER,
  Cohort.MF_WOMEN_AGE,
  Cohort.MF_WOMEN_AGE_2,
]

export const PERCENTAGE = 90
