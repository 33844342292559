import buildHabitsFemale from 'assets/images/build-healthy-habits-female.png'
import buildHabitsMale from 'assets/images/build-healthy-habits-male.png'
import getFitFemale from 'assets/images/get-fit-female.png'
import getFitMale from 'assets/images/get-fit-male.png'
import loseWeightFemale from 'assets/images/lose-weight-female.png'
import loseWeightMale from 'assets/images/lose-weight-male.png'

import { Gender } from 'root-constants'

export const QUESTION = 'What do you want to achieve with NutriMate?'

export const OPTION_IMAGES = {
  [Gender.MALE]: {
    LOSE_WEIGHT: loseWeightMale,
    GET_FIT: getFitMale,
    DEVELOP_HEALTHY_HABITS: buildHabitsMale,
  },
  [Gender.FEMALE]: {
    LOSE_WEIGHT: loseWeightFemale,
    GET_FIT: getFitFemale,
    DEVELOP_HEALTHY_HABITS: buildHabitsFemale,
  },
}
