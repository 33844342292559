import styled from 'styled-components'

export const StyledReviewsSlider = {
  Wrapper: styled.div`
    .swiper {
      padding: 10px 0 30px 20px;
      margin-left: -20px;
    }

    .swiper-wrapper {
      display: flex;
    }

    .swiper-slide {
      position: relative;
      width: 264px;
      height: 144px;
      box-shadow: 0px 8px 20px rgba(17, 30, 23, 0.1);
      border-radius: 16px;
    }

    .swiper-slide-prev {
      box-shadow: none;
    }
  `,
  Card: styled.div`
    position: relative;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
  `,
  UserName: styled.h3`
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
  `,
  CardText: styled.p`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  `,
  Container: styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
  `,
  Rating: styled.img`
    display: block;
    width: 84px;
    height: 16px;
  `,
}
