import styled from 'styled-components'

import { Timer } from 'components/Timer'

import promoBg from 'assets/images/purchase-email/promo-bg-min.png'

import { Color, MediaBreakpoint } from 'root-constants'

type TProps = {
  isTimerHidden?: boolean
}

export const StyledIntroMediaEmail = {
  Promo: styled.div`
    border: 1px solid ${Color.ORANGE};
    border-radius: 24px;
    margin-bottom: 40px;
    padding: 26px 16px;
    background: #fff8f2 url(${promoBg}) no-repeat center;
    background-size: cover;
  `,
  PromoTitle: styled.h3`
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: ${Color.GREY_100};
    margin-bottom: 8px;
    text-align: center;
  `,
  PromoTimer: styled.div<TProps>`
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ isTimerHidden }) =>
      isTimerHidden &&
      `
    position: fixed;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      justify-content: center;
      padding: 13px 0;
      background: linear-gradient(269.02deg, #f08b34 25.31%, #ffda97 108.57%);
      z-index: 11;
      border-radius: 0;
    `};

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
    }
  `,
  TimerLabel: styled.span<TProps>`
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    color: ${Color.ORANGE};

    margin: 0 6px 0 0;

    ${({ isTimerHidden }) =>
      isTimerHidden &&
      `
    font-size: 18px;
      font-weight: 600;
      color: ${Color.WHITE};
    `};
  `,
  Timer: styled(Timer)`
    strong {
      font-weight: 700;
      font-size: ${({ isHeaderTimer }) => (isHeaderTimer ? `18px` : `20px`)};
      color: ${({ isHeaderTimer }) =>
        isHeaderTimer ? Color.WHITE : Color.ORANGE};
    }
  `,
}
