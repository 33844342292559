import React from 'react'

import { PayPalProvider } from 'modules/purchase/components/PayPalProvider'

import { StyledOneClickPayment as S } from './OneClickPayment.styles'

export const OneClickPayment = () => (
  <S.Container>
    <S.PaymentRequestButton />
    <PayPalProvider />
  </S.Container>
)
