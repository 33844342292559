import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { Spinner } from 'components/Spinner'

import {
  getSubscriptionListAction,
  setScreenNameAction,
} from 'root-redux/actions/common'
import {
  selectCurrentVariantCohortToUse,
  selectSubscriptionList,
} from 'root-redux/selects/common'
import { TAppDispatch } from 'root-redux/store/store'

import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { useVatInfo } from 'hooks/useHasVatInfo'
import { usePayment } from 'hooks/usePayment'
import {
  ViewportScrollPercentage,
  useViewportValue,
} from 'hooks/useViewportValue'

import { createProductId } from 'helpers/createProductId'

import {
  CustomerReviews,
  DiscountBannerV1,
  FAQList,
  FeaturedBlock,
  IntroMediaV2,
  MoneyBackGuaranteeV2,
  SubscriptionsBlock,
} from 'modules/purchase/components'
import { useDynamicPaywallConfig } from 'modules/purchase/hooks/useDynamicPaywallConfig'

import { eventLogger } from 'services/eventLogger.service'

import questionIcon from 'assets/images/question-icon-circle.png'

import { goTo } from 'browser-history'
import {
  Cohort,
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
} from 'root-constants'

import { OnboardingButtonText, PURCHASE_OFFSET_TOP } from '../constants'
import { StyledPurchaseVariant4 as S } from './PurchaseVariant4.styles'

export const PurchaseVariant4: React.FC = () => {
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()
  const { search } = useLocation()

  const subscriptions = useSelector(selectSubscriptionList)
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const hasVatInfo = useVatInfo()
  const { currentPaymentPageId } = useGetPageInfo()
  const viewportValue = useViewportValue()
  const { title, subtitle, buttonText } = useDynamicPaywallConfig()
  const { hasPrices, handleShowPayment, handleAmplitudePaymentEvents } =
    usePayment()

  const titleElementRef = useRef<HTMLHeadingElement>(null)
  const firstSubscriptionsRef = useRef<HTMLDivElement>(null)

  const isAlternativeVariantWithLowerPrices = useFeatureIsOn(
    'fas_519_lower_prices',
  )

  const hasDiscountBanner = useMemo(
    () => cohortToUse === Cohort.MF_INFLUENCER,
    [cohortToUse],
  )

  useLayoutEffect(() => {
    dispatch(setScreenNameAction(ScreenName.ONBOARDING))

    let tags = `${hasVatInfo ? SubscriptionTags.TAX : SubscriptionTags.NO_TAX}`

    if (isAlternativeVariantWithLowerPrices) {
      tags = `${tags},${SubscriptionTags.LOWER_PRICES}`
    }

    dispatch(getSubscriptionListAction(SubscriptionListType.PURCHASE, tags))
  }, [dispatch, hasVatInfo, isAlternativeVariantWithLowerPrices])

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(({ mainPrices }) =>
        createProductId({
          periodName: mainPrices.periodName,
          periodQuantity: mainPrices.periodQuantity,
          price: mainPrices.fullPrice,
        }),
      ),
      screenName: ScreenName.ONBOARDING,
    })
  }, [subscriptions])

  useEffect(() => {
    if (viewportValue > ViewportScrollPercentage.V_0) {
      eventLogger.logScrollToBottom(viewportValue, ScreenName.ONBOARDING)
    }
  }, [viewportValue])

  const handleIntroMediaButtonClick = useCallback(
    (event: SyntheticEvent<HTMLButtonElement>) => {
      const top = firstSubscriptionsRef.current?.offsetTop

      if (top) {
        document.body.scrollTo(0, top - PURCHASE_OFFSET_TOP)
      }

      handleAmplitudePaymentEvents(event.target as HTMLButtonElement)
    },
    [handleAmplitudePaymentEvents],
  )

  const handleGetPlan = useCallback(
    (event: SyntheticEvent<HTMLButtonElement>) => {
      handleShowPayment()
      handleAmplitudePaymentEvents(event.target as HTMLButtonElement)

      goTo(`${currentPaymentPageId}${search}`)
    },
    [
      handleAmplitudePaymentEvents,
      handleShowPayment,
      currentPaymentPageId,
      search,
    ],
  )

  return !hasPrices ? (
    <Spinner />
  ) : (
    <>
      <S.Wrapper>
        <S.ProgramPlanContainer>
          <S.Title>{title || <Trans i18nKey="purchase.title" />}</S.Title>
          <S.Subtitle>
            {subtitle || <Trans i18nKey="purchase.subtitle" />}
          </S.Subtitle>
          {hasDiscountBanner && <DiscountBannerV1 />}
          <IntroMediaV2
            hasDiscountBanner={hasDiscountBanner}
            elemForComparisonRef={titleElementRef}
            data-button-number="1"
            data-button-text={OnboardingButtonText.GET_PLAN_UPPER}
            onButtonClick={handleIntroMediaButtonClick}
          />
          <S.PlanHighlights titleElementRef={titleElementRef} />
        </S.ProgramPlanContainer>

        <SubscriptionsBlock ref={firstSubscriptionsRef} titleMargin={16} />

        <S.Button
          type="button"
          data-button-number="2"
          data-button-text={OnboardingButtonText.GET_PLAN_MIDDLE}
          onClick={handleGetPlan}
        >
          {buttonText || t('actions.getMyPlan')}
        </S.Button>

        <S.Disclaimer isTrialOnly={isAlternativeVariantWithLowerPrices} />
        <FeaturedBlock />
        <MoneyBackGuaranteeV2 />
        <S.SecurityInfo />
      </S.Wrapper>

      <S.SecondaryWrapper>
        <S.CustomerReviewsContainer>
          <S.PhotoResult titlePath="purchase.photoResult.titleV2" />
          <FAQList questionIconSrc={questionIcon} />
          <CustomerReviews />
          <S.Button
            type="button"
            data-button-number="3"
            data-button-text={OnboardingButtonText.GET_PLAN_BOTTOM}
            onClick={handleGetPlan}
          >
            {buttonText || t('actions.getMyPlan')}
          </S.Button>
        </S.CustomerReviewsContainer>
      </S.SecondaryWrapper>
    </>
  )
}
