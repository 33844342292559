import React from 'react'

import { useGetPageInfo } from 'hooks/useGetPageInfo'

import { Color } from 'root-constants'

import { StyledOnboardingProgressBar as S } from './OnboardingProgressBar.styles'

type TProps = {
  className?: string
}

export const OnboardingProgressBar: React.FC<TProps> = ({ className }) => {
  const { currentPageNumber, pagesWithProgressBar } = useGetPageInfo()

  return (
    <S.Wrapper className={className}>
      <S.Progress
        backroundColor={Color.LIGHT}
        color={Color.PRIMARY}
        value={currentPageNumber}
        max={pagesWithProgressBar.length + 1}
      />
    </S.Wrapper>
  )
}
