import dayjs, { Dayjs } from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

const enum DATES_INTERVALS_AMOUNT {
  ONE = 1,
  TWO = 2,
  THREE = 3,
}
export const DEFAULT_DATE_FORMAT = 'DD.MM.YYYY'
export const LOCALIZED_DATE_FORMAT = 'MMMM D, YYYY'
export const FULL_MONTH_FORMAT = 'MMMM'
export const SHORT_MONTH_FORMAT = 'MMM'
export const DAY_MONTH_FORMAT = 'D MMMM'
export const SHORT_YEAR_FORMAT = 'YY'
export const FULL_YEAR_FORMAT = 'YYYY'
export const DAY_FORMAT = 'D'
export const LONG_YEAR_FORMAT = 'YYYY'

export const getFormattedTodaysDate = (
  dateFormat = DEFAULT_DATE_FORMAT,
): string => dayjs().format(dateFormat)

export const getFormattedDate = (
  targetFormat: string,
  currentFormat: string,
  date: string,
  locale: string,
): string => dayjs(date, currentFormat).locale(locale).format(targetFormat)

export const addDaysToCurrentDate = (
  days: number,
  dateFormat = DEFAULT_DATE_FORMAT,
): string => {
  return dayjs().add(days, 'day').format(dateFormat)
}

export const daysFromNowToGoalDate = (goalDate: string): number => {
  const currentDate = dayjs()
  const endDate = dayjs(goalDate, DEFAULT_DATE_FORMAT)
  return endDate.diff(currentDate, 'day')
}

export const getIntermediateDates = (goalDate: string): string[] => {
  const currentDate = dayjs()
  const endDate = dayjs(goalDate, DEFAULT_DATE_FORMAT)
  const diff = endDate.diff(currentDate, 'month')

  if (diff > DATES_INTERVALS_AMOUNT.THREE) {
    const interval = diff / DATES_INTERVALS_AMOUNT.THREE
    const firstPoint = currentDate
      .add(interval, 'month')
      .format(FULL_MONTH_FORMAT)
    const secondPoint = currentDate
      .add(interval * DATES_INTERVALS_AMOUNT.TWO, 'month')
      .format(FULL_MONTH_FORMAT)

    return [firstPoint, secondPoint]
  }

  if (diff > DATES_INTERVALS_AMOUNT.ONE) {
    const interval = diff / DATES_INTERVALS_AMOUNT.TWO
    const firstPoint = currentDate
      .add(interval, 'month')
      .format(FULL_MONTH_FORMAT)

    return [firstPoint]
  }

  return []
}

export const getIntermediateMonthsArray = (
  predictableDate: Dayjs,
  locale: string,
) => {
  const currentDate = dayjs()
  const lastDateOfCurrentMonth = dayjs().endOf('month')

  const fullDiff = predictableDate.diff(currentDate, 'month', true)
  const currentMonthDiff = lastDateOfCurrentMonth.diff(
    currentDate,
    'month',
    true,
  )

  const remainingMonthsDiff = fullDiff - currentMonthDiff
  const countOfMonths =
    remainingMonthsDiff > 0 ? Math.ceil(remainingMonthsDiff) : 0

  const months: string[] = new Array(countOfMonths).fill('')

  return [
    currentDate.locale(locale).format(SHORT_MONTH_FORMAT),
    ...months.reduce(
      (acc: string[], cur, index) => [
        ...acc,
        currentDate
          .add(index + 1, 'month')
          .locale(locale)
          .format(SHORT_MONTH_FORMAT),
      ],
      [],
    ),
  ]
}

export const getDateSequence = ({
  mainDate,
  sequenceLength,
  beforeMainDate = false,
}: {
  mainDate: dayjs.Dayjs
  sequenceLength: number
  beforeMainDate?: boolean
}): dayjs.Dayjs[] =>
  Array.from({ length: sequenceLength }).map((item, index) => {
    return beforeMainDate
      ? mainDate.subtract(index, 'day')
      : mainDate.add(index, 'day')
  })
