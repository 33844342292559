import React, { useMemo } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  INITIAL_PROGRESS_VALUE,
  POST_PAYMENT_STEPS_MAP,
  PROGRESS_SECTION_STEP,
} from 'components/PostPaymentProgressBar/constants'

import { selectHasUpsellOffer } from 'root-redux/selects/common'

import { useGetPageInfo } from 'hooks/useGetPageInfo'

import { PageId } from 'page-constants'

import { StyledPostPaymentProgressBar as S } from './PostPaymentProgressBar.styles'

type TProps = {
  className?: string
}

export const PostPaymentProgressBar: React.FC<TProps> = ({ className }) => {
  const { currentPageId, currentUpsellPaywallPageId } = useGetPageInfo()

  const hasUpsellOffer = useSelector(selectHasUpsellOffer)

  const upsellProgressbarSteps = useMemo(
    () => [
      [
        {
          id: currentUpsellPaywallPageId,
          text: 'commonComponents.postPaymentProgressBar.buyPlan',
        },
      ],
      [
        {
          id: PageId.FINISHING_TOUCHES,
          text: 'commonComponents.postPaymentProgressBar.addOffer',
        },
      ],
      [
        {
          id: PageId.LOGIN,
          text: 'commonComponents.postPaymentProgressBar.confirmInformation',
        },
      ],
      [
        {
          id: PageId.DOWNLOAD,
          text: 'commonComponents.postPaymentProgressBar.createAccount',
        },
      ],
    ],
    [currentUpsellPaywallPageId],
  )

  const stepsMap = useMemo(
    () => (hasUpsellOffer ? upsellProgressbarSteps : POST_PAYMENT_STEPS_MAP),
    [hasUpsellOffer, upsellProgressbarSteps],
  )

  const currentSectionIndex = useMemo(
    () =>
      stepsMap.findIndex(
        (block) => !!block.find((step) => step.id === currentPageId),
      ),
    [currentPageId, stepsMap],
  )

  return (
    <S.Wrapper className={className}>
      {stepsMap.map(([{ id, text }], index, arr) => {
        const progressValue =
          currentSectionIndex >= index ? arr.length : INITIAL_PROGRESS_VALUE
        const nextSectionIndex = currentSectionIndex + PROGRESS_SECTION_STEP

        return (
          <S.StepBlock key={id}>
            <S.Step
              isActive={nextSectionIndex === index}
              isPassed={currentSectionIndex >= index}
            >
              <S.StepText>
                <Trans i18nKey={text} />
              </S.StepText>
              {currentSectionIndex < index && (
                <S.StepNumber>{index + PROGRESS_SECTION_STEP}</S.StepNumber>
              )}
            </S.Step>
            <S.StepBlockProgress
              value={progressValue}
              pointsNumber={arr.length}
              max={arr.length}
            />
          </S.StepBlock>
        )
      })}
      <S.Step isActive={currentPageId === PageId.DOWNLOAD}>
        <S.StepText>
          <Trans i18nKey="commonComponents.postPaymentProgressBar.accessPlan" />
        </S.StepText>
        {currentSectionIndex < stepsMap.length + 1 && (
          <S.StepNumber>{stepsMap.length + PROGRESS_SECTION_STEP}</S.StepNumber>
        )}
      </S.Step>
    </S.Wrapper>
  )
}
