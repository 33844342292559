import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledWhatYouGetV1 = {
  Title: styled.h2`
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    margin: 0 0 24px 0;
    text-align: center;
  `,

  ListItem: styled.li<{ bulletSrc: string }>`
    padding: 0 0 0 62px;
    position: relative;
    &:not(:last-of-type) {
      margin: 0 0 24px 0;
    }

    &:before {
      position: absolute;
      left: 0;
      top: 0;
      content: url(${({ bulletSrc }) => bulletSrc});
      width: 46px;
      height: 46px;
    }
  `,
  ItemTitle: styled.h4`
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: ${Color.DARK_BLUE};
    margin: 0 0 2px 0;
  `,
  ItemText: styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${Color.GREY_101};
  `,
}
