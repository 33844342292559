import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { BMI_LABELS_MAP } from 'map-constants'

import {
  selectCurrentUserCurrentWeight,
  selectCurrentUserGoalWeight,
  selectCurrentUserHeight,
  selectUserMeasurementSystem,
} from 'root-redux/selects/common'

import { getBmiInImperialSystem } from 'helpers/getBmiInImperialSystem'
import { getBmiInMetricSystem } from 'helpers/getBmiInMetricSystem'

import { TBMIValue } from 'models/common.model'

import {
  LOWER_BMI_THRESHOLD_V1,
  MeasurementSystem,
  UPPER_BMI_THRESHOLD,
} from 'root-constants'

type TBMI = {
  bmiIndex: number
  bmiValue: TBMIValue
}

type TReturnValue = {
  currentBMI: TBMI
  goalBMI: TBMI
}

type TProps = {
  userHeight?: number
  currentUserWeight?: number
  goalUserWeight?: number
  currentMeasurementSystem?: MeasurementSystem
}

export const useUsersBmi = (props?: TProps): TReturnValue => {
  const selectedCurrentUserHeight = +useSelector(selectCurrentUserHeight)
  const selectedCurrentUserCurrentWeight = +useSelector(
    selectCurrentUserCurrentWeight,
  )
  const selectedCurrentUserGoalWeight = +useSelector(
    selectCurrentUserGoalWeight,
  )
  const selecedtUserMeasurementSystem = useSelector(selectUserMeasurementSystem)

  const userHeight = props?.userHeight || selectedCurrentUserHeight
  const currentUserWeight =
    props?.currentUserWeight || selectedCurrentUserCurrentWeight
  const goalUserWeight = props?.goalUserWeight || selectedCurrentUserGoalWeight
  const currentMeasurementSystem =
    props?.currentMeasurementSystem || selecedtUserMeasurementSystem

  const isMetricSystemSelected = useMemo(
    () => currentMeasurementSystem === MeasurementSystem.METRIC,
    [currentMeasurementSystem],
  )

  const currentBMIIndex = useMemo(
    () =>
      isMetricSystemSelected
        ? getBmiInMetricSystem(currentUserWeight, userHeight)
        : getBmiInImperialSystem(currentUserWeight, userHeight),
    [isMetricSystemSelected, currentUserWeight, userHeight],
  )

  const goalBMIIndex = useMemo(
    () =>
      isMetricSystemSelected
        ? getBmiInMetricSystem(goalUserWeight, userHeight)
        : getBmiInImperialSystem(goalUserWeight, userHeight),
    [isMetricSystemSelected, goalUserWeight, userHeight],
  )

  const currentBMIValue = useMemo(() => {
    if (currentBMIIndex <= LOWER_BMI_THRESHOLD_V1) {
      return BMI_LABELS_MAP[0]
    }

    if (currentBMIIndex >= UPPER_BMI_THRESHOLD) {
      return BMI_LABELS_MAP[BMI_LABELS_MAP.length - 1]
    }

    return (
      BMI_LABELS_MAP.find(
        (label) =>
          currentBMIIndex >= label.lowerThreshold &&
          currentBMIIndex <= label.upperThreshold,
      ) || BMI_LABELS_MAP[BMI_LABELS_MAP.length - 1]
    )
  }, [currentBMIIndex])

  const goalBMIValue = useMemo(() => {
    if (goalBMIIndex <= LOWER_BMI_THRESHOLD_V1) {
      return BMI_LABELS_MAP[0]
    }

    if (goalBMIIndex >= UPPER_BMI_THRESHOLD) {
      return BMI_LABELS_MAP[BMI_LABELS_MAP.length - 1]
    }

    return (
      BMI_LABELS_MAP.find(
        (label) =>
          goalBMIIndex >= label.lowerThreshold &&
          goalBMIIndex <= label.upperThreshold,
      ) || BMI_LABELS_MAP[BMI_LABELS_MAP.length - 1]
    )
  }, [goalBMIIndex])

  return {
    currentBMI: {
      bmiIndex: currentBMIIndex,
      bmiValue: currentBMIValue,
    },
    goalBMI: {
      bmiIndex: goalBMIIndex,
      bmiValue: goalBMIValue,
    },
  }
}
