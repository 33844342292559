import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  selectCurrentUserGoalWeight,
  selectCurrentUserPrimaryGoal,
  selectUserMeasurementSystem,
} from 'root-redux/selects/common'

import { GOAL_MAP } from 'modules/purchase/components/UserInfoBlock/constants'

import { MeasurementSystem } from 'root-constants'

import { StyledUserInfoBlock as S } from './UserInfoBlock.styles'

type TProps = {
  className?: string
}

export const UserInfoBlock: React.FC<TProps> = ({
  className,
}): React.ReactElement => {
  const { t } = useTranslation()
  const userPrimaryGoal = useSelector(selectCurrentUserPrimaryGoal)
  const currentMeasurementSystem = useSelector(selectUserMeasurementSystem)
  const targetWeight = useSelector(selectCurrentUserGoalWeight)

  const measurementSystem = useMemo(
    () =>
      currentMeasurementSystem === MeasurementSystem.METRIC
        ? t('commonComponents.kg')
        : t('commonComponents.lbs'),
    [currentMeasurementSystem, t],
  )

  return (
    <S.Container className={className}>
      <S.Column>
        <S.InfoLabel>{t('purchase.goal')}</S.InfoLabel>
        <S.Info>{t(GOAL_MAP[userPrimaryGoal])}</S.Info>
      </S.Column>
      <S.Divider />
      <S.Column>
        <S.InfoLabel>{t('purchase.targetWeight')}</S.InfoLabel>
        <S.Info>{`${targetWeight} ${measurementSystem}`}</S.Info>
      </S.Column>
    </S.Container>
  )
}
