import styled, { css } from 'styled-components'

import checkIcon from 'assets/images/check-white.svg'
import priceBadgeUrl from 'assets/images/price-badge.svg'
import selectedPriceBadgeUrl from 'assets/images/selected-price-badge.svg'

import { Color, MediaBreakpoint } from 'root-constants'

type TProps = {
  isSelected?: boolean
  isCustom?: boolean
}

type TPriceProps = {
  fontSize?: number
}

const commonPriceStyles = css`
  position: relative;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: ${Color.DISABLED};
`
export const CustomPrice = styled.p<TProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 87px;
  min-height: 48px;
  margin-right: 10px;
  color: rgba(19, 29, 48, 0.5);
  transition: background 0.3s ease-out;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${priceBadgeUrl});
  background-size: 100%;

  ${({ isSelected }) =>
    isSelected &&
    `
        background-image: url(${selectedPriceBadgeUrl});
        color: #ffffff;
    `};
`
export const CurrentPrice = styled.span`
  ${commonPriceStyles};
`
export const PriceValue = styled.span`
  margin: 10px 0 0 24px;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
`
export const TimePeriod = styled.span`
  margin-left: 24px;
  font-weight: 700;
  font-size: 8px;
  line-height: 10px;
`
export const Content = styled.div<TProps>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:after {
    content: '';
    width: 20px;
    height: 20px;
    mix-blend-mode: normal;
    border: 2px solid rgba(19, 29, 48, 0.1);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 14px;
    transform: translateY(-50%);
    transition: background-color 0.3s ease-out;
    ${({ isSelected }) =>
      isSelected &&
      `
          background-image: url(${checkIcon});
          background-position: center center;
          background-repeat: no-repeat;
          background-color: #8cd9ae;
          border: 2px solid ${Color.PRIMARY};
        `};
  }
`
export const MostPopularBadge = styled.div<TProps>`
  position: absolute;
  top: -15px;
  left: 45px;
  transform: translateY(-50%);
  padding: 4px 16px;
  border-radius: 24px;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  background-color: ${({ isCustom }) =>
    isCustom ? Color.PRIMARY : Color.ORANGE};
  color: ${Color.WHITE};
`
export const PlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding-left: 42px;
`
export const PlanPeriod = styled.span<TProps>`
  font-size: 16px;
  font-weight: 800;
  line-height: 20px;
  text-transform: uppercase;
  color: ${({ isSelected }) => (isSelected ? Color.GREY_100 : Color.DISABLED)};

  @media (min-width: ${MediaBreakpoint.MIN_PHONE}px) {
    font-size: 16px;
  }
`
export const PreviousPrice = styled.span<TPriceProps>`
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '14px')};
  margin-right: 6px;
  ${commonPriceStyles};

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 115%;
    height: 1px;
    background-color: ${Color.RED_200};
  }
`

export const StyledSubscriptionItemV3 = {
  Wrapper: styled.div<TProps>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 14px 0;
    background: ${Color.WHITE};
    border: ${({ isSelected }) =>
      `2px solid ${isSelected ? Color.PRIMARY : Color.GREY_WHITE}`};
    border-radius: ${({ isCustom }) => (isCustom ? '20px' : '12px')};
  `,
  PriceContainer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  `,
}
