import extraFemale from 'assets/images/extra-female.png'
import extraMale from 'assets/images/extra-male.png'
import midSizedFemale from 'assets/images/mid-sized-female.png'
import midSizedMale from 'assets/images/mid-sized-male.png'
import slimFemale from 'assets/images/slim-female.png'
import slimMale from 'assets/images/slim-male.png'

import { Gender } from 'root-constants'

export const QUESTION = 'How would you describe your physical build?'

export const OPTION_VALUES = {
  SLIM: 'slim',
  MID_SIZED: 'mid_sized',
  EXTRA: 'extra',
}

export const OPTION_IMAGES = {
  [Gender.MALE]: {
    SLIM: slimMale,
    MID_SIZED: midSizedMale,
    EXTRA: extraMale,
  },
  [Gender.FEMALE]: {
    SLIM: slimFemale,
    MID_SIZED: midSizedFemale,
    EXTRA: extraFemale,
  },
}
