export const QUESTION_V1 = 'Sleep Patterns'
export const QUESTION_V2 = 'How are you sleeping?'

export const OPTION_VALUES = {
  LONG_TIME: 'long_time',
  AFTER_ELEVEN: 'after_eleven',
  LESS_SEVEN: 'less_seven',
  MORE_TEN: 'more_ten',
  RESTED: 'rested',
  RECOMMEND: 'recommend',
}

export const ANSWER_OPTIONS_V1 = [
  {
    value: OPTION_VALUES.LONG_TIME,
    title: 'onboarding.sleepingTime.options2.longTimeV1',
  },
  {
    value: OPTION_VALUES.AFTER_ELEVEN,
    title: 'onboarding.sleepingTime.options2.afterEleven',
  },
  {
    value: OPTION_VALUES.LESS_SEVEN,
    title: 'onboarding.sleepingTime.options2.lessSeven',
  },
  {
    value: OPTION_VALUES.MORE_TEN,
    title: 'onboarding.sleepingTime.options2.moreTen',
  },
  {
    value: OPTION_VALUES.RESTED,
    title: 'onboarding.sleepingTime.options2.rested',
  },
  {
    value: OPTION_VALUES.RECOMMEND,
    title: 'onboarding.sleepingTime.options2.recommend',
  },
]

export const ANSWER_OPTIONS_V2 = [
  {
    value: OPTION_VALUES.LONG_TIME,
    title: 'onboarding.sleepingTime.options2.longTimeV2',
  },
  {
    value: OPTION_VALUES.AFTER_ELEVEN,
    title: 'onboarding.sleepingTime.options2.afterEleven',
  },
  {
    value: OPTION_VALUES.LESS_SEVEN,
    title: 'onboarding.sleepingTime.options2.lessSeven',
  },
  {
    value: OPTION_VALUES.MORE_TEN,
    title: 'onboarding.sleepingTime.options2.moreTen',
  },
  {
    value: OPTION_VALUES.RESTED,
    title: 'onboarding.sleepingTime.options2.rested',
  },
  {
    value: OPTION_VALUES.RECOMMEND,
    title: 'onboarding.sleepingTime.options2.recommend',
  },
]
