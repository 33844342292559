import React, {
  FC,
  InputHTMLAttributes,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from 'react'

import checkIcon from 'assets/images/check-circle-2.png'

import { StyledKitInputWithValidationIcons as S } from './KitInputWithValidationIcons.styles'

type TEmailInputProps = InputHTMLAttributes<HTMLInputElement> & {
  isValid: boolean
  label: string
  shortLabel?: string
  className?: string
  value: string
  children?: ReactNode
  onChange: (event: SyntheticEvent<HTMLInputElement>) => void
  onFocus: () => void
  onBlur: () => void
}

export const KitInputWithValidationIcons: FC<TEmailInputProps> = ({
  value,
  label,
  shortLabel,
  className,
  isValid,
  children,
  onFocus,
  onChange,
  onBlur,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false)

  const handleFocus = useCallback(() => {
    setIsFocused(true)
    onFocus()
  }, [onFocus])

  const handleBlur = useCallback(() => {
    setIsFocused(false)
    onBlur()
  }, [onBlur])

  const labelToShow = useMemo(() => {
    if (shortLabel) {
      return value || isFocused ? shortLabel : label
    }
    return label
  }, [isFocused, label, shortLabel, value])

  return (
    <S.InputContainer className={className}>
      <S.Input
        value={value}
        isValid={isValid}
        label={labelToShow}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...props}
      />
      {value && isValid && (
        <S.ValidationIcon>
          <img src={checkIcon} alt="check-icon" />
        </S.ValidationIcon>
      )}
      {children}
    </S.InputContainer>
  )
}
