import { SleepingTimeValue } from 'value-constants'

export const FEEDBACKS = [
  'onboarding.sleepingTimeFeedbackV1.items.appetite',
  'onboarding.sleepingTimeFeedbackV1.items.resistance',
  'onboarding.sleepingTimeFeedbackV1.items.stressLevels',
]

export const TITLE_MAP = {
  [SleepingTimeValue.LESS_THAN_FIVE]:
    'onboarding.sleepingTimeFeedbackV1.titleV1',
  [SleepingTimeValue.FIVE_SIX]: 'onboarding.sleepingTimeFeedbackV1.titleV1',
  [SleepingTimeValue.SEVEN_EIGHT]: 'onboarding.sleepingTimeFeedbackV1.titleV2',
  [SleepingTimeValue.MORE_THAN_EIGHT]:
    'onboarding.sleepingTimeFeedbackV1.titleV2',
}
