import { BmiValue } from 'value-constants'

import checkIcon from 'assets/images/check-circle.png'
import exclaimationIcon from 'assets/images/exclaimation-circle.png'
import exclaimationWarningIcon from 'assets/images/exclaimation-warning-circle.png'

export const QUESTION = 'Your current weight'

export const enum MinMaxWeight {
  MIN_WEIGHT_KG = 21,
  MAX_WEIGHT_KG = 149,
  MIN_WEIGHT_LB = 46,
  MAX_WEIGHT_LB = 328,
}

export const CURRENT_BMI_INFO_MAP = {
  [BmiValue.UNDERWEIGHT]: {
    iconSrc: exclaimationIcon,
    background: 'linear-gradient(0deg, #ffe3e3 0%, #ffe3e3 100%), #f5f5f5;',
    text: 'onboarding.currentWeight.mbiInfo.underweight',
    bmiValue: 'onboarding.currentWeight.disclaimer.label.underweight',
  },
  [BmiValue.NORMAL]: {
    iconSrc: checkIcon,
    background: 'linear-gradient(0deg, #ecfbf2 0%, #ecfbf2 100%), #f1fff8;',
    text: 'onboarding.currentWeight.mbiInfo.normal',
    bmiValue: 'onboarding.currentWeight.disclaimer.label.normal',
  },
  [BmiValue.OVERWEIGHT]: {
    iconSrc: exclaimationWarningIcon,
    background: 'linear-gradient(0deg, #fff2e3 0%, #fff2e3 100%), #f5f5f5;',
    text: 'onboarding.currentWeight.mbiInfo.overweight',
    bmiValue: 'onboarding.currentWeight.disclaimer.label.overweight',
  },
  [BmiValue.OBESITY]: {
    iconSrc: exclaimationIcon,
    background: 'linear-gradient(0deg, #ffe3e3 0%, #ffe3e3 100%), #f5f5f5;',
    text: 'onboarding.currentWeight.mbiInfo.obesity',
    bmiValue: 'onboarding.currentWeight.disclaimer.label.obese',
  },
  [BmiValue.EXTREME_OBESITY]: {
    iconSrc: exclaimationIcon,
    background: 'linear-gradient(0deg, #ffe3e3 0%, #ffe3e3 100%), #f5f5f5;',
    text: 'onboarding.currentWeight.mbiInfo.obesity',
    bmiValue: 'onboarding.currentWeight.disclaimer.label.extrObese',
  },
}
