import styled from 'styled-components'

import { AnswerButton } from 'components/AnswerButton'
import { AnswerButtonV2 } from 'components/AnswerButtonV2'

export const StyledCheckboxOptionsPage = {
  AnswerButton: styled(AnswerButtonV2)`
    min-height: 92px;

    h3 {
      font-size: 18px;
      letter-spacing: initial;
      line-height: 20px;
    }
  `,
  QuestionDescription: styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 24px;
    text-align: center;
  `,
}

export const StyledRadioOptionsPage = {
  AnswerButton: styled(AnswerButton)`
    min-height: 92px;

    h3 {
      letter-spacing: initial;
    }
  `,
  OptionInfoContainer: styled.div`
    background: linear-gradient(0deg, #e3f2ff 0%, #e3f2ff 100%), #f5f5f5;
    border-radius: 12px;
    column-gap: 8px;
    display: flex;
    margin-bottom: 24px;
    padding: 16px;
  `,
  OptionInfoIcon: styled.div`
    aspect-ratio: 1 / 1;
    height: 24px;
    min-width: 24px;
  `,
  OptionInfo: styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  `,
  OptionInfoTitle: styled.h4`
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  `,
  OptionInfoText: styled.p`
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  `,
}

export const StyledMixedOptionsPage = {
  AnswerButton: styled(AnswerButtonV2)`
    min-height: 100px;

    h3 {
      font-size: 18px;
    }

    &::after {
      width: 20px;
      height: 20px;
      border-radius: 6px;
    }
  `,
}
