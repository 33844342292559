import React, { FC, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { PageContainer } from 'components/PageContainer'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import banner from 'assets/images/program-suite-banner.png'

import { Language } from 'root-constants'

import { StyledProgramSuite as S } from './ProgramSuite.styles'

export const ProgramSuiteV1: FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.programSuiteV1.title', { lng: Language.EN }),
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  return (
    <PageContainer>
      <S.Title>
        <Trans i18nKey="onboarding.programSuiteV1.title" />
      </S.Title>
      <S.Subtitle>{t('onboarding.programSuiteV1.subtitle')}</S.Subtitle>
      <S.Banner>
        <img src={banner} alt="program-suite-banner" />
      </S.Banner>
      <S.Info>
        <Trans i18nKey="onboarding.programSuiteV1.info" />
      </S.Info>
      <StickyButtonContainer>
        <Button type="button" onClick={handleNextClick}>
          {t('actions.continue')}
        </Button>
      </StickyButtonContainer>
    </PageContainer>
  )
}
