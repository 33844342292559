import styled from 'styled-components'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledCheckoutSummary = {
  DescriptionContainer: styled.div`
    column-gap: 16px;
    display: flex;
    font-size: 15px;
    font-weight: 400;
    justify-content: space-between;
    line-height: 30px;
    margin-bottom: 13px;
    padding-bottom: 8px;

    &:last-of-type {
      margin-bottom: 8px;
    }

    @media (min-width: ${MediaBreakpoint.MIN_PHONE}px) {
      font-size: 16px;
    }
  `,
  PersonalizedPlan: styled.span`
    text-align: left;
  `,
  PlanPrice: styled.span`
    font-weight: 700;
  `,
  TotalAmountContainer: styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: 14px;
    border-top: 1px solid #eaeaec;
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
  `,
  DiscountDescription: styled.p`
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: ${Color.ORANGE};
    text-align: right;
  `,
  Discount: styled.span`
    font-weight: 700;
    color: ${Color.ORANGE};
  `,
}
