import styled, { css } from 'styled-components'

import goalWeightImg from 'assets/images/graph-label-green.png'
import currentWeightImg from 'assets/images/graph-label-white.png'

import { Color } from 'root-constants'

const commonLabelStyles = css<{ isAnimationStarted: boolean }>`
  position: absolute;
  z-index: 2;
  display: inline-block;
  width: 50px;
  height: 37px;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: center;
  padding-top: 8px;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: ${({ isAnimationStarted }) => (isAnimationStarted ? '1' : '0')};
`

export const StyledLoseWeightGraphV4 = {
  GraphWrapper: styled.div`
    border-radius: 20px;
    border: 1px solid var(--Base-Gray20, #e9e9e9);
    background-color: ${Color.WHITE};
    padding: 16px 0;
    margin-bottom: 16px;
  `,

  Graph: styled.div`
    position: relative;
    aspect-ratio: 341/192;
  `,
  CurrentWeightLabel: styled.div`
    ${commonLabelStyles};
    left: 4%;
    top: 1.5%;
    color: #ed8e70;
    background-image: url(${currentWeightImg});
  `,
  GoalWeightLabel: styled.div`
    ${commonLabelStyles};
    right: 23.5%;
    top: 49%;
    color: ${Color.WHITE};
    background-image: url(${goalWeightImg});
    transition: opacity 2s linear;
  `,
  GraphSignatureWrapper: styled.div`
    display: flex;
    justify-content: space-between;
    margin: -12px 20% 12px 10.8%;
  `,
  GraphSignature: styled.span`
    color: ${Color.GREY_101};
    font-size: 12px;
    line-height: 14px;
  `,
  BaseOnText: styled.p`
    color: ${Color.GREY_101};
    font-size: 13px;
    line-height: 16px;
    margin-left: 10.3%;

    strong {
      font-weight: 700;
    }
  `,
}
