import React, { FC, InputHTMLAttributes, forwardRef } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { StyledCustomDatePicker as S } from './CustomDatePicker.styles'

type TProps = {
  onChange: (date: Date) => void
  selectedDate: Date | null
  minDate: Date
  toggleDatePickerVisibility: () => void
}

type TInputProps = InputHTMLAttributes<HTMLInputElement>

export const CustomDatePicker = ({
  onChange,
  selectedDate,
  minDate,
  toggleDatePickerVisibility,
  ...props
}: TProps) => {
  const handleDateFieldClick = (onClick) => {
    toggleDatePickerVisibility()
    onClick()
  }

  const handleOutsideDatePickerClick = () => {
    toggleDatePickerVisibility()
  }

  const CustomInput: FC = forwardRef<HTMLInputElement, TInputProps>(
    (inputProps, ref) => (
      <S.WrapperDataField
        onClick={() => {
          handleDateFieldClick(inputProps.onClick)
        }}
      >
        <S.DateField ref={ref} {...inputProps}>
          {inputProps.value}
        </S.DateField>
        <S.CalendarIcon />
      </S.WrapperDataField>
    ),
  )

  return (
    <S.Wrapper>
      <DatePicker
        customInput={<CustomInput />}
        showIcon
        onCalendarClose={handleOutsideDatePickerClick}
        selected={selectedDate}
        onChange={onChange}
        minDate={minDate}
        dateFormat="dd MMM yyyy"
        popperModifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, -55],
            },
          },
        ]}
        {...props}
      />
    </S.Wrapper>
  )
}
