import styled from 'styled-components'

import { PageContainer } from 'components/PageContainer'
import { UserInfoBlockV1 } from 'components/UserInfoBlock'

export const StyledPresaleV1 = {
  PageContainer: styled(PageContainer)`
    padding: 24px 16px;
  `,
  UserInfoBlock: styled(UserInfoBlockV1)`
    margin: 0 0 16px 0;
  `,
}
