import { FavouriteFoodsValue } from 'value-constants'

import appleIcon from 'assets/images/apple.png'
import breadIcon from 'assets/images/bread.png'
import cheeseIcon from 'assets/images/cheese.png'
import meatIcon from 'assets/images/meat.png'
import sushiIcon from 'assets/images/sushi.png'

export const TRANSLATION_FAMILY = 'onboarding.favouriteFoodsV1'
export const OPTIONS = [
  {
    key: 'meat',
    value: FavouriteFoodsValue.MEAT,
    iconSrc: meatIcon,
  },
  {
    key: 'fish',
    value: FavouriteFoodsValue.FISH,
    iconSrc: sushiIcon,
  },
  {
    key: 'fruitsAndVeggies',
    value: FavouriteFoodsValue.FRUITS_AND_VEGGIES,
    iconSrc: appleIcon,
  },
  {
    key: 'carbs',
    value: FavouriteFoodsValue.CARBS,
    iconSrc: breadIcon,
  },
  {
    key: 'cheese',
    value: FavouriteFoodsValue.CHEESE,
    iconSrc: cheeseIcon,
  },
]
