import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'

import { Color, MAX_COLUMN_WIDTH, MediaBreakpoint } from 'root-constants'

export const StyledReviews = {
  ReviewsContainer: styled.div`
    width: 100%;

    .swiper-pagination {
      bottom: 18px;

      .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        background: ${Color.GREY_WHITE};
        opacity: 1;

        &.swiper-pagination-bullet-active {
          background: ${Color.PRIMARY};
        }
      }
    }
  `,
  Title: styled.h4`
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    color: ${Color.BLACK};
    padding: 0 16px;
  `,
  Carousel: styled(Swiper)`
    width: 100%;
    margin: 0 auto;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: ${MAX_COLUMN_WIDTH}px;
    }
  `,
  SwiperSlide: styled(SwiperSlide)`
    padding: 16px 16px 56px;
  `,
}
