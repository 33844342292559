import styled from 'styled-components'

export const StyledMenu = {
  Banner: styled.div`
    aspect-ratio: 1 / 1;
    display: flex;
    margin: 0 auto 8px;
    width: 244px;
  `,
}
