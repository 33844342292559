import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { convertTextFromSnakeCase } from 'helpers/convertTextFromSnakeCase'

import { DynamicTitle } from 'root-constants'

export const useTitleFromUrl = () => {
  const { search } = useLocation()

  const paramsFromUrl = useMemo(() => {
    const URLParams = new URLSearchParams(search)

    return Object.fromEntries(URLParams.entries())
  }, [search])

  const title = useMemo(
    () =>
      paramsFromUrl[DynamicTitle.HEADLINE]
        ? convertTextFromSnakeCase(paramsFromUrl[DynamicTitle.HEADLINE])
        : '',
    [paramsFromUrl],
  )

  const subText = useMemo(
    () =>
      paramsFromUrl[DynamicTitle.SUBTEXT]
        ? convertTextFromSnakeCase(paramsFromUrl[DynamicTitle.SUBTEXT])
        : '',
    [paramsFromUrl],
  )

  return {
    title,
    subText,
  }
}
