import React, { useCallback, useEffect, useLayoutEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { Spinner } from 'components/Spinner'

import {
  getSubscriptionListAction,
  setScreenNameAction,
} from 'root-redux/actions/common'
import {
  selectCurrentVariantCohortToUse,
  selectLanguage,
  selectSubscriptionList,
} from 'root-redux/selects/common'
import { selectUserOnboardingEmail } from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { useVatInfo } from 'hooks/useHasVatInfo'
import { usePayment } from 'hooks/usePayment'

import { createProductId } from 'helpers/createProductId'

import {
  CustomerReviews,
  CustomerReviewsV2,
  Disclaimer,
  FAQList,
  MoneyBackGuarantee,
  PhotoResult,
  PlanHighlights,
  SubscriptionsBlock,
} from 'modules/purchase/components'
import { COHORTS_SUPPORTING_TRIAL_PRICE } from 'modules/purchase/constants'
import { IMAGE_PATH } from 'modules/purchase/pages/CancelOffer/constants'

import { eventLogger } from 'services/eventLogger.service'

import giftImageDiscount75 from 'assets/images/cancelOffer/cancel-offer-gift-75.png'
import giftImageDiscountLaptop65 from 'assets/images/cancelOffer/gift-laptop-65.jpg'
import giftImageDiscountLaptop75 from 'assets/images/cancelOffer/gift-laptop-75.jpg'

import { goTo } from 'browser-history'
import {
  CDN_FOLDER_LINK,
  Cohort,
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
} from 'root-constants'

import { StyledCancelOffer as S } from './CancelOffer.styles'

export const CancelOffer: React.FC = () => {
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()
  const { search } = useLocation()

  const subscriptions = useSelector(selectSubscriptionList)
  const email = useSelector(selectUserOnboardingEmail)
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)
  const language = useSelector(selectLanguage)
  const { hasPrices, handleShowPayment } = usePayment()

  const hasVatInfo = useVatInfo()
  const { currentPaymentPageId } = useGetPageInfo()

  const isAlternativeVariantWithLowerPrices = useFeatureIsOn(
    'fas_519_lower_prices',
  )

  const isCohortWithTrialPrice = useMemo(
    () => COHORTS_SUPPORTING_TRIAL_PRICE.includes(cohortToUse),
    [cohortToUse],
  )

  useLayoutEffect(() => {
    dispatch(setScreenNameAction(ScreenName.CANCEL_OFFER))

    let tags = `${SubscriptionTags.CANCEL_OFFER},${
      hasVatInfo ? SubscriptionTags.TAX : SubscriptionTags.NO_TAX
    }`

    if (isAlternativeVariantWithLowerPrices) {
      tags = `${tags},${SubscriptionTags.LOWER_PRICES}`
    }

    dispatch(getSubscriptionListAction(SubscriptionListType.PURCHASE, tags))
  }, [dispatch, hasVatInfo, isAlternativeVariantWithLowerPrices])

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(({ mainPrices }) =>
        createProductId({
          periodName: mainPrices.periodName,
          periodQuantity: mainPrices.periodQuantity,
          price: mainPrices.fullPrice,
        }),
      ),
      screenName: ScreenName.CANCEL_OFFER,
    })
  }, [subscriptions])

  const handleGetPlan = useCallback(() => {
    window.ttq.identify({ email })
    handleShowPayment()

    goTo(`${currentPaymentPageId}${search}`)
  }, [email, handleShowPayment, currentPaymentPageId, search])

  return !hasPrices ? (
    <Spinner />
  ) : (
    <div>
      <S.ImageContainer>
        <picture>
          <source
            srcSet={
              isCohortWithTrialPrice
                ? giftImageDiscountLaptop65
                : giftImageDiscountLaptop75
            }
            media="(min-width: 1800px)"
          />
          <img
            src={
              isCohortWithTrialPrice
                ? `${CDN_FOLDER_LINK}${IMAGE_PATH}-${language}.png`
                : giftImageDiscount75
            }
            alt="discount banner"
          />
        </picture>
      </S.ImageContainer>

      <S.PlanDescription>
        <Trans i18nKey="cancelOffer.paywall.planDescription" />
      </S.PlanDescription>

      <S.SubscriptionsContainer>
        <SubscriptionsBlock titleMargin={24} isCancelOffer />
        <MoneyBackGuarantee />
        <S.Button type="button" onClick={handleGetPlan}>
          {t('actions.getMyPlan')}
        </S.Button>
      </S.SubscriptionsContainer>

      <S.Wrapper>
        <PlanHighlights />
        <PhotoResult />
        <FAQList />
      </S.Wrapper>
      <S.SecondaryWrapper>
        <S.Wrapper>
          {cohortToUse === Cohort.MF_FLOW_2 ||
          cohortToUse === Cohort.MF_FLOW_2_INTRO_2 ? (
            <CustomerReviewsV2 />
          ) : (
            <CustomerReviews />
          )}
          <S.Button type="button" onClick={handleGetPlan}>
            {t('actions.getMyPlan')}
          </S.Button>
          <S.FeaturedBlock />
          <Disclaimer isTrialOnly={isAlternativeVariantWithLowerPrices} />
        </S.Wrapper>
      </S.SecondaryWrapper>
    </div>
  )
}
