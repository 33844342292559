import {
  ActivityDuringWorkValue,
  ActivityLevelValue,
  BmiValue,
  PrimaryGoalValue,
  WorkOutFrequencyValue,
} from 'value-constants'

import normalImg from 'assets/images/bmi-bg-normal.svg'
import obeseImg from 'assets/images/bmi-bg-obese.svg'
import underweightImg from 'assets/images/bmi-bg-overweight.svg'
import overweightImg from 'assets/images/bmi-bg-underweight.svg'
import femaleNormalImg from 'assets/images/kit-profile-summary-female-normal.png'
import femaleObesityImg from 'assets/images/kit-profile-summary-female-obesity.png'
import femaleOverweightImg from 'assets/images/kit-profile-summary-female-overweight.png'
import maleNormalImg from 'assets/images/kit-profile-summary-male-normal.png'
import maleObesityImg from 'assets/images/kit-profile-summary-male-obesity.png'
import maleOverweightImg from 'assets/images/kit-profile-summary-male-overweight.png'

import { Color, Gender } from 'root-constants'

export const QUESTION = 'Your profile summary'

export const BMI_VALUE_TO_MARKUP_MAP = {
  [BmiValue.UNDERWEIGHT]: {
    color: `${Color.ORANGE}`,
    backgroundImage: underweightImg,
    description: 'onboarding.profileSummary.description.underweight',
  },
  [BmiValue.NORMAL]: {
    color: `${Color.PRIMARY}`,
    backgroundImage: normalImg,
    description: '',
  },
  [BmiValue.OVERWEIGHT]: {
    color: `${Color.PINK}`,
    backgroundImage: overweightImg,
    description: 'onboarding.profileSummary.description.overweight',
  },
  [BmiValue.OBESITY]: {
    color: `${Color.RED_100}`,
    backgroundImage: obeseImg,
    description: 'onboarding.profileSummary.description.obesity',
  },
  [BmiValue.EXTREME_OBESITY]: {
    color: `${Color.RED_100}`,
    backgroundImage: obeseImg,
    description: 'onboarding.profileSummary.description.obesity',
  },
}

export const GOAL_TO_MARKUP_MAP = {
  [PrimaryGoalValue.LOSE_WEIGHT]:
    'onboarding.primaryGoal.options.loseWeight.title',
  [PrimaryGoalValue.GET_FIT]: 'onboarding.primaryGoal.options.getFit.title',
  [PrimaryGoalValue.BUILD_HEALTHY_HABITS]:
    'onboarding.primaryGoal.options.healthyHabits.title',
  [PrimaryGoalValue.GET_HEALTHY]:
    'onboarding.primaryGoal.options.getHealthy.title',
}

export const ACTIVITY_TO_MARKUP_MAP = {
  [ActivityLevelValue.SEDENTARY]: {
    exercises: 'onboarding.activityLevel.options.sedentary.description',
    level: 'onboarding.activityLevel.options.sedentary.title',
  },
  [ActivityLevelValue.SLIGHTLY_ACTIVE]: {
    exercises:
      'onboarding.activityLevel.options.slightlyActive.descriptionShort',
    level: 'onboarding.activityLevel.options.slightlyActive.title',
  },
  [ActivityLevelValue.MODERATELY]: {
    exercises: 'onboarding.activityLevel.options.moderately.descriptionShort',
    level: 'onboarding.activityLevel.options.moderately.title',
  },

  [ActivityLevelValue.VERY_ACTIVE]: {
    exercises: 'onboarding.activityLevel.options.veryActive.descriptionShort',
    level: 'onboarding.activityLevel.options.veryActive.title',
  },

  [ActivityLevelValue.SUPER_ACTIVE]: {
    exercises: 'onboarding.activityLevel.options.superActive.descriptionShort',
    level: 'onboarding.activityLevel.options.superActive.title',
  },
}

export const DESKTOP_COHORTS_EXERCISES_TO_MARKUP_MAP = {
  [WorkOutFrequencyValue.ONE_TWO_A_WEEK]:
    'onboarding.profileSummary.exercises.fewTimesAWeek',
  [WorkOutFrequencyValue.RARELY]: 'onboarding.profileSummary.exercises.rarely',
  [WorkOutFrequencyValue.THREE_FIVE_A_WEEK]:
    'onboarding.profileSummary.exercises.often',
}

export const DESKTOP_COHORTS_ACTIVITY_LEVEL_TO_MARKUP_MAP = {
  [ActivityDuringWorkValue.ACTIVE]:
    'onboarding.profileSummary.activity.veryActive',
  [ActivityDuringWorkValue.NORMAL]:
    'onboarding.profileSummary.activity.moderately',
  [ActivityDuringWorkValue.PASSIVE]:
    'onboarding.profileSummary.activity.sedentary',
}

export const EXERCISE_MAP = {
  [WorkOutFrequencyValue.THREE_FIVE_A_WEEK]:
    'onboarding.profileSummary.exercises.often',
  [WorkOutFrequencyValue.EVERY_DAY]:
    'onboarding.profileSummary.exercises.everyDay',
  [WorkOutFrequencyValue.RARELY]: 'onboarding.profileSummary.exercises.rarely',
  [WorkOutFrequencyValue.NEVER]: 'onboarding.profileSummary.exercises.rarely',
  [WorkOutFrequencyValue.ONE_TWO_A_WEEK]:
    'onboarding.profileSummary.exercises.fewTimesAWeek',
  [WorkOutFrequencyValue.ALMOST_EVERY_DAY]:
    'onboarding.profileSummary.exercises.almostEveryDay',
  [WorkOutFrequencyValue.SEVERAL_TIMES_PER_WEEK]:
    'onboarding.profileSummary.exercises.severalTimesPerWeek',
  [WorkOutFrequencyValue.SEVERAL_TIMES_PER_MONTH]:
    'onboarding.profileSummary.exercises.severalTimesPerWeek',
}

export const BMI_INFO_TO_MARKUP_MAP = {
  [BmiValue.UNDERWEIGHT]: {
    color: `${Color.ORANGE_200}`,
    backgroundColor: 'rgba(255, 153, 0, 0.20)',
    description: 'onboarding.profileSummary.description.underweight',
    imgSrc: {
      [Gender.MALE]: maleNormalImg,
      [Gender.FEMALE]: femaleNormalImg,
    },
  },
  [BmiValue.NORMAL]: {
    color: `${Color.GREEN_300}`,
    backgroundColor: 'rgba(169, 205, 80, 0.20)',
    description: 'onboarding.profileSummary.description.normal.male',
    imgSrc: {
      [Gender.MALE]: maleNormalImg,
      [Gender.FEMALE]: femaleNormalImg,
    },
  },
  [BmiValue.OVERWEIGHT]: {
    color: `${Color.ORANGE_200}`,
    backgroundColor: 'rgba(255, 153, 0, 0.20)',
    description: 'onboarding.profileSummary.description.overweight',
    imgSrc: {
      [Gender.MALE]: maleOverweightImg,
      [Gender.FEMALE]: femaleOverweightImg,
    },
  },
  [BmiValue.OBESITY]: {
    color: `${Color.RED_400}`,
    backgroundColor: 'rgba(231, 76, 60, 0.20)',
    description: 'onboarding.profileSummary.description.obesity',
    imgSrc: {
      [Gender.MALE]: maleObesityImg,
      [Gender.FEMALE]: femaleObesityImg,
    },
  },
  [BmiValue.EXTREME_OBESITY]: {
    color: `${Color.RED_400}`,
    backgroundColor: 'rgba(231, 76, 60, 0.20)',
    description: 'onboarding.profileSummary.description.obesity',
    imgSrc: {
      [Gender.MALE]: maleObesityImg,
      [Gender.FEMALE]: femaleObesityImg,
    },
  },
}
