import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledPlanHighlightsV2 = {
  Title: styled.h2`
    margin: 16px 0 24px;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: ${Color.GREY_100};
  `,
  List: styled.ul`
    padding: 0 4px;
  `,
  ListItem: styled.li`
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;

    svg {
      margin-right: 12px;
      min-width: 24px;
    }

    &:last-child {
      margin-bottom: 32px;
    }
  `,
}
