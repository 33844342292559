import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { CURRENCY_SYMBOLS, PurchaseProduct } from 'modules/purchase/constants'
import { TSubscriptionItemProps } from 'modules/purchase/types'

import { StyledSubscriptionItemV2 as S } from './SubscriptionItemV2.styles'

export const SubscriptionItemV2: React.FC<TSubscriptionItemProps> = ({
  subscription: { id, product, mainPrices, trialPrices, isDefault, currency },
  isSelected,
  isCancelOffer,
}) => {
  const { t } = useTranslation()

  const oldPriceBefore75Discount = useMemo(
    () =>
      (
        trialPrices.oldPrices.before75PercentDiscount.weekly ||
        mainPrices.oldPrices.before75PercentDiscount.weekly
      ).toFixed(2),
    [mainPrices, trialPrices],
  )

  const oldPriceBefore50Discount = useMemo(
    () =>
      (
        trialPrices.oldPrices.before50PercentDiscount.weekly ||
        mainPrices.oldPrices.before50PercentDiscount.weekly
      ).toFixed(2),
    [mainPrices, trialPrices],
  )

  const oldPriceBefore33Discount = useMemo(
    () =>
      (
        trialPrices.oldPrices.before33PercentDiscount.weekly ||
        mainPrices.oldPrices.before33PercentDiscount.weekly
      ).toFixed(2),
    [mainPrices, trialPrices],
  )

  return (
    <S.Wrapper key={id} isSelected={isSelected}>
      <S.Content isSelected={isSelected}>
        {isDefault && (
          <S.MostPopularBadge>
            {t('purchase.subscription.mostPopularBadge')}
          </S.MostPopularBadge>
        )}
        <S.PlanPeriod isSelected={isSelected}>
          {product === PurchaseProduct.SEVEN_DAY
            ? t('purchase.subscription.weeklyPeriod', {
                trialPeriodDays: trialPrices.durationDays,
              })
            : t('purchase.subscription.monthlyPeriod', {
                periodQuantity: mainPrices.periodQuantity,
              })}
        </S.PlanPeriod>

        <S.PriceContainer>
          <S.PreviousPriceContainer>
            {isCancelOffer && (
              <S.OldPriceSecond>
                {CURRENCY_SYMBOLS[currency]}
                {oldPriceBefore75Discount}
              </S.OldPriceSecond>
            )}

            <S.OldPrice>
              {CURRENCY_SYMBOLS[currency]}
              {isCancelOffer
                ? oldPriceBefore33Discount
                : oldPriceBefore50Discount}
            </S.OldPrice>
          </S.PreviousPriceContainer>

          <S.CustomPrice isSelected={isSelected}>
            <S.PriceValue>
              {CURRENCY_SYMBOLS[currency]}
              {trialPrices.weekly || mainPrices.weekly}
            </S.PriceValue>
            <S.Period>
              {t('purchase.subscription.interval')}{' '}
              {t(`purchase.subscription.week`)}
            </S.Period>
          </S.CustomPrice>
        </S.PriceContainer>
      </S.Content>
    </S.Wrapper>
  )
}
