import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledGenderV2 = {
  Title: styled.h2`
    font-weight: 800;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    color: ${Color.GREY_100};
    margin-bottom: 12px;
    margin-top: 16px;
  `,
  Subtitle: styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: ${Color.GREY_100};
    margin-bottom: 32px;
  `,
  CallToAction: styled.h3`
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #131d30;
    text-align: center;
    margin-bottom: 16px;
  `,
  Actions: styled.div`
    display: flex;

    input:checked + div > div {
      background-color: var(--checked-bg-color);
    }

    & label {
      flex-basis: calc(50% - 8px);

      &:first-child {
        margin-right: 8px;
      }
      &:last-child {
        margin-left: 8px;
      }
    }
  `,
  Action: styled.div`
    display: flex;
    min-height: 260px;
    position: relative;
    padding-top: 8px;
  `,

  AnswerForMale: styled.div`
    --checked-bg-color: #65b1e9;
    flex-basis: 100%;
    background-color: rgba(101, 177, 233, 0.15);
    border: 2px solid #65b1e9;
    border-radius: 12px;
    transition: background-color 0.1s ease-out;
  `,
  AnswerForFemale: styled.div`
    --checked-bg-color: #e0698c;
    flex-basis: 100%;
    background-color: #f9e1e8;
    border: 2px solid #e0698c;
    border-radius: 12px;
    transition: background-color 0.1s ease-out;
  `,
  MaleGender: styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: #65b1e9;
    transition: 0.2s;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: ${Color.WHITE};
    position: absolute;
    bottom: 8px;
    left: 8px;
    height: 40px;
    width: calc(100% - 16px);
    padding: 10px 16px;
    z-index: 2;
  `,
  FemaleGender: styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    transition: 0.2s;
    background: #e0698c;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: ${Color.WHITE};
    position: absolute;
    bottom: 8px;
    left: 8px;
    height: 40px;
    width: calc(100% - 16px);
    padding: 10px 16px;
    z-index: 2;
  `,
  MaleImage: styled.img`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    max-height: 260px;
    z-index: 1;
  `,
  FemaleImage: styled.img`
    position: absolute;
    top: 0;
    left: 7px;
    max-height: 260px;
    z-index: 1;
  `,
}
