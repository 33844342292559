import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledCustomerReviewsV2 = {
  Reviews: styled.ul`
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    margin-bottom: 32px;
  `,
  Item: styled.li`
    background-color: ${Color.WHITE};
    border-radius: 12px;
    box-shadow: 0px 4px 20px 0px rgba(187, 189, 198, 0.4);
    display: flex;
    flex-direction: column;
    padding: 16px;
    row-gap: 8px;
  `,
  Stars: styled.div`
    aspect-ratio: 49 / 9;
    max-width: 98px;
    width: 98px;
  `,
  Text: styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  `,
  Name: styled.p`
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  `,
}
