import styled from 'styled-components'

import { Button } from 'components/Button'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { Color } from 'root-constants'

type TProps = {
  backgroundImage: string
  color: string
}

export const StyledProfileSummaryV1 = {
  List: styled.ul`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 464px;
    padding: 20px 16px;
    background-color: #f1fff8;
    border-radius: 12px;
    margin: 0 auto 12px;

    li {
      display: flex;
      width: calc(100% - 90px);
    }

    strong {
      display: block;
      min-width: 95px;
      flex-shrink: 0;
      font-weight: 800;
      font-size: 12px;
      line-height: 16px;
      color: ${Color.PRIMARY};
    }
  `,
  Answer: styled.span`
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  `,
  Image: styled.img`
    position: absolute;
    bottom: 0;
    right: 0;
    width: 106px;
    height: 124px;
  `,
  BmiDescription: styled.div<TProps>`
    display: flex;
    align-items: center;
    gap: 16px;
    max-width: 464px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    padding: 16px;
    margin: 0 auto 32px;
    border-radius: 12px;
    color: ${({ color }) => color && color};
    background-image: ${({ backgroundImage }) =>
      backgroundImage && `url(${backgroundImage})`};
    background-size: cover;
    background-repeat: no-repeat;
  `,
  BmiTitle: styled.span`
    display: inline-block;
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 8px;
  `,
  BmiValue: styled.strong`
    font-weight: 700;
    font-size: 36px;
    line-height: 30px;
  `,
  Description: styled.p`
    color: ${Color.GREY_100};

    strong {
      font-weight: 600;
    }
  `,
  Button: styled(Button)`
    max-width: 464px;
  `,
}

export const StyledProfileSummaryV2 = {
  Title: styled.h2`
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: ${Color.GREY_100};
    margin: 16px 0 24px 0;
  `,
  ContentBlock: styled.div`
    box-shadow: 0 8px 20px 0 #111e171a;
    background-color: ${Color.WHITE};
    border-radius: 16px;
    padding: 16px 16px 0 16px;
  `,
  StickyButtonContainer: styled(StickyButtonContainer)`
    padding: 0;
    margin: 24px 0;
  `,
}
