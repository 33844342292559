import styled, { css } from 'styled-components'

import { SvgImage } from 'components/SvgImage'

import checkIcon from 'assets/images/check.svg'

import { Color } from 'root-constants'

type TSvgImageProps = {
  top: number
}

type TOptionContentProps = {
  isChecked: boolean
}

export const StyledTargetAreas = {
  Row: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
  `,
  ImageContainer: styled.div`
    width: 168px;
    aspect-ratio: 168/374;
  `,
  Col: styled.div`
    margin-left: 19px;
    flex-shrink: 0;
  `,
  OptionContent: styled.div<TOptionContentProps>`
    display: flex;
    justify-content: space-between;
    width: 144px;
    min-width: 144px;
    cursor: pointer;
    transition: 0.2s ease-out;
    background: #f5f6f7;
    border-radius: 12px;
    color: ${Color.GREY_100};
    position: relative;
    min-height: 72px;
    padding: 0 8px 0 16px;
    height: 72px;
    border: 2px solid #edeff0;

    &::after {
      display: block;
      content: '';
      width: 18px;
      height: 18px;
      mix-blend-mode: normal;
      border: 2px solid #edeff0;
      border-radius: 2px;
      background: ${Color.WHITE};
      position: absolute;
      top: 25px;
      right: 18px;
    }

    input:checked + & {
      background: ${Color.SECONDARY};
      border: 2px solid ${Color.PRIMARY};
      color: #fff;
    }

    input:checked + &::after {
      opacity: 1;
      background: #fff url(${checkIcon}) no-repeat center;
      border-color: ${Color.PRIMARY};
      background-size: 11px 8.5px;
    }

    svg {
      stroke: ${Color.GREY};
    }

    ${({ isChecked }) =>
      isChecked &&
      css`
        svg {
          stroke: ${Color.PRIMARY};
        }
      `}
  `,
  SvgImage: styled(SvgImage)<TSvgImageProps>`
    position: absolute;
    left: 0;
    transform: translateX(-100%);
    color: ${Color.LIGHT};
    top: ${({ top }) => `${top}px`};
  `,
  OptionText: styled.p`
    flex-basis: 100%;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.45px;
    color: ${Color.GREY_100};
    margin-bottom: 2px;
    text-transform: capitalize;
  `,
  Content: styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-self: center;
    padding: 20px 0;
    max-width: 256px;
  `,
  Subtitle: styled.p`
    color: ${Color.GREY_100};
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 24px;
    text-align: center;
  `,
}
