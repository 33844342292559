export const QUESTION = 'What pace do you prefer for your personalized plan?'

export const OPTION_VALUES = {
  FAST: 'fast',
  SLOW: 'slow',
  BETWEEN: 'between',
}

export const ANSWER_OPTIONS = [
  {
    value: OPTION_VALUES.FAST,
    title: 'onboarding.planPace.options.fast',
  },
  {
    value: OPTION_VALUES.SLOW,
    title: 'onboarding.planPace.options.slow',
  },
  {
    value: OPTION_VALUES.BETWEEN,
    title: 'onboarding.planPace.options.between',
  },
]
