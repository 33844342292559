import styled from 'styled-components'

import { Input } from 'components/Input'

import { Color } from 'root-constants'

export const StyledKitInputWithValidationIcons = {
  InputContainer: styled.div`
    position: relative;
  `,
  Input: styled(Input)`
    background-color: ${Color.WHITE};
    border: ${({ isValid, value }) =>
      `1px solid ${value && !isValid ? Color.RED_300 : Color.GREY_102}`};
    border-radius: 16px;
    font-size: 17px;
    font-weight: 400;
    height: 64px;
    line-height: 24px;
    padding: 30px 40px 14px 16px;

    & + span {
      color: ${Color.GREY_101};
      font-size: 17px;
      font-weight: 400;
      left: 17px;
      letter-spacing: -0.45px;
      line-height: 24px;
      top: 20px;
    }

    &:focus,
    &:valid {
      & + span {
        color: ${Color.DISABLED};
        font-size: 14px;
        line-height: 18px;
        top: 10px;
      }
    }
  `,
  ValidationIcon: styled.div`
    aspect-ratio: 1 / 1;
    display: flex;
    height: 24px;
    position: absolute;
    right: 10px;
    top: 22px;
    width: 24px;
  `,
}
