import styled from 'styled-components'

import { Color } from 'root-constants'

export const Header = styled.div`
  width: 100%;
  padding: 16px;
`
export const Title = styled.h3`
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.45px;
  color: ${Color.GREY_100};
  margin-bottom: 4px;
`
export const Subtitle = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${Color.GREY_100};
  margin-bottom: 16px;
`
export const BeforeAfter = styled.div`
  width: 100%;
  display: flex;
`

export const Label = styled.span`
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  background: #e9f8f0;
  border: 1px solid ${Color.PRIMARY};
  border-radius: 6px;
  color: ${Color.PRIMARY};
  padding: 4px 8px;
`

export const StyledBodyChange = {
  Root: styled.div`
    box-shadow: 0 12px 32px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    margin-bottom: 24px;
  `,
  Labels: styled.div`
    display: flex;
    justify-content: space-between;
  `,
}
