import styled from 'styled-components'

import { Container } from 'components/Container'

import pageBg from 'assets/images/men-choice-bg.png'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledMenChoice = {
  Wrapper: styled.div`
    padding: 8px 0 0 0;
    background-image: url(${pageBg});
    background-repeat: no-repeat;
    background-size: 100% 412px;
    background-position: top center;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      background-size: 360px auto;
    }
  `,
  Container: styled(Container)`
    max-width: 100%;
    padding: 0;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
    }
  `,

  ImageContainer: styled.div`
    width: 100%;
    aspect-ratio: 360/336;
    margin: 0 auto 26px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: 360px;
    }
  `,
  Title: styled.h1`
    font-weight: 700;
    font-size: 36px;
    line-height: 32px;
    text-align: center;
    color: ${Color.GREY_100};
    margin: 0 0 12px 0;
  `,
  Subtitle: styled.p`
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: ${Color.GREY_100};
    margin: 0 0 24px 0;

    strong {
      font-weight: 700;
    }
  `,
}
