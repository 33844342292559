import mealPlanning from 'assets/images/bullet-v2-arrow.svg'
import library from 'assets/images/bullet-v2-book.svg'
import schedule from 'assets/images/bullet-v2-calendar.svg'
import program from 'assets/images/bullet-v2-smile.svg'

export const GOALS_MAP = [
  {
    translationKey: 'purchase.boostResult.goals.schedule',
    icon: schedule,
  },
  {
    translationKey: 'purchase.boostResult.goals.program',
    icon: program,
  },
  {
    translationKey: 'purchase.boostResult.goals.mealPlanning',
    icon: mealPlanning,
  },
  {
    translationKey: 'purchase.boostResult.goals.library',
    icon: library,
  },
]
