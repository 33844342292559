import bullet1 from 'assets/images/fasting-plan.svg'
import bullet2 from 'assets/images/meal-plan.svg'
import bullet3 from 'assets/images/recipes.svg'
import bullet4 from 'assets/images/water-tracker.svg'
import bullet5 from 'assets/images/weight-tracker.svg'
import item1 from 'assets/images/what-you-get-v2-item-1.png'
import item2 from 'assets/images/what-you-get-v2-item-2.png'
import item3 from 'assets/images/what-you-get-v2-item-3.png'

export const ITEMS_MAP = [
  {
    icon: bullet1,
    title: 'purchase.whatYouGetV1.items.fastingPlan.title',
    text: 'purchase.whatYouGetV1.items.fastingPlan.text',
  },
  {
    icon: bullet2,
    title: 'purchase.whatYouGetV1.items.mealPlan.title',
    text: 'purchase.whatYouGetV1.items.mealPlan.text',
  },
  {
    icon: bullet3,
    title: 'purchase.whatYouGetV1.items.recipes.title',
    text: 'purchase.whatYouGetV1.items.recipes.text',
  },
  {
    icon: bullet4,
    title: 'purchase.whatYouGetV1.items.waterTracker.title',
    text: 'purchase.whatYouGetV1.items.waterTracker.text',
  },
  {
    icon: bullet5,
    title: 'purchase.whatYouGetV1.items.weightTracker.title',
    text: 'purchase.whatYouGetV1.items.weightTracker.text',
  },
]

export const WHAT_YOU_GET_MAP_V2 = [
  {
    imgSrc: item1,
    background:
      'linear-gradient(0deg, #eff7e2 0%, #eff7e2 100%), linear-gradient(0deg, #f1f5f9 0%, #f1f5f9 100%), #fbf6ee',
    title: 'purchase.whatYouGetV2.approach.title',
    info: 'purchase.whatYouGetV2.approach.info',
  },
  {
    imgSrc: item2,
    background: '#f7efe4',
    title: 'purchase.whatYouGetV2.support.title',
    info: 'purchase.whatYouGetV2.support.info',
  },
  {
    imgSrc: item3,
    background: 'linear-gradient(0deg, #f1f5f9 0%, #f1f5f9 100%), #eefbf4',
    title: 'purchase.whatYouGetV2.noToxic.title',
    info: 'purchase.whatYouGetV2.noToxic.info',
  },
]
