export const QUESTION = 'What is your body type?'

export const FEMALE_OPTION_VALUES = {
  RECTANGLE: 'rectangle',
  HOURGLASS: 'hourglass',
  PEAR: 'pear',
  ROUND: 'round',
}

export const MALE_OPTION_VALUES = {
  ECTOMORPH: 'ectomorph',
  MESOMORPH: 'mesomorph',
  ENDOMORPH: 'endomorph',
  NOT_SURE: 'not_sure',
}
