import leanFemale from 'assets/images/body-types-female-lean.png'
import midSizeFemale from 'assets/images/body-types-female-mid-size.png'
import significantlyOverweightFemale from 'assets/images/body-types-female-obessity.png'
import overweightFemale from 'assets/images/body-types-female-overweight.png'
import plusSizeFemale from 'assets/images/body-types-female-plus-size.png'
import leanMale from 'assets/images/body-types-male-lean.png'
import midSizeMale from 'assets/images/body-types-male-mid-size.png'
import significantlyOverweightMale from 'assets/images/body-types-male-obessity.png'
import overweightMale from 'assets/images/body-types-male-overweight.png'
import plusSizeMale from 'assets/images/body-types-male-plus-size.png'

export const QUESTION = 'How would you describe your body now?'

export const BODY_TYPES_DATA = [
  {
    value: 'lean',
    text: 'onboarding.bodyType.optionsV4.lean',
    img: { male: leanMale, female: leanFemale },
  },
  {
    value: 'mid_size',
    text: 'onboarding.bodyType.optionsV4.midSize',
    img: { male: midSizeMale, female: midSizeFemale },
  },
  {
    value: 'plus_size',
    text: 'onboarding.bodyType.optionsV4.plusSize',
    img: { male: plusSizeMale, female: plusSizeFemale },
  },
  {
    value: 'overweight',
    text: 'onboarding.bodyType.optionsV4.overweight',
    img: { male: overweightMale, female: overweightFemale },
  },
  {
    value: 'significantly_overweight',
    text: 'onboarding.bodyType.optionsV4.significantlyOverweight',
    img: {
      male: significantlyOverweightMale,
      female: significantlyOverweightFemale,
    },
  },
]
