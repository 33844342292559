import React, { SyntheticEvent, useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { PageTitle } from 'components/PageTitle'
import { SvgImage } from 'components/SvgImage'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import alertIcon from 'assets/images/sprite/alert-circle.svg'

import { CustomPageId } from 'page-constants'

import { StyledAgeV2 as S } from './AgeV2.styles'
import { IntegerNumberRegex, MAX_AGE, MIN_AGE, QUESTION } from './constants'

export const AgeV2: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const [age, setAge] = useState({
    value: '',
    isValid: false,
    rangeOverflow: false,
  })
  const [isFocused, setIsFocused] = useState(false)

  const handleChange = useCallback(({ target: { value, validity } }) => {
    if (!value || IntegerNumberRegex.test(value)) {
      setAge({
        value,
        isValid: validity.valid,
        rangeOverflow: validity.rangeOverflow,
      })
    }
  }, [])

  const handleContinue = useNextStep({
    pageId: CustomPageId.AGE,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(
    (e: SyntheticEvent<HTMLFormElement>) => {
      e.preventDefault()
      handleContinue(+age.value)
    },
    [age.value, handleContinue],
  )

  const handleFocus = useCallback(() => {
    setIsFocused(true)
  }, [])

  const handleBlur = useCallback(() => {
    setIsFocused(false)
  }, [])

  return (
    <form onSubmit={handleNextClick}>
      <Container>
        <PageTitle marginBottom={24}>{t('onboarding.age.title')}</PageTitle>
        <S.InputContainer>
          <S.AgeInput
            type="number"
            min={MIN_AGE}
            max={MAX_AGE}
            pattern="\d*"
            value={age.value}
            onChange={handleChange}
            isContentCentered
            isValid={age.isValid}
            allowFloatNumbers={false}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
          {!isFocused && !age.value && <S.Placeholder />}
          {age.value && !age.isValid && (
            <S.ErrorMessage>
              <SvgImage svg={alertIcon} />
              <span>
                {age.rangeOverflow
                  ? t('onboarding.age.errorMessage')
                  : t('onboarding.age.tooYoung')}
              </span>
            </S.ErrorMessage>
          )}
        </S.InputContainer>
        <S.Disclaimer>
          <S.Title>
            <Trans i18nKey="onboarding.age.disclaimer.title" />
          </S.Title>
          <S.SubTitle>
            <Trans i18nKey="onboarding.age.disclaimer.subTitle" />
          </S.SubTitle>
        </S.Disclaimer>
      </Container>
      <S.ButtonContainer>
        <Button type="submit" disabled={!age.isValid}>
          {t('actions.continue')}
        </Button>
      </S.ButtonContainer>
    </form>
  )
}
