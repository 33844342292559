import styled from 'styled-components'

import { BannerWithTimer } from 'modules/purchase/components/BannerWithTimer'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledDiscountTimerV1 = {
  BannerWithTimer: styled(BannerWithTimer)`
    width: 100%;
    height: 50px;
    position: sticky;
    z-index: 100;
    top: 0;
    margin: 0 auto 16px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: ${MediaBreakpoint.MEDIUM_PHONE}px;
    }
  `,
  Wrapper: styled.div`
    width: 100%;
    margin: 0 auto;
    padding: 10px 16px;
    box-shadow: 0 3px 20px 0 #cad0e566;
    position: relative;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: ${MediaBreakpoint.MEDIUM_PHONE}px;
    }
  `,
  Timer: styled.div`
    display: flex;
    flex-direction: column;
  `,
  TimerLabel: styled.p`
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    color: ${Color.GREY_100};
  `,
  TimerValue: styled.p`
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    color: ${Color.GREY_100};
  `,
  Button: styled.button`
    appearance: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    height: 60px;
    outline: none;
    padding: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 182px;
  `,
}

export const StyledDiscountTimerV2 = {
  Wrapper: styled.div`
    width: 100%;
    margin: 0 auto;
    padding: 10px 16px;
    box-shadow: 0 3px 20px 0 #cad0e566;
    position: relative;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: ${MediaBreakpoint.MEDIUM_PHONE}px;
    }
  `,
  Timer: styled.div`
    display: flex;
    flex-direction: column;
    max-width: 154px;
  `,
  TimerLabel: styled.p`
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    color: ${Color.GREY_100};
  `,
  TimerValue: styled.p`
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    color: ${Color.GREY_100};
  `,
  Button: styled.button`
    appearance: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    height: 60px;
    outline: none;
    padding: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 182px;
  `,
}
