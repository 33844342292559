import styled, { css } from 'styled-components'

import { Input } from 'components/Input'

import { Color } from 'root-constants'

type TAgeProps = {
  value: string
  isValid: boolean
}

export const StyledAgeV2 = {
  InputContainer: styled.div`
    position: relative;
  `,
  ButtonContainer: styled.div`
    padding-top: 8px;
  `,
  Disclaimer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 8px 12px;
    gap: 4px;
    background: rgba(192, 187, 255, 0.15);
    border-radius: 12px;
    margin-top: 24px;
    margin-bottom: 24px;
  `,
  Title: styled.div`
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: ${Color.GREY_100};
  `,
  SubTitle: styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: ${Color.GREY_100};
  `,
  AgeInput: styled(Input)<TAgeProps>`
    border: 2px solid ${Color.GREY_WHITE};

    ${({ isValid, value }) =>
      value &&
      !isValid &&
      css`
        background: rgba(224, 105, 140, 0.1);
        border: 2px solid #e0698c;
        color: #e0698c;
      `};

    ${({ isValid, value }) =>
      value &&
      isValid &&
      css`
        border: 2px solid ${Color.PRIMARY};
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
      `};

    &:focus,
    &:active {
      & ~ span {
        display: none;
      }
    }
  `,
  ErrorMessage: styled.p`
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    margin-top: 6px;
    color: #e0698c;
    & svg {
      width: 18px;
      height: 18px;
      margin-right: 2px;
    }
  `,
  Placeholder: styled.span`
    position: absolute;
    top: calc(50% - 2px);
    left: calc(50% - 7px);
    height: 4px;
    width: 14px;
    background-color: ${Color.DISABLED};
    pointer-events: none;
  `,
}
