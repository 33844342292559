import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { IMG_SRC_MAP } from 'pages/questions/UniquelyTailoredPlan/constants'

import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Language } from 'root-constants'

import { StyledUniquelyTailoredPlanV1 as S } from './UniquelyTailoredPlanV1.styles'

export const UniquelyTailoredPlanV1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const genderValue = useSelector(selectCurrentUserGender)

  const imgSrc = useMemo(() => IMG_SRC_MAP[genderValue], [genderValue])

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.kitUniquelyTailoredPlanV1.title', {
      lng: Language.EN,
    }),
    nextPagePath,
  })

  return (
    <KitPageContainer
      hasContinueButton
      onContinueButtonClick={() => handleContinue('')}
    >
      <S.ImageContainer>
        <img src={imgSrc} alt="uniquely-tailored-plan" />
      </S.ImageContainer>
      <PageTitle textAlign="left" marginBottom={16}>
        {t('onboarding.kitUniquelyTailoredPlanV1.title')}
      </PageTitle>
      <S.Text>{t('onboarding.kitUniquelyTailoredPlanV1.info')}</S.Text>
    </KitPageContainer>
  )
}
