import styled from 'styled-components'

import { InputWithValidationIcons } from 'components/InputWithValidationIcons'
import { SvgImage } from 'components/SvgImage'

import { Color } from 'root-constants'

export const StyledEmail = {
  TitleContainer: styled.header`
    margin-top: 24px;

    strong {
      color: ${Color.PRIMARY};
    }
  `,
  Form: styled.form`
    max-width: 464px;
    margin: 0 auto;
  `,
  Input: styled(InputWithValidationIcons)`
    margin-bottom: 30px;
  `,
  Disclaimer: styled.div`
    align-items: flex-start;
    color: ${Color.DISABLED};
    display: flex;
    font-size: 11px;
    font-weight: normal;
    line-height: 16px;
    margin-bottom: 40px;
    position: relative;
  `,
  LockIcon: styled(SvgImage)`
    flex-shrink: 0;
    margin-right: 10px;
  `,
  ButtonContainer: styled.div`
    position: relative;
    margin-bottom: 30px;
  `,
}
