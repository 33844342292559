import styled from 'styled-components'

import { Color } from 'root-constants'

import { Disclaimer } from '../Disclaimer'

export const StyledEmailSubscriptionsV2 = {
  Title: styled.h2`
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    margin: 0 0 8px 0;
  `,
  DiscountBlock: styled.div`
    display: flex;
    align-items: center;
  `,
  DiscountBadge: styled.span`
    background: radial-gradient(
      130% 196.05% at 80.8% 0%,
      #ffc969 0%,
      #ff8923 82.33%
    );
    border-radius: 8px;
    box-shadow: 0px 4px 12px 0px rgba(249, 133, 32, 0.3);
    color: ${Color.WHITE};
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    margin: 0 auto 16px auto;
    padding: 4px 12px;
    text-align: center;
  `,
  Subscriptions: styled.div`
    margin: 0 0 16px 0;
  `,
  Disclaimer: styled(Disclaimer)`
    padding: 0;
  `,
}
