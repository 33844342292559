import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledMoneyBackGuarantee = {
  Wrapper: styled.div`
    margin-bottom: 24px;
    padding: 6px;
    border-radius: 24px;
    border: 2px solid ${Color.PRIMARY};
    background-color: ${Color.WHITE};
  `,
  Title: styled.h2`
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
  `,
  Content: styled.div`
    padding: 16px 8px 18px;
    border: 1px dashed ${Color.PRIMARY};
    border-radius: 16px;
  `,
  Text: styled.p`
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: ${Color.DISABLED};

    a {
      color: ${Color.DISABLED};
      text-decoration: underline;
    }
  `,
}
