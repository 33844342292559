import React, { FC, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { PRIMARY_GOAL_MAP } from 'map-constants'

import { Button } from 'components/Button'
import { PageContainer } from 'components/PageContainer'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { selectCurrentUserPrimaryGoal } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import banner from 'assets/images/burn-calories-banner.png'

import { Language } from 'root-constants'

import { StyledBurnCalories as S } from './BurnCalories.styles'

export const BurnCaloriesV1: FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const primaryGoal = useSelector(selectCurrentUserPrimaryGoal)

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.burnCaloriesV1.title', { lng: Language.EN }),
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  return (
    <PageContainer>
      <S.Title>{t('onboarding.burnCaloriesV1.title')}</S.Title>
      <S.Banner>
        <img src={banner} alt="burn-calories-banner" />
      </S.Banner>
      <S.Info>
        <Trans
          i18nKey="onboarding.burnCaloriesV1.info"
          values={{
            primaryGoal: t(PRIMARY_GOAL_MAP[primaryGoal]),
          }}
        />
      </S.Info>
      <StickyButtonContainer>
        <Button type="button" onClick={handleNextClick}>
          {t('actions.continue')}
        </Button>
      </StickyButtonContainer>
    </PageContainer>
  )
}
