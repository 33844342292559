import React, { ButtonHTMLAttributes } from 'react'

import { StyledButton } from './Button.styles'

export interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonRef?: React.Ref<HTMLButtonElement>
  disabledBackgroundColor?: string
  className?: string
}

export const Button: React.FC<IProps> = ({
  children,
  buttonRef = null,
  className,
  ...props
}) => (
  <StyledButton className={className} ref={buttonRef} {...props}>
    {children}
  </StyledButton>
)
