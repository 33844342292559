import { AnswerWithIcon } from 'storybook-ui'
import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

const commonGenderStyles = css`
  display: flex;
  align-items: center;
  transition: 0.2s;
  border-radius: 0 0 12px 12px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: ${Color.WHITE};
  position: absolute;
  bottom: 0;
  left: 0;
  height: 40px;
  width: 100%;
  padding: 10px 16px;
  z-index: 2;
`

export const StyledGender = {
  Title: styled.h2`
    margin-bottom: 8px;
    font-weight: 800;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    letter-spacing: 0.36px;
    text-transform: uppercase;
    color: ${Color.GREY_100};
    margin-top: 16px;
  `,
  Subtitle: styled.p`
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: ${Color.DISABLED};
  `,
  CallToAction: styled.h3`
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: ${Color.GREY_100};
    text-align: center;
    margin-bottom: 16px;
  `,
  Actions: styled.div`
    display: flex;
    justify-content: space-between;

    input:checked + div > div {
      background-color: var(--checked-bg-color);
    }

    & label {
      flex-basis: calc(50% - 8px);
    }
  `,
  Action: styled.div`
    display: flex;
    min-height: 260px;
    position: relative;
    padding-top: 8px;
  `,

  AnswerForMale: styled.div`
    --checked-bg-color: #6fd09a;
    flex-basis: 100%;
    background-color: #e8ffee;
    border: 1px solid #6fd09a;
    border-radius: 12px;
    transition: background-color 0.1s ease-out;
  `,
  AnswerForFemale: styled.div`
    --checked-bg-color: #e0698c;
    flex-basis: 100%;
    background-color: #f9e1e8;
    border: 1px solid #e0698c;
    border-radius: 12px;
    transition: background-color 0.1s ease-out;
  `,
  MaleGender: styled.span`
    ${commonGenderStyles};
    background: #6fd09a;
  `,
  FemaleGender: styled.span`
    ${commonGenderStyles};
    background: #e0698c;
  `,
  MaleImage: styled.img`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    max-height: 220px;
    z-index: 1;
  `,
  FemaleImage: styled.img`
    position: absolute;
    top: 0;
    left: 7px;
    max-height: 220px;
    z-index: 1;
  `,
}

export const StyledGenderV5 = {
  AnswerWithIcon: styled(AnswerWithIcon)`
    text-transform: capitalize;
  `,
}
