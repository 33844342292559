import { CDN_FOLDER_LINK } from 'root-constants'

export type TReview = {
  key: string
  userName: string
  videoUrl: string
  posterUrl: string
}

export const VIDEO_REVIEWS: TReview[] = [
  {
    key: 'video_1_britney',
    userName: 'Britney, 37',
    videoUrl: `${CDN_FOLDER_LINK}/videos/short_video_review_britney.mp4`,
    posterUrl: `${CDN_FOLDER_LINK}/videos/britney-video-poster.png`,
  },
  {
    key: 'video_2_brandon',
    userName: 'Brandon, 35',
    videoUrl: `${CDN_FOLDER_LINK}/videos/short_video_review_brandon.mp4`,
    posterUrl: `${CDN_FOLDER_LINK}/videos/brandon-video-poster.png`,
  },
  {
    key: 'video_3_leslie',
    userName: 'Leslie, 48',
    videoUrl: `${CDN_FOLDER_LINK}/videos/short_video_review_leslie.mp4`,
    posterUrl: `${CDN_FOLDER_LINK}/videos/leslie-video-poster.png`,
  },
]
