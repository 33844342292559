import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Spinner } from 'components/Spinner'

import { usePrimer } from 'hooks/usePrimer'

import { PRIMER_PAY_PAL_ID } from 'modules/purchase/constants'
import { selectIsPrimerPayPalButtonLoading } from 'modules/purchase/redux/selects'

import svgSrc from 'assets/images/sprite/spinner-grey.svg'

import { StyledPrimerPaymentForm as S } from './PrimerPayPalButton.styles'

export const PrimerPayPalButton: React.FC = () => {
  const isPrimerPayPalButtonLoading = useSelector(
    selectIsPrimerPayPalButtonLoading,
  )
  const [isRendered, setIsRendered] = useState(false)
  usePrimer()

  useEffect(() => {
    if (isPrimerPayPalButtonLoading) {
      setIsRendered(isPrimerPayPalButtonLoading)
    }
  }, [isPrimerPayPalButtonLoading])

  return (
    <>
      <S.PayPalButton isHidden={!isRendered} id={PRIMER_PAY_PAL_ID} />
      {!isRendered && <Spinner svgSrc={svgSrc} isFullScreen={false} />}
    </>
  )
}
