import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { useVatInfo } from 'hooks/useHasVatInfo'

import {
  CURRENCY_SYMBOLS,
  EMAIL_FLOW_DISCOUNT,
} from 'modules/purchase/constants'
import {
  selectCurrency,
  selectSubscriptionFullPrice,
  selectSubscriptionFullPriceBeforeDiscount,
  selectSubscriptionIntroDifAmount,
} from 'modules/purchase/redux/selects'

import { StyledCheckoutSummary as S } from './CheckoutSummary.styles'

export const EmailTrialCheckoutSummary: React.FC = () => {
  const { t } = useTranslation()

  const currency = useSelector(selectCurrency)
  const discountAmount = useSelector(selectSubscriptionIntroDifAmount)
  const fullPrice = useSelector(selectSubscriptionFullPrice)
  const priceBeforeCustomDiscount = useSelector(
    selectSubscriptionFullPriceBeforeDiscount,
  )

  const hasVatInfo = useVatInfo()

  const isAlternativeVariant = useFeatureIsOn('fas_91_checkout_page')

  const discountValue = useMemo(() => fullPrice.toFixed(2), [fullPrice])

  const oldPrice = useMemo(
    () => priceBeforeCustomDiscount.toFixed(2),
    [priceBeforeCustomDiscount],
  )

  const currentPrice = useMemo(() => fullPrice.toFixed(2), [fullPrice])

  return (
    <>
      {isAlternativeVariant ? (
        <>
          <S.DescriptionContainer>
            <S.PersonalizedPlan>
              {t('purchase.checkoutV1.offerDiscountEmail', {
                discountPercent: EMAIL_FLOW_DISCOUNT,
              })}
            </S.PersonalizedPlan>
            <S.Discount>
              -
              {t('purchase.checkoutV1.discountAmount', {
                discountAmount: discountAmount.toFixed(2),
                currency: CURRENCY_SYMBOLS[currency],
              })}
            </S.Discount>
          </S.DescriptionContainer>
          <S.DescriptionContainer>
            <S.PersonalizedPlan>
              <Trans i18nKey="purchase.checkoutV1.personalizedPlan" />
            </S.PersonalizedPlan>
            <S.PlanPrice>
              {t('purchase.checkoutV1.fullPrice', {
                oldPrice,
                currency: CURRENCY_SYMBOLS[currency],
              })}
            </S.PlanPrice>
          </S.DescriptionContainer>
        </>
      ) : (
        <>
          <S.DescriptionContainer>
            <S.PersonalizedPlan>
              <Trans i18nKey="purchase.checkoutV1.personalizedPlan" />
            </S.PersonalizedPlan>
            <S.PlanPrice>
              {t('purchase.checkoutV1.fullPrice', {
                oldPrice,
                currency: CURRENCY_SYMBOLS[currency],
              })}
            </S.PlanPrice>
          </S.DescriptionContainer>
          <S.DescriptionContainer>
            <S.PersonalizedPlan>
              {t('purchase.checkoutV1.offerDiscountEmail', {
                discountPercent: EMAIL_FLOW_DISCOUNT,
              })}
            </S.PersonalizedPlan>
            <S.Discount>
              -
              {t('purchase.checkoutV1.discountAmount', {
                discountAmount: discountValue,
                currency: CURRENCY_SYMBOLS[currency],
              })}
            </S.Discount>
          </S.DescriptionContainer>
        </>
      )}
      <S.TotalAmountContainer>
        <span>
          {t('purchase.checkoutV1.total', {
            includingVat: hasVatInfo ? t('purchase.includingVat') : '',
          })}
        </span>
        <span>
          {t('purchase.checkoutV1.price', {
            price: currentPrice,
            currency: CURRENCY_SYMBOLS[currency],
          })}
        </span>
      </S.TotalAmountContainer>
      <S.DiscountDescription>
        {t('purchase.checkoutV1.discountDescription', {
          discountAmount: discountValue,
          discountPercent: EMAIL_FLOW_DISCOUNT,
          currency: CURRENCY_SYMBOLS[currency],
        })}
      </S.DiscountDescription>
    </>
  )
}
