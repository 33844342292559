import styled from 'styled-components'

import { Color } from 'root-constants'

type TWrapperProps = {
  withCheckbox?: boolean
}

export const StyledAnswerButton = {
  Wrapper: styled.div<TWrapperProps>`
    display: flex;
    width: 100%;
    max-width: 100%;
    cursor: pointer;
    transition: 0.2s ease-out;
    background: #f5f6f7;
    border-radius: 12px;
    color: ${Color.GREY_100};
    position: relative;
    min-height: 100px;
    padding: 0 20px 0 0;
    border: 2px solid transparent;

    input:checked + & {
      background: ${Color.SECONDARY};
      border: 2px solid ${Color.PRIMARY};
      color: #fff;
    }

    input:checked + &::after {
      opacity: 1;
      border-color: #fff;
      background: #fff;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      flex-basis: 100%;
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      letter-spacing: normal;
      color: ${Color.GREY_100};
      margin-bottom: 4px;
    }

    p {
      flex-basis: 100%;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.154px;
      color: ${Color.DISABLED};
    }
  `,
  Content: styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-self: center;
    padding: 20px 0;
  `,
  IconContainer: styled.div`
    flex-basis: 100px;
    padding-left: 4px;
    display: flex;
    justify-content: center;
    flex-shrink: 0;
  `,
  Icon: styled.img`
    vertical-align: top;
  `,
}
