import { useSelector } from 'react-redux'

import {
  selectActionList,
  selectCurrentVariantCohort,
  selectCurrentVariantCohortToUse,
  selectScreenName,
  selectStripeAccountId,
  selectStripeAccountName,
  selectSubscriptionList,
} from 'root-redux/selects/common'

import { ISubscription } from 'models/subscriptions.model'

import { Cohort, ScreenName } from 'root-constants'

type TReturnsUseCommonStore = {
  cohort: Cohort
  cohortToUse: Cohort
  fetchingActionsList: string[]
  screenName: ScreenName
  stripeAccountId: string
  stripeAccountName: string
  subscriptionsList: ISubscription[]
}

export const useCommonStore = (): TReturnsUseCommonStore => {
  const cohort = useSelector(selectCurrentVariantCohort)
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)
  const fetchingActionsList = useSelector(selectActionList)
  const screenName = useSelector(selectScreenName)
  const stripeAccountId = useSelector(selectStripeAccountId)
  const stripeAccountName = useSelector(selectStripeAccountName)
  const subscriptionsList = useSelector(selectSubscriptionList)

  return {
    cohort,
    cohortToUse,
    fetchingActionsList,
    screenName,
    stripeAccountId,
    stripeAccountName,
    subscriptionsList,
  }
}
