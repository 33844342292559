import en from 'date-fns/locale/en-US'
import es from 'date-fns/locale/es'
import { BodyPartValue, CheckboxCustomValue } from 'value-constants'

import { PageId } from 'page-constants'

export const enum CurrentEnvironment {
  DEV = 'dev',
  PROD = 'prod',
  STAGE = 'stage',
}

export const enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
  PATCH = 'PATCH',
}

export const enum MediaBreakpoint {
  MIN_PHONE = 321,
  MEDIUM_PHONE = 360,
  MAX_PHONE = 440,
  TABLET = 520,
  MAX_TABLET = 768,
  MIN_LAPTOP = 1024,
  LAPTOP = 1800,
  MAX_PHONE_HEIGHT = 667,
}

export const MAX_COLUMN_WIDTH = 360
export const KIT_MAX_COLUMN_WIDTH = 375

export const HEADER_HEIGHT = 44
export const PROGRESS_BAR_HEIGHT = 2
export const PROGRESS_BAR_MARGIN_BOTTOM = 16

export const enum StripeFieldName {
  NAME = 'name',
  NUMBER = 'cardNumber',
  EXPIRY = 'cardExpiry',
  CVC = 'cardCvc',
}

export const enum DynamicTitle {
  HEADLINE = 'headline',
  SUBTEXT = 'subtext',
}

export const CONTACT_FORM_LINKS = {
  [CurrentEnvironment.PROD]: 'https://account.nutrimate.fit/contact-form',
  [CurrentEnvironment.DEV]: 'https://account-nutrimate.gidev.xyz/contact-form',
  [CurrentEnvironment.STAGE]:
    'https://account-nutrimate.gistage.com/contact-form',
}
export const CDN_FOLDER_LINK =
  'https://cdn-prod-unified-content.gismart.xyz/d5808ebedf5357929eee86647da0a1d76abe111a9f5d3503d7eb9157aad897bd'
export const EMAIL_REGEXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const NumericKeypadRegex = /([0-9]{1}|Backspace|Enter)/
export const NumericKeypadFloatRegex = /([0-9.,]{1}|Backspace|Enter)/
export const FloatNumberRegex = /^([1-9]+[0-9]*|0{1})([.,][0-9]{0,1})?$/
export const ALPHABETIC_SYMBOLS_REGEXP = /[^\p{L}\p{Z}]+/gu

export const enum LoginMethod {
  EMAIL = 'email',
  FACEBOOK = 'facebook',
  APPLE = 'apple',
}

export const enum LoginMethodRaw {
  EMAIL = 'password',
  FACEBOOK = 'facebook.com',
  APPLE = 'apple.com',
}

export const LOGIN_METHOD_RAW_MAP = {
  [LoginMethodRaw.EMAIL]: LoginMethod.EMAIL,
  [LoginMethodRaw.FACEBOOK]: LoginMethod.FACEBOOK,
  [LoginMethodRaw.APPLE]: LoginMethod.APPLE,
}

export const INITIAL_INPUT_VALUE = {
  value: '',
  isValid: false,
}

export const enum FirebaseSignUpErrorCode {
  EMAIL_EXISTS = 'auth/email-already-in-use',
}

export const enum Color {
  PRIMARY = '#6fd09a',
  SECONDARY = '#e8ffee',
  LIGHT_GRAY = '#c4c4c4',
  SUCCESS = '#7ad718',
  ERROR = '#ff6169',
  WHITE = '#fff',
  GREY_WHITE = '#e9ecee',
  BLACK = '#17202a',
  BLACK_100 = '#000',
  BLACK_200 = '#2d3240',
  LIGHT = '#f5f6f7',
  LIGHT_BLUE = '#edeff0',
  DANGER = '#ff8484',
  DISABLED = '#898e97',
  ORANGE = '#f08b34',
  ORANGE_100 = '#eaa300',
  ORANGE_200 = '#ff9900',
  VERY_LIGHT_BLUE = '#F7F9FC',
  DARK_BLUE = '#131D30',
  PINK = '#e0698c',
  PALE_RED = '#fff2f2',
  ORANGE_LIGHT = '#FFF8F2',
  BLUE = '#978FFF',
  GREY = '#D5D6D6',
  GREY_100 = '#2c3131',
  GREY_101 = '#626262',
  GREY_102 = '#a9a9a9',
  GREY_103 = '#d1f0df',
  GREY_110 = '#647069',
  GREY_120 = '#f1f5f9',
  GREY_130 = '#f2f3f5',
  GREY_200 = '#f1f1f3',
  GREY_300 = '#d1d5db',
  GREY_400 = '#e5e7eb',
  GREY_500 = '#b8bbc1',
  GREY_600 = '#81848c',
  GREY_700 = '#fafaff',
  GREY_800 = '#e9e9e9',
  GREY_900 = '#efeff6',
  GREY_1000 = '#cacaca',
  GREY_LIGHT = '#f5f5f5',
  GREEN_100 = '#F1FFF8',
  GREEN_200 = '#D1FAE5',
  GREEN_300 = '#98bf36',
  GREEN_400 = '#a9cd50',
  RED_100 = '#BC2222',
  RED_200 = '#ff4329',
  RED_300 = '#f83b00',
  RED_400 = '#e74c3c',
  BEIGE_100 = '#fbf6ee',
}

export const enum Emojis {
  BICEPS = '\uD83D\uDCAA',
  SOUP = '\uD83C\uDF72',
  SUPPORT = '\uD83E\uDEC2',
  CHART = '\uD83D\uDCC8',
  FINGER_DOWN_POINTER = '\uD83D\uDC47',
  FINGER_UP_POINTER = '\u261d\ufe0f',
  STAR_EYES = '\uD83E\uDD29',
  STAR = '\u2b50',
  WAVING_HAND = '\uD83D\uDC4B',
  SMILING_FACE_WITH_3_HEARTS = '\uD83E\uDD70',
  OK_HAND = '\uD83D\uDC4C',
  THUMBS_UP = '\uD83D\uDC4D',
}

export const enum TimeInterval {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

export const enum UpsellProduct {
  BOTH = 'pdf_FitnessAndSelfcare',
  FITNESS = 'pdf_fitness',
  SELF_CARE = 'pdf_selfcare',
}

export const enum SubscriptionDuration {
  SEVEN_DAYS = '7day',
  ONE_MONTH = '1month',
  THREE_MONTHS = '3month',
}

export const enum EventLoggerInstanceName {
  GIA = 'gia-amplitude',
  AMPLITUDE = 'amplitude',
  USER_FLOW_TRACKER = 'user-flow-tracker',
}

export const enum OutbrainEventName {
  PAGE_VIEW = 'PAGE_VIEW',
  ADD_TO_CART = 'Add To Cart',
  CHECKOUT = 'Checkout',
  PURCHASE = 'Purchase',
  COMPLETE_REGISTRATION = 'Lead',
}

export const enum TaboolaEventName {
  PAGE_VIEW = 'page_view',
  ADD_TO_CART = 'add_to_cart',
  CHECKOUT = 'start_checkout',
  PURCHASE = 'make_purchase',
  COMPLETE_REGISTRATION = 'complete_registration',
}

export const enum PlatformOS {
  ANDROID = 'Android',
  IOS = 'iOS',
  UNKNOWN = 'unknown',
}

export const enum AppWithInAppBrowser {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
}

export const enum Cohort {
  MF_INTRO = 'mf_intro',
  MF_INTRO_2 = 'mf_intro2',
  MF_FLOW_2 = 'mf_flow2',
  MF_MEN_FLOW = 'mf_menflow',
  MF_WOMEN_AGE = 'mf_womenage',
  MF_WOMEN_AGE_2 = 'mf_womenage2',
  MF_WOMEN_45 = 'mf_women_45',
  MF_FLOW_2_INTRO_2 = 'mf_flow2intro2',
  MF_EMAIL_FLOW = 'mf_email_flow',
  MF_EMAIL_FLOW_2 = 'mf_email_flow2',
  MF_EMAIL_FLOW_3 = 'mf_email_flow3',
  MF_INFLUENCER = 'mf_influencer',
}

export const MEN_COHORTS = [Cohort.MF_MEN_FLOW]

export const WOMEN_COHORTS = [
  Cohort.MF_WOMEN_AGE,
  Cohort.MF_WOMEN_AGE_2,
  Cohort.MF_WOMEN_45,
]

export const enum PagePath {
  LOCALHOST = 'localhost',
  CHECKOUT = 'checkout',
}

export const enum Gender {
  MALE = 'male',
  FEMALE = 'female',
}

export const enum SubscriptionListType {
  PURCHASE = 'purchase',
  UPSELL = 'upsell',
  ONE_TIME = 'one_time',
}

export const enum SubscriptionTags {
  TAX = 'tax',
  NO_TAX = 'no_tax',
  CANCEL_OFFER = 'cancel',
  LOWER_PRICES = 'low',
  UPSELL_LONG = 'upsell_long',
}

export const APP_NAME = 'NutriMate'
export const API_APP_NAME = 'myfast'
export const CENTS_IN_DOLLAR = 100
export const INITIAL_PRICE = 0
export const CUSTOM_TOKEN_LOCAL_STORAGE_KEY = 'custom_token'
export const DEFAULT_CURRENCY = 'usd'
export const PAGE_TRANSITION_DELAY = 500
export const TEST_ENV_QUERY_PARAM = 'is_test_environment'
export const NO_GROWTH_BOOK_TEST = 'no_test'
export const EXPERIMENT_ID_NOT_SET = 'experiment_id_not_set'
export const INCHES_IN_FOOT = 12
export const INITIAL_INCHES_VALUE = 0
export const MAX_HEIGHT_INCHES = 94
export const MAXIMUM_WEIGHT_LOSS_PERCENTAGE = 0.00143
export const DYNAMIC_PAGE_ID_PREFIX = 'dynamic'

export const UPSELL_PAYWALL_PAGES = [
  PageId.UPSELL_PAYWALL_1,
  PageId.UPSELL_PAYWALL_2,
]

export const POST_PAYMENT_PAGES = [
  ...UPSELL_PAYWALL_PAGES,
  PageId.FINISHING_TOUCHES,
  PageId.LOGIN,
  PageId.DOWNLOAD,
]

export const enum MeasurementSystem {
  METRIC = 'metric',
  IMPERIAL = 'imperial',
}

export const WEIGHT_UNITS: Record<MeasurementSystem, string> = {
  [MeasurementSystem.METRIC]: 'kg',
  [MeasurementSystem.IMPERIAL]: 'lbs',
}

export const HEIGHT_UNITS: Record<MeasurementSystem, string | string[]> = {
  [MeasurementSystem.METRIC]: 'cm',
  [MeasurementSystem.IMPERIAL]: ['ft', 'in'],
}

export const COUNTRIES_WITH_IMPERIAL_MEASUREMENT_SYSTEM = [
  'AU',
  'CA',
  'GB',
  'NZ',
  'US',
  'ZA',
]

export const enum ScreenName {
  ONBOARDING = 'onboarding',
  CANCEL_OFFER = 'cancel_offer',
  CANCEL_OFFER_MEALPLAN = 'cancel_offer_mealplan',
  EMAIL_OFFER = 'email_offer',
  UPSALE_SCREEN = 'upsale_screen',
  UPSELL_SWITCHER = 'upsell_switcher',
  CHECKOUT = 'checkout',
  UPSELL_COACHING = 'upsell_coaching',
  UPSELL_COACHING_CANCEL = 'upsell_coaching_cancel',
}

export const CENTIMETERS_IN_METER = 100
export const IMPERIAL_BMI_CONSTANT = 703

export const LOWER_BMI_THRESHOLD_V1 = 10
export const LOWER_BMI_THRESHOLD_V2 = 18.5
export const UPPER_BMI_THRESHOLD = 45

export const enum PlanAddition {
  MEAL_PLAN = 'Meal plan',
  PREMIUM = 'Premium',
  UPSELL = 'Upsell',
}

export const IN_APP_PRODUCT_EVENT_NAMES_MAP = {
  [UpsellProduct.BOTH]: 'guide_bundle',
  [UpsellProduct.FITNESS]: 'dream_body_guide',
  [UpsellProduct.SELF_CARE]: 'self_care_guide',
}

export const enum Language {
  EN = 'en',
  ES = 'es',
}

export const SUPPORTED_LOCALES: string[] = [Language.EN, Language.ES]

export const LANG_QUERY_PARAM = 'lang'
export const INITIAL_LANGUAGE = Language.EN
export const DATEPICKER_SUPPORTED_LOCALES = {
  [Language.EN]: en,
  [Language.ES]: es,
}

export const EMPTY_OLD_PRICE = {
  fullPrice: 0,
  amountOfDiscount: 0,
  weekly: 0,
  daily: 0,
  monthly: 0,
  yearly: 0,
  percentOfDiscount: 0,
}

export const VAT_INCLUDED_COUNTRIES = ['US', 'MX', 'ES']

export const VAT_INCLUDED_COHORTS = [
  Cohort.MF_INTRO,
  Cohort.MF_EMAIL_FLOW,
  Cohort.MF_INTRO_2,
  Cohort.MF_INFLUENCER,
  Cohort.MF_WOMEN_AGE,
  Cohort.MF_WOMEN_AGE_2,
  Cohort.MF_WOMEN_45,
  Cohort.MF_FLOW_2_INTRO_2,
  Cohort.MF_MEN_FLOW,
  Cohort.MF_WOMEN_45,
]

export const UPSELL_INCLUDED_COHORTS = [
  Cohort.MF_INTRO,
  Cohort.MF_INTRO_2,
  Cohort.MF_INFLUENCER,
  Cohort.MF_FLOW_2,
  Cohort.MF_WOMEN_AGE,
  Cohort.MF_WOMEN_AGE_2,
  Cohort.MF_WOMEN_45,
  Cohort.MF_FLOW_2_INTRO_2,
]

export const COHORTS_BASED_ON_FLOW2 = [
  Cohort.MF_FLOW_2,
  Cohort.MF_FLOW_2_INTRO_2,
  Cohort.MF_MEN_FLOW,
]

export const PUBLIC_PAGES = [
  PageId.ENTER_EMAIL_1,
  PageId.ENTER_EMAIL_2,
  PageId.KIT_ENTER_EMAIL_1,
  PageId.EMAIL_CONSENT_1,
  PageId.KIT_EMAIL_CONSENT_1,
  PageId.RESULT_1,
  PageId.CREATING_PLAN_1,
  PageId.CREATING_PLAN_3,
  PageId.PRESALE_1,
  PageId.PRESALE_2,
  PageId.KIT_PRESALE_1,
]

export const CHECKBOX_CUSTOM_VALUES = [CheckboxCustomValue.NONE as string]

export const PERCENTAGE_MULTIPLIER = 100

export const TARGET_AREA_FULL_LIST = [
  BodyPartValue.ARMS,
  BodyPartValue.CHEST,
  BodyPartValue.BELLY,
  BodyPartValue.HIPS,
  BodyPartValue.LEGS,
]

export const enum Position {
  LEFT = 'left',
  RIGHT = 'right',
}

// TODO: delete after kit-header refactoring
export const ONBOARDING_PAGE_BREAKPOINTS = {
  [Cohort.MF_INTRO_2]: [
    PageId.KIT_GENDER_1,
    PageId.KIT_DYNAMIC_FEELINGS_TO_ACHIEVE_1,
    PageId.KIT_DYNAMIC_FASTING_1,
    PageId.KIT_CREATING_PLAN_1,
  ],
}

export const ONBOARDING_START = 0
export const ONBOARDING_END = 3

export const enum AppLinks {
  TOU_LINK = `/${PageId.TERMS_OF_USE}`,
  PP_LINK = `/${PageId.PRIVACY_POLICY}`,
}

export const TOU_AND_PP_LINKS: string[] = [AppLinks.TOU_LINK, AppLinks.PP_LINK]

export const WEEK_DAYS = 7
