import React, { SyntheticEvent, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import lottie from 'lottie-web/build/player/lottie_light'

import {
  selectCurrentUserGender,
  selectCurrentVariantCohortToUse,
} from 'root-redux/selects/common'

import { useTargetHiddenOnScroll } from 'hooks/useTargetHiddenOnScroll'

import {
  MEN_ANIMATION_PATH_V2,
  MEN_ANIMATION_PATH_V3,
  WOMEN_ANIMATION_PATH_V2,
} from 'modules/purchase/components/IntroMedia/constants'
import { COHORTS_WITH_VIOLET_HEADER } from 'modules/purchase/components/IntroMediaV2/constants'
import { useDynamicPaywallConfig } from 'modules/purchase/hooks/useDynamicPaywallConfig'

import { CDN_FOLDER_LINK, Cohort, Color, Gender } from 'root-constants'

import { StyledIntroMediaV2 as S } from './IntroMediaV2.styles'

type TProps = {
  hasDiscountBanner?: boolean
  elemForComparisonRef: React.RefObject<HTMLHeadingElement>
  onButtonClick: (event: SyntheticEvent<HTMLButtonElement>) => void
}

export const IntroMediaV2: React.FC<TProps> = ({
  elemForComparisonRef,
  hasDiscountBanner = false,
  onButtonClick,
}) => {
  const { t } = useTranslation()
  const animationRef = useRef<HTMLDivElement>(null)

  const gender = useSelector(selectCurrentUserGender)
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const isTargetHidden = useTargetHiddenOnScroll(elemForComparisonRef)
  const { buttonText } = useDynamicPaywallConfig()

  const isMale = useMemo(() => gender === Gender.MALE, [gender])

  const animationPath = useMemo(
    () =>
      isMale
        ? `${CDN_FOLDER_LINK}${
            cohortToUse === Cohort.MF_MEN_FLOW
              ? MEN_ANIMATION_PATH_V3
              : MEN_ANIMATION_PATH_V2
          }`
        : `${CDN_FOLDER_LINK}${WOMEN_ANIMATION_PATH_V2}`,
    [isMale, cohortToUse],
  )

  const isVioletTimerInHeader = useMemo(
    () => COHORTS_WITH_VIOLET_HEADER.includes(cohortToUse),
    [cohortToUse],
  )

  const timerContainerBgColor = useMemo(() => {
    if (isTargetHidden) {
      if (hasDiscountBanner) {
        return Color.ORANGE_100
      }

      if (isVioletTimerInHeader) {
        return `linear-gradient(91.11deg, rgb(216, 212, 250) 0%, rgb(187, 180, 244) 100%), rgb(111, 208, 154)`
      }

      return Color.PRIMARY
    }
    return Color.WHITE
  }, [isTargetHidden, isVioletTimerInHeader, hasDiscountBanner])

  useEffect(() => {
    if (animationRef.current) {
      lottie.loadAnimation({
        container: animationRef.current,
        path: animationPath,
        loop: true,
      })
    }
  }, [animationPath])

  return (
    <S.Wrapper>
      <S.BeforeAfter ref={animationRef} />
      <S.TimerContainer
        bgColor={timerContainerBgColor}
        isTimerHidden={isTargetHidden}
      >
        {isTargetHidden && (
          <S.TimerLabel>{t('purchase.subheaderText')}</S.TimerLabel>
        )}
        <S.Timer isHeaderTimer={isTargetHidden} hasIndent hasText />
        <S.Button
          isTimerHidden={isTargetHidden}
          color={Color.PRIMARY}
          onClick={onButtonClick}
        >
          {buttonText || t('actions.getMyPlan')}
        </S.Button>
      </S.TimerContainer>
    </S.Wrapper>
  )
}
