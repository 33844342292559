export const QUESTION =
  'In the past, have you tried any of these to help you lose weight?'

export const PAST_TRIES_VALUES = {
  RESTRICTIVE: 'restrictive',
  GYM: 'gym',
  PAID_MEAL: 'paid_meal',
  NONE: 'none',
}

export const ANSWER_OPTIONS = [
  {
    value: PAST_TRIES_VALUES.RESTRICTIVE,
    title: 'onboarding.pastTries.options.restrictive',
  },
  {
    value: PAST_TRIES_VALUES.GYM,
    title: 'onboarding.pastTries.options.gym',
  },
  {
    value: PAST_TRIES_VALUES.PAID_MEAL,
    title: 'onboarding.pastTries.options.paidMeal',
  },
]
