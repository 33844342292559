import React, { ReactNode, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { usePayPalScriptReducer } from '@paypal/react-paypal-js'

import { TAppDispatch } from 'root-redux/store/store'

import { setIsPayPalButtonLoadingAction } from 'modules/purchase/redux/actions/common'

export const ButtonWrapper = ({ children }: { children: ReactNode }) => {
  const [{ isPending: isPaypalButtonLoading }] = usePayPalScriptReducer()

  const dispatch: TAppDispatch = useDispatch()

  useEffect(() => {
    dispatch(setIsPayPalButtonLoadingAction(isPaypalButtonLoading))

    return () => {
      dispatch(setIsPayPalButtonLoadingAction(false))
    }
  }, [dispatch, isPaypalButtonLoading])

  return <div>{children}</div>
}
