import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledCustomerReviewsV2 = {
  Wrapper: styled.div`
    padding-bottom: 32px;
  `,
  Title: styled.h2`
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 16px;
    text-align: center;
  `,
  ReviewContainer: styled.div`
    background-color: ${Color.WHITE};
    border-radius: 16px;
    box-shadow: 0 8px 20px rgba(17, 30, 23, 0.1);
    margin-bottom: 16px;
    padding: 16px;

    &:last-of-type {
      margin-bottom: 24px;
    }
  `,
  ReviewHeader: styled.div`
    display: grid;
    grid-template-columns: 3fr 1fr;
  `,
  Rating: styled.div`
    aspect-ratio: 49 / 9;
    display: flex;
    height: 18px;
    justify-content: space-between;
    margin-bottom: 8px;
  `,
  ReviewTitle: styled.span`
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    padding-bottom: 4px;
    text-align: left;
  `,
  Date: styled.span`
    color: ${Color.DISABLED};
    font-size: 14px;
    font-weight: 400;
    text-align: right;
  `,
  Author: styled.span`
    color: ${Color.DISABLED};
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    text-align: right;
  `,
  TextContent: styled.div`
    display: flex;
  `,
  TextContainer: styled.div`
    overflow: hidden;
  `,
  Text: styled.p`
    color: ${Color.DISABLED};
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  `,
  Button: styled.button`
    background-color: transparent;
    border: none;
    color: ${Color.PRIMARY};
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    margin-left: 4px;
    outline: none;
    padding: 0;
  `,
}
