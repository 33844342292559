import styled from 'styled-components'

import { UserInfoBlockV3 } from 'components/UserInfoBlock'

import { Color } from 'root-constants'

type TProps = {
  backgroundColor: string
  color: string
}

export const StyledProfileSummaryV3 = {
  UserInfoBlockV3: styled(UserInfoBlockV3)`
    margin-bottom: 8px;
  `,
  Info: styled.ul`
    background-color: rgba(169, 205, 80, 0.2);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 8px;
    padding: 16px;
    position: relative;
  `,
  InfoItem: styled.li`
    align-items: center;
    display: flex;
    column-gap: 6px;
  `,
  InfoLabel: styled.span`
    color: ${Color.GREEN_300};
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 16px;
  `,
  InfoValue: styled.span`
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 16px;
  `,
  ImageContainer: styled.div`
    aspect-ratio: 101 / 113;
    bottom: 0;
    display: flex;
    height: 113px;
    position: absolute;
    right: 0;
  `,
  InfoV2: styled.div`
    display: flex;
    justify-content: space-between;
    min-height: 144px;
    background-color: rgba(169, 205, 80, 0.2);
    border-radius: 16px;
    padding: 16px;
    margin-bottom: 8px;
    position: relative;
  `,
  InfoList: styled.ul`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    column-gap: 8px;
    max-width: 207px;
  `,
  Banner: styled.img`
    height: 154px;
    position: absolute;
    bottom: 0;
    right: -10px;
  `,

  ImageContainerV2: styled.div`
    aspect-ratio: 101 / 113;
    height: 113px;
  `,
  InfoItemV2: styled.li`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &:not(:last-of-type) {
      margin: 0 0 8px 0;
    }
  `,

  BmiDescription: styled.div<TProps>`
    align-items: center;
    background-color: ${({ backgroundColor }) => backgroundColor};
    border-radius: 16px;
    color: ${({ color }) => color};
    column-gap: 16px;
    display: flex;
    padding: 16px;
  `,
  BmiValueContainer: styled.div`
    display: flex;
    flex-direction: column;
  `,
  BmiTitle: styled.span`
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 16px;
    white-space: nowrap;
  `,
  BmiValue: styled.strong`
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  `,
  Description: styled.p`
    color: ${Color.GREY_100};
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;

    strong {
      font-weight: 700;
    }
  `,
}
