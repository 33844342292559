import athleticFemale from 'assets/images/athletic-2-female.png'
import athleticMale from 'assets/images/athletic-2-male.png'
import curvyFemale from 'assets/images/curvy-female.png'
import curvyMale from 'assets/images/curvy-male.png'
import slimFemale from 'assets/images/slim-female.png'
import slimMale from 'assets/images/slim-male.png'

import { Gender } from 'root-constants'

export const QUESTION = 'What is your “dream body”?'

export const OPTION_VALUES = {
  FIT: 'fit',
  ATHLETIC: 'athletic',
  CURVY: 'curvy',
  SWOLE: 'swole',
}

export const OPTION_IMAGES = {
  [Gender.MALE]: {
    FIT: slimMale,
    ATHLETIC: athleticMale,
    CURVY: curvyMale,
  },
  [Gender.FEMALE]: {
    FIT: slimFemale,
    ATHLETIC: athleticFemale,
    CURVY: curvyFemale,
  },
}
