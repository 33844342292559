import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledUserInfoBlockV1 = {
  Container: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: ${Color.WHITE};
    box-shadow: 0 12px 32px 0 #0000001f;
    border-radius: 16px;
  `,
  InfoList: styled.ul`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 194px;
    width: 180px;
    padding: 15px 2px 15px 16px;
  `,
  InfoItem: styled.li<{ bulletSrc: string }>`
    height: 52px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 0 0 40px;
    position: relative;

    &:before {
      position: absolute;
      content: url(${({ bulletSrc }) => bulletSrc});
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 32px;
      height: 32px;
    }
  `,
  Title: styled.h3`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${Color.DISABLED};
  `,
  Info: styled.p`
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: ${Color.GREY_100};
  `,
  ImageContainer: styled.div`
    position: relative;
    width: 144px;

    img {
      height: 210px;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  `,
}

export const StyledUserInfoBlockV2 = {
  Container: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
  `,
  InfoList: styled.ul`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 172px;
    width: 168px;
    padding: 0 2px 16px 0;
  `,
  InfoItem: styled.li<{ bulletSrc: string }>`
    height: 36px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 0 0 32px;
    position: relative;

    &:before {
      position: absolute;
      content: url(${({ bulletSrc }) => bulletSrc});
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
    }
  `,
  Title: styled.h3`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: ${Color.DISABLED};
  `,
  Info: styled.p`
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: ${Color.GREY_100};
  `,
  ImageContainer: styled.div`
    position: relative;
    width: 120px;

    img {
      height: 172px;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  `,
}
