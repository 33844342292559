import React, { FC, useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { FlagContainer } from 'components/FlagContainer'

import {
  CURRENCY_SYMBOLS,
  Currency,
  PurchaseProduct,
  WEEKS_BY_PRODUCT_NAME_MAP,
} from 'modules/purchase/constants'
import { TSubscriptionItemProps } from 'modules/purchase/types'

import { Color } from 'root-constants'

import { StyledKitSubscriptionItemV1 as S } from './KitSubscriptionItemV1.styles'
import { StyledMxnKitSubscriptionItemV1 as M } from './MxnKitSubscriptionItemV1.styles'

export const KitSubscriptionItemV1Trial: FC<TSubscriptionItemProps> = ({
  subscription: { id, product, mainPrices, trialPrices, isDefault, currency },
  isSelected,
}) => {
  const { t } = useTranslation()

  const oldPriceWithFractionalPart = useMemo(
    () =>
      product === PurchaseProduct.SEVEN_DAY
        ? trialPrices.oldPrices.beforeCustomDiscount?.fullPrice.toFixed(2)
        : mainPrices.fullPrice.toFixed(2),
    [product, mainPrices, trialPrices],
  )

  const trialCurrentPriceWithFractionalPart = useMemo(
    () => trialPrices.fullPrice.toFixed(2),
    [trialPrices],
  )

  const pricePerDayWithFractionalPart = useMemo(
    () =>
      product === PurchaseProduct.SEVEN_DAY
        ? mainPrices.daily.toFixed(2)
        : trialPrices.daily.toFixed(2),
    [product, trialPrices, mainPrices],
  )

  const oldPricePerDayWithFractionalPart = useMemo(
    () => trialPrices.oldPrices.beforeCustomDiscount?.daily.toFixed(2),
    [trialPrices],
  )

  const period = useMemo(() => WEEKS_BY_PRODUCT_NAME_MAP[product], [product])

  const isMexican = useMemo(() => currency === Currency.MXN, [currency])

  const getFormattedPrice = useCallback(
    (price?: string) =>
      isMexican ? (
        <>
          {price} {CURRENCY_SYMBOLS[currency]}
        </>
      ) : (
        <>
          {CURRENCY_SYMBOLS[currency]}
          {price}
        </>
      ),
    [currency, isMexican],
  )

  return !isMexican ? (
    <S.PlanContainer key={id} isSelected={isSelected}>
      <S.PlanColumn>
        <S.PlanPeriod isSelected={isSelected}>
          <Trans
            i18nKey="purchase.subscriptionV2.weeklyPeriod"
            values={{ period }}
          />
        </S.PlanPeriod>
        <S.PlanPrice>
          <S.PreviousPrice>
            {getFormattedPrice(oldPriceWithFractionalPart)}
          </S.PreviousPrice>
          <S.CurrentPrice>
            {getFormattedPrice(trialCurrentPriceWithFractionalPart)}
          </S.CurrentPrice>
          <S.PreviousDayPrice>
            {getFormattedPrice(oldPricePerDayWithFractionalPart)}
          </S.PreviousDayPrice>
        </S.PlanPrice>
      </S.PlanColumn>
      <S.PlanColumn>
        <FlagContainer
          backgroundColor={isSelected ? Color.PRIMARY : Color.GREY_LIGHT}
        >
          <S.DayPriceContainer isSelected={isSelected}>
            <S.CurrentDayPrice>
              {getFormattedPrice(pricePerDayWithFractionalPart)}
            </S.CurrentDayPrice>
            <S.DayPricePeriod>
              {t('purchase.subscriptionV2.perDay')}
            </S.DayPricePeriod>
          </S.DayPriceContainer>
        </FlagContainer>
      </S.PlanColumn>
      {isDefault && (
        <S.PopularBadge isSelected={isSelected}>
          {t('purchase.subscriptionV2.mostPopularBadge')}
        </S.PopularBadge>
      )}
    </S.PlanContainer>
  ) : (
    <M.PlanContainer key={id} isSelected={isSelected}>
      <M.PlanColumn>
        <M.PlanPeriod isSelected={isSelected}>
          <Trans
            i18nKey="purchase.subscriptionV2.weeklyPeriod"
            values={{ period }}
          />
        </M.PlanPeriod>
        <M.PlanPrice>
          <M.PreviousPrice>
            {getFormattedPrice(oldPriceWithFractionalPart)}
          </M.PreviousPrice>
          <M.CurrentPrice>
            {getFormattedPrice(trialCurrentPriceWithFractionalPart)}
          </M.CurrentPrice>
        </M.PlanPrice>
      </M.PlanColumn>
      <M.PlanColumn>
        <FlagContainer
          backgroundColor={isSelected ? Color.PRIMARY : Color.GREY_LIGHT}
        >
          <M.DayPriceContainer isSelected={isSelected}>
            <M.PreviousDayPrice isSelected={isSelected}>
              {getFormattedPrice(oldPricePerDayWithFractionalPart)}
            </M.PreviousDayPrice>
            <M.CurrentDayPrice>
              {getFormattedPrice(pricePerDayWithFractionalPart)}
            </M.CurrentDayPrice>
            <M.DayPricePeriod>
              {t('purchase.subscriptionV2.perDay')}
            </M.DayPricePeriod>
          </M.DayPriceContainer>
        </FlagContainer>
      </M.PlanColumn>
      {isDefault && (
        <M.PopularBadge isSelected={isSelected}>
          {t('purchase.subscriptionV2.mostPopularBadge')}
        </M.PopularBadge>
      )}
    </M.PlanContainer>
  )
}
