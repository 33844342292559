import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { Logo } from 'components/Logo'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledYouAreNotAlone as S } from './YouAreNotAlone.styles'
import { QUESTION } from './constants'

export const YouAreNotAlone: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  return (
    <>
      <S.ImageContainer>
        <Logo marginBottom={28} />
      </S.ImageContainer>

      <Container>
        <S.Title>{t('onboarding.youAreNotAlone.title')}</S.Title>
        <S.Subtitle>
          <Trans i18nKey="onboarding.youAreNotAlone.subTitle" />
        </S.Subtitle>
        <S.Description>
          <Trans i18nKey="onboarding.youAreNotAlone.description" />
        </S.Description>
        <StickyButtonContainer>
          <Button type="button" onClick={handleNextClick}>
            {t('actions.continue')}
          </Button>
        </StickyButtonContainer>
      </Container>
    </>
  )
}
