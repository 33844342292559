import { PeriodValue } from 'value-constants'

export const PERIOD_DATA_MAP = [
  {
    value: PeriodValue.MENSTRUATION,
    title: 'onboarding.period.options.menstruation.title',
    subtitle: 'onboarding.period.options.menstruation.subtitle',
  },
  {
    value: PeriodValue.PERIMENOPAUSE_MENOPAUSEE,
    title: 'onboarding.period.options.menopause.title',
    subtitle: 'onboarding.period.options.menopause.subtitle',
  },
]
