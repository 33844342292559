import styled from 'styled-components'

import { PageTitle } from 'components/PageTitle'

import tailIcon from 'assets/images/tail-icon.svg'

import { Color } from 'root-constants'

export const StyledStatementQuestionComponent = {
  PageTitle: styled(PageTitle)`
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
  `,
  QuestionContainer: styled.div`
    margin-bottom: 24px;
    min-height: 208px;
  `,
  Question: styled.h2`
    background-color: ${Color.GREY_900};
    border-radius: 32px;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    padding: 24px;
    position: relative;

    &:after {
      background-image: url(${tailIcon});
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      bottom: 0;
      content: '';
      display: block;
      height: 32px;
      left: -5px;
      position: absolute;
      width: 26px;
    }
  `,
  AnswersContainer: styled.div`
    column-gap: 12px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    margin-bottom: 12px;
  `,
  Scale: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  ScaleItem: styled.span`
    color: ${Color.GREY_101};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  `,
}
