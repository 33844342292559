import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { RadioOptionsPage } from 'pages/options-page'
import { MenuValue } from 'value-constants'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { TPageProps } from 'models/common.model'

import confusingFaceIcon from 'assets/images/confusing-face.png'
import dailyMenuBanner from 'assets/images/daily-menu.png'
import manTippingHandIcon from 'assets/images/man-tipping-hand.png'
import okIcon from 'assets/images/ok-fingers.png'
import womanTippingHandIcon from 'assets/images/woman-tipping-hand.png'

import { Gender } from 'root-constants'

import { StyledMenu as S } from './Menu.styles'
import { TRANSLATION_FAMILY } from './constants'

export const MenuV1: React.FC<TPageProps> = (props) => {
  const gender = useSelector(selectCurrentUserGender)

  const options = useMemo(() => {
    const isFemale = gender === Gender.FEMALE

    return [
      {
        key: 'yes',
        value: MenuValue.YES_IT_DOES,
        iconSrc: okIcon,
      },
      {
        key: 'somewhat',
        value: MenuValue.SOMEWHAT,
        iconSrc: isFemale ? womanTippingHandIcon : manTippingHandIcon,
      },
      {
        key: 'not',
        value: MenuValue.NOT_AT_ALL,
        iconSrc: confusingFaceIcon,
      },
    ]
  }, [gender])

  return (
    <RadioOptionsPage
      {...props}
      banner={
        <S.Banner>
          <img src={dailyMenuBanner} alt="Daily menu" />
        </S.Banner>
      }
      options={options}
      translationFamily={TRANSLATION_FAMILY}
    />
  )
}
