import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledWhatYouGetV2 = {
  Container: styled.div`
    margin-bottom: 40px;
    width: 100%;
  `,
  Title: styled.h2`
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 24px;
    text-align: center;

    strong {
      color: ${Color.PRIMARY};
    }
  `,
  List: styled.ul`
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  `,
  Item: styled.li<{ background: string }>`
    background: ${({ background }) => background};
    border-radius: 16px;
    overflow: hidden;
  `,
  ItemContent: styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px 24px 16px;
    row-gap: 8px;
  `,
  ItemTitle: styled.h3`
    color: ${Color.BLACK};
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  `,
  ItemInfo: styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  `,
  ImageContainer: styled.div`
    aspect-ratio: 41 / 40;
    width: 100%;
  `,
}
