import React from 'react'

import logoPng from 'assets/images/logo.png'

import { APP_NAME } from 'root-constants'

import { StyledLogo as S } from './Logo.styles'

export type TLogoProps = {
  marginBottom?: number
  className?: string
}

export const Logo: React.FC<TLogoProps> = ({
  marginBottom = 32,
  className,
}) => (
  <S.Wrapper className={className} marginBottom={marginBottom}>
    <S.LogoContainer>
      <S.Logo>
        <img src={logoPng} alt={APP_NAME} />
      </S.Logo>
      <S.LogoText>{APP_NAME}</S.LogoText>
    </S.LogoContainer>
  </S.Wrapper>
)
