import React from 'react'
import { useTranslation } from 'react-i18next'

import { StyledBMIInfoCard as S } from './BMIInfoCard.styles'

type TProps = {
  color: string
  title: string
  info: string
  icon: string
  className?: string
}
export const BMIInfoCard: React.FC<TProps> = ({
  className,
  color,
  title,
  info,
  icon,
}) => {
  const { t } = useTranslation()

  return (
    <S.Card className={className} color={color} icon={icon}>
      <S.Title>{t(title)}</S.Title>
      <S.Info>{t(info)}</S.Info>
    </S.Card>
  )
}
