export const PERIODS = [
  'onboarding.fastingFeedbackV1.period.fasting',
  'onboarding.fastingFeedbackV1.period.eating',
]

export const BENEFITS = [
  'onboarding.fastingFeedbackV1.benefits.items.weight',
  'onboarding.fastingFeedbackV1.benefits.items.diabetes',
  'onboarding.fastingFeedbackV1.benefits.items.heart',
  'onboarding.fastingFeedbackV1.benefits.items.cancer',
  'onboarding.fastingFeedbackV1.benefits.items.longevity',
]

export const IMAGE_PATH = '/images/fasting-feedback-v2'
