export const UPSELL_BENEFITS = [
  {
    id: '1',
    description: 'upsell.benefits.option1',
  },
  {
    id: '2',
    description: 'upsell.benefits.option2',
  },
  {
    id: '3',
    description: 'upsell.benefits.option3',
  },
  {
    id: '4',
    description: 'upsell.benefits.option4',
  },
  {
    id: '5',
    description: 'upsell.benefits.option5',
  },
  {
    id: '6',
    description: 'upsell.benefits.option6',
  },
]

export const IMAGE_PATH_DREAM_BODY = '/images/dream-body'
export const IMAGE_PATH_SELF_CARE = '/images/self-care'
