import React from 'react'
import { useTranslation } from 'react-i18next'

import { StyledUpsellFeedbacks as S } from './UpsellFeedbacks.styles'
import { UPSELL_FEEDBACKS_MAP } from './constants'

export const UpsellFeedbacksV1 = () => {
  const { t } = useTranslation()

  return (
    <S.Container>
      <S.Title>{t('purchase.upsellFeedbacksV1.title')}</S.Title>

      <S.List>
        {UPSELL_FEEDBACKS_MAP.map(({ title, info, about, emoji }) => (
          <S.Item key={title}>
            <S.ItemTitle>{t(title, { emoji })}</S.ItemTitle>
            <S.ItemInfo>{t(info)}</S.ItemInfo>
            <S.ItemAbout>{t(about)}</S.ItemAbout>
          </S.Item>
        ))}
      </S.List>
    </S.Container>
  )
}
