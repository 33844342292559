import styled from 'styled-components'

import pageBg from 'assets/images/food-bg.png'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledContributingEventsV2 = {
  ImageContainer: styled.div`
    aspect-ratio: 460/358;
    background-image: url(${pageBg});
    background-repeat: no-repeat;
    background-size: contain;
    margin: 0 auto;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    padding-top: 10px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
    }
  `,
  Title: styled.h1`
    color: ${Color.WHITE};
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    padding-left: 24px;
    width: 200px;
  `,
  FeedbackTitle: styled.h2`
    color: ${Color.GREY_100};
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;

    strong {
      font-weight: 700;

      :last-of-type {
        color: ${Color.PRIMARY};
      }
    }
  `,
  DateSubTitle: styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    padding-bottom: 16px;
    text-align: center;
  `,
  DateTitle: styled.h2`
    color: ${Color.GREY_100};
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 8px;
    text-align: center;

    strong {
      color: ${Color.PRIMARY};
    }
  `,
}
