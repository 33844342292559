import React from 'react'

import { StyledAnswerButton as S } from './AnswerButton.styles'

export const enum IconVerticalAlignments {
  CENTER = 'center',
  BOTTOM = 'flex-end',
  TOP = 'top',
  DEFAULT = 'stretch',
}

type TProps = {
  iconSrc?: string
  children:
    | React.ReactElement<HTMLHeadingElement>
    | React.ReactElement<HTMLParagraphElement>
  iconWidth?: number
  iconHeight?: number
  iconVerticalAlignment?: IconVerticalAlignments
  className?: string
}

export const AnswerButton: React.FC<TProps> = ({
  iconSrc,
  children,
  iconWidth = 56,
  iconHeight = 56,
  iconVerticalAlignment = IconVerticalAlignments.CENTER,
  className,
}) => (
  <S.Wrapper className={className}>
    {iconSrc && (
      <S.IconContainer>
        <S.Icon
          src={iconSrc}
          style={{
            width: iconWidth,
            height: iconHeight,
            alignSelf: iconVerticalAlignment,
          }}
          alt="icon"
        />
      </S.IconContainer>
    )}
    <S.Content>{children}</S.Content>
  </S.Wrapper>
)
