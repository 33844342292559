export const LIST_ITEMS_V1 = [
  { text: 'purchase.shredPounds' },
  { text: 'purchase.forgetAboutDiet' },
  { text: 'purchase.learnNewPatterns' },
  { text: 'purchase.getToned' },
  { text: 'purchase.newHabits' },
  { text: 'purchase.dealFoodCravings' },
  { text: 'purchase.enjoyLife' },
]

export const LIST_ITEMS_V2 = [
  { text: 'purchase.whatYouWillGet.pack' },
  { text: 'purchase.whatYouWillGet.approach' },
  { text: 'purchase.whatYouWillGet.body' },
  { text: 'purchase.whatYouWillGet.diet' },
  { text: 'purchase.whatYouWillGet.tips' },
  { text: 'purchase.whatYouWillGet.habits' },
  { text: 'purchase.whatYouWillGet.libido' },
]
