import styled from 'styled-components'

import { PageTitle } from 'components/PageTitle'

import { Color } from 'root-constants'

export const StyledReachGoals = {
  PageTitle: styled(PageTitle)`
    strong {
      color: ${Color.GREEN_300};
    }
  `,
  Banner: styled.div`
    aspect-ratio: 343 / 208;
    margin-bottom: 16px;
    width: 100%;
  `,
}
