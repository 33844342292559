import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Spinner } from 'components/Spinner'

import {
  getSubscriptionListAction,
  setScreenNameAction,
} from 'root-redux/actions/common'
import {
  selectCurrentUserPrimaryGoal,
  selectCurrentVariantCohortToUse,
  selectSubscriptionList,
} from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import { useVatInfo } from 'hooks/useHasVatInfo'
import { usePayment } from 'hooks/usePayment'
import {
  ViewportScrollPercentage,
  useViewportValue,
} from 'hooks/useViewportValue'

import { createProductId } from 'helpers/createProductId'

import {
  CustomerReviewsV2,
  Disclaimer,
  FAQList,
  FeaturedBlock,
  IntroMediaV2,
  MoneyBackGuaranteeV2,
  PlanHighlightsV2,
  SubscriptionsBlock,
} from 'modules/purchase/components'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import questionIcon from 'assets/images/question-icon-circle.png'

import { goTo } from 'browser-history'
import {
  Cohort,
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
} from 'root-constants'

import { OnboardingButtonText, PURCHASE_OFFSET_TOP } from '../constants'
import { StyledPurchaseVariant3 as S } from './PurchaseVariant3.styles'
import { PRIMARY_GOAL_MAP } from './constants'

export const PurchaseVariant3: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()
  const uuid = useSelector(selectUUID)

  const subscriptions = useSelector(selectSubscriptionList)
  const primaryGoal = useSelector(selectCurrentUserPrimaryGoal)
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const hasVatInfo = useVatInfo()
  const viewportValue = useViewportValue()
  const { hasPrices, handleAmplitudePaymentEvents, handleShowPayment } =
    usePayment()

  const elemForComparisonRef = useRef<HTMLHeadingElement>(null)
  const firstSubscriptionsRef = useRef<HTMLDivElement>(null)

  const titleProps = useMemo(
    () =>
      cohortToUse === Cohort.MF_MEN_FLOW
        ? {
            i18nKey: 'purchase.titleV2',
            values: {
              goal: t(PRIMARY_GOAL_MAP[primaryGoal]),
            },
          }
        : { i18nKey: 'purchase.title' },
    [t, cohortToUse, primaryGoal],
  )

  const photoResultTitle = useMemo(
    () =>
      cohortToUse === Cohort.MF_MEN_FLOW
        ? 'purchase.photoResult.titleV3'
        : 'purchase.photoResult.titleV2',
    [cohortToUse],
  )

  useLayoutEffect(() => {
    dispatch(setScreenNameAction(ScreenName.ONBOARDING))

    dispatch(
      getSubscriptionListAction(
        SubscriptionListType.PURCHASE,
        hasVatInfo ? SubscriptionTags.TAX : SubscriptionTags.NO_TAX,
      ),
    )
  }, [dispatch, hasVatInfo])

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(({ mainPrices }) =>
        createProductId({
          periodName: mainPrices.periodName,
          periodQuantity: mainPrices.periodQuantity,
          price: mainPrices.fullPrice,
        }),
      ),
      screenName: ScreenName.ONBOARDING,
    })
  }, [subscriptions])

  useEffect(() => {
    if (viewportValue > ViewportScrollPercentage.V_0) {
      eventLogger.logScrollToBottom(viewportValue, ScreenName.ONBOARDING)
    }
  }, [viewportValue])

  const handleGetPlan = useCallback(
    (event: SyntheticEvent<HTMLButtonElement>) => {
      window.fbq('track', 'InitiateCheckout', {}, { eventID: uuid })
      handleShowPayment()
      googleAnalyticsLogger.logCheckoutStarted(subscriptions)

      handleAmplitudePaymentEvents(event.target as HTMLButtonElement)

      goTo(nextPagePath)
    },
    [
      uuid,
      subscriptions,
      handleAmplitudePaymentEvents,
      handleShowPayment,
      nextPagePath,
    ],
  )

  const handleIntroMediaButtonClick = useCallback(
    (event: SyntheticEvent<HTMLButtonElement>) => {
      const top = firstSubscriptionsRef.current?.offsetTop

      if (top) {
        document.body.scrollTo(0, top - PURCHASE_OFFSET_TOP)
      }

      handleAmplitudePaymentEvents(event.target as HTMLButtonElement)
    },
    [handleAmplitudePaymentEvents],
  )

  return !hasPrices ? (
    <Spinner />
  ) : (
    <>
      <S.Wrapper>
        <S.ProgramPlanContainer>
          <S.Title>
            <Trans {...titleProps} />
          </S.Title>
          <S.Subtitle>
            <Trans i18nKey="purchase.subtitle" />
          </S.Subtitle>
          <IntroMediaV2
            elemForComparisonRef={elemForComparisonRef}
            data-button-number="1"
            data-button-text={OnboardingButtonText.GET_PLAN_UPPER}
            onButtonClick={handleIntroMediaButtonClick}
          />
          <PlanHighlightsV2 titleElementRef={elemForComparisonRef} />
        </S.ProgramPlanContainer>
        <SubscriptionsBlock ref={firstSubscriptionsRef} titleMargin={16} />

        <S.Button
          type="button"
          data-button-number="2"
          data-button-text={OnboardingButtonText.GET_PLAN_MIDDLE}
          onClick={handleGetPlan}
        >
          {t('actions.getMyPlan')}
        </S.Button>
        {cohortToUse === Cohort.MF_MEN_FLOW && <S.DisclaimerV2 />}

        <FeaturedBlock />
        <MoneyBackGuaranteeV2 />
        <S.SecurityInfo />
      </S.Wrapper>

      <S.CustomerReviewsContainer>
        <S.PhotoResult titlePath={photoResultTitle} />
        <FAQList questionIconSrc={questionIcon} />
        <CustomerReviewsV2 />
        <S.Button
          type="button"
          data-button-number="3"
          data-button-text={OnboardingButtonText.GET_PLAN_BOTTOM}
          onClick={handleGetPlan}
        >
          {t('actions.getMyPlan')}
        </S.Button>
        {cohortToUse !== Cohort.MF_MEN_FLOW && <Disclaimer />}
      </S.CustomerReviewsContainer>
    </>
  )
}
