import styled from 'styled-components'

import { StyledButtonBase } from 'components/Button/Button.styles'

type TTitleProps = {
  titleMargin: number
}

export const StyledSubscriptionsBlock = {
  Wrapper: styled.div`
    margin: 0 auto;
    border-radius: 24px;
    background-size: cover;

    & label:last-child {
      margin-bottom: 24px;
    }
  `,
  Title: styled.h2<TTitleProps>`
    margin-bottom: ${({ titleMargin }) => `${titleMargin}px`};
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  `,
  Button: styled.button`
    ${StyledButtonBase};
    margin: 24px 0;
    max-width: 328px;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
  `,
}
