import styled from 'styled-components'

import { MAX_COLUMN_WIDTH, MediaBreakpoint } from 'root-constants'

type TProps = {
  paddingTop: number
  width?: number
}

export const StyledPageContainer = {
  Container: styled.div<TProps>`
    margin: 0 auto;
    ${({ paddingTop }) => `
      padding: ${paddingTop}px 16px 24px;
    `}

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: ${({ width }) => (width ? `${width}px` : `${MAX_COLUMN_WIDTH}px`)};
    }
  `,
}
