import React from 'react'
import { useTranslation } from 'react-i18next'

import stars from 'assets/images/alternative-stars-v2.png'

import { StyledCustomerReviewsV2 as S } from './CustomerReviewsV2.styles'
import { CUSTOMER_REVIEWS_V2 } from './constants'

export const CustomerReviewsV2: React.FC = () => {
  const { t } = useTranslation()

  return (
    <S.Wrapper>
      <S.Title>{t('purchase.customerReviewsV3.title')}</S.Title>
      {CUSTOMER_REVIEWS_V2.map(({ id, title, date, author, text }) => (
        <S.ReviewContainer key={id}>
          <S.ReviewHeader>
            <S.ReviewTitle>{t(title)}</S.ReviewTitle>
            <S.Date>{date}</S.Date>
            <S.Rating>
              <img src={stars} alt="stars" />
            </S.Rating>
            <S.Author>{author}</S.Author>
          </S.ReviewHeader>
          <S.TextContent>
            <S.TextContainer>
              <S.Text>{t(text)}</S.Text>
            </S.TextContainer>
          </S.TextContent>
        </S.ReviewContainer>
      ))}
    </S.Wrapper>
  )
}
