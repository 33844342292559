import React, {
  FC,
  RefObject,
  SyntheticEvent,
  useCallback,
  useMemo,
} from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import lottie from 'lottie-web/build/player/lottie_light'

import { useTargetHiddenOnScroll } from 'hooks/useTargetHiddenOnScroll'

import { getDecoratedTimerValue } from 'modules/purchase/helpers/rootHelpers'
import { OnboardingButtonText } from 'modules/purchase/pages/constants'
import {
  selectSubscriptionIntroDifPercents,
  selectSubscriptionTimerValue,
} from 'modules/purchase/redux/selects'

import { CDN_FOLDER_LINK } from 'root-constants'

import { StyledDiscountTimerV1 as S } from './DiscountTimer.styles'
import { BUTTON_ANIMATION_PATH } from './constants'

type TProps = {
  observableElemRef: RefObject<HTMLDivElement>
  onButtonClick: (event: SyntheticEvent<HTMLButtonElement>) => void
  className?: string
}

export const DiscountTimerV1: FC<TProps> = ({
  observableElemRef,
  onButtonClick,
  className,
}) => {
  const discount = useSelector(selectSubscriptionIntroDifPercents)
  const timerValue = useSelector(selectSubscriptionTimerValue)
  const isTargetHidden = useTargetHiddenOnScroll(observableElemRef)

  const beforeAfterAnimationRef = useCallback((node: HTMLButtonElement) => {
    if (node) {
      lottie.loadAnimation({
        container: node,
        path: `${CDN_FOLDER_LINK}${BUTTON_ANIMATION_PATH}`,
        loop: true,
      })
    }
  }, [])

  const { minutes, seconds } = useMemo(
    () => getDecoratedTimerValue(timerValue, false),
    [timerValue],
  )

  return isTargetHidden ? (
    <S.BannerWithTimer />
  ) : (
    <S.Wrapper className={className}>
      <S.Timer>
        <S.TimerLabel>
          <Trans
            i18nKey="purchase.discountTimerV1.timerLabel"
            values={{
              discount,
            }}
          />
        </S.TimerLabel>
        <S.TimerValue>
          {minutes}:{seconds}
        </S.TimerValue>
      </S.Timer>
      <S.Button
        data-button-number="1"
        data-button-text={OnboardingButtonText.GET_PLAN_UPPER}
        ref={beforeAfterAnimationRef}
        onClick={onButtonClick}
      />
    </S.Wrapper>
  )
}
