import styled from 'styled-components'

export const StyledNavigationButton = {
  Root: styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    position: sticky;
    bottom: 0;
    z-index: 2;
    padding: 40px 0;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.0001) 0.25%,
      #ffffff 26.14%
    );
  `,
}
