const START_AGE_PERIOD = 20

export const roundToTenth = (value: number) =>
  value < START_AGE_PERIOD
    ? Math.ceil(value / 10) * 10
    : Math.floor(value / 10) * 10

export const roundToNearestFive = (value: number) => Math.round(value / 5) * 5

export const roundNumber = (value: number, decimals: number) =>
  Number(`${Math.round(Number(`${value}e+${decimals}`))}e-${decimals}`)

export const roundDown = (value: number, decimals = 0) =>
  Math.floor(value * 10 ** decimals) / 10 ** decimals
