import React, { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { MotivationValue } from 'value-constants'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { CustomerReviewsV2 } from 'components/CustomerReviews'
import { Logo } from 'components/Logo'
import { LoseWeightGraphV2 } from 'components/LoseWeightGraphV2'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import {
  selectCurrentUserMotivation,
  selectUserMeasurementSystem,
} from 'root-redux/selects/common'

import { useEventWeightInfo } from 'hooks/useEventWeightInfo'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { MeasurementSystem, WEIGHT_UNITS } from 'root-constants'

import { StyledContributingEventsV2 as S } from './ContributingEventsV2.styles'
import { LoseAverageWeightPerWeek, QUESTION } from './constants'

export const ContributingEventsV2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const measurementSystem = useSelector(selectUserMeasurementSystem)
  const motivation = useSelector(selectCurrentUserMotivation) as string

  const {
    eventDate,
    goalWeight,
    shortenedGoalDate,
    weightDifference,
    currentWeight,
    isEventWithinGoal,
  } = useEventWeightInfo()

  const metric = useMemo(
    () => WEIGHT_UNITS[measurementSystem],
    [measurementSystem],
  )

  const loseAverageWeightPerWeek = useMemo(
    () =>
      metric === WEIGHT_UNITS[MeasurementSystem.METRIC]
        ? LoseAverageWeightPerWeek.KG
        : LoseAverageWeightPerWeek.LBS,
    [metric],
  )

  const hasMotivationToShow = useMemo(
    () =>
      motivation !== MotivationValue.NO && motivation !== MotivationValue.OTHER,
    [motivation],
  )

  const lostWeightToShow = useMemo(
    () =>
      isEventWithinGoal
        ? weightDifference
        : (Number(currentWeight) - Number(goalWeight)).toFixed(1),
    [currentWeight, goalWeight, isEventWithinGoal, weightDifference],
  )

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  return (
    <>
      <S.ImageContainer>
        <Logo />
        <S.Title>{t('onboarding.contributingEventsV2.title')}</S.Title>
      </S.ImageContainer>
      <Container>
        <S.DateTitle>
          <Trans
            i18nKey="onboarding.contributingEventsV2.dateTitle"
            values={{
              date: shortenedGoalDate,
              goal: goalWeight,
              metric,
            }}
          />
        </S.DateTitle>
        {eventDate && hasMotivationToShow && (
          <S.DateSubTitle>
            &quot;
            <Trans
              i18nKey="onboarding.contributingEventsV2.dateSubtitle"
              values={{
                weight: lostWeightToShow,
                metric,
                answer: motivation.toLowerCase(),
              }}
            />
            &quot;
          </S.DateSubTitle>
        )}
        <LoseWeightGraphV2 />
        <S.FeedbackTitle>
          <Trans
            i18nKey="onboarding.contributingEventsV2.feedbackTitle"
            values={{
              weight: loseAverageWeightPerWeek,
              metric,
            }}
          />
        </S.FeedbackTitle>
        <StickyButtonContainer>
          <Button type="button" onClick={handleNextClick}>
            {t('actions.soundsLikePlan')}
          </Button>
        </StickyButtonContainer>
        <CustomerReviewsV2 />
      </Container>
    </>
  )
}
