import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { IMAGE_PATH } from 'pages/questions/AchieveResult/constants'

import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'

import { selectLanguage } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { CDN_FOLDER_LINK, Language } from 'root-constants'

import { StyledAchieveResultV4 as S } from './AchieveResult.styles'

export const AchieveResultV4: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const language = useSelector(selectLanguage)

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.achieveResultV4.title', { lng: Language.EN }),
    nextPagePath,
  })

  const bannerImgSrc = useMemo(
    () => `${CDN_FOLDER_LINK}${IMAGE_PATH}-${language}.png`,
    [language],
  )

  return (
    <KitPageContainer
      hasContinueButton
      onContinueButtonClick={() => handleContinue('')}
    >
      <PageTitle marginBottom={16}>
        {t('onboarding.achieveResultV4.title')}
      </PageTitle>
      <S.Subtitle>
        <Trans i18nKey="onboarding.achieveResultV4.subtitle" />
      </S.Subtitle>
      <S.Banner>
        <img src={bannerImgSrc} alt="banner" />
      </S.Banner>
      <S.CallToAction>
        <Trans i18nKey="onboarding.achieveResultV4.callToAction" />
      </S.CallToAction>
    </KitPageContainer>
  )
}
