import { BmiValue } from 'value-constants'

import { Color } from 'root-constants'

export const BMI_VALUE_TO_MARKUP_MAP = {
  [BmiValue.UNDERWEIGHT]: {
    textColor: '#93D4ED',
    backgroundColor: '#E9F3F8',
    value: 'result.personalSummary.underweight',
  },
  [BmiValue.NORMAL]: {
    textColor: Color.PRIMARY,
    backgroundColor: '#E9F8F0',
    value: 'result.personalSummary.normal',
  },
  [BmiValue.OVERWEIGHT]: {
    textColor: '#F4CF88',
    backgroundColor: '#F8F4E9',
    value: 'result.personalSummary.overweight',
  },
  [BmiValue.OBESITY]: {
    textColor: '#ED8875',
    backgroundColor: '#F8E9E9',
    value: 'result.personalSummary.obesity',
  },
  [BmiValue.EXTREME_OBESITY]: {
    textColor: '#ED8875',
    backgroundColor: '#F8E9E9',
    value: 'result.personalSummary.extremeObesity',
  },
}

export const KIT_BMI_LABEL_TO_COLOR_MAP = {
  [BmiValue.UNDERWEIGHT]: Color.ORANGE_200,
  [BmiValue.NORMAL]: Color.GREEN_300,
  [BmiValue.OVERWEIGHT]: Color.ORANGE_200,
  [BmiValue.OBESITY]: Color.RED_400,
  [BmiValue.EXTREME_OBESITY]: Color.RED_400,
}

export const MAX_BMI_VALUE = 100
