import styled, { css } from 'styled-components'

import backArrowIcon from 'assets/images/back-arrow.svg'
import forwardArrowIcon from 'assets/images/forward-arrow.svg'

import { Color, MediaBreakpoint } from 'root-constants'

const commonLabelStyles = css`
  padding: 8px 0;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
`

export const TextContainer = styled.div`
  padding-bottom: 30px;
`
export const Author = styled.p`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
`
export const LostWeight = styled.span``
export const Review = styled.p`
  position: relative;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${Color.DISABLED};

  svg {
    transform: translateY(3px);
    height: 16px;
  }
`
export const Image = styled.div`
  aspect-ratio: 35 / 52;
  width: 90px;

  @media (min-width: ${MediaBreakpoint.MIN_PHONE}px) {
    width: 100px;
  }
`
export const LabelBefore = styled.p`
  ${commonLabelStyles};
  color: ${Color.DISABLED};
`
export const LabelAfter = styled.p`
  ${commonLabelStyles};
  color: ${Color.GREY_100};
`
export const ResultTitle = styled.h2`
  margin-bottom: 22px;
  font-weight: bold;
  font-size: 17px;
  line-height: 26px;
  color: ${Color.GREY_100};
  text-align: center;

  strong {
    color: ${Color.PRIMARY};
  }
`
export const Cards = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  padding: 30px 0 24px;
  background: ${Color.GREY_103};
  border-radius: 10px;
  overflow: hidden;
`
export const ImageContainer = styled.div`
  padding: 4px 4px 0;
  background-color: ${Color.WHITE};
  border-radius: 14px;
  box-shadow: 0 3.35428px 67.0857px rgba(0, 0, 0, 0.16);

  &:first-of-type {
    transform: rotate(-7deg);
  }

  &:last-of-type {
    transform: rotate(7deg) translateY(-10px);
  }
`

export const StyledPhotoResult = {
  ResultContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 8px;
    padding: 22px 16px;
    background: ${Color.WHITE};
    box-shadow: 0 8px 20px rgba(17, 30, 23, 0.1);
    border-radius: 24px;
  `,
  CarouselContainer: styled.div`
    max-width: 100%;

    .swiper-container-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      display: flex;
      justify-content: center;
      bottom: 0;
    }

    .swiper-pagination-bullets {
      bottom: -5px;
    }

    .swiper-pagination-bullets .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      margin: 0 6px;
      background: #ececec;
      opacity: 1;
    }

    .swiper-pagination-bullets .swiper-pagination-bullet-active {
      opacity: 1;
      background-color: ${Color.PRIMARY};
    }

    .swiper-button-next,
    .swiper-button-prev {
      background-color: ${Color.WHITE};
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-repeat: no-repeat;
      top: 130px;
    }

    .swiper-button-next {
      background-image: url(${forwardArrowIcon});
      background-position: 55% 50%;
    }

    .swiper-button-prev {
      background-image: url(${backArrowIcon});
      background-position: 45% 50%;
    }
  `,
  Disclaimer: styled.p`
    padding-bottom: 24px;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: ${Color.DISABLED};
  `,
}
