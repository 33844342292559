import styled, { css } from 'styled-components'

import { Input } from 'components/Input'

import { Color, MediaBreakpoint } from 'root-constants'

type TWeightProps = {
  value: string
  isValid: boolean
}

type TDifferentProps = {
  normal: boolean
}

export const StyledGoalWeightV2 = {
  Actions: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  `,
  WeightLabel: styled.div`
    border: 2px solid ${Color.GREY_WHITE};
    padding: 2px;
    border-radius: 8px;
    top: 105px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 60px;
    height: 32px;
    background-color: ${Color.WHITE};
    z-index: 1;
  `,
  Label: styled.div`
    background-color: ${Color.PRIMARY};
    text-transform: uppercase;
    color: ${Color.WHITE};
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    line-height: 14px;
    display: flex;
    width: 56px;
    height: 24px;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
  `,
  InputWrapper: styled.div`
    position: relative;
    min-width: 285px;
    flex-basis: 48%;

    @media (min-width: ${MediaBreakpoint.MIN_PHONE}px) {
      min-width: 328px;
      flex-basis: auto;
    }
  `,
  Disclaimer: styled.div<TDifferentProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 8px 12px;
    gap: 4px;
    background-color: ${({ normal }) =>
      normal ? `${Color.GREEN_100}` : `${Color.ORANGE_LIGHT}`};
    border-radius: 12px;
    margin-top: 24px;
    margin-bottom: 24px;
  `,
  Title: styled.div<TDifferentProps>`
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: ${({ normal }) => (normal ? `${Color.PRIMARY}` : `${Color.ORANGE}`)};

    strong {
      color: ${Color.GREY_100};
    }
  `,
  SubTitle: styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: ${Color.GREY_100};
  `,
  InputContainer: styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
  `,
  WeightInput: styled(Input)<TWeightProps>`
    padding: 21px 35px 21px 32px;
    height: 72px;
    background: ${Color.WHITE};
    border: 2px solid ${Color.LIGHT_BLUE};
    border-radius: 12px;
    text-align: center;
    ${({ isValid, value }) =>
      value &&
      !isValid &&
      css`
        background: rgba(224, 105, 140, 0.1);
        border: 2px solid ${Color.PINK};
        color: ${Color.PINK};
      `};

    ${({ isValid, value }) =>
      value &&
      isValid &&
      css`
        border: 2px solid ${Color.PRIMARY};
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
      `};

    &:focus,
    &:active {
      & ~ span {
        display: none;
      }
    }
  `,
  Placeholder: styled.span`
    position: absolute;
    top: calc(50% - 2px);
    left: calc(50% - 2px);
    height: 2px;
    width: 10px;
    background-color: ${Color.DISABLED};
    pointer-events: none;
  `,
  ErrorMessage: styled.p`
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: ${Color.PINK};
    margin-top: 6px;

    & svg {
      width: 18px;
      height: 18px;
      margin-right: 4px;
    }
  `,
  Suffix: styled.span`
    position: absolute;
    top: 23px;
    right: 32px;
    height: 22px;
    width: 13px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: ${Color.DISABLED};
  `,
  ButtonContainer: styled.div<TWeightProps>`
    padding-top: ${({ isValid, value }) =>
      !value || !isValid ? '138px' : '8px'};
  `,
}
