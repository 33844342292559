import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  ACTIVITY_DURING_WORK_MAP,
  ACTIVITY_LEVEL_MAP,
  BMI_VALUE_TO_MARKUP_MAP,
  PRIMARY_GOAL_MAP,
} from 'map-constants'

import {
  selectActivityDuringWork,
  selectCurrentUserGoalWeight,
  selectCurrentUserPrimaryGoal,
  selectUserMeasurementSystem,
  selectWorkout,
} from 'root-redux/selects/common'

import { useUsersBmi } from 'hooks/useUsersBmi'

import { convertTextFromSnakeCase } from 'helpers/convertTextFromSnakeCase'

import bmiBullet from 'assets/images/bullet-body-v2.svg'
import chartBullet from 'assets/images/bullet-chart-bar-v2.svg'
import goalBullet from 'assets/images/bullet-goal-v2.svg'
import weightBullet from 'assets/images/bullet-weight.svg'

import { MeasurementSystem } from 'root-constants'

import { StyledPersonalizedPlan as S } from './PersonalizedPlan.styles'

type TProps = {
  className?: string
}

export const PersonalizedPlan: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()

  const primaryGoal = useSelector(selectCurrentUserPrimaryGoal)
  const activityDuringWork = useSelector(selectActivityDuringWork)
  const goalWeight = useSelector(selectCurrentUserGoalWeight)
  const measurementSystem = useSelector(selectUserMeasurementSystem)
  const workoutValue = useSelector(selectWorkout)

  const { currentBMI } = useUsersBmi()

  const bmiValue = useMemo(
    () => BMI_VALUE_TO_MARKUP_MAP[currentBMI.bmiValue.value],
    [currentBMI],
  )
  const goal = useMemo(() => PRIMARY_GOAL_MAP[primaryGoal], [primaryGoal])

  const activity = useMemo(
    () =>
      t(ACTIVITY_DURING_WORK_MAP[activityDuringWork]) ||
      convertTextFromSnakeCase(t(ACTIVITY_LEVEL_MAP[workoutValue])),
    [activityDuringWork, workoutValue, t],
  )

  const weight = useMemo(
    () =>
      `${goalWeight} ${
        measurementSystem === MeasurementSystem.METRIC
          ? t('commonComponents.kg')
          : t('commonComponents.lbs')
      }`,
    [t, measurementSystem, goalWeight],
  )

  return (
    <div className={className}>
      <S.Title>{t('purchase.personalizedPlan.title')}</S.Title>
      <S.PlanContainer>
        <S.PlanItem bullet={goalBullet} hasBottomBorder hasRightBorder>
          <S.ItemTitle>{t('purchase.personalizedPlan.goal')}</S.ItemTitle>
          <S.ItemValue>{t(goal)}</S.ItemValue>
        </S.PlanItem>
        <S.PlanItem bullet={chartBullet} hasBottomBorder>
          <S.ItemTitle>
            {t('purchase.personalizedPlan.activityLevel')}
          </S.ItemTitle>
          <S.ItemValue>{activity}</S.ItemValue>
        </S.PlanItem>
        <S.PlanItem bullet={weightBullet} hasRightBorder>
          <S.ItemTitle>
            {t('purchase.personalizedPlan.targetWeight')}
          </S.ItemTitle>
          <S.ItemValue isLowercase>{weight}</S.ItemValue>
        </S.PlanItem>
        <S.PlanItem bullet={bmiBullet}>
          <S.ItemTitle>
            {t('purchase.personalizedPlan.bmiCategory')}
          </S.ItemTitle>
          <S.ItemValue>{t(bmiValue)}</S.ItemValue>
        </S.PlanItem>
      </S.PlanContainer>
    </div>
  )
}
