import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import { useGetPageInfo } from 'hooks/useGetPageInfo'

import {
  COHORTS_WITHOUT_TRIAL_FOR_WEEKLY_PLAN,
  CURRENCY_SYMBOLS,
  Period,
  PurchaseProduct,
} from 'modules/purchase/constants'
import { TSubscriptionItemProps } from 'modules/purchase/types'

import { PageId } from 'page-constants'

import {
  Content,
  CurrentPrice,
  CustomPrice,
  MostPopularBadge,
  PlanContainer,
  PlanPeriod,
  PreviousPrice,
  PriceValue,
  StyledSubscriptionItemV3 as S,
  TimePeriod,
} from './SubscriptionItemV3.styles'

export const SubscriptionItemV3: React.FC<TSubscriptionItemProps> = ({
  subscription: { id, product, mainPrices, trialPrices, isDefault, currency },
  isSelected,
  hasWeeklyTrial = true,
}) => {
  const { t } = useTranslation()

  const { currentPageId } = useGetPageInfo()

  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const isWeeklyPlanWithoutTrial = useMemo(
    () =>
      (COHORTS_WITHOUT_TRIAL_FOR_WEEKLY_PLAN.includes(cohortToUse) ||
        !hasWeeklyTrial) &&
      trialPrices.durationDays === Period.SEVEN_DAYS,
    [cohortToUse, trialPrices, hasWeeklyTrial],
  )

  const isPurchaseV3Page = useMemo(
    () => currentPageId === PageId.PURCHASE_4,
    [currentPageId],
  )

  const oldPriceWithFractionalPart = useMemo(
    () =>
      product === PurchaseProduct.SEVEN_DAY
        ? trialPrices.oldPrices.beforeCustomDiscount?.fullPrice.toFixed(2)
        : mainPrices.fullPrice.toFixed(2),
    [product, mainPrices, trialPrices],
  )

  const trialCurrentPriceWithFractionalPart = useMemo(
    () => trialPrices.fullPrice.toFixed(2),
    [trialPrices],
  )

  const noTrialCurrentPriceWithFractionalPart = useMemo(
    () => mainPrices.fullPrice.toFixed(2),
    [mainPrices],
  )

  const pricePerDayWithFractionalPart = useMemo(
    () =>
      product === PurchaseProduct.SEVEN_DAY
        ? mainPrices.daily.toFixed(2)
        : trialPrices.daily.toFixed(2),
    [product, trialPrices, mainPrices],
  )

  const oldPricePerDayWithFractionalPart = useMemo(
    () => trialPrices.oldPrices.beforeCustomDiscount?.daily.toFixed(2),
    [trialPrices],
  )

  const period = useMemo(
    () =>
      product === PurchaseProduct.SEVEN_DAY
        ? t('purchase.subscription.weeklyPeriod', {
            periodQuantity: trialPrices.durationDays,
          })
        : t('purchase.subscription.monthlyPeriod', {
            periodQuantity: mainPrices.periodQuantity,
          }),
    [t, product, trialPrices, mainPrices],
  )

  return (
    <S.Wrapper isCustom={isPurchaseV3Page} key={id} isSelected={isSelected}>
      <Content isSelected={isSelected}>
        {isDefault && (
          <MostPopularBadge isCustom={isPurchaseV3Page}>
            {t('purchase.subscription.mostPopularBadge')}
          </MostPopularBadge>
        )}
        <PlanContainer>
          <PlanPeriod isSelected={isSelected}>{period}</PlanPeriod>

          <S.PriceContainer>
            {isWeeklyPlanWithoutTrial ? (
              <div>
                <CurrentPrice>
                  {CURRENCY_SYMBOLS[currency]}
                  {noTrialCurrentPriceWithFractionalPart}
                </CurrentPrice>
              </div>
            ) : (
              <div>
                <PreviousPrice>
                  {CURRENCY_SYMBOLS[currency]}
                  {oldPriceWithFractionalPart}
                </PreviousPrice>{' '}
                <CurrentPrice>
                  {CURRENCY_SYMBOLS[currency]}
                  {trialCurrentPriceWithFractionalPart}
                </CurrentPrice>
              </div>
            )}

            {!isWeeklyPlanWithoutTrial && (
              <PreviousPrice fontSize={16}>
                {CURRENCY_SYMBOLS[currency]}
                {oldPricePerDayWithFractionalPart}
              </PreviousPrice>
            )}
          </S.PriceContainer>
        </PlanContainer>

        <CustomPrice isSelected={isSelected}>
          <PriceValue>
            {CURRENCY_SYMBOLS[currency]}
            {pricePerDayWithFractionalPart}
          </PriceValue>
          <TimePeriod>
            {t('purchase.subscription.interval')}{' '}
            {t('purchase.subscription.day')}
          </TimePeriod>
        </CustomPrice>
      </Content>
    </S.Wrapper>
  )
}
