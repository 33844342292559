import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledReviewCard = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 128px;
    width: 100%;
    padding: 16px;
    border-radius: 12px;
    background-color: ${Color.WHITE};
    box-shadow: 0 12px 32px 0 #0000001f;
  `,
  UserBlock: styled.div`
    display: flex;
    align-items: center;
    margin: 0 0 8px 0;

    > :not(:last-child) {
      margin: 0 10px 0 0;
    }
  `,
  UserAvatar: styled.div`
    aspect-ratio: 1/1;
    width: 40px;
    height: auto;
  `,
  UserName: styled.span`
    color: ${Color.BLACK};
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
  `,
  Rating: styled.div`
    aspect-ratio: 88/16;
    height: 16px;
  `,
  ReviewText: styled.span`
    color: ${Color.BLACK};
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
  `,
}
