import styled from 'styled-components'

import { Container } from 'components/Container'
import { PageTitle } from 'components/PageTitle'

import { Color, MAX_COLUMN_WIDTH, MediaBreakpoint } from 'root-constants'

export const StyledEmailConsent = {
  Container: styled(Container)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: initial;
    min-height: calc(100vh - 45px);
    padding: 24px 16px;
    width: 100%;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: ${MAX_COLUMN_WIDTH}px;
    }
  `,
  Title: styled(PageTitle)`
    line-height: 30px;

    strong {
      color: ${Color.PRIMARY};
    }
  `,
  ButtonsContainer: styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    width: 100%;
  `,
  NoButton: styled.button`
    appearance: none;
    background-color: transparent;
    border: none;
    color: ${Color.DISABLED};
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    outline: none;
    padding: 0;
    width: 100%;
  `,
}
