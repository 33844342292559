import React from 'react'

import {
  AnswerWithIconAndCheckbox,
  answerWithIconAndCheckboxTheme,
} from 'storybook-ui'

import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'

import {
  TAnswer,
  TBuilderOptionData,
  TBuilderOptionProps,
  TPageProps,
} from 'models/common.model'

import check from 'assets/images/white-check-icon.svg'

import { StyledKitOptionsPage as S } from './KitCheckboxOptionsPage.styles'

type TProps = {
  title: string
  subtitle?: string
  buttonText?: string
  options?: TBuilderOptionData[]
  optionProps: TBuilderOptionProps
  answers: string[]
  isReverse?: boolean
  handleContinue: (value: TAnswer) => void
} & TPageProps

export const KitCheckboxOptionsPage: React.FC<TProps> = ({
  title,
  subtitle,
  options,
  optionProps,
  buttonText,
  answers,
  isReverse = true,
  handleContinue,
}) => {
  return (
    <KitPageContainer
      hasContinueButton
      isContinueButtonDisabled={!answers.length}
      continueButtonContent={buttonText}
      onContinueButtonClick={() => handleContinue(answers)}
    >
      <PageTitle marginBottom={subtitle ? 8 : 24} textAlign="left">
        {title}
      </PageTitle>
      {subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}

      {options &&
        options.map(({ id, value, imageUrl, text }) =>
          imageUrl ? (
            <AnswerWithIconAndCheckbox
              {...optionProps}
              key={id}
              theme={answerWithIconAndCheckboxTheme.NUTRIMATE}
              value={value}
              reverse={isReverse}
              borderRadius="48px"
              maxWidth="100%"
              margin="0 0 12px 0"
              checkboxBorderRadius="6px"
              checkboxBorder="1.5px solid #CACACA"
              checkboxWidth="24px"
              checkboxHeight="24px"
              spacingBetweenCheckboxAndContent="0 16px 0 16px"
              iconSrc={check}
              iconSize="auto"
              imageSrc={imageUrl}
              imageWidth="40px"
              imageHeight="40px"
            >
              <S.AnswerTitle>{text}</S.AnswerTitle>
            </AnswerWithIconAndCheckbox>
          ) : (
            <AnswerWithIconAndCheckbox
              {...optionProps}
              key={id}
              theme={answerWithIconAndCheckboxTheme.NUTRIMATE}
              value={value}
              reverse={isReverse}
              borderRadius="48px"
              maxWidth="100%"
              margin="0 0 12px 0"
              checkboxBorderRadius="6px"
              checkboxBorder="1.5px solid #CACACA"
              checkboxWidth="24px"
              checkboxHeight="24px"
              spacingBetweenCheckboxAndContent="0 16px 0 16px"
              iconSrc={check}
              iconSize="auto"
            >
              <S.AnswerTitle>{text}</S.AnswerTitle>
            </AnswerWithIconAndCheckbox>
          ),
        )}
    </KitPageContainer>
  )
}
