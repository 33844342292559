import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { QuestionCheckboxForm } from 'components/QuestionCheckboxForm'

import { selectCurrentUserPrimaryGoal } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { ANSWER_OPTIONS_V1, QUESTION_V1, TITLE_V1 } from './constants'

export const SecondaryGoalV1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const primaryGoal = useSelector(selectCurrentUserPrimaryGoal)

  const title = useMemo(() => TITLE_V1[primaryGoal], [primaryGoal])

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION_V1,
    nextPagePath,
  })

  return (
    <QuestionCheckboxForm
      pageId={pageId}
      formTitle={title}
      options={ANSWER_OPTIONS_V1}
      handleContinue={handleContinue}
    />
  )
}
