import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { IdealWeightTimeValue } from 'value-constants'

import { AnswerButton } from 'components/AnswerButton'
import { Container } from 'components/Container'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import confusingFaceIcon from 'assets/images/confusing-face.png'
import facepalmFemaleFaceIcon from 'assets/images/facepalm-female-face.png'
import facepalmMaleFaceIcon from 'assets/images/facepalm-male-face.png'
import redCrossIcon from 'assets/images/red-cross.png'
import surprisedFaceIcon from 'assets/images/surprised-face.png'

import { CustomPageId } from 'page-constants'
import { Gender, Language } from 'root-constants'

export const IdealWeightTime: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()

  const gender = useSelector(selectCurrentUserGender)

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId: CustomPageId.IDEAL_WEIGHT_TIME,
    question: t('onboarding.idealWeightTime.title', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: CustomPageId.IDEAL_WEIGHT_TIME,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [handleChange, setIsAnswersDisabled, handleContinue],
  )

  return (
    <Container>
      <PageTitle marginBottom={24}>
        {t('onboarding.idealWeightTime.title')}
      </PageTitle>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={IdealWeightTimeValue.LESS_THAN_YEAR_AGO}
      >
        <AnswerButton iconSrc={confusingFaceIcon}>
          <h3>{t('onboarding.idealWeightTime.options.lessThanYearAgo')}</h3>
        </AnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={IdealWeightTimeValue.ONE_TWO_YEARS_AGO}
      >
        <AnswerButton iconSrc={surprisedFaceIcon}>
          <h3>{t('onboarding.idealWeightTime.options.oneTwoYearsAgo')}</h3>
        </AnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={IdealWeightTimeValue.MORE_THAN_THREE_YEARS_AGO}
      >
        <AnswerButton
          iconSrc={
            gender === Gender.FEMALE
              ? facepalmFemaleFaceIcon
              : facepalmMaleFaceIcon
          }
        >
          <h3>
            {t('onboarding.idealWeightTime.options.moreThanThreeYearsAgo')}
          </h3>
        </AnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={IdealWeightTimeValue.NEVER}
      >
        <AnswerButton iconSrc={redCrossIcon}>
          <h3>{t('onboarding.idealWeightTime.options.never')}</h3>
        </AnswerButton>
      </Option>
    </Container>
  )
}
