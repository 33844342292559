import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { paymentApi } from 'api'
import lottie from 'lottie-web/build/player/lottie_light'

import {
  savePlanAdditionsAction,
  sendUserConfigAction,
} from 'root-redux/actions/user'
import {
  selectCurrentVariantCohort,
  selectScreenName,
  selectStripeAccountId,
  selectStripeAccountName,
} from 'root-redux/selects/common'
import { selectUUID, selectUserOnboardingEmail } from 'root-redux/selects/user'

import { createProductId } from 'helpers/createProductId'

import { PaymentMethod, PaymentSystem } from 'modules/purchase/constants'
import { logStartedPayPalPayment } from 'modules/purchase/helpers/logStartedPayPalPayment'
import { logSuccessfulPayPalPayment } from 'modules/purchase/helpers/logSuccessfulPayPalPayment'
import {
  selectCurrency,
  selectPayPalPlanId,
  selectSubscriptionFullPrice,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
} from 'modules/purchase/redux/selects'

import { eventLogger } from 'services/eventLogger.service'

import paymentErrorAnimation from 'assets/animations/paymentErrorAnimation.json'
import paymentSuccessAnimation from 'assets/animations/paymentSuccessAnimation.json'

import { logFailedPayPalPayment } from '../helpers/logFailedPayPalPayment'

export const enum AnimationState {
  SUCCESS = 'success',
  ERROR = 'error',
}

type TValues = {
  isButtonTouched: boolean
  isPaymentStatusShown: boolean
  setIsPaymentStatusShown: (value: boolean) => void
  animationStatus: AnimationState | boolean
  completeAnimationRef: React.MutableRefObject<HTMLDivElement | null>
  errorAnimationRef: React.MutableRefObject<HTMLDivElement | null>
  handleResetError: () => void
  handlePaymentApprove: (data) => Promise<void>
  handlePaymentError: (error) => void
  handlePaymentCancel: () => void
  handleButtonClick: () => void
}

export const usePayPalButton = (): TValues => {
  const [isPaymentStatusShown, setIsPaymentStatusShown] = useState(false)
  const [isButtonTouched, setIsButtonTouched] = useState(false)
  const [animationStatus, setAnimationStatus] = useState<
    AnimationState | boolean
  >(false)

  const currentPrice = useSelector(selectSubscriptionFullPrice)
  const currentUUID = useSelector(selectUUID)
  const currentPeriodName = useSelector(selectSubscriptionPeriodName)
  const currentPeriodQuantity = useSelector(selectSubscriptionPeriodQuantity)
  const paypalPlanId = useSelector(selectPayPalPlanId)
  const currency = useSelector(selectCurrency)
  const trialPrice = useSelector(selectSubscriptionTrialPeriodPrice)
  const trialPeriodDays = useSelector(selectSubscriptionTrialPeriodDays)
  const screenName = useSelector(selectScreenName)
  const email = useSelector(selectUserOnboardingEmail)
  const stripeAccountId = useSelector(selectStripeAccountId)
  const stripeAccountName = useSelector(selectStripeAccountName)
  const cohort = useSelector(selectCurrentVariantCohort)

  const dispatch = useDispatch()

  const productId = createProductId({
    periodName: currentPeriodName,
    periodQuantity: currentPeriodQuantity,
    price: trialPrice || currentPrice,
  })
  const completeAnimationRef = useRef<HTMLDivElement | null>(null)
  const errorAnimationRef = useRef<HTMLDivElement | null>(null)

  const handleResetError = useCallback(() => {
    setIsPaymentStatusShown(false)
    setAnimationStatus(false)
  }, [])

  const handlePaymentApprove = useCallback(
    async (data) => {
      setIsButtonTouched(false)
      logSuccessfulPayPalPayment({
        productId: paypalPlanId,
        price: currentPrice,
        subscriptionId: data.subscriptionID,
        uuid: currentUUID,
        periodName: currentPeriodName,
        periodQuantity: currentPeriodQuantity,
        paymentMethod: PaymentMethod.PAYPAL,
        currency,
        trialPrice,
        trialPeriodDays,
        screenName,
        isTrialActive: !!trialPeriodDays,
        email,
        stripeAccountId,
        stripeAccountName,
        paymentSystem: PaymentSystem.PAYPAL,
      })
      const response = await paymentApi.createPaypalSubscription({
        uuid: currentUUID,
        paypalPlanId,
        cohort,
      })
      if (response.status) {
        dispatch(
          sendUserConfigAction(
            {
              payment_currency: currency,
              payment_method: PaymentMethod.PAYPAL,
              is_download_visited: false,
              subscription_price: `${currentPrice}`,
              subscription_duration: `${currentPeriodQuantity}${currentPeriodName}`,
              price_id: paypalPlanId,
              trial_price: `${trialPrice}`,
              trial_period: `${trialPeriodDays}`,
            },
            null,
          ),
        )
        dispatch(savePlanAdditionsAction())
        setIsPaymentStatusShown(true)
        setAnimationStatus(AnimationState.SUCCESS)
      }
    },
    [
      paypalPlanId,
      dispatch,
      currentPrice,
      currentUUID,
      currentPeriodName,
      currentPeriodQuantity,
      currency,
      trialPrice,
      trialPeriodDays,
      screenName,
      email,
      stripeAccountId,
      stripeAccountName,
    ],
  )

  const handlePaymentError = useCallback(
    (error) => {
      setIsButtonTouched(false)
      logFailedPayPalPayment({
        productId,
        price: currentPrice,
        error,
        paymentMethod: PaymentMethod.PAYPAL,
        currency,
        screenName,
        isTrialActive: !!trialPeriodDays,
        stripeAccountId,
        stripeAccountName,
      })
      setIsPaymentStatusShown(true)
      setAnimationStatus(AnimationState.ERROR)
    },

    [
      currency,
      currentPrice,
      productId,
      screenName,
      trialPeriodDays,
      stripeAccountId,
      stripeAccountName,
    ],
  )

  const handlePaymentCancel = useCallback(() => {
    setIsButtonTouched(false)
    eventLogger.logPayPalPaymentPopupClose()
  }, [])

  const handleButtonClick = useCallback(() => {
    setIsButtonTouched(true)

    eventLogger.logPaymentMethodSelected(PaymentMethod.PAYPAL)
    logStartedPayPalPayment({
      productId,
      currency,
      screenName,
      stripeAccountId,
      stripeAccountName,
      price: currentPrice,
      paymentMethod: PaymentMethod.PAYPAL,
      paymentSystem: PaymentSystem.PAYPAL,
      isTrialActive: !!trialPeriodDays,
    })
  }, [
    currentPrice,
    currency,
    productId,
    screenName,
    trialPeriodDays,
    stripeAccountId,
    stripeAccountName,
  ])

  useEffect(() => {
    switch (animationStatus) {
      case AnimationState.SUCCESS:
        if (completeAnimationRef.current) {
          lottie.loadAnimation({
            container: completeAnimationRef.current,
            animationData: paymentSuccessAnimation,
            loop: false,
          })
        }
        break
      case AnimationState.ERROR:
        if (errorAnimationRef.current) {
          lottie.loadAnimation({
            container: errorAnimationRef.current,
            animationData: paymentErrorAnimation,
            loop: false,
          })
        }
        break
      default:
        break
    }

    return () => lottie.destroy()
  }, [animationStatus])

  return {
    isButtonTouched,
    isPaymentStatusShown,
    setIsPaymentStatusShown,
    animationStatus,
    completeAnimationRef,
    errorAnimationRef,
    handleResetError,
    handlePaymentApprove,
    handlePaymentError,
    handlePaymentCancel,
    handleButtonClick,
  }
}
