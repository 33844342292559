import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { GENDER_MAP } from 'components/UserInfoBlock/constants'

import {
  selectCurrentAge,
  selectCurrentUserGender,
} from 'root-redux/selects/common'

import { convertTextFromSnakeCase } from 'helpers/convertTextFromSnakeCase'

import bulletAge from 'assets/images/bullet-age.svg'
import bulletBiologicalSex from 'assets/images/bullet-biological-sex.svg'

import { StyledUserInfoBlockV3 as S } from './UserInfoBlockV3.styles'

type TProps = {
  className?: string
}
export const UserInfoBlockV3 = ({ className }: TProps) => {
  const { t } = useTranslation()
  const gender = useSelector(selectCurrentUserGender)
  const age = useSelector(selectCurrentAge)

  const genderTitle = useMemo(
    () => convertTextFromSnakeCase(t(GENDER_MAP[gender])),
    [gender, t],
  )

  return (
    <S.Container className={className}>
      <S.InfoItem bulletSrc={bulletBiologicalSex}>
        <S.Label>
          {convertTextFromSnakeCase(t('commonComponents.biologicalSex'))}
        </S.Label>
        <S.InfoValue>{genderTitle}</S.InfoValue>
      </S.InfoItem>
      <S.Divider />
      <S.InfoItem bulletSrc={bulletAge}>
        <S.Label>{convertTextFromSnakeCase(t('commonComponents.age'))}</S.Label>
        <S.InfoValue>{age}</S.InfoValue>
      </S.InfoItem>
    </S.Container>
  )
}
