import {
  ActivityDuringWorkValue,
  BmiValue,
  BodyPartValue,
  PrimaryGoalValue,
  WorkOutFrequencyValue,
} from 'value-constants'

import { PageId } from 'page-constants'
import {
  Cohort,
  MeasurementSystem,
  PlanAddition,
  UPPER_BMI_THRESHOLD,
} from 'root-constants'

export const BMI_LABELS_MAP = [
  {
    lowerThreshold: 10,
    upperThreshold: 18.5,
    value: BmiValue.UNDERWEIGHT,
  },
  {
    lowerThreshold: 18.5,
    upperThreshold: 24.99,
    value: BmiValue.NORMAL,
  },
  {
    lowerThreshold: 25,
    upperThreshold: 29.99,
    value: BmiValue.OVERWEIGHT,
  },
  {
    lowerThreshold: 30,
    upperThreshold: 34.99,
    value: BmiValue.OBESITY,
  },
  {
    lowerThreshold: 35,
    upperThreshold: UPPER_BMI_THRESHOLD,
    value: BmiValue.EXTREME_OBESITY,
  },
]

export const BODY_PART_NAME_MAP = {
  [BodyPartValue.ARMS]: 'commonComponents.bodyPart.arms',
  [BodyPartValue.CHEST]: 'commonComponents.bodyPart.chest',
  [BodyPartValue.BELLY]: 'commonComponents.bodyPart.belly',
  [BodyPartValue.HIPS]: 'commonComponents.bodyPart.hips',
  [BodyPartValue.LEGS]: 'commonComponents.bodyPart.legs',
}

export const PLAN_ADDITIONS_MAP: Record<string, PlanAddition[]> = {
  [PageId.PURCHASE_1]: [PlanAddition.PREMIUM],
  [PageId.PURCHASE_3]: [PlanAddition.PREMIUM],
  [PageId.PURCHASE_4]: [PlanAddition.PREMIUM],
  [PageId.CANCEL_OFFER_1]: [PlanAddition.PREMIUM, PlanAddition.MEAL_PLAN],
  [PageId.UPSELL_PAYWALL_1]: [PlanAddition.UPSELL],
  [PageId.UPSELL_PAYWALL_2]: [PlanAddition.UPSELL],
}

export const PRIMARY_GOAL_MAP = {
  [PrimaryGoalValue.LOSE_WEIGHT]: 'commonComponents.primaryGoal.loseWeight',
  [PrimaryGoalValue.GET_FIT]: 'commonComponents.primaryGoal.getFit',
  [PrimaryGoalValue.BUILD_HEALTHY_HABITS]:
    'commonComponents.primaryGoal.developHealthyHabits',
  [PrimaryGoalValue.GET_HEALTHY]: 'commonComponents.primaryGoal.getHealthy',
}

export const BMI_VALUE_TO_MARKUP_MAP = {
  [BmiValue.UNDERWEIGHT]: 'commonComponents.bmiValues.underweight',
  [BmiValue.NORMAL]: 'commonComponents.bmiValues.normal',
  [BmiValue.OVERWEIGHT]: 'commonComponents.bmiValues.overweight',
  [BmiValue.OBESITY]: 'commonComponents.bmiValues.obesity',
  [BmiValue.EXTREME_OBESITY]: 'commonComponents.bmiValues.extremeObesity',
}

export const ACTIVITY_DURING_WORK_MAP = {
  [ActivityDuringWorkValue.ACTIVE]: 'commonComponents.activity.active',
  [ActivityDuringWorkValue.PASSIVE]: 'commonComponents.activity.sedentary',
  [ActivityDuringWorkValue.NORMAL]: 'commonComponents.activity.moderately',
}

export const ACTIVITY_LEVEL_MAP = {
  [WorkOutFrequencyValue.THREE_FIVE_A_WEEK]:
    'commonComponents.activity.moderately',
  [WorkOutFrequencyValue.EVERY_DAY]: 'commonComponents.activity.active',
  [WorkOutFrequencyValue.RARELY]: 'commonComponents.activity.sedentary',
  [WorkOutFrequencyValue.NEVER]: 'commonComponents.activity.sedentary',
}

export const PAGES_CATEGORIES_NAME_MAP = {
  [Cohort.MF_INTRO_2]: [
    'pageCategories.body',
    'pageCategories.lifestyle',
    'pageCategories.challenges',
  ],
  [Cohort.MF_INTRO_SALE]: [
    'pageCategories.body',
    'pageCategories.lifestyle',
    'pageCategories.challenges',
  ],
}

export const LOST_WEIGHT_PER_WEEK_MAP = {
  [BmiValue.UNDERWEIGHT]: {
    [MeasurementSystem.METRIC]: 1,
    [MeasurementSystem.IMPERIAL]: 2.2,
  },
  [BmiValue.NORMAL]: {
    [MeasurementSystem.METRIC]: 1,
    [MeasurementSystem.IMPERIAL]: 2.2,
  },
  [BmiValue.OVERWEIGHT]: {
    [MeasurementSystem.METRIC]: 1.5,
    [MeasurementSystem.IMPERIAL]: 3.3,
  },
  [BmiValue.OBESITY]: {
    [MeasurementSystem.METRIC]: 1.7,
    [MeasurementSystem.IMPERIAL]: 3.7,
  },
  [BmiValue.EXTREME_OBESITY]: {
    [MeasurementSystem.METRIC]: 2,
    [MeasurementSystem.IMPERIAL]: 4.4,
  },
}
