import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useVatInfo } from 'hooks/useHasVatInfo'
import { usePaymentStore } from 'hooks/usePaymentStore'

import { StyledCheckoutSummaryV2 as S } from 'modules/purchase/components/CheckoutSummaryV2/CheckoutSummaryV2.styles'
import {
  CURRENCY_SYMBOLS,
  Currency,
  Period,
  PurchaseProduct,
} from 'modules/purchase/constants'

type TProps = {
  className?: string
}
export const TrialCheckoutSummaryV2: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()

  const {
    currency,
    fullPrice,
    periodName,
    periodQuantity,
    trialCurrentPrice,
    trialPriceBeforeDiscount,
    trialPeriodDays,
    product,
    discountAmount,
  } = usePaymentStore()

  const hasVatInfo = useVatInfo()

  const planName = useMemo(() => {
    if (product === PurchaseProduct.SEVEN_DAY) {
      return t('checkoutV3.planLabel', {
        periodQuantity: Period.SEVEN_DAYS,
        periodName: t(`commonComponents.timeInterval.${periodName}`, {
          count: periodQuantity,
        }),
      })
    }

    return t('checkoutV3.planLabel', {
      periodQuantity,
      periodName: t(`commonComponents.timeInterval.${periodName}`, {
        count: periodQuantity,
      }),
    })
  }, [t, product, periodQuantity, periodName])

  const totalPeriodName = useMemo(() => {
    if (product === PurchaseProduct.SEVEN_DAY) {
      return t('commonComponents.timeInterval.day', {
        count: trialPeriodDays,
      })
    }

    return t(`commonComponents.timeInterval.${periodName}`, {
      count: periodQuantity,
    })
  }, [t, periodQuantity, periodName, product, trialPeriodDays])

  const totalPeriodQuantity = useMemo(
    () =>
      product === PurchaseProduct.SEVEN_DAY
        ? Period.SEVEN_DAYS
        : periodQuantity,
    [product, periodQuantity],
  )

  const isMexican = useMemo(() => currency === Currency.MXN, [currency])

  return (
    <div className={className}>
      <S.DescriptionContainer>
        <S.PlanLabel>{planName}</S.PlanLabel>
        <S.PlanPrice>
          {t('checkoutV3.fullPrice', {
            currency: CURRENCY_SYMBOLS[currency],
            price:
              product === PurchaseProduct.SEVEN_DAY
                ? trialPriceBeforeDiscount.toFixed(2)
                : fullPrice.toFixed(2),
          })}
        </S.PlanPrice>
      </S.DescriptionContainer>
      <S.DescriptionContainer>
        <S.PlanLabel>{t('checkoutV3.discountLabel')}</S.PlanLabel>
        <S.PlanPrice>
          -{CURRENCY_SYMBOLS[currency]}
          {discountAmount}
        </S.PlanPrice>
      </S.DescriptionContainer>
      <S.TotalContainer isMexican={isMexican}>
        <S.TotalLabel>
          {t('checkoutV3.totalLabel', {
            includingVat: hasVatInfo ? t('purchase.includingVat') : '',
          })}
        </S.TotalLabel>
        <S.TotalPrice>
          <Trans
            i18nKey="checkoutV3.totalPrice"
            values={{
              currency: CURRENCY_SYMBOLS[currency],
              price: trialCurrentPrice.toFixed(2),
              periodQuantity: totalPeriodQuantity,
              periodName: totalPeriodName,
            }}
            components={{ span: <span /> }}
          />
        </S.TotalPrice>
      </S.TotalContainer>
    </div>
  )
}
