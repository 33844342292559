import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledKitOptionsPage = {
  Subtitle: styled.h3`
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: ${Color.GREY_101};
    margin: 0 0 24px 0;
  `,
  AnswerTitle: styled.h3`
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    color: ${Color.GREY_100};
  `,
}
