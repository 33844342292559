import styled, { css } from 'styled-components'

import { AnswerButton } from 'components/AnswerButton'
import { AnswerButtonV2 } from 'components/AnswerButtonV2'
import { PageTitle } from 'components/PageTitle'

import { Color } from 'root-constants'

const commonSubtitleStyles = css`
  margin-bottom: 24px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: ${Color.GREY_100};
`

export const StyledDynamicPage = {
  Subtitle: styled.h3`
    ${commonSubtitleStyles}
  `,
  AnswerButtonV2: styled(AnswerButtonV2)`
    height: auto;
  `,
}

export const StyledCustomOptionsLeftToRight = {
  Subtitle: styled.h3`
    ${commonSubtitleStyles}
  `,
  AnswerButtonV2: styled(AnswerButtonV2)<{ iconSrc: string }>`
    min-height: ${({ iconSrc }) => (iconSrc ? '100' : '92')}px;
    height: auto;
    border-color: transparent;
    margin-bottom: 12px;

    h3 {
      font-size: 18px;
    }

    &::after {
      width: 20px;
      height: 20px;
      border-radius: 6px;
      background: none;
      border-color: #d5d6d6;
    }
  `,
}

export const StyledOptionsLeftToRightV2 = {
  PageTitle: styled(PageTitle)`
    line-height: 30px;
  `,
  Subtitle: styled.h3`
    ${commonSubtitleStyles};
    margin: 0 0 24px 0;
  `,

  AnswerButton: styled(AnswerButton)`
    min-height: ${({ iconSrc }) => (iconSrc ? '100' : '92')}px;
    height: auto;
    padding: 0 24px;
    border-color: transparent;

    h3 {
      font-size: 18px;
      line-height: 20px;
    }
  `,
}
