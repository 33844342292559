import { BmiValue } from 'value-constants'

import alarmExclamationIcon from 'assets/images/alarm-exclamation.svg'
import checkIcon from 'assets/images/green-check.svg'
import warningExclamationIcon from 'assets/images/warning-exclamation.svg'

export const VALUE_THRESHOLDS = [
  'commonComponents.bmiValues.normal',
  'commonComponents.bmiValues.overweight',
  'commonComponents.bmiValues.obesity',
]

export const INDEX_THRESHOLDS = [18.5, 25, 30, 35]

export const BMI_SCALE_MAP = {
  [BmiValue.UNDERWEIGHT]: {
    range: 0.05,
    minPercentValue: 0,
  },
  [BmiValue.NORMAL]: {
    range: 0.3,
    minPercentValue: 5,
  },
  [BmiValue.OVERWEIGHT]: {
    range: 0.3,
    minPercentValue: 35,
  },
  [BmiValue.OBESITY]: {
    range: 0.3,
    minPercentValue: 65,
  },
  [BmiValue.EXTREME_OBESITY]: {
    range: 0.05,
    minPercentValue: 95,
  },
}

export const INFO_BMI_MAP = {
  [BmiValue.NORMAL]: {
    color: '#ECFBF2',
    icon: checkIcon,
    title: 'onboarding.profileSummaryV2.cardInfo.healthyTitle',
    info: 'onboarding.profileSummaryV2.cardInfo.healthyInfo',
  },
  [BmiValue.OVERWEIGHT]: {
    color: '#FFF2E3',
    icon: warningExclamationIcon,
    title: 'onboarding.profileSummaryV2.cardInfo.riskTitle',
    info: 'onboarding.profileSummaryV2.cardInfo.riskInfo',
  },
  [BmiValue.OBESITY]: {
    color: '#FFE3E3',
    icon: alarmExclamationIcon,
    title: 'onboarding.profileSummaryV2.cardInfo.riskTitle',
    info: 'onboarding.profileSummaryV2.cardInfo.riskInfo',
  },
  [BmiValue.EXTREME_OBESITY]: {
    color: '#FFE3E3',
    icon: alarmExclamationIcon,
    title: 'onboarding.profileSummaryV2.cardInfo.riskTitle',
    info: 'onboarding.profileSummaryV2.cardInfo.riskInfo',
  },
}
