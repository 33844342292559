import styled from 'styled-components'

import { SvgImage } from 'components/SvgImage'

import moneyBackBackground from 'assets/images/money-back-guarantee.png'

import { Color } from 'root-constants'

export const Title = styled.h2`
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
`
export const Content = styled.div`
  padding: 30px 20px 32px;
`
export const Text = styled.p`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: ${Color.DISABLED};

  a {
    text-decoration: underline;
    color: ${Color.DISABLED};
  }
`
export const Badge = styled(SvgImage)`
  position: absolute;
  bottom: -68px;
  right: 0;
  width: 92px;
`

export const StyledMoneyBackGuaranteeV2 = {
  Wrapper: styled.div`
    position: relative;
    margin: 20px 0 85px;
    border-radius: 24px;
    background: url(${moneyBackBackground}) no-repeat;
    background-size: 100% 100%;
  `,
}
