import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { ActivityDuringWorkValue } from 'value-constants'

import { AnswerButtonV2 } from 'components/AnswerButtonV2'
import { Container } from 'components/Container'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { CustomPageId } from 'page-constants'
import { Language } from 'root-constants'

import { OPTION_IMAGES } from './constants'

export const ActivityDuringWorkV2: React.FC<TPageProps> = ({
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const gender = useSelector(selectCurrentUserGender)

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId: CustomPageId.ACTIVITY_DURING_WORK,
    question: t('onboarding.activityDuringWork.title', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: CustomPageId.ACTIVITY_DURING_WORK,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [handleChange, setIsAnswersDisabled, handleContinue],
  )

  return (
    <Container>
      <PageTitle marginBottom={24}>
        {t('onboarding.activityDuringWork.title')}
      </PageTitle>
      <Option
        {...optionProps}
        marginBottom="12"
        disabled={isAnswersDisabled}
        value={ActivityDuringWorkValue.PASSIVE}
      >
        <AnswerButtonV2
          iconSrc={OPTION_IMAGES[gender][ActivityDuringWorkValue.PASSIVE]}
        >
          <h3>
            <Trans i18nKey="onboarding.activityDuringWork.options.passive" />
          </h3>
        </AnswerButtonV2>
      </Option>
      <Option
        {...optionProps}
        marginBottom="12"
        disabled={isAnswersDisabled}
        value={ActivityDuringWorkValue.ACTIVE}
      >
        <AnswerButtonV2
          iconSrc={OPTION_IMAGES[gender][ActivityDuringWorkValue.ACTIVE]}
        >
          <h3>{t('onboarding.activityDuringWork.options.active')}</h3>
        </AnswerButtonV2>
      </Option>
      <Option
        {...optionProps}
        marginBottom="12"
        disabled={isAnswersDisabled}
        value={ActivityDuringWorkValue.NORMAL}
      >
        <AnswerButtonV2
          iconSrc={OPTION_IMAGES[gender][ActivityDuringWorkValue.NORMAL]}
        >
          <h3>{t('onboarding.activityDuringWork.options.normal')}</h3>
        </AnswerButtonV2>
      </Option>
    </Container>
  )
}
