import styled, { css, keyframes } from 'styled-components'

import { Color } from 'root-constants'

const commonTextStyles = css`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: ${Color.GREEN_300};
`
type TProps = {
  hasMultipleMonths?: boolean
  hasMultipleYears?: boolean
  hasMultipleDays?: boolean
}

export const datesAnimation = keyframes`
  0% {margin-top: 0;}
  4.1% {margin-top: -16px;}
  8.2% {margin-top: -32px;}
  12.3% {margin-top: -32px;}
  16.4% {margin-top: -48px;}
  20.5% {margin-top: -60px;}
  24.6% {margin-top: -60px;}
  28.7% {margin-top: -72px;}
  32.8% {margin-top: -84px;} 
  36.9% {margin-top: -90px;}
  41% {margin-top: -106px;}
  45.1% {margin-top: -122px;}
  49.2% {margin-top: -122px;}
  53.3% {margin-top: -138px;}
  57.4% {margin-top: -154px;}
  60.5% {margin-top: -154px;}
  64.6% {margin-top: -170px;}
  68.7% {margin-top: -186px;}
  72.8% {margin-top: -190px;}
  76.9% {margin-top: -198px;}
  81% {margin-top: -208px;}
  85.1% {margin-top: -216px;}
  89.2% {margin-top: -220px;}
  100% {margin-top: -226px;}
`

export const yearsAndMonthsAnimation = keyframes`
    0% {margin-top: 0;}
    50% {margin-top: -16px;}
    100% {margin-top: -32px;}
`

export const StyledWeightLossDateV2 = {
  Subtitle: styled.p`
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    text-align: center;
    color: ${Color.GREY_100};
    margin: 0 0 4px 0;
  `,
  PredictionBlock: styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    margin: 0 0 20px 0;
  `,

  Text: styled.p`
    ${commonTextStyles};
    color: ${Color.GREY_100};
    margin: 0 6px 0 0;

    span {
      color: ${Color.GREEN_300};
    }
  `,
  Date: styled.div`
    display: flex;
    height: 32px;
    width: 146px;
  `,
  MonthsContainer: styled.p<TProps>`
    display: flex;
    flex-direction: column;
    margin: 0 6px 0 0;

    ${({ hasMultipleMonths }) =>
      hasMultipleMonths &&
      css`
        & > :first-child {
          margin-top: -32px;
          animation: ${yearsAndMonthsAnimation} 1.2s linear;
        }
      `}
  `,
  MonthsValue: styled.span`
    ${commonTextStyles};
    text-transform: capitalize;
  `,
  DayContainer: styled.p<TProps>`
    display: flex;
    flex-direction: column;
    min-width: 34px;

    ${({ hasMultipleDays }) =>
      hasMultipleDays &&
      css`
        margin: 2px 0;

        & > :first-of-type {
          margin-top: -226px;
          animation: ${datesAnimation} 1.2s linear;
        }
      `}
  `,
  DayValue: styled.span`
    ${commonTextStyles};
    margin: 0 6px 0 0;
  `,
  YearContainer: styled.p<TProps>`
    display: flex;
    flex-direction: column;

    ${({ hasMultipleYears }) =>
      hasMultipleYears &&
      css`
        & > :first-child {
          margin-top: -32px;
          animation: ${yearsAndMonthsAnimation} 1.2s linear;
        }
      `}
  `,
  YearValue: styled.span`
    ${commonTextStyles};
  `,
}
