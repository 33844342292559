import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledIconsBulletList = {
  ListContainer: styled.div`
    width: 100%;
  `,
  Title: styled.h4`
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: ${Color.GREY_100};
    margin: 0 0 24px 0;
  `,
  List: styled.ul`
    border-radius: 16px;
    padding: 16px;
    box-shadow: 0 12px 32px 0 #0000001f;
  `,
  ListItem: styled.li<{ emoji: string }>`
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: ${Color.BLACK};
    position: relative;
    padding: 0 0 0 32px;

    :not(:last-of-type) {
      margin: 0 0 16px 0;
    }

    &::before {
      content: '${({ emoji }) => emoji}';
      padding: 0 8px 0 0;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      font-size: 24px;
    }
  `,
}
