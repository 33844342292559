import { Currency } from 'modules/purchase/constants'

export const DEFAULT_DISCOUNT_PERCENTAGE = 25
export const DEFAULT_FULL_PRICE = 99.99

export const WEIGHT_LOSS_GUIDE_PRICE = {
  [Currency.USD]: '69.99',
  [Currency.EUR]: '61.99',
  [Currency.MXN]: '1373.70',
}

export const ALTERNATIVE_WEIGHT_LOSS_GUIDE_PRICE = {
  [Currency.USD]: '49.99',
  [Currency.EUR]: '44.28',
  [Currency.MXN]: '981.16',
}
