import React, {
  RefObject,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import {
  StripeCardCvcElement,
  StripeCardExpiryElement,
  StripeCardNumberElement,
} from '@stripe/stripe-js'

import { resetErrorAction } from 'root-redux/actions/common'
import { TAppDispatch } from 'root-redux/store/store'

import { useGetPageInfo } from 'hooks/useGetPageInfo'

import {
  EMPTY_FIELD_ERROR,
  THREEDS_REDIRECT_SEARCH_PARAM,
} from 'modules/purchase/constants'
import {
  getDefaultPaymentErrorsState,
  getPaymentErrorStateBySubmitWithUntouchedFields,
} from 'modules/purchase/helpers/rootHelpers'
import {
  check3DSecure,
  purchaseAction,
} from 'modules/purchase/redux/actions/common'
import { select3DSecureIframeUrl } from 'modules/purchase/redux/selects'
import { TCreditCardField, TPaymentErrorState } from 'modules/purchase/types'

import {
  OutbrainEventName,
  StripeFieldName,
  TaboolaEventName,
} from 'root-constants'

import {
  StyledCreditCardForm as S,
  stripeElementStyle,
} from './CreditCardForm.styles'

export const CreditCardForm: React.FC = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const { currentPageId } = useGetPageInfo()
  const stripe = useStripe()
  const elements = useElements()
  const dispatch: TAppDispatch = useDispatch()
  const cardNumberElemRef = useRef<StripeCardNumberElement | null>(null)
  const cardExpiryElemRef = useRef<StripeCardExpiryElement | null>(null)
  const cvcElemRef = useRef<StripeCardCvcElement | null>(null)
  const cardholderNameElemRef = useRef<HTMLInputElement>(null)
  const threeDSecureIframeUrl = useSelector(select3DSecureIframeUrl)

  const [errors, setErrors] = useState<TPaymentErrorState>(() =>
    getDefaultPaymentErrorsState(),
  )
  const [name, setName] = useState('')
  const hasErrors = useMemo(
    () => Object.values(errors).some((error) => error.isShown && error.error),
    [errors],
  )
  const hasUntouchedFields = useMemo(
    () =>
      Object.values(errors).some((error) => error.isShown && !error.isTouched),
    [errors],
  )
  const hasUncompletedFields = useMemo(
    () => Object.values(errors).some((field) => !field.isComplete),
    [errors],
  )
  const isFormValid = useMemo(
    () => !hasErrors && !hasUntouchedFields && !hasUncompletedFields,
    [hasErrors, hasUncompletedFields, hasUntouchedFields],
  )

  useEffect(() => {
    const URLParams = new URLSearchParams(search)
    const isSuccess = URLParams.has(THREEDS_REDIRECT_SEARCH_PARAM)

    if (!isSuccess || !threeDSecureIframeUrl || !stripe) return

    dispatch(check3DSecure(stripe))
  }, [dispatch, search, stripe, threeDSecureIframeUrl])

  const handleChange = useCallback(
    ({
      fieldName,
      isEmpty,
      hasError,
      isComplete,
      nextElemRef,
    }: {
      fieldName: StripeFieldName
      isEmpty: boolean
      hasError: boolean
      isComplete: boolean
      nextElemRef?: RefObject<TCreditCardField>
    }) => {
      dispatch(resetErrorAction())

      let error = ''

      if (hasError) {
        error = 'is invalid'
      }

      if (isEmpty) {
        error = EMPTY_FIELD_ERROR
      }

      if (nextElemRef && isComplete) {
        nextElemRef.current?.focus()
      }

      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: { isTouched: true, error, isComplete },
      }))
    },
    [dispatch],
  )

  const handleSubmit = useCallback(
    (e: SyntheticEvent<HTMLFormElement>) => {
      e.preventDefault()
      dispatch(resetErrorAction())

      if (hasUntouchedFields) {
        setErrors(getPaymentErrorStateBySubmitWithUntouchedFields(errors))
        return
      }

      if (hasErrors) return

      const card = elements?.getElement(CardNumberElement)

      if (!stripe || !card || !currentPageId) return

      dispatch(
        purchaseAction({
          stripe,
          card,
          name,
          paymentPageId: currentPageId,
        }),
      )

      window.obApi && window.obApi('track', OutbrainEventName.CHECKOUT)
      window._tfa &&
        window._tfa.push({
          notify: 'event',
          name: TaboolaEventName.CHECKOUT,
        })
    },
    [
      dispatch,
      hasUntouchedFields,
      hasErrors,
      elements,
      stripe,
      currentPageId,
      name,
      errors,
    ],
  )

  return (
    <S.Form onSubmit={handleSubmit}>
      <S.CardNumberElement
        onReady={(elem) => {
          elem.focus()
          cardNumberElemRef.current = elem
        }}
        options={{
          showIcon: false,
          placeholder: t('checkout.cardNumber'),
          style: stripeElementStyle,
        }}
        onChange={({ empty, error, complete }) => {
          handleChange({
            fieldName: StripeFieldName.NUMBER,
            isEmpty: empty,
            hasError: !!error,
            isComplete: complete,
            nextElemRef: cardExpiryElemRef,
          })
        }}
      />

      <S.CardExpiryElement
        onReady={(elem) => {
          cardExpiryElemRef.current = elem
        }}
        options={{
          placeholder: t('checkout.cardExpiry'),
          style: stripeElementStyle,
        }}
        onChange={({ empty, error, complete }) => {
          handleChange({
            fieldName: StripeFieldName.EXPIRY,
            isEmpty: empty,
            hasError: !!error,
            isComplete: complete,
            nextElemRef: cvcElemRef,
          })
        }}
      />

      <S.CardCvcElement
        onReady={(elem) => {
          cvcElemRef.current = elem
        }}
        options={{
          placeholder: t('checkout.securityNumber'),
          style: stripeElementStyle,
        }}
        onChange={({ empty, error, complete }) => {
          handleChange({
            fieldName: StripeFieldName.CVC,
            isEmpty: empty,
            hasError: !!error,
            isComplete: complete,
            nextElemRef: cardholderNameElemRef,
          })
        }}
      />

      <S.CardholderInput
        ref={cardholderNameElemRef}
        type="text"
        placeholder={t('checkout.cardholderName')}
        data-invalid={!!errors[StripeFieldName.NAME].error}
        onChange={(e: SyntheticEvent<HTMLInputElement>) => {
          const value = (e.target as HTMLInputElement).value.trim()

          setName(value)

          handleChange({
            fieldName: StripeFieldName.NAME,
            isEmpty: false,
            hasError: false,
            isComplete: true,
          })
        }}
      />

      <S.Button
        type="submit"
        disabled={!stripe || !isFormValid}
        disabledBackgroundColor="rgba(205, 206, 209, 0.85)"
      >
        {t('actions.continue')}
      </S.Button>
    </S.Form>
  )
}
