import styled from 'styled-components'

import { Logo } from 'components/Logo'

import pageBg from 'assets/images/intro-flow-bg.svg'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledUsersChoiceV2 = {
  Wrapper: styled.div`
    min-height: 100%;
    padding: 10px 0 24px;
    background-image: url(${pageBg});
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top center;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      background-size: ${MediaBreakpoint.MEDIUM_PHONE}px auto;
    }
  `,
  Container: styled.div`
    margin: 0 auto;
    padding: 0 16px;
  `,
  Title: styled.h1`
    font-weight: 800;
    font-size: 32px;
    line-height: 32px;
    text-align: center;
    margin-bottom: 4px;
  `,
  ImageContainer: styled.div`
    aspect-ratio: 272/346;
    max-height: 346px;
    margin: 0 auto 32px;
  `,
  Subtitle: styled.p`
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 44px;
  `,
}

export const StyledAlternativeUsersChoiceV2 = {
  Logo: styled(Logo)`
    left: 0;
    padding-top: 8px;
    position: absolute;
    top: 0;
    width: 100%;
  `,
  Banner: styled.div`
    aspect-ratio: 90 / 103;
    margin: 0 auto 16px;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    width: 100%;
  `,
  Title: styled.h2`
    color: ${Color.GREY_100};
    display: flex;
    flex-direction: column;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 64px;
    row-gap: 12px;
    text-align: center;

    strong {
      font-size: 36px;
      font-weight: 700;
      line-height: 32px;
    }
  `,
}
