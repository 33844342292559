import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { AnswerButtonV2 } from 'components/AnswerButtonV2'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledPlanPace as S } from './PlanPace.styles'
import { ANSWER_OPTIONS, QUESTION } from './constants'

export const PlanPace: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, setIsAnswersDisabled, handleContinue],
  )

  return (
    <S.Container>
      <PageTitle marginBottom={24}>{t('onboarding.planPace.title')}</PageTitle>
      {ANSWER_OPTIONS.map(({ value, title }) => (
        <Option
          key={value}
          {...optionProps}
          marginBottom="12"
          disabled={isAnswersDisabled}
          value={value}
        >
          <AnswerButtonV2>
            <h3>{t(title)}</h3>
          </AnswerButtonV2>
        </Option>
      ))}
    </S.Container>
  )
}
