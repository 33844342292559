import { useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'

import { selectUUID } from 'root-redux/selects/user'

export const useFacebookPixelInitialization = (
  facebookPixelIds: string[],
): void => {
  const uuid = useSelector(selectUUID)

  useLayoutEffect(() => {
    !!facebookPixelIds.length &&
      facebookPixelIds.forEach((facebookPixelId) => {
        window.fbq('init', facebookPixelId, { external_id: uuid })
      })
    window.fbq('track', 'PageView')
  }, [facebookPixelIds, uuid])
}
