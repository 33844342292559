import React from 'react'
import { useTranslation } from 'react-i18next'

import { useTargetHiddenOnScroll } from 'hooks/useTargetHiddenOnScroll'

import { FIFTEEN_MINUTES_IN_SECONDS } from 'modules/purchase/constants'

import { StyledIntroMediaEmail as S } from './IntroMediaEmail.styles'

type TProps = {
  elemForComparisonRef: React.RefObject<HTMLHeadingElement>
}

export const IntroMediaEmail: React.FC<TProps> = ({ elemForComparisonRef }) => {
  const { t } = useTranslation()
  const isTargetHidden = useTargetHiddenOnScroll(elemForComparisonRef)

  return (
    <S.Promo>
      <S.PromoTitle>{t('purchaseEmail.promoTitle')}</S.PromoTitle>
      <S.PromoTimer isTimerHidden={isTargetHidden}>
        <S.TimerLabel isTimerHidden={isTargetHidden}>
          {t('purchaseEmail.promoTimer')}{' '}
        </S.TimerLabel>
        <S.Timer
          isHeaderTimer={isTargetHidden}
          secondsToEnd={FIFTEEN_MINUTES_IN_SECONDS}
        />
      </S.PromoTimer>
    </S.Promo>
  )
}
