import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  selectCurrentUserCurrentWeight,
  selectUserMeasurementSystem,
} from 'root-redux/selects/common'

import emptyGlassIcon from 'assets/images/sprite/empty-glass.svg'
import fullGlassIcon from 'assets/images/sprite/full-glass.svg'

import { MeasurementSystem } from 'root-constants'

import {
  GlassImg,
  Header,
  HeaderLabel,
  HeaderSubtitle,
  HeaderTitle,
  StyledWaterConsumption as S,
} from './WaterConsumption.styles'
import {
  GLASS_AVERAGE_VOLUME_IN_LITERS,
  GLASS_AVERAGE_VOLUME_IN_OUNCE,
  MAX_NUMBERS_OF_WATER_GLASSES,
  WATER_CONSUMPTION_IMPERIAL_MULTIPLIER,
  WATER_CONSUMPTION_METRIC_MULTIPLIER,
} from './constants'

export const WaterConsumption = () => {
  const { t } = useTranslation()

  const currentMeasurementSystem = useSelector(selectUserMeasurementSystem)
  const currentUserWeight = useSelector(selectCurrentUserCurrentWeight)

  const isMetricSystemSelected = useMemo(
    () => currentMeasurementSystem === MeasurementSystem.METRIC,
    [currentMeasurementSystem],
  )

  const waterConsumption = useMemo(() => {
    if (isMetricSystemSelected) {
      return WATER_CONSUMPTION_METRIC_MULTIPLIER * Number(currentUserWeight)
    }

    return WATER_CONSUMPTION_IMPERIAL_MULTIPLIER * Number(currentUserWeight)
  }, [currentUserWeight, isMetricSystemSelected])

  const requiredNumbersOfGlasses = useMemo(
    () =>
      isMetricSystemSelected
        ? Math.round(waterConsumption / GLASS_AVERAGE_VOLUME_IN_LITERS)
        : Math.round(waterConsumption / GLASS_AVERAGE_VOLUME_IN_OUNCE),
    [waterConsumption, isMetricSystemSelected],
  )

  const getFullGlassesSubsequence = useCallback(
    (numbersOfFullGlasses: number) => {
      const fullGlassesSubsequenceWithLimitation =
        numbersOfFullGlasses <= MAX_NUMBERS_OF_WATER_GLASSES
          ? numbersOfFullGlasses
          : MAX_NUMBERS_OF_WATER_GLASSES
      const fullGlassesSubsequence: number[] = []
      for (let i = 1; i <= fullGlassesSubsequenceWithLimitation; i += 1) {
        fullGlassesSubsequence.push(i)
      }
      return fullGlassesSubsequence
    },
    [],
  )

  const getEmptyGlassesSubsequence = useCallback(
    (numbersOfFullGlasses: number) => {
      const emptyGlassesSubsequence: number[] = []
      for (
        let i = numbersOfFullGlasses;
        i < MAX_NUMBERS_OF_WATER_GLASSES;
        i += 1
      ) {
        emptyGlassesSubsequence.push(i)
      }
      return emptyGlassesSubsequence
    },
    [],
  )

  return (
    <S.Root>
      <Header>
        <div>
          <HeaderTitle>{t('result.waterConsumption.headerTitle')}</HeaderTitle>
          <HeaderSubtitle>
            {t('result.waterConsumption.headerSubtitle')}
          </HeaderSubtitle>
        </div>
        <HeaderLabel>
          {!(waterConsumption % 1)
            ? waterConsumption
            : waterConsumption.toFixed(2)}{' '}
          {isMetricSystemSelected ? 'L' : 'OZ'}
        </HeaderLabel>
      </Header>
      <S.WaterGlasses>
        {getFullGlassesSubsequence(requiredNumbersOfGlasses).map((item) => (
          <S.WaterGlass key={item}>
            <GlassImg svg={fullGlassIcon} />
          </S.WaterGlass>
        ))}
        {getEmptyGlassesSubsequence(requiredNumbersOfGlasses).map((item) => (
          <S.WaterGlass key={item}>
            <GlassImg svg={emptyGlassIcon} />
          </S.WaterGlass>
        ))}
      </S.WaterGlasses>
    </S.Root>
  )
}
