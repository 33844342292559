import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledBadHabits = {
  SubTitle: styled.p`
    color: ${Color.GREY_100};
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 24px;
    text-align: center;
  `,
}
