import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { AnswerButton } from 'components/AnswerButton'
import { Container } from 'components/Container'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import cheeseSoupIcon from 'assets/images/cheese-soup.png'
import chickenSoupIcon from 'assets/images/chicken-soup.png'
import cupOfMilkIcon from 'assets/images/cup-of-milk.png'
import saladIcon from 'assets/images/dinner-vegi-salad.png'

import { OPTION_VALUES, QUESTION } from './constants'

export const DinnerTime: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, setIsAnswersDisabled, handleContinue],
  )

  return (
    <Container>
      <PageTitle marginBottom={24}>
        {t('onboarding.dinnerTime.title')}
      </PageTitle>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.FOUR_SIX_PM}
      >
        <AnswerButton iconSrc={chickenSoupIcon}>
          <h3>{t('onboarding.dinnerTime.options.fourSix')}</h3>
        </AnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.SIX_EIGHT_PM}
      >
        <AnswerButton iconSrc={cheeseSoupIcon}>
          <h3>{t('onboarding.dinnerTime.options.sixEight')}</h3>
        </AnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.EIGHT_TEN_PM}
      >
        <AnswerButton iconSrc={saladIcon}>
          <h3>{t('onboarding.dinnerTime.options.eightTen')}</h3>
        </AnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.SKIP}
      >
        <AnswerButton iconSrc={cupOfMilkIcon}>
          <h3>{t('onboarding.dinnerTime.options.skip')}</h3>
        </AnswerButton>
      </Option>
    </Container>
  )
}
