import React, {
  FC,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useMemo,
  useRef,
} from 'react'

import { t } from 'i18next'
import { Button } from 'storybook-ui'

import { useGetPageInfo } from 'hooks/useGetPageInfo'

import {
  HEADER_HEIGHT,
  PROGRESS_BAR_HEIGHT,
  PROGRESS_BAR_MARGIN_BOTTOM,
} from 'root-constants'

import { StyledKitPageContainer as S } from './KitPageContainer.styles'
import { CONTINUE_BUTTON_IS_STICKY } from './constants'

type TProps = {
  children: ReactNode
  paddingTop?: number
  className?: string
  hasContinueButton?: boolean
  hasSkipButton?: boolean
  continueButtonType?: 'submit' | 'button'
  isContinueButtonDisabled?: boolean
  onContinueButtonClick?: (event: SyntheticEvent<HTMLButtonElement>) => void
  onSkipButtonClick?: (event: SyntheticEvent<HTMLButtonElement>) => void
  continueButtonContent?: ReactNode
  skipButtonContent?: ReactNode
  bottomContent?: ReactNode
}

export const KitPageContainer: FC<TProps> = ({
  children,
  paddingTop,
  className,
  hasContinueButton = false,
  hasSkipButton = false,
  isContinueButtonDisabled = false,
  continueButtonContent = t('actions.continue'),
  continueButtonType = 'button',
  skipButtonContent = t('actions.skipQuestion'),
  bottomContent,
  onContinueButtonClick,
  onSkipButtonClick,
}) => {
  const stickyButtonContainerRef = useRef<HTMLDivElement>(null)
  const { hasHeader, hasProgressbar } = useGetPageInfo()

  const headerWithProgressBarHeight = useMemo(() => {
    if (hasHeader && hasProgressbar) {
      return HEADER_HEIGHT + PROGRESS_BAR_HEIGHT
    }

    if (hasHeader) {
      return HEADER_HEIGHT
    }

    return 0
  }, [hasHeader, hasProgressbar])

  // TODO: remove the 'marginTop' prop after implementation of the UI kit for all relevant cohorts
  const marginTop = useMemo(() => {
    if (hasHeader && hasProgressbar) {
      return -PROGRESS_BAR_MARGIN_BOTTOM
    }

    return 0
  }, [hasHeader, hasProgressbar])

  useEffect(() => {
    const stickyButtonContainer = stickyButtonContainerRef.current
    let observer: IntersectionObserver | null = null

    if (stickyButtonContainer) {
      observer = new IntersectionObserver(
        ([e]) =>
          e.target.classList.toggle(
            CONTINUE_BUTTON_IS_STICKY,
            e.intersectionRatio < 1,
          ),
        { threshold: [1], rootMargin: '0px 0px -1px 0px' },
      )

      observer.observe(stickyButtonContainer)
    }

    return () => {
      observer?.disconnect()
    }
  }, [stickyButtonContainerRef.current])

  return (
    <S.Wrapper
      marginTop={marginTop}
      headerWithProgressBarHeight={headerWithProgressBarHeight}
      className={className}
    >
      <S.Container
        paddingTop={paddingTop}
        hasContinueButton={hasContinueButton}
      >
        {hasContinueButton ? (
          <>
            <S.Content>
              {children}
              {!!bottomContent && (
                <S.BottomContent>{bottomContent}</S.BottomContent>
              )}
            </S.Content>
            <S.StickyButtonContainer ref={stickyButtonContainerRef}>
              <Button
                width="100%"
                type={continueButtonType}
                margin={hasSkipButton ? '0 auto 24px auto' : '0 auto'}
                theme="nutrimatePrimary"
                disabled={isContinueButtonDisabled}
                onClick={onContinueButtonClick}
              >
                {continueButtonContent}
              </Button>
              {hasSkipButton && (
                <S.SkipButton onClick={onSkipButtonClick}>
                  {skipButtonContent}
                </S.SkipButton>
              )}
            </S.StickyButtonContainer>
          </>
        ) : (
          <>
            {children}
            {!!bottomContent && (
              <S.BottomContent hasContinueButton={false}>
                {bottomContent}
              </S.BottomContent>
            )}
          </>
        )}
      </S.Container>
    </S.Wrapper>
  )
}
