import { useSelector } from 'react-redux'

import { PaymentMethod } from 'modules/purchase/constants'
import {
  select3DSecureIframeUrl,
  selectCurrency,
  selectOneClickPaymentMethod,
  selectPaymentMethod,
  selectProductId,
  selectSubscriptionFullPrice,
  selectSubscriptionId,
  selectSubscriptionIntroDifAmount,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
  selectTrialSubscriptionFullPriceBeforeDiscount,
} from 'modules/purchase/redux/selects'

import { TimeInterval } from 'root-constants'

type TReturnsUsePaymentStore = {
  currency: string
  discountAmount: number
  fullPrice: number
  periodName: TimeInterval | null
  periodQuantity: number
  product: string
  selectedSubscriptionId: string
  threeDSecureIframeUrl: string
  trialCurrentPrice: number
  trialPriceBeforeDiscount: number
  trialPeriodDays: number
  oneClickMethod: PaymentMethod | null
  paymentMethod: PaymentMethod
}

export const usePaymentStore = (): TReturnsUsePaymentStore => {
  const fullPrice = useSelector(selectSubscriptionFullPrice)
  const periodName = useSelector(selectSubscriptionPeriodName)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)
  const selectedSubscriptionId = useSelector(selectSubscriptionId)
  const threeDSecureIframeUrl = useSelector(select3DSecureIframeUrl)
  const trialCurrentPrice = useSelector(selectSubscriptionTrialPeriodPrice)
  const trialPriceBeforeDiscount = useSelector(
    selectTrialSubscriptionFullPriceBeforeDiscount,
  )
  const trialPeriodDays = useSelector(selectSubscriptionTrialPeriodDays)
  const currency = useSelector(selectCurrency)
  const discountAmount = useSelector(selectSubscriptionIntroDifAmount)
  const product = useSelector(selectProductId)
  const oneClickMethod = useSelector(selectOneClickPaymentMethod)
  const paymentMethod = useSelector(selectPaymentMethod)

  return {
    currency,
    discountAmount,
    fullPrice,
    oneClickMethod,
    periodName,
    periodQuantity,
    product,
    selectedSubscriptionId,
    threeDSecureIframeUrl,
    trialCurrentPrice,
    trialPriceBeforeDiscount,
    trialPeriodDays,
    paymentMethod,
  }
}
