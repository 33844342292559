import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Container } from 'components/Container'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledBodyTypeV3 as S } from './BodyTypeV3.styles'
import { DREAM_BODY_OPTIONS_MAP, QUESTION } from './constants'

export const BodyTypeV3: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, setIsAnswersDisabled, handleContinue],
  )

  return (
    <Container>
      <PageTitle marginBottom={24}>
        {t('onboarding.bodyType.titleV3')}
      </PageTitle>
      {DREAM_BODY_OPTIONS_MAP.map(({ title, icon, value }) => (
        <Option
          key={value}
          {...optionProps}
          marginBottom="12"
          disabled={isAnswersDisabled}
          value={value}
        >
          <S.AnswerButtonV2 iconSrc={icon}>
            <h3>{t(title)}</h3>
          </S.AnswerButtonV2>
        </Option>
      ))}
    </Container>
  )
}
