import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledUserInfoBlockV3 = {
  Container: styled.div`
    width: 100%;
    display: flex;
    border-radius: 16px;
    border: 1px solid ${Color.GREY_800};
    background-color: ${Color.WHITE};
    padding: 16px;
  `,
  InfoItem: styled.div<{ bulletSrc?: string }>`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 50%;
    padding: 0 0 0 48px;

    &:before {
      position: absolute;
      content: url(${({ bulletSrc }) => bulletSrc});
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 36px;
      height: 36px;
    }
  `,

  Label: styled.h3`
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: ${Color.GREY_101};
    margin: 0 0 2px 0;
  `,
  InfoValue: styled.h3`
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: ${Color.GREY_100};
  `,
  Divider: styled.div`
    margin: 0 14px;
    width: 1px;
    height: 36px;
    background-color: ${Color.GREY_800};
  `,
}
