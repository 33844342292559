import defined from 'assets/images/defined-body.png'
import muscular from 'assets/images/muscular-body.png'
import toned from 'assets/images/toned-body.png'

export const QUESTION = 'What is your dream body?'

const enum MENS_DREAM_BODY_VALUES {
  TONED = 'toned',
  DEFINED = 'defined',
  MUSCULAR = 'muscular',
}

export const DREAM_BODY_OPTIONS_MAP = [
  {
    value: MENS_DREAM_BODY_VALUES.TONED,
    title: 'onboarding.bodyType.optionsV3.toned',
    icon: toned,
  },
  {
    value: MENS_DREAM_BODY_VALUES.DEFINED,
    title: 'onboarding.bodyType.optionsV3.defined',
    icon: defined,
  },
  {
    value: MENS_DREAM_BODY_VALUES.MUSCULAR,
    title: 'onboarding.bodyType.optionsV3.muscular',
    icon: muscular,
  },
]
