import styled, { css } from 'styled-components'

import { PageTitle } from 'components/PageTitle'

import { Color, Emojis } from 'root-constants'

const commonTextStyles = css`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${Color.GREY_100};
`

export const StyledHelpCalmCravings = {
  ImageContainer: styled.div`
    aspect-ratio: 280/240;
    height: 240px;
    margin: 0 auto 16px auto;
  `,
  PageTitle: styled(PageTitle)`
    font-size: 22px;
    line-height: 30px;
    margin: 0 0 24px 0;

    span {
      color: ${Color.PRIMARY};
    }
  `,
  ListDescription: styled.p`
    ${commonTextStyles};
    margin: 0 0 12px 0;
  `,
  List: styled.ul`
    margin: 0 0 8px 0;
  `,

  ListItem: styled.li`
    ${commonTextStyles};

    padding: 0 0 0 28px;
    position: relative;

    &:not(:last-of-type) {
      margin: 0 0 12px 0;
    }

    &::before {
      position: absolute;
      content: '${Emojis.STAR}';
      left: 0;
      top: 0;
      font-size: 20px;
    }
  `,
}
