import React, { SyntheticEvent, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { BodyCalculation } from 'components/BodyCalculation'
import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { PageTitle } from 'components/PageTitle'
import { SvgImage } from 'components/SvgImage'

import { setMultipleAnswerAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'
import { selectUserMeasurementSystem } from 'root-redux/selects/common'

import { IInputState, TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import alertIcon from 'assets/images/sprite/alert-circle.svg'

import { goTo } from 'browser-history'
import { CustomPageId } from 'page-constants'
import {
  FloatNumberRegex,
  INITIAL_INPUT_VALUE,
  MeasurementSystem,
  WEIGHT_UNITS,
} from 'root-constants'

import { StyledCurrentWeightV2 as S } from './CurrentWeightV2.styles'
import { MinMaxWeight, QUESTION } from './constants'

export const CurrentWeightV2: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const currentMeasurementSystem = useSelector(selectUserMeasurementSystem)

  const [weight, setWeight] = useState<IInputState>({
    ...INITIAL_INPUT_VALUE,
  })
  const [isFocused, setIsFocused] = useState(false)

  const isMetricSystemSelected = useMemo(
    () => currentMeasurementSystem === MeasurementSystem.METRIC,
    [currentMeasurementSystem],
  )

  const handleSubmit = useCallback(
    (e: SyntheticEvent<HTMLFormElement>) => {
      e.preventDefault()

      dispatch(
        setMultipleAnswerAction({
          answers: {
            [CustomPageId.CURRENT_WEIGHT]: +weight.value,
            measurement_system: isMetricSystemSelected
              ? MeasurementSystem.METRIC
              : MeasurementSystem.IMPERIAL,
          },
        }),
      )

      eventLogger.logQuestion({
        question: QUESTION,
        answers: `${weight.value},${
          isMetricSystemSelected
            ? MeasurementSystem.METRIC
            : MeasurementSystem.IMPERIAL
        }`,
      })
      dispatch(sendUserAnswersAction())
      goTo(nextPagePath)
    },
    [dispatch, weight.value, isMetricSystemSelected, nextPagePath],
  )

  const handleChange = useCallback(({ target: { value, validity } }) => {
    if (!value || FloatNumberRegex.test(value)) {
      setWeight({
        value,
        isValid: validity.valid,
      })
    }
  }, [])

  const handleFocus = useCallback(() => {
    setIsFocused(true)
  }, [])

  const handleBlur = useCallback(() => {
    setIsFocused(false)
  }, [])

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <PageTitle marginBottom={24}>
          {t('onboarding.currentWeight.title2')}
        </PageTitle>
        <S.Actions>
          <S.WeightLabel>
            <S.Label>
              {isMetricSystemSelected
                ? WEIGHT_UNITS.metric
                : WEIGHT_UNITS.imperial}
            </S.Label>
          </S.WeightLabel>
          <S.InputContainer>
            <S.InputWrapper>
              <S.WeightInput
                isValid={weight.isValid}
                value={weight.value}
                type="number"
                min={
                  isMetricSystemSelected
                    ? MinMaxWeight.MIN_WEIGHT_KG
                    : MinMaxWeight.MIN_WEIGHT_LB
                }
                max={
                  isMetricSystemSelected
                    ? MinMaxWeight.MAX_WEIGHT_KG
                    : MinMaxWeight.MAX_WEIGHT_LB
                }
                lang="en"
                step={isMetricSystemSelected ? '0.1' : '1'}
                onChange={handleChange}
                allowFloatNumbers={isMetricSystemSelected}
                onBlur={handleBlur}
                onFocus={handleFocus}
              />
              {!isFocused && !weight.value && <S.Placeholder />}
              <S.Suffix>
                {isMetricSystemSelected
                  ? WEIGHT_UNITS.metric
                  : WEIGHT_UNITS.imperial}
              </S.Suffix>
            </S.InputWrapper>
          </S.InputContainer>
          {weight.value && !weight.isValid && (
            <S.ErrorMessage>
              <SvgImage svg={alertIcon} />
              <span>{t('onboarding.currentWeight.errorMessage')}</span>
            </S.ErrorMessage>
          )}
        </S.Actions>
        {weight.isValid && (
          <BodyCalculation
            isMetric={isMetricSystemSelected}
            weight={weight.value}
          />
        )}
      </Container>
      <S.ButtonContainer isValid={weight.isValid} value={weight.value}>
        <Button type="submit" disabled={!weight.isValid}>
          {t('actions.continue')}
        </Button>
      </S.ButtonContainer>
    </form>
  )
}
