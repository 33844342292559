import styled, { css } from 'styled-components'

import checkIcon from 'assets/images/check-white.svg'
import priceBadgeUrl from 'assets/images/price-badge.svg'
import selectedPriceBadgeUrl from 'assets/images/selected-price-badge.svg'

import { Color } from 'root-constants'

type TProps = {
  isSelected?: boolean
}

const commonPriceStyles = css`
  position: relative;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: ${Color.DISABLED};
`

export const StyledSubscriptionItemV4 = {
  Wrapper: styled.div<TProps>`
    background: ${Color.WHITE};
    border: ${({ isSelected }) =>
      `2px solid ${isSelected ? Color.PRIMARY : Color.GREY_WHITE}`};
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 14px 0;
    position: relative;
  `,
  PriceContainer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  `,
  PreviousPrice: styled.span`
    font-size: 16px;
    margin-right: 6px;
    ${commonPriceStyles};

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 115%;
      height: 1px;
      background-color: ${Color.RED_200};
    }
  `,
  CustomPrice: styled.p<TProps>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 87px;
    min-height: 48px;
    margin-right: 10px;
    color: rgba(19, 29, 48, 0.5);
    transition: background 0.3s ease-out;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${priceBadgeUrl});
    background-size: 100%;

    ${({ isSelected }) =>
      isSelected &&
      `
        background-image: url(${selectedPriceBadgeUrl});
        color: #ffffff;
    `};
  `,
  CurrentPrice: styled.span`
    ${commonPriceStyles};
  `,
  PriceValue: styled.span`
    margin: 10px 0 0 24px;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
  `,
  TimePeriod: styled.span`
    margin-left: 24px;
    font-weight: 700;
    font-size: 8px;
    line-height: 10px;
  `,
  Content: styled.div<TProps>`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:after {
      content: '';
      width: 20px;
      height: 20px;
      mix-blend-mode: normal;
      border: 2px solid rgba(19, 29, 48, 0.1);
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 14px;
      transform: translateY(-50%);
      transition: background-color 0.3s ease-out;
      ${({ isSelected }) =>
        isSelected &&
        `
          background-image: url(${checkIcon});
          background-position: center center;
          background-repeat: no-repeat;
          background-color: #8cd9ae;
          border: none;
        `};
    }
  `,
  MostPopularBadge: styled.div<TProps>`
    background-color: ${Color.PRIMARY};
    border-radius: 24px;
    color: ${Color.WHITE};
    font-size: 10px;
    font-weight: 700;
    left: 42px;
    line-height: 12px;
    padding: 4px 8px;
    position: absolute;
    text-transform: uppercase;
    top: -10px;
  `,
  PlanContainer: styled.span<TProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding-left: 42px;
  `,
  PlanPeriod: styled.span<TProps>`
    font-size: 16px;
    font-weight: 800;
    line-height: 20px;
    text-transform: uppercase;
    color: ${({ isSelected }) =>
      isSelected ? Color.GREY_100 : Color.DISABLED};
  `,
}
