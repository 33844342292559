import styled, { css } from 'styled-components'

import { Button } from 'components/Button'
import { StyledButtonBase } from 'components/Button/Button.styles'

export const StyledLogin = {
  Title: styled.h1`
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  `,
  Form: styled.form`
    max-width: 464px;
    margin: 0 auto;
  `,
  Button: styled(Button)`
    max-width: 328px;
  `,
  DisclaimerContainer: styled.div`
    position: relative;
  `,
  SignUpLink: styled.p`
    margin: 24px 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    pointer-events: none;

    strong {
      font-weight: 600;
      text-decoration: underline;
      pointer-events: auto;
      cursor: pointer;
    }
  `,
  Separator: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px auto;
    max-width: 464px;
  `,
  SeparatorLine: styled.div`
    flex: 1;
    height: 1px;
    background-color: #dadadd;
  `,
  SeparatorText: styled.span`
    padding: 0 5px;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
  `,
  FooterContainer: styled.div`
    display: none;
    margin-top: 16px;
  `,
}

const StyledLoginButtonBase = css`
  ${StyledButtonBase};
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 328px;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
`

export const StyledLoginButton = {
  Email: styled.button`
    ${StyledLoginButtonBase};

    &[data-icon='false'] {
      background-image: none;
    }
  `,
  Facebook: styled.button`
    ${StyledLoginButtonBase};
    background-color: #395a98;
  `,
  Apple: styled.button`
    ${StyledLoginButtonBase};
    background-color: #010002;

    & > span {
      margin-left: 3px;
    }
  `,
}
