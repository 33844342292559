import { BmiValue } from 'value-constants'

import { Gender } from 'root-constants'

const MEN_ANIMATION_NORMAL = '/animations/paywall-male-normal'
const MEN_ANIMATION_OVERWEIGHT = '/animations/paywall-male-overweight'
const MEN_ANIMATION_OBESE = '/animations/paywall-male-obese'

const WOMEN_ANIMATION_NORMAL = '/animations/paywall-female-normal'
const WOMEN_ANIMATION_OVERWEIGHT = '/animations/paywall-female-overweight'
const WOMEN_ANIMATION_OBESE = '/animations/paywall-female-obese'

export const ANIMATION_PATH_MAP = {
  [Gender.MALE]: {
    [BmiValue.UNDERWEIGHT]: MEN_ANIMATION_NORMAL,
    [BmiValue.NORMAL]: MEN_ANIMATION_NORMAL,
    [BmiValue.OVERWEIGHT]: MEN_ANIMATION_OVERWEIGHT,
    [BmiValue.OBESITY]: MEN_ANIMATION_OBESE,
    [BmiValue.EXTREME_OBESITY]: MEN_ANIMATION_OBESE,
  },
  [Gender.FEMALE]: {
    [BmiValue.UNDERWEIGHT]: WOMEN_ANIMATION_NORMAL,
    [BmiValue.NORMAL]: WOMEN_ANIMATION_NORMAL,
    [BmiValue.OVERWEIGHT]: WOMEN_ANIMATION_OVERWEIGHT,
    [BmiValue.OBESITY]: WOMEN_ANIMATION_OBESE,
    [BmiValue.EXTREME_OBESITY]: WOMEN_ANIMATION_OBESE,
  },
}

export const CURRENT_FAT_LEVEL_MAP = {
  [Gender.MALE]: {
    [BmiValue.UNDERWEIGHT]: '2-4',
    [BmiValue.NORMAL]: '6-13',
    [BmiValue.OVERWEIGHT]: '14-17',
    [BmiValue.OBESITY]: '18-25',
    [BmiValue.EXTREME_OBESITY]: '26+',
  },
  [Gender.FEMALE]: {
    [BmiValue.UNDERWEIGHT]: '0-12',
    [BmiValue.NORMAL]: '14-20',
    [BmiValue.OVERWEIGHT]: '21-24',
    [BmiValue.OBESITY]: '25-31',
    [BmiValue.EXTREME_OBESITY]: '32+',
  },
}

export const GOAL_FAT_LEVEL_MAP = {
  [Gender.FEMALE]: '14-20',
  [Gender.MALE]: '6-13',
}
