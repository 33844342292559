import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import checkIcon from 'assets/images/check-benefits.png'
import banner from 'assets/images/feeling-hungry-banner.png'

import { Language } from 'root-constants'

import { StyledStopHunger as S } from './StopHunger.styles'
import { STOP_HUNGER_LIST } from './constants'

export const StopHungerV1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.stopHungerV1.title', { lng: Language.EN }),
    nextPagePath,
  })

  return (
    <>
      <S.Banner>
        <img src={banner} alt="banner" />
      </S.Banner>
      <Container>
        <S.Title>
          <Trans i18nKey="onboarding.stopHungerV1.title" />
        </S.Title>
        <S.Info>
          <Trans i18nKey="onboarding.stopHungerV1.info" />
        </S.Info>
        <S.List>
          {STOP_HUNGER_LIST.map((item) => (
            <S.Item key={item}>
              <S.CheckIcon>
                <img src={checkIcon} alt="check-icon" />
              </S.CheckIcon>
              <S.ItemText>
                <Trans i18nKey={item} />
              </S.ItemText>
            </S.Item>
          ))}
        </S.List>
        <StickyButtonContainer>
          <Button type="button" onClick={() => handleContinue('')}>
            {t('actions.soundsGood')}
          </Button>
        </StickyButtonContainer>
      </Container>
    </>
  )
}
