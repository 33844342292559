import React, { useCallback, useLayoutEffect, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { Spinner } from 'components/Spinner'

import {
  getSubscriptionListAction,
  setScreenNameAction,
} from 'root-redux/actions/common'
import { sendFacebookParamsAction } from 'root-redux/actions/user'
import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'
import { selectUserOnboardingEmail } from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import { useVatInfo } from 'hooks/useHasVatInfo'
import { usePayment } from 'hooks/usePayment'
import { useTargetHiddenOnScroll } from 'hooks/useTargetHiddenOnScroll'

import {
  CustomerReviews,
  IntroMediaEmail,
  PlanHighlights,
} from 'modules/purchase/components'

import { TPageProps } from 'models/common.model'

import heroPicture from 'assets/images/purchase-email/purchase-email-hero-min.png'

import { goTo } from 'browser-history'
import {
  Cohort,
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
} from 'root-constants'

import { StyledPurchaseEmail as S } from './PurchaseEmail.styles'

export const PurchaseEmail: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()

  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)
  const email = useSelector(selectUserOnboardingEmail)

  const hasVatInfo = useVatInfo()
  const { hasPrices, handleShowPayment } = usePayment()

  const elemForComparisonRef = useRef<HTMLHeadingElement>(null)

  useTargetHiddenOnScroll(elemForComparisonRef)

  useLayoutEffect(() => {
    dispatch(setScreenNameAction(ScreenName.ONBOARDING))

    dispatch(
      getSubscriptionListAction(
        SubscriptionListType.PURCHASE,
        hasVatInfo ? SubscriptionTags.TAX : SubscriptionTags.NO_TAX,
      ),
    )
  }, [dispatch, hasVatInfo])

  const handleGetPlan = useCallback(() => {
    dispatch(sendFacebookParamsAction())
    window.ttq.identify({ email })
    handleShowPayment()

    goTo(nextPagePath)
  }, [dispatch, email, handleShowPayment, nextPagePath])

  return !hasPrices ? (
    <Spinner />
  ) : (
    <S.Root>
      <S.Banner>
        <img src={heroPicture} alt="purchase-email-banner" />
      </S.Banner>
      <Container>
        <S.Title>{t('purchaseEmail.title')}</S.Title>
        <S.Description>
          <Trans i18nKey="purchaseEmail.description" />
        </S.Description>
        <IntroMediaEmail elemForComparisonRef={elemForComparisonRef} />
        <S.HighlightsContainer>
          <PlanHighlights titleElementRef={elemForComparisonRef} />
        </S.HighlightsContainer>
        {cohortToUse === Cohort.MF_EMAIL_FLOW_3 ? (
          <S.EmailSubscriptionsV2 />
        ) : (
          <S.EmailSubscriptions handleClick={handleGetPlan} />
        )}
        {cohortToUse === Cohort.MF_EMAIL_FLOW_3 && (
          <S.Button type="button" onClick={handleGetPlan}>
            {t('actions.getMyPlan')}
          </S.Button>
        )}
      </Container>
      <S.Reviews>
        <Container>
          <S.ReviewsTitle>
            <Trans i18nKey="purchaseEmail.reviewsTitle" />
          </S.ReviewsTitle>
          <CustomerReviews withTitle={false} />
        </Container>
      </S.Reviews>
      <S.ButtonContainer>
        <Button onClick={handleGetPlan} type="button">
          {t('actions.continue')}
        </Button>
      </S.ButtonContainer>
    </S.Root>
  )
}
